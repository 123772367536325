import { Option } from '@unifii/sdk';


export const PREVIEW_FORM_STORAGE_KEY = 'UfFormPreviewForm';
export const PROJECT_STORAGE_KEY = 'UfFormPreviewProject';
export const USER_PREFERENCES_STORAGE_KEY = 'UfUserPreferences';
export const STRUCTURE_STORAGE_KEY = 'UfStructure';
export const TABLE_SEARCH_MIN_LENGTH = 3;
export const LAST_USED_PROJECT_ID_KEY = 'lastUsedProjectId';

export const DefaultPaginationParams = { offset: 0, limit: 100 };

export const BOOL_OPTIONS: Option[] = [
    { identifier: 'true', name: 'Yes' },
    { identifier: 'false', name: 'No' }
];