import { Injectable } from '@angular/core';
import { CompanyDataDescriptorAdapterLoader } from '@unifii/library/common';
import { ClaimConfig } from '@unifii/sdk';

import { UcCompanyClaims } from 'client';


@Injectable()
export class UcCompanyDataDescriptorAdapterLoader implements CompanyDataDescriptorAdapterLoader {

    constructor(private ucCompanyClaims: UcCompanyClaims) { }

    loadCompanyClaims(): Promise<ClaimConfig[]> {
        return this.ucCompanyClaims.list({ params: { limit: 1000 } });
    }
}