<div class="header-pane uf-app-bar transparent">
    <a class="uf-button tertiary right" [routerLink]="['/']">Cancel</a>
    <button type="button" class="uf-button primary" (click)="save()">Save</button>
</div>

<uf-panel class="content-pane">
    <div *ngIf="form" class="uf-container-lg">
        <div class="uf-grid row-gap-lg pad-top">

            <uf-blockquote *ngIf="error && error.message" class="col-12 error" icon="errorSolid"
                [content]="error.message">
            </uf-blockquote>

            <div class="uf-box col-12">
                <div class="uf-app-bar flat accent">
                    <div class="title">
                        Email Settings
                    </div>
                </div>
                <div class="uf-grid pad">
                    <uf-text class="col-12" label="Reply to" [control]="replyToControl">
                        <uf-help content="Comma separate multiple email addresses."></uf-help>
                    </uf-text>
                </div>
            </div>

            <div class="uf-box col-12">
                <div class="uf-app-bar flat accent">
                    <div class="title">
                        Theming
                    </div>
                </div>
                <div class="uf-grid pad">
                    <uc-color [control]="emailColourControl" label="Button Colour" class="col-12"></uc-color>
                </div>
            </div>

            <div class="uf-box col-12">
                <div class="uf-app-bar accent">
                    <div class="title">
                        Invite Email
                    </div>
                </div>

                <div class="uf-app-bar">
                    <div class="title">Invite Email Expiry</div>
                </div>
                <div class="uf-grid pad">
                    <uf-number (valueChange)="inviteEmailExpirationChange($event, 'days')"
                        [value]="emailTokenDuration.days" class="col-md-3 col-sm-12" label="Days">
                    </uf-number>
                    <uf-number (valueChange)="inviteEmailExpirationChange($event, 'hours')"
                        [value]="emailTokenDuration.hours" class="col-md-3 col-sm-12" label="Hours">
                    </uf-number>
                    <uf-number (valueChange)="inviteEmailExpirationChange($event, 'minutes')"
                        [value]="emailTokenDuration.minutes" class="col-md-3 col-sm-12" label="Minutes">
                    </uf-number>
                    <uf-number (valueChange)="inviteEmailExpirationChange($event, 'seconds')"
                        [value]="emailTokenDuration.seconds" class="col-md-3 col-sm-12" label="Seconds">
                    </uf-number>
                    <uf-error class="col-12" [control]="inviteEmailExpiryControl | asUfControl">
                    </uf-error>
                </div>

                <div class="uf-app-bar">
                    <div class="title">Templates</div>
                </div>
                <div class="uf-grid pad">
                    <uc-email-templates [defaultTemplateControl]="inviteEmailDefaultTemplateControl"
                        [templatesControl]="inviteEmailTemplatesControl" [emailColour]="emailColourControl.value"
                        [default]="defaultTemplates.invite" class="col-12">
                    </uc-email-templates>
                </div>
            </div>

            <div class="uf-box col-12">
                <div class="uf-app-bar accent">
                    <div class="title">
                        Reset Password Email
                    </div>
                </div>
                <div class="uf-app-bar">
                    <div class="title">Templates</div>
                </div>
                <div class="uf-grid pad">
                    <uc-email-templates class="col-12"
                        [defaultTemplateControl]="resetPasswordEmailDefaultTemplateControl"
                        [templatesControl]="resetPasswordEmailTemplatesControl"
                        [default]="defaultTemplates.resetPassword">
                    </uc-email-templates>
                </div>
            </div>
        </div>
    </div>
</uf-panel>