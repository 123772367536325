<ng-template [ngIf]="ready">
    <div class="uf-app-bar">
        <button type="button" class="uf-action tertiary" (click)="close()" title="Close">
            <uf-icon name="close"></uf-icon>
        </button>
        <h3>{{isFormSettingsMode ? 'Form Settings' : 'Form Data Repository'}}</h3>
    </div>
    <div class="grow">
        <div class="uf-grid pad gaps" [formGroup]="formGroup">
            <ng-template [ngIf]="isFormSettingsMode">
                <uf-text [formControlName]="definitionKeys.Label" label="Label" class="col-12"></uf-text>
                <uf-text [formControlName]="definitionKeys.Identifier" label="Identifier" class="col-12"
                    [maxLength]="identifierWarningLength">
                </uf-text>
                <uf-message *ngIf="showIdentifierWarning" class="x-small warning col-12" icon="warningSolid">
                    <p>Identifier is too long</p>
                </uf-message>
                <uf-text [formControlName]="definitionKeys.SequenceNumberFormat" label="Form Number Format"
                    placeholder="ABC-######" class="col-12">
                </uf-text>
            </ng-template>
            <uf-autocomplete [formControlName]="definitionKeys.Bucket" label="Form Data Repository"
                (searchChange)="searchBuckets($event)" [options]="bucketsResults" [allowCustom]="true" class="col-12">
            </uf-autocomplete>
            <uf-message *ngIf="showBucketWarning" class="x-small warning col-12" icon="warningSolid">
                <p>Form Data Repository is too long</p>
            </uf-message>
            <uf-checkbox *ngIf="showRollingVersion" label="Has Rolling Version"
                [formControlName]="definitionKeys.HasRollingVersion" class="col-12"></uf-checkbox>
        </div>
    </div>
    <div class="row space-children pad">
        <button (click)="close()" class="uf-button tertiary right" type="button">Cancel</button>
        <button (click)="submit()" class="uf-button primary" type="button">
            {{isFormSettingsMode ? 'Next' : 'Save'}}
        </button>
    </div>
</ng-template>