<uf-panel *ngIf="ready && definition" class="container">

    <div class="header-pane uf-app-bar transparent">
        <uc-builder-header>
        </uc-builder-header>
    </div>

    <uf-panel class="content-pane">
        <div class="grid--fixed">
            <ng-template ngFor [ngForOf]="definition.fields" let-field let-i="index">
                <uc-language-field class="col-1of1" [field]="field" [compound]="compound" [translation]="translation">
                </uc-language-field>
            </ng-template>
        </div>
    </uf-panel>

</uf-panel>