<div class="grid">
    <uf-text class="col-1of1" label="Title" [(value)]="builderService.compound._title"
        [control]="control.controls.title | asUfControl">
    </uf-text>
    <uf-text class="col-1of1" label="Record Name" [(value)]="builderService.compound.recordName"
        [control]="control.controls.recordName | asUfControl">
    </uf-text>
    <uf-text class="col-1of1" label="identifier" [(value)]="builderService.definition.identifier"
        [control]="control.controls.identifier | asUfControl" [maxLength]="identifierMaxLength">
    </uf-text>
</div>