
import { FilterEntry, FilterType } from '@unifii/library/common';

import { CustomChoiceFilterEntry } from 'components';


export const MediaFilterEntries: (FilterEntry | CustomChoiceFilterEntry)[] = [
    {
        identifier: 'sortBy',
        label: 'Sort By',
        type: FilterType.Choice,
        options: [
            { identifier: 'uploaded', name: 'Date Uploaded' },
            { identifier: 'title', name: 'Title A-Z' },
            { identifier: 'filename', name: 'File name A-Z' },
        ]
    },
    {
        identifier: 'types',
        label: 'Types',
        type: FilterType.OptionArray,
        options: [
            { identifier: 'Image', name: 'Images' },
            { identifier: 'Audio', name: 'Audio' },
            { identifier: 'Video', name: 'Video' },
            { identifier: 'File', name: 'Files' }
        ]
    },
    {
        identifier: 'pending',
        label: 'Stages',
        type: FilterType.Choice,
        emptyOption: { // TODO make this a VisibleFilterDescriptor attribute
            identifier: 'false',
            name: 'Processed'
        },
        options: [{
            identifier: 'true',
            name: 'Pending'
        }]
    }
];
