<div class="header">
    <button type="button" class="uf-action tertiary" (click)="close()" title="Close">
        <uf-icon name="close"></uf-icon>
    </button>
    <h3>Image crop</h3>
</div>

<div class="uf-grid grow checkboard-bg" #container>
    <uf-spinner class="col-12" *ngIf="!ready" type="dots" colour="#177e89">
    </uf-spinner>
    <div class="col-12">
        <uc-image-cropper class="col center-all justify-center" *ngIf="ready" [src]="image.url" [minWidth]="minWidth"
            [minHeight]="minHeight" [enabled]="enabled" [(value)]="value" [size]="canvasSize">
        </uc-image-cropper>
    </div>
</div>

<div class="row space-children pad">
    <div class="row grow center-all">
        <p *ngIf="!enabled" class="warning-text left">This image is too small for cropping</p>
        <span *ngIf="ready">Original size {{image.width}}x{{image.height}}</span>
        <span *ngIf="ready && value">
            Crop [ X:{{value.x}}, Y:{{value.y}}, W:{{value.width}}, H:{{value.height}}]
        </span>
    </div>
    <button type="button" class="uf-button tertiary right" *ngIf="enabled" (click)="close()">Cancel</button>
    <button type="button" class="uf-button" *ngIf="ready && value" (click)="remove()">Clear</button>
    <button type="button" class="uf-button primary" *ngIf="enabled" (click)="save()">Save</button>
</div>