<div class="header-pane uf-app-bar transparent">
    <div class="row space-children right">
        <a [routerLink]=" ['../']" type="button" class="uf-button tertiary">
            Cancel
        </a>
        <button (click)="save()" restrict="AppManager" type="button" class="uf-button primary">
            Save
        </button>
    </div>
</div>
<uf-panel *ngIf="app && form" class="content-pane container">
    <div class="uf-container-lg">
        <div class="uf-grid row-gap-lg pad-top">
            <div class="uf-box col-12">
                <div class="uf-app-bar flat accent">
                    <div class="title">
                        Details
                    </div>
                </div>

                <div class="uf-grid pad">
                    <uf-text class="col-6" label="Id" [(value)]="app.id" [control]="form.controls.id | asUfControl"
                        [disabled]="!isNew">
                    </uf-text>
                    <uf-text class="col-6" label="Name" [(value)]="app.name"
                        [control]="form.controls.name | asUfControl">
                    </uf-text>
                    <uf-description-list class="col-12">
                        <ng-container *ngIf="app.createdBy?.username">
                            <dt>Created by:</dt>
                            <dd>{{app.createdBy?.username}}</dd>
                        </ng-container>
                        <ng-container *ngIf="app.createdAt">
                            <dt>Created at:</dt>
                            <dd>{{app.createdAt | momentDate: 'dd/MM/yyyy h:mm a'}}</dd>
                        </ng-container>
                        <ng-container *ngIf="app?.lastModifiedBy?.username">
                            <dt>Last modified by:</dt>
                            <dd>{{app.lastModifiedBy?.username}}</dd>
                        </ng-container>
                        <ng-container *ngIf="app?.lastModifiedAt">
                            <dt>Last modified at:</dt>
                            <dd>{{app.lastModifiedAt | momentDate: 'dd/MM/yyyy h:mm a'}}</dd>
                        </ng-container>
                    </uf-description-list>
                </div>
            </div>

            <div class="uf-box col-12">
                <div class="uf-app-bar flat accent">
                    <div class="title">Authentication</div>
                </div>
                <div class="uf-grid pad">
                    <uc-secret-input [(value)]="app.secret" (valueChange)="changeSecret($event)" class="col-12"
                        label="API Key">
                    </uc-secret-input>
                </div>
            </div>

            <div class="uf-box col-12">
                <div class="uf-app-bar flat accent">
                    <div class="title">Android Notification Settings</div>
                    <button [title]="editingGoogleKey ? 'Lock' : 'Unlock'" class="uf-action"
                        (click)="editingGoogleKey = !editingGoogleKey">
                        <uf-icon [name]="editingGoogleKey ? 'lock' : 'unlocked'"></uf-icon>
                    </button>
                </div>
                <div class="uf-grid pad">
                    <div class="col-12">
                        <uf-textarea label="Google push key" class="grow" [(value)]="app.googlePushKey"
                            [disabled]="!editingGoogleKey">
                        </uf-textarea>
                    </div>
                </div>
            </div>

            <div class="uf-box col-12">
                <div class="uf-app-bar flat accent">
                    <div class="title">
                        iOS Notification Settings
                    </div>
                    <button *ngIf="showAppleCertDetails" (click)="downloadAppleCertificate()" type="button"
                        title="Download" class="uf-action">
                        <uf-icon name="import"></uf-icon>
                    </button>
                </div>
                <div class="uf-grid pad">
                    <uf-text *ngIf="showAppleCertDetails" disabled="true" label="Name" [value]="app.appleCertName"
                        class="col-12">
                    </uf-text>
                    <uf-checkbox *ngIf="showAppleCertDetails" [(value)]="app.appleIsSandbox" label="Sandbox"
                        class="col-12">
                    </uf-checkbox>
                    <div *ngIf="showAppleCertDetails && isAppleCertExpired" class="col-12">
                        <uf-blockquote class="error" icon="errorSolid"
                            content="Your certificate is expired on {{app.appleCertNotAfter | date:'medium'}}">
                        </uf-blockquote>
                    </div>
                    <div *ngIf="showAppleCertDetails && !isAppleCertExpired" class="col-12">
                        <uf-blockquote class="success" icon="successSolid"
                            content="Your certificate is valid until {{app.appleCertNotAfter | date:'medium'}}">
                        </uf-blockquote>
                    </div>
                    <div class="col-12">
                        <uf-file [label]="app.appleCertName ? 'Select a new certificate' : 'Select a certificate'"
                            (valueChange)="updateAppleCertificate($event)" class="padded--vertical">
                        </uf-file>
                    </div>
                    <div *ngIf="showAppleCertPreview" class="col-12">
                        <uf-blockquote icon="warning" content="Certificate selected, please provide its
                            password." class="warning"></uf-blockquote>
                    </div>
                    <uf-password *ngIf="uploadedAppleCert" label="Apple password" [showReveal]="true"
                        [(value)]="app.applePassword" class="col-12">
                    </uf-password>
                    <uf-checkbox *ngIf="uploadedAppleCert" [(value)]="app.appleIsSandbox" label="Sandbox"
                        class="col-12">
                    </uf-checkbox>
                </div>
            </div>

            <div class="uf-box col-12">
                <div class="uf-app-bar flat accent">
                    <div class="title">Cross-Origin Resource Sharing (CORS)</div>
                </div>
                <div class="uf-grid pad">
                    <div *ngFor="let control of (form.controls.corsOrigins | asUfControlArray).controls; let i = index"
                        class="row col-12">
                        <uf-text [label]="'Origin ' + (i + 1)" [(value)]="$any(app.corsOrigins)[i]"
                            [control]="control | asUfControl" class="grow">
                        </uf-text>
                        <button type="button" title="Delete" (click)="deleteCorsOriging(i)" class="uf-action tertiary">
                            <uf-icon name="delete"></uf-icon>
                        </button>
                    </div>
                    <div class="col-12 row">
                        <button type="buton" (click)="addCorsOrigin()" class="uf-button right">Add</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</uf-panel>