import { Subject } from 'rxjs';

import { Inject, inject, Injectable, InjectionToken } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager, TableInputManager, TableInputs, TableManagerFunctions } from '@unifii/components';
import {
    ActionMultiplicity, FieldDisplayPipe, FilterEntries, FilterEntry, FilterValue, HierarchyUnitProvider, ModalService, TableConfig,
    TableConfigColumn, TableRowContext, ToastService
} from '@unifii/library/common';
import { Dictionary, FieldType } from '@unifii/sdk';

import { ActivityType, UcWorkflow, WorkflowActivityInfo } from 'client';
import { TABLE_SEARCH_MIN_LENGTH } from 'constant';

import { DialogsService } from 'services/dialogs.service';

import { ActivityTableDataSource } from './activity-table-data-source';
import { WorkflowActivityLabel } from './constants';


export const ActivityTypeToken = new InjectionToken<ActivityType>('ActivityType');

@Injectable()
export class WorkflowActivityTableManager implements TableContainerManager<WorkflowActivityInfo, FilterValue, FilterEntry> {

    tableConfig: TableConfig<WorkflowActivityInfo>;
    showSearch = true;
    searchMinLength = TABLE_SEARCH_MIN_LENGTH;
    addActionConfig = true;
    defaultSort = 'label';

    reload = new Subject<void>();
    update = new Subject<TableInputs<FilterValue>>();
    updateItem = new Subject<WorkflowActivityInfo>();

    inputManager: TableInputManager<FilterValue, FilterEntry>;

    constructor(
        private ucWorkflow: UcWorkflow,
        private router: Router,
        private route: ActivatedRoute,
        @Inject(ActivityTypeToken) private activityType: ActivityType,
        private displayPipe: FieldDisplayPipe,
        private toastService: ToastService,
        private modalService: ModalService,
        private dialogs: DialogsService,
        @Inject(FilterEntries) entries: FilterEntry[]
    ) {
        this.inputManager = new TableInputManager(entries, inject(HierarchyUnitProvider));

        const id = `table_workflow_${this.activityType}`;
        const columns: TableConfigColumn<WorkflowActivityInfo>[] = [{
            name: 'label',
            label: 'Title',
            sortable: true
        }, {
            name: 'bucket',
            label: 'Form Data Repository',
            sortable: true
        }, {
            name: 'lastModifiedAt',
            label: 'Last Modified At',
            sortable: true,
            value: (item) => this.displayPipe.transform(item.lastModifiedAt, FieldType.DateTime)
        }, {
            name: 'lastModifiedBy',
            label: 'Last Modified by',
            value: (item) => item.lastModifiedBy.username
        }];

        const tableConfig = TableManagerFunctions.createTableConfig(columns, id);

        tableConfig.actions = [{
            label: 'Duplicate',
            multiplicity: ActionMultiplicity.Single,
            action: (row: TableRowContext<WorkflowActivityInfo>) => this.duplicate(row.$implicit?.id as string)
        }, {
            label: 'Delete',
            multiplicity: ActionMultiplicity.Single,
            action: (row: TableRowContext<WorkflowActivityInfo>) => this.delete(row.$implicit as WorkflowActivityInfo)
        }];
        tableConfig.rowLink = (activity: WorkflowActivityInfo) => [activity.id];

        this.tableConfig = tableConfig;
    }

    createDataSource(inputs?: TableInputs<FilterValue>) {
        let params: Dictionary<any> | undefined;
        if (inputs != null) {
            params = this.inputManager?.serializeInputs(inputs);
        }
        return new ActivityTableDataSource(this.ucWorkflow, this.activityType, params, params?.q, params?.sort);
    }

    private duplicate(id: string) {
        this.router.navigate([id, { duplicate: 'true' }], { relativeTo: this.route });
    }

    private async delete(activity: WorkflowActivityInfo, force?: boolean) {
        try {

            if (!force && !await this.dialogs.confirmDelete()) {
                return;
            }

            await this.ucWorkflow.deleteActivity(activity.id, force);
            this.reload.next();
            this.toastService.success(`${WorkflowActivityLabel[activity.type]} deleted`);
        } catch (e) {
            if (e.code?.toString() === '409') {
                const confirmation = await this.modalService.openConfirm({
                    title: `Delete ${WorkflowActivityLabel[activity.type]}`,
                    message: `This activity is linked to a rule, are you really sure you'd like to delete ${activity.label}?`,
                    confirmLabel: 'Delete',
                    cancelLabel: `Don't Delete`
                });

                if (confirmation) {
                    this.delete(activity, true);
                }
                return;
            }
            this.toastService.error(e?.message ?? `Could not delete ${WorkflowActivityLabel[activity.type]}`);
        }
    }

}
