import { Injectable } from '@angular/core';
import { UfControlGroup, UfFormBuilder, ValidatorFunctions } from '@unifii/library/common';
import { ActivityType, Timer } from 'client';

export interface WorkflowTimerModel {
    id: string;
    label: string;
    bucket: { id: string };
    expression: string;
}

export enum ControlKeys {
    Id = 'id',
    Label = 'label',
    Bucket = 'bucket',
    Expression = 'expression',
}

@Injectable({
    providedIn: 'root'
})
export class WorkflowTimerFormController {

    private readonly requiredMessage = 'Field is Required';

    constructor(
        private ufb: UfFormBuilder
    ) { }

    buildRoot(wfTimerModel?: WorkflowTimerModel): UfControlGroup {

        const control = this.ufb.group({
            [ControlKeys.Id]: [{ value: wfTimerModel?.id, disabled: true }],
            [ControlKeys.Label]: [wfTimerModel?.label, ValidatorFunctions.required(this.requiredMessage)],
            [ControlKeys.Bucket]: wfTimerModel?.bucket,
            [ControlKeys.Expression]: [wfTimerModel?.expression, ValidatorFunctions.required(this.requiredMessage)]
        });

        return control;
    }

    toModel({ id, label, expression, bucket: bucketId }: Timer): WorkflowTimerModel {
        return { id, label, expression, bucket: { id: bucketId } } as any as WorkflowTimerModel;
    }

    toData({ id, label, expression, bucket }: WorkflowTimerModel): Timer {
        return { id, label, expression, bucket: bucket?.id, type: ActivityType.Timer } as any as Timer;
    }

}