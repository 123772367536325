import { FilterEntry, FilterType } from '@unifii/library/common';
import { Option } from '@unifii/sdk';


const convertStringToOption = (str: string): Option => {
    const identifier = str;
    const name = str.match(/[A-Z][a-z]+/g)?.join(' ') ?? str;
    return { identifier, name };
};

export const createFilterEntries = (): FilterEntry[] => [
    {
        label: 'Resources',
        identifier: 'resource',
        type: FilterType.Choice,
        options: [
            'Projects', 'MyAccount', 'Users', 'Assets', 'AssetTags', 'AuditLog',
            'Structure', 'Collections', 'CollectionItems', 'Pages', 'Views', 'Forms',
            'Buckets', 'Import', 'Versions'
        ].map(convertStringToOption)
    }, {
        label: 'Actions',
        identifier: 'actions',
        type: FilterType.Choice,
        options: [
            'Get', 'List', 'Create', 'Update', 'Delete', 'Approve', 'Unapprove',
            'Archive', 'Publish', 'PublishPreview', 'Start'
        ].map(convertStringToOption)
    }, {
        label: 'When',
        identifier: 'when',
        type: FilterType.DateRange
    }
];

