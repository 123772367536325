<div class="header">
    <button type="button" class="uf-action tertiary" (click)="close()" title="Close">
        <uf-icon name="close"></uf-icon>
    </button>
    <h3>{{data.title}}</h3>
</div>

<uf-panel class="container">

    <div class="pad-sides">
        <div class="gap--bottom" *ngFor="let entry of data.glossary">
            <strong class="select" (click)="close(entry)" (mousedown)="onMouseDown($event)">{{entry.key}}</strong>
            <span>{{entry.value}}</span>
        </div>
    </div>

</uf-panel>