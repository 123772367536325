<ng-template [ngIf]="field">

    <!-- Value based on a DataSource -->
    <ng-template [ngIf]="dataSourceLoader">
        <ng-template [ngIf]="operator === 'in' || operator === 'contains'" [ngIfElse]="single">
            <uf-chips label="Value" [control]="control" [options]="results" nameProperty="_display"
                (searchChange)="query($event)" [ngClass]="cssClass">
            </uf-chips>
        </ng-template>
        <ng-template #single>
            <uf-autocomplete label="Value" [control]="control" [options]="results" nameProperty="_display"
                (searchChange)="query($event)" [ngClass]="cssClass">
            </uf-autocomplete>
        </ng-template>
    </ng-template>

    <!-- Value based on static Options -->
    <ng-template [ngIf]="options">
        <ng-template [ngIf]="operator === 'in' || operator === 'contains'" [ngIfElse]="single">
            <!-- UNIFII-5457 Removed the uf-multi-choice as it does not allow to deselect value that are not present anymore in the corresponding static options -->
            <!-- <ng-template [ngIf]="options.length > 5" [ngIfElse]="fewOptions"> -->
            <uf-chips label="Value" [control]="control" (searchChange)="query($event)" [options]="results"
                valueProperty="_id" nameProperty="_display" [ngClass]="cssClass">
            </uf-chips>
            <!-- </ng-template> -->
            <!-- <ng-template #fewOptions>
                <uf-multi-choice label="Value" [(value)]="multipleValue" [ngClass]="cssClass" columns="1"
                    [control]="control" [options]="options" valueProperty="_id" nameProperty="_display">
                </uf-multi-choice>
            </ng-template> -->
        </ng-template>
        <ng-template #single>
            <uf-select label="Value" [control]="control" [options]="options" valueProperty="_id" nameProperty="_display"
                [ngClass]="cssClass">
            </uf-select>
        </ng-template>
    </ng-template>

    <!-- Free input value based on the FieldType -->
    <ng-template [ngIf]="!dataSourceLoader && !options">
        <ng-container [ngSwitch]="field.type">
            <uf-datetime *ngSwitchCase="types.DateTime" label="Value" [control]="control" [ngClass]="cssClass">
            </uf-datetime>

            <uf-time *ngSwitchCase="types.Time" label="Value" [control]="control" [ngClass]="cssClass">
            </uf-time>

            <uf-date *ngSwitchCase="types.Date" label="Value" [control]="control" [ngClass]="cssClass">
            </uf-date>

            <uf-datetime-tz *ngSwitchCase="types.ZonedDateTime" label="Value" [control]="control" [ngClass]="cssClass"
                class="stacked">
            </uf-datetime-tz>

            <uf-number *ngSwitchCase="types.Number" label="Value" [control]="control" [ngClass]="cssClass">
            </uf-number>

            <uf-checkbox *ngSwitchCase="types.Bool" label="Value" [control]="control" [ngClass]="cssClass">
            </uf-checkbox>

            <uf-hierarchy-unit *ngSwitchCase="types.Hierarchy" label="Value" template="input" [control]="control"
                [ceiling]="field.hierarchyConfig?.ceiling" [selectLeafsOnly]="false">
            </uf-hierarchy-unit>

            <uf-chips *ngSwitchCase="types.TextArray" label="Value" [control]="control" [allowCustom]="true"
                [ngClass]="cssClass">
            </uf-chips>

            <uf-text *ngSwitchDefault label="Value" [control]="control" [ngClass]="cssClass">
            </uf-text>
        </ng-container>

    </ng-template>

</ng-template>