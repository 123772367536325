import { Injectable } from '@angular/core';
import { ClientDeleteOptions, ClientGetOptions, mergeParams } from '@unifii/sdk';

import { DefaultPaginationParams } from 'constant';

import { PermissionPrincipalType, Role } from './models';
import { UcPermissions } from './permissions';
import { UcClient } from './uc-client';


@Injectable()
export class UcRoles {

    constructor(private client: UcClient) { }

    get(q?: string, sort?: string, options?: ClientGetOptions): Promise<Role[]> {
        const params = mergeParams(DefaultPaginationParams, options?.params, { q, sort });
        return this.client.get(this.url(), { ...options, params });
    }

    getDetails(id: string, options?: ClientGetOptions): Promise<Role> {
        return this.client.get(this.url(id), options);
    }

    save(role: Role): Promise<Role> {
        return role.id ? this.client.put(this.url(role.id), role) : this.client.post(this.url(), { body: role });
    }

    delete(id: string, options?: ClientDeleteOptions): Promise<void> {
        return this.client.delete(this.url(id), options);
    }

    permissions(id: string): UcPermissions {
        return new UcPermissions(this.client, PermissionPrincipalType.Role, id);
    }

    private url(...extra: string[]): string {
        const urlParts = ['roles', ...extra];
        return this.client.buildUrl(urlParts);
    }
}
