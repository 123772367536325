<div class="uf-app-bar ">
    <button type="button" class="uf-action tertiary" (click)="close()" title="Close">
        <uf-icon name="close"></uf-icon>
    </button>
    <h3>Data Mapping</h3>
</div>
<div class="grow">
    <div class="uf-grid pad gaps" [formGroup]="form">

        <uf-checkbox *ngIf="allowIsExpression" [formControlName]="mappingKeys.IsFromExpression"
            (valueChange)="changedIsExpression($event)" label="Is expression" class="col-12">
        </uf-checkbox>

        <uf-text *ngIf="form.get(mappingKeys.IsFromExpression)?.value === true"
            [formControlName]="mappingKeys.FromExpression" label="From expression" class="col-12"></uf-text>

        <uf-autocomplete *ngIf="form.get(mappingKeys.IsFromExpression)?.value !== true"
            [formControlName]="mappingKeys.From" label="From" [options]="filteredProperties" nameProperty="display"
            (searchChange)="filterProperties($event)" (valueChange)="changedFrom($event)" class="col-12">
        </uf-autocomplete>

        <uf-text [formControlName]="mappingKeys.To" label="To" class="col-12">
        </uf-text>


        <uf-text [formControlName]="mappingKeys.Label" label="Label" class="col-12">
        </uf-text>

        <uf-checkbox [formControlName]="mappingKeys.IsVisible" (valueChange)="changedVisible($event)" label="Visible"
            class="col-12">
        </uf-checkbox>

        <uf-textarea *ngIf="showItemTemplate" [formControlName]="mappingKeys.ItemTemplate" label="Item template"
            class="col-12">
        </uf-textarea>

        <uf-checkbox [formControlName]="mappingKeys.HideEmpty" label="Hide Empty" class="col-12">
        </uf-checkbox>

        <ng-template ufFeatureFlag="reporting">
            <uf-checkbox [formControlName]="mappingKeys.IsReportable" label="Reportable" class="col-12">
            </uf-checkbox>
        </ng-template>

        <uf-description-list [items]="dataTypeDescriptor" class="col-12"></uf-description-list>
        <uf-description-list [items]="attributeDescriptor" class="col-12"></uf-description-list>

    </div>
</div>
<div class="row space-children pad">
    <button (click)="close()" class="uf-button tertiary right" type="button">Cancel</button>
    <button (click)="submit()" class="uf-button primary" type="button">Save</button>
</div>