import { Directive } from '@angular/core';

import { Content } from './content';


@Directive()
export abstract class ContentValue extends Content {

    constructor() {
        super();

        // Field ready
        this.subscriptions.add(this.builderService.fieldReady.subscribe(() => {
            if (this.init) {
                this.init();
            }
        }));
    }

    contentChanged(v: any) {
        console.log('ContentValue.onContentChange');
        this.contentChange.emit(v);

        if (this.editorField) {
            this.builderService.fieldEdit.next({ subject: this.editorField, atomic: false });
        }
    }

    // component custom initialization
    protected abstract init?(): void;
}
