import { Subscription } from 'rxjs';

import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService, ToastService, UfControl, UfControlGroup, ValidatorFunctions } from '@unifii/library/common';
import { Language } from '@unifii/sdk';

import { AppInfo, Media, UcApps, UcClient, UcProject, UcProjectInfo } from 'client';

import { EditData } from 'components/common/edit-data';
import { MediaSearchComponent } from 'components/content/modals/media-search.component';
import { ModalSearchData } from 'components/content/modals/modal-search';

import { ContextService } from 'services/context.service';
import { translatedLanguages } from 'services/translate-loader';

import { SettingsComponent } from './settings.component';


@Component({
    templateUrl: './settings-general.html',
    styleUrls: ['./settings-general.less']
})
export class SettingsGeneralComponent implements EditData, OnDestroy {

    form: UfControlGroup;
    project: UcProjectInfo;
    langResults: Language[] = [];
    companyResults: string[] = [];
    selectedLanguage: Language;
    allApps: AppInfo[];
    showCompanyProfile: boolean;

    private allLanguages: Language[] = [];
    private subscriptions = new Subscription();

    constructor(
        private toastService: ToastService,
        private context: ContextService,
        private router: Router,
        private route: ActivatedRoute,
        private modalService: ModalService,
        private ucProject: UcProject,
        private ucClient: UcClient,
        private ucApps: UcApps,
        private parent: SettingsComponent
    ) {

        this.loadProject();
        this.form = new UfControlGroup({
            name: new UfControl(ValidatorFunctions.required('Name is required')),
            privacyPolicyUrl: new UfControl(),
            primaryLanguage: new UfControl(ValidatorFunctions.required('Primary language is required'))
        });

        // Refresh errors, as duplicated errors are based on control.parent values
        // that is not connected at time of Control creation and validation
        this.subscriptions.add(this.form.valueChanges.subscribe(() => this.edited = true));
        // get all available languages
        this.ucClient.getLanguages().then(l => this.allLanguages = l);
        // get all available apps
        this.ucApps.get().then(apps => this.allApps = apps);

        this.showCompanyProfile = !!this.context.tenantSettings?.features.companies;
    }

    set edited(v: boolean) {
        this.parent.edited = v;
    }

    get edited() {
        return this.parent.edited;
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
        this.parent.edited = false;
    }

    async findLogo() {
        const result = await this.modalService.openMedium<ModalSearchData, Media[]>(MediaSearchComponent, {
            title: 'Find Logo',
            type: 'Image',
        });

        if (!result) {
            return;
        }

        this.project.logo = {
            id: result[0].id as number,
            url: result[0].url,
        };
        this.edited = true;
    }

    deleteLogo() {
        delete this.project.logo;
        this.edited = true;
    }

    searchLang(query: string, excludePrimary = false) {
        const availableLanguages = this.allLanguages.filter(language => translatedLanguages.includes(language.code));

        this.langResults = availableLanguages
            .filter(availableLanguage => !(this.project.languages || [])
                .find(projectLanguage => projectLanguage.code === availableLanguage.code));

        if (excludePrimary) {
            this.langResults = this.langResults.filter(l => !this.project.primaryLanguage || this.project.primaryLanguage.code !== l.code);
        }

        if (!query || !query.trim().length) {
            return;
        }

        query = query.toLowerCase();

        this.langResults = this.langResults.filter(l =>
            l.code.toLowerCase().includes(query) || l.name.toLowerCase().includes(query)
        );
    }

    async searchBucket(query: string) {
        this.companyResults = (await this.ucProject.getFormBuckets({ params: { q: query, hasRollingVersion: true } })).map(b => b.id);
    }

    addLang(entry?: Language) {
        if (!entry || !this.project.languages) {
            return;
        }

        this.project.languages.push(entry);
        this.edited = true;
    }

    removeLang(i: number) {
        if (!this.project.languages) {
            return;
        }

        this.project.languages.splice(i, 1);
        this.edited = true;
    }

    save() {
        this.form.setSubmitted();
        if (this.form.invalid) {
            return;
        }

        // update our copy
        this.project.name = this.form.controls.name.value;
        this.project.privacyPolicyUrl = this.form.controls.privacyPolicyUrl.value;

        this.ucProject.save(this.project).then(p => {
            this.edited = false;
            this.context.project = p;
            this.project = JSON.parse(JSON.stringify(p));
            this.toastService.success('Changes saved!');
        });
    }

    cancel() {
        this.router.navigate(['../../'], { relativeTo: this.route });
    }

    private loadProject() {
        this.project = JSON.parse(JSON.stringify(this.context.project));
    }

}
