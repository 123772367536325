
import { AuthProviderComponent } from './auth-provider.component';
import { AuthProvidersComponent } from './auth-providers.component';
import { AuthProviderDetailComponent } from './detail/auth-provider-detail.component';
import { AuthProviderMappingActionsComponent } from './mappings/auth-provider-mapping-actions.component';
import { AuthProviderMappingFiltersComponent } from './mappings/auth-provider-mapping-filters.component';
import { AuthProviderMappingModalComponent } from './mappings/auth-provider-mapping-modal.component';
import { AuthProviderMappingUnitComponent } from './mappings/auth-provider-mapping-unit.component';
import { AuthProviderMappingsComponent } from './mappings/auth-provider-mappings.component';


export * from './auth-provider.component';
export * from './auth-providers.component';
export * from './detail/auth-provider-detail.component';
export * from './mappings/auth-provider-mappings.component';
export * from './mappings/auth-provider-mapping-unit.component';
export * from './mappings/auth-provider-mapping-modal.component';
export * from './mappings/auth-provider-mapping-actions.component';
export * from './mappings/auth-provider-mapping-filters.component';


export const IDENTITY_COMPONENTS = [
    AuthProviderComponent,
    AuthProvidersComponent,
    AuthProviderDetailComponent,
    AuthProviderMappingsComponent,
    AuthProviderMappingUnitComponent,
    AuthProviderMappingModalComponent,
    AuthProviderMappingActionsComponent,
    AuthProviderMappingFiltersComponent
];