import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { UfControlGroup } from '@unifii/library/common';
import { Field, FieldTemplate, FieldType, Option } from '@unifii/sdk';

import { IdentifierFunctions } from 'helpers/helpers';
import { DialogsService } from 'services/dialogs.service';


@Component({
    selector: 'uc-field-option',
    templateUrl: './field-option.html',
    styles: [`:host { display: block; }`]
})
export class FieldOptionComponent implements OnChanges {

    @Input() control: UfControlGroup;
    @Input() isExpanded: boolean;
    @Input() field: Field;
    @Input() option: Option;
    @Output() remove = new EventEmitter<Option>();
    @Output() notifyRefresh = new EventEmitter<void>(); // todo: remove asap should not be required when controls are attached to the same tree structure */

    readonly fieldType = FieldType;

    labelPrefix: string;
    identifierMaxLength: number = IdentifierFunctions.IDENTIFIER_MAX_LENGTH;
    showAlignmentOptions: boolean;

    constructor(private dialogs: DialogsService) { }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.option) {
            if (this.field.type === FieldType.Bool) {
                const { identifier } = changes.option.currentValue as Option;
                this.labelPrefix = `${identifier.charAt(0).toUpperCase() + identifier.substr(1, identifier.length)} - `;
            }
        }

        this.showAlignmentOptions = !(this.field && [FieldType.Choice, FieldType.MultiChoice].includes(this.field.type) &&
            (this.field.template && [
                FieldTemplate.OptionWithContent,
                FieldTemplate.RadioWithContent,
                FieldTemplate.CheckboxWithContent
            ].includes(this.field.template as FieldTemplate)));
    }

    nameChange(value: string) {

        // For boolean field identifiers set to bools
        if (this.field.type === FieldType.Bool) {
            return;
        }

        // Generate identifier
        const identifier = IdentifierFunctions.safeOptionIdentifier(this.option, this.field.options as Option[], value);
        this.control.controls.identifier.setValue(identifier, { onlySelf: false, emitValue: true });

        /** todo: remove asap should not be required when controls are attached to the same tree structure */
        setTimeout(() => {
            this.notifyRefresh.emit();
        }, 0);
    }

    async removeOption(event: Event) {

        event.stopPropagation();

        if (!await this.dialogs.confirmDelete()) {
            return;
        }

        this.remove.emit(this.option);
    }

    get showContentField(): boolean {
        return !!([FieldType.Choice, FieldType.MultiChoice].includes(this.field.type)
            && this.field.template
            && [FieldTemplate.CheckboxWithContent, FieldTemplate.RadioWithContent, FieldTemplate.OptionWithContent].includes(this.field.template as FieldTemplate));

    }

}
