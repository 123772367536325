import { Component } from '@angular/core';
import { Breadcrumb } from '@unifii/library/common';

import { SystemRole } from 'client';

import { TabConfig } from 'pages/page-models';
import { TabsPage } from 'pages/tabs-page';

import { BreadcrumbService } from 'services/breadcrumb.service';
import { ContextService } from 'services/context.service';


@Component({
    templateUrl: './settings.html'
})
export class TenantSettingsComponent extends TabsPage {

    breadcrumbs: Breadcrumb[] = this.breadcrumbService.getBreadcrumbs(this.route);
    tabs: TabConfig[] = [];

    constructor(
        private breadcrumbService: BreadcrumbService,
        private context: ContextService
    ) {
        super();
        if (this.context.checkRoles(SystemRole.SystemManager)) {
            this.tabs.push(
                { label: 'General', path: 'general' },
                { label: 'Identity', path: 'sso' },
                { label: 'Email Settings', path: 'email' },
                { label: 'SMTP Settings', path: 'smtp' },
                { label: 'Integrations', path: 'integrations' }
            );
        }
        if (this.context.checkRoles(SystemRole.SuperUser, SystemRole.AppManager)) {
            this.tabs.push(
                { label: 'Apps', path: 'apps' }
            );
        }
    }
}