<div class="uf-app-bar">
    <button type="button" class="uf-action tertiary" (click)="runtime.close()" title="Close">
        <uf-icon name="close"></uf-icon>
    </button>
    <h3>Send Test</h3>
</div>
<div class="uf-grid pad">
    <uf-email class="col-12" label="Email" [control]="email"></uf-email>
</div>
<div class="row space-children pad">
    <button type="button" class="uf-button tertiary right" (click)="runtime.close()">Cancel</button>
    <button type="button" class="uf-button primary" (click)="sendTest()">Send Test</button>
</div>