<div *ngIf="control" class="uf-box flat pad-sm-sides uf-grid row-gap-sm pad-sm-top pad-sm-bottom">

    <uf-select *ngIf="hasVariations" label="Variation" placeholder="Default" [options]="variationOptions"
        [(value)]="selectedVariation" (valueChange)="selectVariation($event)" class="col-12 pad-sm-sides">
    </uf-select>

    <ng-template [ngIf]="!variation">
        <uc-node-content-info *ngIf="hasContentLinked" class="col-12" [control]="control">
        </uc-node-content-info>
        <uc-node-settings [control]="control" class="col-12"></uc-node-settings>
        <uc-node-roles *ngIf="!isHome" class="col-12" [control]="control"></uc-node-roles>
        <uc-node-variations *ngIf="isHome" class="col-12" [control]="variations"></uc-node-variations>
        <uc-node-dashboard-tables *ngIf="node.type === nodeTypes.Dashboard" class="col-12" [control]="bucketOptions">
        </uc-node-dashboard-tables>
        <uc-node-args *ngIf="node.type === nodeTypes.IFrame" class="col-12" [control]="args">
        </uc-node-args>
    </ng-template>

    <ng-template [ngIf]="variation">
        <uc-node-content-info *ngIf="hasVariationContentLinked" class="col-12" [control]="variation">
        </uc-node-content-info>
        <uc-node-settings class="col-12" [control]="variation"></uc-node-settings>
        <uc-node-roles class="col-12" [control]="variation"></uc-node-roles>
        <uc-node-dashboard-tables *ngIf="variation.get(variationControlKeys.Type)?.value === nodeTypes.Dashboard"
            [control]="variation.get(variationControlKeys.BucketOptions) | asSafeAbstractControl | asUfControlArray"
            class="col-12">
        </uc-node-dashboard-tables>
    </ng-template>

</div>