import { Component, HostBinding } from '@angular/core';

@Component({
    templateUrl: './hierarchy.html',
    styleUrls: ['./hierarchy.less']
})
export class HierarchyComponent {

    @HostBinding('class.stretch-component') class = true;

}