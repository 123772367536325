import { Component, Input, OnInit } from '@angular/core';
import { UfControlArray, UfControlGroup } from '@unifii/library/common';
import { FieldType, Option, ValidatorType } from '@unifii/sdk';
import { DialogsService } from 'services/dialogs.service';

import { ValidatorControlKeys } from '../form-editor-control-keys';
import { FormEditorFormCtrl } from '../form-editor-form-ctrl';
import { FormEditorFunctions } from '../form-editor-functions';
import { FormFieldMetadata, ValidatorLiteral } from '../form-editor-model';
import { FormEditorService } from '../form-editor.service';


@Component({
    selector: 'uc-form-field-validators',
    templateUrl: './form-field-validators.html'
})
export class FormFieldValidatorsComponent implements OnInit {

    @Input() validators: UfControlArray;
    @Input() type: FieldType;

    readonly validatorKeys = ValidatorControlKeys;

    meta: FormFieldMetadata;
    ready: boolean;
    expandeds: boolean[];
    validatorTypes: Option[];

    constructor(
        private fb: FormEditorFormCtrl,
        private service: FormEditorService,
        private dialogs: DialogsService
    ) { }

    ngOnInit() {
        this.validatorTypes = this.service.fieldValidtorOptions(this.type);
        this.expandeds = Array(this.validators.length).fill(false);
        this.ready = true;
    }

    addValidator(option: Option) {
        this.expandeds.push(true);
        const validator = FormEditorFunctions.emptyValidatorByType(option.identifier as ValidatorType);
        this.validators.push(this.fb.buildValidatorControl(validator));
    }

    async removeValidator(index: number) {
        if (!await this.dialogs.confirmDelete()) {
            return;
        }

        this.expandeds.splice(index, 1);
        this.validators.removeAt(index);
    }

    getValidatorLiteral(validator: UfControlGroup): ValidatorLiteral {
        const type = validator.get(ValidatorControlKeys.Type)?.value as ValidatorType;
        return this.service.validatorLiteralsByType(type);
    }
}