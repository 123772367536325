import { NodeArgsComponent } from './node-details/node-args.component';
import { NodeContentInfoComponent } from './node-details/node-content-info.component';
import { NodeDashboardTablesComponent } from './node-details/node-dashboard-tables.component';
import { NodeDetailsComponent } from './node-details/node-details.component';
import { NodeRolesComponent } from './node-details/node-roles.component';
import { NodeSettingsComponent } from './node-details/node-settings.component';
import { NodeVariationsComponent } from './node-details/node-variations.component';
import { NodeFieldComponent } from './node-field.component';
import { StructureFunctions } from './structure-functions';
import { StructureNodeArgControlValue } from './structure-model';
import { StructureComponent } from './structure.component';


const STRUCTURE_COMPONENTS = [
    StructureComponent,
    NodeFieldComponent,
    NodeDetailsComponent,
    NodeContentInfoComponent,
    NodeSettingsComponent,
    NodeRolesComponent,
    NodeDashboardTablesComponent,
    NodeArgsComponent,
    NodeVariationsComponent
];

export {
    StructureFunctions,
    STRUCTURE_COMPONENTS,
    StructureComponent,
    NodeFieldComponent,
    NodeDetailsComponent,
    NodeContentInfoComponent,
    NodeSettingsComponent,
    NodeRolesComponent,
    NodeDashboardTablesComponent,
    NodeArgsComponent,
    NodeVariationsComponent,
    StructureNodeArgControlValue
};