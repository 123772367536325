import { Injectable } from '@angular/core';
import {
    DataPropertyDescriptor, DataSourceMappingDisplayAllowedDataTypes, SortStatus, UfControlGroup, UfFormBuilder, ValidatorFunctions
} from '@unifii/library/common';
import {
    DataSourceOutputMap, DataSourceType, Dictionary, FieldType, generateUUID, IntegrationArgument, OutputDescriptor, OutputField
} from '@unifii/sdk';

import { DataSourceInputValueSource, UcDataSource } from 'client';

import { IdentifierFunctions } from 'helpers/helpers';

import { DataSourceDisplayTo, DataSourceIdTo, hasFindBy, hasSort, isValueExpression } from './data-source-editor-functions';
import { DataSourceEditorService } from './data-source-editor-service';
import { DataSourceEditorStatus } from './data-source-editor-status';
import {
    DataSourceControlKeys, DataSourceExternalInput, DataSourceExternalInputControlKey, DataSourceFormModel, DataSourceMapping,
    DataSourceMappingControlKeys, idBasedDSTypes
} from './data-source-model';


@Injectable()
export class DataSourceFormCtrl {

    constructor(
        private fb: UfFormBuilder,
        private service: DataSourceEditorService,
        private status: DataSourceEditorStatus
    ) { }

    async buildRoot(model: DataSourceFormModel, dataCaptures: string[]) {

        const typeControl = this.fb.control(model.type, ValidatorFunctions.required('A type is needed.'));

        const resourceControl = this.fb.control(model.resource, ValidatorFunctions.custom(v =>
            !typeControl.value ||
            !ValidatorFunctions.isEmpty(v) ||
            !idBasedDSTypes.includes(typeControl.value),
            'Select one'),
            undefined, { deps: [typeControl] }
        );

        const findByControl = this.fb.control(
            model.findBy,
            ValidatorFunctions.compose([
                // valid if invalid data source, or is empty with no data source, or not empty with data source
                ValidatorFunctions.custom(v => !hasFindBy(typeControl.value) || ValidatorFunctions.isEmpty(v) === !dataCaptures.length, 'A findBy target is required.'),
                ValidatorFunctions.custom(v => {
                    if (!hasFindBy(typeControl.value) || ValidatorFunctions.isEmpty(v)) {
                        return true;
                    }

                    return (this.status.findByProperties).find(p => p.identifier === v) != null;
                }, `This field is not available for data capture`)
            ]),
            undefined,
            { deps: [typeControl] }
        );

        if (!hasFindBy(typeControl.value) || !dataCaptures.length) {
            findByControl.disable();
        }

        const sortControl = this.fb.control(
            model.sort,
            ValidatorFunctions.compose([
                ValidatorFunctions.custom(v => !hasSort(typeControl.value) || !ValidatorFunctions.isEmpty(v), 'A sort is required.'),
                ValidatorFunctions.custom(v => {
                    if (!hasSort(typeControl.value || ValidatorFunctions.isEmpty(v))) {
                        return true;
                    }
                    const sortAttribute = SortStatus.fromString(v)?.name;
                    return (this.status.sortableProperties).find(p => p.identifier === sortAttribute) != null;
                }, `This field is not available for sorting`)
            ]),
            undefined,
            { deps: [typeControl] }
        );

        const group = this.fb.group({
            [DataSourceControlKeys.Type]: typeControl,
            [DataSourceControlKeys.Resource]: resourceControl,
            [DataSourceControlKeys.NamedId]: this.fb.control(model.namedId, ValidatorFunctions.compose([
                ValidatorFunctions.custom(v => !typeControl.value || !ValidatorFunctions.isEmpty(v) || typeControl.value !== DataSourceType.Named, 'This field is required'),
                ValidatorFunctions.pattern(/^[A-Za-z0-9_-]+$/, 'Special characters are not allowed'),
            ]), undefined, { deps: [typeControl] }),
            [DataSourceControlKeys.Sort]: sortControl,
            [DataSourceControlKeys.FindBy]: findByControl,
            [DataSourceControlKeys.VisibleFilters]: this.fb.control(model.visibleFilters ?? []),
            [DataSourceControlKeys.Filter]: model.filter,
            [DataSourceControlKeys.AdvancedFilter]: model.advancedFilter,
            [DataSourceControlKeys.Mappings]: this.fb.array(model.mappings.map(m => this.buildMappingControl(m))),
            [DataSourceControlKeys.ExternalInputs]: this.fb.array(model.externalInputs.map(ei => this.buildExternalInputControl(ei)))
        });

        return group;
    }

    buildExternalInputControl(externalInput: DataSourceExternalInput): UfControlGroup {

        const valueControl = this.fb.control(externalInput.value,
            ValidatorFunctions.custom(v => !externalInput.info.required || !ValidatorFunctions.isEmpty(v), 'Required'));
        // Need the error to be visible immediately
        valueControl.markAsTouched();

        return this.fb.group({
            [DataSourceExternalInputControlKey.Info]: externalInput.info,
            [DataSourceExternalInputControlKey.Value]: valueControl
        });
    }

    buildMappingControl(mapping: DataSourceMapping): UfControlGroup {

        const isFromExpressionCtrl = this.fb.control(mapping.isFromExpression);

        const fromExpressionCtrl = this.fb.control(
            mapping.fromExpression,
            ValidatorFunctions.compose([
                ValidatorFunctions.custom(v => isFromExpressionCtrl.value !== true || !ValidatorFunctions.isEmpty(v), 'Required'),
                ValidatorFunctions.custom(v => !v || isValueExpression(v), 'Invalid expression'),
            ]),
            undefined,
            { deps: [isFromExpressionCtrl] }
        );

        const toCtrl = this.fb.control(mapping.to, ValidatorFunctions.compose([
            ValidatorFunctions.required('Required'),
            ValidatorFunctions.custom(v => !v || v.length < IdentifierFunctions.WARNING_IDENTIFIER_MAX_LENGTH, 'Too long')
        ]));
        if ([DataSourceIdTo, DataSourceDisplayTo].includes(mapping.to)) {
            toCtrl.disable();
        }

        const typeControl = this.fb.control(mapping.type, ValidatorFunctions.required('Required'));
        // Need the error to be visible immediately
        typeControl.markAsTouched();

        const fromCtrl = this.fb.control(
            mapping.from,
            ValidatorFunctions.compose([
                ValidatorFunctions.custom(v => isFromExpressionCtrl.value === true || !ValidatorFunctions.isEmpty(v), 'Required'),
                // A DataPropertyDescriptor value with an asDisplay false identify a missing property from the DataDescriptor
                ValidatorFunctions.custom(v =>
                    !v || isFromExpressionCtrl.value === true || (v as DataPropertyDescriptor).asDisplay === true,
                    'Missing'
                ),
                // For to === '_display' only a set of FieldType are allowed
                ValidatorFunctions.custom(v =>
                    !v ||
                    toCtrl.value !== DataSourceDisplayTo ||
                    isFromExpressionCtrl.value === true ||
                    DataSourceMappingDisplayAllowedDataTypes.includes(typeControl.value),
                    `Data Type not allowed`)
            ]),
            undefined,
            { deps: [isFromExpressionCtrl, toCtrl, typeControl] }
        );

        if (mapping.to === DataSourceIdTo) {
            fromCtrl.disable();
        }

        const isVisibleControl = this.fb.control(mapping.isVisible);
        if (mapping.type == null) {
            isVisibleControl.disable();
        }

        const itemTemplateCtrl = this.fb.control(mapping.itemTemplate, ValidatorFunctions.custom(v =>
            typeControl.value !== FieldType.Repeat || !ValidatorFunctions.isEmpty(v),
            'An item template is required'
        ));

        const hideEmptyControl = this.fb.control(mapping.hideEmpty);
        if (!mapping.isVisible) {
            hideEmptyControl.disable();
            itemTemplateCtrl.disable();
        }

        const group = this.fb.group({
            [DataSourceMappingControlKeys.Uuid]: mapping.uuid,
            [DataSourceMappingControlKeys.IsFromExpression]: isFromExpressionCtrl,
            [DataSourceMappingControlKeys.FromExpression]: fromExpressionCtrl,
            [DataSourceMappingControlKeys.From]: fromCtrl,
            [DataSourceMappingControlKeys.Type]: typeControl,
            [DataSourceMappingControlKeys.To]: toCtrl,
            [DataSourceMappingControlKeys.Label]: [mapping.label, ValidatorFunctions.required('A label is required')],
            [DataSourceMappingControlKeys.ItemTemplate]: itemTemplateCtrl,
            [DataSourceMappingControlKeys.IsVisible]: isVisibleControl,
            [DataSourceMappingControlKeys.HideEmpty]: hideEmptyControl,
            [DataSourceMappingControlKeys.IsReportable]: mapping.isReportable
        });

        return group;
    }

    async mapDataToControlValue(ds?: UcDataSource): Promise<DataSourceFormModel> {

        const cloned = (ds ? JSON.parse(JSON.stringify(ds)) : {}) as UcDataSource;

        let mappings: DataSourceMapping[] = [];
        if (cloned.outputs) {
            const outputs = cloned.outputs as DataSourceOutputMap;
            mappings = Object.keys(outputs).map(to => {

                const dataFrom = outputs[to];
                const outputField = cloned.outputFields ? cloned.outputFields[to] : {} as OutputField;
                const descriptor = cloned.outputDescriptors?.find(d => d.output === to);

                const from = this.status.mappableProperties.find(p => p.identifier === dataFrom) ??
                    // use asDisplay false to flag it as a missing property
                    { identifier: dataFrom, display: dataFrom, asDisplay: false } as DataPropertyDescriptor;

                const label: string = outputField.label ?? from.label;
                let type: FieldType = outputField.type ?? from.type;
                const isFromExpression = isValueExpression(dataFrom);
                let fromExpression: string | undefined;

                if (to === DataSourceDisplayTo && isFromExpression) {
                    fromExpression = dataFrom;
                    type = FieldType.Text;
                }

                return {
                    uuid: generateUUID(),
                    isFromExpression,
                    from,
                    fromExpression,
                    type,
                    to,
                    label,
                    itemTemplate: descriptor?.itemTemplate,
                    isVisible: descriptor != null,
                    hideEmpty: descriptor?.hideEmpty ?? false,
                    isReportable: outputField.isReportable
                };
            });
        }

        let externalInputs: DataSourceExternalInput[] = [];
        if (this.status.externalInfo?.feature.inputArgs) {
            externalInputs = this.status.externalInfo.feature.inputArgs.map(arg => {
                const input = this.status.externalInfo?.dataSource.inputMap[arg.identifier];
                const ei: DataSourceExternalInput = {
                    info: {
                        parameter: arg.identifier,
                        type: arg.type,
                        source: input?.source as DataSourceInputValueSource,
                        required: input?.source === DataSourceInputValueSource.Default ? false : arg.isRequired,
                        placeholder: input?.source === DataSourceInputValueSource.Default ? `\\${input?.value}\\` : undefined
                    },
                    value: ds?.inputs ? ds.inputs[arg.identifier] : undefined
                };
                return ei;
            });
        }

        // Parse sort with consideration to the deprecated retro-compatible sortDirection attribute
        const sort = SortStatus.fromString(cloned.sort);
        if (sort && cloned.sortDirection) {
            sort.direction = cloned.sortDirection;
        }

        const formModel: DataSourceFormModel = {
            type: cloned.type ?? null,
            resource: await this.service.loadResource(cloned.type, cloned.id),
            namedId: cloned.id ?? null,
            mappings,
            externalInputs,
            visibleFilters: cloned.visibleFilters,
            filter: cloned.filter,
            advancedFilter: cloned.advancedFilter,
            sort: sort?.toString(),
            findBy: cloned.findBy
        };

        return formModel;
    }

    async mapControlValueToData(model: DataSourceFormModel): Promise<UcDataSource> {

        const id: string | undefined = (
            model.type === DataSourceType.Named ?
                model.namedId :
                model.resource?.identifier
        ) ?? undefined;

        let outputs: DataSourceOutputMap | undefined;
        let outputFields: Dictionary<OutputField> | undefined;
        let outputDescriptors: OutputDescriptor[] | undefined;
        let inputs: Dictionary<string> | undefined;
        let inputArgs: IntegrationArgument[] | undefined;

        // Mappings
        if (model.mappings.length) {

            outputs = model.mappings.reduce((incrementalOutputs, mapping) => {
                if (mapping.isFromExpression) {
                    incrementalOutputs[mapping.to] = mapping.fromExpression as string;
                } else {
                    incrementalOutputs[mapping.to] = (mapping.from as DataPropertyDescriptor).identifier;
                }
                return incrementalOutputs;
            }, {} as DataSourceOutputMap);

            outputFields = model.mappings.reduce((incrementalOutputFields, mapping) => {
                incrementalOutputFields[mapping.to] = {
                    type: mapping.type,
                    label: mapping.label,
                    isReportable: mapping.isReportable ?? false
                };
                return incrementalOutputFields;
            }, {} as Dictionary<OutputField>);

            outputDescriptors = model.mappings.filter(mapping => mapping.isVisible).map(mapping => ({
                output: mapping.to,
                hideEmpty: mapping.hideEmpty ?? undefined,
                // TODO remove label and type once backend accept them as undefined
                label: mapping.label,
                type: mapping.type,
                itemTemplate: mapping.type === FieldType.Repeat ? mapping.itemTemplate : undefined,
            }));
        }

        const valorizedExternalInputs = model.externalInputs.filter(ei => (ei.value ?? '').trim().length > 0);
        if (valorizedExternalInputs.length) {
            inputs = {};
            inputArgs = [];
            for (const ei of valorizedExternalInputs) {
                inputs[ei.info.parameter] = ei.value as string;
                inputArgs.push({
                    identifier: ei.info.parameter,
                    type: ei.info.type,
                    isRequired: ei.info.required
                });
            }
        }

        const result: UcDataSource = {
            type: model.type as DataSourceType,
            id,
            visibleFilters: model.visibleFilters,
            filter: model.filter,
            advancedFilter: model.advancedFilter,
            sort: model.sort,
            findBy: model.findBy,
            outputs,
            outputFields,
            outputDescriptors,
            inputs,
            inputArgs
        };

        return result;
    }
}