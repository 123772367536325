import { Component, HostBinding } from '@angular/core';
import { TableContainerManager } from '@unifii/components';
import { DataLoaderFactory, FilterEntries } from '@unifii/library/common';

import { BucketDataTableManager } from 'pages/form-data/bucket-data-table-manager';

import { BucketDataFilterFactory } from './bucket-filter-factory';


const filterEntryFactory = (factory: BucketDataFilterFactory) => factory.create();

@Component({
    template: `
        <div ufMasterDetail>
            <uf-table-container ufSyncRoute class="accent pad-none list-md"></uf-table-container>
        </div>
        <router-outlet></router-outlet>
    `,
    providers: [
        DataLoaderFactory,
        BucketDataFilterFactory,
        { provide: FilterEntries, useFactory: filterEntryFactory, deps: [BucketDataFilterFactory] },
        { provide: TableContainerManager, useClass: BucketDataTableManager }
    ]
})
export class BucketDataComponent {

    @HostBinding('class.stretch-component') class = true;

}
