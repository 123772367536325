import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { DataPropertyDescriptor, UfControlValueAccessor } from '@unifii/library/common';


@Component({
    selector: 'uc-find-by-property-input',
    templateUrl: 'find-by-property-input.html',
    providers: [{
        provide: NG_VALUE_ACCESSOR, useExisting: FindByPropertyInputComponent, multi: true
    }]
})
export class FindByPropertyInputComponent extends UfControlValueAccessor<string> implements OnInit {

    @Input() label: string;
    @Input() properties: DataPropertyDescriptor[];
    @Output() valueChange = new EventEmitter<string>();

    findByProperty: DataPropertyDescriptor | null;
    findByResults: DataPropertyDescriptor[];

    ngOnInit() {
        this.findByProperty = this.value ? this.properties.find(c => c.identifier === this.value) || null : null;
    }

    encodeFindBy() {
        this.value = this.findByProperty?.identifier;
        this.control.markAsTouched();
        this.valueChange.emit(this.value);
    }

    reset() {
        this.value = undefined;
        this.findByProperty = null;
        this.control.reset();
        this.valueChange.emit(this.value);
    }

    async search(q?: string) {
        this.findByResults = this.properties.filter(dp => {
            if (q && q.trim().length) {
                return (dp.display as string).toLowerCase().indexOf(q.toLowerCase()) > -1;
            }
            return true;
        });
    }
}