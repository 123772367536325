import { Component, HostBinding, Inject } from '@angular/core';
import { Modal, ModalData, ModalRuntime, UfControl, UfControlArray, UfControlGroup, ValidatorFunctions } from '@unifii/library/common';
import { Option, PermissionAction } from '@unifii/sdk';

import { Permission, Resource, ResourceElement, ResourceType, UcResources } from 'client';

import { ArrayHelper } from 'helpers/helpers';


export interface PermissionEditorData {
    permission: Permission;
    resources: Resource;
    readonly?: boolean;
}

interface Step {
    resource: Resource;
    isStatic: boolean;
    options: Option[];
    fields?: string[];
    value?: Option;
}

@Component({
    templateUrl: './permission-editor.html',
    styleUrls: ['./permission-editor.less'],
})
export class PermissionEditorComponent implements Modal<PermissionEditorData, Permission> {

    @HostBinding('class.uc-form-card') class = true;

    readonly segmentId = ':id';
    readonly anycard = '?';
    readonly wildcard = '*';
    readonly endOfPath = '';
    readonly resourceTypes = ResourceType;
    readonly optionAll: Option = { identifier: this.wildcard, name: 'All Descendants' };
    readonly optionEndOfPath: Option = { identifier: this.endOfPath, name: 'End of Path' };
    readonly operatorOptions: Option[] = [
        { identifier: '=', name: 'Equal' },
        { identifier: 'descs', name: 'Descendants' },
        { identifier: 'in', name: 'In' }
    ];

    isReadOnly: boolean;
    ready: boolean;
    title: string;

    form: UfControlGroup;
    actionsControl: UfControlArray;
    pathsControl: UfControlArray;
    conditionControl: UfControl;

    permission: Permission;
    resources: Resource;
    steps: Step[] = [];
    availableActions: { name: PermissionAction; descriptions: string[]; value?: boolean }[] = [];
    fieldsResult: string[];
    meFields: string[];
    userFields: string[];
    companyFields: string[];

    private projectId: number;
    private collectionIdentifier: string;
    private bucketId: string;

    constructor(
        public runtime: ModalRuntime<PermissionEditorData, Permission>,
        @Inject(ModalData) public data: PermissionEditorData,
        private ucResources: UcResources
    ) {
        this.init();
    }

    get lastStep(): Step | null {
        return this.steps.length ? this.steps[this.steps.length - 1] : null;
    }

    get path(): string[] {
        return (this.steps
            .filter(step => step.value?.identifier !== '' && step.value != null)
            .map(step => (step.value as Option).identifier)) as string[];
    }

    get urlPath(): string {
        return this.path.join('/');
    }

    get actions(): PermissionAction[] {
        return this.availableActions.filter(a => a.value === true).map(a => a.name);
    }

    get hasEditAction(): boolean {
        const editActions = [PermissionAction.Add, PermissionAction.Invite, PermissionAction.Update];
        return this.actions.find(action => editActions.includes(action)) != null;
    }

    async stepChanged(value: Option | null, index: number) {

        // console.log(`Step ${index} changed to ${value?.name}(${value?.identifier})`);

        // Remove followings steps and controls
        this.steps = this.steps.slice(0, index + 1);
        while ((index + 1) < this.pathsControl.length) {
            this.pathsControl.removeAt(this.pathsControl.length - 1);
        }

        this.setStepValue(value ?? undefined);

        if (value) {
            // Build next step
            const step = await this.getNextStep();
            this.addStep(step);
        }

        // Clean and update actions
        this.permission.actions = [];
        delete this.permission.fields;
        this.availableActions = this.getStepActions();
        this.actionsControl.setSubmitted(false);

        // Clean condition
        this.conditionControl.setValue(null, { onlySelf: true, emitEvent: false });
    }

    findFields(q?: string) {
        const fields = this.lastStep?.fields ?? [];

        this.fieldsResult = (q != null && q.trim().length > 0) ? fields.filter(f => f.indexOf(q as string) >= 0) : [...fields];
    }

    save() {
        this.form.setSubmitted();
        if (this.form.valid) {
            this.permission.path = this.path;
            this.permission.condition = (this.conditionControl.value ?? '').length > 0 ? this.conditionControl.value.trim() : undefined;
            this.permission.actions = this.actions;
            if (this.permission.fields?.length === 0) {
                delete this.permission.fields;
            }
            this.runtime.close(this.permission);
        }
    }

    close() {
        this.runtime.close();
    }

    async searchStepOptions(step: Step, q?: string) {
        step.options = await this.getStepOptions(step.resource, q);
    }

    get fieldsFieldLabel(): string {
        switch (this.lastStep?.resource.name) {
            case ResourceType.Companies:
            case ResourceType.Company:
                return 'Editable Company Fields';
            case ResourceType.Users:
            case ResourceType.User:
            case ResourceType.Me:
                return 'Editable User Fields';
            default:
                return 'Editable Fields';
        }
    }

    private async init() {

        // TODO UNIFII-6412 START Remove hack once the Resources tree will not contains company-claims anymore
        this.data.resources.children = this.data.resources.children?.filter(r => r.segment !== 'company-claims');


        this.resources = this.data.resources;
        this.permission = Object.assign({ path: [], actions: [] }, this.data.permission);
        this.isReadOnly = this.data.readonly === true;
        this.title = this.isReadOnly ? 'View Permission' : this.permission.path == null ? 'Add Permission' : 'Edit Permission';
        this.meFields = await this.ucResources.getMeFields();
        this.userFields = await this.ucResources.getUsersFields();
        this.companyFields = await this.ucResources.getCompaniesFields();

        this.buildControls();
        await this.parse();

        this.ready = true;
    }

    private buildControls() {

        this.pathsControl = new UfControlArray([]);
        this.actionsControl = new UfControlArray([], undefined, ValidatorFunctions.custom(() => !this.availableActions.length || this.actions.length > 0, 'Select at least one action'));
        this.conditionControl = new UfControl();

        this.form = new UfControlGroup({
            paths: this.pathsControl,
            actions: this.actionsControl,
            condition: this.conditionControl
        });

        if (this.isReadOnly) {
            this.form.disable();
        }
    }

    private async getResourceOption(resource: Resource, id: string): Promise<Option> {

        if (id === this.optionEndOfPath.identifier) {
            return this.optionEndOfPath;
        }

        if (id === this.optionAll.identifier) {
            return this.optionAll;
        }

        const matchStaticOption = this.staticChildrenOption(resource).find(o => o.identifier === id);
        if (matchStaticOption || this.hasStaticOptions(resource)) {
            return matchStaticOption as Option;
        }

        try {
            switch (resource.name) {
                case ResourceType.Projects:
                    return this.mapToOption(await this.ucResources.getProject(+id));
                case ResourceType.Collections:
                    return this.mapToOption(await this.ucResources.getCollection(this.projectId, id));
                case ResourceType.CollectionItems:
                    return this.mapToOption(await this.ucResources.getCollectionItem(this.projectId, this.collectionIdentifier, id));
                case ResourceType.Views:
                    return this.mapToOption(await this.ucResources.getView(this.projectId, id));
                case ResourceType.Pages:
                    return this.mapToOption(await this.ucResources.getPage(this.projectId, id));
                case ResourceType.Forms:
                    return this.mapToOption(await this.ucResources.getForm(this.projectId, id));
                case ResourceType.FormDataRepositories:
                    return this.mapToOption(await this.ucResources.getBucket(this.projectId, id));
                case ResourceType.Tables:
                    return this.mapToOption(await this.ucResources.getTable(this.projectId, id));
                case ResourceType.ExternalDataSources:
                    return this.mapToOption(await this.ucResources.getExternalDataSource(this.projectId, id));
                case ResourceType.Companies:
                    return this.mapToOption(await this.ucResources.getCompany(id));
                case ResourceType.CompanyClaims:
                    return this.mapToOption(await this.ucResources.getCompanyClaim(id));
                case ResourceType.UserClaims:
                    return this.mapToOption(await this.ucResources.getUserClaim(id));
                case ResourceType.Users:
                    return this.mapToOption(await this.ucResources.getUser(id));
                default:
                    return { identifier: id, name: id };
            }

        } catch (e) {
            // fallback
            return { identifier: id, name: id };
        }
    }

    private async parse() {

        const path = this.permission.path ?? [];
        path.push('');
        // console.log(`Parse path "${this.permission.path}" into parts`, path);

        this.steps = [];
        for (const value of path) {

            const step = await this.getNextStep();

            if (step) {
                this.addStep(step);
                const option = await this.getResourceOption(step.resource, value);
                this.setStepValue(option);
                this.pathsControl.at(this.pathsControl.length - 1).markAsTouched();
            }
        }

        // Actions
        const actions = this.getStepActions();
        for (const action of actions) {
            action.value = (this.permission.actions as string[]).indexOf(action.name) >= 0;
        }
        this.availableActions = actions;

        // Condition
        if (this.permission.condition) {
            this.conditionControl.setValue(this.permission.condition, { emitEvent: false, onlySelf: true });
        }

        // Fields
        if (this.permission.fields != null) {
            if (!this.lastStep?.fields) {
                delete this.permission.fields;
            }
            this.permission.fields = (this.permission.fields ?? []).filter(f => this.lastStep?.fields?.includes(f));
        }
    }

    private setStepValue(value?: Option) {

        if (!this.lastStep) {
            return;
        }

        this.lastStep.value = value;

        // No further actions needed for a terminator
        if (this.lastStep.value?.identifier === '' || this.lastStep.value?.identifier === this.wildcard) {
            return;
        }

        // No further actions nedded for a static segment
        const staticSegment = (this.lastStep.resource.children || [])
            .filter(c => c.segment !== this.segmentId)
            .find(c => c.segment === value?.identifier);

        if (staticSegment) {
            return;
        }

        if (!this.lastStep.value) {
            return;
        }

        switch (this.lastStep.resource.name) {
            case ResourceType.Projects:
                this.projectId = +(this.lastStep.value.identifier);
                break;
            case ResourceType.Collections:
                this.collectionIdentifier = this.lastStep.value.identifier;
                break;
            case ResourceType.FormDataRepositories:
                this.bucketId = this.lastStep.value.identifier;
                break;
        }
    }

    private getFields(resource: Resource): string[] | undefined {
        switch (resource.name) {
            case ResourceType.Companies:
            case ResourceType.Company:
                return this.companyFields;
            case ResourceType.Users:
            case ResourceType.User:
                return this.userFields;
            case ResourceType.Me:
                return this.meFields;
            default:
                return;
        }
    }

    private hasStaticOptions(resource: Resource): boolean {
        switch (resource.name) {
            case ResourceType.Projects:
            case ResourceType.Collections:
            case ResourceType.CollectionItems:
            case ResourceType.Views:
            case ResourceType.Pages:
            case ResourceType.Forms:
            case ResourceType.FormDataRepositories:
            case ResourceType.ExternalDataSources:
            case ResourceType.Tables:
            case ResourceType.Users:
            case ResourceType.Companies:
            case ResourceType.CompanyClaims:
            case ResourceType.UserClaims:
                return false;
            default:
                return true;
        }
    }

    /** Based on lastStep (resource and value) find the next  */
    private async getNextStep(): Promise<Step | null> {

        if (this.lastStep?.value?.identifier === this.endOfPath || this.lastStep?.value?.identifier === this.wildcard) {
            return null;
        }

        let resource: Resource | undefined;

        if (this.lastStep == null) {
            resource = this.resources;
        } else {
            const resourceChildren = this.lastStep.resource.children || [];
            const matchingChild = resourceChildren.find(c => c.segment === (this.lastStep as Step).value?.identifier);
            const fallbackChild = resourceChildren.length > 0 ? resourceChildren[0] : undefined;
            resource = matchingChild ?? fallbackChild;
        }

        if (!resource) {
            return null;
        }

        const isStatic = this.hasStaticOptions(resource);
        const options = (await this.getStepOptions(resource)) ?? [];
        const value = isStatic && options.length > 0 ? options[0] : null;

        return {
            resource,
            isStatic,
            options,
            value: value ?? undefined,
            fields: this.getFields(resource)
        };
    }

    private addStep(step: Step | null) {
        if (!step) {
            return;
        }

        this.pathsControl.push(new UfControl(
            ValidatorFunctions.custom(v => v != null, 'Select an option')
        ));

        this.steps.push(step);
    }

    private staticChildrenOption(resource: Resource): Option[] {

        if (!resource.children) {
            return [];
        }

        return resource.children.map(child => {
            if (child.segment === this.anycard) {
                return { identifier: child.segment, name: `Any ${child.name}` };
            }
            return { identifier: child.segment, name: child.name };
        }).sort((a, b) => a.name > b.name ? 1 : -1);
    }

    private async getStepOptions(resource: Resource, query?: string): Promise<Option[]> {
        const hasAny = this.steps.find(s => s.value?.identifier === this.anycard);
        const staticOptions = this.staticChildrenOption(resource);

        const terminatorOptions = [this.optionEndOfPath, this.optionAll];
        if (!resource.children || !resource.children.length ||
            (resource.children.length === 1 && resource.children[0].segment === this.anycard && (!resource.children[0].children || !(resource.children[0].children).length))
        ) {
            // Remove 'All' when the resource is a leaf
            terminatorOptions.pop();
        }

        switch (resource.name) {

            case ResourceType.Resources:
                // Root resource has no terminators
                return staticOptions;

            case ResourceType.Project:
            case ResourceType.Company:
            case ResourceType.Collection:
            case ResourceType.CollectionItem:
            case ResourceType.View:
            case ResourceType.FormDataRepository:
            case ResourceType.FormDataRepositoryDocuments:
            case ResourceType.User:
            case ResourceType.TicketProviders:
            case ResourceType.TicketProviderIntegration:
            case ResourceType.CompanyClaim:
            case ResourceType.UserClaim:
            case ResourceType.Units:
                return [...terminatorOptions, ...staticOptions];

            case ResourceType.Projects:
                const projects = hasAny ? [] : (await this.ucResources.getProjects(query)).map(this.mapToOption);
                return [...terminatorOptions, ...staticOptions, ...projects];

            case ResourceType.Collections:
                const collections = hasAny ? [] : (await this.ucResources.getCollections(this.projectId, query)).map(this.mapToOption);
                return [...terminatorOptions, ...staticOptions, ...collections];

            case ResourceType.CollectionItems:
                const collectionItems = hasAny ? [] : (await this.ucResources.getCollectionItems(this.projectId, this.collectionIdentifier, query)).map(this.mapToOption);
                return [...terminatorOptions, ...staticOptions, ...collectionItems];

            case ResourceType.Views:
                const views = hasAny ? [] : (await this.ucResources.getViews(this.projectId, query)).map(this.mapToOption);
                return [...terminatorOptions, ...staticOptions, ...views];

            case ResourceType.Pages:
                const pages = hasAny ? [] : (await this.ucResources.getPages(this.projectId, query)).map(this.mapToOption);
                return [...terminatorOptions, ...staticOptions, ...pages];

            case ResourceType.Forms:
                const forms = hasAny ? [] : (await this.ucResources.getForms(this.projectId, query)).map(this.mapToOption);
                return [...terminatorOptions, ...staticOptions, ...forms];

            case ResourceType.FormDataRepositories:
                const buckets = hasAny ? [] : (await this.ucResources.getBuckets(this.projectId, query)).map(this.mapToOption);
                return [...terminatorOptions, ...staticOptions, ...buckets];

            case ResourceType.ExternalDataSources:
                const dataSources = hasAny ? [] : (await this.ucResources.getExternalDataSources(this.projectId, query)).map(this.mapToOption);
                return [...terminatorOptions, ...staticOptions, ...dataSources];

            case ResourceType.Tables:
                const tables = hasAny ? [] : (await this.ucResources.getTables(this.projectId, query)).map(this.mapToOption);
                return [...terminatorOptions, ...staticOptions, ...tables];

            case ResourceType.Users:
                const users = hasAny ? [] : (await this.ucResources.getUsers(query)).map(this.mapToOption);
                return [...terminatorOptions, ...staticOptions, ...users];

            case ResourceType.Companies:
                const companies = hasAny ? [] : (await this.ucResources.getCompanies(query)).map(this.mapToOption);
                return [...terminatorOptions, ...staticOptions, ...companies];

            case ResourceType.CompanyClaims:
                const companyClaims = hasAny ? [] : (await this.ucResources.getCompanyClaims(query)).map(this.mapToOption);
                return [...terminatorOptions, ...staticOptions, ...companyClaims];

            case ResourceType.UserClaims:
                const defaultClaims = hasAny ? [] : (await this.ucResources.getUserClaims(query)).map(this.mapToOption);
                return [...terminatorOptions, ...staticOptions, ...defaultClaims];

            default:
                // Leaf resources
                return terminatorOptions;
        }
    }

    // LastStep resource actions as options, when lastStep is 'All' merge the subtree actions
    private getStepActions(): { name: PermissionAction; descriptions: string[]; value: boolean }[] {

        if (!this.lastStep) {
            return [];
        }

        // console.log('actions for', this.lastStep);

        let resources: Resource[];

        if (this.lastStep.value?.identifier === this.wildcard) {
            // children actions
            resources = ArrayHelper.flatTree(this.lastStep.resource);
            resources.shift();
        } else {
            resources = [this.lastStep.resource];
        }

        const results: { name: PermissionAction; descriptions: string[]; value: boolean }[] = [];
        for (const res of resources) {
            for (const action of res.actions || []) {
                let existing = results.find(a => a.name === action.name);
                if (!existing) {
                    existing = { name: action.name, descriptions: [], value: false };
                    results.push(existing);
                }
                existing.descriptions.push(action.description);
            }
        }

        this.actionsControl.clear();
        for (const _ of results) {
            this.actionsControl.push(new UfControl());
        }

        return results;
    }

    private mapToOption(resource: ResourceElement): Option {
        return { identifier: resource.id, name: resource.name };
    }

}
