import { Injectable } from '@angular/core';

import { ClaimsClient } from './claims-client';
import { ClaimSource } from './models';
import { UcClient } from './uc-client';


@Injectable({ providedIn: 'root' })
export class UcUserClaims extends ClaimsClient {

    constructor(client: UcClient) {
        super(client, ClaimSource.User);
    }
}
