import { Component, Input } from '@angular/core';
import { ClipboardService, DataPropertyDescriptor, ModalService, UfControlArray, UfControlGroup } from '@unifii/library/common';
import { generateUUID } from '@unifii/sdk';

import { DialogsService } from 'services/dialogs.service';

import { DataSourceDisplayTo, DataSourceIdTo, getAttribute } from './data-source-editor-functions';
import { DataSourceFormCtrl } from './data-source-form-ctrl';
import { DataSourceMappingEditorComponent, DataSourceMappingEditorData } from './data-source-mapping-editor.component';
import { DataSourceMappingsReorderComponent } from './data-source-mappings-reorder.component';
import { DataSourceMapping, DataSourceMappingControlKeys } from './data-source-model';


@Component({
    selector: 'uc-data-source-mappings',
    templateUrl: './data-source-mappings.html'
})
export class DataSourceMappingsComponent {

    @Input() fieldIdentifier: string | undefined;
    @Input() mappings: UfControlArray;
    @Input() properties: DataPropertyDescriptor[];

    readonly mappingKeys = DataSourceMappingControlKeys;

    constructor(
        private formCtrl: DataSourceFormCtrl,
        private modalService: ModalService,
        private clipboard: ClipboardService,
        private dialogs: DialogsService
    ) { }

    async add() {
        const control = this.formCtrl.buildMappingControl({
            uuid: generateUUID(),
            type: null as any,
            to: null as any,
            label: null as any
        });
        this.mappings.push(control);
        control.updateDependencies();
        this.mappings.updateDependencies();

        const result = await this.openEditor(control);

        if (!result) {
            this.mappings.removeAt(this.mappings.length - 1);
            this.mappings.updateDependencies();
            this.mappings.updateValueAndValidity();
        }
    }

    async edit(index: number) {
        const originalControl = this.mappings.at(index) as UfControlGroup;
        const editControl = this.formCtrl.buildMappingControl(originalControl.getRawValue() as DataSourceMapping);
        this.mappings.setControl(index, editControl);
        this.mappings.updateDependencies();
        this.mappings.updateValueAndValidity();

        const result = await this.openEditor(editControl);

        if (!result) {
            this.mappings.setControl(index, originalControl);
            this.mappings.updateDependencies();
            this.mappings.updateValueAndValidity();
        }
    }

    async remove(index: number, event: Event) {

        event.stopPropagation();

        if (!await this.dialogs.confirmDelete()) {
            return;
        }

        this.mappings.removeAt(index);
        this.mappings.updateDependencies();
    }

    async openReorder() {

        const reordableControls = (this.mappings.controls as UfControlGroup[])
            .filter(c => ![DataSourceIdTo, DataSourceDisplayTo].includes(c.get(DataSourceMappingControlKeys.To)?.value));

        const result = await this.modalService.openMedium(
            DataSourceMappingsReorderComponent,
            reordableControls.map(c => ({
                identifier: c.get(DataSourceMappingControlKeys.Uuid)?.value as string,
                name: c.get(DataSourceMappingControlKeys.Label)?.value as string
            }))
        );

        if (!result) {
            return;
        }

        // Remove original controls
        while (this.mappings.length > 2) {
            this.mappings.removeAt(2);
        }

        // Add reordered controls
        for (const uuid of result) {
            this.mappings.push(reordableControls.find(c => c.value.uuid === uuid));
        }
    }

    getFromCellValue(mapping: UfControlGroup): string {
        const mappingValue = mapping.getRawValue() as DataSourceMapping;
        if (mappingValue.isFromExpression) {
            return mappingValue.fromExpression as string;
        } else {
            return mappingValue.from?.display as string;
        }
    }

    getFromCellError(mapping: UfControlGroup): string {
        if (mapping.get(DataSourceMappingControlKeys.IsFromExpression)?.value === true) {
            return mapping.get(DataSourceMappingControlKeys.FromExpression)?.errors?.message as string;
        }
        return mapping.get(DataSourceMappingControlKeys.From)?.errors?.message as string;
    }

    getAttributeDisplay(mapping: UfControlGroup) {
        return getAttribute(mapping, this.fieldIdentifier);
    }

    async copyAttribute(mapping: UfControlGroup, event: Event) {
        event.stopPropagation();
        await this.clipboard.setText(getAttribute(mapping, this.fieldIdentifier));
    }

    private async openEditor(control: UfControlGroup) {

        return this.modalService.openLarge<DataSourceMappingEditorData, UfControlGroup>(
            DataSourceMappingEditorComponent,
            { properties: this.properties, mapping: control, fieldIdentifier: this.fieldIdentifier },
            { guard: true }
        );
    }
}