<ng-template [ngIf]="ready">

  <div class="header">
    <button type="button" class="uf-action tertiary" (click)="close()" title="Close">
      <uf-icon name="close"></uf-icon>
    </button>
    <h3>Users Filter Builder</h3>
  </div>

  <div class="content grid">
    <!-- Roles and Claims -->
    <uf-chips class="col-1of1" label="Add Role(s)" [(value)]="roles" (searchChange)="findRoles($event)"
      [control]="rolesControl" [options]="roleResults" placeholder="Search roles"></uf-chips>
    <div class="col-1of1">
      <button type="button" class="uf-button x-small right" (click)="addClaim()">Add Claim</button>
    </div>
  </div>

  <div class="content grow scrollable">
    <div class="grid">
      <ng-template ngFor let-claim [ngForOf]="claims" let-i="index">
        <div class="field-row col-1of1--no-padding">
          <div class="grid grow">
            <uf-autocomplete class="col-1of2" label="Type" [(value)]="claim.type"
              [control]="$any(claimsControl.at(i)).controls.type" placeholder="Search Claims"
              (searchChange)="searchClaims($event)" [allowCustom]="true" [options]="claimOptions">
            </uf-autocomplete>
            <uf-text class="col-1of2" [(value)]="claim.value" label="Value"
              [control]="$any(claimsControl.at(i)).controls.value"></uf-text>
          </div>

          <!-- Remove entry -->
          <div class="delete">
            <a (click)="removeClaim(i)">
              <uf-icon name="delete"></uf-icon>
            </a>
          </div>
        </div>
      </ng-template>
    </div>
  </div>

  <div class="row space-children pad">
    <button type="button" class="uf-button tertiary right" (click)="close()">Cancel</button>
    <button type="button" class="uf-button primary" (click)="save()">Confirm</button>
  </div>

</ng-template>