<h4 *ngIf="heading">{{heading}}</h4>

<ng-template [ngIf]="invalid">
    <uf-blockquote class="col-12 info gap-top" icon="info" content="Filter configuration is invalid">
    </uf-blockquote>
    <br>
    <button type="button" (click)="reset()" class="uf-button right">
        Remove Filter
    </button>
</ng-template>

<ng-template [ngIf]="control">
    <ng-template ngFor [ngForOf]="control.controls | asUfControlsGroups" let-node let-i="index">
        <div class="uc-list-item">
            <button type="button" (click)="remove(i)" style="float: right" title="Delete" class="uf-action tertiary">
                <uf-icon name="delete"></uf-icon>
            </button>
            <uc-filter-entry [control]="node" [dataProperties]="dataProperties">
            </uc-filter-entry>
        </div>
    </ng-template>
    <br>
    <button type="button" (click)="add()" class="uf-button right">
        Add Filter
    </button>
</ng-template>