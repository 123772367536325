<uf-panel *ngIf="definition" class="container">
    <div class="header-pane uf-app-bar transparent">
        <uf-breadcrumbs [breadcrumbs]="breadcrumbs" class="large">
            <span *ngIf="edited" class="gap-sm-left">*</span>
        </uf-breadcrumbs>
    </div>
    <div class="content-pane">
        <uf-tabs>
            <uf-tab label="Data" path=""></uf-tab>
            <uf-tab label="Definition" path="definition" *ngIf="context.checkRoles('ProjectManager')"></uf-tab>
        </uf-tabs>
    </div>
</uf-panel>