import { Component, HostBinding, Inject, OnInit } from '@angular/core';
import { Modal, ModalData, ModalRuntime } from '@unifii/library/common';


@Component({
    templateUrl: './metadata-modal.html',
})
export class MetadataModalComponent implements Modal<any, void>, OnInit {

    @HostBinding('class.uc-form-card') class = true;

    formData: any;

    constructor(
        public runtime: ModalRuntime<any, void>,
        @Inject(ModalData) public data: any,
    ) { }

    ngOnInit() {
        this.formData = Object.keys(this.data).reduce((acc, property) => {
            if (property.startsWith('_') && property !== '_history') {
                return { ...acc, [property]: this.data[property] };
            }
            return acc;
        }, {});
    }

    close() {
        this.runtime.close();
    }
}