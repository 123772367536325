<uf-expander [class.uc-group--draggable--no-shrink]="canDragField && !isSelected"
    [class.uc-group--draggable--no-shrink--accent]="canDragField && isSelected" class="uc-group">
    <div expanderHeader dragHandle (click)="selectField($event)" [class.accent]="isSelected"
        class="row uf-app-bar flat">
        <uf-icon [name]="icon" />
        <div class="title primary grow">{{configuredField.label ?? configuredField.type | toDisplayName}}</div>
        <uf-help *ngIf="configuredField.help" [content]="configuredField.help" class="uf-action tertiary" />
        <uf-icon *ngIf="!isValid" name="warning" class="icon-warning" />
        <a *ngIf="canRemoveField" (click)="removeField($event)" title="Delete" class="uf-action tertiary">
            <uf-icon name="delete" />
        </a>
    </div>
    <div expanderBody #container [class.grid]="!isGroup && configuredField.type !== fieldTypes.MultiText"
        [class.content--accent]="isSelected" class="uc-group-content">
        <uf-field [field]="field" [(content)]="content" (contentChange)="contentChanged($event)"
            class="col-1of1--no-padding" />
    </div>
</uf-expander>