import { Subscription } from 'rxjs';

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import { Breadcrumb, ToastService, UfControl, UfControlArray, UfControlGroup, ValidatorFunctions } from '@unifii/library/common';
import { DataSeed, DataSourceType, Dictionary, Error, ErrorType, Field, FieldType, IntegrationArgument } from '@unifii/sdk';

import {
    ConsoleDataSource, DataSourceInput, DataSourceInputValueSource, Integration, IntegrationFeatureType, IntegrationInfo, IntegrationProvider,
    IntegrationProviderFeature, UcDataSources, UcIntegrations
} from 'client';

import { EditData } from 'components/common/edit-data';

import { DataSourcesTableManager } from 'pages/project-settings/data-sources/data-sources-table-manager';

import { BreadcrumbService } from 'services/breadcrumb.service';
import { DialogsService } from 'services/dialogs.service';


interface InputArg extends OutputArgs {
    source: DataSourceInputValueSource;
}

interface OutputArgs {
    key: string;
    value: any;
    type?: any;
    isRequired: boolean;
}

export enum DataSeedIdentifiers {
    Id = 'idArg',
    Display = 'displayArg'
}

const Messages: Dictionary<string> = {
    IntegrationError: 'Failed to get integration',
    InvalidForm: 'Required fields must be completed',
    UnknownError: 'Unknown error',
    Saved: 'Integration saved'
};

const DataSource: ConsoleDataSource = {
    type: DataSourceType.External,
    name: '',
    description: '',
    integrationId: null as any,
    featureId: null as any,
    inputMap: null as any as Dictionary<DataSourceInput>,
    outputMap: null as any as DataSeed
};


@Component({
    templateUrl: './data-source-details.html'
})
export class DataSourceDetailsComponent implements OnInit, OnDestroy, EditData {

    readonly sourceTypesInfo: Dictionary<string> = {};
    readonly dataSeedOutputLookup = {
        _id: DataSeedIdentifiers.Id,
        _display: DataSeedIdentifiers.Display
    };
    readonly sourceTypes = [DataSourceInputValueSource.Form, DataSourceInputValueSource.Constant, DataSourceInputValueSource.Default, DataSourceInputValueSource.Unset];
    readonly filteredSourceTypes = this.sourceTypes.filter(type => type !== DataSourceInputValueSource.Unset);

    form = new UfControlGroup({
        integration: new UfControl(),
        feature: new UfControl(),
        description: new UfControl(),
        name: new UfControl(ValidatorFunctions.required('This field is required')),
    });
    integrations: IntegrationInfo[];
    dataSource: ConsoleDataSource;
    integration: Integration;
    provider: IntegrationProvider;
    features: IntegrationProviderFeature[];
    feature: IntegrationProviderFeature | null;
    inputArgs: InputArg[] = [];
    dataSeedOutputArgs: OutputArgs[];
    outputArgs: OutputArgs[] = [];
    error: Error;
    busy: boolean;
    edited: boolean;
    breadcrumbs: Breadcrumb[];

    /** Table Experiments  */
    inputArgFields: Field[] = [
        {
            label: 'Integration Parameter',
            isReadOnly: true,
            type: FieldType.Text,
            isRequired: true
        }
    ];

    private dataSourceId: string;
    private subscriptions = new Subscription();

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private ucIntegrations: UcIntegrations,
        private ucDataSources: UcDataSources,
        private toast: ToastService,
        private dialogs: DialogsService,
        private breadcrumbService: BreadcrumbService,
        @Inject(TableContainerManager) private tableManager: DataSourcesTableManager
    ) {
        this.sourceTypesInfo[DataSourceInputValueSource.Form] = 'Configured in Form';
        this.sourceTypesInfo[DataSourceInputValueSource.Unset] = 'No value needed';

        this.dataSourceId = this.route.snapshot.params.id;
    }

    async ngOnInit() {

        try {

            this.integrations = await this.ucIntegrations.list();

            if (this.isNew) {
                this.dataSource = Object.assign({}, DataSource);
            } else {
                await this.loadDataSource();
            }

            this.subscriptions.add(this.form.statusChanges.subscribe(() => this.edited = true));

        } catch (e) {
            this.error = this.getError(e);
        }

        this.breadcrumbs = this.breadcrumbService.getBreadcrumbs(this.route, [this.isNew ? 'New' : this.dataSource?.name]);
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    add() {

        const control = this.createResultParamControl();
        (this.form.controls.outputArgs as UfControlArray).push(control);

        this.outputArgs.push({
            key: null as any as string,
            value: null as any as string,
            type: null as any as FieldType,
            isRequired: false
        });
    }

    async deleteOutput(index: number) {
        if (!await this.dialogs.confirmDelete()) {
            return;
        }
        this.outputArgs.splice(index, 1);
        (this.form.controls.outputArgs as UfControlArray).removeAt(index);
    }

    async save() {

        if (!this.form.valid) {
            this.form.setSubmitted();
            this.toast.error(Messages.InvalidForm);
            return;
        }

        // disable extra button click
        this.busy = true;

        // convert input map
        this.dataSource.inputMap = this.inputArgs.reduce((map, input) => {

            const res = { value: input.value, source: input.source };

            if (res.source === DataSourceInputValueSource.Form || res.source === DataSourceInputValueSource.Unset) {
                res.value = null as any as string;
            }

            map[input.key] = res;
            return map;
        }, {} as Dictionary<DataSourceInput>);

        // convert output map
        this.dataSource.outputMap = this.outputArgs.concat(this.dataSeedOutputArgs).reduce((map, output) => {
            map[output.key] = output.value;
            return map;
        }, {} as DataSeed);

        try {
            await this.ucDataSources.save(this.dataSource);
            this.toast.success(Messages.Saved);
            this.edited = false;

        } catch (err) {
            const error = this.getError(err);
            this.toast.error(error.message as string);
        } finally {
            this.busy = false;
        }
    }

    integrationChanged(availableIntegration: IntegrationInfo) {

        this.dataSource.integrationId = availableIntegration.id as string;
        this.feature = null;

        this.ucIntegrations.get(availableIntegration.id as string).then(integration => {
            this.integration = integration;
            this.provider = integration.provider as IntegrationProvider;
            this.features = this.provider.features.filter(feature => this.featureFilter(feature, IntegrationFeatureType.Lookup, integration));
        }, error => {
            this.error = this.getError(error);
        });
    }

    featureChanged(feature: IntegrationProviderFeature) {
        this.dataSource.featureId = feature.id;
        this.updateInputs(feature);
    }

    get isNew(): boolean {
        return this.dataSourceId == null || this.dataSourceId === 'new';
    }

    private async loadDataSource() {
        try {
            const dataSource = await this.ucDataSources.get(this.dataSourceId);
            const integration = await this.ucIntegrations.get(dataSource.integrationId);
            this.setup(dataSource, integration);
        } catch (e) {
            this.error = this.getError(e);
        }
    }

    private featureFilter(feature: IntegrationProviderFeature, type: IntegrationFeatureType, integration: Integration): boolean {
        // filter type type
        if (feature.type !== type) {
            return false;
        }
        // filter enabled (disabled === false)
        if (integration.featureConfig && integration.featureConfig[feature.id]?.disabled != null) {
            return integration.featureConfig[feature.id].disabled === false;
        }
        // fallback on feature configuration
        return integration.provider.features.find(f => f.id === feature.id)?.disabled === false;
    }

    private setup(dataSource: ConsoleDataSource, integration: Integration) {

        this.dataSource = dataSource;
        this.integration = integration;
        this.provider = integration.provider as IntegrationProvider;
        this.features = this.provider.features.filter(feature => feature.type === IntegrationFeatureType.Lookup);
        this.feature = this.features.find(feature => feature.id === this.dataSource.featureId) as IntegrationProviderFeature;

        /**
         * Load existing datasource
         */
        const inputArgs: InputArg[] = this.getInputArgs(this.feature.inputArgs)
            // Update value
            .map(arg => {

                if (this.dataSource.inputMap && this.dataSource.inputMap[arg.key]) {

                    const inputMap = this.dataSource.inputMap[arg.key];
                    arg.value = inputMap.value;
                    arg.source = inputMap.source;
                }
                return arg;
            });

        const dataSeedOutputArgs: OutputArgs[] = this.getOutputArgs(this.dataSource, this.feature)
            // Remove optional
            .filter(arg => (this.dataSeedOutputLookup as Dictionary<string>)[arg.key] != null);

        const outputArgs: OutputArgs[] = this.getOutputArgs(this.dataSource, this.feature)
            // Remove required
            .filter(arg => (this.dataSeedOutputLookup as Dictionary<string>)[arg.key] == null);

        this.updateForm(inputArgs, dataSeedOutputArgs, outputArgs);
    }

    private updateInputs(feature: IntegrationProviderFeature) {

        // Reset inputs
        this.inputArgs = [];
        this.dataSeedOutputArgs = [];
        this.outputArgs = [];
        this.dataSource.name = feature.name;

        const inputArgs: InputArg[] = this.getInputArgs(feature.inputArgs);
        const dataSeedOutputArgs: OutputArgs[] = Object.keys(this.dataSeedOutputLookup)
            .map(key => {
                const value = feature[(this.dataSeedOutputLookup as Dictionary<string>)[key] as keyof IntegrationProviderFeature] as string;
                const isRequired = true;
                return { key, value, isRequired };
            });
        const outputArgs: OutputArgs[] = feature.outputArgs.map(arg => ({
            key: arg.identifier,
            value: arg.identifier,
            isRequired: arg.isRequired,
            type: arg.type
        }));

        this.updateForm(inputArgs, dataSeedOutputArgs, outputArgs);
    }

    private updateForm(inputArgs: InputArg[], dataSeedOutputArgs: OutputArgs[], outputArgs: OutputArgs[]) {

        this.form.setControl('inputArgs', new UfControlArray(inputArgs.map(input => this.getInputControlGroup(input))));
        this.form.setControl('dataSeedOutputArgs', new UfControlArray(dataSeedOutputArgs.map(() => this.createResultParamControl())));
        this.form.setControl('outputArgs', new UfControlArray(outputArgs.map(() => this.createResultParamControl())));

        this.inputArgs = inputArgs;
        this.dataSeedOutputArgs = dataSeedOutputArgs;
        this.outputArgs = outputArgs;
    }

    private getInputArgs(inputArgs: IntegrationArgument[] = []): InputArg[] {

        return inputArgs.map(arg => {

            const key = arg.identifier;
            const type = arg.type;
            const isRequired = arg.isRequired;
            const value = null as any as string;
            const source = DataSourceInputValueSource.Form;

            return { key, isRequired, type, value, source };
        });
    }

    private getOutputArgs(dataSource: ConsoleDataSource, feature: IntegrationProviderFeature): OutputArgs[] {

        return Object.keys(dataSource.outputMap)
            .map(key => {

                const value = this.dataSource.outputMap[key];
                const isRequired = !!(feature.outputArgs || []).find(arg => arg.identifier === key && arg.isRequired);
                return { key, value, isRequired };
            });
    }

    private getInputControlGroup(input: InputArg): UfControlGroup {

        const value = new UfControl(ValidatorFunctions.custom(v =>
            input.source !== DataSourceInputValueSource.Constant || !ValidatorFunctions.isEmpty(v), 'A value is required.')
        );
        const source = new UfControl(ValidatorFunctions.required('A type is required.'), { deps: [value] });

        return new UfControlGroup({ source, value });
    }

    private createResultParamControl(): UfControlGroup {

        return new UfControlGroup({
            key: new UfControl(ValidatorFunctions.required('A value is required.')),
            value: new UfControl(ValidatorFunctions.required('A value is required.'))
        });
    }

    private close() {
        this.tableManager.reload.next();
        this.router.navigate(['../'], { relativeTo: this.route });
    }

    private getError(error: Error | any = {}, message?: string): Error {

        message = message || Messages.UnknownError;
        const type = ErrorType.Unknown;

        if (error.message == null) {
            delete error.message;
        }

        return Object.assign({ message, type }, error);
    }

}
