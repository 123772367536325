import { Subject } from 'rxjs';

import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager, TableInputs } from '@unifii/components';
import {
    ActionMultiplicity, ClipboardService, FieldDisplayPipe, FilterEntry, FilterValue, TableAction, TableConfig, TableConfigColumn, TableRowContext,
    ToastService
} from '@unifii/library/common';
import { FieldType } from '@unifii/sdk';

import { ClaimsClient, ClaimSource, UcClaimConfig, UcClient } from 'client';

import { ClaimDataSource } from './claim-data-source';


@Injectable()
export class ClaimTableManager implements TableContainerManager<UcClaimConfig, FilterValue, FilterEntry> {

    tableConfig: TableConfig<UcClaimConfig>;
    addActionConfig = true;

    reload = new Subject<void>();
    update = new Subject<TableInputs<FilterValue>>();
    updateItem = new Subject<UcClaimConfig>();

    private claimClient: ClaimsClient;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private ucClient: UcClient,
        private fielDisplay: FieldDisplayPipe,
        private toastService: ToastService,
        private clipboard: ClipboardService
    ) {
        const claimSource = route.snapshot.data.claimSource as ClaimSource;
        this.claimClient = new ClaimsClient(this.ucClient, claimSource);

        this.tableConfig = {
            id: 'user-claims',
            columns: this.columns,
            actions: this.actions,
            pageSize: 50,
            selectable: true,
            columnToggles: true,
            rowLink: element => element?.id as string
        };
    }

    createDataSource() {
        return new ClaimDataSource(this.claimClient);
    }

    private get columns(): TableConfigColumn<UcClaimConfig>[] {
        return [
            { name: 'label', label: 'Label' },
            { name: 'type', label: 'Identifier' },
            { name: 'valueType', label: 'Value Type' },
            { name: 'lastModifiedBy', label: 'Modified By', value: item => item.lastModifiedBy?.username ?? '' },
            { name: 'lastModifiedAt', label: 'Modified At', value: item => this.fielDisplay.transform(item.lastModifiedAt, FieldType.DateTime) ?? '' }
        ];
    }

    private get actions(): TableAction<UcClaimConfig>[] {
        return [{
            label: 'Delete',
            action: (rows: TableRowContext<UcClaimConfig>[]) => this.delete(rows.map(row => row.$implicit))
        }, {
            label: 'Duplicate',
            multiplicity: ActionMultiplicity.Single,
            action: (row: TableRowContext<UcClaimConfig>) => this.duplicate(row.$implicit?.id as string)
        }, {
            label: 'Copy',
            multiplicity: ActionMultiplicity.Single,
            action: (row: TableRowContext<UcClaimConfig>) => this.clipboard.setText(JSON.stringify(row.$implicit))
        }];
    }

    private async delete(claimConfig: UcClaimConfig[]) {
        // Create new reference so array can be modified for printing a meaningful error message
        claimConfig = [...claimConfig];

        try {
            for (let i = (claimConfig.length - 1); i >= 0; i--) {
                const config = claimConfig[i];
                await this.claimClient.delete(config.id as string);
                claimConfig.splice(i, 1);
            }
            this.toastService.success(`Claim/s deleted`);
        } catch (e) {
            this.toastService.error(`Failed to delete claim: ${claimConfig.map(c => c.label).join(', ')}`);
        } finally {
            this.reload.next();
        }
    }

    private duplicate(id: string) {
        this.router.navigate([id, { duplicate: 'true' }], { relativeTo: this.route });
    }

}

