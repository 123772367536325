import { Subscription } from 'rxjs';

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UfControlGroup, UfFormBuilder } from '@unifii/library/common';
import { DataSeed, FieldType, generateUUID, VisibleFilterDescriptor } from '@unifii/sdk';

import { UcRoles } from 'client';

import { FilterInfo } from 'components';

import { allowsLabelOverride } from 'helpers/data-descriptor-helper';


@Component({
    selector: 'uc-visible-filter',
    templateUrl: 'visible-filter.html'
})
export class VisibleFilterComponent implements OnInit, OnDestroy {

    @Input() parentControl: UfControlGroup;
    @Input() filterInfo: FilterInfo;
    @Output() filterChange = new EventEmitter<VisibleFilterDescriptor>();

    protected readonly control = new UfControlGroup({});
    protected readonly labelControlKey = 'label';
    protected readonly inputTypeControlKey = 'type';
    protected readonly rolesControlKey = 'roles';
    protected readonly formatControlKey = 'format';
    protected readonly dateTimePlaceholder = 'Date & Time';
    protected readonly dataTimeOptions: DataSeed[] = [{ _id: FieldType.Date, _display: 'Date' }];
    protected readonly formatOptions: DataSeed[] = [];
    protected roleResults: string[] = [];
    protected ready: boolean;

    private readonly controlIdentifier = generateUUID();
    private subscriptions = new Subscription();

    constructor(private ucRoles: UcRoles, private formBuilder: UfFormBuilder) { }

    ngOnInit() {

        if (!this.filterInfo.dataPropertyDescriptor) {
            return;
        }

        if (allowsLabelOverride(this.filterInfo.dataDescriptorType, this.filterInfo.dataPropertyDescriptor)) {
            this.control.setControl(this.labelControlKey, this.formBuilder.control(this.filterInfo.filter.label));
        }

        if (this.filterInfo.dataPropertyDescriptor.type === FieldType.DateTime) {
            this.control.setControl(this.inputTypeControlKey, this.formBuilder.control(this.filterInfo.filter.inputType));
        }

        if (this.filterInfo.dataPropertyDescriptor.type === FieldType.Hierarchy) {
            this.control.setControl(this.formatControlKey, this.formBuilder.control(this.filterInfo.filter.format));
            this.formatOptions.push({ _id: 'leaf', _display: 'Show leaf unit only' });
        }

        if (this.filterInfo.dataPropertyDescriptor.icon === 'user') {
            this.control.setControl(this.rolesControlKey, this.formBuilder.control(this.filterInfo.filter.roles));
        }

        this.subscriptions.add(this.control.valueChanges.subscribe(() => this.onValueChanges()));
        this.parentControl.setControl(this.controlIdentifier, this.control);
        this.ready = true;
    }

    ngOnDestroy() {
        this.parentControl.removeControl(this.controlIdentifier);
        this.subscriptions.unsubscribe();
    }

    async findRoles(query?: string) {
        this.roleResults = (await this.ucRoles.get(query)).map(r => r.name);
    }

    private onValueChanges() {
        if (this.control.get(this.rolesControlKey)) {
            this.filterInfo.filter.roles = this.control.get(this.rolesControlKey)?.value;
        }

        if (this.control.get(this.inputTypeControlKey)) {
            this.filterInfo.filter.inputType = this.control.get(this.inputTypeControlKey)?.value;
        }

        if (this.control.get(this.labelControlKey)) {
            this.filterInfo.filter.label = this.control.get(this.labelControlKey)?.value;
        }

        if (this.control.get(this.formatControlKey)) {
            this.filterInfo.filter.format = this.control.get(this.formatControlKey)?.value;
        }

        this.filterChange.emit(this.purgeEmptyValues(this.filterInfo.filter));
    }

    private purgeEmptyValues(data: VisibleFilterDescriptor) {

        for (const key of Object.keys(data)) {
            const value = data[key];

            if (!value || (Array.isArray(value) && !value.length)) {
                delete data[key];
            }
        }

        return data;
    }

}