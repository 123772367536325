<uf-panel class="container">

    <div class="header-pane uf-app-bar transparent">
        <uf-breadcrumbs [breadcrumbs]="[{ name: 'Form Page' }]" class="large">
        </uf-breadcrumbs>
    </div>

    <uf-panel class="content-pane">
        <form>

            <uf-text class="col-1of1" label="User name" value="sergio_unifii"></uf-text>
            <uf-text class="col-1of1" label="First name" value="Sergio"></uf-text>
            <uf-text class="col-1of1" label="Last name" value="Guidi"></uf-text>

            <div class="col-1of2">
                <uf-text label="Email" value=""></uf-text>
                <uf-phone label="Phone" value=""></uf-phone>
                <uf-text label="Email" value="sergio.guidi@unifii.com.au"></uf-text>
            </div>


            <div class="row space-children col-1of1">
                <a class="uf-button tertiary right" [routerLink]="['../']">Cancel</a>
                <button type="button" class="uf-button">Delete</button>
                <button type="button" class="uf-button primary">Save</button>
            </div>

        </form>

    </uf-panel>

</uf-panel>