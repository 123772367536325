import { inject, Injectable } from '@angular/core';
import {
    amendOptionsParams, ClientDeleteOptions, ClientGetOptions, ClientPostOptions, ClientPutOptions, HierarchyUnit, HierarchyUnitExtended,
    HierarchyUnitWithChildCount, HierarchyUnitWithPath
} from '@unifii/sdk';

import { UcClient } from './uc-client';


@Injectable()
export class UcHierarchy {

    private client = inject(UcClient);

    getUnit(id: string, options?: ClientGetOptions): Promise<HierarchyUnitExtended> {
        return this.client.get(this.url(id), options) as Promise<HierarchyUnitExtended>;
    }

    getUnits(ids: string[], options?: ClientGetOptions): Promise<HierarchyUnitWithPath[]> {
        return this.client.get(this.url(), amendOptionsParams({ ids: ids.join(',') }, options)) as Promise<HierarchyUnitWithPath[]>;
    }

    getChildren(id: string, options?: ClientGetOptions): Promise<HierarchyUnitWithChildCount[]> {
        return this.client.get(this.url(id, 'children'), options) as Promise<HierarchyUnitWithChildCount[]>;
    }

    search(params: { q?: string; leaves?: boolean; ceiling?: string; active?: boolean }, options?: ClientGetOptions): Promise<HierarchyUnitWithPath[]> {
        return this.client.get(this.url(), amendOptionsParams(params, options));
    }

    update(unit: HierarchyUnit, options?: ClientPutOptions): Promise<HierarchyUnitExtended> {
        return this.client.put(this.url(unit.id), unit, options);
    }

    add(unit: HierarchyUnit, options?: ClientPostOptions): Promise<HierarchyUnitExtended> {
        return this.client.post(this.url(), { ...options, body: unit });
    }

    remove(id: string, options?: ClientDeleteOptions) {
        return this.client.delete(this.url(id), options);
    }

    private url(...extra: string[]): string {
        const urlParts = ['units'].concat(extra);
        return this.client.buildUrl(urlParts);
    }
}