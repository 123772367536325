import { Component, HostBinding } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager } from '@unifii/components';

import { DataSourcesTableManager } from 'pages/project-settings/data-sources/data-sources-table-manager';


@Component({
    template: `
        <div ufMasterDetail>
            <uf-table-container (addItem)="addItem()"  class="accent list-md pad-none">
            </uf-table-container>
        </div>
        <router-outlet></router-outlet>
    `,
    providers: [
        { provide: TableContainerManager, useClass: DataSourcesTableManager }
    ]
})
export class DataSourcesComponent {

    constructor(
        private route: ActivatedRoute,
        private router: Router
    ) { }

    addItem() {
        this.router.navigate(['./new'], { relativeTo: this.route });
    }
}
