import { CompoundType, Dictionary, Field, FieldTemplate, FieldType, Transition, ValidatorType } from '@unifii/sdk';

import { BuilderField } from 'client';


export interface TransitionItem extends Transition {
    index: number;
}

export interface OptionItem {
    value: any;
    name: string;
}

export interface ValidatorConfig {
    type: string;
    label: string;
    restricted: string[];
    values?: {
        message?: string;
        value?: any;
    };
    placeholders?: {
        message?: string;
        value?: string;
    };
}

export interface FieldMetadata {
    isContainer: boolean;
    heading: boolean;
    label: boolean;
    shortLabel: boolean;
    identifier: boolean;
    template: boolean;
    columns: boolean;
    help: boolean;
    content: boolean;
    step: boolean;
    placeholder: boolean;
    maxLength: boolean;
    readOnly: boolean;
    required: boolean;
    format: boolean;
    autoFill: boolean;
    dataSource: boolean;
    role: boolean;
    showOn: boolean;
    showIf: boolean;
    visibleTo: boolean;
    precision: boolean;
    options: boolean;
    transitions: boolean;
    validators: boolean;
    currency: boolean;
}

export interface FieldDetailMetadata extends FieldMetadata {
    autoDetect: boolean;
    bindTo: boolean;
    collections: boolean;
    columnCount: boolean;
    hasArrayValues: boolean;
    hasNestedFields: boolean;
    hasNestedReadOnly: boolean;
    isSearchable: boolean;
    isReportable: boolean;
    isTranslatable: boolean;
    layoutDirection: boolean;
    minHeight: boolean;
    minWidth: boolean;
    oneToMany: boolean;
    position: boolean;
    types: boolean;
    variations: boolean;
    width: boolean;
    breakAfter: boolean;
    itemLabel: boolean;
    addButtonLabel: boolean;
    columnVisibility: boolean;
    customFields: boolean;
    ceiling: boolean;
}

export class FieldDetailHelper {

    // Validators
    static readonly validators: Dictionary<ValidatorConfig> = {
        Pattern: {
            type: ValidatorType.Pattern,
            restricted: [FieldType.Text, FieldType.Phone, FieldType.Email],
            label: 'Pattern',
            values: {
                message: 'Incorrect format',
                value: ''
            },
            placeholders: {
                value: '^[A-Za-z]$',
            }
        },
        MinLength: {
            type: ValidatorType.MinLength,
            restricted: [FieldType.Repeat, FieldType.Text, FieldType.MultiText, FieldType.MultiChoice, FieldType.Phone, FieldType.Website, FieldType.ImageList, FieldType.FileList],
            label: 'Min Length',
            values: {
                message: 'Minimum length',
                value: 0
            }
        },
        Min: {
            type: ValidatorType.Min,
            restricted: [FieldType.Number],
            label: 'Minimum Value',
            values: {
                message: 'Minimum Value',
                value: 0
            }
        },
        Max: {
            type: ValidatorType.Max,
            restricted: [FieldType.Number],
            label: 'Maximum Value',
            values: {
                message: 'Maximum Value',
                value: 0
            }
        },
        Expression: {
            type: ValidatorType.Expression,
            restricted: [FieldType.Text, FieldType.MultiText, FieldType.Cost, FieldType.Date, FieldType.Time, FieldType.DateTime, FieldType.Number, FieldType.Phone, FieldType.Email, FieldType.Website, FieldType.Bool, FieldType.Choice, FieldType.MultiChoice, FieldType.ImageList, FieldType.SoundList, FieldType.VideoList, FieldType.FileList, FieldType.GeoLocation, FieldType.Content, FieldType.Address, FieldType.Signature, FieldType.Separator, FieldType.Group, FieldType.Repeat, FieldType.Section, FieldType.ActionGroup, FieldType.Lookup, FieldType.Link, FieldType.LinkList, FieldType.DefinitionLink],
            label: 'Expression',
            values: {
                message: '',
                value: '',
            },
            placeholders: {
                value: '$self > 10',
                message: 'Value needs to be greater than 10'
            }
        },
        ItemExpression: {
            type: ValidatorType.ItemExpression,
            restricted: [FieldType.Repeat],
            label: 'ItemExpression',
            values: {
                message: '',
                value: '',
            },
            placeholders: {
                message: 'Item value needs to be greater than 10',
                value: '$item.value > 10'
            }
        },
        LettersOnly: {
            type: ValidatorType.LettersOnly,
            restricted: [FieldType.Text],
            label: 'Letters Only',
            values: {
                message: 'Value needs to be letters only'
            }
        },
        Alphanumeric: {
            type: ValidatorType.Alphanumeric,
            restricted: [FieldType.Text],
            label: 'Alphanumeric',
            values: {
                message: 'Value needs to be letters and numbers',
            },
            placeholders: {
                value: '^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$'
            }
        },
        BeforeNow: {
            type: ValidatorType.BeforeNow,
            restricted: [FieldType.Date, FieldType.DateTime, FieldType.ZonedDateTime],
            label: 'Before Now',
            values: {
                message: 'Value must be before the specified date',
            }
        },
        AfterNow: {
            type: ValidatorType.AfterNow,
            restricted: [FieldType.Date, FieldType.DateTime, FieldType.ZonedDateTime],
            label: 'After Now',
            values: {
                message: 'Value must be after the specified date'
            }
        },
        Email: {
            type: ValidatorType.Email,
            restricted: [FieldType.Email],
            label: 'Email',
            values: {
                message: 'Value must be an email'
            },
            placeholders: {
                value: 'name@host'
            }
        },
        Website: {
            type: ValidatorType.Website,
            restricted: [FieldType.Website],
            label: 'Website',
            values: {
                message: 'Value must be a website',
                value: ''
            }
        }
    };

    static getMetadata(field: Field, compoundType?: CompoundType, parent?: Field): FieldDetailMetadata {

        const core = this.getBasicMetadata(field);

        const basic: FieldDetailMetadata = Object.assign({
            autoDetect: [FieldType.GeoLocation, FieldType.Address].indexOf(field.type) >= 0,
            bindTo: false,
            collections: [FieldType.LinkList].indexOf(field.type) >= 0,
            columnCount: [FieldType.Choice, FieldType.MultiChoice, FieldType.Bool].indexOf(field.type) >= 0,
            hasArrayValues: [FieldType.LinkList, FieldType.ImageList, FieldType.VideoList, FieldType.SoundList, FieldType.FileList].indexOf(field.type) >= 0,
            hasNestedFields: [FieldType.Address, FieldType.GeoLocation].indexOf(field.type) >= 0,
            hasNestedReadOnly: false,
            isSearchable: [FieldType.Text, FieldType.MultiText, FieldType.Phone, FieldType.Email, FieldType.Website, FieldType.Cost].indexOf(field.type) >= 0,
            isReportable: [FieldType.ImageList, FieldType.GeoLocation, FieldType.Text, FieldType.Cost, FieldType.Address, FieldType.MultiText, FieldType.Choice, FieldType.MultiChoice, FieldType.Date, FieldType.Time, FieldType.ZonedDateTime, FieldType.Website, FieldType.Phone, FieldType.Email, FieldType.Bool, FieldType.Number, FieldType.Signature, FieldType.FileList, FieldType.Group, FieldType.Repeat, FieldType.DateTime, FieldType.Lookup, FieldType.Hierarchy].indexOf(field.type) >= 0,
            isTranslatable: [FieldType.Text, FieldType.MultiText, FieldType.Number, FieldType.Phone, FieldType.Email, FieldType.Website, FieldType.Cost, FieldType.LinkList, FieldType.ImageList, FieldType.VideoList, FieldType.SoundList, FieldType.FileList].indexOf(field.type) >= 0,
            layoutDirection: [FieldType.Choice, FieldType.MultiChoice].indexOf(field.type) >= 0,
            imageOrientation: [FieldType.Choice, FieldType.MultiChoice].indexOf(field.type) >= 0,
            imageProportion: [FieldType.Choice, FieldType.MultiChoice].indexOf(field.type) >= 0,
            minHeight: [FieldType.ImageList].indexOf(field.type) >= 0,
            minWidth: [FieldType.ImageList].indexOf(field.type) >= 0,
            oneToMany: [FieldType.LinkList].indexOf(field.type) >= 0,
            position: [FieldType.ImageList].indexOf(field.type) >= 0,
            width: [FieldType.Bool, FieldType.Text, FieldType.MultiText, FieldType.Date, FieldType.Time, FieldType.DateTime, FieldType.Number, FieldType.Choice, FieldType.MultiChoice, FieldType.Phone, FieldType.Email, FieldType.Website, FieldType.Cost, FieldType.Lookup].indexOf(field.type) >= 0,
            breakAfter: [FieldType.Bool, FieldType.Text, FieldType.MultiText, FieldType.Date, FieldType.Time, FieldType.DateTime, FieldType.Number, FieldType.Choice, FieldType.MultiChoice, FieldType.Phone, FieldType.Email, FieldType.Website, FieldType.Cost, FieldType.Lookup].indexOf(field.type) >= 0,
            types: [FieldType.Link].indexOf(field.type) >= 0 && [CompoundType.Collection].indexOf(field.compoundType as CompoundType) >= 0,
            variations: false,
            itemLabel: [FieldType.Repeat].indexOf(field.type) >= 0,
            addButtonLabel: [FieldType.Repeat].indexOf(field.type) >= 0,
            columnVisibility: [FieldType.Repeat].includes(field.type),
            customFields: false,
            ceiling: field.type === FieldType.Hierarchy
        }, core);


        // Customize based on current builder
        switch (compoundType) {

            case CompoundType.View:
                basic.shortLabel = false;
                basic.autoFill = false;
                basic.dataSource = false;
                basic.position = false;
                basic.role = false;
                basic.showIf = false;
                basic.showOn = false;
                basic.transitions = false;
                basic.breakAfter = false;
                basic.width = false;
                basic.itemLabel = false;
                basic.addButtonLabel = false;
                basic.readOnly = false;
                basic.required = true;
                basic.validators = false;
                basic.template = [FieldType.FileList].includes(field.type);
                break;

            case CompoundType.Page:
                basic.shortLabel = false;
                basic.autoFill = false;
                basic.dataSource = false;
                basic.identifier = false;
                basic.label = false;
                basic.role = false;
                basic.showIf = false;
                basic.showOn = false;
                basic.transitions = false;
                basic.types = false;
                basic.breakAfter = false;
                basic.width = false;
                basic.itemLabel = false;
                basic.addButtonLabel = false;
                basic.readOnly = false;
                basic.required = false;
                basic.validators = false;
                basic.template = [FieldType.MultiText, FieldType.LinkList, FieldType.FileList].includes(field.type);
                basic.help = false;
                break;

            case CompoundType.Collection:
                basic.shortLabel = false;
                basic.autoFill = false;
                basic.dataSource = false;
                basic.position = false;
                basic.role = false;
                basic.showIf = false;
                basic.showOn = false;
                basic.transitions = false;
                basic.breakAfter = false;
                basic.width = false;
                basic.itemLabel = false;
                basic.addButtonLabel = false;
                basic.readOnly = false;
                basic.required = true;
                basic.validators = true;
                break;

            default: // Forms default
                basic.oneToMany = false;
                basic.variations = [FieldType.Choice, FieldType.MultiChoice, FieldType.Bool].indexOf(field.type) >= 0;
                basic.hasNestedReadOnly = [FieldType.Address, FieldType.GeoLocation].indexOf(field.type) >= 0;
                basic.minHeight = false;
                basic.minWidth = false;
                basic.position = false;
                basic.customFields = !basic.isContainer;
                basic.bindTo = [
                    FieldType.Text, FieldType.MultiText, FieldType.Number, FieldType.Date, FieldType.Time, FieldType.DateTime,
                    FieldType.Phone, FieldType.Email, FieldType.Website, FieldType.Choice, FieldType.Lookup
                ].indexOf(field.type) >= 0;

                if (parent?.type === FieldType.Survey) {
                    basic.placeholder = false;
                    basic.dataSource = false;
                    basic.width = false;
                    basic.breakAfter = false;
                    basic.template = false;
                    basic.columnCount = false;
                    basic.layoutDirection = false;
                    basic.collections = false;
                    basic.options = false;
                    basic.variations = false;
                    basic.customFields = false;
                }

                break;
        }

        return basic;
    }

    static getColumnOptions(): OptionItem[] {

        /** Return items that matches our layout system 1, 2, 3, 4, 5,  */

        return Array.from(Array(8).keys()).reduce((arr: any[], i) => {

            if (i < 5 || i === 7) {
                arr.push({ value: i + 1, name: '' + (i + 1) });
            }
            return arr;
        }, []);
    }

    static getTemplateOptions(type: FieldType) {
        switch (type) {
            case FieldType.Choice:
                return [
                    { value: FieldTemplate.DropDown, name: 'Drop down' },
                    { value: FieldTemplate.Radio, name: 'Radio buttons' },
                    { value: FieldTemplate.RadioWithContent, name: 'Advanced (with Radio Buttons)' },
                    { value: FieldTemplate.OptionWithContent, name: 'Advanced (without Radio buttons)' }
                ];
            case FieldType.MultiChoice:
                return [
                    { value: FieldTemplate.Checkbox, name: 'Checkboxes' },
                    { value: FieldTemplate.CheckboxWithContent, name: 'Advanced (with Checkboxes)' },
                    { value: FieldTemplate.OptionWithContent, name: 'Advanced (without Checkboxes)' },
                    { value: FieldTemplate.Chips, name: 'Look Up' },
                ];
            case FieldType.Repeat:
                return [
                    { value: FieldTemplate.Form, name: 'Form' },
                    { value: FieldTemplate.HorizontalTable, name: 'Horizontal Table (Switch to Form on mobile)' },
                    { value: FieldTemplate.HorizontalTableMobile, name: 'Horizontal Table' },
                    { value: FieldTemplate.VerticalTable, name: 'Vertical Table (Switch to Form on mobile)' },
                    { value: FieldTemplate.VerticalTableMobile, name: 'Vertical Table' }
                ];
            case FieldType.ImageList:
                return [
                    { value: FieldTemplate.Left, name: 'Left' },
                    { value: FieldTemplate.Stretch, name: 'Stretch' },
                    { value: FieldTemplate.Right, name: 'Right' },
                    { value: FieldTemplate.Banner, name: 'Banner' }
                ];
            case FieldType.LinkList:
                return [
                    { value: FieldTemplate.List, name: 'List' },
                    { value: FieldTemplate.Table, name: 'Table' }
                ];
            case FieldType.FileList:
                return [
                    { value: '', name: 'File List' },
                    { value: FieldTemplate.Pdf, name: 'PDF Viewer' },
                ];
            case FieldType.Bool:
                return [
                    { value: FieldTemplate.Checkbox, name: 'Check box' },
                    { value: FieldTemplate.DropDown, name: 'Drop down' },
                    { value: FieldTemplate.Radio, name: 'Radio buttons' },
                    { value: FieldTemplate.BoolTickCross, name: 'Tick/Cross' }
                ];
            case FieldType.Content:
            case FieldType.MultiText:
                return [
                    { value: '', name: 'Markdown (default)' },
                    { value: FieldTemplate.Content, name: 'Content (primary theme - flat)' },
                    { value: FieldTemplate.Callout, name: 'Callout (primary theme - raised)' },
                    { value: FieldTemplate.Info, name: 'Infomation (blue)' },
                    { value: FieldTemplate.Alert, name: 'Alert (red)' },
                    { value: FieldTemplate.Warning, name: 'Warning (orange)' },
                    { value: FieldTemplate.Success, name: 'Success (green)' }
                ];
        }

        return null;
    }

    /**
     *  Check existence of a similar transition Transition inside fields list
     *
     * @param transition - The subject for comparison
     * @param fields - Fields of the Form definition
     */
    static existsSimilarTransition(transition: Transition, transitionIndex: number | undefined, field: BuilderField, fields: BuilderField[]): boolean {
        // Transitions are defined only in Section and Section are only at fields root level
        // Two transitions are similar when have same source and action but different target

        return fields
            .filter(f => f.type === FieldType.Section && f.transitions != null)
            .filter(f => {
                // clone array
                const transitions = [...(f.transitions || [])];

                // exclude itself
                if (field.id === f.id && transitionIndex !== undefined && transitions.length > transitionIndex) {
                    transitions.splice(transitionIndex, 1);
                }

                // see if similar exist
                return transitions.find(t => transition.source != null && t.source === transition.source &&
                    transition.action != null && t.action === transition.action &&
                    transition.target != null && (t.target !== transition.target || field.id === f.id)) != null;
            }).length > 0;
    }

    /**
     * Check existence of other ActionGroup within the container section that trigger on the sam action
     *
     * @param action The action for comparison
     * @param section The subject container to search for other ActionGroup with same action
     */
    static existActionGroupsForSameAction(action: string, section?: Field): boolean {
        if (!section || !section.fields) {
            return false;
        }

        return section.fields.filter(f =>
            f.type === FieldType.ActionGroup &&
            action != null &&
            f.showOn === action
        ).length > 1;
    }

    static existActionGroupForSameTransitions(action: string, parentSection: Field, sections: Field[]): boolean {

        // The current AG action is not set, no risk for conflicts
        if (action == null || parentSection == null) {
            return false;
        }

        // This AG parent Section transitions that can trigger the AG, same transition.action === AG.showOn
        const matchingTransitions = (parentSection.transitions || []).filter(t => t.action === action);

        // All sections with 1+ compatible transitions, means that can trigger the the AG
        const sameWorkFlowSections = (sections || [])
            // Sections
            .filter(s => FieldType.Section === s.type)
            // Their transitions matching one or more
            .filter(s => (s.transitions || [])
                // AG parent Section transitions
                .find(t => matchingTransitions
                    // Have at least one match
                    .find(mt => mt.source === t.source && mt.action === action) != null)
                != null
            );

        // Matching sections
        const actionGroupSameTrigger = sameWorkFlowSections
            // Their AGs
            .map(s => (s.fields || []).filter(f => f.type === FieldType.ActionGroup))
            // Flatten
            .reduce((res, next) => res.concat(next), [])
            // Match the action trigger
            .filter(ag => ag.showOn === action);

        // Only 1 is itself, 1+ means there are other matching AGs
        return actionGroupSameTrigger.length > 1;
    }

    private static getBasicMetadata(field: Field): FieldMetadata {
        return {
            isContainer: [FieldType.Section, FieldType.Group, FieldType.Repeat, FieldType.ActionGroup, FieldType.Survey].indexOf(field.type) >= 0,
            heading: [FieldType.Content].indexOf(field.type) >= 0,
            label: [FieldType.Separator, FieldType.Content].indexOf(field.type) < 0,
            shortLabel: [FieldType.Section, FieldType.Group, FieldType.ActionGroup, FieldType.Repeat, FieldType.Separator, FieldType.Content, FieldType.Survey].indexOf(field.type) < 0,
            identifier: [FieldType.Section, FieldType.Group, FieldType.ActionGroup, FieldType.Content, FieldType.Separator, FieldType.Survey].indexOf(field.type) < 0,
            template: [FieldType.Choice, FieldType.MultiChoice, FieldType.Repeat, FieldType.LinkList, FieldType.Bool, FieldType.Content].indexOf(field.type) >= 0,
            columns: [FieldType.LinkList].indexOf(field.type) >= 0,
            help: [FieldType.Separator].indexOf(field.type) < 0,
            content: [FieldType.Content].indexOf(field.type) >= 0,
            step: [FieldType.Time, FieldType.DateTime, FieldType.ZonedDateTime].indexOf(field.type) >= 0,
            placeholder: [FieldType.Text, FieldType.MultiText, FieldType.Number, FieldType.Choice, FieldType.Bool, FieldType.MultiChoice, FieldType.Phone, FieldType.Email, FieldType.Website, FieldType.Cost, FieldType.Lookup, FieldType.Hierarchy].indexOf(field.type) >= 0,
            readOnly: [FieldType.Section, FieldType.Group, FieldType.Separator, FieldType.Content, FieldType.ActionGroup, FieldType.Address, FieldType.GeoLocation].indexOf(field.type) < 0,
            maxLength: [FieldType.Repeat, FieldType.Text, FieldType.MultiText, FieldType.MultiChoice, FieldType.Phone, FieldType.Email, FieldType.Website, FieldType.ImageList, FieldType.FileList, FieldType.VideoList, FieldType.LinkList, FieldType.SoundList].indexOf(field.type) >= 0,
            required: [FieldType.Section, FieldType.Group, FieldType.Content, FieldType.ActionGroup, FieldType.Separator, FieldType.Address, FieldType.GeoLocation, FieldType.Survey].indexOf(field.type) < 0,
            format: [FieldType.Date, FieldType.DateTime, FieldType.Time, FieldType.ZonedDateTime].indexOf(field.type) >= 0,
            autoFill: [FieldType.Text, FieldType.MultiText, FieldType.Date, FieldType.Time, FieldType.DateTime, FieldType.ZonedDateTime, FieldType.Number, FieldType.Choice, FieldType.MultiChoice, FieldType.Phone, FieldType.Email, FieldType.Website, FieldType.ImageList, FieldType.FileList, FieldType.Bool, FieldType.Lookup, FieldType.Hierarchy, FieldType.Repeat].indexOf(field.type) >= 0,
            dataSource: [FieldType.Repeat, FieldType.Choice, FieldType.Lookup].indexOf(field.type) >= 0,
            role: [FieldType.Section].indexOf(field.type) >= 0,
            showOn: [FieldType.ActionGroup].indexOf(field.type) >= 0,
            showIf: [FieldType.ActionGroup, FieldType.Section].indexOf(field.type) < 0,
            visibleTo: [FieldType.Section].indexOf(field.type) < 0,
            precision: [FieldType.Number].indexOf(field.type) >= 0,
            currency: [FieldType.Cost].indexOf(field.type) >= 0,
            options: [FieldType.Choice, FieldType.MultiChoice, FieldType.Bool, FieldType.Survey].indexOf(field.type) >= 0,
            transitions: [FieldType.Section].indexOf(field.type) >= 0,
            validators: [FieldType.Section, FieldType.Separator, FieldType.Content, FieldType.Signature, FieldType.Address, FieldType.Lookup, FieldType.Survey, FieldType.Hierarchy].indexOf(field.type) < 0
        };
    }

}
