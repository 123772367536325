<uf-expander *ngIf="ready" class="uf-box flat">
    <div expanderHeader class="uf-app-bar flat">
        <div class="title primary">Settings</div>
        <uf-icon *ngIf="isInvalid" name="error" class="error"></uf-icon>
    </div>
    <div expanderBody [formGroup]="control" class="uf-grid pad-sides pad-sm-top pad-bottom">

        <uf-checkbox label="Required" *ngIf="meta.isRequired" [formControlName]="fieldKeys.IsRequired" class="col-6">
        </uf-checkbox>

        <uf-checkbox label="Read only" *ngIf="meta.isReadOnly" [formControlName]="fieldKeys.IsReadOnly" class="col-6">
        </uf-checkbox>

        <uf-checkbox label="Autodetect" *ngIf="meta.autoDetect" [formControlName]="fieldKeys.AutoDetect" class="col-12">
        </uf-checkbox>

        <uf-select label="Currency" *ngIf="meta.currency" [formControlName]="fieldKeys.Currency"
            valueProperty="identifier" nameProperty="name" [options]="currencies" class="col-12">
        </uf-select>

        <uf-text label="Autofill" *ngIf="meta.autofill" [formControlName]="fieldKeys.Autofill" class="col-12">
        </uf-text>

        <uf-text label="Bind to" *ngIf="meta.bindTo" [formControlName]="fieldKeys.BindTo" class="col-12">
        </uf-text>

        <uf-textarea label="Show if" *ngIf="meta.showIf" [formControlName]="fieldKeys.ShowIf" class="col-12">
        </uf-textarea>

        <uf-autocomplete label="Show on" *ngIf="meta.showOn" (searchChange)="searchActions($event)"
            [options]="actionsResult" [formControlName]="fieldKeys.ShowOn" class="col-12">
        </uf-autocomplete>

        <uf-number label="Precision" *ngIf="meta.precision" [formControlName]="fieldKeys.Precision" class="col-12">
        </uf-number>

        <uf-number label="Maximum length" *ngIf="meta.maxLength" [formControlName]="fieldKeys.MaxLength" class="col-12">
        </uf-number>

        <uf-select label="Interval" *ngIf="meta.step" [formControlName]="fieldKeys.Step" [options]="intervals"
            valueProperty="identifier" nameProperty="name" class="col-12">
        </uf-select>

        <uf-autocomplete label="Format" *ngIf="meta.format" [formControlName]="fieldKeys.Format" [allowCustom]="true"
            [options]="formatOptions" [placeholder]="formatPlaceholder" class="col-12">
        </uf-autocomplete>

        <ng-container *ngIf="meta.hierarchy" [formGroupName]="fieldKeys.HierarchyConfig">
            <uf-hierarchy-unit label="Starting Unit" [formControlName]="hierarchyConfigKeys.Ceiling" placeholder="Root"
                [selectLeafsOnly]="false" [isRequired]="false" [filterInactiveChildren]="true" class="col-12">
            </uf-hierarchy-unit>

            <uf-select label="Limit selection to" [formControlName]="hierarchyConfigKeys.SelectionMode"
                [options]="selectionModeOptions" valueProperty="identifier" nameProperty="name" class="col-12">
            </uf-select>
        </ng-container>

        <uc-sort-property-input *ngIf="showSort" label="Sort" [formControlName]="fieldKeys.Sort"
            [properties]="sortProperties" class="col-12">
        </uc-sort-property-input>

    </div>
</uf-expander>