import { Subscription } from 'rxjs';

import { Component, HostBinding, Inject, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import { Breadcrumb, ModalService, UfControl, UfControlGroup, ValidatorFunctions } from '@unifii/library/common';

import { Media, MediaType, UcMedia } from 'client';

import { EditData } from 'components/common/edit-data';
import { ImagePreviewComponent } from 'components/content/modals/image-preview.component';

import { MediaHelper } from 'helpers/helpers';

import { BreadcrumbService } from 'services/breadcrumb.service';

import { FileConflictError, FileConflictModalComponent } from './file-conflict-modal.component';
import { MediaTableManager } from './media-table-manager';


const Conflict = '409';

@Component({
    templateUrl: './media-detail.html',
    styleUrls: ['./media-detail.less']
})
export class MediaDetailComponent implements EditData, OnDestroy {

    @HostBinding('class.media-detail') class = true;

    readonly mediaType = MediaType;

    media: Media;
    edited: boolean;
    loading = true;
    error: Error;
    detailsForm: UfControlGroup;
    breadcrumbs: Breadcrumb[];

    private isPending = false;
    private subscriptions = new Subscription();

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private ucMedia: UcMedia,
        public modalService: ModalService,
        private breadcrumbService: BreadcrumbService,
        @Inject(TableContainerManager) private tableManager: MediaTableManager
    ) {

        this.detailsForm = new UfControlGroup({
            titleControl: new UfControl(ValidatorFunctions.required('Title is required')),
            descriptionControl: new UfControl(),
            authorControl: new UfControl(),
            creditsControl: new UfControl()
        });

        this.subscriptions.add(this.detailsForm.statusChanges.subscribe(v => this.edited = true));

        this.subscriptions.add(this.route.params.subscribe(v => {
            if (v.mediaId === 'new') {
                return;
            }
            this.init(+v.mediaId);
        }));
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    getUrl(width: number, height: number) {
        if (!this.media) {
            return null;
        }
        return MediaHelper.getImageUrl(this.media, width, height);
    }

    async delete() {
        const confirmed = await this.modalService.openConfirm({
            title: 'Delete',
            message: `Media ${this.media.title ? this.media.title : this.media.filename} deletion can't be undone.`,
            confirmLabel: 'Delete',
            cancelLabel: `Don't Delete`
        });

        if (!confirmed) {
            return;
        }

        try {
            await this.ucMedia.delete(this.media.id as number);
            this.tableManager.reload.next();
            this.close();
        } catch (error) {
            if (error.code === Conflict) {
                this.modalService.openMedium<FileConflictError[], void>(FileConflictModalComponent, error.data);
            } else {
                this.error = error;
            }
        }
    }

    async save() {
        this.detailsForm.setSubmitted();
        if (!this.detailsForm.valid) {
            return;
        }

        try {
            const updated = await this.ucMedia.save(this.media);

            this.edited = false;

            if (this.isPending) {
                this.tableManager.reload.next();
            } else {
                this.tableManager.updateItem.next(updated);
            }
            this.close();
        } catch (error) {
            this.error = error;
        }
    }

    download() {
        window.open(this.media.url);
    }

    viewFullSizeImage() {
        this.modalService.openLarge<Media, void>(ImagePreviewComponent, this.media);
    }

    private async init(id: number) {

        try {
            this.media = await this.ucMedia.getDetail(id);
            this.isPending = !this.media.title;
            this.loading = false;
            this.edited = false;
            this.breadcrumbs = this.breadcrumbService.getBreadcrumbs(this.route, [this.getTitle()]);

            if (this.isPending) {
                this.media.title = this.media.filename;
            }

        } catch (_error) {

            await this.modalService.openAlert({
                title: 'Not found',
                message: 'This media item no longer exists.',
                confirmLabel: 'Ok'
            });

            this.router.navigate(['../'], { relativeTo: this.route });
        }
    }

    private close() {
        this.router.navigate(['../'], { relativeTo: this.route });
    }


    private getTitle() {
        return this.media?.title ?? this.media?.filename ?? 'Media';
    }

}
