import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { TenantSettingsService } from 'services/tenant-settings.service';


export const canActivateTenant: CanActivateFn = async () => {

    const service = inject(TenantSettingsService);
    const router = inject(Router);

    try {
        await service.sync();
        // successfully synced tenant settings
        return true;
    } catch (err) {
        // Not enough tenant info provided
        router.navigate(['./page-not-found/server']);
        return false;
    }
}
