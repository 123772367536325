<h4>Results Mappings</h4>
<table class="uf-table inputs accent clickable">
    <thead>
        <tr>
            <th>From</th> <!-- mapping.value -->
            <th>Data Type</th> <!-- mapping.type -->
            <th>To</th> <!-- mapping.attribute -->
            <th>Attribute</th> <!-- <field.identifier>.<mapping.attribute> -->
            <th>
                <div class="row">
                    Label
                    <uf-help content="Label used when the value is displayed"></uf-help>
                </div>
            </th>
            <th>
                <div class="row">
                    Visible
                    <uf-help content="Enable to display this data in the form. Some data types may not be supported.">
                    </uf-help>
                </div>
            </th>
            <th>
                Hide Empty
            </th>
            <ng-template ufFeatureFlag="reporting">
                <th>
                    <div class="row">
                        Reportable
                        <uf-help content="Enable to add this data to reports">
                        </uf-help>
                    </div>
                </th>
            </ng-template>
            <th></th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let mapping of mappings.controls | asUfControlsGroups; let i = index" (click)="edit(i)"
            [formGroup]="mapping" class="body-row">
            <td>
                {{ getFromCellValue(mapping) }}
                <span *ngIf="getFromCellError(mapping)" class="uf-lozenge error">{{getFromCellError(mapping)}}</span>
            </td>
            <td>
                {{ mapping.get(mappingKeys.Type)?.value }}
                <span *ngIf="mapping.get(mappingKeys.Type)?.invalid" class="uf-lozenge error">Required</span>
            </td>
            <td>
                {{ mapping.get(mappingKeys.To)?.value }}
                <span *ngIf="mapping.get(mappingKeys.To)?.invalid" class="uf-lozenge error">Invalid</span>
            </td>
            <td class="row center-all">
                <button class="uf-action tertiary gap-sm-right" (click)="copyAttribute(mapping, $event)" title="Copy">
                    <uf-icon name="copy"></uf-icon>
                </button>
                <span>{{getAttributeDisplay(mapping)}}</span>
            </td>
            <td> {{ mapping.get(mappingKeys.Label)?.value }} </td>
            <td lass="input-cell">
                <uf-icon *ngIf="mapping.get(mappingKeys.IsVisible)?.value" name="tick"></uf-icon>
            </td>
            <td lass="input-cell">
                <uf-icon *ngIf="mapping.get(mappingKeys.HideEmpty)?.value" name="tick"></uf-icon>
            </td>
            <ng-template ufFeatureFlag="reporting">
                <td lass="input-cell">
                    <uf-icon *ngIf="mapping.get(mappingKeys.IsReportable)?.value" name="tick"></uf-icon>
                </td>
            </ng-template>
            <td class="input-cell">
                <button *ngIf="i > 1" (click)="remove(i, $event)" title="Delete"
                    class="uf-action tertiary right gap-sm-right">
                    <uf-icon name="delete"></uf-icon>
                </button>
            </td>
        </tr>
    </tbody>
</table>
<div class="col-12 row gap-top">
    <button [disabled]="mappings.invalid" (click)="openReorder()" type="button" class="uf-button right">
        Reorder Fields
    </button>
    <button (click)="add()" type="button" class="uf-button gap-sm-left">Add Mapping</button>
</div>