<div class="header-pane uf-app-bar transparent">
    <button (click)="resetForm()" type="button" class="uf-button tertiary right">
        Reset
    </button>
    <button (click)="send()" type="button" class="uf-button primary">
        Send
    </button>
</div>
<uf-panel class="content-pane container">
    <div class="uf-container-lg">
        <div class=" uf-grid row-gap-lg pad-top">

            <div class="uf-box col-12">
                <div class="uf-app-bar flat accent">
                    <div class="title">
                        Target Devices
                    </div>
                    <button *ngIf="selectedDevices.length" type="button" class="uf-action tertiary"
                        (click)="resetDevices()" title="Clear">
                        <uf-icon name="backspace"></uf-icon>
                    </button>
                </div>

                <div class="uf-grid pad">
                    <ul class="uc-list col-12">
                        <ng-template ngFor [ngForOf]="selectedDevices" let-device let-i="index">
                            <li class="uc-list-item--bottom-border--small row center-all">
                                {{ device.name }} - {{ device.model }} - {{ device.id }}
                                <button type="button" class="uf-action tertiary right"
                                    (click)="selectedDevices.splice(i, 1)" title="Delete">
                                    <uf-icon name="delete"></uf-icon>
                                </button>
                            </li>
                        </ng-template>
                    </ul>
                    <div class="col-12">
                        <button type="button" class="uf-button right" (click)="addDevices()">
                            Add Devices
                        </button>
                    </div>
                </div>
            </div>

            <div class="uf-box col-12">
                <div class="uf-app-bar flat accent">
                    <div class="title">
                        Target Users
                    </div>
                    <button *ngIf="roles?.length > 0 || userAstNode.args?.length ?? 0 > 1" type="button"
                        class="uf-action tertiary" (click)="resetUsers()" title="Clear">
                        <uf-icon name="backspace"></uf-icon>
                    </button>
                </div>

                <div class="uf-grid pad">
                    <ul class="uc-list col-12">
                        <ng-template ngFor [ngForOf]="roles" let-role let-i="index">
                            <li class="uc-list-item--bottom-border--small row center-all">
                                <div class="fieldset-item--small grow">
                                    <span>Role:</span>
                                    <span>{{role}}</span>
                                </div>
                                <button type="button" class="uf-action tertiary right" (click)="roles.splice(i, 1)"
                                    title="Delete">
                                    <uf-icon name="delete"></uf-icon>
                                </button>
                            </li>
                        </ng-template>
                        <ng-template ngFor let-claim [ngForOf]="userAstNode.args" let-i="index">
                            <li *ngIf="i > 0" class="uc-list-item--bottom-border--small row center-all">
                                <div class="fieldset-item--small grow">
                                    <span>Claim Type:</span>
                                    <span>{{$any(claim.args)[0].value}}</span>
                                </div>
                                <div class="fieldset-item--small grow">
                                    <span>Claim Type:</span>
                                    <span>{{$any(claim.args)[1].value}}</span>
                                </div>
                                <button type="button" class="uf-action tertiary right"
                                    (click)="userAstNode.args?.splice(i, 1)" title="Delete">
                                    <uf-icon name="delete"></uf-icon>
                                </button>
                            </li>
                        </ng-template>
                    </ul>
                    <div class="col-12">
                        <button type="button" class="uf-button right" (click)="openUserQueryBuilder()">
                            Add Roles/Claims
                        </button>
                    </div>
                </div>
            </div>

            <!-- Message Data -->
            <div class="uf-box col-12">
                <div class="uf-app-bar flat accent">
                    <div class="title">
                        Message Data
                    </div>
                    <button type="button" class="uf-action tertiary" (click)="messageControl.reset()" title="Clear">
                        <uf-icon name="backspace"></uf-icon>
                    </button>
                </div>
                <div class="uf-grid pad">
                    <uf-text label="Title" class="col-12" [(value)]="notification.title"
                        [control]="messageControl.controls.title | asUfControl"></uf-text>
                    <uf-textarea label="Message" class="col-12" [(value)]="notification.message"
                        [control]="messageControl.controls.message | asUfControl">
                    </uf-textarea>
                </div>
            </div>

            <!-- Routing -->
            <div class="uf-box col-12">
                <div class="uf-app-bar flat accent">
                    <div class="title">
                        Routing
                    </div>
                    <button type="button" class="uf-action tertiary" (click)="routingControl.reset()" title="Clear">
                        <uf-icon name="backspace"></uf-icon>
                    </button>
                </div>
                <div *ngIf="notification.route" class="uf-grid pad">
                    <uf-text label="Tenant" class="col-4" [(value)]="notification.route.tenant"
                        [control]="routingControl.controls.tenant | asUfControl">
                    </uf-text>
                    <uf-text label="Project Id" class="col-4" [(value)]="notification.route.projectId"
                        [control]="routingControl.controls.projectId | asUfControl">
                    </uf-text>
                    <uf-text label="Node Id" class="col-4" [(value)]="notification.route.nodeId"
                        [control]="routingControl.controls.nodeId | asUfControl">
                    </uf-text>
                    <uf-text label="Form Data Repository Id" class="col-6" [(value)]="notification.route.bucket"
                        [control]="routingControl.controls.bucket | asUfControl">
                    </uf-text>
                    <uf-text label="Id" class="col-6" [(value)]="notification.route.id"
                        [control]="routingControl.controls.id | asUfControl">
                    </uf-text>
                </div>
            </div>
        </div>
    </div>
</uf-panel>