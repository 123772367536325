import { Component, Input, OnInit } from '@angular/core';
import { UfControl, UfControlArray, UfControlGroup, UfFormControl } from '@unifii/library/common';
import { FieldType } from '@unifii/sdk';

import { FORM_EDITOR_CONSTANTS } from '../form-editor-constants';
import { FieldControlKeys, VariationControlKeys } from '../form-editor-control-keys';
import { FormFieldMetadata } from '../form-editor-model';


@Component({
    selector: 'uc-form-field-variation',
    templateUrl: './form-field-variation.html'
})
export class FormFieldVariationComponent implements OnInit {

    @Input() control: UfControlGroup;
    @Input() type: FieldType;
    @Input() id: UfControl;
    @Input() identifier: UfControl;
    @Input() template: UfControl;
    @Input() isReportable: UfControl;
    @Input() meta: FormFieldMetadata;

    readonly fieldTypes = FieldType;
    readonly variationKeys = VariationControlKeys;
    readonly identifierWarningLength = FORM_EDITOR_CONSTANTS.FIELD_IDENTIFIER_WARNING_LENGTH;
    readonly currencies = FORM_EDITOR_CONSTANTS.CURRENCIES;

    readonly controlsKeys = [
        FieldControlKeys.Label,
        FieldControlKeys.Help,
        FieldControlKeys.Placeholder
    ];

    ready: boolean;
    controls: UfFormControl[];

    get isInvalid(): boolean {
        return this.controls.find(c => c.invalid) != null;
    }

    get options(): UfControlArray {
        return this.control.get(VariationControlKeys.Options) as UfControlArray;
    }

    get dataSourceConfig(): UfControl {
        return this.control.get(VariationControlKeys.DataSourceConfig) as UfControl;
    }

    get dataCaptures(): UfControl {
        return this.control.parent?.parent?.get(FieldControlKeys.DataCaptures) as UfControl;
    }

    get validators(): UfControlArray {
        return this.control.get(VariationControlKeys.Validators) as UfControlArray;
    }

    async ngOnInit() {
        this.controls = this.controlsKeys.map(k => this.control.get(k) as UfFormControl).filter(c => c != null);
        this.ready = true;
    }

}