import { Component, HostBinding } from '@angular/core';


@Component({
    templateUrl: './lists.html',
    styleUrls: ['./lists.less']
})
export class ListsComponent {

    @HostBinding('class.stretch-component') class = true;

}


