import { Subscription } from 'rxjs';

import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UfControl, UfControlArray, UfControlGroup } from '@unifii/library/common';
import { Option, StructureNode, StructureNodeType } from '@unifii/sdk';

import { UcStructure } from 'client';

import { StructureControlKeys, StructureNodeControlKeys, StructureNodeVariationControlKeys } from '../structure-control-keys';
import { StructureFunctions } from '../structure-functions';


@Component({
    selector: 'uc-node-details',
    templateUrl: './node-details.html'
})
export class NodeDetailsComponent implements OnInit, OnDestroy {

    @Input() control: UfControlGroup;

    readonly nodeTypes = StructureNodeType;
    readonly variationControlKeys = StructureNodeVariationControlKeys;

    variationsControl: UfControl;
    variationOptions: Option[];
    selectedVariation: Option;
    variation: UfControlGroup | null;

    private subscriptions: Subscription[] = [];

    constructor(private cdr: ChangeDetectorRef) {
        this.variationsControl = new UfControl();
        this.subscriptions.push(this.variationsControl.valueChanges.subscribe(v => console.log('VariationsChanged', v)));
    }

    ngOnInit() {
        if (this.isHome) {
            this.subscriptions.push(this.variations.valueChanges.subscribe(v => this.updateVariationOptions()));
            this.updateVariationOptions();
        }
    }

    ngOnDestroy() {
        // console.log('destroy node details');
        for (const sub of this.subscriptions) {
            sub.unsubscribe();
        }
    }

    selectVariation(variationOption: Option) {

        this.variation = null;
        this.cdr.detectChanges();

        if (!variationOption) {
            return;
        }

        this.variation = this.variations.at(parseInt(variationOption.identifier)) as UfControlGroup;
        this.cdr.detectChanges();
    }

    get node(): StructureNode {
        return this.control.value as StructureNode;
    }

    get bucketOptions(): UfControlArray {
        return this.control.get(StructureNodeControlKeys.BucketOptions) as UfControlArray;
    }

    get args(): UfControlArray {
        return this.control.get(StructureNodeControlKeys.Args) as UfControlArray;
    }

    get variations(): UfControlArray {
        return this.control.get(StructureControlKeys.Variations) as UfControlArray;
    }

    get isHome(): boolean {
        return StructureFunctions.isNodeRoot(this.node);
    }

    get hasVariations(): boolean {
        return StructureFunctions.hasVariations(this.node);
    }

    get hasContentLinked(): boolean {
        return StructureFunctions.isNodeLinkedToContent(this.node);
    }

    get hasVariationContentLinked(): boolean {
        return StructureFunctions.isNodeLinkedToContent(this.variation?.value);
    }

    private updateVariationOptions() {
        this.variationOptions = ((this.control.getRawValue() as UcStructure).variations || []).map((v, i) => ({
            name: v.name as string,
            identifier: `${i}`
        }));
    }
}