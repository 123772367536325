import { Component, Input, OnInit } from '@angular/core';
import { UfControlGroup, UfFormControl } from '@unifii/library/common';
import { FieldType } from '@unifii/sdk';

import { ContextService } from 'services/context.service';

import { FormEditorField, FormFieldMetadata } from '../form-editor-model';
import { GeoLocationNestedControlKey, AddressNestedControlKey, FieldControlKeys, NestedControlKey } from '../form-editor-control-keys';
import { FormEditorFunctions } from '../form-editor-functions';


@Component({
    selector: 'uc-form-field-nested-fields',
    templateUrl: './form-field-nested-fields.html',
    styleUrls: ['./form-field-nested-fields.less']
})
export class FormFieldNestedFieldsComponent implements OnInit {

    @Input() control: UfControlGroup;

    readonly fieldTypes = FieldType;
    readonly fieldKeys = FieldControlKeys;
    readonly addressKeys = AddressNestedControlKey;
    readonly geoLocationKeys = GeoLocationNestedControlKey;
    readonly nestedKeys = NestedControlKey;

    meta: FormFieldMetadata;
    ready: boolean;
    controls: UfFormControl[];

    get field(): FormEditorField {
        return this.control.getRawValue() as FormEditorField;
    }

    get isInvalid(): boolean {
        return this.controls.find(c => c.invalid) != null;
    }

    get nestedControlGroup(): UfControlGroup {
        switch (this.field.type) {
            case FieldType.Address:
                return this.control.get(FieldControlKeys.AddressNested) as UfControlGroup;
            case FieldType.GeoLocation:
                return this.control.get(FieldControlKeys.GeoLocationNested) as UfControlGroup;
            default:
                return null as any;
        }
    }

    get nestedControlNames(): string[] {
        switch (this.field.type) {
            case FieldType.Address:
                return Object.values(AddressNestedControlKey);
            case FieldType.GeoLocation:
                return Object.values(GeoLocationNestedControlKey);
            default:
                return [];
        }
    }

    constructor(private context: ContextService) { }

    ngOnInit() {

        this.meta = FormEditorFunctions.controlMetadata(this.control, this.context);

        let controlsKeys: string[] = [];

        switch (this.field.type) {
            case FieldType.Address:
                controlsKeys = [FieldControlKeys.AddressAutocomplete, FieldControlKeys.AddressNested];
                break;
            case FieldType.GeoLocation:
                controlsKeys = [FieldControlKeys.GeoLocationNested];
                break;
        }

        this.controls = controlsKeys.map(k => this.control.get(k) as UfFormControl).filter(c => c != null);

        this.ready = true;
    }

    getLabel(fieldName: string): string {
        switch (fieldName) {
            case GeoLocationNestedControlKey.LatitudeLongitude: return 'latitude & longitude';
            default: return fieldName;
        }
    }

}