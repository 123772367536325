import { Injectable } from '@angular/core';
import { ModalService } from '@unifii/library/common';


@Injectable({ providedIn: 'root' })
export class DialogsService {

    constructor(private modalService: ModalService) { }

    /** Navigation request from meanwhile in a modified content => Leave Vs Stay */
    async confirmLeaveWithoutSaving(): Promise<boolean> {
        return await this.modalService.openConfirm({
            title: 'Leave Without Saving',
            message: 'Your data won’t be saved if you leave this page.',
            confirmLabel: `Leave`,
            cancelLabel: `Don't Leave`
        }) ?? false;
    }

    /** Delete some content with non-reproducible information => Delete Vs Keep */
    async confirmDelete(): Promise<boolean> {
        return await this.modalService.openConfirm({
            title: 'Delete',
            message: `This deletion can't be undone.`,
            confirmLabel: 'Delete',
            cancelLabel: `Don't Delete`
        }) ?? false;
    }

    /** Connect the SSO provider => Connect Vs Don't Connect */
    async confirmSSOConnect(): Promise<boolean> {
        return await this.modalService.openConfirm({
            title: 'Connect',
            message: `This will enable specified tenant users to sign in with this identity.`,
            confirmLabel: 'Connect',
            cancelLabel: `Don't Connect`
        }) ?? false;
    }

    /** Disconnect the SSO provider => Disconnect Vs Don't Disconnect */
    async confirmSSODisconnect(): Promise<boolean> {
        return await this.modalService.openConfirm({
            title: 'Disconnect',
            message: `This will lose all configuration and will prevent users from logging in with this identity. This action can't be undone.`,
            confirmLabel: 'Disconnect',
            cancelLabel: `Don't Disconnect`
        }) ?? false;
    }

    /** Activate the SSO provider => Activate Vs Don't Activate */
    async confirmSSOActivate(): Promise<boolean> {
        return await this.modalService.openConfirm({
            title: 'Activate',
            message: `This will allow users to log in with this identity.`,
            confirmLabel: 'Activate',
            cancelLabel: `Don't Activate`
        }) ?? false;
    }

    /** Deactovate the SSO provider => Deactivate Vs Activate */
    async confirmSSODeactivate(): Promise<boolean> {
        return await this.modalService.openConfirm({
            title: 'Deactivate',
            message: `This will prevent users from logging in with this identity, but will keep your configuration.`,
            confirmLabel: 'Deactivate',
            cancelLabel: `Don't Deactivate`
        }) ?? false;
    }
}