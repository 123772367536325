import { ChangeDetectorRef, Component } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MessageLevel, UfControl, UfControlGroup, ValidatorFunctions } from '@unifii/library/common';
import { Dictionary, FieldTemplate, FieldType, Option } from '@unifii/sdk';

import { BuilderField } from 'client';

import { BuilderService } from 'components/compound-builder/builder.service';

import { IdentifierFunctions } from 'helpers/helpers';

import { ConfigService } from 'services/config.service';
import { DialogsService } from 'services/dialogs.service';

import { FieldAttributeConfig } from './field-detail-basic';
import { FieldDetailListBasic } from './field-detail-list-basic';


interface InputsConfig extends Dictionary<any> {
    name: FieldAttributeConfig;
    identifier: FieldAttributeConfig;
}

@Component({
    selector: 'uc-field-options',
    templateUrl: './field-options.html',
    styleUrls: ['./indented-list.less']
})
export class FieldOptionsComponent extends FieldDetailListBasic<Option, InputsConfig> {

    static errorIdentifier = 'optionsError';

    identifierMaxLength: number = IdentifierFunctions.IDENTIFIER_MAX_LENGTH;
    expanded: Dictionary<boolean> = {};

    constructor(
        builderService: BuilderService,
        ref: ChangeDetectorRef,
        dialogs: DialogsService,
        private configService: ConfigService
    ) {
        super(builderService, 'options', ref, dialogs);
    }

    get elements(): Option[] {

        if (this.field && this.field.options != null) {
            return this.field.options;
        }

        return [];
    }

    set elements(options: Option[]) {
        this.field.options = options;
    }

    async addOption(option?: Option) {

        option = option || { name: null as any, identifier: null as any };

        this.addElementGroupControl(option, this.elements.length);

        this.expanded['' + this.elements.length] = true;
        this.elements.push(option);
    }

    protected update() {
        if (this.isContentField(this.field)) {
            if (this.isContentTemplate(this.field)) {
                // add content control
                this.formList.controls.forEach(controlGroup => {
                    if (!controlGroup.get('content') && controlGroup instanceof UntypedFormGroup) {
                        (controlGroup as UntypedFormGroup).addControl('content', new UfControl(ValidatorFunctions.required('Content is required')));
                    }
                });
            } else {
                // remove content control
                this.formList.controls.forEach(controlGroup => {
                    if (controlGroup.get('content') && controlGroup instanceof UntypedFormGroup) {
                        (controlGroup as UntypedFormGroup).removeControl('content');
                    }
                });
            }
        }
    }

    protected afterSetup() {

        this.expanded = this.elements
            .reduce((expanded: Dictionary<boolean>, _, index) => {
                expanded['' + index] = false;
                return expanded;
            }, {});
    }

    protected addElementGroupControl(_o: Option, _i: number) {

        const optionGroup = new UfControlGroup({});
        this.formList.push(optionGroup);

        // TODO: Remove when no deployment need old crown data back-compatibility
        const allowCrownCompatibility = this.configService.isCrownCompatible();
        const optionIdentifierRegEx = allowCrownCompatibility ? /^[A-Za-z0-9][A-Za-z0-9_-]*$/ : /^[A-Za-z0-9][A-Za-z0-9_]*$/;

        const config: InputsConfig = {
            identifier: {
                show: true, control: new UfControl(ValidatorFunctions.compose([
                    ValidatorFunctions.required('Identifier is required'),
                    ValidatorFunctions.custom(v => this.elements.map(option => option.identifier).filter(x => x === v).length <= 1, 'Identifier needs to be unique'),
                    ValidatorFunctions.custom(v => optionIdentifierRegEx.test(v), 'Your identifier contains an invalid character'),
                    ValidatorFunctions.custom(v => !/\s/.test(v), 'Identifier can\'t contain a space'),
                    ValidatorFunctions.custom(v => !v || v.length <= this.identifierMaxLength, 'Identifier can\'t be longer than ' + this.identifierMaxLength + ' characters')
                ]))
            },

            name: { show: true, control: new UfControl(ValidatorFunctions.required('Name is required')) },
            content: { show: false, control: new UfControl(ValidatorFunctions.required('Content is required')) }
        };

        // -------------------------------------------------------------------
        // Add warning in case user is at risk at breaking there published form by changing an identifer

        if (!this.field.isNew && this.builderService.definition.lastPublishedAt) {
            this.subscriptions.add(config.identifier.control.valueChanges.subscribe(() => {
                this.builderService.notify.next({
                    level: MessageLevel.Warning,
                    title: 'Warning',
                    message: 'Editing your identifier after your field is published may cause errors with your ' + this.builderService.builder.type
                });
            }));
        }

        if (this.field.type === FieldType.Bool) {
            config.identifier.show = false;
        }

        if (this.isContentField(this.field) && this.isContentTemplate(this.field)) {
            config.content.show = true;
        }

        this.configs.push(config);

        // Add/Remove controls from the form
        Object.keys(config).forEach(k => {
            if (config[k as keyof InputsConfig].show === true && optionGroup.controls[k] == null) {
                // Add control
                optionGroup.addControl(k, config[k as keyof InputsConfig].control);
            }
            if (config[k as keyof InputsConfig].show === false && optionGroup.controls[k] != null) {
                optionGroup.removeControl(k);
            }
        });
    }

    private isContentField(field: BuilderField): boolean {
        return !!([FieldType.MultiChoice, FieldType.Choice].includes(field.type as FieldType)
            && field.template);
    }

    private isContentTemplate(field: BuilderField): boolean {
        return [FieldTemplate.OptionWithContent, FieldTemplate.CheckboxWithContent, FieldTemplate.RadioWithContent].includes(field.template as FieldTemplate);
    }

}
