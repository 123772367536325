import { Component, Input } from '@angular/core';
import { UfControlGroup } from '@unifii/library/common';
import { StructureNode } from '@unifii/sdk';

import { UcRoles } from 'client';

import { StructureNodeControlKeys } from '../structure-control-keys';


@Component({
    selector: 'uc-node-roles',
    templateUrl: './node-roles.html'
})
export class NodeRolesComponent {

    @Input() control: UfControlGroup;

    readonly formKeys = StructureNodeControlKeys;

    rolesResult: string[];

    constructor(
        private roles: UcRoles
    ) { }

    get node(): StructureNode {
        return this.control.getRawValue();
    }

    async findRoles(query?: string) {
        const roles = (await this.roles.get(query)).map(r => r.name);
        this.rolesResult = roles.filter(r => (this.node.roles || []).find(nr => nr === r) == null);
    }

}
