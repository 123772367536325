import {
    AuthProvider, Claim, ClaimConfig, DataSeed, DataSourceType, Definition, Dictionary, FileType, FormData, IntegrationArgument, LogoDto,
    PermissionAction, ProjectInfo, RouteInfo, TenantSettings as sdkTenantSettings, UserInfo
} from '@unifii/sdk';


export enum PermissionPrincipalType {
    Role = 'Role',
    User = 'User',
    ApiKey = 'ApiKey'
}

export enum ResourceType {
    Resources = 'Resources',
    Roles = 'Roles',
    Projects = 'Projects',
    Project = 'Project',
    Structure = 'Structure',
    Versions = 'Versions',
    Version = 'Version',
    VersionArtifacts = 'Version Artifacts',
    VersionArtifact = 'Version Artifact',
    VersionArtifactDiff = 'Version Artifact Diff',
    LatestVersion = 'Latest Version',
    Collections = 'Collections',
    Collection = 'Collection',
    CollectionItems = 'Collection Items',
    CollectionItem = 'Collection Item',
    Views = 'Views',
    View = 'View',
    ViewDefinition = 'View Definition',
    Pages = 'Pages',
    Page = 'Page',
    Forms = 'Forms',
    Form = 'Form',
    FormVersions = 'Form Versions',
    FormVersion = 'Form Version',
    FormDataRepositories = 'Form Data Repositories',
    FormDataRepository = 'Form Data Repository',
    FormDataRepositoryDocuments = 'Form Data Repository Documents',
    FormDataRepositoryDocument = 'Form Data Repository Document',
    FormDataTransactionLog = 'Form Data Transaction Log',
    FormDataTranslationLogDocument = 'Form Data Transaction Log Document',
    Schema = 'Schema',
    Revisions = 'Revisions',
    Revision = 'Revision',
    Files = 'Files',
    File = 'File',
    ExternalDataSources = 'External Data Sources',
    ExternalDataSource = 'External Data Source',
    Tables = 'Tables',
    Table = 'Table',
    Devices = 'Devices',
    Device = 'Device',
    Users = 'Users',
    User = 'User',
    UserToken = 'User Token',
    UserFiles = 'User Files',
    UserFile = 'User File',
    UserName = 'User Name',
    UserPermissions = 'Permissions',
    TicketProviders = 'Ticket Providers',
    TicketProviderIntegration = 'Ticket Provider Integration',
    TicketProvider = 'Ticket Provider',
    Companies = 'Companies',
    Company = 'Company',
    UserClaims = 'User Claims',
    UserClaim = 'User Claim',
    CompanyClaims = 'Company Claims',
    CompanyClaim = 'Company Claim',
    Me = 'Me',
    Units = 'Units',
    Unit = 'Unit'
}

export enum SystemRole {
    SuperUser = 'SuperUser',
    SystemManager = 'SystemManager',
    UserManager = 'UserManager',
    AssetManager = 'AssetManager',
    ProjectManager = 'ProjectManager',
    ContentEditor = 'ContentEditor',
    FormDesigner = 'FormDesigner',
    Publisher = 'Publisher',
    FormDataViewer = 'FormDataViewer',
    WorkflowDesigner = 'WorkflowDesigner',
    Importer = 'Importer',
    Auditor = 'Auditor',
    Translator = 'Translator',
    AppManager = 'AppManager'
}

export enum IntegrationFeatureType {
    Lookup = 'Lookup',
    Sink = 'Sink',
    TicketProvider = 'TicketProvider',
    Validator = 'Validator'
}

export enum WorkflowNotificationState {
    Inactive = 'Inactive',
    Preview = 'Preview',
    Live = 'Live'
}

export enum WorkflowNotificationRecipientType {
    User = 'User',
    Role = 'Role',
    // eslint-disable-next-line @typescript-eslint/no-shadow
    Claim = 'Claim',
    CreatedBy = 'CreatedBy',
    LastModifiedBy = 'LastModifiedBy',
    CreatedByManager = 'CreatedByManager',
    LastModifiedByManager = 'LastModifiedByManager',
    Email = 'Email',
    Combo = 'Combo',
    // eslint-disable-next-line @typescript-eslint/no-shadow
    FormData = 'FormData',
    Hierarchy = 'Hierarchy',
    Company = 'Company',
    EmailField = 'EmailField',
    UserDatasource = 'UserDatasource',
    UserDatasourceManager = 'UserDatasourceManager',
    ClaimDatasource = 'ClaimDatasource',
    UserModified = 'UserModified',
    UserModifiedManager = 'UserModifiedManager',
}

export enum WorkflowNotificationDeliveryMethod {
    Email = 'email',
    Push = 'push',
    Sms = 'sms'
}

export enum DataSourceInputValueSource {
    Form = 'Form', // Comes from a datasource config
    Constant = 'Constant', // Enforced value
    Default = 'Default', // Uses default value if not overriden by front end
    Unset = 'Unset' // Never has a value
}

export enum ClaimSource {
    User = 'user',
    Company = 'company'
}

export enum AuthProviderMappingActionType {
    AssignUnit = 'AssignUnit',
    AssignClaim = 'AssignClaim',
    AssignRole = 'AssignRole',
    AssignSystemRole = 'AssignSystemRole',
    AssignClaimFrom = 'AssignClaimFrom',
}

export interface AuditEntry {
    login: string;
    sourceIp: string;
    at: string;
    resource: string;
    action: string;
    objectId: string;
}

export interface Limits {
    projectsLimit?: number;
    formsLimit?: number;
    sectionsLimit?: number;
    pagesLimit?: number;
    viewsLimit?: number;
}


export interface Media {
    id?: number;
    type: MediaType;
    title?: string;
    description?: string;
    author?: string;
    credits?: string;
    filename: string;
    uploadedAt: string;
    url: string;
    filesize: number;
    width?: number;
    height?: number;
    length?: string;
    thumbUrl?: string;
    tags: number[];
}

export enum MediaType {
    Image = 'Image',
    Video = 'Video',
    Audio = 'Audio',
    File = 'File'
}

export interface TenantSettings extends sdkTenantSettings {
    id: string;
    isAccessControlEnabled: boolean;
    baseUrl?: string;
    logo?: UcLogoDto;
    allowedAssetFileTypes: FileType[] | null;
    allowedAttachmentFileTypes: FileType[] | null;
    features: Dictionary<boolean>;
    language?: {
        code: string;
        name: string;
    };
    refreshTokenExpiry?: number;
    maxSessionLength?: number;
    googleMapsApiKey?: string;
    rememberMe?: boolean;
    rememberMeExpiryDays?: number;
    limits?: Limits;
}

export interface MyAccount {
    id: string;
    username: string;
    roles: string[];
    changePasswordOnNextLogin?: boolean;
}


export interface ApiKey {
    key?: string;
    secret?: string;
    name: string;
    createdAt: string;
    enableOAuth?: boolean;
    createdBy: { id: string; username: string };
}

export interface VersionInfo {
    version: number;
    preview?: number;
    publishedAt: string;
}

export interface PublishStatus {
    stable?: VersionInfo;
    preview?: VersionInfo;
}

export interface RelatedUser {
    id: string;
    username: string;
}

export interface AppInfo {
    id: string;
    name: string;
    createdAt?: string;
    createdBy?: RelatedUser;
    lastModifiedAt?: string;
    lastModifiedBy?: RelatedUser;
}

export interface App extends AppInfo {
    secret?: string;
    googlePushKey?: string;
    appleCert?: string;
    appleCertName?: string;
    appleCertNotAfter?: string;
    applePassword?: string;
    corsOrigins?: string[];
    appleIsSandbox?: boolean;
}

export interface PushNotification {
    title: string;
    message: string;
    route?: RouteInfo;
    devices?: string[];
    userQuery?: string;
}

export interface AuthProviderExtras extends Dictionary<any> {
    audience?: string;
    manualRegistration?: boolean;
    useDirectory?: boolean;
    authorizationServer?: string;
    sswsSecret?: string; // Secret field to load groups for Okta providers
}

export interface AuthProviderDetails {
    type: AuthProvider;
    id: string;
    clientId?: string;
    clientSecret?: string;
    extras?: AuthProviderExtras;
    tenant?: string;
    manualRegistration?: boolean; // todo: check that this is correct or shoul
    manual?: boolean;
    isActive: boolean;
    systemRolesMapping?: { source: string; targets: string[] }[];
    tenantRolesMapping?: { source: string; targets: string[] }[];
    claimsMapping?: AuthProviderClaimMapping[];
    lastModifiedAt?: string;
    featureConfig?: IntegrationFeatureConfig;
    userFieldsMapping?: FieldMappingData;
    mappings?: AuthProviderMapping[];
    scimToken?: string;
    providerLoginLabel?: string;
    disableSsoMapping?: boolean;
}

export type NewAuthProviderDetails = Omit<AuthProviderDetails, 'id'>;

export interface AuthProviderClaimMapping {
    source: string;
    target: string;
    valueMap: Dictionary<string>;
}

export interface FieldMappingData {
    [key: string]: FieldMapping;
}

export interface FieldMapping {
    source: string;
    label?: string;
}

export interface AuthProviderMapping {
    condition?: AuthProviderMappingCondition;
    actions: AuthProviderMappingAction[];
}

export interface AuthProviderMappingCondition {
    type: AuthProviderMappingConditionType;
    identifier?: string;
    value?: string;
    children?: AuthProviderMappingCondition[];
}

export interface AuthProviderMappingAction {
    type: AuthProviderMappingActionType;
    identifier: string;
    value?: string;
}

export interface AuthProviderSourceGroup {
    id: string;
    name: string;
}

export interface ExternalLogin {
    authProvider?: AuthProvider;
    tenant?: string;
    key?: string;
}

export interface UcUserInfo extends UserInfo {
    logins?: ExternalLogin[];
}

export interface UcLogoDto extends LogoDto {
    id: number;
}

export interface SmtpInfo {
    smtpHost: string;
    smtpPort?: number;
    smtpSystemName?: string;
    smtpSystemEmail: string;
    smtpUsername: string;
    smtpPassword: string;
}
export interface EmailInfo {
    inviteEmailExpiry: number;
    inviteEmailTemplates: EmailTemplate[];
    resetPasswordEmailTemplates: EmailTemplate[];
    emailColour?: string;
    replyTo?: string;
}

export interface EmailTemplate {
    title: string;
    subject: string;
    introMessage: string;
    bodyMessage: string;
    buttonLabel: string;
    conditions: EmailTemplateCondition[];
    baseUrl?: string | null;
    index?: number;
    replyTo?: string;
}

export interface EmailTemplateCondition {
    role?: string;
    claim?: Claim;
}

export interface UcProjectInfo extends ProjectInfo {
    logo?: UcLogoDto;
    hasImport: boolean;
    importToolUrl?: string;
    version?: number;
    publishedAt: string;
    previewApp?: AppInfo;
    liveApp?: AppInfo;
}

export interface DefaultClaim {
    id?: string;
    type: string;
    createdAt?: string;
    createdBy?: RelatedUser;
}

export interface UcClaimConfig extends ClaimConfig {
    createdAt?: string; // standard RFC3339 UTC timestamp
    createdBy?: RelatedUser;
    // readonly, assigned by server on mutation
    lastModifiedAt?: string; // standard RFC3339 UTC timestamp
    lastModifiedBy?: RelatedUser;
    // Enables search on field types Text, TextArray, Phone, Website, Email
    isSearchable?: boolean;
}


export interface Role {
    id?: string;
    name: string;
    description?: string;
    permissionsCount?: number;
}

export interface Permission {
    id?: string;
    path?: string[];
    principalId: string;
    principalType: PermissionPrincipalType;
    actions?: PermissionAction[];
    condition?: string; // transformed to an AstNode when served in /v0
    description?: string;
    fields?: string[];
}

export interface Resource {
    name: ResourceType;
    segment: string;
    actions?: { name: PermissionAction; description: string }[];
    allowsCondition?: boolean;
    children?: Resource[];
}

export interface ResourceElement {
    id: string;
    name: string;
}

export interface ProjectTranslations {
    id: string;
    name: string;
    publishedAt: string;
    version: number;
    languages: {
        code: string;
        name: string;
        approved: number;
        draft: number;
        total: number;
    }[];
}

export interface WorkflowNotificationMessage {
    title?: string;
    body?: string;
    replyTo?: string;
    attachFormAsPdf?: boolean;
}

export enum WorkflowNotificationConditionType {
    Role = 'Role',
    // eslint-disable-next-line @typescript-eslint/no-shadow
    Claim = 'Claim',
    Hierarchy = 'Hierarchy',
    Company = 'Company'
}

export enum WorkflowNotificationRecipientClaimMatchType {
    Value = 'Value',
    CreatedBy = 'CreatedBy',
    LastModifiedBy = 'LastModifiedBy',
    // eslint-disable-next-line @typescript-eslint/no-shadow
    FormData = 'FormData',
    UserModified = 'UserModified',
}

export enum WorkflowNotificationRecipientFormDataType {
    User = 'User',
    UserManager = 'UserManager',
    Email = 'Email',
}

export interface WorkflowNotificationMessageInfo {
    sms?: WorkflowNotificationMessage;
    push?: Omit<WorkflowNotificationMessage, 'attachFormAsPdf'>;
    email?: WorkflowNotificationMessage;
}

export interface WorkflowNotificationRecipient {
    liveOnly: boolean;
    type: WorkflowNotificationRecipientType;
}

export interface WorkflowNotificationRecipientEmail extends WorkflowNotificationRecipient {
    email: string;
}

export interface WorkflowNotificationRecipientRole extends WorkflowNotificationRecipient {
    role: string;
}
export interface WorkflowNotificationRecipientClaimData {
    type: string;
    value: string;
    matchType?: WorkflowNotificationRecipientClaimMatchType;
    matchAgainst?: string;
}
export interface WorkflowNotificationRecipientClaim extends WorkflowNotificationRecipient {
    claim: WorkflowNotificationRecipientClaimData;
}

export type WorkflowNotificationConditionRole = Omit<WorkflowNotificationRecipientRole, 'liveOnly' | 'type'>;
export type WorkflowNotificationConditionClaim = Omit<WorkflowNotificationRecipientClaim, 'liveOnly' | 'type'>;

export interface WorkflowNotificationConditionHierarchy {
    hierarchy: {
        formData: string;
        value: string;
    };
}

export interface WorkflowNotificationConditionCompany {
    company: string;
}

export type WorkflowNotificationRecipientComboTypes =
    WorkflowNotificationConditionRole |
    WorkflowNotificationConditionClaim |
    WorkflowNotificationConditionCompany |
    WorkflowNotificationConditionHierarchy;

export interface WorkflowNotificationRecipientComboCompany extends WorkflowNotificationRecipient {
    company: string;
}

export interface WorkflowNotificationRecipientCombo extends WorkflowNotificationRecipient {
    conditions: WorkflowNotificationRecipientComboTypes[];
}

export type WorkflowNotificationRecipients =
    WorkflowNotificationRecipientFormData |
    WorkflowNotificationRecipientUser |
    WorkflowNotificationRecipient |
    WorkflowNotificationRecipientEmail |
    WorkflowNotificationRecipientRole |
    WorkflowNotificationRecipientClaim |
    WorkflowNotificationRecipientCombo;

export interface WorkflowNotificationRecipientFormData extends WorkflowNotificationRecipient {
    formData: {
        value: string;
        type: WorkflowNotificationRecipientFormDataType;
    };
}

export interface WorkflowNotificationRecipientUser extends WorkflowNotificationRecipient {
    user: {
        id: string;
        username: string;
    };
}

export interface WorkflowNotification extends WorkflowActivityInfo {
    messages: WorkflowNotificationMessageInfo;
    recipients: WorkflowNotificationRecipient[];
    createdAt: string;
    createdBy: RelatedUser;
    lastModifiedAt: string;
    lastModifiedBy: RelatedUser;
}
export interface DataForwarder extends WorkflowActivityInfo {
    type: ActivityType.DataForwarder;
    name?: string;
    integrationId?: string;
    featureId?: string;
    inputMap?: Dictionary<string>;
}

export interface WorkflowBasicCondition {
    expression?: string;
}

export interface FormSubmittedCondition extends WorkflowBasicCondition {
    bucket?: string;
    transitions?: {
        source: string;
        action: string;
    }[];
    forms?: string[]; // form identifiers (within the bucket)
}

export interface RoleAddedCondition extends WorkflowBasicCondition {
    roles?: string[];
    includeNewUser?: boolean;
    includeExternal?: boolean;
}

export type WorkflowCondition = FormSubmittedCondition | WorkflowBasicCondition | RoleAddedCondition;

// --------------------------------------------------
// Activities

export enum ActivityType {
    Timer = 'Timer',
    DataForwarder = 'DataForwarder',
    Notification = 'Notification'
}

export interface WorkflowActivityInfo {
    id: string;
    label: string;
    createdAt: string;
    createdBy: RelatedUser;
    lastModifiedAt: string;
    lastModifiedBy: RelatedUser;
    type: ActivityType;
    bucket?: string; // Advisory for autocomplete
}

export enum WorkflowValueTypes {
    Value = 'value',
    Expression = 'expression'
}

export interface Timer extends WorkflowActivityInfo {
    type: ActivityType.Timer;
    expression: string; // will result to datatime value
}

// --------------------------------------------------
// Workflow rule

export enum WorkflowEventType {
    Timer = 'Timer',
    FormSubmitted = 'FormSubmitted',
    ApiEvent = 'Api',
    RoleAdded = 'RoleAdded'
}

export type WorkflowEvent = FormSubmittedEvent | TimerEvent | APIEvent | RoleAddedEvent;

export interface TimerEvent {
    type: WorkflowEventType.Timer;
    timerId?: string;
}

export interface RoleAddedEvent {
    type: WorkflowEventType.RoleAdded;
}

export interface FormSubmittedEvent {
    type: WorkflowEventType.FormSubmitted;
}

export interface APIEvent {
    type: WorkflowEventType.ApiEvent;
    integrationId?: string;
    featureId?: string;
}

export enum WorkflowState {
    Active = 'Active',
    Inactive = 'Inactive',
    Preview = 'Preview',
    DryRun = 'DryRun'
}

export interface WorkflowRuleActivity {
    id: string;
    label: string;
    type: ActivityType;
}

export interface WorkflowRule {
    id: string;
    label: string;
    createdAt: string;
    createdBy: RelatedUser;
    lastModifiedAt: string;
    lastModifiedBy: RelatedUser;
    event: WorkflowEvent;
    condition?: WorkflowCondition;
    activities: WorkflowRuleActivity[];
    state: WorkflowState; //?? can we remove preview
}

export type NewWorkflowRule = Omit<WorkflowRule, 'id' | 'state' | 'createdAt' | 'createdBy' | 'lastModifiedAt' | 'lastModifiedBy'>;


export interface IntegrationProviderInfo {
    id: string;
    name: string;
    description: string;
    logo: string; // base64, maybe full DATA URI
}

export interface IntegrationProvider extends IntegrationProviderInfo {
    configForm?: Definition;
    features: IntegrationProviderFeature[];
}

export interface IntegrationProviderFeature {
    id: string;
    type: IntegrationFeatureType;
    name: string;
    configurable: boolean;
    disabled: boolean;
    inputArgs?: IntegrationArgument[];
    outputArgs: IntegrationArgument[];
    idArg?: string;
    displayArg?: string;
}

export interface IntegrationInfo {
    id?: string;
    name: string;
    provider: IntegrationProviderInfo;
}

export interface IntegrationFeatureConfig {
    [key: string]: {
        disabled: boolean;
        label?: string;
    };
}
export interface Integration {
    id?: string;
    name: string;
    provider: IntegrationProvider;
    config: FormData;
    createdAt?: string;
    createdBy?: RelatedUser;
    lastModifiedAt?: string;
    lastModifiedBy?: RelatedUser;
    featureConfig?: IntegrationFeatureConfig;
}

export interface DataSourceInfo {
    id: string;
    name: string;
    description: string;
    integrationName: string;
    integrartionTypeLogo: string; // base64
    integrationProviderName: string;
    lastModifiedAt: string;
}

export interface ConsoleDataSource {
    id?: string;
    type: DataSourceType;
    name: string;
    description?: string;
    integrationId: string;
    featureId: string;
    inputMap: Dictionary<DataSourceInput>; // refers to provider.inputArgs[].identifier
    outputMap: DataSeed;
}

export interface DataSourceInput {
    source: DataSourceInputValueSource;
    value: string;
}

export interface DataForwarderInfo {
    id: string;
    name: string;
    integrationName: string;
    integrationProviderName: string;
    integrationProviderLogo: string; // base64
    featureId: string;
    createdAt: string;
    createdBy: RelatedUser;
    lastModifiedAt: string;
    lastModifiedBy: RelatedUser;
}

export enum AuthProviderMappingConditionType {
    GroupMembership = 'GroupMembership',
    RoleAssignment = 'RoleAssignment',
    ClaimValue = 'ClaimValue',
    ClaimFrom = 'ClaimFrom',
    And = 'And',
    Or = 'Or',
}