<uf-panel class="locked form-preview" [class.settings-visible]="settingsVisible">

    <div *ngIf="formData" [class.push-right]="settingsVisible" class="header">
        <div class="title">
            <h4>Form Preview <span *ngIf="edited">*</span></h4>
            <h4>v{{config.version}}</h4>
        </div>
        <div *ngIf="formData._state" class="fieldset-item--small--stacked">
            <div class="fieldset-label">Current state</div>
            <div class="fieldset-value">{{formData._state}}</div>
        </div>
        <div *ngIf="formData._result" class="fieldset-item--small--stacked">
            <div class="fieldset-label">Result</div>
            <div class="fieldset-value">{{formData._result}}</div>
        </div>
        <div *ngIf="formDebugger.roles?.length" class="fieldset-item--small--stacked">
            <div class="fieldset-label">Roles</div>
            <div class="fieldset-value">{{formDebugger.roles | fieldDisplay}}</div>
        </div>
        <button class="uf-action tertiary" (click)="load()" title="Refresh">
            <uf-icon name="refreshApp"></uf-icon>
        </button>
        <button class="uf-action tertiary" (click)="settingsVisible = !settingsVisible" title="Toggle Settings">
            <uf-icon name="settings"></uf-icon>
        </button>

        <div class="header-shadow"></div>

    </div>

    <uf-panel class="preview" [class.push-right]="settingsVisible">
        <div class="grid--fixed">
            <div class="col-1of1" *ngIf="formLoadError">
                <br>
                <uf-blockquote class="error" icon="errorSolid" heading="Configuration missing or corrupted!"
                    content="Please close and restart the FormBuilder">
                    <div class="col-1of1 row space-children">
                        <button class="uf-button tertiary right" (click)="close()">Close</button>
                    </div>
                </uf-blockquote>
            </div>
            <uf-form *ngIf="!formLoadError" class="col-1of1--no-padding" [config]="formConfig" [(formData)]="formData"
                [definition]="definition" (submit)="submit($event)" (cancel)="close()">
            </uf-form>
        </div>
    </uf-panel>

    <uf-panel class="settings">
        <div class="settings-shadow"></div>
        <div class="grid" *ngIf="definition != null">

            <div class="col-1of1">
                <h4>Form Info</h4>
                <div class="fieldset-item--small--stacked">
                    <div class="fieldset-label">Name</div>
                    <div class="fieldset-value">{{definition.label}}</div>
                </div>
                <div class="fieldset-item--small--stacked">
                    <div class="fieldset-label">Identifier</div>
                    <div class="fieldset-value">{{definition.identifier}}</div>
                </div>
                <div class="fieldset-item--small--stacked">
                    <div class="fieldset-label">Bucket</div>
                    <div class="fieldset-value">{{definition.bucket}}</div>
                </div>
            </div>

            <div class="col-1of1">
                <h4>Workflow</h4>
                <div class="grid--compact">
                    <uf-checkbox class="col-1of1--compact" [(value)]="formDebugger.enableWorkflow"
                        (valueChange)="toggleWorkflow()" label="Enabled">
                    </uf-checkbox>
                    <uf-checkbox class="col-1of1--compact" *ngIf="formDebugger.enableWorkflow"
                        [(value)]="enableTriggers" label="Triggers">
                    </uf-checkbox>
                </div>
            </div>

            <div class="col-1of1">
                <uf-select *ngIf="formDebugger.enableWorkflow" [(value)]="formDebugger.state" [options]="states"
                    valueProperty="identifier" label="State">
                </uf-select>
            </div>

            <div class="col-1of1">
                <h4>Validation</h4>
                <uf-select [(value)]="formDebugger.validation" nameProperty="label" valueProperty="value" label="Level"
                    [options]="validationOptions">
                </uf-select>
            </div>

            <div class="col-1of1">
                <h4>User</h4>
                <uf-multi-choice *ngIf="formRoles?.length" label="Roles" [(value)]="formDebugger.roles"
                    [options]="formRoles" columns="1" valueProperty="identifier"
                    [disabled]="!formDebugger.enableWorkflow">
                </uf-multi-choice>
                <div *ngIf="!formDebugger.enableWorkflow" class="error-text">Unavailable while workflow is disabled
                </div>
            </div>

            <div class="col-1of1">
                <h4>Actions</h4>
                <button class="uf-button" (click)="load()">Reset</button>
            </div>

            <div class="col-1of1">
                <h4>Current Values</h4>
                <div class="fieldset-item--small data" *ngIf="formData">
                    <div class="fieldset-label">Data</div>
                    <uf-checkbox label="Hide" [(value)]="hideData">
                    </uf-checkbox>
                    <div class="fieldset-value" *ngIf="!hideData">
                        {{formData | json}}
                    </div>
                    <!-- <div class="fieldset-value">
                        <uf-help [content]="formData | json"></uf-help>
                    </div> -->
                </div>
            </div>
        </div>
    </uf-panel>

</uf-panel>