import * as Animations from 'animations';

import { Component, HostBinding } from '@angular/core';
import { Breadcrumb } from '@unifii/library/common';

import { ContextService } from 'services/context.service';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbService } from 'services/breadcrumb.service';


@Component({
    templateUrl: './language-overview.html'
})
export class LanguageOverviewComponent {

    @HostBinding('class.stretch-component') class = true;

    items: {
        label: string;
        route: any[];
        roles: string;
        image: string;
    }[];

    breadcrumbs: Breadcrumb[];

    constructor(
        public context: ContextService,
        private route: ActivatedRoute,
        private breadcrumbService: BreadcrumbService
    ) {
        this.items = [{
            label: 'Collections',
            route: ['collections'],
            roles: 'Translator,Publisher',
            image: '/assets/svg/new-icons/collections.svg'
        }, {
            label: 'Views',
            route: ['views'],
            roles: 'Translator,Publisher',
            image: '/assets/svg/new-icons/views.svg'
        }/*, {
            label: 'Pages',
            route: ['pages'],
            roles: 'Translator,Publisher',
            image: '/assets/svg/icon-pages.svg'
        }*/];
        const currentName = [context.project?.name, context.language?.name].filter(s => s != null).join(' ');
        this.breadcrumbs = this.breadcrumbService.getBreadcrumbs(this.route, [currentName]);
    }
}