import { CollectionBuilderComponent } from './collection-builder.component';
import { CollectionDataComponent } from './collection-data.component';
import { CollectionFieldComponent } from './collection-field.component';
import { CollectionItemBuilderComponent } from './collection-item-builder.component';
import { CollectionSettingsComponent } from './collection-settings.component';
import { CollectionComponent } from './collection.component';
import { CollectionsComponent } from './collections.component';


const COLLECTION_COMPONENTS = [CollectionDataComponent, CollectionItemBuilderComponent, CollectionFieldComponent, CollectionComponent, CollectionsComponent, CollectionBuilderComponent, CollectionSettingsComponent];

export { COLLECTION_COMPONENTS, CollectionDataComponent, CollectionItemBuilderComponent, CollectionFieldComponent, CollectionComponent, CollectionsComponent, CollectionBuilderComponent, CollectionSettingsComponent };
