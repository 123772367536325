import { TableDataSource, TableDataSourceQueryOptions } from '@unifii/library/common';
import { RequestAnalyticsOrigin } from '@unifii/sdk';

import { SchemaInfo, UcProject } from 'client';


export class BucketsDataSource extends TableDataSource<SchemaInfo> {

    filtered: boolean;
    sorted: boolean;

    constructor(private ucProject: UcProject) {
        super();
    }

    load(options?: TableDataSourceQueryOptions) {
        this.ucProject.getFormBuckets({
            params: { offset: options?.offset, limit: options?.limit },
            analytics: { origin: RequestAnalyticsOrigin.Table }
        }).then(
            schemas => this.stream.next({ data: schemas }),
            error => this.stream.next({ error })
        );
    }

}
