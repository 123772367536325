import {
    CollectionItemMetadataIdentifiers, CompanyIdentifiers, DataPropertyDescriptor, DataSourceUserFullNameExpression,
    FormDefinitionMetadataIdentifiers, UfControlGroup, UserInfoIdentifiers
} from '@unifii/library/common';
import { AstNode, DataSourceType, FieldType, generateUUID, NodeType } from '@unifii/sdk';

import { DataSourceInputValueSource } from 'client';

import { DataSourceExternalInput, DataSourceMapping, DataSourceMappingControlKeys, ExternalInfo, idBasedDSTypes } from './data-source-model';


export const DataSourceIdTo = '_id';
export const DataSourceIdLabel = 'Id';

export const DataSourceDisplayTo = '_display';
export const DataSourceDisplayLabel = 'Display';

export const hasMappings = (dataSourceType?: DataSourceType | null): boolean =>
    dataSourceType != null && [
        DataSourceType.Collection,
        DataSourceType.Bucket,
        DataSourceType.Users,
        DataSourceType.UserClaims,
        DataSourceType.Company,
        DataSourceType.CompanyClaims
    ].includes(dataSourceType);

export const hasSort = (dataSourceType?: DataSourceType | null): boolean =>
    dataSourceType != null && [
        DataSourceType.Collection,
        DataSourceType.Bucket,
        DataSourceType.Users,
        DataSourceType.Company,
    ].includes(dataSourceType);

export const hasFilter = (dataSourceType?: DataSourceType | null): boolean =>
    dataSourceType != null && [
        DataSourceType.Collection,
        DataSourceType.Bucket,
        DataSourceType.Users,
        DataSourceType.Company,
    ].includes(dataSourceType);

export const hasVisibleFilters = (dataSourceType?: DataSourceType | null, fieldType?: FieldType | null): boolean =>
    hasFilter(dataSourceType) && fieldType === FieldType.Lookup;

export const hasFindBy = (dataSourceType?: DataSourceType | null): boolean =>
    dataSourceType != null && [
        DataSourceType.Collection,
        DataSourceType.Bucket,
        DataSourceType.Users,
    ].includes(dataSourceType);

export const isValidTypeAndIdConfiguration = (dataSourceType?: DataSourceType | null, id?: string | null): boolean =>
    dataSourceType != null &&
    (!idBasedDSTypes.includes(dataSourceType) || id != null);

export const isValueExpression = (value?: string | null): boolean =>
    value ? /{{.*}}*/g.test(value) : false;

export const getAttribute = (mapping: UfControlGroup, fieldIdentifier?: string): string =>
    `${fieldIdentifier ? fieldIdentifier + '.' : ''}${mapping.get(DataSourceMappingControlKeys.To)?.value}`;

export const getDataSourceDefaults = (fieldType: FieldType, type: DataSourceType | null, id: string | null, mappableProperties: DataPropertyDescriptor[], externalInfo?: ExternalInfo) => {

    const mappablesMap = new Map<string, DataPropertyDescriptor>();
    for (const prop of mappableProperties) {
        mappablesMap.set(prop.identifier, prop);
    }

    const defaults = {
        sort: null as null | string,
        filter: null as null | AstNode,
        namedId: null as null | string,
        mappings: undefined as undefined | DataSourceMapping[],
        externalInputs: undefined as undefined | DataSourceExternalInput[]
    };

    if (!isValidTypeAndIdConfiguration(type, id)) {
        return defaults;
    }

    switch (type) {

        case DataSourceType.Bucket:

            const formSeqIdDProperty = mappablesMap.get(FormDefinitionMetadataIdentifiers.SeqId) as DataPropertyDescriptor;
            const formIdProperty = mappablesMap.get(FormDefinitionMetadataIdentifiers.Id) as DataPropertyDescriptor;

            defaults.mappings = [{
                uuid: generateUUID(),
                from: formIdProperty,
                type: formIdProperty.type,
                to: DataSourceIdTo,
                label: DataSourceIdLabel
            }, {
                uuid: generateUUID(),
                from: formSeqIdDProperty,
                type: formSeqIdDProperty.type,
                to: DataSourceDisplayTo,
                label: DataSourceDisplayLabel,
                isVisible: true
            }, {
                uuid: generateUUID(),
                from: formSeqIdDProperty,
                type: formSeqIdDProperty.type,
                to: formSeqIdDProperty.identifier,
                label: formSeqIdDProperty.label,
                isVisible: true
            }];

            defaults.sort = FormDefinitionMetadataIdentifiers.SeqId;
            break;

        case DataSourceType.UserClaims:
        case DataSourceType.CompanyClaims:

            const claimIdProperty: DataPropertyDescriptor = {
                identifier: 'id',
                type: FieldType.Text,
                label: DataSourceIdLabel,
                display: `${DataSourceIdLabel} (id)`,
                asDisplay: true,
                asSearch: false,
                asSort: false,
                asInputFilter: false,
                asStaticFilter: false
            };

            const claimDisplayProperty: DataPropertyDescriptor = {
                identifier: 'display',
                type: FieldType.Text,
                label: DataSourceDisplayLabel,
                display: `${DataSourceDisplayLabel} (display)`,
                asDisplay: true,
                asSearch: false,
                asSort: false,
                asInputFilter: false,
                asStaticFilter: false
            };

            defaults.mappings = [{
                uuid: generateUUID(),
                from: claimIdProperty,
                type: claimIdProperty.type,
                to: DataSourceIdTo,
                label: claimIdProperty.label
            }, {
                uuid: generateUUID(),
                from: claimDisplayProperty,
                type: claimDisplayProperty.type,
                to: DataSourceDisplayTo,
                label: claimDisplayProperty.label
            }];
            break;

        case DataSourceType.Collection:

            const collectionIdProperty = mappablesMap.get(CollectionItemMetadataIdentifiers.Id) as DataPropertyDescriptor;
            const collectionTitleProperty = mappablesMap.get(CollectionItemMetadataIdentifiers.Title) as DataPropertyDescriptor;

            defaults.mappings = [{
                uuid: generateUUID(),
                from: collectionIdProperty,
                type: collectionIdProperty.type,
                to: DataSourceIdTo,
                label: collectionIdProperty.label
            }, {
                uuid: generateUUID(),
                from: collectionTitleProperty,
                type: collectionTitleProperty.type,
                to: DataSourceDisplayTo,
                label: collectionTitleProperty.label
            }];

            defaults.sort = collectionTitleProperty.identifier;
            break;

        case DataSourceType.Company:

            const companyIdProperty = mappablesMap.get(CompanyIdentifiers.Id) as DataPropertyDescriptor;
            const companyNameProperty = mappablesMap.get(CompanyIdentifiers.Name) as DataPropertyDescriptor;
            const companyStatusProperty = mappablesMap.get(CompanyIdentifiers.Status) as DataPropertyDescriptor;

            defaults.mappings = [{
                uuid: generateUUID(),
                from: companyIdProperty,
                type: companyIdProperty.type,
                to: DataSourceIdTo,
                label: companyIdProperty.label
            }, {
                uuid: generateUUID(),
                from: companyNameProperty,
                type: companyNameProperty.type,
                to: DataSourceDisplayTo,
                label: companyIdProperty.label
            }, {
                uuid: generateUUID(),
                from: companyStatusProperty,
                type: companyStatusProperty.type,
                to: companyStatusProperty.identifier,
                label: companyStatusProperty.label
            }];

            defaults.sort = CompanyIdentifiers.Name;
            break;

        case DataSourceType.Users:

            const userIdProperty = mappablesMap.get(UserInfoIdentifiers.Id) as DataPropertyDescriptor;
            const userUsernameProperty = mappablesMap.get(UserInfoIdentifiers.Username) as DataPropertyDescriptor;

            defaults.mappings = [{
                uuid: generateUUID(),
                from: userIdProperty,
                type: userIdProperty.type,
                to: DataSourceIdTo,
                label: DataSourceIdLabel
            }, {
                uuid: generateUUID(),
                isFromExpression: true,
                fromExpression: DataSourceUserFullNameExpression,
                type: userIdProperty.type,
                to: DataSourceDisplayTo,
                label: DataSourceDisplayLabel
            }, {
                uuid: generateUUID(),
                from: userUsernameProperty,
                type: userUsernameProperty.type,
                to: userUsernameProperty.identifier,
                label: userUsernameProperty.label
            }];

            defaults.filter = {
                type: NodeType.Combinator,
                op: 'and',
                args: [{
                    op: 'eq',
                    type: NodeType.Operator,
                    args: [{
                        type: NodeType.Identifier,
                        value: 'isActive'
                    }, {
                        type: NodeType.Value,
                        value: true
                    }]
                }]
            };

            defaults.sort = UserInfoIdentifiers.Username;
            break;

        case DataSourceType.External:
            if (externalInfo != null) {
                defaults.externalInputs = externalInfo.feature.inputArgs?.map(arg => {
                    const input = externalInfo.dataSource.inputMap[arg.identifier];
                    const ei: DataSourceExternalInput = {
                        info: {
                            parameter: arg.identifier,
                            type: arg.type,
                            source: input?.source as DataSourceInputValueSource,
                            required: input?.source === DataSourceInputValueSource.Default ? false : arg.isRequired,
                            placeholder: input?.source === DataSourceInputValueSource.Default ? `\\${input?.value}\\` : undefined
                        }
                    };
                    return ei;
                }) ?? [];
            }
            break;
    }

    // _display mapping is defaulted true for Repeat field
    const displayMapping = defaults.mappings?.find(m => m.to === DataSourceDisplayTo);
    if (displayMapping && fieldType === FieldType.Repeat) {
        displayMapping.isVisible = true;
    }

    return defaults;
};