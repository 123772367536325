import { inject } from '@angular/core';
import { CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';

import { MyAccount, TokenService, UcClient } from 'client';

import { ContextService } from '../services/context.service';


export const canActivateAccount: CanActivateFn = async (_, state: RouterStateSnapshot) => {

    const client = inject(UcClient);
    const tokenService = inject(TokenService);
    const router = inject(Router);
    const context = inject(ContextService);

    const params = state.url !== '/' ? { next: state.url } : {};


    if (tokenService.token == null) {
        router.navigate(['/login', params]);
        return false;
    }

    const isUpdate = context.account != null;
    let getAccountError;
    let result: boolean | undefined;

    try {
        const promises = [client.getMyAccount()];

        if (isUpdate) {
            promises.push(new Promise((_r, rej) => setTimeout(() => rej(), 500)));
        }

        const getAccountPromise = Promise.race(promises);

        context.account = await getAccountPromise;

    } catch (error) {
        getAccountError = error;

    } finally {

        if (!isUpdate && getAccountError) {
            router.navigate(['/login', params]);
            result = false;
        }

        if (result != null && (context.account as MyAccount).changePasswordOnNextLogin) {
            router.navigate(['/login', params]);
            result = false;
        }

        if (result == null) {
            result = true;
        }
    }

    return result as boolean;
}
