<ng-template [ngIf]="ready">

    <div class="header">
        <button type="button" class="uf-action tertiary" (click)="close()" title="Close">
            <uf-icon name="close"></uf-icon>
        </button>
        <h3>{{searchConfig.title}}</h3>
    </div>

    <div class="content">
        <uf-search [search]="query" (searchChange)="filter($event)" [autofocus]="true">
        </uf-search>
    </div>

    <uf-panel (scrollBottom)="onScrolledToEnd()" [bottomThreshold]="50">

        <ul class="media-select">
            <li *ngIf="!busy && query?.length && !items.length">
                Sorry, there was no result for your search.
            </li>
            <li *ngFor="let item of items, let i = index">
                <a (click)="select(i)" [ngClass]="{active: item.selected}">
                    <p>{{item.name}} |
                        <span class="font-size-sm">
                            {{item.definitionLabel ? item.definitionLabel : searchConfig.type}}
                        </span>
                    </p>
                </a>
            </li>
            <li *ngIf="busy">
                <uf-spinner class="padded"></uf-spinner>
            </li>
        </ul>

    </uf-panel>

    <div *ngIf="error" class="content">
        <div class="error-text">{{error}}</div>
    </div>

    <div class="row space-children pad">
        <button type="button" class="uf-button tertiary right" (click)="close()">Close</button>
        <button *ngIf="searchConfig.multiSelect" type="button" class="uf-button" (click)="updateAll(!selectAll)">
            {{selectAll ? 'Unselect All' : 'Select All'}}
        </button>
        <button type="button" class="uf-button primary" (click)="save()">
            {{ searchConfig.multiSelect ? 'Add' : 'Select' }}
        </button>
    </div>

</ng-template>