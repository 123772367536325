<uf-expander *ngIf="field && compound && translation" class="uc-group col-1of1">
    <div expanderHeader class="uf-app-bar flat">
        <div class="title">
            {{label}} ({{field.identifier}})
        </div>
        <button *ngIf="translatable" class="uf-action tertiary" (click)="toggle()" title="Enable">
            <uf-icon name="{{enabled ? 'checkboxTick' : 'checkbox'}}"></uf-icon>
        </button>
        <button *ngIf="translatable" class="uf-action tertiary" (click)="copy()" title="Copy">
            <uf-icon name="copy"></uf-icon>
        </button>
        <button *ngIf="translatable" [disabled]="!enabled" class="uf-action tertiary" (click)="clear()" title="Clear">
            <uf-icon name="backspace"></uf-icon>
        </button>
    </div>
    <div expanderBody class="row">
        <div class="uc-group-content grow grid--no-padding">
            <uf-field class="col-1of1" [field]="field" [content]="compound[$any(field.identifier)]"
                [control]="disabledControl">
            </uf-field>
        </div>
        <div class="uc-group-content grow" [ngClass]="{'inactive': !enabled, 'grid--no-padding': enabled }">
            <uf-field *ngIf="enabled" class="col-1of1" [field]="field"
                [(content)]="translation[$any(field.identifier)]">
            </uf-field>
            <span *ngIf="!translatable">No translation required</span>
            <span *ngIf="translatable && !enabled">Using source</span>
        </div>
    </div>
</uf-expander>