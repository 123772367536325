<form (ngSubmit)="add()" [formGroup]="form" class="uf-form-card">
    <div class="uf-app-bar">
        <button (click)="runtime.close()" type="button" class="uf-action tertiary">
            <uf-icon name="close"></uf-icon>
        </button>
        <h3>
            Edit
        </h3>
    </div>
    <div class="uf-grid pad gaps">
        <uf-text ufAutofocus [formControlName]="columnKey.Label" label="Label" class="col-12"></uf-text>
    </div>
    <div class="uf-form-actions small">
        <button (click)="runtime.close()" type="button" class="uf-button tertiary right">
            Cancel
        </button>
        <button type="submit" class="uf-button primary">
            Save
        </button>
    </div>
</form>