
import { FilterEntry, FilterType } from '@unifii/library/common';

import { ActivityType, UcIntegrations, UcProject, WorkflowEventType, WorkflowState } from 'client';

import { BucketLoader } from './bucket-loader';
import { WorkflowActivityLabel, WorkflowEventLabel } from './constants';
import { IntegrationLoader } from './integration-loader';


export const activityFilterFactory = (ucProject: UcProject): FilterEntry[] => [
    {
        identifier: 'bucket',
        label: 'Form Data Repository',
        type: FilterType.DataSeed,
        loader: new BucketLoader(ucProject),
    }
];

export const dataForwarderFilterFactory = (ucProject: UcProject, ucIntegration: UcIntegrations): FilterEntry[] => [
    {
        identifier: 'bucket',
        label: 'Form Data Repository',
        type: FilterType.DataSeed,
        loader: new BucketLoader(ucProject),
    },
    {
        identifier: 'integrationId',
        label: 'Integration',
        type: FilterType.DataSeed,
        loader: new IntegrationLoader(ucIntegration),
    }
];

export const workflowRuleFilterFactory = (ucProject: UcProject): FilterEntry[] => [
    {
        identifier: 'eventType',
        label: 'Event',
        type: FilterType.Choice,
        options: [
            { identifier: WorkflowEventType.FormSubmitted, name: WorkflowEventLabel[WorkflowEventType.FormSubmitted] },
            { identifier: WorkflowEventType.Timer, name: WorkflowEventLabel[WorkflowEventType.Timer] },
            { identifier: WorkflowEventType.ApiEvent, name: WorkflowEventLabel[WorkflowEventType.ApiEvent] },
            { identifier: WorkflowEventType.RoleAdded, name: WorkflowEventLabel[WorkflowEventType.RoleAdded] },
        ]
    },
    {
        identifier: 'state',
        label: 'State',
        type: FilterType.Choice,
        options: [
            { identifier: WorkflowState.Active, name: 'Active' },
            { identifier: WorkflowState.Inactive, name: 'Inactive' }
        ]
    }, {
        identifier: 'activityType',
        label: 'Activity',
        type: FilterType.Choice,
        options: [
            { identifier: ActivityType.DataForwarder, name: WorkflowActivityLabel[ActivityType.DataForwarder] },
            { identifier: ActivityType.Notification, name: WorkflowActivityLabel[ActivityType.Notification] },
            { identifier: ActivityType.Timer, name: WorkflowActivityLabel[ActivityType.Timer] }
        ]
    }, {
        identifier: 'bucket',
        label: 'Form Data Repository',
        type: FilterType.DataSeed,
        loader: new BucketLoader(ucProject),
    }
];

