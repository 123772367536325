<label *ngIf="label">
    {{label}}
    <ng-content></ng-content>
</label>

<div *ngIf="supported" class="row">
    <input #input [formControl]="control" class="colour-picker" type="color" [attr.placeholder]="placeholder"
        [readonly]="disabled" (focus)="focused = true" (blur)="focused = false" />
    <uf-text placeholder="#FFFFFF" class="grow" [value]="control.value" (valueChange)="digitColor($event)"></uf-text>
    <uf-icon *ngIf="control.value" name="backspace" (click)="delete()" title="Clear"></uf-icon>
</div>

<div *ngIf="!supported">
    <input type="text" [attr.placeholder]="placeholder" [readonly]="disabled" (focus)="focused = true"
        (blur)="focused = false">
</div>
<uf-error [control]="control"></uf-error>