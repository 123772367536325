<div class="header">
    <button type="button" class="uf-action tertiary" (click)="close()" title="Close">
        <uf-icon name="close"></uf-icon>
    </button>
    <h3>{{title}}</h3>
</div>

<uf-panel *ngIf="ready">
    <div class="uf-grid pad">
        <uf-textarea class="col-12" label="Description" [(value)]="permission.description" [disabled]="isReadOnly">
        </uf-textarea>
        <div class="col-12">
            <uf-description-list [items]="[{term: 'Path', description: urlPath}]" class="columns"></uf-description-list>
            <!-- <uf-description-list [items]="[{term: 'Fields', description: permission.fields}]" class="columns">
            </uf-description-list> -->
        </div>
        <div class="col-12">
            <h4>Path</h4>
        </div>
        <ng-template ngFor let-step [ngForOf]="steps" let-i="index">
            <div class="col-6">
                <uf-select *ngIf="step.isStatic" [value]="step.value" (valueChange)="stepChanged($event, i)"
                    [control]="pathsControl.at(i) | asUfControl" [options]="step.options" [disabled]="isReadOnly">
                </uf-select>
                <uf-autocomplete *ngIf="!step.isStatic" [value]="step.value" (valueChange)="stepChanged($event, i)"
                    [control]="pathsControl.at(i) | asUfControl" (searchChange)="searchStepOptions(step, $event)"
                    [options]="step.options" nameProperty="name" [disabled]="isReadOnly">
                </uf-autocomplete>
            </div>
        </ng-template>
        <div class="col-12">
            <h4>Actions</h4>
            <ul *ngIf="availableActions.length">
                <li class="row" *ngFor="let action of availableActions; let i = index">
                    <uf-checkbox [(value)]="action.value" [label]="action.name"
                        [control]="actionsControl.at(i) | asUfControl" [disabled]="!form.controls.paths.valid"
                        class="center"></uf-checkbox>
                    <div class="descriptions">
                        <div *ngFor="let description of action.descriptions">{{description}}</div>
                    </div>
                </li>
            </ul>
            <uf-error [control]="form.controls.actions | asUfControl"></uf-error>
        </div>
        <div class="col-12">
            <!-- <span>{{lastStep?.resource | json}}</span> -->
            <ng-container *ngIf="lastStep?.resource?.allowsCondition && !isReadOnly">

                <div class="uc-group">
                    <div class="uf-app-bar flat">
                        <div class="title">
                            Conditions
                        </div>
                    </div>

                    <div class="uc-group-content grid">
                        <!-- <div>{{condition}}</div> -->
                        <uf-textarea [control]="conditionControl" class="col-1of1"></uf-textarea>
                    </div>
                </div>
            </ng-container>
        </div>
        <div *ngIf="permission.fields?.length ?? 0 > 0 || (lastStep?.fields?.length ?? 0 > 0 && hasEditAction && !isReadOnly)"
            class="col-12">
            <uf-chips [label]="fieldsFieldLabel" [options]="fieldsResult" [(value)]="permission.fields"
                (searchChange)="findFields($event)" [disabled]="isReadOnly">
                <uf-help content="Leave blank to allow access to all fields, or specify which fields can be edited.">
                </uf-help>
            </uf-chips>
        </div>
    </div>
</uf-panel>

<div *ngIf="ready && !isReadOnly" class="row space-children pad">
    <button type="button" class="uf-button tertiary right" (click)="close()">Cancel</button>
    <button type="button" class="uf-button primary" (click)="save()">Save</button>
</div>