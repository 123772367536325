import { TableDataSource } from '@unifii/library/common';

import { AuthProviderDetails, UcAuthProviders } from 'client';


export class AuthProvidersDatasource extends TableDataSource<AuthProviderDetails> {

    filtered: boolean;
    sorted: boolean;

    private providers: AuthProviderDetails[];

    constructor(
        private ucAuthProviders: UcAuthProviders,
    ) {
        super();
    }

    async load() {
        try {
            if (!this.providers) {
                this.providers = await this.ucAuthProviders.list();
            }
            this.stream.next({ data: this.providers });
        } catch (error) {
            this.stream.error({ error });
        }
    }
}
