import { Injectable } from '@angular/core';
import { UserDataDescriptorAdapterLoader } from '@unifii/library/common';
import { ClaimConfig, Role } from '@unifii/sdk';

import { UcRoles, UcUserClaims } from 'client';


@Injectable()
export class UcUserDataDescriptorAdapterLoader implements UserDataDescriptorAdapterLoader {

    constructor(
        private ucUserClaims: UcUserClaims,
        private ucRoles: UcRoles
    ) { }

    loadUserClaims(): Promise<ClaimConfig[]> {
        return this.ucUserClaims.list({ params: { limit: 1000 } });
    }

    loadRoles(): Promise<Role[]> {
        return this.ucRoles.get(undefined, undefined, { params: { limit: 1000 } });
    }

}