import { Component, HostBinding, Inject } from '@angular/core';
import { Modal, ModalData, ModalRuntime } from '@unifii/library/common';


export interface TypeSelectOption extends Record<string, any> {
    type: string;
    label: string;
    logo?: string;
    icon?: string;
}

export interface TypeSelectConfig {
    title: string;
    types: TypeSelectOption[];
}
/**
 * Modal component to make a general purpose selection of a 'type' element
 * The element is indentified by a type and described by label and optional logo or icon
 */
@Component({
    templateUrl: './type-select.html',
    styleUrls: ['./type-select.less']
})
export class TypeSelectComponent implements Modal<TypeSelectConfig, TypeSelectOption> {

    @HostBinding('class.uc-form-card') classes = true;

    constructor(
        @Inject(ModalData) public data: TypeSelectConfig,
        public runtime: ModalRuntime<TypeSelectConfig, TypeSelectOption>
    ) { }

    close(type?: TypeSelectOption) {
        this.runtime.close(type);
    }
}