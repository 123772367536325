import { Subscription } from 'rxjs';

import { Injectable, OnDestroy } from '@angular/core';
import { HierarchyUnitProvider, UfControlGroup, UfFormBuilder, ValidatorFunctions } from '@unifii/library/common';
import { HierarchyUnit } from '@unifii/sdk';

import {
    UcClaimConfig, UcUserClaims, WorkflowNotification, WorkflowNotificationConditionClaim, WorkflowNotificationConditionCompany,
    WorkflowNotificationConditionHierarchy, WorkflowNotificationConditionRole, WorkflowNotificationConditionType, WorkflowNotificationMessage,
    WorkflowNotificationRecipient, WorkflowNotificationRecipientClaim, WorkflowNotificationRecipientClaimData,
    WorkflowNotificationRecipientClaimMatchType, WorkflowNotificationRecipientCombo, WorkflowNotificationRecipientComboTypes,
    WorkflowNotificationRecipientEmail, WorkflowNotificationRecipientFormData, WorkflowNotificationRecipientFormDataType,
    WorkflowNotificationRecipientRole, WorkflowNotificationRecipients, WorkflowNotificationRecipientType, WorkflowNotificationRecipientUser
} from 'client';


export interface WorkflowNotificationMessageInfo {
    email?: WorkflowNotificationMessage;
    push?: WorkflowNotificationMessage;
    sms?: WorkflowNotificationMessage;
}

// Add this property on the messages to avoid adding and removing controls based on having it or not
export type WorkflowNotificationMessageModel = WorkflowNotificationMessage & { hasContent: boolean };

export type WorkflowNotificationRecipientComboTypesModel = (Exclude<WorkflowNotificationRecipientComboTypes, WorkflowNotificationConditionHierarchy | WorkflowNotificationConditionClaim> | WorkflowNotificationConditionHierarchyModel | WorkflowNotificationConditionClaimModel) & { type: WorkflowNotificationConditionType };

export interface WorkflowNotificationConditionClaimModel {
    claim: WorkflowNotificationRecipientClaimData & { claimConfig?: UcClaimConfig };
}

export interface WorkflowNotificationRecipientClaimModel extends WorkflowNotificationRecipient {
    claim: WorkflowNotificationRecipientClaimData & { claimConfig?: UcClaimConfig };
}

export interface WorkflowNotificationConditionHierarchyModel {
    formData?: string;
    value?: string;
    hierarchyUnit?: HierarchyUnit;
}

export interface WorkflowNotificationRecipientComboModel extends WorkflowNotificationRecipient {
    conditions: WorkflowNotificationRecipientComboTypesModel[];
}

export type WorkflowNotificationRecipientsModel = Exclude<WorkflowNotificationRecipients, WorkflowNotificationRecipientCombo | WorkflowNotificationRecipientClaim> | WorkflowNotificationRecipientComboModel | WorkflowNotificationRecipientClaimModel;

export interface WorkflowNotificationMessagesModel extends WorkflowNotificationMessageInfo {
    email?: WorkflowNotificationMessageModel;
    push?: WorkflowNotificationMessageModel;
    sms?: WorkflowNotificationMessageModel;
}
export interface WorkflowNotificationModel {
    [ControlKeys.Id]?: string;
    [ControlKeys.Label]: string;
    [ControlKeys.Bucket]?: string;
    [ControlKeys.Recipients]: WorkflowNotificationRecipientsModel[];
    [ControlKeys.Messages]: WorkflowNotificationMessagesModel;
}

export enum ControlKeys {
    Id = 'id',
    Label = 'label',
    Bucket = 'bucket',
    Messages = 'messages',
    Recipients = 'recipients',
    Email = 'email',
    Push = 'push',
    MessageTitle = 'title',
    MessageBody = 'body',
    MessageAttachFormAsPdf = 'attachFormAsPdf',
    Type = 'type',
    LiveOnly = 'liveOnly',
    User = 'user',
    Claim = 'claim',
    ClaimConfig = 'claimConfig',
    MatchType = 'matchType',
    MatchAgainst = 'matchAgainst',
    Value = 'value',
    Role = 'role',
    FormData = 'formData',
    HasContent = 'hasContent',
    Conditions = 'conditions',
    Hierarchy = 'hierarchy',
    // eslint-disable-next-line @typescript-eslint/no-shadow
    HierarchyUnit = 'hierarchyUnit',
    Company = 'company',
    TwoConditions = 'twoConditions',
    AtLeastOneNotification = 'atLeastOneNotification',
    ReplyTo = 'replyTo',
}

@Injectable({
    providedIn: 'root'
})
export class WorkflowNotificationFormController implements OnDestroy {

    private subscriptions: Subscription[] = [];

    constructor(
        private ufb: UfFormBuilder,
        private hierarchyProvider: HierarchyUnitProvider,
        private ucUserClaim: UcUserClaims
    ) { }

    ngOnDestroy(): void {
        for (const sub of this.subscriptions) {
            sub.unsubscribe();
        }
    }

    buildRoot(workflowNotification?: WorkflowNotificationModel | null, isUserManagement?: boolean): UfControlGroup {
        const recipientsControl = this.ufb.array((workflowNotification?.recipients ?? [])
            .map(recipient => this.buildReceiptControl(recipient, isUserManagement)));


        const defaultTitle = '{{formNumber}} has been submitted';
        const defaultBody = '{{formLabel}} {{formNumber}} was submitted by {{lastModifiedBy}} at {{lastModifiedAt}}.\nThe form progressed from {{previousState}} to {{currentState}} with action {{action}}.\n[Click here to view the form.]({{link}})';

        const hasEmailControl = this.ufb.control(workflowNotification?.messages?.email?.hasContent);
        const hasPushControl = this.ufb.control(workflowNotification?.messages?.push?.hasContent);

        const emailGroup = this.ufb.group({
            [ControlKeys.HasContent]: hasEmailControl,
            [ControlKeys.ReplyTo]: [workflowNotification?.messages?.email?.replyTo],
            [ControlKeys.MessageTitle]: this.ufb.control(
                workflowNotification?.id || workflowNotification?.messages?.email?.title ?
                    workflowNotification?.messages?.email?.title :
                    isUserManagement ? '' : defaultTitle,
                ValidatorFunctions.required('Title is required')),
            [ControlKeys.MessageBody]: this.ufb.control(
                workflowNotification?.id || workflowNotification?.messages?.email?.body ?
                    workflowNotification?.messages?.email?.body :
                    isUserManagement ? '' : defaultBody
                , ValidatorFunctions.required('Message is required')),
            [ControlKeys.MessageAttachFormAsPdf]: this.ufb.control({ value: workflowNotification?.messages?.email?.attachFormAsPdf, disabled: isUserManagement })
        });

        const pushGroup = this.ufb.group({
            [ControlKeys.HasContent]: hasPushControl,
            [ControlKeys.MessageTitle]: this.ufb.control(workflowNotification?.messages?.push?.title ?? defaultTitle, ValidatorFunctions.required('Title is required')),
            [ControlKeys.MessageBody]: this.ufb.control(workflowNotification?.messages?.push?.body ?? defaultTitle, ValidatorFunctions.required('Message is required')),
        });

        const messagesControl = this.ufb.group({
            [ControlKeys.Email]: emailGroup,
            [ControlKeys.Push]: pushGroup,
        });


        const bucketControl = this.ufb.control({ value: workflowNotification?.bucket, disabled: isUserManagement }, ValidatorFunctions.required('Form is required'));
        const atLeastOneNotificationControl = this.ufb.control(
            undefined,
            ValidatorFunctions.custom((_) => hasEmailControl.value || hasPushControl.value, 'Either Email or Push needs to be set up'),
            undefined,
            { deps: [hasEmailControl, hasPushControl] }
        );

        const root = this.ufb.group({
            [ControlKeys.Id]: workflowNotification?.id,
            [ControlKeys.Label]: [workflowNotification?.label, ValidatorFunctions.required('Title is required')],
            [ControlKeys.Bucket]: bucketControl,
            [ControlKeys.Messages]: messagesControl,
            [ControlKeys.Recipients]: recipientsControl,
            [ControlKeys.AtLeastOneNotification]: atLeastOneNotificationControl
        });

        this.subscriptions.push(bucketControl.valueChanges.subscribe(_ => {
            const indexesToBeRemoved = [];
            let offset = 0;
            for (let index = 0; index < recipientsControl.controls.length; index++) {
                const control = recipientsControl.controls[index];
                const type = control.get(ControlKeys.Type)?.value as WorkflowNotificationRecipientType;

                if ([
                    WorkflowNotificationRecipientType.Combo,
                    WorkflowNotificationRecipientType.FormData,
                    WorkflowNotificationRecipientType.EmailField,
                    WorkflowNotificationRecipientType.UserDatasource,
                    WorkflowNotificationRecipientType.UserDatasourceManager,
                ].includes(type)) {
                    indexesToBeRemoved.push(index - offset++);
                }
            }
            for (const indexToBeRemoved of indexesToBeRemoved) {
                recipientsControl.removeAt(indexToBeRemoved);
            }
        }));

        return root;
    }

    async toFormModel(workflowNotification: WorkflowNotification): Promise<WorkflowNotificationModel> {
        const model: WorkflowNotificationModel = { messages: {}, recipients: [] } as any as WorkflowNotificationModel;

        model.id = workflowNotification.id.toString();
        model.label = workflowNotification.label;
        model.bucket = workflowNotification.bucket;

        if (workflowNotification.messages.email) {
            const { title, body, attachFormAsPdf, replyTo } = workflowNotification.messages.email;
            model.messages.email = {
                title, body, attachFormAsPdf, replyTo, hasContent: true
            };
        }

        if (workflowNotification.messages.push) {
            const { title, body } = workflowNotification.messages.push;
            model.messages.push = {
                title, body, hasContent: true
            };
        }

        model.recipients = await Promise.all((workflowNotification.recipients ?? []).map(async (recipient: WorkflowNotificationRecipient) => {
            let recipientModel: WorkflowNotificationRecipientsModel;

            switch (recipient.type) {
                case WorkflowNotificationRecipientType.Role:
                case WorkflowNotificationRecipientType.Email:
                case WorkflowNotificationRecipientType.User:
                case WorkflowNotificationRecipientType.CreatedBy:
                case WorkflowNotificationRecipientType.LastModifiedBy:
                case WorkflowNotificationRecipientType.CreatedByManager:
                case WorkflowNotificationRecipientType.LastModifiedByManager:
                case WorkflowNotificationRecipientType.UserModified:
                case WorkflowNotificationRecipientType.UserModifiedManager:
                    recipientModel = recipient as WorkflowNotificationRecipient;
                    break;
                case WorkflowNotificationRecipientType.FormData:
                    recipientModel = recipient as WorkflowNotificationRecipient;
                    break;
                case WorkflowNotificationRecipientType.Claim:
                    const claimRecipient = (recipient as WorkflowNotificationRecipientClaim) as WorkflowNotificationRecipientClaimModel;

                    if (claimRecipient?.claim?.value) {
                        const claimConfig = (await this.ucUserClaim.list({ params: { q: claimRecipient?.claim?.type } })).find(claim => claim.type === claimRecipient?.claim?.type);
                        if (claimConfig) {
                            claimRecipient.claim.claimConfig = claimConfig;
                        }
                    }
                    recipientModel = claimRecipient;
                    break;
                case WorkflowNotificationRecipientType.Combo:
                    recipientModel = await this.toComboFormModel(recipient as WorkflowNotificationRecipientCombo);
                    break;
                default:
                    throw new TypeError(`Error loading Workflow notification - ${workflowNotification.label}`);
            }

            return recipientModel;
        }));

        return model;
    }

    toDataModel(model: WorkflowNotificationModel): WorkflowNotification {
        const workflowNotification: WorkflowNotification = { messages: {}, recipients: [] } as any as WorkflowNotification;

        workflowNotification.id = model.id ?? '';
        workflowNotification.label = model.label;
        workflowNotification.bucket = model.bucket;

        if (model.messages.email?.hasContent) {
            const { title, body, attachFormAsPdf, replyTo } = model.messages.email;
            workflowNotification.messages.email = {
                title, body, attachFormAsPdf, replyTo
            };
        }

        if (model.messages.push?.hasContent) {
            const { title, body } = model.messages.push;
            workflowNotification.messages.push = {
                title, body
            };
        }

        workflowNotification.recipients = (model.recipients ?? []).map((modelRecipient: WorkflowNotificationRecipient) => {
            let recipient: WorkflowNotificationRecipient;
            switch (modelRecipient.type) {
                case WorkflowNotificationRecipientType.Role:
                    recipient = modelRecipient as WorkflowNotificationRecipientRole;
                    break;
                case WorkflowNotificationRecipientType.Claim:
                    recipient = modelRecipient as WorkflowNotificationRecipientClaimModel;
                    if ((recipient as WorkflowNotificationRecipientClaimModel).claim) {
                        delete (recipient as WorkflowNotificationRecipientClaimModel).claim.claimConfig;
                    }
                    break;
                case WorkflowNotificationRecipientType.FormData:
                    recipient = modelRecipient as WorkflowNotificationRecipientFormData;
                    if ((recipient as WorkflowNotificationRecipientFormData).formData.type === WorkflowNotificationRecipientFormDataType.Email) {
                        (recipient as WorkflowNotificationRecipientFormData).formData.value = (recipient as any).formData.value.identifier ?? (recipient as any).formData.value;
                    }
                    break;
                case WorkflowNotificationRecipientType.Email:
                case WorkflowNotificationRecipientType.User:
                case WorkflowNotificationRecipientType.CreatedBy:
                case WorkflowNotificationRecipientType.LastModifiedBy:
                case WorkflowNotificationRecipientType.CreatedByManager:
                case WorkflowNotificationRecipientType.LastModifiedByManager:
                case WorkflowNotificationRecipientType.UserModified:
                case WorkflowNotificationRecipientType.UserModifiedManager:
                    recipient = modelRecipient as WorkflowNotificationRecipient;
                    break;
                case WorkflowNotificationRecipientType.Combo:
                    recipient = this.toComboDataModel(modelRecipient as WorkflowNotificationRecipientComboModel);
                    break;
                default:
                    throw new TypeError(`Error loading Workflow notification - ${workflowNotification.label}`);
            }
            return recipient;
        });

        return workflowNotification;
    }

    buildReceiptControl(recipient?: WorkflowNotificationRecipientsModel, isUserManagement?: boolean): UfControlGroup {

        const typeControl = this.ufb.control(recipient?.type, ValidatorFunctions.required('Type is required'));
        const recipientControlGroup = this.ufb.group({
            [ControlKeys.Type]: typeControl,
            [ControlKeys.LiveOnly]: !!recipient?.liveOnly,
        });

        switch (recipient?.type) {
            case WorkflowNotificationRecipientType.Email:
                recipientControlGroup.addControl(ControlKeys.Email, this.ufb.control((recipient as WorkflowNotificationRecipientEmail)?.email, ValidatorFunctions.required('Email is required')));
                break;
            case WorkflowNotificationRecipientType.User:
                recipientControlGroup.addControl(ControlKeys.User, this.ufb.control((recipient as WorkflowNotificationRecipientUser)?.user, ValidatorFunctions.required('User is required')));
                break;
            case WorkflowNotificationRecipientType.Role:
                recipientControlGroup.addControl(ControlKeys.Role, this.ufb.control((recipient as WorkflowNotificationRecipientRole)?.role, ValidatorFunctions.required('Role is required')));
                break;
            case WorkflowNotificationRecipientType.Claim:
                const claim = (recipient as WorkflowNotificationRecipientClaimModel);
                const claimControlGroup = this.buildClaimControlGroup(claim);
                recipientControlGroup.addControl(ControlKeys.Claim, claimControlGroup);
                break;
            case WorkflowNotificationRecipientType.FormData:
                const formData = (recipient as WorkflowNotificationRecipientFormData)?.formData;
                const formDataControlGroup = this.ufb.group({
                    [ControlKeys.Type]: [formData?.type, ValidatorFunctions.required('Type is required')],
                    [ControlKeys.Value]: [formData?.value, ValidatorFunctions.required('Value is required')],
                });
                recipientControlGroup.addControl(ControlKeys.FormData, formDataControlGroup);
                break;
            case WorkflowNotificationRecipientType.Combo:
                const twoConditionsControl = this.ufb.control((recipient as WorkflowNotificationRecipientComboModel)?.conditions ?? [],
                    ValidatorFunctions.custom(value => !!value && value.length >= 2, 'At least two conditions are required'));
                recipientControlGroup.addControl(ControlKeys.TwoConditions, twoConditionsControl);
                const conditionsControls = this.ufb.array(((recipient as WorkflowNotificationRecipientComboModel)?.conditions ?? [])
                    .map(condition => this.addConditionControl(condition, isUserManagement)).filter(Boolean));
                recipientControlGroup.addControl(ControlKeys.Conditions, conditionsControls);
                this.subscriptions.push(conditionsControls.valueChanges.subscribe(value => {
                    twoConditionsControl.setValue(value);
                }));
                break;
            default: break;

        }

        return recipientControlGroup;
    }

    addConditionControl(condition?: WorkflowNotificationRecipientComboTypesModel, isUserManagement?: boolean): UfControlGroup {
        const conditionControlType = this.ufb.control(condition?.type, ValidatorFunctions.required('Condition Type is required'));
        const controlGroup = this.ufb.group({
            [ControlKeys.Type]: conditionControlType
        });

        const addConditionControls = (conditionControlGroup: UfControlGroup, value?: WorkflowNotificationRecipientComboTypesModel) => {
            for (const key in controlGroup.controls) {
                if (key !== ControlKeys.Type) {
                    conditionControlGroup.removeControl(key);
                }
            }

            switch (value?.type) {
                case WorkflowNotificationConditionType.Role:
                    const role = (value as WorkflowNotificationConditionRole).role;
                    conditionControlGroup.addControl(ControlKeys.Role, this.ufb.control(role, ValidatorFunctions.required('Role is required')));
                    break;
                case WorkflowNotificationConditionType.Claim:
                    conditionControlGroup.addControl(ControlKeys.Claim, this.buildClaimControlGroup((value as WorkflowNotificationConditionClaimModel)));
                    break;
                case WorkflowNotificationConditionType.Company:
                    conditionControlGroup.addControl(ControlKeys.Company, this.ufb.control({ value: (value as WorkflowNotificationConditionCompany).company, disabled: isUserManagement }, ValidatorFunctions.required('Company is required')));
                    break;
                case WorkflowNotificationConditionType.Hierarchy:
                    const hierarchy = (value as WorkflowNotificationConditionHierarchyModel);
                    conditionControlGroup.addControl(ControlKeys.FormData, this.ufb.control({ value: hierarchy?.formData, disabled: isUserManagement }, ValidatorFunctions.required('Hierarchy Field is required')));
                    conditionControlGroup.addControl(ControlKeys.Value, this.ufb.control(hierarchy?.value));
                    const hierarchyControl = this.ufb.control(hierarchy?.hierarchyUnit, ValidatorFunctions.required('Hierarchy unit is required'));
                    conditionControlGroup.addControl(ControlKeys.HierarchyUnit, hierarchyControl);
                    this.subscriptions.push(hierarchyControl.valueChanges.subscribe((v: HierarchyUnit) => conditionControlGroup.get(ControlKeys.Value)?.setValue(v.id)));
                    break;
                default: break;
            }
        };

        addConditionControls(controlGroup, condition);

        this.subscriptions.push(conditionControlType.valueChanges.subscribe((value: WorkflowNotificationRecipientType) => {
            addConditionControls(controlGroup, { type: value } as any);
        }));

        return controlGroup;
    }

    private toComboDataModel(comboModel: WorkflowNotificationRecipientComboModel): WorkflowNotificationRecipientCombo {
        const conditions = (comboModel.conditions ?? []).map((conditionModel: WorkflowNotificationRecipientComboTypesModel) => {
            switch (conditionModel.type) {
                case WorkflowNotificationConditionType.Role:
                    return (conditionModel as WorkflowNotificationConditionRole);
                case WorkflowNotificationConditionType.Hierarchy:
                    const hierarchyCondition = (conditionModel as WorkflowNotificationConditionHierarchyModel);
                    return {
                        hierarchy: {
                            value: hierarchyCondition.hierarchyUnit?.id,
                            formData: (hierarchyCondition.formData as any)?.identifier ?? hierarchyCondition.formData,
                        }
                    };
                case WorkflowNotificationConditionType.Company:
                    return (conditionModel as WorkflowNotificationConditionCompany);
                case WorkflowNotificationConditionType.Claim:
                    return {
                        claim: (conditionModel as WorkflowNotificationConditionClaimModel).claim
                    } as WorkflowNotificationConditionClaim;
                default:
                    throw new TypeError(`Error loading condition`);
            }
        }) as WorkflowNotificationRecipientComboTypes[];
        return { conditions, type: WorkflowNotificationRecipientType.Combo, liveOnly: comboModel.liveOnly };
    }

    private async toComboFormModel(combo: WorkflowNotificationRecipientCombo): Promise<WorkflowNotificationRecipientComboModel> {
        const conditions = await Promise.all((combo.conditions ?? []).filter(condition => !!Object.keys(condition).length).map(async (condition: WorkflowNotificationRecipientComboTypes) => {
            if ((condition as WorkflowNotificationRecipientRole).role != null) {
                return {
                    role: (condition as WorkflowNotificationRecipientRole).role,
                    type: WorkflowNotificationConditionType.Role
                } as WorkflowNotificationRecipientComboTypesModel;
            } else if ((condition as WorkflowNotificationConditionClaim).claim != null) {
                const claimConditionData = (condition as WorkflowNotificationConditionClaim).claim;
                const claimConfig = (await this.ucUserClaim.list({ params: { q: claimConditionData.type } })).find(claim => claim.type === claimConditionData.type);
                return {
                    claim: {
                        ...claimConditionData,
                        claimConfig
                    },
                    type: WorkflowNotificationConditionType.Claim
                } as WorkflowNotificationRecipientComboTypesModel;
            } else if ((condition as WorkflowNotificationConditionCompany).company != null) {
                return {
                    company: (condition as WorkflowNotificationConditionCompany).company,
                    type: WorkflowNotificationConditionType.Company
                } as WorkflowNotificationRecipientComboTypesModel;
            } else if ((condition as WorkflowNotificationConditionHierarchy).hierarchy != null) {
                const hierarchyCondition = (condition as WorkflowNotificationConditionHierarchy);
                return {
                    formData: hierarchyCondition.hierarchy.formData,
                    value: hierarchyCondition.hierarchy.value,
                    hierarchyUnit: await this.hierarchyProvider.getUnit(hierarchyCondition.hierarchy.value),
                    type: WorkflowNotificationConditionType.Hierarchy
                } as WorkflowNotificationRecipientComboTypesModel;
            }
            throw new TypeError(`Error loading condition`);
        }));

        return { conditions, type: WorkflowNotificationRecipientType.Combo, liveOnly: combo.liveOnly };
    }

    private buildClaimControlGroup(claimModel?: (WorkflowNotificationRecipientClaimModel | WorkflowNotificationConditionClaimModel)) {
        const claim = claimModel?.claim;
        const claimControlGroup = this.ufb.group({});
        const claimMatchTypeControl = this.ufb.control(claim?.matchType, ValidatorFunctions.required('Match Type is required'));
        const claimMatchAgainstControl = this.ufb.control(claim?.matchAgainst, ValidatorFunctions.required('Claim is required'));
        const claimValueControl = this.ufb.control(claim?.value, ValidatorFunctions.required('Value is required'));
        const claimTypeControl = this.ufb.control(claim?.type, ValidatorFunctions.required('Claim is required'));

        claimControlGroup.addControl(ControlKeys.Type, claimTypeControl);
        claimControlGroup.addControl(ControlKeys.ClaimConfig, this.ufb.control(claim?.claimConfig));

        const addClaimControls = (type?: WorkflowNotificationRecipientClaimMatchType) => {
            const claimValue = claimControlGroup.getRawValue();

            claimControlGroup.reset({
                matchType: type,
                type: claimValue.type,
                claimConfig: claimValue?.claimConfig
            }, { emitEvent: false });

            if (claimControlGroup.get(ControlKeys.MatchType)) {
                claimControlGroup.removeControl(ControlKeys.MatchType);
            }

            if (claimControlGroup.get(ControlKeys.Value)) {
                claimControlGroup.removeControl(ControlKeys.Value);
            }

            if (claimControlGroup.get(ControlKeys.MatchAgainst)) {
                claimControlGroup.removeControl(ControlKeys.MatchAgainst);
            }

            if (!!claimValue.type) {
                claimControlGroup.addControl(ControlKeys.MatchType, claimMatchTypeControl);
            }

            switch (type) {
                case WorkflowNotificationRecipientClaimMatchType.Value:
                case WorkflowNotificationRecipientClaimMatchType.FormData:
                    claimControlGroup.addControl(ControlKeys.Value, claimValueControl);
                    break;
                case WorkflowNotificationRecipientClaimMatchType.LastModifiedBy:
                case WorkflowNotificationRecipientClaimMatchType.CreatedBy:
                    claimControlGroup.addControl(ControlKeys.MatchType, claimMatchTypeControl);
                    claimControlGroup.addControl(ControlKeys.MatchAgainst, claimMatchAgainstControl);
            }
        };

        addClaimControls(claim?.matchType);

        this.subscriptions.push(claimTypeControl.valueChanges.subscribe((_: string) => {
            addClaimControls();
        }));

        this.subscriptions.push(claimMatchTypeControl.valueChanges.subscribe((value: WorkflowNotificationRecipientClaimMatchType) => {
            addClaimControls(value);
        }));

        return claimControlGroup;
    }

}