<uf-panel class="container">
    <div class="header-pane uf-app-bar transparent">
        <uf-breadcrumbs [breadcrumbs]="breadcrumbs" class="large">
            <span *ngIf="edited"> *</span>
        </uf-breadcrumbs>
        <div class="row header-buttons right">
            <a [routerLink]="['../']" class="uf-button tertiary">Cancel</a>
            <button (click)="delete()" restrict="AssetManager" type="button" class="uf-button">Delete</button>
            <button (click)="save()" restrict="AssetManager" type="submit" class="uf-button primary">Save</button>
        </div>
    </div>

    <uf-panel class="content-pane">
        <div [formGroup]="detailsForm" *ngIf="!loading" class="uf-container-lg">
            <div class="uf-grid row-gap-lg pad-top">
                <div class="uf-box col-12">
                    <div class="uf-app-bar flat accent">
                        <div class="title">
                            Details
                        </div>
                    </div>
                    <div class="uf-grid pad">
                        <div class="col-12" *ngIf="error">
                            <uf-blockquote class="error-text" icon="errorSolid"
                                [content]="error.message || 'Unknown Error'">
                            </uf-blockquote>
                        </div>
                        <div class="col-12 media-detail-preview" [class.checkboard-bg]="media.type === mediaType.Image">
                            <img *ngIf="media.type === mediaType.Image" src="{{getUrl(640, 320)}}"
                                (click)="viewFullSizeImage()" />
                            <video *ngIf="media.type === mediaType.Video" width="640" controls
                                poster="{{getUrl(640, 320)}}">
                                <source src="{{media.url}}" type="video/mp4"> Your browser doesn't support HTML5
                                video.
                            </video>
                            <audio *ngIf="media.type === 'Audio'" controls="controls">
                                Your browser does not support the
                                <code>audio</code> element.
                                <source src="{{media.url}}" type="audio/mp3">
                            </audio>
                        </div>

                        <uf-description-list class="col-12">
                            <dt>Filename</dt>
                            <dd>{{media.filename}}</dd>
                            <dt>Actual Image size</dt>
                            <dd>{{media.width}} x {{media.height}} px</dd>
                            <dt>Upload Date</dt>
                            <dd>{{media.uploadedAt | momentDate:'dd/MM/yyyy'}}</dd>
                        </uf-description-list>

                        <div class="col-12">
                            <button (click)="download()" type="button" class="uf-button primary">Download</button>
                        </div>
                        <uf-text [(value)]="media.title" [control]="detailsForm.controls.titleControl | asUfControl"
                            label="Title" name="title" class="col-12"></uf-text>
                        <uf-textarea [(value)]="media.description" name="description"
                            [control]="detailsForm.controls.descriptionControl | asUfControl" label="Description"
                            class="col-12">
                        </uf-textarea>
                        <uf-text [(value)]="media.author" name="author"
                            [control]="detailsForm.controls.authorControl | asUfControl" label="Author" class="col-12">
                        </uf-text>
                        <uf-text [(value)]="media.credits" name="credits"
                            [control]="detailsForm.controls.creditsControl | asUfControl" label="Credits"
                            class="col-12"></uf-text>
                    </div>
                </div>
            </div>
        </div>
    </uf-panel>
</uf-panel>