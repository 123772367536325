import { Subject } from 'rxjs';

import { inject, Injectable } from '@angular/core';
import { TableContainerManager, TableInputManager, TableInputs } from '@unifii/components';
import { FilterValue, FromNowPipe, HierarchyUnitProvider, TableConfig } from '@unifii/library/common';

import { UcClient, UcProjectInfo } from 'client';

import { ProjectsDataSource } from './projects-data-source';


@Injectable()
export class ProjectTableManager implements TableContainerManager<UcProjectInfo, FilterValue, any> {

    tableConfig: TableConfig<UcProjectInfo>;
    showSearch = true;
    addActionConfig = true;
    reload = new Subject<void>();
    inputManager = new TableInputManager([], inject(HierarchyUnitProvider));
    searchMinLength = 1;
    update = new Subject<TableInputs<FilterValue>>();

    private projects: UcProjectInfo[] | null;

    constructor(
        private ucClient: UcClient,
        private fromNowPipe: FromNowPipe,
    ) {
        this.tableConfig = {
            columns: [{
                name: 'version',
                label: 'Version'
            },
            {
                name: 'publishedAt',
                label: 'Published',
                value: p => this.fromNowPipe.transform(p.publishedAt)
            }],
            row: {
                image: p => {
                    if (p.logo?.url) {
                        return p.logo.url + '?w=205&h=96';
                    }
                    return '/assets/svg/new-icons/projects.svg';
                },
                label: p => p.name,
                link: p => p.id
            },
            pageSize: -1,
        };
    }

    createDataSource({ q }: TableInputs<any> = {}) {
        return new ProjectsDataSource(this.getProjects, q);
    }

    refeshList() {
        this.reload.next();
    }

    getProjects = async () => {
        if (this.projects == null) {
            this.projects = await this.ucClient.getProjects();
        }
        return this.projects;
    };

}
