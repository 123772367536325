import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager } from '@unifii/components';

import { ClaimSource } from 'client';

import { ClaimTableManager } from './claim-table-manager';


@Component({
    template: `
        <div ufMasterDetail>
            <uf-table-container (addItem)="addItem()" class="pad-none accent list-md">
            </uf-table-container>
        </div>
        <router-outlet></router-outlet>
        `,
    providers: [
        { provide: TableContainerManager, useClass: ClaimTableManager }
    ]
})
export class ClaimTableComponent {

    label: string;

    constructor(
        private route: ActivatedRoute,
        private router: Router
    ) {
        const claimSource = route.snapshot.data.claimSource as ClaimSource;
        this.label = `${claimSource === ClaimSource.Company ? 'Company' : 'User'} Claims`;
    }

    addItem() {
        this.router.navigate(['new'], { relativeTo: this.route });
    }


}
