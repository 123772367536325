import { Subscription } from 'rxjs';

import { Component, HostBinding, Inject, OnDestroy } from '@angular/core';
import {
    DataPropertyDescriptor, DataSourceMappingDisplayAllowedDataTypes, DescriptionListItem, Modal, ModalData, ModalRuntime, UfControl, UfControlGroup
} from '@unifii/library/common';
import { FieldType } from '@unifii/sdk';

import { camelizeNoLowerCase } from 'helpers/field-identifier-helper';

import { DataSourceDisplayTo, getAttribute } from './data-source-editor-functions';
import { DataSourceMappingControlKeys } from './data-source-model';


export interface DataSourceMappingEditorData {
    properties: DataPropertyDescriptor[];
    mapping: UfControlGroup;
    fieldIdentifier?: string;
}

@Component({
    selector: 'uc-data-source-mapping-editor',
    templateUrl: './data-source-mapping-editor.html'
})
export class DataSourceMappingEditorComponent implements Modal<DataSourceMappingEditorData, UfControlGroup>, OnDestroy {

    @HostBinding('class.uf-form-card') cardClass = true;

    protected readonly mappingKeys = DataSourceMappingControlKeys;

    protected form: UfControlGroup;
    protected dataTypeDescriptor: DescriptionListItem[];
    protected attributeDescriptor: DescriptionListItem[];
    protected filteredProperties: DataPropertyDescriptor[];
    protected subs: Subscription[] = [];
    protected showItemTemplate: boolean;

    constructor(
        public runtime: ModalRuntime<DataSourceMappingEditorData, UfControlGroup>,
        @Inject(ModalData) public data: DataSourceMappingEditorData
    ) {
        this.form = data.mapping;

        this.subs.push(this.typeCtrl.valueChanges.subscribe(_ => this.onTypeChange()));
        this.subs.push(this.toCtrl.valueChanges.subscribe(_ => this.updateAttributeDescriptor()));

        this.onTypeChange();
        this.updateAttributeDescriptor();
    }

    protected get allowIsExpression() {
        return this.toCtrl.value === DataSourceDisplayTo;
    }

    protected get fromExpressionCtrl() {
        return this.form.get(DataSourceMappingControlKeys.FromExpression) as UfControl;
    }

    protected get fromCtrl() {
        return this.form.get(DataSourceMappingControlKeys.From) as UfControl;
    }

    protected get typeCtrl() {
        return this.form.get(DataSourceMappingControlKeys.Type) as UfControl;
    }

    protected get toCtrl() {
        return this.form.get(DataSourceMappingControlKeys.To) as UfControl;
    }

    protected get labelCtrl() {
        return this.form.get(DataSourceMappingControlKeys.Label) as UfControl;
    }

    protected get itemTemplateCtrl() {
        return this.form.get(DataSourceMappingControlKeys.ItemTemplate) as UfControl;
    }

    protected get isVisibleCtrl() {
        return this.form.get(DataSourceMappingControlKeys.IsVisible) as UfControl;
    }

    protected get isReportableCtrl() {
        return this.form.get(DataSourceMappingControlKeys.IsReportable) as UfControl;
    }

    protected get hideEmptyCtrl() {
        return this.form.get(DataSourceMappingControlKeys.HideEmpty) as UfControl;
    }

    ngOnDestroy() {
        for (const sub of this.subs) {
            sub.unsubscribe();
        }
    }

    close() {
        this.runtime.close();
    }

    protected onTypeChange() {
        this.dataTypeDescriptor = [{ term: 'Data Type', description: this.typeCtrl.value }];
        this.showItemTemplate = this.typeCtrl.value === FieldType.Repeat;
    }

    protected updateAttributeDescriptor() {
        this.attributeDescriptor = [{ term: 'Attribute', description: getAttribute(this.form, this.data.fieldIdentifier) }];
    }

    protected filterProperties(q?: string) {

        const properties = this.data.properties.filter(p => this.toCtrl.value !== DataSourceDisplayTo || DataSourceMappingDisplayAllowedDataTypes.includes(p.type));

        if (!q) {
            this.filteredProperties = [...properties];
            return;
        }

        const term = q.trim().toLowerCase();
        this.filteredProperties = properties.filter(p => (p.display ?? '').toLocaleLowerCase().indexOf(term) >= 0);
    }

    protected changedIsExpression(isExpression?: boolean) {
        if (isExpression) {
            this.fromCtrl.setValue(null);
            this.typeCtrl.setValue(FieldType.Text);
        } else {
            this.fromExpressionCtrl.setValue(null);
            this.typeCtrl.setValue(this.fromCtrl.value?.type ?? null);
        }
    }

    protected changedFrom(from?: DataPropertyDescriptor) {

        if (!this.toCtrl.disabled) {
            const to = from ? camelizeNoLowerCase(from.identifier) : null;
            this.toCtrl.setValue(to);
        }

        this.typeCtrl.setValue(from?.type ?? null);

        if (this.typeCtrl.value == null) {
            this.isVisibleCtrl.setValue(null);
            this.isVisibleCtrl.disable();
            this.isReportableCtrl.disable();
            this.isReportableCtrl.setValue(false);
        } else {
            this.isVisibleCtrl.enable();
            this.isReportableCtrl.enable();
        }

        if ((this.typeCtrl.value as FieldType) === FieldType.Repeat) {
            this.itemTemplateCtrl.enable();
        } else {
            this.itemTemplateCtrl.setValue(undefined, { onlySelf: true, emitEvent: false });
            this.itemTemplateCtrl.disable();
        }

        if (!this.isVisibleCtrl.value) {
            this.itemTemplateCtrl.setValue(null);
            this.itemTemplateCtrl.disable();
            this.hideEmptyCtrl.setValue(false);
            this.hideEmptyCtrl.disable();
        } else {
            this.itemTemplateCtrl.enable();
            this.hideEmptyCtrl.enable();
        }

        if (this.labelCtrl.value == null) {
            this.labelCtrl.setValue(from?.label ?? null);
        }

    }

    protected changedVisible(v?: boolean) {
        if (v) {
            this.itemTemplateCtrl.enable();
            this.hideEmptyCtrl.enable();
            this.hideEmptyCtrl.setValue(true);
        } else {
            this.itemTemplateCtrl.setValue(null);
            this.itemTemplateCtrl.disable();
            this.hideEmptyCtrl.disable();
            this.hideEmptyCtrl.setValue(false);
        }
    }

    protected submit() {
        this.form.setSubmitted();
        if (this.form.invalid) {
            return;
        }

        this.runtime.close(this.form);
    }
}