import { Component, HostBinding, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import { Breadcrumb, ToastService, UfControl } from '@unifii/library/common';

import { Role, UcPermissions, UcRoles } from 'client';

import { EditData } from 'components/common/edit-data';

import { BreadcrumbService } from 'services/breadcrumb.service';

import { RolesTableManager } from './roles-table-manager';


export const permissionsFactory = (ucRoles: UcRoles, route: ActivatedRoute) => ucRoles.permissions(route.snapshot.params.id);

@Component({
    templateUrl: './role-details.html',
    styleUrls: ['./role-details.less'],
    providers: [{ provide: UcPermissions, useFactory: permissionsFactory, deps: [UcRoles, ActivatedRoute] }]
})
export class RoleDetailsComponent implements EditData {

    @HostBinding('class.stretch-component') class = true;

    role: Role;
    breadcrumbs: Breadcrumb[] = [];
    edited = false;
    control = new UfControl();

    constructor(
        private ucRoles: UcRoles,
        private toast: ToastService,
        private route: ActivatedRoute,
        private router: Router,
        private breadcrumbService: BreadcrumbService,
        @Inject(TableContainerManager) private tableManager: RolesTableManager
    ) {
        this.init();
    }

    async init() {
        this.role = await this.ucRoles.getDetails(this.route.snapshot.params.id);
        this.breadcrumbs = this.breadcrumbService.getBreadcrumbs(this.route, [this.role.name]);
        this.control.valueChanges.subscribe(() => this.edited = true);
    }

    async save() {
        try {
            const role = await this.ucRoles.save(this.role);

            this.tableManager.updateItem.next(role);
            this.toast.info(`Role ${role.name} saved`);
            this.router.navigate(['../'], { relativeTo: this.route });
        } catch (e) {
            this.toast.error('Failed to save role');
        }
    }
}
