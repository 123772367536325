<uf-panel class="container">

    <div class="header-pane uf-app-bar transparent">
        <uf-breadcrumbs class="large" [breadcrumbs]="breadcrumbs">
            <span *ngIf="edited">*</span>
        </uf-breadcrumbs>
    </div>

    <div class="content-pane">
        <uf-tabs>
            <uf-tab *ngFor="let tab of tabs" [label]="tab.label" [path]="tab.path"></uf-tab>
        </uf-tabs>
    </div>

</uf-panel>