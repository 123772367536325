import { Subscription } from 'rxjs';

import { Component, Inject, OnDestroy } from '@angular/core';

import { Config } from 'app-config';
import { MyAccount, SystemRole, UcProjectInfo } from 'client';

import { ContextService } from 'services/context.service';
import { ProjectService } from 'services/project.service';

import { MenuConfig } from './nav-models';


@Component({
    selector: 'uc-project-nav',
    templateUrl: './project-nav.html',
    styleUrls: ['./project-nav.less']
})
export class ProjectNavComponent implements OnDestroy {

    protected menuConfigs: MenuConfig[] = [];

    private subscriptions = new Subscription();

    constructor(
        public context: ContextService,
        @Inject(Config) public config: Config,
        private projectService: ProjectService
    ) {
        this.updateMenu();
        this.subscriptions.add(this.projectService.projectChange.subscribe(() => this.updateMenu()));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    get account(): MyAccount {
        return this.context.account as MyAccount;
    }

    get project(): UcProjectInfo {
        return this.context.project as UcProjectInfo;
    }

    private updateMenu() {
        if (this.project == null) {
            this.menuConfigs = [];
            return;
        }

        this.menuConfigs = [{
            restrict: [SystemRole.ProjectManager, SystemRole.ContentEditor, SystemRole.Publisher].join(','),
            label: 'Structure',
            icon: 'structure',
            link: ['/projects', this.project.id, 'structure']
        }, {
            restrict: [SystemRole.ProjectManager, SystemRole.ContentEditor, SystemRole.FormDesigner, SystemRole.Publisher].join(','),
            label: 'Content',
            icon: 'content',
            link: ['/projects', this.project.id, 'content']
        }, {
            restrict: [SystemRole.ProjectManager, SystemRole.FormDesigner].join(','),
            label: 'Tables',
            icon: 'table',
            link: ['/projects', this.project.id, 'tables']
        }, {
            restrict: [SystemRole.ProjectManager, SystemRole.FormDesigner, SystemRole.Publisher].join(','),
            label: 'Forms',
            icon: 'forms',
            link: ['/projects', this.project.id, 'forms']
        }, {
            restrict: [SystemRole.ProjectManager, SystemRole.WorkflowDesigner].join(','),
            label: 'Workflows',
            icon: 'workflows',
            link: ['/projects', this.project.id, 'workflows']
        }, {
            restrict: SystemRole.FormDataViewer,
            label: 'Form Data',
            icon: 'form-data-repo',
            link: ['/projects', this.project.id, 'form-data']
        }, {
            restrict: SystemRole.ProjectManager,
            label: 'Settings',
            icon: 'project-settings',
            link: ['/projects', this.project.id, 'settings']
        }];

        if (this.project.hasImport) {
            this.menuConfigs.push({
                restrict: [SystemRole.Importer, SystemRole.ContentEditor].join(','),
                label: 'Import',
                icon: 'import',
                link: ['/projects', this.project.id, 'import']
            });
        }

        this.menuConfigs.push({
            restrict: [SystemRole.ProjectManager, SystemRole.ContentEditor, SystemRole.FormDesigner, SystemRole.Publisher].join(','),
            label: 'Publish',
            icon: 'publish',
            link: ['/projects', this.project.id, 'publish']
        });
    }

}