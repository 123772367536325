<div class="pad-sides uf-grid">

    <div class="col-6">
        <label class="x-small">Show on</label>
        <div class="row gap-sm-top">
            <uf-checkbox [control]="control.controls.showOnMobile | asUfControl" label="Mobile" class="grow">
            </uf-checkbox>
            <uf-checkbox [control]="control.controls.showOnDesktop | asUfControl" label="Desktop" class="grow">
            </uf-checkbox>
        </div>
    </div>
    <div class="col-6 gap-top">
    </div>

    <uf-text *ngIf="headingControl.enabled" label="Column Heading" [control]="headingControl" class="col-6">
    </uf-text>

    <uf-text *ngIf="defaultCellValueControl.enabled" label="Default Cell Value" [control]="defaultCellValueControl"
        class="col-6">
    </uf-text>

    <uf-select class="col-6" label="Cell Template" nameProperty="_display" valueProperty="_id"
        [control]="control.controls.defaultTemplate | asUfControl" [options]="templateOptions">
    </uf-select>

    <ng-template [ngIf]="!!control.get('defaultTemplate')?.value">
        <uf-select label="Colour" nameProperty="_display" valueProperty="_id" placeholder="Select template"
            [control]="control.controls.colour | asUfControl" [options]="colourOptions" class="col-6">
        </uf-select>
    </ng-template>

    <uf-select *ngIf="formatOptions" label="Format" [placeholder]="formatPlaceholder" [options]="formatOptions"
        [control]="control.controls.defaultFormat | asUfControl" class="col-6">
    </uf-select>

    <uf-textarea *ngIf="itemTemplateControl.enabled" label="Item Template" [control]="itemTemplateControl"
        class="col-12">
    </uf-textarea>

    <div class="col-12">
        <uc-drag-list [items]="(control.controls.variations | asUfControlArray).controls" (moved)="emit()"
            canDrop="false" canReorder="true">
            <ng-container
                *ngFor="let control of (control.controls.variations | asUfControlArray).controls | asUfControlsGroups; let i = index">
                <uf-expander [isExpanded]="true" dragItem class="uf-box draggable flat gap-sm-top">
                    <div expanderHeader dragHandle class="uf-app-bar">
                        <div class="title primary">
                            <div class="ellipsis">{{control.get('condition')?.value}}</div>
                        </div>
                        <div>
                            <button type="button" (click)="removeVariation(i)" title="Delete"
                                class="uf-action tertiary right">
                                <uf-icon name="delete"></uf-icon>
                            </button>
                        </div>
                    </div>
                    <ng-container expanderBody>
                        <div class="grow uf-grid pad">
                            <uf-textarea label="Condition" [control]="control.controls.condition | asUfControl"
                                class="col-12">
                            </uf-textarea>
                            <uf-text label="Value" [control]="control.controls.value | asUfControl" class="col-12">
                            </uf-text>
                            <uf-select label="Template" nameProperty="_display" valueProperty="_id"
                                [options]="templateOptions" [control]="control.controls.template | asUfControl"
                                class="col-6">
                            </uf-select>
                            <uf-select *ngIf="!!control.controls.template.value" label="Colour"
                                placeholder="Select colour" nameProperty="_display" valueProperty="_id"
                                [control]="control.controls.colour | asUfControl" [options]="colourOptions"
                                class="col-6">
                            </uf-select>
                        </div>
                    </ng-container>
                </uf-expander>
            </ng-container>
        </uc-drag-list>
    </div>

    <div class="col-12 pad-bottom row">
        <button (click)="addVariationControl()" type="button" class="uf-button x-small right">
            Add Variation
        </button>
    </div>
</div>