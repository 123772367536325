<!-- Application root component -->
<router-outlet></router-outlet>

<!-- new library modals -->
<uf-modal-container></uf-modal-container>
<uf-toast-container></uf-toast-container>

<!-- Order is important cause we want new icons to override old icons -->
<uc-icons></uc-icons>
<uf-icons></uf-icons>

<div class="tooltip"></div>

<uf-progress class="app-progress" [inProgress]="progressCount > 0"></uf-progress>