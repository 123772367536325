import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';

import { UcAuthProviders } from 'client';

import { AuthProviderTypes } from '../models';

import { AuthProviderResolverData } from './automatic-auth-provider-creation-resolver';


export const manualAuthProviderResolver: ResolveFn<AuthProviderResolverData> = async (route: ActivatedRouteSnapshot) => {

    const ucAuthProviders = inject(UcAuthProviders);
    const id = route.params.id;

    if (id === 'new') {
        return {
            authProvider: {
                type: AuthProviderTypes[route.params.type as keyof typeof AuthProviderTypes],
                isActive: false,
                manual: true,
                extras: {
                    manualRegistration: true
                }
            }
        };
    }
    try {
        const authProvider = await ucAuthProviders.get(id);
        return { authProvider };
    } catch (e) {
        return { error: e.message ?? 'Error loading Authorization Provider' };
    }
}
