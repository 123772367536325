<div class="header-pane uf-app-bar transparent">
    <div class="row header-buttons right">
        <button type="button" class="uf-button tertiary" (click)="cancel()">Cancel</button>
        <button type="button" class="uf-button primary" (click)="save()">Save</button>
    </div>
</div>

<uf-panel class="content-pane container">
    <div class="uf-container-lg">
        <div class="uf-grid row-gap-lg">
            <div class="uf-box col-12">
                <div class="uf-app-bar flat accent">
                    <div class="title">
                        Details
                    </div>
                </div>
                <div class="content grid">
                    <uf-text class="col-1of1" label="Name" [(value)]="project.name"
                        [control]="form.controls.name | asUfControl">
                    </uf-text>
                    <uf-url class="col-1of1" label="Privacy Policy URL" [(value)]="project.privacyPolicyUrl"
                        [control]="form.controls.privacyPolicyUrl | asUfControl">
                    </uf-url>
                </div>
            </div>

            <div class="uf-box col-12">
                <div class="uf-app-bar flat accent">
                    <div class="title">
                        Logo
                    </div>

                    <button type="button" class="uf-action tertiary" *ngIf="project.logo" (click)="deleteLogo()"
                        title="Remove">
                        <uf-icon name="backspace"></uf-icon>
                    </button>
                    <button type="button" class="uf-action tertiary" *ngIf="!project.logo" (click)="findLogo()"
                        title="Edit">
                        <uf-icon name="edit"></uf-icon>
                    </button>
                </div>
                <div class="uf-grid condensed">
                    <uf-message *ngIf="!project.logo" icon="infoSolid" content="No logo added" class="info col-12 gap">
                    </uf-message>
                    <div *ngIf="project.logo" class="col-12 checkboard-bg">
                        <a [routerLink]="['/media', project.logo.id]" target="_blank">
                            <img class="thumb" [src]="project.logo.url + '?h=120'" alt="item.title"
                                style="max-height: 120px;" />
                        </a>
                    </div>
                </div>
            </div>
            <div class="uf-box col-12">
                <div class="uf-app-bar flat accent">
                    <div class="title">
                        Features
                    </div>
                </div>
                <div class="grid">
                    <uf-checkbox label="Offline" [(value)]="project.offline" class="col-1of1"></uf-checkbox>
                    <uf-checkbox label="PIN Lock" [(value)]="project.usesPinLock" class="col-1of1"></uf-checkbox>
                    <uf-autocomplete *ngIf="showCompanyProfile" label="Company Profile Form Data Repository"
                        placeholder="Select a Company Profile Form Data Repository..."
                        [control]="form.controls.companyBucket | asUfControl" [(value)]="project.companyBucket"
                        (searchChange)="searchBucket($event)" [options]="companyResults" class="col-1of1">
                    </uf-autocomplete>
                </div>
            </div>

            <div class="uf-box col-12">
                <div class="uf-app-bar flat accent">
                    <div class="title">
                        Primary Language
                    </div>
                </div>
                <div class="content grid">
                    <uf-autocomplete class="col-1of1" placeholder="Select a language..."
                        [control]="form.controls.primaryLanguage | asUfControl" [(value)]="project.primaryLanguage"
                        (searchChange)="searchLang($event)" [options]="langResults" nameProperty="name">
                    </uf-autocomplete>
                </div>
            </div>

            <div class="uf-box col-12">
                <div class="uf-app-bar flat accent">
                    <div class="title">
                        Additional Languages
                    </div>
                </div>
                <ul class="uf-list">
                    <li>
                        <div class="uf-grid grow pad">
                            <uf-search-result placeholder="Add a language..." (valueChange)="addLang($event)"
                                (searchChange)="searchLang($event, true)" [options]="langResults" nameProperty="name"
                                class="col-12"></uf-search-result>
                        </div>
                    </li>
                    <li *ngFor="let lang of project.languages; let i = index" class="row">
                        <div class="uf-grid grow pad">
                            <uf-text label="Name" [value]="lang.name" [disabled]="true" class="col-6"></uf-text>
                            <uf-text label="Code" [value]="lang.code" [disabled]="true" class="col-6"></uf-text>
                        </div>
                        <div class="col center">
                            <button type="button" (click)="removeLang(i)" title="Delete" class="uf-action tertiary">
                                <uf-icon name="delete"></uf-icon>
                            </button>
                        </div>
                    </li>
                </ul>
            </div>

            <div class="uf-box col-12">
                <div class="uf-app-bar flat accent">
                    <div class="title">
                        Default Apps
                    </div>
                </div>

                <ul class="uf-list">
                    <li class="row">
                        <div class="uf-grid grow pad">
                            <uf-select label="Preview App" placeholder="" [(value)]="project.previewApp"
                                [options]="allApps" trackBy="id" class="col-6">
                            </uf-select>
                            <uf-url label="Preview Web Url" [(value)]="project.previewWebUrl" class="col-6">
                            </uf-url>
                        </div>
                    </li>
                    <li class="row">
                        <div class="uf-grid grow pad">
                            <uf-select label="Live App" placeholder="" [(value)]="project.liveApp" [options]="allApps"
                                trackBy="id" class="col-6"></uf-select>
                            <uf-url label="Live Web Url" [(value)]="project.liveWebUrl" class="col-6">
                            </uf-url>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</uf-panel>