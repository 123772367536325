<uf-expander class="uf-box flat">

    <div expanderHeader class="uf-app-bar flat">
        <div class="title primary">Variations</div>
        <uf-icon *ngIf="variations.invalid" name="error" class="error"></uf-icon>
        <uc-expander-controls *ngIf="variations.length" [container]="variationsWrap"></uc-expander-controls>
    </div>

    <div expanderBody #variationsWrap class="pad-sm">

        <uc-drag-list [items]="variations.controls" canDrop="false" canReorder="true">

            <ng-template ngFor [ngForOf]="variations.controls | asUfControlsGroups" let-variation let-i="index">
                <uf-expander dragItem [dragDisabled]="variations.disabled" [isExpanded]="expandeds[i]"
                    class="uf-box flat draggable gap-sm-bottom">
                    <div expanderHeader dragHandle class="uf-app-bar flat">
                        <div class="title primary">
                            {{ variation.get(variationKeys.Name)?.value }}
                        </div>
                        <uf-icon *ngIf="variation.invalid" name="error" class="error">
                        </uf-icon>
                        <button *ngIf="!variation.disabled" class="uf-action" (click)="removeVariation(i)"
                            title="Delete">
                            <uf-icon name="delete"></uf-icon>
                        </button>
                    </div>
                    <div expanderBody [formGroup]="variation" class="uf-grid pad">
                        <uf-message *ngIf="showVariationError(variation)" icon="errorSolid"
                            class="x-small col-12 error">
                            <p>This variation has an error - please select it for more information.</p>
                        </uf-message>
                        <uf-text [formControlName]="variationKeys.Name" label="Name" class="col-12">
                        </uf-text>
                        <uf-textarea [formControlName]="variationKeys.Condition" label="Condition" class="col-12">
                        </uf-textarea>
                    </div>
                </uf-expander>
            </ng-template>

        </uc-drag-list>

        <button *ngIf="!control.disabled" class="uf-button x-small right gap-sm-top" (click)="addVariation()">
            Add Variation
        </button>

    </div>
</uf-expander>