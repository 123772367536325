import { EMPTY, lastValueFrom } from 'rxjs';

import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, Router } from '@angular/router';
import { ToastService } from '@unifii/library/common';
import { ErrorType } from '@unifii/sdk';

import { UcProjectInfo } from 'client';

import { ContextService } from 'services/context.service';
import { ProjectService } from 'services/project.service';


export const projectResolver: ResolveFn<UcProjectInfo> = async (route: ActivatedRouteSnapshot) => {

    const context = inject(ContextService);
    const router = inject(Router);
    const toastService = inject(ToastService);
    const projectService = inject(ProjectService);

    const { languageId, projectId } = route.params;
    if (!projectId) {
        return lastValueFrom(EMPTY);
    }

    try {
        const project = await projectService.setProject(projectId);

        if (!project) {
            return lastValueFrom(EMPTY);
        }

        context.language = (project.languages || []).find(l => l.code === languageId) || null;
        // Register optional translation language
        if (languageId && !context.language) {
            // router.navigate(['/translations']);
            console.log('Should navigate back to /translations');
            return lastValueFrom(EMPTY);
        }

        return project;

    } catch (error) {
        if (error.type !== ErrorType.Unauthorized && error.type !== ErrorType.Forbidden) {
            toastService.error(`Failed to load project ${projectId}`);
            router.navigate(['/projects']);
        }
        return lastValueFrom(EMPTY);
    }
}
