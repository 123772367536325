import { Component, HostBinding } from '@angular/core';


@Component({
    templateUrl: './template-tabs.html'
})
export class TemplateTabsComponent {

    @HostBinding('class.stretch-component') class = true;

}

