import { Subscription } from 'rxjs';

import { Component, HostBinding, Inject, OnDestroy, OnInit } from '@angular/core';
import { Modal, ModalData, ModalRuntime, UfControl, UfControlArray, UfControlGroup } from '@unifii/library/common';
import { Schema, SchemaTransition, Transition } from '@unifii/sdk';

import { DefinitionInfo } from 'client';

import { ArrayHelper } from 'helpers/helpers';

import { FormEditorCache } from '../form-editor-cache';
import { TransitionControlKeys } from '../form-editor-control-keys';
import { FormEditorFormCtrl } from '../form-editor-form-ctrl';
import { FormFieldMetadata } from '../form-editor-model';
import { FormEditorStatus } from '../form-editor-status';
import { FormEditorService } from '../form-editor.service';

import { sortValue } from './field-utils';


export interface FormFieldTransitionEditorData {
    bucket: string;
    transition: UfControlGroup;
    roles: Set<string>;
    meta: FormFieldMetadata; // The Section field metadata
}

@Component({
    templateUrl: './form-field-transition-editor.html',
    styleUrls: ['./form-field-transition-editor.less']
})
export class FormFieldTransitionEditorComponent implements Modal<FormFieldTransitionEditorData, UfControlGroup>, OnInit, OnDestroy {

    @HostBinding('class.uc-form-card') classes = true;

    readonly transitionKeys = TransitionControlKeys;

    isNew: boolean;
    ready: boolean;
    edited: boolean;

    control: UfControlGroup;
    bucket: string;
    roles: Set<string>;
    meta: FormFieldMetadata;

    formDefinitionInfos: DefinitionInfo[];
    schema: Schema | null;
    startStatusesResult: string[] = [];
    endStatusesResult: string[] = [];
    rolesResult: string[] = [];
    tagsResult: string[] = [];
    subscriptions: Subscription[] = [];

    constructor(
        public runtime: ModalRuntime<FormFieldTransitionEditorData, UfControlGroup>,
        @Inject(ModalData) data: FormFieldTransitionEditorData,
        private fb: FormEditorFormCtrl,
        @Inject(FormEditorCache) private cache: FormEditorCache,
        private service: FormEditorService,
        private status: FormEditorStatus,
    ) {
        this.control = data.transition;
        this.bucket = data.bucket;
        this.roles = data.roles;
        this.meta = data.meta;
    }

    get transition(): Transition {
        return this.control.getRawValue() as Transition;
    }

    get label(): string {
        if (this.isNew) {
            return 'New Workflow';
        }
        return `${this.transition.source ?? ''} > ${this.transition.target ?? ''} [${this.transition.actionLabel ?? ''}] ${this.edited ? '*' : ''}`;
    }

    get triggers(): UfControlArray {
        return this.control.get(TransitionControlKeys.Triggers) as UfControlArray;
    }

    get spawns(): UfControlArray {
        return this.control.get(TransitionControlKeys.Spawns) as UfControlArray;
    }

    async ngOnInit() {
        this.isNew = this.transition.source == null || this.transition.target == null || this.transition.actionLabel == null;
        this.subscriptions.push(this.control.valueChanges.subscribe(() => this.edited = true));

        this.subscriptions.push((this.control.get(TransitionControlKeys.Source) as UfControl).valueChanges.subscribe(this.service.refreshTransitionStatuses.bind(this)));
        this.subscriptions.push((this.control.get(TransitionControlKeys.Target) as UfControl).valueChanges.subscribe(this.service.refreshTransitionStatuses.bind(this)));

        this.formDefinitionInfos = await this.cache.getFormsInfo();
        this.schema = await this.cache.getSchema(this.bucket);

        sortValue(this.control, TransitionControlKeys.Roles);

        this.ready = true;
    }

    ngOnDestroy() {
        for (const sub of this.subscriptions) {
            sub.unsubscribe();
        }
    }

    close() {
        this.runtime.close();
    }

    save() {

        this.control.setSubmitted();

        if (this.control.invalid) {
            return;
        }

        // Save
        this.edited = false;

        this.runtime.close(this.control);
    }

    findStartStatuses(query: string) {
        this.startStatusesResult = ArrayHelper.filterList([...this.status.statuses], query);
    }

    findEndStatuses(query: string) {
        this.endStatusesResult = ArrayHelper.filterList([...this.status.statuses], query);
    }

    findTags(query: string) {
        this.tagsResult = ArrayHelper.filterList([...this.status.tags], query);
    }

    findRoles(query?: string) {
        this.rolesResult = ArrayHelper.filterList([...this.roles], query);
    }

    async removeTrigger(i: number) {
        this.triggers.removeAt(i);
    }

    addSpawn() {
        this.spawns.push(this.fb.buildTransitionTargetControl({ label: '' }, this.meta, !this.control.submitted));
    }

    async removeSpawn(i: number) {
        this.spawns.removeAt(i);
    }

    transitionLabel(transition: SchemaTransition): string {
        return `${transition.source} > ${transition.target} (${transition.action})`;
    }

}