import { Subject } from 'rxjs';

import { Injectable } from '@angular/core';
import { TableContainerManager, TableInputs } from '@unifii/components';
import {
    FieldDisplayPipe, FilterEntry, FilterValue, TableAction, TableConfig, TableConfigColumn, TableRowContext, ToastService
} from '@unifii/library/common';
import { FieldType } from '@unifii/sdk';

import { ApiKey, UcAPIKeys } from 'client';

import { APIKeysDataSource } from './api-keys-datasource';


@Injectable()
export class APIKeysTableManager implements TableContainerManager<ApiKey, FilterValue, FilterEntry> {

    tableConfig: TableConfig<ApiKey>;
    addActionConfig = true;

    reload = new Subject<void>();
    update = new Subject<TableInputs<FilterValue>>();

    constructor(
        private ucAPIKeys: UcAPIKeys,
        private toastService: ToastService,
        private fielDisplay: FieldDisplayPipe
    ) {
        this.tableConfig = {
            id: 'api-keys',
            columns: this.columns,
            actions: this.actions,
            pageSize: 50,
            selectable: true,
            columnToggles: true,
            rowLink: role => role.key as string
        };
    }

    createDataSource() {
        return new APIKeysDataSource(this.ucAPIKeys);
    }

    private get columns(): TableConfigColumn<ApiKey>[] {
        return [{
            name: 'name',
            label: 'Name'
        }, {
            name: 'key',
            label: 'Key'
        }, {
            name: 'createdBy',
            label: 'Created By',
            value: item => item.createdBy.username
        }, {
            name: 'createdAt',
            label: 'Created At',
            value: item => this.fielDisplay.transform(item.createdAt, FieldType.DateTime)
        }];
    }

    private get actions(): TableAction<ApiKey>[] {
        return [{
            label: 'Revoke',
            icon: 'delete',
            action: (rows: TableRowContext<ApiKey>[]) => this.delete(rows.map(row => row.$implicit))
        }];
    }

    private async delete(keys: ApiKey[]) {
        // Create new reference so array can be modified for printing a meaningful error message
        keys = [...keys];

        try {
            for (let i = (keys.length - 1); i >= 0; i--) {
                const key = keys[i];
                await this.ucAPIKeys.delete(key.key as string);
                keys.splice(i, 1);
            }
            this.toastService.success('API Keys revoke');
        } catch (e) {
            this.toastService.error(`Failed to revoke API Keys: ${keys.map(k => k.name)}`);
        } finally {
            this.reload.next();
        }
    }

}
