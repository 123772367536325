<div class="header">
    <button type="button" class="uf-action tertiary" (click)="runtime.close()" title="Close">
        <uf-icon name="close"></uf-icon>
    </button>
    <h3>{{ edit ? 'Edit' : 'Add'}} Field Mapping</h3>
</div>

<uf-panel>
    <div *ngIf="mappingForm" class="uf-box gap">
        <div class="uf-app-bar flat accent">
            <div class="title">Provider Conditions</div>
        </div>
        <ng-container *ngFor="let condition of conditions.controls | asUfControlsGroups; let i = index">
            <uc-auth-provider-mapping-unit *ngIf="condition" [form]="condition" [sourceClaims]="sourceClaims"
                (deleteClick)="deleteCondition($event)" [authProviderId]="authProviderId" [config]="config"
                [identifier]="i" [authProviderType]="data.authProviderType"></uc-auth-provider-mapping-unit>
        </ng-container>

        <div class="uf-form-actions">
            <button [options]="conditionOptions" (optionSelected)="addCondition($event)" type="button"
                class="uf-button x-small" listBox nameProperty="name">
                Add
                <uf-icon name="more" class="gap-none"></uf-icon>
            </button>
        </div>
    </div>

    <div class="uf-box gap-top gap">
        <div class="uf-app-bar flat accent">
            <div class="title">Unifii User Actions</div>
        </div>
        <uc-auth-provider-mapping-actions [actions]="actions" [config]="config"
            [authProviderId]="authProviderId"></uc-auth-provider-mapping-actions>
    </div>

</uf-panel>

<div class="uf-form-actions">
    <uf-error [control]="conditions"></uf-error>
    <uf-error *ngIf="conditions.valid" [control]="actions"></uf-error>
    <button (click)="runtime.close()" type="button" class="uf-button tertiary right">Cancel</button>
    <button (click)="save()" type="button" class="uf-button primary">Save</button>
</div>