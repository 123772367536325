import { Component } from '@angular/core';
import { Breadcrumb, FeatureFlagService } from '@unifii/library/common';

import { SystemRole } from 'client';

import { TabConfig } from 'pages/page-models';
import { TabsPage } from 'pages/tabs-page';

import { BreadcrumbService } from 'services/breadcrumb.service';
import { ContextService } from 'services/context.service';


@Component({
    templateUrl: './user-management.html',
})
export class UserManagementComponent extends TabsPage {

    breadcrumbs: Breadcrumb[];
    tabs: TabConfig[] = [];

    constructor(
        private breadcrumbService: BreadcrumbService,
        private context: ContextService,
        private featureFlagService: FeatureFlagService
    ) {
        super();
        this.breadcrumbs = this.breadcrumbService.getBreadcrumbs(this.route);

        this.initTabs();
    }

    async initTabs() {
        if (this.context.checkRoles(SystemRole.UserManager)) {
            this.tabs.push(
                { label: 'Users', path: 'users' },
                { label: 'Roles', path: 'roles' },
                { label: 'User Claims', path: 'user-claims' },
                { label: 'Hierarchy', path: 'hierarchy' },
                { label: 'API Keys', path: 'api-keys' }
            );
        }

        if (this.context.checkRoles(SystemRole.Auditor)) {
            this.tabs.push(
                { label: 'AuditLog', path: 'audit-log' });
        }
    }
}
