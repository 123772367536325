import { Subscription } from 'rxjs';

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import {
    Breadcrumb, BucketDataDescriptorAdapter, DataPropertyDescriptor, ModalService, ToastService, UfControl, UfControlGroup, UfFormBuilder
} from '@unifii/library/common';
import { DataSourceInputType, FieldType, Schema, SchemaField } from '@unifii/sdk';

import {
    DataForwarder, IntegrationFeatureType, IntegrationInfo, IntegrationProviderFeature, SchemaInfo, UcClient, UcIntegrations, UcProject
} from 'client';
import { UcWorkflow } from 'client/workflow';

import { EditData } from 'components/common/edit-data';

import { SchemaFieldMapperComponent, SchemaFieldMappingData } from 'pages/form-editor/field-configuration/schema-field-mapper.component';

import { BreadcrumbService } from 'services/breadcrumb.service';

import { WorkflowActivityTableManager } from './workflow-activity-table-manager';
import { ControlKeys, WorkflowDataForwardersFormController, WorkflowDataForwardersModel } from './workflow-data-forwarders-form.controller';


@Component({
    templateUrl: 'workflow-data-forwarders-form.html'
})
export class WorkflowDataForwardersFormComponent implements EditData, OnInit, OnDestroy {

    readonly controlKeys = ControlKeys;

    error?: string;
    edited = false;
    form: UfControlGroup;
    buckets: SchemaInfo[];
    integrations: IntegrationInfo[];
    breadcrumbs: Breadcrumb[];
    filteredFeatures: IntegrationProviderFeature[];

    private fields: DataPropertyDescriptor[];
    private bucket?: Schema;
    private features: IntegrationProviderFeature[];
    private subscriptions = new Subscription();

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private ucProject: UcProject,
        private ucWorkflow: UcWorkflow,
        private ufb: UfFormBuilder,
        private toastService: ToastService,
        private ucIntegrations: UcIntegrations,
        private ucClient: UcClient,
        private modal: ModalService,
        private formController: WorkflowDataForwardersFormController,
        private bucketDataDescriptorAdapter: BucketDataDescriptorAdapter,
        private breadcrumbService: BreadcrumbService,
        @Inject(TableContainerManager) private tableManager: WorkflowActivityTableManager
    ) { }

    async ngOnInit() {
        const { id, duplicate } = this.route.snapshot.params;

        try {
            const dataForwarder = await this.getDataForwarder(id);
            const model = await this.formController.toModel(dataForwarder);

            if (model != null) {
                if (duplicate) {
                    model.id = null as any as string;
                    model.label += ' (copy)';
                }

                this.breadcrumbs[2].name = model.label;
                if (model.bucket?.id) {
                    this.bucket = await this.ucProject.getBucket(model.bucket.id);
                    await this.loadDataDescriptors();
                }
            }

            this.form = this.formController.buildRoot(model);
            this.subscriptions.add(this.form.valueChanges.subscribe(() => this.edited = true));

            this.breadcrumbs = this.breadcrumbService.getBreadcrumbs(this.route, [model?.label ?? 'New']);
        } catch (e) {
            this.error = e.message;
            console.error(e);
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    get inputMapControl(): UfControl {
        return this.form.get(ControlKeys.InputMap) as UfControl;
    }

    get hasInputMap(): boolean {
        return (this.featureControl?.value?.inputArgs ?? []).length > 0 && this.bucket != null;
    }

    private get integrationControl(): UfControl {
        return this.form.get(ControlKeys.Integration) as UfControl;
    }

    private get featureControl(): UfControl {
        return this.form.get(ControlKeys.Feature) as UfControl;
    }

    async searchBuckets(q: string) {
        this.buckets = await this.ucProject.getFormBuckets({ params: { q } });
    }

    async searchIntegration(q?: string) {
        this.integrations = await this.ucIntegrations.list({ params: { q } });
    }

    async save() {
        this.error = undefined;
        this.form.setSubmitted();
        this.inputMapControl?.markAllAsTouched();

        if (!this.form.valid) {
            return;
        }

        try {
            const model = this.form.getRawValue() as WorkflowDataForwardersModel;
            const dataForwarder = this.formController.toData(model);

            let updatedDataForwarder;
            if (!dataForwarder.id) {
                updatedDataForwarder = await this.ucWorkflow.addActivity<DataForwarder>(dataForwarder);
                this.toastService.success('Data Forwarder saved successfully');
                this.tableManager.reload.next();
            } else {
                updatedDataForwarder = await this.ucWorkflow.updateActivity<DataForwarder>(dataForwarder);
                this.toastService.success('Data Forwarder updated successfully');
                this.tableManager.updateItem.next(updatedDataForwarder);
            }

            this.edited = false;
            this.router.navigate(['../'], { relativeTo: this.route });
        } catch (e) {
            this.error = e.message;
        }
    }

    async bucketChange(bucket: SchemaInfo) {
        this.bucket = bucket ? await this.ucProject.getBucket(bucket.id) : undefined;
        if (this.featureControl?.value) {
            this.featureChange(this.featureControl.value);
        }
        await this.loadDataDescriptors();
    }

    async integrationChange(integration?: IntegrationInfo) {
        if (integration) {
            await this.loadProvider(integration.provider.id ?? '');
        }

        this.form.get(ControlKeys.Feature)?.setValue(undefined);
    }

    async inputArgClick(control: UfControl) {
        const { identifier } = control.value;
        const inputArg = this.featureControl?.value?.inputArgs?.find((arg: any) => arg.identifier === identifier);

        if (!inputArg || !this.bucket) {
            return;
        }

        const sourceFields: SchemaField[] = [{
            identifier,
            label: identifier,
            type: inputArg.type === DataSourceInputType.Number ? FieldType.Number : FieldType.Text
        }];

        const data: SchemaFieldMappingData = {
            sourceFields,
            targetFields: this.fields,
            mappingGroup: {
                control: this.ufb.group({
                    source: sourceFields[0],
                    target: control.value?.field
                }),
                sourceKey: 'source',
                targetKey: 'target'
            },
            sourceFieldLabel: 'Integration Parameter',
            targetFieldLabel: 'Form Data',
        };

        const result = await this.modal.openMedium<SchemaFieldMappingData, UfControlGroup>(SchemaFieldMapperComponent, data);

        if (!result) {
            return;
        }

        control.setValue({
            ...control.value,
            field: result.get('target')?.value
        });

        control.setSubmitted();
    }

    async searchFeature(query?: string) {
        if (!this.features) {
            const integration = this.integrationControl?.value;
            if (integration) {
                await this.loadProvider(integration.provider.id);
            }
        }
        this.filteredFeatures = (this.features ?? []).filter(v => !query || v.name.toLowerCase().includes(query.toLowerCase()));
    }

    async featureChange(feature: IntegrationProviderFeature) {
        this.form.removeControl(ControlKeys.InputMap);
        if (this.bucket) {
            if (!!feature) {
                this.form.addControl(ControlKeys.InputMap, this.formController.buildInputMapControl({
                    feature
                }));
            }
        }
    }


    private async getDataForwarder(id: string): Promise<DataForwarder | null> {
        if (id === 'new') {
            return null;
        }
        return this.ucWorkflow.getActivity<DataForwarder>(id);
    }

    private async loadProvider(id: string) {
        const provider = await this.ucClient.getAvailableIntegration(id);
        this.features = (provider.features ?? []).filter(f => f.type === IntegrationFeatureType.Sink);
    }

    private async loadDataDescriptors() {
        if (!this.bucket?.bucket) {
            this.fields = [];
            return;
        }

        const { propertyDescriptors } = await this.bucketDataDescriptorAdapter.getDataDescriptor(this.bucket?.bucket) ?? { propertyDescriptors: [] };
        this.fields = propertyDescriptors;
    }

}