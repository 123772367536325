import { Subscription } from 'rxjs';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalService, ToastService, UfControl, UfControlGroup, ValidatorFunctions } from '@unifii/library/common';

import { SmtpInfo, UcSmtp } from 'client';

import { EditData } from 'components/common/edit-data';

import { DialogsService } from 'services/dialogs.service';

import { TenantSettingsComponent } from './settings.component';
import { SystemSmtpTestModalComponent } from './system-smtp-test-modal.component';


@Component({
    templateUrl: './system-smtp.html',
    styleUrls: ['./system-smtp.less']
})
export class SystemSmtpComponent implements OnInit, OnDestroy, EditData {

    canDelete = true;

    loading = true;
    error: any;

    form: UfControlGroup;

    valueChangesSubscription: Subscription;

    constructor(
        private ucSmtp: UcSmtp,
        private toast: ToastService,
        private modal: ModalService,
        private dialogs: DialogsService,
        private parent: TenantSettingsComponent
    ) { }

    set edited(v: boolean) {
        this.parent.edited = v;
    }

    get edited() {
        return this.parent.edited;
    }

    async ngOnInit() {
        this.form = new UfControlGroup({
            smtpHost: new UfControl(ValidatorFunctions.required('Host is required')),
            smtpPort: new UfControl(),
            smtpSystemName: new UfControl(),
            smtpSystemEmail: new UfControl(
                ValidatorFunctions.compose([
                    ValidatorFunctions.email('Invalid email'),
                    ValidatorFunctions.required('System Email is required')
                ])
            ),
            smtpUsername: new UfControl(ValidatorFunctions.required('Username is required')),
            smtpPassword: new UfControl(ValidatorFunctions.required('Password is required')),
        });

        await this.load();
    }

    ngOnDestroy() {
        if (this.valueChangesSubscription) {
            this.valueChangesSubscription.unsubscribe();
        }
        this.parent.edited = false;
    }

    async load() {
        this.error = null;
        this.edited = false;
        this.loading = true;
        if (this.valueChangesSubscription) {
            this.valueChangesSubscription.unsubscribe();
        }

        try {
            const smtpInfo = await this.ucSmtp.get();
            this.form.patchValue(smtpInfo);
        } catch (e) {
            if (e && e.code === '404') { // 404 means SMTP is just not set up yet
                this.canDelete = false;
            } else {
                this.error = e;
                this.toast.error('Loading error');
                console.error(e);
            }
        }
        this.loading = false;
        this.valueChangesSubscription = this.form.valueChanges.subscribe(() => this.edited = true);
    }

    async save() {
        this.error = null;

        this.form.markAllAsTouched();

        if (!this.form.valid) {
            return;
        }

        // TODO check uf-password component and why it removes value from root control
        if (!this.form.value.smtpPassword && this.form.controls.smtpPassword.value) {
            this.form.value.smtpPassword = this.form.controls.smtpPassword.value;
        }

        try {
            await this.ucSmtp.save(this.form.value as SmtpInfo);
            await this.load();
            this.toast.success('Saved successfully');
        } catch (e) {
            this.error = e;
            this.toast.error('Error saving');
            console.error(e);
        }
    }

    async delete() {
        this.error = null;

        if (!await this.dialogs.confirmDelete()) {
            return;
        }

        try {
            await this.ucSmtp.delete();
            await this.load();
        } catch (e) {
            this.error = e;
            this.toast.error('Error deleting');
            console.error(e);
        }
    }

    async sendTest() {
        this.error = null;

        this.form.markAllAsTouched();

        const email = await this.modal.openFit(SystemSmtpTestModalComponent, null);

        if (!this.form.valid || !email) {
            return;
        }

        // TODO check uf-password component and why it removes value from root control
        if (!this.form.value.smtpPassword && this.form.controls.smtpPassword.value) {
            this.form.value.smtpPassword = this.form.controls.smtpPassword.value;
        }

        try {
            await this.ucSmtp.test(this.form.value as SmtpInfo, email as string);
            this.toast.success('Test email sent');
        } catch (e) {
            this.error = e;
            this.toast.error('Error sending test');
            console.error(e);
        }
    }

}