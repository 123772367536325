import { Injectable } from '@angular/core';
import { FromNowPipe, TableConfigColumn } from '@unifii/library/common';
import { TableSourceType } from '@unifii/sdk';

import { DefinitionInfo, TableInfo } from 'client';

import { TableSourceTypeLabelPipe } from 'pipes/table-source-type-label.pipe';

import { Info, InfoType } from 'services/table/models';


@Injectable({
    providedIn: 'root'
})
export class InfoColumnFactory {

    constructor(
        private fromNowPipe: FromNowPipe,
        private tableSourceTypePipe: TableSourceTypeLabelPipe
    ) {
    }

    create(type: InfoType): TableConfigColumn<Info>[] {
        switch (type) {
            case InfoType.Form: return [...this.createFormColumns(), ...this.createStatusColumns()];
            case InfoType.Table: return [...this.createTableColumns(), ...this.createStatusColumns()];
            case InfoType.Collection: return [...this.createCollectionColumns()];
            case InfoType.CollectionData: return [...this.createCollectionDataColumns(), ...this.createStatusColumns()];
            default: return [...this.createCommonColumns(), ...this.createStatusColumns()];
        }
    }

    private createTableColumns(): TableConfigColumn<TableInfo>[] {
        return [{
            name: 'id',
            label: 'Id',
            sortable: true,
            hidden: true
        }, {
            name: 'identifier',
            label: 'Identifier',
            sortable: true,
            hidden: true
        }, {
            name: 'title',
            label: 'Title',
            sortable: true
        }, {
            name: 'description',
            label: 'Description'
        }, {
            name: 'sourceType',
            label: 'Type',
            value: item => this.tableSourceTypePipe.transform(item.sourceType)
        }, {
            name: 'source',
            label: 'Source',
            sortable: true,
        }, {
            name: 'hasDetail',
            label: 'Detail',
            value: item => {
                if (item.hasDetail === true) {
                    return 'Details Page';
                }
                switch (item.sourceType) {
                    case TableSourceType.Bucket: return 'Form';
                    case TableSourceType.Company: return 'Company';
                    default: return 'User';
                }
            }
        }
        ];
    }

    private createFormColumns(): TableConfigColumn<DefinitionInfo>[] {
        return [{
            name: 'identifier',
            label: 'Identifier',
            sortable: true,
            hidden: true
        }, {
            name: 'name',
            label: 'Form Name',
            sortable: true
        }, {
            name: 'bucket',
            label: 'Form Data Repository',
            sortable: true
        }, {
            name: 'formNumberFormat',
            label: 'Form Number Format',
            sortable: false,
            hidden: true
        }];
    }

    private createCollectionColumns(): TableConfigColumn<DefinitionInfo>[] {
        return [{
            name: 'identifier',
            label: 'Identifier',
            sortable: false,
            hidden: true
        }, {
            name: 'name',
            label: 'Name',
            sortable: false
        }];
    }

    private createCollectionDataColumns(): TableConfigColumn<DefinitionInfo>[] {
        return [{
            name: 'identifier',
            label: 'Identifier',
            sortable: false,
            hidden: true
        }, {
            name: '_title',
            label: 'Title',
            sortable: true
        }];
    }


    private createCommonColumns(): TableConfigColumn<Info>[] {
        return [{
            name: 'id',
            label: 'Id',
            sortable: true,
            hidden: true
        }, {
            name: '_title',
            label: 'Title',
            sortable: true
        }];
    }

    private createStatusColumns(): TableConfigColumn<Info>[] {
        return [{
            name: 'lastModifiedAt',
            label: 'Last Modified',
            sortable: true,
            value: item => this.fromNowPipe.transform(item.lastModifiedAt)
        }, {
            name: 'lastModifiedBy',
            label: 'Modified By',
            sortable: true,
            hidden: true,
            value: item => item.lastModifiedBy?.username
        }, {
            name: 'lastPublishedAt',
            label: 'Last Published',
            sortable: true,
            value: item => this.fromNowPipe.transform(item.lastPublishedAt)
        }, {
            name: 'lastPublishedBy',
            label: 'Published By',
            sortable: true,
            hidden: true,
            value: item => item.lastPublishedBy?.username
        }, {
            name: 'publishState',
            label: 'State',
        }];
    }


}

