import { Inject, Injectable } from '@angular/core';
import {
    ClientGetOptions, Compound, ContentType, Definition, Dictionary, ImageProfile, NodeType, Page, ProjectContentOptions,
    ProjectContentOptionsInterace, PublishedContent, Query, Schema, Table
} from '@unifii/sdk';

import { StructureService, UcClient, UcProject } from 'client';


@Injectable()
export class PreviewContentService implements PublishedContent {

    constructor(
        private ucClient: UcClient,
        private ucProject: UcProject,
        @Inject(ProjectContentOptions) private projectOptions: ProjectContentOptionsInterace
    ) { }

    getView(identifier: string): Promise<Compound> {
        return this.ucProject.getView(+identifier);
    }

    getViewDefinition(identifier: string): Promise<Definition> {
        return this.ucProject.getViewDefinition(identifier) as Promise<Definition>;
    }

    getPage(identifier: string): Promise<Page> {
        return this.ucProject.getPage(+identifier);
    }

    getCollectionDefinition(identifier: string): Promise<Definition> {
        return this.ucProject.collection(identifier).getDefinition() as Promise<Definition>;
    }

    getCollections(): Promise<Definition[]> {
        throw new Error('not implemented');
    }

    queryCollection(identifier: string, query?: Query): Promise<Compound[]> {
        const ids = this.getIds(query || null);
        return this.ucProject.collection(identifier).get({ limit: 200, full: true, ids: ids?.join(',') });
    }

    getCollectionItem(identifier: string, id: string): Promise<Compound> {
        return this.ucProject.collection(identifier).getItem(+id);
    }

    getBucket(identifier: string): Promise<Schema> {
        return this.ucProject.getBucket(identifier);
    }

    queryForms(_query?: Query): Promise<Definition[]> {
        throw new Error('not implemented');
    }

    getForm(identifier: string, _version: any = null): Promise<Definition> {
        return this.ucProject.getForm(identifier) as Promise<Definition>;
    }

    queryTables(_query?: Query): Promise<Table[]> {
        throw new Error('not implemented');
    }

    queryPages(_query?: Query, _options?: ClientGetOptions): Promise<Page[]> {
        throw new Error('not implemented.');
    }

    getTable(id: string): Promise<Table> {
        return this.ucProject.getTable(id);
    }

    getStructure() {
        return new StructureService(this.ucClient, this.projectOptions).get();
    }

    getIdentifiers(): Promise<Dictionary<{ type: ContentType }>> {
        throw new Error('not implemented');
    }

    getAssetUrl(_id: string): Promise<string> {
        throw new Error('Method not implemented.');
    }

    // TODO remove and instead use SDK buildImageUrl functions
    buildImageUrl(imageProfile: ImageProfile, options?: any): string {

        const url = new URL(imageProfile.url as string);

        // set crop
        if (imageProfile.crop != null) {
            url.searchParams.set('crop', `(${imageProfile.crop.x}, ${imageProfile.crop.y}, ${imageProfile.crop.x + imageProfile.crop.width}, ${imageProfile.crop.y + imageProfile.crop.height})`);
        }

        if (options) {
            if (options.width) {
                url.searchParams.set('w', options.width.toString());
            }
        }

        return url.toString();
    }

    private getIds(query: Query | null): string[] | null {

        if (query != null && query.args.length === 1) {
            const n = query.args[0];
            if (n.op === 'in' && n.args[0].type === NodeType.Identifier && n.args[0].value === 'id') {
                return n.args[1].value as string[];
            }
        }

        return null;
    }
}

