import { Component, HostBinding, Inject } from '@angular/core';
import { DescriptionListItem, Modal, ModalData, ModalRuntime, UfControlGroup } from '@unifii/library/common';

import { DataSourceExternalInfo, DataSourceExternalInputControlKey } from './data-source-model';


@Component({
    selector: 'uc-data-source-external-input',
    templateUrl: './data-source-external-input-editor.html'
})
export class DataSourceExternalInputEditorComponent implements Modal<UfControlGroup, UfControlGroup> {

    @HostBinding('class.uf-form-card') cardClass = true;

    readonly externalInputKeys = DataSourceExternalInputControlKey;

    info: DataSourceExternalInfo;
    label: string;
    infoDescriptors: DescriptionListItem[];

    constructor(
        public runtime: ModalRuntime<DataSourceExternalInputEditorComponent, UfControlGroup>,
        @Inject(ModalData) public control: UfControlGroup
    ) {
        this.info = control.get(DataSourceExternalInputControlKey.Info)?.value;
        this.label = `${this.info.required ? '* ' : ''}Value or Expression`;

        this.infoDescriptors = [
            { term: 'Parameter', description: this.info.parameter },
            { term: 'Source', description: this.info.source },
            { term: 'Type', description: this.info.type },
            { term: 'Required', description: this.info.required ? 'Yes' : 'No' }
        ];
    }

    close() {
        this.runtime.close();
    }

    submit() {
        this.control.setSubmitted();
        if (this.control.invalid) {
            return;
        }

        this.runtime.close(this.control);
    }

}