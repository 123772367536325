import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { UfControl } from '@unifii/library/common';
import { FormField } from '@unifii/library/smart-forms';
import { CompoundType } from '@unifii/sdk';

import { BuilderService } from 'components/compound-builder/builder.service';


@Component({
    templateUrl: './uc-markdown-wrapper.html'
})
export class UcMarkdownWrapperComponent implements FormField, OnInit {

    @Input() field: any;
    @Input() control: UfControl;
    @Input() disabled: boolean;

    content: any;
    showAlignmentOptions: boolean;
    contentChange: EventEmitter<any>;

    constructor(private builderService: BuilderService) {
    }

    ngOnInit() {
        this.showAlignmentOptions = this.builderService.builder.type === CompoundType.Page;
    }

    valueChanged(value: string) {
        if (this.contentChange) {
            this.contentChange.emit(value);
        }

    }
}