import { Injectable } from '@angular/core';
import { amendOptionsParams, ClientGetOptions } from '@unifii/sdk';

import { DefaultPaginationParams } from 'constant';

import { Integration, IntegrationInfo } from './models';
import { UcClient } from './uc-client';


@Injectable()
export class UcIntegrations {

    constructor(private client: UcClient) { }

    list(options?: ClientGetOptions): Promise<IntegrationInfo[]> {
        return this.client.get(this.url(), amendOptionsParams(DefaultPaginationParams, options));
    }

    get(id: string): Promise<Integration> {
        return this.client.get(this.url(id));
    }

    save(integration: Integration): Promise<Integration> {

        if (integration.id) {
            return this.client.put(this.url(integration.id), integration);
        }

        return this.client.post(this.url(), { body: integration });
    }

    private url(...extra: string[]): string {
        const urlParts = ['integrations', ...extra];
        return this.client.buildUrl(urlParts);
    }
}
