<uf-panel class="container">

    <div class="header-pane uf-app-bar transparent">
        <uc-builder-header>
        </uc-builder-header>
    </div>

    <div class="content-pane">
        <uf-tabs>
            <uf-tab label="Table Configuration" path=""></uf-tab>
            <uf-tab *ngIf="info.table.id != null" label="Details Page" path="details-page"></uf-tab>
        </uf-tabs>
    </div>

</uf-panel>