<div class="row">
    <uf-message *ngIf="noParentSchemaMessage; else ready" class="grow x-small warning" icon="warningSolid">
        <p>{{noParentSchemaMessage}}</p>
    </uf-message>
    <a (click)="onRemove($event)" title="Delete" class="uf-action tertiary">
        <uf-icon name="delete"></uf-icon>
    </a>
</div>

<ng-template #ready>
    <div [formGroup]="control" class="uf-grid grow pad">
        <uf-text label="Label" [formControlName]="targetKeys.Label" class="col-6">
        </uf-text>
        <uf-autocomplete label="Form" placeholder="Form" [formControlName]="targetKeys.Form"
            (valueChange)="onTargetFormChange()" (searchChange)="findForms($event)" [options]="formsResult"
            nameProperty="name" class="col-6">
        </uf-autocomplete>
        <uf-select *ngIf="control.get(targetKeys.Form)?.value != null" label="Transition"
            placeholder="Select a transition" [formControlName]="targetKeys.Transition" [options]="transitionsResult"
            [nameProperty]="transitionLabel" class="col-12">
        </uf-select>
        <uf-textarea *ngIf="showCondition" label="Condition" [formControlName]="targetKeys.Condition" class="col-12">
        </uf-textarea>
        <div class="col-12" *ngIf="fieldMappings.length">
            <h4>Data Mapping</h4>
            <table class="uf-table inputs accent clickable">
                <thead>
                    <tr>
                        <th>Field in Primary Form</th>
                        <th>Field in Triggered Form</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let fieldMap of fieldMappings.controls | asUfControlsGroups; index as i"
                        (click)="mapFields(fieldMap)" class="body-row">
                        <td>{{ fieldMap.get(fieldMappingKeys.ParentField)?.value
                            | nameProperty:namePropertyFunc }} <span
                                *ngIf="fieldMap.get(fieldMappingKeys.ParentField)?.invalid"
                                class="uf-lozenge error">Invalid</span>
                        </td>
                        <td>{{ fieldMap.get(fieldMappingKeys.ChildField)?.value
                            | nameProperty:namePropertyFunc }} <span
                                *ngIf="fieldMap.get(fieldMappingKeys.ChildField)?.invalid"
                                class="uf-lozenge error">Invalid</span>
                        </td>
                        <td class="input-cell">
                            <button (click)="removeMappedFields(i, $event)" title="Delete"
                                class="uf-action tertiary right gap-sm-right">
                                <uf-icon name="delete"></uf-icon>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div *ngIf="showFieldMappings" class="col-12">
            <button class="uf-button right" (click)="mapFields()">
                Add Data
            </button>
        </div>
    </div>
</ng-template>