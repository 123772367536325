import { Component, EventEmitter, Input, OnChanges, Optional, Output, SimpleChanges } from '@angular/core';
import { DataPropertyDescriptor, ExpandersService, UfControlGroup } from '@unifii/library/common';
import { FieldType, VisibleFilterDescriptor } from '@unifii/sdk';

import { FilterInfo } from 'components';
import { DataSourceEditorComponent } from 'components/field-builder/data-source-editor';

import { allowsLabelOverride } from 'helpers/data-descriptor-helper';

import { TableComponent } from 'pages/tables/table.component';


@Component({
    selector: 'uc-visible-filters',
    templateUrl: 'visible-filters.html',
    providers: [ExpandersService]
})
export class VisibleFiltersComponent implements OnChanges {

    @Input() parentControl: UfControlGroup;
    @Input() availableFilters: DataPropertyDescriptor[];
    @Input() filters: VisibleFilterDescriptor[] | undefined;
    @Output() filtersChange: EventEmitter<VisibleFilterDescriptor[] | undefined> = new EventEmitter();

    protected filterResults: DataPropertyDescriptor[];
    protected items: FilterInfo[];

    private _items: VisibleFilterDescriptor[] = [];

    constructor(
        @Optional() private tableComponent?: TableComponent,
        @Optional() private dataSourceEditor?: DataSourceEditorComponent
    ) { }

    // determine if component need to render as card or flat
    get showAsCard(): boolean {
        return !!this.tableComponent;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.filters?.currentValue === this._items) {
            return;
        }
        this.items = (this.filters || []).map(f => this.getFilterInfo(f));
    }

    addFilter(filter: VisibleFilterDescriptor) {
        this.items.push(this.getFilterInfo(filter));
        this.emit();
    }

    removeFilter(index: number) {
        this.items.splice(index, 1);
        this.emit();
    }

    async filterFilters(q?: string) {
        this.filterResults = this.availableFilters.filter(descriptor => {
            let accepted = true;
            // Apply search
            if (q && q.trim().length) {
                accepted = (descriptor.display ?? '').toLowerCase().indexOf(q.toLowerCase()) > -1;
            }
            // Apply excluded
            if (accepted) {
                accepted = !this.items.find(i => i.filter.identifier === descriptor.identifier);
            }
            return accepted;
        });
    }

    emit() {
        this.syncLabels();
        this._items = this.items.map(i => i.filter);
        this.filtersChange.emit(this._items);
    }

    private getFilterInfo(filter: VisibleFilterDescriptor): FilterInfo {

        const dataPropertyDescriptor = this.availableFilters.find(f => f.identifier === filter.identifier);

        const dataDescriptorType =
            this.tableComponent?.info.dataDescriptor.type ||
            this.dataSourceEditor?.status.descriptor?.type;

        const hasConfiguration = (dataPropertyDescriptor?.type && [FieldType.DateTime, FieldType.ZonedDateTime].includes(dataPropertyDescriptor.type))
            || dataPropertyDescriptor?.icon === 'user'
            || allowsLabelOverride(dataDescriptorType, dataPropertyDescriptor);

        return {
            filter,
            dataDescriptorType,
            dataPropertyDescriptor,
            icon: dataPropertyDescriptor?.icon ?? 'query',
            label: this.getDisplayLabelValue(filter, dataPropertyDescriptor),
            hasConfiguration
        };
    }

    private syncLabels() {
        for (const item of this.items) {
            item.label = this.getDisplayLabelValue(item.filter, item.dataPropertyDescriptor);
        }
    }

    private getDisplayLabelValue(filter: VisibleFilterDescriptor, dataPropertyDescriptor?: DataPropertyDescriptor): string {
        if (filter.label) {
            return `${filter.label} (${filter.identifier})`;
        }

        return dataPropertyDescriptor?.display ?? filter.identifier;
    }
}