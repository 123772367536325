import { Injectable, Type } from '@angular/core';
import { ComponentRegistry, ComponentRegistryType } from '@unifii/library/smart-forms';
import { FieldType } from '@unifii/sdk';

import { ContentEditorRegistry } from './content-editor-registry';
import { UcMarkdownWrapperComponent } from './form/_index';


// Colletion Item Builder
@Injectable()
export class MarkdownEditorRegistry extends ContentEditorRegistry implements ComponentRegistry {

    type = ComponentRegistryType.Input;

    get(type: FieldType): Type<any> {

        if (type === FieldType.MultiText) {
            return UcMarkdownWrapperComponent;
        }

        return super.get(type);
    }
}
