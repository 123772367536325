<div *ngIf="loaded">
        <span>minWidth: {{c.cropper.minWidth}} minHeight: {{c.cropper.minHeight}} ratio:{{c.cropper.ratio}}</span><br>
        <span>{{c.cropper.measures | json}}</span>
        <span>Image Cropper Test {{c.cropper.overlay | json}}</span>
        <span *ngIf="c.cropper.overlay"> ratio > {{c.cropper.overlay.height/c.cropper.overlay.width}}</span>
</div>


<button (click)="removeCrop($event)">Remove Crop</button>
<div style="padding: 20px;"></div>
<input type="text" [(ngModel)]="scale">
<h2 *ngIf="!enabled">Crop disabled</h2>
<div style="padding: 20px;"></div>
<uc-image-cropper [src]="imageURL" [minWidth]="minWidth" [minHeight]="minHeight" [enabled]="enabled" [(value)]="value"
        [size]="size">
</uc-image-cropper>