import { Pipe, PipeTransform } from '@angular/core';


@Pipe({ name: 'fileType' })
export class FileTypePipe implements PipeTransform {

  transform(value: string): any {
    if (value) {
      const dotsParts = value.split('.');
      return dotsParts[dotsParts.length - 1];
    }
    return '';
  }
}
