<div class="uf-app-bar ">
    <button type="button" class="uf-action tertiary" (click)="runtime.close()" title="Close">
        <uf-icon name="close"></uf-icon>
    </button>
    <h3>Select Asset</h3>
</div>

<uf-panel class="grow">
    <div class="uf-grid pad">

        <uc-asset class="col-12" [(value)]="asset" [control]="assetControl" [type]="type" [autofocus]="true">
        </uc-asset>

        <ng-container *ngIf="asset && !hasData && asset.type === mediaType.Image">

            <div class="col-12 uf-grid">
                <img [src]="asset.url" alt="" class="thumb col">
                <uf-description-list class="col-10 row-gap-sm">
                    <dt>Title</dt>
                    <dd>{{asset.title}}</dd>
                    <dt>Description</dt>
                    <dd>{{asset.description}}</dd>
                    <dt>Original Size</dt>
                    <dd>{{asset.width}}x{{asset.height}}</dd>
                </uf-description-list>
            </div>

            <uf-select class="col-4" label="Alignment" [options]="alignmentOptions" nameProperty="name"
                valueProperty="identifier" [(value)]="alignment" (valueChange)="updateAlignment()">
            </uf-select>
            <uf-select class="col-4" label="Image Width" [options]="imageSizeOptions" nameProperty="name"
                valueProperty="identifier" [(value)]="imageSize" (valueChange)="directive = updateDirective(true)">
            </uf-select>
            <uf-select class="col-4" label="Aspect Ratio" [options]="radioOptions" [(value)]="ratio" nameProperty="name"
                valueProperty="value" (valueChange)="directive = updateDirective(true)"></uf-select>
            <div class="col-12">
                <button type="button" class="uf-button secondary" (click)="cropImage()"
                    [disabled]="!ratio">Crop</button>
            </div>
        </ng-container>

        <ng-container *ngIf="asset">
            <div *ngIf="directive" class="col-12 uf-box body-copy flat pad-sides" [markdownRenderer]="directive"></div>
        </ng-container>
    </div>
</uf-panel>

<div class="row space-children pad">
    <button type="button" class="uf-button tertiary right" (click)="runtime.close()">Close</button>
    <button type="button" class="uf-button primary" (click)="add()">Add</button>
</div>