import { Subscription } from 'rxjs';

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DescriptionListItem, UfControlGroup } from '@unifii/library/common';
import { AuthProvider } from '@unifii/sdk';

import { AuthProviderDetails } from 'client';

import { DetailsControlKeys } from '../models';


@Component({
    selector: 'uc-auth-provider-detail',
    templateUrl: './auth-provider-detail.html'
})
export class AuthProviderDetailComponent implements OnInit, OnDestroy {

    @Input() form: UfControlGroup;

    @Output() deactivateClicked = new EventEmitter<void>();
    @Output() activateClicked = new EventEmitter<void>();
    @Output() deleteClicked = new EventEmitter<void>();

    protected readonly controlKeys = DetailsControlKeys;

    protected showAuthorizationServerId: boolean;
    protected showManagementApiAudience: boolean;
    protected showSswsSecret: boolean;
    protected showClientSecret: boolean;
    protected showScimToken: boolean;
    protected showProviderLoginLabel: boolean;
    protected extrasControl: UfControlGroup;
    protected isNew: boolean;
    protected edit: boolean;
    protected descriptionListItem?: DescriptionListItem[];

    private type: AuthProvider;
    private previousValue: AuthProviderDetails;
    private subscriptions = new Subscription();

    ngOnInit() {
        const isActiveControl = this.form.get(DetailsControlKeys.IsActive);
        this.subscriptions.add(isActiveControl?.valueChanges.subscribe(_ => this.buildDescriptionList()));

        this.setup();
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    protected doneEditing() {
        this.form.setSubmitted();

        if (this.form.invalid) {
            return;
        }

        this.buildDescriptionList();
        this.edit = false;
    }

    protected editDetails() {
        this.previousValue = this.form.getRawValue();
        this.edit = !this.edit;
    }

    protected cancelEdit() {
        if (!this.form.pristine) {
            this.form.reset(this.previousValue, { emitEvent: false });
        }
        this.edit = false;
    }

    private setup() {
        this.type = this.form.get(DetailsControlKeys.Type)?.value;
        this.isNew = !this.form.get(DetailsControlKeys.Id)?.value;
        this.showManagementApiAudience = this.type === AuthProvider.Auth0;
        this.showSswsSecret = this.type === AuthProvider.Okta;
        this.showClientSecret = !(this.type === AuthProvider.Azure && this.isAutomatic());
        this.showProviderLoginLabel = this.type !== AuthProvider.Auth0;
        this.showAuthorizationServerId = this.type === AuthProvider.Okta;
        this.showScimToken = this.type !== AuthProvider.Auth0;

        this.extrasControl = this.form.get(DetailsControlKeys.Extras) as UfControlGroup;

        this.buildDescriptionList();
    }

    private buildDescriptionList() {
        const isActive = this.form.get(DetailsControlKeys.IsActive)?.value;
        this.descriptionListItem = [
            { term: 'Tenant Name', description: this.form.get(DetailsControlKeys.Tenant)?.value },
            { term: 'Client ID', description: this.form.get(DetailsControlKeys.ClientId)?.value },
            { term: 'Registration Type', description: `${this.getRegistrationType()} - ${this.type}`, },
            { term: 'Status', description: { description: isActive ? 'Active' : 'Inactive', status: isActive ? 'success' : 'error' } },
        ];

        if (this.showScimToken && this.form.get(DetailsControlKeys.ScimToken)?.value) {
            this.descriptionListItem.push({ term: 'Scim Token', description: 'Enabled' });
        }

        const extrasControl = this.form.get(DetailsControlKeys.Extras) as UfControlGroup;
        if (this.type === AuthProvider.Auth0) {
            this.descriptionListItem.push({ term: 'Management API Audience', description: extrasControl.get(DetailsControlKeys.Audience)?.value });
            return;
        }

        this.descriptionListItem.push({ term: 'Provider Login Label', description: this.form.get(DetailsControlKeys.ProviderLoginLabel)?.value });

        if (this.type === AuthProvider.Okta) {
            this.descriptionListItem.push({ term: 'Authorization Server ID', description: extrasControl.get(DetailsControlKeys.AuthorizationServer)?.value });
        }
    }

    private getRegistrationType() {
        return this.isAutomatic() ? 'Automatic' : 'Manual';
    }

    private isAutomatic() {
        return !this.form.get(DetailsControlKeys.Manual)?.value;
    }
}