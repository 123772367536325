import { Component, HostBinding } from '@angular/core';
import { TableConfig } from '@unifii/library/common';
import { Device } from '@unifii/sdk';

import { UcUsers } from 'client';

import { UserDevicesDataSource } from 'pages/users/user-devices-datasource';
import { UserComponent } from 'pages/users/user.component';


@Component({
    templateUrl: 'user-devices.html',
    styleUrls: ['user-devices.less', './../../styles/pages/table.less'],
})
export class UserDevicesComponent {

    @HostBinding('class.stretch-component') class = true;

    config: TableConfig<Device>;
    datasource: UserDevicesDataSource;

    constructor(
        private ucUsers: UcUsers,
        private parent: UserComponent
    ) {
        this.init();
    }

    private init() {
        this.config = {
            id: 'user-devices',
            columnToggles: true,
            pageSize: 50,
            columns: [{
                name: 'id',
                label: 'Id'
            }, {
                name: 'name',
                label: 'Name'
            }, {
                name: 'os',
                label: 'OS'
            }, {
                name: 'osVersion',
                label: 'OS Version'
            }, {
                name: 'manufacturer',
                label: 'Manufacturer'
            }, {
                name: 'model',
                label: 'Model'
            }]
        };

        this.datasource = new UserDevicesDataSource(this.ucUsers, this.parent.id);
    }
}
