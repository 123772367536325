<ng-container *ngIf="control">

    <!-- Home -->
    <div *ngIf="isHome" class="uf-app-bar flat clickable" [class.accent]="isSelected" (click)="select($event)">
        <uf-icon [name]="icon"></uf-icon>
        <div *ngIf="isHomeEmpty; else homeConfigured" class="title primary">Select a home page</div>
        <ng-template #homeConfigured>
            <div class="title primary">{{ node.name || 'Home' }}</div>
            <uf-icon *ngIf="control.invalid" name="error" class="error" title="This node configuration is invalid">
            </uf-icon>
            <uf-icon *ngIf="!isPublished" name="unpublished" class="icon-warning" [title]="notPublishedMessage">
            </uf-icon>
            <uf-icon *ngIf="isAccessRestricted" name="lock" class="icon-warning" title="This node access is restricted">
            </uf-icon>
            <uf-icon *ngIf="hasVariations" name="variation" title="This node has variations"></uf-icon>
        </ng-template>
        <button class="uf-action" [class.tertiary]="!isSelected" [class.primary]="isSelected" (click)="expand($event)"
            title="Expand All">
            <uf-icon name="arrowDown"></uf-icon>
        </button>
        <button class="uf-action" [class.tertiary]="!isSelected" [class.primary]="isSelected" (click)="collapse($event)"
            title="Collapse All">
            <uf-icon name="arrowUp"></uf-icon>
        </button>
        <button type="button" *ngIf="!isHomeEmpty && !control.disabled" class="uf-action" (click)="remove($event)"
            title="Delete">
            <uf-icon name="delete"></uf-icon>
        </button>
    </div>

    <!-- Leaf node -->
    <div *ngIf="!isHome && children.length == 0" class="uf-app-bar flat draggable gap-sm-bottom"
        [class.uf-box]="!isSelected" [class.accent]="isSelected" (click)="select($event)">
        <uf-icon [name]="icon"></uf-icon>
        <div class="title primary">{{node.name}}</div>
        <uf-icon *ngIf="control.invalid" name="error" class="error" title="This node configuration is invalid">
        </uf-icon>
        <uf-icon *ngIf="!isPublished" name="unpublished" class="icon-warning margin--horizontal"
            [title]="notPublishedMessage">
        </uf-icon>
        <uf-icon *ngIf="isAccessRestricted" name="lock" class="icon-warning" title="This node access is restricted">
        </uf-icon>
        <!--
        <a restrict="SuperUser" *ngIf="!control.disabled && !isParentHidden" (click)="toggleHidden($event)"
            [title]="showAsHidden ? 'Hidden' : 'Visible'" class="uf-action">
            <uf-icon [name]="showAsHidden ? 'viewHidden' : 'view'"></uf-icon>
        </a>
        <uf-icon restrict="SuperUser" *ngIf="control.disabled || isParentHidden"
            [name]="showAsHidden ? 'viewHidden' : 'view'" [title]="showAsHidden ? 'Hidden' : 'Visible'">
        </uf-icon>
        -->
        <button type="button" *ngIf="!control.disabled" class="uf-action" (click)="remove($event)" title="Delete">
            <uf-icon name="delete"></uf-icon>
        </button>
    </div>

    <!-- Parent node -->
    <uf-expander *ngIf="!isHome && children.length > 0" class="uf-box flat draggable gap-sm-bottom">
        <div expanderHeader class="uf-app-bar flat draggable" [class.accent]="isSelected" (click)="select($event)">
            <uf-icon [name]="icon"></uf-icon>
            <div class="title primary">{{node.name}}</div>
            <uf-icon *ngIf="control.invalid" name="error" class="error" title="This node configuration is invalid">
            </uf-icon>
            <uf-icon *ngIf="!isPublished" name="unpublished" class="icon-warning margin--horizontal"
                [title]="notPublishedMessage">
            </uf-icon>
            <uf-icon *ngIf="isAccessRestricted" name="lock" class="icon-warning"></uf-icon>
            <!--
            <a restrict="SuperUser" *ngIf="!control.disabled && !isParentHidden" (click)="toggleHidden($event)"
                [title]="showAsHidden ? 'Hidden' : 'Visible'" class="uf-action">
                <uf-icon [name]="showAsHidden ? 'viewHidden' : 'view'"></uf-icon>
            </a>
            <uf-icon restrict="SuperUser" *ngIf="control.disabled || isParentHidden"
                [name]="showAsHidden ? 'viewHidden' : 'view'" [title]="showAsHidden ? 'Hidden' : 'Visible'">
            </uf-icon>
            -->
            <button *ngIf="!control.disabled" (click)="remove($event)" tilte="Delete" type="button" class="uf-action">
                <uf-icon name="delete"></uf-icon>
            </button>
        </div>
        <uc-drag-list expanderBody [parent]="children" [items]="children.controls" [canDrop]="service.canDrop"
            [convertAdded]="service.addConverter" [drop]="service.drop" class="gap-left gap-sm-top">
            <uc-node-field *ngFor="let child of children.controls | asUfControlsGroups" class="gap-sm-bottom" dragItem
                [dragDisabled]="child.disabled" [nestable]="true" [control]="child" [isParentHidden]="showAsHidden">
            </uc-node-field>
        </uc-drag-list>
    </uf-expander>

</ng-container>