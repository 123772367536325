<ng-template [ngIf]="ready">
    <uf-expander class="uf-box flat pad-sm-sides uf-grid row-gap-sm pad-sm-top pad-sm-bottom">
        <div expanderHeader class="uf-app-bar flat">
            <div class="title primary">Settings</div>
            <uf-icon *ngIf="isInvalid" name="error" class="error"></uf-icon>
        </div>
        <div expanderBody [formGroup]="control" class="uc-group-content grid">

            <uf-text label="Label" [formControlName]="variationKeys.Label" class="col-1of1">
            </uf-text>

            <uc-markdown label="Help text" [formControlName]="variationKeys.Help" [showAlignmentOptions]="true"
                class="col-1of1">
            </uc-markdown>

            <uf-text label="Placeholder" [formControlName]="variationKeys.Placeholder" class="col-1of1">
            </uf-text>

        </div>
    </uf-expander>

    <uc-form-field-options [type]="type" [meta]="meta" [options]="options" [dataSourceConfig]="dataSourceConfig"
        [template]="template" [id]="id" [identifier]="identifier" [dataCaptures]="dataCaptures"
        [isReportable]="isReportable" class="col-1of1">
    </uc-form-field-options>

    <uc-form-field-validators [validators]="validators" [type]="type"></uc-form-field-validators>

</ng-template>