<uf-panel *ngIf="ready" class="content-pane container">
    <div class="uf-container-lg">
        <div class="uf-grid row-gap-lg gap-top">
            <!-- Type block -->
            <div class="uf-box col-12">
                <div class="uf-app-bar flat accent">
                    <div class="title">
                        Details Page
                    </div>
                </div>
                <div class="uc-group-content grid">
                    <!-- // TODO UNIFII-4422 - Remove conditional disabled -->
                    <uf-select class="col-1of1" label="Type" [(value)]="tableDetailType"
                        (valueChange)="toggleTableDetailType()" [options]="tableDetailTypeOptions"
                        valueProperty="value">
                    </uf-select>
                    <uf-description-list class="col-1of1" *ngIf="table.sourceType === sourceTypes.Bucket">
                        <dt>Form Data Repository:</dt>
                        <dd>{{table.source}}</dd>
                    </uf-description-list>
                    <uf-text class="col-1of1" *ngIf="tableDetail" label="Title" [(value)]="tableDetail.title"
                        [control]="cRoot.controls.label | asUfControl">
                    </uf-text>
                </div>
            </div>
            <ng-template [ngIf]="tableDetail">
                <!-- Data block -->
                <uf-expander class="uf-box col-12 gap-top-sm">
                    <div expanderHeader class="uf-app-bar flat accent">
                        <div *ngIf="table.sourceType === sourceTypes.Bucket" class="title">
                            Form Data
                        </div>
                        <div *ngIf="table.sourceType === sourceTypes.Users" class="title">
                            User Data
                        </div>
                        <div *ngIf="table.sourceType === sourceTypes.Company" class="title">
                            Company Data
                        </div>
                    </div>
                    <div expanderBody class="uf-grid pad">
                        <uc-drag-list *ngIf="fields.length" [items]="fields" canDrop="false" canReorder="true"
                            (moved)="edited = true" class="col-12">
                            <ng-template ngFor let-field [ngForOf]="fields" let-i="index">
                                <ng-template [ngIf]="field._overrideLabel || field.descriptor.type === 'Heading'"
                                    [ngIfElse]="noexpander">
                                    <uf-expander [isExpanded]="false" dragItem class="uf-box draggable flat gap-sm-top">
                                        <div class="uf-app-bar" expanderHeader dragHandle>
                                            <uf-icon [name]="field._icon"></uf-icon>
                                            <div class="grow title primary">{{field._label}}
                                            </div>
                                            <span *ngIf="!field._exists" class="uf-lozenge error">Missing</span>
                                            <button class="uf-action tertiary" (click)="removeField(i)" title="Delete">
                                                <uf-icon name="delete"></uf-icon>
                                            </button>
                                        </div>
                                        <ng-container expanderBody>
                                            <div class="pad uf-grid">
                                                <uf-text
                                                    *ngIf="field._overrideLabel && field.descriptor.type === 'Field'"
                                                    [(value)]="field.descriptor.label" (valueChange)="onFieldChange()"
                                                    label="Label" placeholder="Override default label" class="col-12">
                                                </uf-text>
                                                <uf-text *ngIf="field.descriptor.type === 'Heading'"
                                                    [(value)]="field.descriptor.value" (valueChange)="onFieldChange()"
                                                    label="Value" class="col-12">
                                                </uf-text>
                                            </div>
                                        </ng-container>
                                    </uf-expander>
                                </ng-template>
                                <ng-template #noexpander>
                                    <div dragItem class="uf-box draggable flat gap-sm-top">
                                        <div class="uf-app-bar" dragHandle>
                                            <uf-icon [name]="field._icon"></uf-icon>
                                            <div class="grow title primary">{{field._label}}
                                            </div>
                                            <span *ngIf="!field._exists" class="uf-lozenge error">Missing</span>
                                            <button class="uf-action tertiary" (click)="removeField(i)" title="Delete">
                                                <uf-icon name="delete"></uf-icon>
                                            </button>
                                        </div>
                                    </div>
                                </ng-template>

                            </ng-template>
                        </uc-drag-list>

                        <uf-search-result class="col-12" label="Fields" (valueChange)="addField($event.identifier)"
                            (searchChange)="filterFields($event)" [options]="visibleFields" placeholder="Search field"
                            nameProperty="display">
                        </uf-search-result>

                        <div class="col-12 gap-top">
                            <button class="uf-button right" type="button" (click)="addField()">
                                Add Heading
                            </button>
                        </div>
                    </div>
                </uf-expander>

                <div class="uf-box col-12">

                    <div class="uf-app-bar flat accent">
                        <div class="title">Modules</div>
                        <uc-expander-controls *ngIf="tableDetail.modules.length" [container]="modulesWrap">
                        </uc-expander-controls>
                    </div>

                    <div #modulesWrap class="uc-group-content pad-sides pad-bottom pad-sm-top">
                        <uc-drag-list [items]="tableDetail.modules" canDrop="false" canReorder="true"
                            (moved)="moveModule($event)">
                            <ng-template ngFor let-module [ngForOf]="tableDetail.modules" let-i="index">
                                <uf-expander class="uf-box draggable flat gap-sm-top" [isExpanded]="false" dragItem>
                                    <div class="uf-app-bar" expanderHeader dragHandle>
                                        <div class="grow title primary">
                                            {{module.type}} - {{module.identifier}}
                                            <uf-icon *ngIf="cModules.at(i).invalid" name="error" class="error"
                                                title="This module is invalid"></uf-icon>
                                        </div>
                                        <button type="button" (click)="removeModule(i)" title="Delete"
                                            class="uf-action tertiary">
                                            <uf-icon name="delete"></uf-icon>
                                        </button>
                                    </div>
                                    <ng-container expanderBody>
                                        <div class="pad">
                                            <uc-table-module-table *ngIf="module.type === moduleTypes.Table"
                                                [module]="module" [parentTable]="table"
                                                [parentControl]="cModules.at(i) | asUfControlGroup">
                                            </uc-table-module-table>
                                        </div>
                                    </ng-container>
                                </uf-expander>
                            </ng-template>
                        </uc-drag-list>
                        <div class="col-12 gap-top">
                            <button class="uf-button right" type="button" (click)="addModule()">
                                Add Module
                            </button>
                        </div>
                    </div>
                </div>

            </ng-template>
        </div>
    </div>
</uf-panel>