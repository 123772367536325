import { Subscription } from 'rxjs';

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DataDescriptor, DataPropertyDescriptor, UfControlGroup, UfFormBuilder, ValidatorFunctions } from '@unifii/library/common';
import { AstNode, DataSourceType, NodeType, QueryOperators, TableDetailModule, TableSourceType } from '@unifii/sdk';

import { UcProject, UcRoles, UcTable } from 'client';

import { TableEditorService } from '../table-editor.service';


export enum TableModuleControlKeys {
    Title = 'title',
    Limit = 'limit',
    Roles = 'roles',
    CanAdd = 'canAdd'
}

@Component({
    selector: 'uc-table-module-table',
    templateUrl: 'table-module-table.html'
})
export class TableModuleTableComponent implements OnInit, OnDestroy {

    @Input() parentControl: UfControlGroup;
    @Input() parentTable: UcTable;
    @Input() module: TableDetailModule;
    @Output() moduleChange: EventEmitter<TableDetailModule> = new EventEmitter();

    readonly mapTableToDataSource = new Map([
        [TableSourceType.Bucket, DataSourceType.Bucket],
        [TableSourceType.Company, DataSourceType.Company],
        [TableSourceType.Users, DataSourceType.Users]
    ]);

    readonly controlKeys = TableModuleControlKeys;

    error: boolean;
    ready: boolean;
    table: UcTable;
    tableLink: any[];
    showCanAdd: boolean;
    rolesResult: string[];
    staticFilters: DataPropertyDescriptor[] = [];

    private subscriptions = new Subscription();

    constructor(
        private fb: UfFormBuilder,
        private ucProject: UcProject,
        private ucRoles: UcRoles,
        private tableEditorService: TableEditorService
    ) { }

    async ngOnInit() {

        try {
            this.table = await this.ucProject.getTable(this.module.identifier);
            this.tableLink = ['..', '..', this.table.id];
            const dataDescriptor = await this.getTableEditorConfig(this.table);

            if (dataDescriptor) {
                this.staticFilters = dataDescriptor.propertyDescriptors.filter(pd => pd.asStaticFilter) ?? [];
            }

            if (!this.module.limit) {
                this.module.limit = 5;
            }

            this.showCanAdd = this.table.sourceType === TableSourceType.Bucket;

            this.parentControl.addControl(TableModuleControlKeys.Title, this.fb.control(this.module.title));
            this.parentControl.addControl(TableModuleControlKeys.Limit, this.fb.control(this.module.limit, ValidatorFunctions.custom(v => v >= 1 && v <= 100, 'Between 1 and 100 rows')));
            this.parentControl.addControl(TableModuleControlKeys.Roles, this.fb.control(this.module.roles));
            this.parentControl.addControl(TableModuleControlKeys.CanAdd, this.fb.control(this.module.canAdd, ValidatorFunctions.custom(v => {
                if (!v) {
                    return true;
                }

                return this.hasEqualToDetailId(this.module.filter);

            }, 'The filter is missing the $detail.id condition')));

            this.ready = true;
        } catch (e) {
            console.error(e);
            this.parentControl.addControl('TableModuleDetailError', this.fb.control(null, ValidatorFunctions.custom(() => false, 'Table not found')));
            this.error = true;
        }

        this.subscriptions.add(this.parentControl.valueChanges.subscribe(() => {
            this.module = Object.assign(this.module, this.parentControl.getRawValue());
            this.moduleChange.emit(this.module);
        }));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    async findRoles(query?: string) {
        this.rolesResult = (await this.ucRoles.get(query)).map(r => r.name);
    }

    protected onFilterChange() {
        const addControl = this.parentControl.get(TableModuleControlKeys.CanAdd);
        if (!addControl) {
            return;
        }
        addControl.updateValueAndValidity();
        addControl.markAsTouched();
    }

    private hasEqualToDetailId(filter?: AstNode): boolean {
        if (!filter?.args) {
            return false;
        }

        const isOperator = filter.type === NodeType.Operator;
        const isEqual = filter.op === QueryOperators.Equal;
        const hasDetailIdValue = filter.args.find(arg => arg.type === NodeType.Expression && arg.value === '$detail.id') != null;

        if (isOperator && isEqual && hasDetailIdValue) {
            return true;
        }

        return filter.args.some(arg => this.hasEqualToDetailId(arg));
    }

    private async getTableEditorConfig(table: UcTable): Promise<DataDescriptor | undefined> {
        switch (table.sourceType) {
            case TableSourceType.Bucket:
                return this.tableEditorService.getBucketDataDescriptor(table.source as string);
            case TableSourceType.Users:
                return this.tableEditorService.getUserDataDescriptor();
            case TableSourceType.Company:
                return this.tableEditorService.getCompanyDataDescriptor();
        }
    }
}