import { Component, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import { ModalService, WindowWrapper } from '@unifii/library/common';
import { AuthProvider, TenantClient } from '@unifii/sdk';

import { UcAuthProviders } from 'client';

import { TypeSelectComponent, TypeSelectConfig, TypeSelectOption } from 'components/common/type-select.component';

import { AuthProvidersTableManager } from './auth-providers-table-manager';
import { AuthProviderTypeDescription } from './models';


@Component({
    template: `
    <uf-table-container ufSyncRoute ufMasterDetail (addItem)="selectProvider()" class="cards container">
    </uf-table-container>
    <router-outlet></router-outlet>
`,
    providers: [
        { provide: TableContainerManager, useClass: AuthProvidersTableManager }
    ]
})
export class AuthProvidersComponent {

    private readonly azureBaseUrl = 'https://login.microsoftonline.com/';

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private modalService: ModalService,
        private ucAuthProviders: UcAuthProviders,
        private tenantClient: TenantClient,
        @Inject(WindowWrapper) private window: Window,
        @Inject(TableContainerManager) private manager: AuthProvidersTableManager
    ) { }

    refresh() {
        this.manager.reload.next();
    }

    protected async selectProvider() {

        const config: TypeSelectConfig = {
            title: 'Select SSO Provider',
            types: [
                {
                    type: AuthProvider.Azure,
                    label: AuthProviderTypeDescription.Azure,
                    logo: `assets/svg/${AuthProvider.Azure.toLowerCase()}.svg`,
                    manualRegistration: false
                },
                {
                    type: AuthProvider.Azure,
                    label: `${AuthProviderTypeDescription.Azure} - Manual Registration`,
                    logo: `assets/svg/${AuthProvider.Azure.toLowerCase()}.svg`
                },
                {
                    type: AuthProvider.Auth0,
                    label: AuthProviderTypeDescription.Auth0,
                    logo: `assets/svg/${AuthProvider.Auth0.toLowerCase()}.svg`
                },
                {
                    type: AuthProvider.Okta,
                    label: AuthProviderTypeDescription.Okta,
                    logo: `assets/svg/${AuthProvider.Okta.toLowerCase()}.svg`
                }]
        };

        const provider = await this.modalService.openMedium<TypeSelectConfig, TypeSelectOption>(TypeSelectComponent, config);
        if (!provider) {
            return;
        }

        // if it's automatic registration
        if (provider.manualRegistration === false && provider.type === AuthProvider.Azure) {
            this.redirectToAzure();
            return;
        }

        this.router.navigate([provider.type.toLocaleLowerCase(), 'new'], { relativeTo: this.route });
    }

    private async redirectToAzure() {
        const microsoftConfig = await this.ucAuthProviders.getMicrosoftConfig();
        const redirectUrl = await this.getRedirectUrl();
        const url = `${this.azureBaseUrl}common/adminconsent?response_mode=form_post&client_id=${microsoftConfig.clientId}&redirect_uri=${redirectUrl}`;
        this.window.location.assign(url);
    }

    private async getRedirectUrl(): Promise<string> {
        const baseUrl = `${this.window.location.origin}/system-settings/sso/azure/automatic-registration`;
        const oidcState = await this.tenantClient.getOIDCState(baseUrl, {});
        return `https://directory.unifii.net/azure/callback&state=${oidcState.state}`;
    }

}
