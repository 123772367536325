<nav class="col center-all">
    <ng-container *ngIf="project && !context.language">
        <uc-menu-item *ngFor="let config of menuConfigs" [restrict]="config.restrict" [label]="config.label"
            [icon]="config.icon" [link]="config.link" [showLabel]="true">
        </uc-menu-item>
    </ng-container>
    <ng-container *ngIf="project && context.language">
        <uc-menu-item restrict="Translator,Publisher" label="Collections" icon="collections"
            [link]="['/assets/translations/projects', project.id, context.language.code, 'collections']">
        </uc-menu-item>
        <uc-menu-item restrict="Translator,Publisher" label="Views" icon="views"
            [link]="['/assets/translations/projects', project.id, context.language.code, 'views']">
        </uc-menu-item>
    </ng-container>

    <div class="grow"></div>

    <uf-description-list class="x-small stacked" [items]="{ version: config.version }">
    </uf-description-list>

</nav>