import { Component, HostBinding, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import { FileUploader } from '@unifii/library/common';
import { FileType } from '@unifii/sdk';

import { UcMedia } from 'client';
import { EditData } from 'components/common/edit-data';
import { ContextService } from 'services/context.service';
import { TenantSettingsService } from 'services/tenant-settings.service';
import { BreadcrumbService } from 'services/breadcrumb.service';

import { FileUploaderService } from '../../services/file-uploader.service';
import { MediaTableManager } from './media-table-manager';


@Component({
    templateUrl: './media-new.html',
    providers: [
        { provide: FileUploader, useClass: FileUploaderService }
    ],
    styleUrls: ['./media-new.less']
})
export class MediaNewComponent implements OnInit, EditData {

    @HostBinding('class.stretch-component')
    @HostBinding('class.media-new') class = true;

    assets: { id: number }[] = [];
    allowedAssets: FileType[] | undefined;
    edited: boolean;
    breadcrumbs = this.breadcrumbService.getBreadcrumbs(this.route, ['New']);

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private ucMedia: UcMedia,
        private context: ContextService,
        private tenantSettings: TenantSettingsService,
        private breadcrumbService: BreadcrumbService,
        @Inject(TableContainerManager) private tableManager: MediaTableManager
    ) { }

    async ngOnInit() {
        const settings = await this.tenantSettings.sync();
        this.allowedAssets = settings.allowedAssetFileTypes ?? undefined;
    }

    async removeUploaded() {
        // Remove uploads in progress
        this.edited = false;
        return Promise.all(this.assets.map(asset => this.ucMedia.delete(asset.id)));
    }

    onContentChange(value: any[]) {
        this.assets = value;
        this.edited = !!value.length;
    }

    back() {
        this.router.navigate(['../'], { relativeTo: this.route });
    }

    continue() {
        this.edited = false;
        this.router.navigate(['..', { pending: true }], { relativeTo: this.route });
        this.tableManager.reload.next();
    }

    get disabled(): boolean {
        return this.context.tenantSettings != null &&
            this.context.tenantSettings.allowedAssetFileTypes != null &&
            this.context.tenantSettings.allowedAssetFileTypes.length === 0;
    }

    async onLeavePage() {
        await this.removeUploaded();
    }
}
