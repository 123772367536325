import { Injectable } from '@angular/core';
import { amendOptionsParams, ClientDeleteOptions, ClientGetOptions, ClientPostOptions, ClientPutOptions } from '@unifii/sdk';

import { DefaultPaginationParams } from 'constant';

import { ContextService } from 'services/context.service';

import { ActivityType, NewWorkflowRule, WorkflowActivityInfo, WorkflowRule } from './models';
import { UcClient } from './uc-client';


export interface ActivitiesParams {
    type?: ActivityType;
    q?: string;
    offset?: number;
    limit?: number;
    sort?: string;
}

@Injectable()
export class UcWorkflow {

    private readonly id = 'default';

    constructor(
        private client: UcClient,
        private context: ContextService
    ) { }

    getRules(options?: ClientGetOptions): Promise<WorkflowRule[]> {
        return this.client.get(this.url('rules'), amendOptionsParams(DefaultPaginationParams, options));
    }

    addRule(rule: NewWorkflowRule, options?: ClientPostOptions): Promise<WorkflowRule> {
        return this.client.post(this.url('rules'), { ...options, body: rule });
    }

    updateRule(rule: WorkflowRule, options?: ClientPutOptions): Promise<WorkflowRule> {
        return this.client.put(this.url('rules', rule.id), rule, options);
    }

    getRule(id: string, options?: ClientGetOptions): Promise<WorkflowRule> {
        return this.client.get(this.url('rules', id), options);
    }

    deleteRule(id: string, options?: ClientDeleteOptions): Promise<void> {
        return this.client.delete(this.url('rules', id), options);
    }

    getActivities<T extends WorkflowActivityInfo>(options?: ClientGetOptions): Promise<T[]> {
        return this.client.get(this.url('activities'), amendOptionsParams(DefaultPaginationParams, options));
    }

    addActivity<T extends WorkflowActivityInfo>(activity: T, options?: ClientPostOptions): Promise<T> {
        return this.client.post(this.url('activities'), { ...options, body: activity });
    }

    updateActivity<T extends WorkflowActivityInfo>(activity: T, options?: ClientPutOptions): Promise<T> {
        return this.client.put(this.url('activities', activity.id.toString()), activity, options);
    }

    getActivity<T extends WorkflowActivityInfo>(id: string, options?: ClientGetOptions): Promise<T> {
        return this.client.get(this.url('activities', id), options);
    }

    deleteActivity(id: string, force?: boolean, options?: ClientDeleteOptions): Promise<void> {
        return this.client.delete(this.url('activities', id), amendOptionsParams({ force }, options));
    }

    private url(...extra: string[]): string {
        const projectId = this.context.project?.id;
        return this.client.buildUrl(['projects', projectId, 'workflows', this.id, ...extra]);
    }

}