import { Injectable } from '@angular/core';
import { UfControlGroup, UfFormBuilder, ValidatorFunctions } from '@unifii/library/common';
import { AuthProvider } from '@unifii/sdk';

import { AuthProviderDetails, AuthProviderExtras, NewAuthProviderDetails } from 'client';

import { DetailsControlKeys } from '../models';


@Injectable({
    providedIn: 'root'
})
export class AuthProviderDetailController {
    constructor(
        private ufb: UfFormBuilder
    ) { }

    buildRoot(provider: AuthProviderDetails | NewAuthProviderDetails): UfControlGroup {
        return this.ufb.group({
            [DetailsControlKeys.Id]: (provider as AuthProviderDetails)?.id ?? null,
            [DetailsControlKeys.Tenant]: [provider?.tenant, ValidatorFunctions.required('Tenant Name is required')],
            [DetailsControlKeys.ClientId]: [provider?.clientId, ValidatorFunctions.required('Client Id is required')],
            [DetailsControlKeys.ClientSecret]: provider?.clientSecret,
            [DetailsControlKeys.ScimToken]: provider?.scimToken,
            [DetailsControlKeys.Type]: provider.type,
            [DetailsControlKeys.Extras]: this.buildExtra(provider.type, provider?.extras),
            [DetailsControlKeys.Manual]: provider?.manual,
            [DetailsControlKeys.IsActive]: provider?.isActive,
            [DetailsControlKeys.ProviderLoginLabel]: provider?.providerLoginLabel,
            [DetailsControlKeys.RegistrationType]: provider?.extras?.manualRegistration,
        });
    }

    private buildExtra(type: AuthProvider, extras?: AuthProviderExtras): UfControlGroup {
        return this.ufb.group({
            [DetailsControlKeys.Audience]: extras?.audience,
            [DetailsControlKeys.ManualRegistration]: extras?.manualRegistration,
            [DetailsControlKeys.AuthorizationServer]: extras?.authorizationServer,
            [DetailsControlKeys.UseDirectory]: extras?.useDirectory,
            [DetailsControlKeys.SswsSecret]: [{ value: extras?.sswsSecret, disabled: type !== AuthProvider.Okta }],
        });
    }
}