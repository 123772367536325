<uf-panel class="container">

    <div class="header-pane uf-app-bar transparent">
        <uf-breadcrumbs [breadcrumbs]="[{ name: 'Lab'}]" class="large"></uf-breadcrumbs>
    </div>

    <div class=" content-pane">
        <uf-tabs>
            <uf-tab path="style-guide" label="Style Guide"></uf-tab>
            <uf-tab path="empty" label="Empty"></uf-tab>
            <uf-tab path="icons" label="Icons"></uf-tab>
            <uf-tab path="lists" label="Lists"></uf-tab>
            <uf-tab path="drag-list" label="Drag List"></uf-tab>
            <uf-tab path="reactive-forms" label="Reactive Forms"></uf-tab>
            <uf-tab path="template-form" label="Template Form"></uf-tab>
            <uf-tab path="template-builder" label="Template Builder"></uf-tab>
            <uf-tab path="template-table" label="Template Table"></uf-tab>
            <uf-tab path="template-tabs" label="Template Tabs"></uf-tab>
        </uf-tabs>
    </div>
</uf-panel>