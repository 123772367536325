import { Subscription } from 'rxjs';
import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { DescriptionListItem, ModalService, UfControl, UfControlArray, UfControlGroup, UfFormControl } from '@unifii/library/common';

import { Config } from 'app-config';
import { UcDefinition } from 'client';
import { ArrayHelper } from 'helpers/helpers';

import { FORM_EDITOR_CONSTANTS } from './form-editor-constants';
import { DefinitionControlKeys, DefinitionSettingsControlKeys, FieldControlKeys } from './form-editor-control-keys';
import { FormEditorStatus } from './form-editor-status';
import { FormMetadataModalComponent, FormMetadataModalData } from './form-metadata-modal.component';
import { FormEditorService } from './form-editor.service';
import { FormEditorField } from './form-editor-model';


@Component({
    selector: 'uc-form-metadata',
    templateUrl: './form-metadata.html'
})
export class FormMetadataComponent implements OnInit, OnDestroy {

    @Input() control: UfControlGroup;

    readonly definitionKeys = DefinitionControlKeys;
    readonly settingsKeys = DefinitionSettingsControlKeys;
    readonly identifierWarningLength = FORM_EDITOR_CONSTANTS.FIELD_IDENTIFIER_WARNING_LENGTH;
    readonly identifierMaxLength = this.status.identifiersMaxLength.definition;
    readonly reportableMetaFields = FORM_EDITOR_CONSTANTS.REPORTABLE_METADATA_FIELDS;

    formControls: UfFormControl[];
    formDataRepositoryControls: UfFormControl[];
    reportableMetaFieldsResults: string[];
    tagsResults: string[];
    formDataRepositoryInfo: DescriptionListItem[] | null;
    identifierSub: Subscription | undefined;
    showIdentifierWarning: boolean;
    showBucketWarning: boolean;

    constructor(
        private status: FormEditorStatus,
        private service: FormEditorService,
        private modalService: ModalService,
        @Inject(Config) public config: Config
    ) { }

    get isFormInvalid(): boolean {
        return this.formControls.find(c => c.invalid) != null;
    }

    get isFormDataRepositoryInvalid(): boolean {
        return this.formDataRepositoryControls.find(c => c.invalid) != null;
    }

    get identifierControl(): UfControl {
        return this.control.get(DefinitionControlKeys.Identifier) as UfControl;
    }

    get bucketControl(): UfControl {
        return this.control.get(DefinitionControlKeys.Bucket) as UfControl;
    }

    get fieldsControl(): UfControlArray {
        return this.control.get(FieldControlKeys.Fields) as UfControlArray;
    }

    ngOnInit() {
        this.formControls = FORM_EDITOR_CONSTANTS.SECTION_FORM
            .map(k => this.control.get(k) as UfFormControl)
            .filter(c => c != null);

        this.formDataRepositoryControls = FORM_EDITOR_CONSTANTS.SECTION_FORM_DATA_REPOSITORY
            .map(k => this.control.get(k) as UfFormControl)
            .filter(c => c != null);

        this.identifierSub = this.identifierControl.valueChanges.subscribe(this.checkIdentifierWarning.bind(this));
        this.checkIdentifierWarning();
        this.checkBucketWarning();
        this.updateFormDataRepositoryInfo();
    }

    ngOnDestroy() {
        this.identifierSub?.unsubscribe();
    }

    searchReportableMetaFields(query: string) {

        if (!query) {
            this.reportableMetaFieldsResults = [...this.reportableMetaFields];
            return;
        }

        this.reportableMetaFieldsResults = this.reportableMetaFields.filter(i =>
            i.toLocaleLowerCase().indexOf(query.toLowerCase()) > -1
        );
    }

    searchTags(q: string) {
        this.tagsResults = ArrayHelper.filterList([...this.status.tags], q);
    }

    copyFields() {
        this.service.copyFields(this.fieldsControl.getRawValue() as FormEditorField[]);
    }

    pasteFields() {
        this.service.pasteFields(this.control);
    }

    async editFormDataRepository() {

        const partialDefinition = {
            lastPublishedAt: this.control.get(DefinitionControlKeys.LastPublishedAt)?.value,
            bucket: this.control.get(DefinitionControlKeys.Bucket)?.value,
            hasRollingVersion: this.control.get(DefinitionControlKeys.HasRollingVersion)?.value
        } as any as UcDefinition;

        const result = await this.modalService.openMedium<FormMetadataModalData, UcDefinition>(FormMetadataModalComponent, { definition: partialDefinition });
        if (!result) {
            return;
        }

        this.control.get(DefinitionControlKeys.Bucket)?.setValue(result.bucket);
        this.control.get(DefinitionControlKeys.HasRollingVersion)?.setValue(result.hasRollingVersion);
        this.updateFormDataRepositoryInfo();
        this.checkBucketWarning();
    }

    private updateFormDataRepositoryInfo() {
        const hasRollingVersion: boolean = this.control.get(DefinitionControlKeys.HasRollingVersion)?.value ?? false;
        this.formDataRepositoryInfo = [];
        this.formDataRepositoryInfo.push({ term: 'Form Data Repository', description: this.control.get(DefinitionControlKeys.Bucket)?.value });
        this.formDataRepositoryInfo.push({ term: 'Has Rolling Version', description: hasRollingVersion ? 'true' : 'false' });
    }

    private checkIdentifierWarning() {
        this.showIdentifierWarning = (this.identifierControl.value ?? '').length > FORM_EDITOR_CONSTANTS.FIELD_IDENTIFIER_WARNING_LENGTH &&
            !this.identifierControl.showError;
    }

    private checkBucketWarning() {
        this.showBucketWarning = (this.bucketControl.value ?? '').length > FORM_EDITOR_CONSTANTS.FIELD_IDENTIFIER_WARNING_LENGTH &&
            !this.bucketControl.showError;
    }
}