import { Duration } from 'date-fns';
import { Subscription } from 'rxjs';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalService, Regexp, ToastService, UfControl, UfControlGroup, ValidatorFunctions } from '@unifii/library/common';
import { FileType, Language } from '@unifii/sdk';

import { Media, TenantSettings, UcClient } from 'client';

import { EditData } from 'components/common/edit-data';
import { MediaSearchComponent } from 'components/content/modals/media-search.component';
import { ModalSearchData } from 'components/content/modals/modal-search';

import { TenantSettingsService } from 'services/tenant-settings.service';
import { translatedLanguages } from 'services/translate-loader';

import { TenantSettingsComponent } from './settings.component';


@Component({
    templateUrl: './system-general.html',
    styleUrls: ['./system-general.less'],
})
export class SystemGeneralComponent implements OnInit, OnDestroy, EditData {

    form: UfControlGroup;
    error: any;
    settings: TenantSettings;

    sessionDuration: Duration;
    tokenDuration: Duration;

    langResults: Language[] = [];

    fileTypes: FileType[] = [];
    fileTypeOptions: FileType[] = [];

    bypassAssetFileTypesWhitelist = true;
    bypassAttachmentFileTypesWhitelist = true;
    editGoogleApiKey = false;

    private _disabledSessionTimeout = true;
    private subscriptions = new Subscription();
    private allLanguages: Language[] = [];

    constructor(
        private client: UcClient,
        private toast: ToastService,
        private modalService: ModalService,
        private ucClient: UcClient,
        private tenantSettings: TenantSettingsService,
        private parent: TenantSettingsComponent
    ) {
        // get all available languages
        this.ucClient.getLanguages().then(l => this.allLanguages = l);
    }

    set edited(v: boolean) {
        this.parent.edited = v;
    }

    get edited() {
        return this.parent.edited;
    }

    set disabledSessionTimeout(v: boolean) {
        this._disabledSessionTimeout = v;
        if (v) {
            this.form.removeControl('maxSessionLength');
        } else {
            this.settings.maxSessionLength = this.settings.maxSessionLength || 0;
            this.sessionDuration = this.secondsToDuration(this.settings.maxSessionLength);
            this.form.addControl('maxSessionLength', new UfControl(ValidatorFunctions.custom(() => !!this.settings.maxSessionLength && this.settings.maxSessionLength >= 300, 'Inactive session expiry must be longer than 5 minutes')));
        }
    }

    get disabledSessionTimeout() {
        return this._disabledSessionTimeout;
    }

    ngOnInit() {
        this.init();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
        this.parent.edited = false;
    }

    async findLogo() {
        const result = await this.modalService.openMedium<ModalSearchData, Media[]>(MediaSearchComponent, {
            title: 'Find Logo',
            type: 'Image',
        });

        if (!result) {
            return;
        }

        this.settings.logo = {
            id: result[0].id as number,
            url: result[0].url,
        };
        this.edited = true;
    }

    deleteLogo() {
        delete this.settings.logo;
        this.edited = true;
    }

    searchLang(query: string) {
        this.langResults = this.allLanguages.filter(language => translatedLanguages.includes(language.code));

        if (!query || !query.trim().length) {
            return;
        }

        query = query.toLowerCase();

        this.langResults = this.langResults.filter(l =>
            l.code.toLowerCase().includes(query) || l.name.toLowerCase().includes(query)
        );
    }

    save() {

        this.form.setSubmitted();

        if (this.form.invalid) {
            return;
        }

        this.error = null;

        if (this.disabledSessionTimeout) {
            delete this.settings.maxSessionLength;
        }
        if (this.bypassAssetFileTypesWhitelist) {
            this.settings.allowedAssetFileTypes = null;
        } else if (this.settings.allowedAssetFileTypes) {
            // PUT API accept {id: string}[] format instead of FileType[] of GET API
            const toSaveAssetFileTypes = (this.settings.allowedAssetFileTypes as FileType[]).map(fileType => ({ id: fileType.id }));
            this.settings.allowedAssetFileTypes = toSaveAssetFileTypes as any as FileType[];
        }

        if (this.bypassAttachmentFileTypesWhitelist) {
            this.settings.allowedAttachmentFileTypes = null;
        } else if (this.settings.allowedAttachmentFileTypes) {
            // PUT API accept {id: string}[] format instead of FileType[] of GET API
            const toSaveAttachmentFileTypes = (this.settings.allowedAttachmentFileTypes as FileType[]).map(fileType => ({ id: fileType.id }));
            this.settings.allowedAttachmentFileTypes = toSaveAttachmentFileTypes as any as FileType[];
        }


        this.client.updateSettings(this.settings).then(
            () => {
                this.edited = false;
                this.toast.success('Changes saved!');
            },
            error => this.error = error
        );
    }

    sessionLengthChange(value: number, field: keyof Duration) {
        this.sessionDuration[field] = value;
        this.settings.maxSessionLength = this.durationToSeconds(this.sessionDuration);
        this.form.controls.maxSessionLength.updateValueAndValidity();
    }

    tokenLengthChange(value: number, field: keyof Duration) {
        this.tokenDuration[field] = value;
        this.settings.refreshTokenExpiry = this.durationToSeconds(this.tokenDuration);
        this.form.controls.refreshTokenExpiry.setValue(this.settings.refreshTokenExpiry);
    }

    searchAssetFileTypes(q?: string) {
        if (q && q.length) {
            this.fileTypeOptions = this.fileTypes.filter(fileType => fileType.id.includes(q));
        } else {
            this.fileTypeOptions = this.fileTypes;
        }
        if (this.settings.allowedAssetFileTypes) {
            this.fileTypeOptions = this.fileTypeOptions.filter(fileType => !(this.settings.allowedAssetFileTypes as FileType[]).map(type => type.id).includes(fileType.id));
        }
    }

    searchAttachmentFileTypes(q?: string) {
        if (q && q.length) {
            this.fileTypeOptions = this.fileTypes.filter(fileType => fileType.id.includes(q));
        } else {
            this.fileTypeOptions = this.fileTypes;
        }
        if (this.settings.allowedAttachmentFileTypes) {
            this.fileTypeOptions = this.fileTypeOptions.filter(fileType => !(this.settings.allowedAttachmentFileTypes as FileType[]).map(type => type.id).includes(fileType.id));
        }
    }

    private secondsToDuration(seconds: number): Duration {
        const duration: Duration = {};
        duration.days = Math.floor(seconds / 86400);

        const daysRemainder = seconds % 86400;
        duration.hours = Math.floor(daysRemainder / 3600);

        const hoursRemainder = daysRemainder % 3600;

        duration.minutes = Math.floor(hoursRemainder / 60);
        duration.seconds = hoursRemainder % 60;

        return duration;
    }

    private durationToSeconds(duration: Duration): number {
        return (duration.days as number * 86400) + (duration.hours as number * 3600) + (duration.minutes as number * 60) + (duration.seconds as number);
    }

    private async init() {
        this.form = new UfControlGroup({
            name: new UfControl(ValidatorFunctions.required('A value is required')),
            baseUrl: new UfControl(ValidatorFunctions.required('A value is required')),
            privacyPolicyUrl: new UfControl(),
            emailRequired: new UfControl(),
            contactName: new UfControl(),
            contactEmail: new UfControl(ValidatorFunctions.pattern(Regexp.REGEXP_EMAIL, 'Must be a valid email')),
            contactPhone: new UfControl(),
            language: new UfControl(),
            refreshTokenExpiry: new UfControl(ValidatorFunctions.custom((v) => v >= 300, 'Refresh token expiry must be longer than 5 minutes')),
            rememberMeExpiryDays: new UfControl(ValidatorFunctions.min(1, 'Must be at least 1 day'))
        });

        try {
            // load file extentions
            const fileTypes = await this.client.getFileTypes();
            for (const fileType of fileTypes) {
                this.fileTypes.push(Object.assign(fileType, { label: fileType.id + (fileType.extensions.length > 1 ? ' (' + fileType.extensions.join(', ') + ')' : '') }));
            }

            // load settings
            this.settings = await this.tenantSettings.sync();

            if (this.settings.maxSessionLength) {
                this.disabledSessionTimeout = false;
            }

            if (this.settings.allowedAssetFileTypes) {
                this.bypassAssetFileTypesWhitelist = false;
            }

            if (this.settings.allowedAttachmentFileTypes) {
                this.bypassAttachmentFileTypesWhitelist = false;
            }

            this.settings.refreshTokenExpiry = this.settings.refreshTokenExpiry || 0;
            this.form.controls.refreshTokenExpiry.setValue(this.settings.refreshTokenExpiry);
            this.tokenDuration = this.secondsToDuration(this.settings.refreshTokenExpiry);
        } catch (error) {
            this.error = error;
        }

        this.subscriptions.add(this.form.valueChanges.subscribe(() => this.edited = true));
    }
}
