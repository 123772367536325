import { SortStatus, TableDataSource } from '@unifii/library/common';

import { AppInfo, UcApps } from 'client';


export class AppsDatasource extends TableDataSource<AppInfo> {

    private streamClosed = false; // todo: UNIFII-5952

    constructor(
        private ucApps: UcApps,
        private search?: string,
        private sort?: SortStatus,
        private status?: string
    ) {
        super();
    }

    get filtered(): boolean {
        return (this.search && this.search.trim().length > 0) || this.status != null;
    }

    get sorted(): boolean {
        return this.sort != null;
    }

    load() {
        try {

            if (this.streamClosed) {
                this.stream.next({ data: [] });
                return;
            }

            this.ucApps.get()
                .then(apps => {
                    this.stream.next({ data: apps });
                }, error => {
                    this.stream.next({ error });
                });

            this.streamClosed = true;
        } catch (error) {
            this.stream.error({ error });
        }
    }
}
