import { amendOptionsParams, ClientDeleteOptions, ClientGetOptions, ClientPostOptions, mergeParams } from '@unifii/sdk';

import { DefaultPaginationParams } from 'constant';

import { UcCollectionTranslation } from './collection-translation';
import { CompoundInfo, UcCompound, UcDefinition } from './content-models';
import { UcClient } from './uc-client';


interface CollectionGetParams {
    q?: string;
    sort?: string;
    status?: string;
    offset?: number;
    limit?: number;
    full?: boolean;
    ids?: string;
}

export class UcCollection {

    constructor(
        private client: UcClient,
        private projectId: number,
        private collectionIdentifier: string
    ) { }

    translation(langCode: string) {
        if (!langCode) {
            throw new Error('Invalid langCode');
        }
        return new UcCollectionTranslation(this.client, this.projectId, this.collectionIdentifier, langCode);
    }

    get(params?: CollectionGetParams, options?: ClientGetOptions): Promise<CompoundInfo[]> {
        const defaultedParams = mergeParams(DefaultPaginationParams, params as Record<string, unknown>);
        return this.client.get(this.url(), amendOptionsParams(defaultedParams, options));
    }

    getItem(id: number, options?: ClientGetOptions): Promise<UcCompound> {
        return this.client.get(this.url(id), options);
    }

    saveItem(compound: UcCompound): Promise<UcCompound> {
        if (compound.id) {
            return this.client.put(this.url(compound.id), compound);
        } else {
            return this.client.post(this.url(), { body: compound });
        }
    }

    getDefinition(options?: ClientGetOptions): Promise<UcDefinition> {
        return this.client.get(this.url('definition'), options);
    }

    approve(id: number, options?: ClientPostOptions): Promise<CompoundInfo> {
        return this.client.post(this.url('approved'), amendOptionsParams({ id }, options));
    }

    revert(id: number, options?: ClientPostOptions): Promise<CompoundInfo> {
        return this.client.post(this.url(), amendOptionsParams({ id }, options));
    }

    archive(id: number, options?: ClientPostOptions): Promise<CompoundInfo> {
        return this.client.post(this.url('archived'), amendOptionsParams({ id }, options));
    }

    delete(id: number, options?: ClientDeleteOptions): Promise<void> {
        return this.client.delete(this.url(id), options);
    }

    private url(...extra: (string | number)[]): string {
        const urlParts = ['projects', this.projectId, 'collections', this.collectionIdentifier].concat(extra);
        return this.client.buildUrl(urlParts);
    }
}
