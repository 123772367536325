/* eslint-disable @typescript-eslint/member-ordering */
import { AfterViewInit, Directive } from '@angular/core';
import { Modal, ModalRuntime } from '@unifii/library/common';


export interface ModalSearchData {
    type: string;
    title: string;
    multiSelect?: boolean;
    minQuantitySelected?: number;
}

@Directive()
export abstract class ModalSearch implements Modal<ModalSearchData, any[]>, AfterViewInit {

    query: string | undefined | null;
    items: any[] = [];
    ignoreList: any = [];

    busy: boolean;
    offset = 0;
    limit = 30;
    limitReached: boolean;
    selectAll: boolean;
    error: string | null;

    protected selectedItems: any = {};

    abstract search(query?: string, offset?: number): Promise<any[]>;

    constructor(
        public data: ModalSearchData,
        public runtime: ModalRuntime<ModalSearchData, any[]>
    ) { }

    ngAfterViewInit() {
        this.filter();
    }

    filter(query?: string | undefined | null) {
        this.query = query;

        if (query && query.length) {
            this.offset = 0;
            this.items = [];
        }

        this.getListItems();
    }

    async getListItems() {

        this.busy = true;

        const items = await this.search(this.query || undefined, this.offset);
        this.busy = false;

        if (this.offset === 0) {
            this.items = [];
        }

        this.limitReached = !items || !items.length;
        this.items = this.items.concat(this.removeIgnoredItems(items));
    }

    removeIgnoredItems(items: any[] = []) {

        return items.reduce((result, item) => {
            if (this.ignoreList.indexOf(item.id) < 0) {
                item.selected = this.selectedItems[item.id] != null;
                result.push(item);
            }
            return result;
        }, []);
    }

    select(i: number) {
        this.error = null;
        const item = this.items[i];

        if (!this.data.multiSelect) {
            this.items.forEach(n => n.selected = false);
            this.selectedItems = {};
        }

        item.selected = !item.selected;

        if (item.selected) {
            this.selectedItems[item.id] = item;
            return;
        }

        delete this.selectedItems[item.id];

    }

    updateAll(v: boolean) {

        this.selectAll = v;
        this.error = null;

        for (const item of this.items) {
            item.selected = this.selectAll;
        }

        if (this.selectAll) {
            this.selectedItems = this.items.reduce((result, item) => {
                result[item.id] = item;
                return result;
            }, {});
            return;
        }

        this.selectedItems = {};

    }

    save() {
        if (Object.keys(this.selectedItems).length < (this.data.minQuantitySelected || 0)) {
            this.error = 'Please select at least ' + this.data.minQuantitySelected;
            return;
        }
        const result = Object.keys(this.selectedItems).map(key => this.selectedItems[key]);
        this.runtime.close(result);
    }

    close() {
        this.runtime.close();
    }

    onScrolledToEnd() {
        if (this.busy || this.limitReached) {
            return;
        }

        this.offset += this.limit;
        this.getListItems();

    }

}
