import { Injectable } from '@angular/core';
import { ClaimConfig, ClientGetOptions, Company, Role } from '@unifii/sdk';
import { UserProvisioningProvider } from '@unifii/user-provisioning';

import { UcRoles } from './roles';
import { UcClient } from './uc-client';
import { UcUserClaims } from './user-claims';


@Injectable()
export class UserProvisioning implements UserProvisioningProvider {

    private userClaimsPromise: Promise<ClaimConfig[]>;
    private companyClaimsPromise: Promise<ClaimConfig[]>;

    constructor(
        private ucClient: UcClient,
        private ucRoles: UcRoles,
        private ucUserClaims: UcUserClaims
    ) { }

    queryRoles(q?: string, sort?: string, options?: ClientGetOptions): Promise<Role[]> {
        return this.ucRoles.get(q, sort, options);
    }

    queryCompanies(q?: string): Promise<Company[]> {
        return this.ucClient.getCompanies(q);
    }

    getUserClaimConfig(): Promise<ClaimConfig[]> {
        if (this.userClaimsPromise == null) {
            this.userClaimsPromise = this.ucUserClaims.list() as Promise<ClaimConfig[]>;
        }

        return this.userClaimsPromise;
    }

    getCompanyClaimConfig(): Promise<ClaimConfig[]> {
        if (this.companyClaimsPromise == null) {
            this.companyClaimsPromise = this.ucUserClaims.list() as Promise<ClaimConfig[]>;
        }

        return this.companyClaimsPromise;
    }

}