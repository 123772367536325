<div [formGroup]="control" class="grid--no-padding">

    <!-- <pre class="col-1of1">{{filterEntry | json}}</pre> -->

    <!-- Field -->
    <uf-autocomplete label="Field" placeholder="Search..." (searchChange)="search($event)" [options]="fieldOptions"
        nameProperty="display" [formControlName]="controlKeys.Field" [ngClass]="cssClass" class="col-1of2">
    </uf-autocomplete>

    <!-- Operator -->
    <uf-select *ngIf="filterEntry.field" label="Operator" [options]="operatorOptions" valueProperty="identifier"
        [formControlName]="controlKeys.Operator" [ngClass]="cssClass" class="col-1of2">
    </uf-select>

    <!-- ValueType -->
    <uf-select *ngIf="filterEntry.operator" label="Value Type" [options]="valueTypeOptions" valueProperty="identifier"
        [formControlName]="controlKeys.ValueType" [ngClass]="cssClass" class="col-1of2">
    </uf-select>

    <ng-template [ngIf]="filterEntry.field && filterEntry.operator">
        <!-- Value -->
        <uc-filter-value *ngIf="filterEntry.valueType === nodeTypes.Value" [field]="filterEntry.field"
            [operator]="filterEntry.operator" [control]="valueControl" cssClass="small" class="col-1of2 small">
        </uc-filter-value>
        <!-- Expression -->
        <uf-text *ngIf="filterEntry.valueType === nodeTypes.Expression" label="Expression"
            [formControlName]="controlKeys.Value" [ngClass]="cssClass" class="col-1of2">
        </uf-text>
    </ng-template>

    <uf-error class="col-1of1" [control]="control"></uf-error>

</div>