import { Subscription } from 'rxjs';

import { Directive, OnDestroy, OnInit, Optional } from '@angular/core';
import { MessageLevel, ToastService } from '@unifii/library/common';

import { BuilderNotificationInfo, BuilderService } from '../builder.service';

import { BuilderHeaderService } from './builder-header.service';


@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[uc-header-warning]'
})
export class BuilderHeaderWarningDirective implements OnInit, OnDestroy {

    private subscriptions = new Subscription();

    constructor(
        private toastService: ToastService,
        private builderHeaderService: BuilderHeaderService,
        @Optional() private builderService: BuilderService
    ) { }

    ngOnInit() {
        if (this.builderService) {
            this.subscriptions.add(this.builderService.ready.subscribe(() => {
                this.subscriptions.add(this.builderService.notify.subscribe(notification => this.displayMessage(notification)));
            }));
        } else {
            this.subscriptions.add(this.builderHeaderService.notify.subscribe(notification => this.displayMessage(notification)));
        }
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    private displayMessage({ message, level }: BuilderNotificationInfo) {
        switch (level) {
            case MessageLevel.Error:
                this.toastService.error(message);
                break;
            case MessageLevel.Info:
                this.toastService.info(message);
                break;
            case MessageLevel.Warning:
                this.toastService.warning(message);
                break;
            case MessageLevel.Success:
                this.toastService.success(message);
                break;
        }
    }
}
