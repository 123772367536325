<uf-expander *ngIf="ready" class="uf-box flat">
    <div expanderHeader class="uf-app-bar flat">
        <div class="title primary">Nested Fields</div>
        <uf-icon *ngIf="isInvalid" name="error" class="error"></uf-icon>
    </div>

    <div expanderBody [formGroup]="control" class="uc-group-content grid">

        <!-- Address autocomplete -->
        <ng-template [ngIf]="field.type === fieldTypes.Address">
            <uf-checkbox label="Enable search" [formControlName]="fieldKeys.AddressAutocomplete"
                class="col-1of1--no-margin">
            </uf-checkbox>
        </ng-template>

        <!-- Nested Fields -->
        <div [formGroup]="nestedControlGroup">
            <div *ngFor="let controlName of nestedControlNames" [formGroupName]="controlName"
                class="col-1of1--no-margin">
                <div class="fieldset-item--x-small">
                    <span>{{ getLabel(controlName) | toDisplayName }}</span>
                </div>
                <div class="row space-children">
                    <uf-checkbox label="Visible" [formControlName]="nestedKeys.Visible">
                    </uf-checkbox>
                    <uf-checkbox label="Read Only" [formControlName]="nestedKeys.ReadOnly">
                    </uf-checkbox>
                    <uf-checkbox label="Required" [formControlName]="nestedKeys.Required" class="hideError">
                    </uf-checkbox>
                </div>
                <uf-error [formControlName]="nestedKeys.Required"></uf-error>
            </div>
        </div>

    </div>

</uf-expander>