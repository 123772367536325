<header>
    <h1>Drag Test</h1>
</header>
<main>
    <article class="padded">
        <uc-drag-list [items]="items">
            <ul class="fields">
                <li *ngFor="let item of items" dragItem [nestable]="true">
                    Test Node
                </li>
            </ul>
        </uc-drag-list>
    </article>
</main>