import { Component, Input, OnInit } from '@angular/core';

import { MediaType } from 'client';
import { MediaHelper } from 'helpers/media-helper';


@Component({
    selector: 'uc-mediabox',
    templateUrl: './mediabox.html',
    styleUrls: ['mediabox.less']
})
export class MediaboxComponent implements OnInit {

    @Input() media: any;

    readonly infoDisplay = 'info';
    readonly imageDisplay = 'img';

    displayType: string;
    fileType: string;
    icon: string;
    url: string | null;

    private _offset: number;

    ngOnInit() {
        this.url = this.getImageUrl(214, 120) || null;
        this.displayType = (this.media.type === MediaType.Image) ? this.imageDisplay : this.infoDisplay;
        this.icon = (this.fileType === 'pdf') ? 'pdf' : this.media.type.toLowerCase();
    }

    @Input() set offset(v) {
        this._offset = v;
    }

    get offset() {
        return this._offset || 0;
    }

    private getImageUrl(width: number, height: number) {

        if (!this.media) {
            return;
        }

        if (this.media) {
            const url = MediaHelper.getImageUrl(this.media, width - this.offset, height - this.offset);

            if (!url) {
                return;
            }

            const splitDots = url.split('.');
            const fileString = splitDots[splitDots.length - 1];
            this.fileType = fileString.split('?')[0];
            return url;
        }

        return null;
    }
}
