<form class="grid" *ngIf="ready && notifyEnabled">
	<uf-chips class="col-1of1" label="Roles" *ngIf="config.role.show" [value]="roles"
		(valueChange)="mapRoles($event, 'role')" (searchChange)="findRoles($event)" [options]="rolesResult"
		[control]="form.controls.role | asUfControl"></uf-chips>
	<uf-textarea class="col-1of1" label="Show If" *ngIf="config.showIf.show"
		[control]="form.controls.showIf | asUfControl" [(value)]="field.showIf"></uf-textarea>

	<uf-chips class="col-1of1" label="Visible to" *ngIf="config.visibleTo.show" [value]="visibleTos"
		(valueChange)="mapRoles($event, 'visibleTo')" (searchChange)="findRoles($event)" [options]="rolesResult"
		[control]="form.controls.visibleTo | asUfControl"></uf-chips>

	<uf-autocomplete class="col-1of1" label="Show On" *ngIf="config.showOn.show" [(value)]="field.showOn"
		(searchChange)="searchActions($event)" [options]="actions" [control]="form.controls.showOn | asUfControl">
	</uf-autocomplete>
</form>