import { AppAuthProviderConfiguration } from '@unifii/sdk';


export const mapProviderLoginLabel = (provider: AppAuthProviderConfiguration, providers: AppAuthProviderConfiguration[]) => {
    let providerLoginLabel = provider.providerLoginLabel;
    if (!providerLoginLabel) {
        const multiplesOfSameProvider = providers.filter(p => p.type === provider.type).length > 1;
        providerLoginLabel = multiplesOfSameProvider ? provider.tenant : '';
    }
    return { ...provider, providerLoginLabel };
};