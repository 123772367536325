<uf-panel class="container">
    <div class="grid--fixed">
        <div class="content-pane">
            <div class="uf-grid col-12">
                <ng-template ngFor [ngForOf]="contentTypes" let-type>
                    <div class="uf-lozenge info col-1of12" [innerHTML]="type" (click)="show($any(type))">
                    </div>
                </ng-template>
            </div>
            <div *ngIf="content" class="uf-grid col-12">
                <pre class="col-12">{{content | json}}</pre>
            </div>
        </div>
    </div>
</uf-panel>