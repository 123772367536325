<uf-expander *ngIf="control" class="uf-box flat">
    <div expanderHeader class="uf-app-bar flat">
        <div class="title primary">{{contentTypeLabel}}</div>
        <uf-icon *ngIf="unpublishedWarningMessage" name="unpublished" [title]="unpublishedWarningMessage"
            class="icon-warning">
        </uf-icon>
        <a *ngIf="canCopy" (click)="copy()" class="uf-action tertiary" title="Copy">
            <uf-icon name="copy"></uf-icon>
        </a>
        <a *ngIf="contentLink" [routerLink]="contentLink" target="_blank" class="uf-action tertiary" title="Open">
            <uf-icon name="open"></uf-icon>
        </a>
    </div>
    <div expanderBody [formGroup]="control">
        <!-- todo: add loading skeleton -->
        <ng-container *ngIf="ready">
            <uf-blockquote *ngIf="unpublishedWarningMessage" icon="unpublished" class="warning"
                [content]="unpublishedWarningMessage">
            </uf-blockquote>
            <uf-description-list [items]="contentInfo" class="small-label pad"></uf-description-list>
        </ng-container>
    </div>
</uf-expander>