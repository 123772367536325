import { Subscription } from 'rxjs';

import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import {
    DataDescriptorAdapterCache, DataPropertyInfoService, ExpandersService, MessageLevel, ModalService, StorageWrapper, ToastService, UfControlArray,
    UfControlGroup, WindowWrapper
} from '@unifii/library/common';
import { CompoundType, Error, ErrorType, FieldType } from '@unifii/sdk';

import { DefinitionInfo, UcDefinition, UcProject } from 'client';
import { PREVIEW_FORM_STORAGE_KEY, PROJECT_STORAGE_KEY } from 'constant';

import { EditData, EditMode } from 'components/common/edit-data';
import { SaveOption, SaveOptionType } from 'components/common/save-options/save-options.component';
import { BuilderHeaderService } from 'components/compound-builder/builder-header/builder-header.service';
import { TemplateConfigManager } from 'components/compound-builder/template-config-manager';

import { PUBLISH_STATE_CLASS_DICTIONARY } from 'helpers/css-class-helper';

import { flattenControls } from 'pages/structure/structure-form-ctrl';
import { reloadCurrentRoute } from 'pages/utils';

import { ContextService } from 'services/context.service';
import { InfoTableManager } from 'services/table/info-table-manager';

import { FormEditorCache, FormEditorCacheService } from './form-editor-cache';
import { DefinitionControlKeys, FieldControlKeys } from './form-editor-control-keys';
import { FormEditorFieldScopeManager } from './form-editor-field-scope-manager';
import { FormEditorFormCtrl } from './form-editor-form-ctrl';
import { FormEditorFunctions } from './form-editor-functions';
import { FormEditorDefinition } from './form-editor-model';
import { FormEditorStatus } from './form-editor-status';
import { FormEditorService } from './form-editor.service';


@Component({
    selector: 'uc-form-editor',
    templateUrl: './form-editor.html',
    providers: [
        FormEditorStatus, FormEditorFormCtrl, FormEditorService, ExpandersService,
        TemplateConfigManager, FormEditorFieldScopeManager,
        { provide: FormEditorCache, useClass: FormEditorCacheService }
    ],
    styleUrls: ['./form-editor.less']
})
export class FormEditorComponent implements OnInit, OnDestroy, EditData {

    readonly compoundTypes = CompoundType;
    readonly publishStateClassDictionary = PUBLISH_STATE_CLASS_DICTIONARY;

    private previewWindow: Window | null;
    private subscriptions: Subscription = new Subscription();

    constructor(
        public status: FormEditorStatus,
        public service: FormEditorService,
        private fb: FormEditorFormCtrl,
        @Inject(FormEditorCache) private cache: FormEditorCache,
        private ucProject: UcProject,
        private toast: ToastService,
        private context: ContextService,
        private router: Router,
        private dataDescriptorCache: DataDescriptorAdapterCache,
        private dataPropertyInfoService: DataPropertyInfoService,
        private modalService: ModalService,
        private route: ActivatedRoute,
        private cdr: ChangeDetectorRef,
        @Inject(StorageWrapper) private storage: StorageWrapper,
        @Inject(WindowWrapper) private window: Window,
        @Inject(ExpandersService) private expanders: ExpandersService,
        @Optional() @Inject(TableContainerManager) private tableManager: InfoTableManager,
        headerService: BuilderHeaderService,
    ) {
        this.status.headerService = headerService;
        this.status.route = route;
        this.status.containersByFieldType = FormEditorFunctions.getContainersByFieldType(this.context);
        this.status.editMode = FormEditorFunctions.detectEditMode(this.route.snapshot.params);
    }

    async ngOnInit() {

        this.dataDescriptorCache.reset();

        // Cached data
        await this.service.loadItemPickerGroups();

        this.subscriptions.add(this.status.headerService.saveClicked.subscribe(saveOption => this.onAction(saveOption)));

        // Use definition set by definition resolver
        const { definition } = this.route.snapshot.data as { definition: UcDefinition };
        const formEditorDefinition = await FormEditorFunctions.mapDataToControlValue(definition, this.cache, this.dataPropertyInfoService);
        this.service.applyDefinition(formEditorDefinition);

        // New and duplicated forms start in edited status
        if (this.status.editMode !== EditMode.Existing) {
            this.status.edited = true;
        }
    }

    ngOnDestroy() {
        this.status.valueChangesSub?.unsubscribe();
        this.subscriptions.unsubscribe();
        this.fb.destroy();
    }

    get edited(): boolean {
        return this.status.edited;
    }

    get form(): UfControlGroup {
        return this.status.root;
    }

    get disabled(): boolean {
        return this.status.root.disabled;
    }

    get definition(): FormEditorDefinition {
        return this.form.getRawValue();
    }

    get fields(): UfControlArray {
        return this.form.get(FieldControlKeys.Fields) as UfControlArray;
    }

    get bucket(): string {
        return this.form.get(DefinitionControlKeys.Bucket)?.value;
    }

    openPreview() {
        const openFn = this.window.open;

        if (openFn) {
            const definition = FormEditorFunctions.mapControlValueToData(this.definition);
            this.storage.setItem(PREVIEW_FORM_STORAGE_KEY, JSON.stringify(definition));
            this.storage.setItem(PROJECT_STORAGE_KEY, JSON.stringify(this.context.project));

            if (this.previewWindow && !this.previewWindow.closed) {
                this.previewWindow.focus();
                return;
            }

            const url = location.origin + '/form-preview';
            this.previewWindow = openFn(url, 'FormPreview');
        }
    }

    async onAction(option?: SaveOption) {

        try {
            // Guard
            if (!this.form.valid) {
                // DEBUG start
                const items = flattenControls(this.form);

                for (const entry of items) {
                    entry.control.markAsTouched();

                    if (entry.control.errors != null) {
                        console.log(entry.key, entry.control.errors);
                    }
                }

                this.toast.error('Unable to save. There are errors in your Form');
                // DEBUG end
                return;
            }

            // Disable save button so it won't be pressed twice mid save
            this.status.headerService.config.disabledSave = true;

            const saved = await this.save(FormEditorFunctions.mapControlValueToData(this.definition));
            if (!saved) {
                return;
            }

            const definitionInfo = Object.assign({}, saved, { name: saved?.label }) as any as DefinitionInfo;

            if (this.tableManager != null) {
                if (this.status.editMode === EditMode.Existing) {
                    this.tableManager.updateItem?.next(definitionInfo);
                } else {
                    this.tableManager.reload?.next();
                }
            }

            this.status.edited = false;

            if (!option) {
                const formEditorDefinition = await FormEditorFunctions.mapDataToControlValue(
                    saved,
                    this.cache,
                    this.dataPropertyInfoService
                );

                // force refresh in template of root
                // needed for detecting changes to root once is re-built in applyDefinition
                this.status.root = undefined as any;
                this.cdr.detectChanges();

                this.service.applyDefinition(formEditorDefinition);
                return;
            }

            switch (option.id) {
                case SaveOptionType.Close:
                    this.back();
                    break;
                case SaveOptionType.New:
                    if (this.router.url.endsWith('/new')) {
                        reloadCurrentRoute(this.router);
                    } else {
                        this.router.navigate(['..', 'new'], { relativeTo: this.route });
                    }
                    break;
                case SaveOptionType.Next:
                    const nextId = this.tableManager.getNextItem(saved?.id)?.id;
                    if (nextId != null) {
                        this.router.navigate(['..', nextId], { relativeTo: this.route });
                    }
                    break;
            }
        } catch (e) {
            this.status.headerService.notify.next({
                title: 'Error',
                level: MessageLevel.Error,
                message: e.message ?? 'Oops... something went wrong with saving your form'
            });
        } finally {
            this.status.headerService.config.disabledSave = false;
        }
    }

    selectField(control: UfControlGroup | null) {
        this.status.selected = control;
    }

    toggleExpanders(expand: boolean, list: HTMLElement) {

        if (expand) {
            this.expanders.expandAll(list);
            return;
        }

        this.expanders.collapseAll(list);
    }

    isNestable(type?: FieldType): boolean {
        return this.status.containersByFieldType[type as FieldType] === true;
    }

    filterFieldControls(control: UfControlGroup): boolean {
        return FormEditorFunctions.isControlAField(control);
    }

    private async save(toSave: UcDefinition, force?: boolean): Promise<UcDefinition | null> {
        try {
            const saved = await this.ucProject.saveForm(toSave, force);
            this.cache.reset();
            this.dataDescriptorCache.reset();
            this.toast.success('Form saved');
            return saved;
        } catch (err) {

            console.error(err);
            const error = err as Error;
            if (error.type === ErrorType.Validation) {
                if (error.data?.warnings) {
                    const confirmed = await this.modalService.openConfirm({
                        title: 'Warning - Incompatible Fields',
                        message: (err.data.warnings as string[]).reduce((p, c) => `${p}\n\n${c}`, ''),
                        confirmLabel: 'Save'
                    });

                    if (confirmed) {
                        this.save(toSave, true);
                    }
                } else {
                    await this.modalService.openAlert({
                        title: 'Error - Incompatible Fields',
                        message: err.data.message,
                        closeButtonLabel: 'Close'
                    });
                }
            } else {
                const message = err.message || (err.data && err.data.message) ? err.data.message : 'Oops... something went wrong with saving your form';
                this.toast.error(message);
            }

            return null;
        }
    }

    private back() {
        this.router.navigate(['..'], { relativeTo: this.route });
    }
}