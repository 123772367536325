import { inject, Injectable } from '@angular/core';
import { HierarchyUnitProvider } from '@unifii/library/common';
import { Dictionary, Error, ErrorType, HierarchyUnitExtended, HierarchyUnitWithChildCount, HierarchyUnitWithPath } from '@unifii/sdk';

import { UcHierarchy } from './hierarchy';


@Injectable()
export class UcHierarchyUnitProvider implements HierarchyUnitProvider {

    private hierarchy = inject(UcHierarchy);

    async getUnit(id: string): Promise<HierarchyUnitExtended | undefined> {
        try {
            return this.hierarchy.getUnit(id);
        } catch (e) {
            if ((e as Error).type === ErrorType.NotFound) {
                return Promise.resolve(undefined);
            } else {
                throw e;
            }
        }
    }

    getUnits(ids: string[]): Promise<HierarchyUnitWithPath[]> {
        return this.hierarchy.getUnits(ids);
    }

    // TODO use API query parameter once UNIFII-6526 is resolved
    async getChildren(id: string, query?: string, params?: Dictionary<any>): Promise<HierarchyUnitWithChildCount[]> {
        try {
            const children = await this.hierarchy.getChildren(id, { params });

            if (!query || query.trim().length === 0) {
                return children;
            }

            return children.filter(u => u.label.toLowerCase().indexOf((query as string).toLowerCase()) >= 0);
        } catch (e) {
            if ((e as Error).type === ErrorType.NotFound) {
                return Promise.resolve([]);
            } else {
                throw e;
            }
        }
    }

    search(params: { q?: string; leaves?: boolean; ceiling?: string; active?: boolean }): Promise<HierarchyUnitWithPath[]> {
        return this.hierarchy.search(params);
    }
}