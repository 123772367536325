import { Subscription } from 'rxjs';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Device } from '@unifii/sdk';
import { Breadcrumb } from '@unifii/library/common';

import { UcApps } from 'client';
import { BreadcrumbService } from 'services/breadcrumb.service';
import { AppDetailsComponent } from './app-details.component';


@Component({
    templateUrl: './device-detail.html'
})
export class DeviceDetailComponent {

    device: Device | null;
    subscription: Subscription;
    loading = true;

    breadcrumbs: Breadcrumb[] = [];

    constructor(
        private route: ActivatedRoute,
        private ucApps: UcApps,
        private appDetails: AppDetailsComponent,
        private breadcrumbService: BreadcrumbService
    ) {
        this.load();
    }

    get id() {
        return this.route.snapshot.params.id;
    }

    private async load() {
        const devices = await this.ucApps.getDevices(this.appDetails.id);
        this.device = devices.find(d => d.id === this.id) || null;
        if (this.device) {
            this.loading = false;
        }
        this.breadcrumbs = this.breadcrumbService.getBreadcrumbs(this.route, [this.appDetails.name, this.device?.name ?? '']);
    }
}
