export * from './data-forwarders.component';
export * from './workflow-notification.component';
export * from './workflow-notifications.component';
export * from './new-workflow-component';
export * from './workflow-timers-form.component';
export * from './workflow-timers.component';
export * from './workflow-rules.component';
export * from './workflow-rule-form.component';
export * from './workflow-data-forwarders-form.component';

import { DataForwardersComponent } from './data-forwarders.component';
import { NewWorkflowComponent } from './new-workflow-component';
import { WorkflowDataForwardersFormComponent } from './workflow-data-forwarders-form.component';
import { WorkflowNotificationComponent } from './workflow-notification.component';
import { WorkflowNotificationsComponent } from './workflow-notifications.component';
import { WorkflowRuleFormComponent } from './workflow-rule-form.component';
import { WorkflowRulesComponent } from './workflow-rules.component';
import { WorkflowTimersFormComponent } from './workflow-timers-form.component';
import { WorkflowTimersComponent } from './workflow-timers.component';


// todo: add console module
export const WORKFLOW_COMPONENTS = [NewWorkflowComponent, DataForwardersComponent, WorkflowDataForwardersFormComponent, WorkflowNotificationComponent, WorkflowRuleFormComponent, WorkflowNotificationsComponent, WorkflowTimersFormComponent, WorkflowTimersComponent, WorkflowRulesComponent];
