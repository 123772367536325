import { Subject } from 'rxjs';

import { inject, Injectable } from '@angular/core';
import { TableContainerManager, TableInputManager, TableInputs } from '@unifii/components';
import { FilterEntry, FilterValue, HierarchyUnitProvider, TableConfig } from '@unifii/library/common';

import { AppInfo, SchemaInfo, UcProject } from 'client';

import { BucketsDataSource } from './buckets-datasource';


@Injectable()
export class BucketsTableManager implements TableContainerManager<SchemaInfo, FilterValue, FilterEntry> {

    tableConfig: TableConfig<AppInfo>;
    addActionConfig = true;
    reload = new Subject<void>();
    update = new Subject<TableInputs<FilterValue>>();
    inputManager: TableInputManager<FilterValue, FilterEntry>;

    constructor(
        private ucProject: UcProject
    ) {
        this.tableConfig = {
            columns: [{
                name: 'id',
                label: 'Bucket'
            }],
            row: {
                link: item => [item.id]
            },
            pageSize: 100
        };

        this.inputManager = new TableInputManager([], inject(HierarchyUnitProvider));
    }

    createDataSource() {
        return new BucketsDataSource(this.ucProject);
    }
}
