import { ClientDeleteOptions, ClientGetOptions, ClientPostOptions, mergeParams, Table } from '@unifii/sdk';

import { CompoundInfo, DefinitionInfo, TableInfo, UcCollection, UcCompound, UcDefinition, UcPage, UcProject, UcView } from 'client';
import { DefaultPaginationParams } from 'constant';

import { InfoLoader, InfoQueryParams, InfoType } from 'services/table/models';


export class ViewInfoLoader implements InfoLoader {

    type = InfoType.View;

    constructor(private ucProject: UcProject) { }

    list(infoQueryParams: InfoQueryParams, options?: ClientGetOptions): Promise<CompoundInfo[]> {
        const params = mergeParams(DefaultPaginationParams, options?.params, infoQueryParams as Record<string, unknown>);
        return this.ucProject.getViews({ ...options, params });
    }

    get(id: number, options?: ClientGetOptions): Promise<UcView> {
        return this.ucProject.getView(id, options);
    }

    approve(id: number, options?: ClientPostOptions): Promise<CompoundInfo> {
        return this.ucProject.approveView(id, options);
    }

    revert(id: number, options?: ClientPostOptions): Promise<CompoundInfo> {
        return this.ucProject.revertView(id, options);
    }

    archive(id: number, options?: ClientPostOptions): Promise<CompoundInfo> {
        return this.ucProject.archiveView(id, options);
    }

    delete(id: number, options?: ClientDeleteOptions): Promise<void> {
        return this.ucProject.deleteView(id, options);
    }
}

export class PageInfoLoader implements InfoLoader {

    type = InfoType.Page;

    constructor(private ucProject: UcProject) { }

    list(infoQueryParams: InfoQueryParams, options?: ClientGetOptions): Promise<CompoundInfo[]> {
        const params = mergeParams(DefaultPaginationParams, options?.params, infoQueryParams as Record<string, unknown>);
        return this.ucProject.getPages({ ...options, params });
    }

    get(id: number, options?: ClientGetOptions): Promise<UcPage> {
        return this.ucProject.getPage(id, options);
    }

    approve(id: number, options?: ClientPostOptions): Promise<CompoundInfo> {
        return this.ucProject.approvePage(id, options);
    }

    revert(id: number, options?: ClientPostOptions): Promise<CompoundInfo> {
        return this.ucProject.revertPage(id, options);
    }

    archive(id: number, options?: ClientPostOptions): Promise<CompoundInfo> {
        return this.ucProject.archivePage(id, options);
    }

    delete(id: number, options?: ClientDeleteOptions): Promise<void> {
        return this.ucProject.deletePage(id, options);
    }
}

export class CollectionsInfoLoader implements InfoLoader {
    type = InfoType.Collection;

    constructor(private ucProject: UcProject) { }

    list(infoQueryParams: InfoQueryParams, options?: ClientGetOptions): Promise<DefinitionInfo[]> {
        const params = mergeParams(DefaultPaginationParams, options?.params, swapStateForStatus(infoQueryParams));
        return this.ucProject.getCollections({ ...options, params });
    }
}

export class CollectionCompoundLoader implements InfoLoader {

    type = InfoType.CollectionData;

    constructor(private ucCollection: UcCollection) { }

    list(infoQueryParams: InfoQueryParams, options?: ClientGetOptions): Promise<CompoundInfo[]> {
        return this.ucCollection.get(swapStateForStatus(infoQueryParams), options);
    }

    get(id: number, options?: ClientGetOptions): Promise<UcCompound> {
        return this.ucCollection.getItem(id, options);
    }

    approve(id: number, options?: ClientPostOptions): Promise<CompoundInfo> {
        return this.ucCollection.approve(id, options);
    }

    revert(id: number, options?: ClientPostOptions): Promise<CompoundInfo> {
        return this.ucCollection.revert(id, options);
    }

    archive(id: number, options?: ClientPostOptions): Promise<CompoundInfo> {
        return this.ucCollection.archive(id, options);
    }

    delete(id: number, options?: ClientDeleteOptions): Promise<void> {
        return this.ucCollection.delete(id, options);
    }

}

export class FormInfoLoader implements InfoLoader {

    type = InfoType.Form;

    constructor(private ucProject: UcProject) { }

    list(infoQueryParams: InfoQueryParams, options?: ClientGetOptions): Promise<DefinitionInfo[]> {
        const params = mergeParams(DefaultPaginationParams, options?.params, swapStateForStatus(infoQueryParams));
        return this.ucProject.getForms({ ...options, params });
    }

    get(id: number, options?: ClientGetOptions): Promise<UcDefinition> {
        return this.ucProject.getForm(id as any as string, options);
    }

    approve(id: number, options?: ClientPostOptions): Promise<DefinitionInfo> {
        // todo: check comment on DefininitionInfo
        return this.ucProject.approveForm(id as any as string, options);
    }

    revert(id: number, options?: ClientPostOptions): Promise<DefinitionInfo> {
        return this.ucProject.revertForm(id as any as string, options);
    }

    archive(id: number, options?: ClientPostOptions): Promise<DefinitionInfo> {
        return this.ucProject.archiveForm(id as any as string, options);
    }

    delete(id: number, options?: ClientDeleteOptions): Promise<void> {
        return this.ucProject.deleteForm(id as any as string, options);
    }

}


export class TableInfoLoader implements InfoLoader {

    type = InfoType.Table;

    constructor(private ucProject: UcProject) { }

    list(infoQueryParams: InfoQueryParams, options?: ClientGetOptions): Promise<TableInfo[]> {
        const params = mergeParams(DefaultPaginationParams, options?.params, swapStateForStatus(infoQueryParams));
        return this.ucProject.getTables({ ...options, params });
    }

    get(id: number, options?: ClientGetOptions): Promise<Table> {
        return this.ucProject.getTable(id as any as string, options);
    }

    approve(id: number, options?: ClientPostOptions): Promise<TableInfo> {
        // todo: check comment on TableInfo
        return this.ucProject.approveTable(id as any as string, options);
    }

    revert(id: number, options?: ClientPostOptions): Promise<TableInfo> {
        return this.ucProject.revertTable(id as any as string, options);
    }

    archive(id: number, options?: ClientPostOptions): Promise<TableInfo> {
        return this.ucProject.archiveTable(id as any as string, options);
    }

    delete(id: number, options?: ClientDeleteOptions): Promise<void> {
        return this.ucProject.deleteTable(id as any as string, options);
    }

}


const swapStateForStatus = (params: InfoQueryParams): Record<string, unknown> => {
    // Map state to status
    if (params.state != null) {
        (params as Record<string, unknown>).status = params.state;
        delete params.state;
    }
    return params as Record<string, unknown>;
};