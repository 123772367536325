import { Component, HostBinding, Inject } from '@angular/core';
import { Modal, ModalData, ModalRuntime, UfControl, UfControlArray, UfControlGroup, ValidatorFunctions } from '@unifii/library/common';
import { AstNode, Claim, NodeType, SchemaField } from '@unifii/sdk';

import { UcRoles, UcUserClaims } from 'client';

import { ContextService } from 'services/context.service';


export interface QueryBuilderData {
    fields: SchemaField[];
    filter: AstNode;
}

export interface QueryBuilderResult {
    edited: boolean;
    filter: AstNode;
}


@Component({
    templateUrl: './users-query-builder.html',
    styleUrls: ['./users-query-builder.less']
})
export class UsersQueryBuilderComponent implements Modal<QueryBuilderData, QueryBuilderResult> {

    @HostBinding('class.uc-form-card') class = true;

    ready: boolean;
    roleResults: string[];
    roles: string[] = [];
    claims: Claim[] = [];
    claimOptions: string[];

    form: UfControlGroup = new UfControlGroup({});
    claimsControl: UfControlArray = new UfControlArray([]);
    rolesControl: UfControl = new UfControl();
    filter: AstNode;

    constructor(
        public runtime: ModalRuntime<QueryBuilderData, QueryBuilderResult>,
        @Inject(ModalData) data: QueryBuilderData,
        private ucRoles: UcRoles,
        public context: ContextService,
        private ucUserClaims: UcUserClaims
    ) {

        this.filter = data.filter;
        // Initial configurations
        this.form.addControl('roles', this.rolesControl);
        this.form.addControl('claims', this.claimsControl);

        // Map filter to UI model
        if (data.filter && data.filter.args) {
            if (data.filter.args.length > 0) {
                if (data.filter.args[0].op === 'contains') {
                    if (data.filter.args[0].args != null) {
                        this.roles = (data.filter.args[0].args)[1].value;
                    } else {
                        this.roles = [];
                    }
                }
                for (const arg of data.filter.args) {
                    if (arg.op === 'eq') {
                        // todo: UNIFII-4818
                        this.claims.push({
                            type: (arg.args as AstNode[])[0].value,
                            value: (arg.args as AstNode[])[1].value
                        });
                    }
                }
            }
        }

        // Create controls
        for (const _ of this.claims) {
            this.claimsControl.push(this.getClaimControl());
        }

        // Apply filter rows associated configuration and controls, after mark the builder as ready to work
        this.ready = true;
    }

    static getEmptyAstNode(): AstNode {
        return {
            type: NodeType.Combinator,
            op: 'and',
            args: [{
                type: NodeType.Operator,
                op: 'contains',
                args: [{
                    type: NodeType.Identifier,
                    value: 'roles'
                }, {
                    type: NodeType.Value,
                    value: []
                }]
            }]
        };
    }

    static isEmptyAstNode(astNode: AstNode): boolean {
        return ((astNode.args as AstNode[])[0].args as AstNode[])[1].value.length === 0 && (astNode.args as AstNode[]).length === 1;
    }

    async findRoles(query?: string) {
        this.roleResults = (await this.ucRoles.get(query)).map(r => r.name);
    }

    searchClaims(q: string | null = null) {
        this.ucUserClaims.list({ params: { q } }).then(claims => this.claimOptions = claims.map(claim => claim.type).filter(c => c != null));
    }

    addClaim() {
        // Control
        this.claimsControl.push(this.getClaimControl());
        // Data
        // todo: UNIFII-4818
        this.claims.push({ type: '', value: '' });
    }

    removeClaim(i: number) {
        this.claims.splice(i, 1);
        this.claimsControl.removeAt(i);
    }

    save() {

        this.form.setSubmitted();

        if (this.form.valid) {
            // Empty
            const filter = UsersQueryBuilderComponent.getEmptyAstNode();
            // Add roles
            ((filter.args as AstNode[])[0].args as AstNode[])[1].value = this.roles;
            // Add claims
            for (const c of this.claims) {
                (filter.args as AstNode[]).push({
                    type: NodeType.Operator,
                    op: 'eq',
                    args: [{
                        type: NodeType.Identifier,
                        value: c.type
                    }, {
                        type: NodeType.Value,
                        value: c.value
                    }]
                });
            }

            // Return filter
            this.runtime.close({ edited: this.form.dirty, filter });
        }
    }

    close() {
        this.runtime.close({ edited: false, filter: this.filter });
    }

    private getClaimControl(): UfControlGroup {
        const claim = new UfControlGroup({});
        claim.addControl('type', new UfControl(ValidatorFunctions.required('A type is required')));
        claim.addControl('value', new UfControl(ValidatorFunctions.required('A value is required')));
        return claim;
    }
}
