import { Subscription } from 'rxjs';

import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import {
    Breadcrumb, ModalService, ToastService, UfControl, UfControlArray, UfControlGroup, UfMarkdownEditorComponent, UfTextareaComponent
} from '@unifii/library/common';
import { DataSourceType, FieldType, Option, Schema } from '@unifii/sdk';

import {
    ActivityType, RelatedUser, UcClaimConfig, UcProject, UcRoles, UcUserClaims, UcUsers, UcWorkflow, WorkflowNotification,
    WorkflowNotificationConditionType, WorkflowNotificationDeliveryMethod, WorkflowNotificationRecipientClaimMatchType,
    WorkflowNotificationRecipientFormData, WorkflowNotificationRecipientFormDataType, WorkflowNotificationRecipientType
} from 'client';

import { EditData } from 'components/common/edit-data';
import { GlossaryComponent, GlossaryConfig, GlossaryEntry } from 'components/common/glossary.component';

import { BreadcrumbService } from 'services/breadcrumb.service';
import { ContextService } from 'services/context.service';
import { DialogsService } from 'services/dialogs.service';

import { WorkflowActivityTableManager } from './workflow-activity-table-manager';
import {
    WorkflowNotificationClaimMatchTypes, WorkflowNotificationConditions, WorkflowNotificationFormDataOptions, WorkflowNotificationRecipientOptions,
    WorkflowNotificationUserManagementClaimMatchTypes, WorkflowNotificationUserManagementRecipientOptions, WorkflowNotificationUserManagentConditions
} from './workflow-notification-constants';
import {
    ControlKeys, WorkflowNotificationFormController, WorkflowNotificationModel, WorkflowNotificationRecipientClaimModel,
    WorkflowNotificationRecipientsModel
} from './workflow-notification.controller';


interface MessageGroup {
    title: UfControl;
    body: UfControl;
    attachFormAsPdf: UfControl;
    replyTo: UfControl;
}

@Component({
    templateUrl: 'workflow-notification.html',
})
export class WorkflowNotificationComponent implements EditData, OnInit, OnDestroy {

    @ViewChild('emailMarkdownEditor', { static: false }) emailMarkdownEditor: UfMarkdownEditorComponent;
    @ViewChild('pushTextArea', { static: false }) pushTextArea: UfTextareaComponent;

    readonly controlKeys = ControlKeys;
    readonly workflowNotificationDeliveryMethod = WorkflowNotificationDeliveryMethod;
    readonly workflowNotificationRecipientType = WorkflowNotificationRecipientType;
    readonly workflowNotificationRecipientFormDataType = WorkflowNotificationRecipientFormDataType;
    readonly workflowNotificationRecipientClaimMatchType = WorkflowNotificationRecipientClaimMatchType;
    readonly workflowNotificationConditionType = WorkflowNotificationConditionType;

    readonly formDataTypes: Option[] = WorkflowNotificationFormDataOptions;
    readonly glossaryTitle = 'Glossary of available data';
    readonly glossaryEntries: GlossaryEntry[] = [{
        key: '{{id}}', value: 'Unique form identifier (eg. edb645a3-7dab-41ac-a6b0-21cb049bba40)'
    }, {
        key: '{{formNumber}}', value: 'Custom form number (eg. ABC-00001)'
    }, {
        key: '{{formLabel}}', value: 'The form name'
    }, {
        key: '{{previousState}}', value: 'Start Status (eg. Start)'
    }, {
        key: '{{action}}', value: 'Action performed (eg. Submit)'
    }, {
        key: '{{currentState}}', value: 'Target Status (eg. Submitted)'
    }, {
        key: '{{lastModifiedBy}}', value: 'The username of the user who last modified the form'
    }, {
        key: '{{lastModifiedAt}}', value: 'The Date/Time when the form was last modified'
    }, {
        key: '{{link}}', value: 'The URL that takes the notification recipient to the form'
    }, {
        key: '{{route}}', value: 'The unique URL path that takes the notification recipient to the form. It requires a hardcoded base URL to work. (eg. https://discover.unifiiplatform.com/{{route}})'
    }, {
        key: '{{result}}', value: 'The result of the workflow change (eg. Approved)'
    }, {
        key: '{{data.}}', value: 'Add form data using the field identifier after the full stop'
    }];

    // Enums

    bucket?: Schema;
    edited: boolean;
    root: UfControlGroup;
    bucketsResult: string[];
    error: Error | null = null;
    breadcrumbs: Breadcrumb[];
    users: RelatedUser[];

    workflowNotification: WorkflowNotification;

    // Filtered Fields
    filteredRoles: string[];
    filteredUserClaims: string[];
    filteredEmailFields: Option[];
    filteredHierarchyFields: Option[];
    filteredUserDatasources: string[];
    filteredClaimDatasources: string[];
    filteredFormDataClaimDatasources: string[];
    filteredCompanyDatasources: string[];
    recipientTypes: Option[] = [];
    isUserManagementNotification = false;
    conditionTypes: string[] = [];
    claimMatchOptions: Option[] = [];

    private emailFields: Option[];
    private hierarchyFields: Option[];
    private userDatasources: string[];
    private subscriptions: Subscription[] = [];
    private claimDatasources: string[];
    private userClaims: UcClaimConfig[];
    private companyDatasources: string[];

    constructor(
        private router: Router,
        private ucUserClaims: UcUserClaims,
        private ucRoles: UcRoles,
        private route: ActivatedRoute,
        public context: ContextService,
        private ucProject: UcProject,
        private ucUsers: UcUsers,
        private toastService: ToastService,
        private modalService: ModalService,
        private ucWorkflow: UcWorkflow,
        private dialogs: DialogsService,
        private formController: WorkflowNotificationFormController,
        private breadcrumbService: BreadcrumbService,
        @Inject(TableContainerManager) private tableManager: WorkflowActivityTableManager
    ) { }

    get hasEmail(): UfControl {
        return (this.messages.get(ControlKeys.Email) as UfControlGroup).get(ControlKeys.HasContent) as UfControl;
    }

    get hasPushNotification(): UfControl {
        return (this.messages.get(ControlKeys.Push) as UfControlGroup).get(ControlKeys.HasContent) as UfControl;
    }

    get messages(): UfControlGroup {
        return this.root.get(ControlKeys.Messages) as UfControlGroup;
    }

    get email(): MessageGroup {
        const emailGroup = this.messages.get(ControlKeys.Email) as UfControlGroup;
        return {
            title: emailGroup.get(ControlKeys.MessageTitle) as UfControl,
            body: emailGroup.get(ControlKeys.MessageBody) as UfControl,
            attachFormAsPdf: emailGroup.get(ControlKeys.MessageAttachFormAsPdf) as UfControl,
            replyTo: emailGroup.get(ControlKeys.ReplyTo) as UfControl,
        };
    }

    get pushNotification(): MessageGroup {
        const pushNotificationGroup = this.messages.get(ControlKeys.Push) as UfControlGroup;
        return {
            title: pushNotificationGroup.get(ControlKeys.MessageTitle) as UfControl,
            body: pushNotificationGroup.get(ControlKeys.MessageBody) as UfControl,
            attachFormAsPdf: pushNotificationGroup.get(ControlKeys.MessageAttachFormAsPdf) as UfControl,
            replyTo: pushNotificationGroup.get(ControlKeys.ReplyTo) as UfControl,
        };
    }

    get recipients(): UfControlArray {
        return this.root.get(ControlKeys.Recipients) as UfControlArray;
    }

    get canAddRecipient() {
        return this.root.get(ControlKeys.Bucket)?.disabled || !!this.bucket;
    }

    recipientControl(index: number): UfControlGroup {
        return this.recipients.at(index) as UfControlGroup;
    }

    recipientLabel(index: number): string {
        const recipientModel = this.recipientControl(index).getRawValue() as WorkflowNotificationRecipientsModel;
        if (recipientModel?.type === WorkflowNotificationRecipientType.FormData) {
            return this.formDataTypes.find(option => option.identifier === (recipientModel as WorkflowNotificationRecipientFormData)?.formData?.type)?.name ?? '';
        }
        return this.recipientTypes.find(o => o.identifier === recipientModel.type)?.name ?? '';
    }

    recipientTypeControl(index: number): UfControl {
        return (this.recipients.at(index) as UfControlGroup).get(ControlKeys.Type) as UfControl;
    }

    recipientEmailControl(index: number): UfControl {
        return (this.recipients.at(index) as UfControlGroup).get(ControlKeys.Email) as UfControl;
    }

    recipientClaimControl(index: number): UfControlGroup {
        return (this.recipients.at(index) as UfControlGroup).get(ControlKeys.Claim) as UfControlGroup;
    }

    recipientConditionsControls(index: number): UfControlArray {
        return (this.recipients.at(index) as UfControlGroup).get(ControlKeys.Conditions) as UfControlArray;
    }

    async removeRecipient(i: number) {
        if (!await this.dialogs.confirmDelete()) {
            return;
        }
        this.recipients.removeAt(i);
    }

    async ngOnInit() {
        this.subscriptions.push(this.route.paramMap.subscribe(params => {
            this.setup((params.get('type') as string) === 'user-management');
        }));
    }

    ngOnDestroy() {
        for (const subscription of this.subscriptions) {
            subscription.unsubscribe();
        }
    }

    addRecipient(option: Option) {
        const type = option.identifier as WorkflowNotificationRecipientType;
        let formDataType: WorkflowNotificationRecipientFormDataType;

        switch (type) {
            case WorkflowNotificationRecipientType.EmailField:
                formDataType = WorkflowNotificationRecipientFormDataType.Email; break;
            case WorkflowNotificationRecipientType.UserDatasource:
                formDataType = WorkflowNotificationRecipientFormDataType.User; break;
            case WorkflowNotificationRecipientType.UserDatasourceManager:
                formDataType = WorkflowNotificationRecipientFormDataType.UserManager; break;
            default:
                return this.recipients.push(this.formController.buildReceiptControl({ type } as any, this.isUserManagementNotification));
        }

        return this.recipients.push(
            this.formController.buildReceiptControl({
                type: WorkflowNotificationRecipientType.FormData,
                formData: {
                    type: formDataType,
                    value: ''
                }, liveOnly: false
            }, this.isUserManagementNotification)
        );
    }

    addCondition(type: WorkflowNotificationRecipientType, conditions: UfControlArray) {
        conditions.push(this.formController.addConditionControl({
            type
        } as any, this.isUserManagementNotification));
    }

    isFormDataUserDatasource(recipientGroupControl: UfControlGroup): boolean {
        const type = (recipientGroupControl.get(ControlKeys.FormData)?.get(ControlKeys.Type)?.getRawValue() as WorkflowNotificationRecipientFormDataType);
        return !!type && [
            WorkflowNotificationRecipientFormDataType.User,
            WorkflowNotificationRecipientFormDataType.UserManager
        ].includes(type);
    }

    isValueClaim(claimControlGroup: UfControlGroup) {
        const claimModel = claimControlGroup.getRawValue() as WorkflowNotificationRecipientClaimModel;
        return claimModel?.claim?.matchType != null && claimModel?.claim?.matchType === WorkflowNotificationRecipientClaimMatchType.Value;
    }

    isMatchAgainst(claimControlGroup: UfControlGroup) {
        const claimModel = claimControlGroup.getRawValue() as WorkflowNotificationRecipientClaimModel;
        return claimModel?.claim?.matchType != null &&
            [
                WorkflowNotificationRecipientClaimMatchType.CreatedBy,
                WorkflowNotificationRecipientClaimMatchType.LastModifiedBy

            ].includes(claimModel?.claim?.matchType);
    }

    async changeClaim(claimType: string, controlGroup: UfControlGroup) {
        if (!this.userClaims) {
            this.userClaims = await this.loadAndMapClaims();
        }

        const claimConfig = this.userClaims.find(userClaim => userClaim.type === claimType);
        controlGroup.get(ControlKeys.ClaimConfig)?.setValue(claimConfig);
    }

    async save() {
        this.error = null;
        this.root.setSubmitted();

        if (this.root.invalid) {
            return;
        }

        try {
            const notification = this.root.getRawValue();
            const notificationData = this.formController.toDataModel(notification);
            notificationData.type = ActivityType.Notification;

            let updatedNotification;
            if (notification.id) {
                updatedNotification = await this.ucWorkflow.updateActivity(notificationData);
                this.toastService.success('Workflow notification updated successfully');
                this.tableManager.updateItem.next(updatedNotification);
            }
            else {
                updatedNotification = await this.ucWorkflow.addActivity(notificationData);
                this.toastService.success('Workflow notification added successfully');
                this.tableManager.reload.next();
            }

            this.edited = false;
            this.router.navigate(['../'], { relativeTo: this.route });
        } catch (e) {
            this.error = e ?? { message: 'Error trying to save Workflow Notification' };
        }
    }

    async searchUsers(query?: string) {
        this.users = (await this.ucUsers.get(query, 'username'))
            .map(user => ({ id: user.id as string, username: user.username }));
    }

    searchClaim(query?: string) {
        this.filteredClaimDatasources = this.claimDatasources.filter(item => !query || item.toLowerCase().includes(query?.toLowerCase()));
    }

    searchFormDataClaim(query?: string) {
        this.filteredFormDataClaimDatasources = this.claimDatasources.filter(item => !query || item.toLowerCase().includes(query?.toLowerCase())).map(item => `${item}._id`);
    }

    async searchRole(query?: string) {
        this.filteredRoles = (await this.ucRoles.get(query)).map(r => r.name);
    }

    searchUserDatasource(query?: string) {
        this.filteredUserDatasources = this.userDatasources.filter(datasource => !query || datasource.toLowerCase().includes(query.toLowerCase()));
    }

    searchCompanyDatasource(query?: string) {
        this.filteredCompanyDatasources = this.companyDatasources.filter(company => !query || company.toLowerCase().includes(query.toLowerCase()));
    }

    searchEmailFields(query?: string) {
        this.filteredEmailFields = this.emailFields.filter(field => !query || field.name.toLowerCase().includes(query.toLowerCase()));
    }

    searchHierarchyField(query?: string) {
        this.filteredHierarchyFields = this.hierarchyFields.filter(field => !query || field.name.toLowerCase().includes(query.toLowerCase()));
    }

    async showGlossary(target: WorkflowNotificationDeliveryMethod) {
        const entry = await this.modalService.openMedium<GlossaryConfig, GlossaryEntry>(
            GlossaryComponent, {
            title: this.glossaryTitle,
            glossary: this.glossaryEntries
        });

        if (!entry) {
            return;
        }

        this.glossarySelected(target, entry);
    }

    glossarySelected(target: WorkflowNotificationDeliveryMethod, entry: GlossaryEntry) {

        if ((target === WorkflowNotificationDeliveryMethod.Email && !this.emailMarkdownEditor) ||
            (target === WorkflowNotificationDeliveryMethod.Push && !this.pushTextArea)) {
            return;
        }

        if (target === WorkflowNotificationDeliveryMethod.Email) {
            this.emailMarkdownEditor.insertCustom(entry.key + ' ');
            return;
        }

        const index = this.pushTextArea.caretPosition;
        const value = this.pushTextArea.value as string || '';

        if (this.pushTextArea.focused && index) {
            this.pushTextArea.value = value.substr(0, index) + entry.key + ' ' + value.substr(index);
        } else {
            this.pushTextArea.value = value + entry.key + ' ';
        }
    }

    async searchUserClaims(q?: string) {
        this.filteredUserClaims = (await this.ucUserClaims.list({ params: { q } })).map(({ type }) => type);
    }

    previewMessage(type: WorkflowNotificationDeliveryMethod) {
        let title;
        let message;
        switch (type) {
            case WorkflowNotificationDeliveryMethod.Email:
                title = this.email.title?.value;
                message = this.email.body?.value;
                break;
            case WorkflowNotificationDeliveryMethod.Push:
                title = this.pushNotification.title?.value;
                message = this.pushNotification.body?.value;
                break;

        }

        if (!title || !message) {
            return;
        }

        this.modalService.openAlert({
            title,
            message
        });
    }

    async searchBuckets(q: string) {
        const formBuckets = await this.ucProject.getFormBuckets({ params: { q } });
        this.bucketsResult = formBuckets.map(({ id }) => id.toString());
    }

    async bucketChange(bucket: string) {
        this.bucket = bucket ? await this.ucProject.getBucket(bucket) : undefined;
        this.loadFormDataFields();
    }

    private loadAndMapClaims() {
        return this.ucUserClaims.list();
    }

    private loadFormDataFields() {
        this.userDatasources = [];
        this.companyDatasources = [];
        this.emailFields = [];
        this.hierarchyFields = [];
        this.claimDatasources = [];

        if (!this.bucket) {
            return;
        }

        for (const field of this.bucket.fields) {
            if (field.type === FieldType.Lookup) {
                if (field.dataSourceConfig?.type === DataSourceType.Users) {
                    this.userDatasources.push(field.identifier);
                } else if (field.dataSourceConfig?.type === DataSourceType.Company) {
                    this.companyDatasources.push(field.identifier);
                } else if (!!field.dataSourceConfig?.type && [DataSourceType.UserClaims, DataSourceType.CompanyClaims].includes(field.dataSourceConfig?.type)) {
                    this.claimDatasources.push(field.identifier);
                }

                // Email fields inside lookups
                if (field.dataSourceConfig?.outputFields && field.dataSourceConfig?.outputs) {
                    const outputFields = (field.dataSourceConfig?.outputFields || {});
                    for (const key of Object.keys(outputFields)) {
                        const outputField = outputFields[key];
                        if (outputField.type === FieldType.Email) {
                            this.emailFields.push({ identifier: `${field.identifier}.${key}`, name: `${field.identifier}.${key}` });
                        }
                    }
                }

            } else if (field.type === FieldType.Email) {
                this.emailFields.push({ identifier: field.identifier, name: field.identifier });
            } else if (field.type === FieldType.Hierarchy) {
                this.hierarchyFields.push({ identifier: field.identifier, name: field.identifier });
            }
        }
    }

    private async setup(isUserManagement: boolean) {
        const { id, duplicate } = this.route.snapshot.params;

        let workflowNotification: WorkflowNotification | null = null;
        let formModel: WorkflowNotificationModel | null = null;

        try {
            if (id !== 'new') {
                workflowNotification = await this.ucWorkflow.getActivity<WorkflowNotification>(id);
                formModel = await this.formController.toFormModel(workflowNotification);

                this.isUserManagementNotification = !formModel.bucket;

                if (duplicate === 'true') {
                    formModel.id = undefined;
                    formModel.label += ' (copy)';
                }

                if (workflowNotification.bucket) {
                    this.bucket = await this.ucProject.getBucket(workflowNotification.bucket);
                }
            } else {
                this.isUserManagementNotification = isUserManagement;
            }

            this.loadFormDataFields();

            this.root = this.formController.buildRoot(formModel, this.isUserManagementNotification);

            this.recipientTypes = this.isUserManagementNotification ? WorkflowNotificationUserManagementRecipientOptions : WorkflowNotificationRecipientOptions;
            this.conditionTypes = this.isUserManagementNotification ? WorkflowNotificationUserManagentConditions : WorkflowNotificationConditions;
            this.claimMatchOptions = this.isUserManagementNotification ? WorkflowNotificationUserManagementClaimMatchTypes : WorkflowNotificationClaimMatchTypes;

            this.subscriptions.push(this.root.valueChanges.subscribe(_ => this.edited = true));
        } catch (e) {
            this.error = new Error(e.mesage ?? 'Failied to load workflow notification');
        }

        this.breadcrumbs = this.breadcrumbService.getBreadcrumbs(this.route, [formModel?.label ?? 'New']);
    }
}
