<div class="header-pane uf-app-bar transparent">
    <uf-breadcrumbs [breadcrumbs]="breadcrumbs" class="large">
    </uf-breadcrumbs>
    <button type="button" class="uf-button tertiary right" (click)="back()">Cancel</button>
    <button type="button" class="uf-button primary" (click)="continue()"
        [disabled]="assets.length === 0">Continue</button>
</div>
<div class="content-pane">
    <uf-panel class="container">
        <uf-media-list label="Drag and drop the files you wish to upload" (valueChange)="onContentChange($event)"
            [allowedFileTypes]="allowedAssets">
        </uf-media-list>
        <uf-blockquote *ngIf="disabled" class="warning" icon="warningSolid" heading="Upload Unavailable"
            content="No file types allowed in tenant settings">
        </uf-blockquote>
    </uf-panel>
</div>