<div [formGroup]="filters">
    <!-- CONDITION FILTERS -->

    <div class="uf-box flat gap-top gap-sides">
        <div class="uf-app-bar accent flat">
            <div class="title">
                Condition Filters
            </div>
        </div>

        <div class="uf-grid pad-sides gap-top gap-bottom">

            <!-- Group Filter -->
            <uf-autocomplete
                *ngIf="filters.get(controlKeys.Group) | asSafeAbstractControl | asUfControl; let groupControl"
                placeholder="Select a source" label="Group" [formControlName]="controlKeys.Group"
                (searchChange)="findSourceGroups($event)" (valueChange)="changeGroup(groupControl, $event)"
                [allowCustom]="true" [options]="filteredSourceGroups" nameProperty="name" class="col-12">
            </uf-autocomplete>

            <!-- Claim Value -->
            <uf-expander [isExpanded]="false" class="col-12">
                <div expanderHeader class="uf-app-bar flat">
                    <div class="title">
                        Has Claim
                    </div>
                </div>
                <div expanderBody class="uf-grid uf-box pad">
                    <uf-autocomplete label="Claim" [formControlName]="controlKeys.ConditionClaimIdentifier"
                        (searchChange)="findSourceClaims($event)" [options]="filteredSourceClaims"
                        placeholder="Select a source" [allowCustom]="true" class="col-6">
                    </uf-autocomplete>
                    <uf-text label="Claim Value" [formControlName]="controlKeys.ConditionClaimValue" class="col-6">
                    </uf-text>
                </div>
            </uf-expander>

            <!-- Role Assigned -->
            <uf-role-input *ngIf="type === authProviderTypes.Auth0" [formControlName]="controlKeys.RolesAssigned"
                label="Roles" class="col-12">
            </uf-role-input>

        </div>
    </div>

    <div class="uf-box flat gap-top gap-sides">
        <div class="uf-app-bar accent flat">
            <div class="title">
                Action Filters
            </div>
        </div>

        <div class="uf-grid pad">
            <!-- ACTION FILTERS -->

            <!-- Hierarchy Filter -->
            <uf-expander [isExpanded]="false" class="col-12">
                <div expanderHeader class="uf-app-bar flat">
                    <div class="title">
                        Hierarchy
                    </div>
                </div>
                <div expanderBody class="uf-box pad">
                    <uf-hierarchy-unit-selector [control]="hierarchyControl" [(value)]="hierarchyValue"
                        class="col-12"></uf-hierarchy-unit-selector>
                </div>
            </uf-expander>

            <!-- System Role -->
            <uf-chips placeholder="Select a System Role" label="System Role"
                [formControlName]="controlKeys.ActionSystemRoles" (searchChange)="findSystemRoles($event)"
                [options]="filteredSystemRoles" class="col-6"></uf-chips>

            <!-- Role -->
            <uf-role-input [formControlName]="controlKeys.ActionRoles" label="Roles" class="col-6">
            </uf-role-input>

            <!-- AssignClaimFrom -->
            <uf-expander [isExpanded]="false" class="col-12">
                <div expanderHeader class="uf-app-bar flat">
                    <div class="title">
                        Assign Claim From
                    </div>
                </div>
                <div expanderBody class="uf-grid  uf-box pad">
                    <uf-autocomplete label="Claim To" [formControlName]="controlKeys.ActionClaimTo"
                        (searchChange)="findSourceClaimsTo($event)" [options]="filteredSourceClaimsTo"
                        [allowCustom]="true" nameProperty="type" placeholder="Select a claim" class="col-6">
                    </uf-autocomplete>
                    <uf-autocomplete label="Claim From" [formControlName]="controlKeys.ActionClaimFrom"
                        (searchChange)="findSourceClaims($event)" [options]="filteredSourceClaims" [allowCustom]="true"
                        nameProperty="type" placeholder="Select a claim" class="col-6">
                    </uf-autocomplete>
                </div>
            </uf-expander>

            <!-- Claim Value -->
            <uf-expander [isExpanded]="false" class="col-12">
                <div expanderHeader class="uf-app-bar flat">
                    <div class="title">
                        Assign Claim Value
                    </div>
                </div>
                <div expanderBody class="uf-grid uf-box pad">
                    <uf-autocomplete label="Claim" [formControlName]="controlKeys.ActionClaimIdentifier"
                        (searchChange)="findSourceClaimsTo($event)" [options]="filteredSourceClaimsTo"
                        placeholder="Select a source" [allowCustom]="true" class="col-6">
                    </uf-autocomplete>
                    <uf-text label="Claim Value" [formControlName]="controlKeys.ActionClaimValue" class="col-6">
                    </uf-text>
                </div>
            </uf-expander>

        </div>
    </div>

    <div class="uf-form-actions">
        <button (click)="clearFilter()" type="button" class="uf-button x-small right">
            Clear
            <uf-icon name="delete"></uf-icon>
        </button>
    </div>

</div>