<div class="overlay-container">
    <div class="header-pane">
        <uc-builder-header>
        </uc-builder-header>
    </div>
    <div class="content-pane">
        <div *ngIf="error" class="uf-container">
            <uf-message icon="errorSolid" class="error">
                <p>{{error}}</p>
                <button [routerLink]="['../../']" type="button" class="uf-button primary right">
                    Back
                </button>
            </uf-message>
        </div>
        <uf-panel *ngIf="form" class="container">
            <uc-auth-provider-detail [form]="form" (deleteClicked)="delete()" (activateClicked)="activate()"
                (deactivateClicked)="deactivate()"></uc-auth-provider-detail>
            <uc-auth-provider-mappings *ngIf="mappingsControl" [form]="mappingsControl"
                [config]="config"></uc-auth-provider-mappings>
        </uf-panel>
        <button (click)="addProviderValue()" type="button" class="uf-action primary large fixed">
            <uf-icon name="add"></uf-icon>
        </button>
    </div>
</div>