import { TableDataSource, TableDataSourceQueryOptions } from '@unifii/library/common';
import { RequestAnalyticsOrigin } from '@unifii/sdk';

import { DataSourceInfo, UcDataSources } from 'client';


export class DataSourcesDataSource extends TableDataSource<DataSourceInfo> {

    sorted: boolean;

    constructor(
        private service: UcDataSources,
        private search?: string,
        private status?: string
    ) {
        super();
    }

    get filtered(): boolean {
        return (this.search && this.search.trim().length > 0) || this.status != null;
    }

    load(options?: TableDataSourceQueryOptions) {

        this.service.list(this.search, { analytics: { origin: RequestAnalyticsOrigin.Table } }).then(data => {
            this.stream.next({ data: data.slice(options?.offset, (options?.offset ?? 0) + (options?.limit ?? 10)) });
        }, error => {
            this.stream.next({ error });
        });
    }

}
