import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import { FilterEntries, ModalService } from '@unifii/library/common';

import { ActivityType, UcProject } from 'client';

import { TypeSelectComponent, TypeSelectConfig, TypeSelectOption } from 'components/common/type-select.component';

import { ActivityTypeToken, WorkflowActivityTableManager } from 'pages/workflows/workflow-activity-table-manager';
import { activityFilterFactory } from 'pages/workflows/workflows-filters';


@Component({
    selector: 'uc-workflow-notification',
    template: `
        <div ufMasterDetail>
            <uf-table-container ufSyncRoute (addItem)="add()" class="accent pad-none list-md"></uf-table-container>
        </div>
        <router-outlet></router-outlet>
    `,
    providers: [
        { provide: FilterEntries, useFactory: activityFilterFactory, deps: [UcProject] },
        { provide: ActivityTypeToken, useValue: ActivityType.Notification },
        { provide: TableContainerManager, useClass: WorkflowActivityTableManager }
    ],
})
export class WorkflowNotificationsComponent {

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private modalService: ModalService
    ) {
    }

    async add() {

        const choice = await this.modalService.openMedium<TypeSelectConfig, TypeSelectOption>(
            TypeSelectComponent, {
            title: 'Select a notification type',
            types: [{
                type: '',
                label: 'Workflow',
            },
            {
                type: 'user-management',
                label: 'User Management',
            }]
        });

        if (!choice) {
            return;
        }
        this.router.navigate(['new', { type: choice.type }], { relativeTo: this.route });
    }

}
