import { USER_PREFERENCES_STORAGE_KEY } from 'constant';

import { Inject, Injectable } from '@angular/core';
import { StorageWrapper, TablePreferences, TablePreferencesProvider } from '@unifii/library/common';
import { Dictionary } from '@unifii/sdk';

import { ConfigService } from './config.service';
import { ContextService } from './context.service';


export interface UserPreferences {
    tables: Dictionary<TablePreferences>;
}

@Injectable()
export class UserPreferencesService implements TablePreferencesProvider {

    constructor(
        private context: ContextService,
        private configService: ConfigService,
        @Inject(StorageWrapper) private storage: StorageWrapper
    ) { }

    get userKey() {
        return `${USER_PREFERENCES_STORAGE_KEY}-${this.configService.tenant}-${this.context.account?.id}`;
    }

    get userPreferences(): UserPreferences {

        const raw: string = this.storage.getItem(this.userKey);

        if (!raw) {
            return {
                tables: {}
            } as UserPreferences;
        }

        return JSON.parse(raw);
    }

    set userPreferences(v: UserPreferences) {

        if (v == null) {
            this.storage.removeItem(this.userKey);
            return;
        }

        this.storage.setItem(this.userKey, JSON.stringify(v));
    }

    loadTablePreferences(id: string): TablePreferences | null {
        return this.userPreferences.tables[id];
    }

    saveTablePreferences(id: string, v?: TablePreferences) {

        const preferences = this.userPreferences;

        if (v != null) {
            preferences.tables[id] = v;
        } else {
            delete preferences.tables[id];
        }

        this.userPreferences = preferences;
    }
}
