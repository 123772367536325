<uf-panel class="container" *ngIf="!isNew">
    <div class="header-pane uf-app-bar transparent">
        <uf-breadcrumbs [breadcrumbs]="breadcrumbs" class="large">
            <span *ngIf="edited">*</span>
        </uf-breadcrumbs>
    </div>
    <div *ngIf="id" class="content-pane">
        <uf-tabs>
            <uf-tab label="Settings" path=""></uf-tab>
            <uf-tab label="Devices" path="devices"></uf-tab>
            <uf-tab label="Send Notification" path="send-notification"></uf-tab>
        </uf-tabs>
    </div>
</uf-panel>

<router-outlet *ngIf="isNew"></router-outlet>