import { Subject } from 'rxjs';

import { Injectable } from '@angular/core';
import { TableContainerManager } from '@unifii/components';
import { FilterEntry, FilterValue, TableConfig } from '@unifii/library/common';
import { AuthProvider } from '@unifii/sdk';

import { AuthProviderDetails, UcAuthProviders } from 'client';

import { AuthProvidersDatasource } from './auth-providers-datasource';
import { AuthProviderTypeDescription } from './models';


@Injectable()
export class AuthProvidersTableManager implements TableContainerManager<AuthProviderDetails, FilterValue, FilterEntry> {

    tableConfig: TableConfig<AuthProviderDetails>;
    addActionConfig = true;
    reload = new Subject<void>();

    constructor(private ucAuthProviders: UcAuthProviders) {
        this.tableConfig = {
            columns: [{
                name: 'providerLoginLabel',
                label: 'Provider',
                value: item => item.providerLoginLabel ?? item.type
            }, {
                name: 'tenant',
                label: 'Tenant'
            }, {
                name: 'type',
                label: 'Type',
                value: item => item.manual ? 'Manual' : 'Automatic'
            }, {
                name: 'status',
                label: 'Status',
                value: item => item.isActive ? 'Active' : 'Inactive'
            }],
            row: {
                image: item => `assets/svg/${item.type?.toLocaleLowerCase()}.svg`,
                label: item => AuthProviderTypeDescription[item.type],
                link: item => [this.getTypeRouteParam(item.type), item.id]
            },
            pageSize: -1
        };
    }

    createDataSource() {
        return new AuthProvidersDatasource(this.ucAuthProviders);
    }

    private getTypeRouteParam(type: AuthProvider): string {
        switch (type) {
            case AuthProvider.Auth0: return 'auth0';
            case AuthProvider.Azure: return 'azure';
            case AuthProvider.Okta: return 'okta';
        }
    }
}