import { Component, HostBinding } from '@angular/core';


@Component({
    templateUrl: './template-form.html'
})
export class TemplateFormComponent {

    @HostBinding('class.stretch-component') class = true;

}

