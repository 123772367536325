import { Component, HostBinding, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import { ModalService, ToastService } from '@unifii/library/common';
import { CompoundType, Definition, FieldType, FormData, ValidatorType } from '@unifii/sdk';

import { ApiKey, UcAPIKeys } from 'client';

import { ModalFormComponent, ModalFormData } from 'components/content/modals/modal-form.component';

import { APIKeysTableManager } from './api-keys-table-manager';


@Component({
    template: `
        <div ufMasterDetail>
            <uf-table-container (addItem)="addItem()" class="accent list-md pad-none"></uf-table-container>
        </div>
        <router-outlet></router-outlet>
    `,
    providers: [
        { provide: TableContainerManager, useClass: APIKeysTableManager }
    ]
})
export class APIKeysComponent {

    @HostBinding('class.stretch-component') class = true;

    newApiKey: ApiKey | null;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private modalService: ModalService,
        private toastService: ToastService,
        private ucAPIKeys: UcAPIKeys,
        @Inject(TableContainerManager) private tableManager: APIKeysTableManager
    ) {
    }

    async addItem() {
        const data: ModalFormData = {
            title: 'New API Key',
            actionText: 'Create',
            definition: this.apiKeyFormDefinition
        };

        const formData = await this.modalService.openMedium<ModalFormData, FormData>(ModalFormComponent, data);
        if (!formData) {
            return;
        }

        try {
            const apiKey: ApiKey = {
                name: formData.name,
                createdBy: undefined as any,
                createdAt: undefined as any
            };

            if (formData.enableOAuth === true) {
                apiKey.enableOAuth = true;
            }

            this.newApiKey = await this.ucAPIKeys.save(apiKey);
            this.toastService.success('API Key added');
            this.tableManager.reload.next();
            this.router.navigate([`${this.newApiKey.key}`], { relativeTo: this.route });

        } catch (error) {
            this.toastService.error(error.message);
        }
    }

    private get apiKeyFormDefinition(): Definition {
        return {
            identifier: null as any,
            label: null as any,
            compoundType: CompoundType.Form,
            fields: [{
                identifier: 'name',
                label: 'Name',
                type: FieldType.Text,
                isRequired: true,
                validators: [{ type: ValidatorType.Pattern, value: `^[A-Za-z][A-Za-z0-9_ ]*$`, message: `Name contains an invalid character` }]
            }, {
                identifier: 'enableOAuth',
                label: 'Enable for OAuth 2 Client Credentials Flow',
                type: FieldType.Bool
            }
            ]
        };
    };
}
