import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilterChoiceInfo, FilterViewMode } from '@unifii/library/common';
import { Option } from '@unifii/sdk';


export interface CustomChoiceFilterEntry extends FilterChoiceInfo {
    emptyOption: Option;
}


/**
 * @description
 * ChoiceFilterComponent contains and empty option which is selected
 * when the filter has a null value
 */
@Component({
    selector: 'uc-custom-choice-filter',
    templateUrl: './custom-choice-filter.html'
})
export class CustomChoiceFilterComponent implements OnInit {

    @Input() mode: FilterViewMode = FilterViewMode.Input;
    @Input() entry?: CustomChoiceFilterEntry;
    @Input() value: string | null = null;
    @Output() valueChange: EventEmitter<string> = new EventEmitter();

    options: Option[] = [];

    get label(): string {
        return this.entry?.label ?? '';
    }

    get isInput(): boolean {
        return this.mode === FilterViewMode.Input;
    }

    get isDisplay(): boolean {
        return this.mode === FilterViewMode.Display;
    }

    get isEmpty(): boolean {
        return this.value == null;
    }

    get displayValue(): string | null {
        if (this.entry?.options) {
            const option = this.entry.options.find(o => o.identifier === this.value);
            if (option) {
                return option.name;
            }
        }
        return this.value;
    }

    remove() {
        this.value = null;
        this.valueChanged();
    }

    ngOnInit() {
        if (this.entry == null) {
            return;
        }
        this.options = [this.entry.emptyOption, ...this.entry.options];
    }

    valueChanged(value?: string) {
        if (value === this.entry?.emptyOption.identifier) {
            value = undefined;
        }
        this.valueChange.emit(value);
    }

}
