import { Subscription } from 'rxjs';

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import {
    Breadcrumb, ClipboardService, ToastService, UfControl, UfControlArray, UfControlGroup, UfFormBuilder, ValidatorFunctions
} from '@unifii/library/common';
import { Error, ErrorType, FieldType, Option } from '@unifii/sdk';

import { ClaimsClient, ClaimSource, UcClaimConfig, UcClient } from 'client';

import { EditData } from 'components/common/edit-data';

import { camelize, pascalize } from 'helpers/field-identifier-helper';

import { BreadcrumbService } from 'services/breadcrumb.service';
import { DialogsService } from 'services/dialogs.service';

import { ClaimTableManager } from './claim-table-manager';


enum ClaimControlKeys { Type = 'type', ValueType = 'valueType', Label = 'label', IsRequired = 'isRequired', Options = 'options', Id = 'id', Searchable = 'isSearchable' }
enum ClaimOptionControlKeys { Id = 'id', Display = 'display' }

const SearchableFieldTypes = [FieldType.Text, FieldType.TextArray, FieldType.Phone, FieldType.Email, FieldType.Website];

@Component({
    templateUrl: './claim-detail.html'
})
export class ClaimDetailComponent implements OnInit, OnDestroy, EditData {

    readonly claimControlKeys = ClaimControlKeys;
    readonly claimOptionControlKeys = ClaimOptionControlKeys;
    readonly fieldType = FieldType;
    readonly valueTypeOptions: Option[] = [
        { identifier: FieldType.Text, name: 'Text' },
        { identifier: FieldType.Number, name: 'Number' },
        { identifier: FieldType.Phone, name: 'Phone' },
        { identifier: FieldType.Email, name: 'Email' },
        { identifier: FieldType.Website, name: 'Website' },
        { identifier: FieldType.Date, name: 'Date' },
        { identifier: FieldType.DateTime, name: 'Date Time' },
        { identifier: FieldType.Bool, name: 'Bool' },
        { identifier: FieldType.Choice, name: 'Choice' },
        { identifier: FieldType.MultiChoice, name: 'Multi Choice' },
        { identifier: FieldType.TextArray, name: 'Text Array' }
    ];

    breadcrumbs: Breadcrumb[];
    form: UfControlGroup;
    error: Error;
    edited = false;

    private claimId?: string;
    private claimSource: ClaimSource;
    private claimClient: ClaimsClient;
    private duplicate = false;
    private unsavedOptions: UfControlGroup[] = []; // References of newly added options so id's can be autogenerated
    private claims: UcClaimConfig[];
    private subscriptions = new Subscription();

    constructor(
        private route: ActivatedRoute,
        private ufb: UfFormBuilder,
        private ucClient: UcClient,
        private toast: ToastService,
        private clipboard: ClipboardService,
        private dialogs: DialogsService,
        private breadcrumbService: BreadcrumbService,
        @Inject(TableContainerManager) private tableManager: ClaimTableManager
    ) {
        this.claimSource = this.route.snapshot.data.claimSource;
        this.duplicate = this.route.snapshot.params.duplicate === 'true';
        this.claimClient = new ClaimsClient(this.ucClient, this.claimSource);
        this.claimId = this.getClaimId(this.route);
    }

    async ngOnInit() {
        try {

            let claim = await this.getClaim(this.claimId);
            if (this.duplicate) {
                claim = this.duplicateClaim(claim);
                delete (claim as any).id;
            }

            this.form = this.getRootControl(claim);

            this.breadcrumbs = this.getBreadcrumbs(this.claimSource, claim, false);
            this.subscriptions.add(this.form.statusChanges.subscribe(() => {
                this.edited = !this.form.pristine;

                // Update claim with current data
                claim = Object.assign(claim, this.claim);
                this.breadcrumbs = this.getBreadcrumbs(this.claimSource, claim, !this.form.pristine);
            }));

            if (!this.claimId || this.duplicate) {
                this.claims = await this.claimClient.list();
            }

        } catch (e) {
            console.error(e);
            this.error = this.getError(e, 'Failed to load claim');
        }
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    addOption() {
        const controlGroup = this.getOptionControl({ id: '', display: '' });
        this.unsavedOptions.push(controlGroup);
        this.options.push(controlGroup);
        this.form.markAsDirty();
    }

    async removeOption(i: number, option: UfControlGroup) {
        if (!await this.dialogs.confirmDelete()) {
            return;
        }
        this.options.removeAt(i);
        this.form.markAsDirty();

        this.unsavedOptions = this.unsavedOptions.filter(o => o !== option);
    }

    async paste() {
        const text = await this.clipboard.getText() ?? '';
        try {
            const json = JSON.parse(text);
            delete json.id;
            // the options need to be added separately so the values can be patched.
            if (json.options) {
                this.options.controls.push(...json.options.map((option: any) => this.getOptionControl(option)));
            }
            this.form.patchValue(json);
        } catch (e) {
            console.error(e);
        }
    }

    async save() {

        this.form.setSubmitted();

        if (this.form.invalid) {
            return;
        }

        let claim = this.mapClaim(this.claim);

        try {
            if (claim.id == null) {
                claim = await this.claimClient.add(claim);
                // update id attribute on form
                this.form.patchValue(claim);
            } else {
                claim = await this.claimClient.update(claim.id, claim);
            }

            this.unsavedOptions = [];
            this.form.markAsPristine();
            this.form.updateValueAndValidity();
            this.toast.success('Claim saved');

            this.breadcrumbs = this.getBreadcrumbs(this.claimSource, claim, false);
            this.tableManager.reload.next();
        } catch (e) {
            this.toast.error(e.message || 'Failed to save');
        }
    }

    valueTypeChange(type: FieldType) {
        if (this.claim.valueType !== type && this.claim.valueType !== type) {
            this.options.reset();
            this.options.clear();
        }

        if (!SearchableFieldTypes.includes(type)) {
            this.searchableControl?.setValue(null);
            this.searchableControl?.disable();
        } else {
            this.searchableControl.enable();
        }
    }

    changeIdentifier(value: string) {
        if (!this.claim.id && this.form.controls[ClaimControlKeys.Type].untouched) {
            this.form.controls[ClaimControlKeys.Type].setValue(camelize(value));
        }
    }

    optionLabelChange(value: string, controlGroup: UfControlGroup) {
        if (this.unsavedOptions.includes(controlGroup) && controlGroup.get(ClaimOptionControlKeys.Id)?.untouched) {
            controlGroup.get(ClaimControlKeys.Id)?.setValue(pascalize(value));
        }
    }

    get claim(): UcClaimConfig {
        return this.form.getRawValue();
    }

    get typeControl(): UfControl {
        return this.form.get(ClaimControlKeys.Type) as UfControl;
    }

    get valueTypeControl(): UfControl {
        return this.form.get(ClaimControlKeys.ValueType) as UfControl;
    }

    get searchableControl(): UfControl {
        return this.form.get(ClaimControlKeys.Searchable) as UfControl;
    }

    get options(): UfControlArray {
        return this.form.get(ClaimControlKeys.Options) as UfControlArray;
    }

    get optionControls(): UfControlGroup[] {
        return (this.form.get(ClaimControlKeys.Options) as UfControlArray).controls as UfControlGroup[];
    }

    private getBreadcrumbs(claimSource: ClaimSource, claim: UcClaimConfig, edited: boolean): Breadcrumb[] {
        const parentName = claimSource === ClaimSource.User ? 'User Claims' : 'Company Claims';

        const name = !!claim.type ? claim.type : 'New claim';

        return this.breadcrumbService.getBreadcrumbs(this.route, [parentName, name]);
    }

    private getRootControl(claim: UcClaimConfig): UfControlGroup {
        return this.ufb.group({
            [ClaimControlKeys.Id]: [{ value: claim.id, disabled: true }],
            [ClaimControlKeys.Type]: [{ value: claim.type, disabled: claim.id != null }, ValidatorFunctions.compose([ValidatorFunctions.required('Identifier is required'), ValidatorFunctions.alphanumeric('Identifier can only contain alphanumeric values'), ValidatorFunctions.custom(this.validateExistingIdentifier.bind(this), 'Identifier must be unique')])],
            [ClaimControlKeys.ValueType]: [{ value: claim.valueType ?? FieldType.Text, disabled: claim.id != null }, ValidatorFunctions.required('Type is required')],
            [ClaimControlKeys.Label]: [claim.label, ValidatorFunctions.required('Label is required')],
            [ClaimControlKeys.IsRequired]: [claim?.isRequired],
            [ClaimControlKeys.Searchable]: [{ value: claim?.isSearchable, disabled: !SearchableFieldTypes.includes(claim.valueType) }],
            [ClaimControlKeys.Options]: this.ufb.array((claim.options ?? []).map(this.getOptionControl.bind(this)))
        });
    }

    private validateExistingIdentifier(identifier: string) {
        return !(this.claims ?? []).some(c => c.type.toLowerCase() === identifier.toLowerCase());
    }

    private async getClaim(id?: string): Promise<UcClaimConfig> {
        if (id) {
            return this.claimClient.get(id);
        }
        // provide empty claim
        return {
            id: null as any,
            type: '',
            valueType: FieldType.Text,
            label: '',
            options: []
        };
    }

    private getClaimId(route: ActivatedRoute): string | undefined {
        const id = route.snapshot.params.claimId;
        if (id !== 'new') {
            return id;
        }
        return;
    }

    private getOptionControl(option: { id: string; display: string }): UfControlGroup {
        const displayControl = this.ufb.control(option.display, ValidatorFunctions.required('Label is required'));
        const idControl = this.ufb.control(option.id, ValidatorFunctions.compose([
            ValidatorFunctions.required('Identifier is required'),
            ValidatorFunctions.alphanumeric('Identifier can only contain alphanumeric values')
        ]));

        return this.ufb.group({
            [ClaimOptionControlKeys.Id]: idControl,
            [ClaimOptionControlKeys.Display]: displayControl
        });
    }

    private getError(e: any, fallbackMessage: string): Error {
        if (!e?.message) {
            return {
                message: fallbackMessage,
                type: ErrorType.Unknown
            };
        }
        return e;
    }

    private duplicateClaim(claim: UcClaimConfig): UcClaimConfig {
        return {
            id: null as any,
            type: claim.type + '_COPY',
            valueType: claim.valueType,
            label: claim.label,
            options: claim.options
        };
    }

    private mapClaim(claim: UcClaimConfig) {
        const { valueType } = claim;
        if (valueType !== FieldType.Choice && valueType !== FieldType.MultiChoice) {
            delete claim.options;
        }

        if (!SearchableFieldTypes.includes(valueType)) {
            delete claim.isSearchable;
        }

        return claim;
    }


}
