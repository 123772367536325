import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { UfControlValueAccessor } from '@unifii/library/common';


@Component({
    selector: 'uc-secret-input',
    templateUrl: './secret-input.html',
    styleUrls: ['./secret-input.less'],
    providers: [{
        provide: NG_VALUE_ACCESSOR, useExisting: SecretInputComponent, multi: true
    }]
})
export class SecretInputComponent extends UfControlValueAccessor<string> implements OnInit {

    @Input() label?: string;
    @Input() help?: string;

    @Output() valueChange: EventEmitter<any> = new EventEmitter();

    edit = true;
    initialValue: string | null = null;

    ngOnInit() {

        if (this.value) {
            this.initialValue = this.value;
            this.edit = false;
        }
    }

    toggleEdit() {

        this.edit = !this.edit;

        if (!this.edit) {
            this.control.setValue(this.initialValue);
            this.control.disable();
        } else {
            this.control.setValue('');
            this.control.enable();
        }
    }

}
