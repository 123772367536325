import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { Inject, Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import {
    ControlAccessor, DataPropertyDescriptor, DataPropertyInfoService, ExpressionParser, SortStatus, ToastService, UfControl, UfControlArray,
    UfControlGroup, UfFormBuilder, UfFormControl, ValidatorFunctions
} from '@unifii/library/common';
import {
    DataSource, DataSourceType, Dictionary, FieldTemplate, FieldType, FieldValidator, FieldWidth, LinkContentType, SchemaField, SchemaTransition,
    ValidatorType
} from '@unifii/sdk';

import { DefinitionInfo } from 'client';

import { IdentifierFunctions } from 'helpers/helpers';

import { ContextService } from 'services/context.service';
import { LimitService } from 'services/limit.service';

import { DataSourceValidator } from './data-source-validatior';
import { FormEditorCache } from './form-editor-cache';
import { FORM_EDITOR_CONSTANTS } from './form-editor-constants';
import {
    DefinitionControlKeys, FieldControlKeys, FieldMappingControlKeys, HierarchyConfigControlKeys, NestedControlKey, OptionControlKeys,
    TransitionControlKeys, TransitionTargetControlKeys, ValidatorControlKeys, VariationControlKeys
} from './form-editor-control-keys';
import { FormEditorFieldScopeManager } from './form-editor-field-scope-manager';
import { FormEditorFunctions } from './form-editor-functions';
import {
    AttributeParentType, FormAddressNestedFields, FormEditorDefinition, FormEditorField, FormEditorFieldMapping, FormEditorHierarchyConfiguration,
    FormEditorOption, FormEditorTransition, FormEditorTransitionTarget, FormEditorVariation, FormFieldMetadata, FormGeoLocationNestedFields,
    FormNestedField
} from './form-editor-model';
import { FormEditorStatus } from './form-editor-status';


@Injectable()
export class FormEditorFormCtrl {

    readonly setSubmitted = true;

    private fieldsSubscriptions: Map<string, Subscription[]> = new Map();

    constructor(
        private formBuilder: UfFormBuilder,
        private status: FormEditorStatus,
        private fieldsScopeManager: FormEditorFieldScopeManager,
        private limitService: LimitService,
        private toast: ToastService,
        private context: ContextService,
        private expressionParser: ExpressionParser,
        @Inject(FormEditorCache) private cache: FormEditorCache,
        private dataPropertyInfoService: DataPropertyInfoService
    ) {
        // Necessary for external usage of Definition controls builderFunctions
        this.fieldsSubscriptions.set(FORM_EDITOR_CONSTANTS.DEFINITION_SCOPE_UUID, []);
    }

    destroy() {
        for (const subscriptions of Array.from(this.fieldsSubscriptions.values())) {
            for (const sub of subscriptions) {
                sub.unsubscribe();
            }
        }
    }

    onFieldRemoved(control: UfControlGroup) {
        const uuid = control.get(FieldControlKeys.Uuid)?.value as string;
        for (const sub of this.fieldsSubscriptions.get(uuid) ?? []) {
            sub.unsubscribe();
        }
        this.fieldsSubscriptions.delete(uuid);

        const fields = (control.get(FieldControlKeys.Fields) as UfControlArray)?.controls as UfControlGroup[] | undefined;
        if (fields) {
            for (const field of fields) {
                this.onFieldRemoved(field);
            }
        }
    }

    buildRoot(definition: FormEditorDefinition): UfControlGroup {

        this.fieldsSubscriptions.set(FORM_EDITOR_CONSTANTS.DEFINITION_SCOPE_UUID, []);

        const lastPublishedAtControl = this.buildDefinitionLastPublishedControl(definition.lastPublishedAt);
        const identifierControl = this.buildDefinitionIdentifierControl(lastPublishedAtControl, definition.identifier);
        const bucketControl = this.buildDefinitionBucketControl(lastPublishedAtControl, definition.bucket);
        const labelControl = this.buildDefinitionLabelControl(lastPublishedAtControl, identifierControl, bucketControl, definition.label);
        const hasRollingVersionControl = this.buildDefinitionHasRollingVersionControl(definition.hasRollingVersion);

        const rootControl = this.formBuilder.group({
            [DefinitionControlKeys.Id]: definition.id,
            [DefinitionControlKeys.CompoundType]: definition.compoundType,
            [DefinitionControlKeys.Label]: labelControl,
            [DefinitionControlKeys.Identifier]: identifierControl,
            [DefinitionControlKeys.Bucket]: bucketControl,
            [DefinitionControlKeys.SequenceNumberFormat]: definition.sequenceNumberFormat,
            [DefinitionControlKeys.State]: definition.state,
            [DefinitionControlKeys.PublishState]: definition.publishState,
            [DefinitionControlKeys.LastPublishedAt]: lastPublishedAtControl,
            [DefinitionControlKeys.LastPublishedBy]: this.formBuilder.control(definition.lastPublishedBy),
            [DefinitionControlKeys.LastModifiedAt]: definition.lastModifiedAt,
            [DefinitionControlKeys.LastModifiedBy]: this.formBuilder.control(definition.lastModifiedBy),
            [FieldControlKeys.Fields]: this.buildFieldsControl(definition.fields),
            [DefinitionControlKeys.Settings]: this.formBuilder.group({
                optionalSuffix: definition.settings?.optionalSuffix,
                requiredSuffix: definition.settings?.requiredSuffix,
                inputStyle: definition.settings?.inputStyle
            }),
            [DefinitionControlKeys.Tags]: this.buildTagsControl(FORM_EDITOR_CONSTANTS.DEFINITION_SCOPE_UUID, definition.tags),
            [DefinitionControlKeys.ReportableMetaFields]: this.formBuilder.control(definition.reportableMetaFields),
            [DefinitionControlKeys.Version]: definition.version,
            [DefinitionControlKeys.HasRollingVersion]: hasRollingVersionControl
        });

        rootControl.updateDependencies();

        if (this.setSubmitted) {
            rootControl.setSubmitted(true);
        }

        return rootControl;
    }

    buildDefinitionLastPublishedControl(lastPublishedAt?: string): UfControl {
        return this.formBuilder.control(lastPublishedAt);
    }

    buildDefinitionIdentifierControl(lastPublishedAtControl: UfControl, identifier?: string): UfControl {
        const control = this.formBuilder.control(identifier, ValidatorFunctions.compose([
            ValidatorFunctions.required('Identifier is required'),
            ValidatorFunctions.pattern(FORM_EDITOR_CONSTANTS.DEFINITION_IDENTIFIER_REGEX, 'Identifier contains invalid characters'),
            ValidatorFunctions.noWhiteSpaces(`Can't contains white spaces`),
            ValidatorFunctions.maxLength(this.status.identifiersMaxLength.definition, `Identifier must be ${this.status.identifiersMaxLength.definition} or less`)
        ]));

        if (lastPublishedAtControl.value != null) {
            control.disable();
        }

        this.fieldsSubscriptions.get(FORM_EDITOR_CONSTANTS.DEFINITION_SCOPE_UUID)?.push(
            control.valueChanges.subscribe(() => this.checkIdentifierEditWarning(lastPublishedAtControl))
        );

        return control;
    }

    buildDefinitionBucketControl(lastPublishedAtControl: UfControl, bucket?: string): UfControl {
        const control = this.formBuilder.control(bucket, ValidatorFunctions.compose([
            ValidatorFunctions.required('Form Data Repository is required'),
            ValidatorFunctions.pattern(FORM_EDITOR_CONSTANTS.DEFINITION_BUCKET_REGEX, 'Form Data Repository contains invalid characters'),
            ValidatorFunctions.maxLength(this.status.identifiersMaxLength.bucket, `Form Data Repository must be ${this.status.identifiersMaxLength.bucket} or less`)
        ]));

        this.fieldsSubscriptions.get(FORM_EDITOR_CONSTANTS.DEFINITION_SCOPE_UUID)?.push(
            control.valueChanges.subscribe(() => this.checkBucketEditWarning(lastPublishedAtControl))
        );

        return control;
    }

    buildDefinitionLabelControl(lastPublishedAtControl: UfControl, identifierControl: UfControl, bucketControl: UfControl, label?: string, generateValues?: boolean): UfControl {
        const control = this.formBuilder.control(label, ValidatorFunctions.required('Label is required'));

        if (generateValues) {
            this.fieldsSubscriptions.get(FORM_EDITOR_CONSTANTS.DEFINITION_SCOPE_UUID)?.push(
                control.valueChanges.subscribe(v => {
                    const kebabizedLabel = IdentifierFunctions.kebabize(v).substring(0, IdentifierFunctions.WARNING_IDENTIFIER_MAX_LENGTH);

                    if (!identifierControl.touched) {
                        identifierControl.setValue(kebabizedLabel, { emitEvent: true });
                        this.checkIdentifierEditWarning(lastPublishedAtControl);
                    }

                    if (!bucketControl.touched) {
                        bucketControl.setValue(kebabizedLabel, { emitEvent: true });
                        this.checkBucketEditWarning(lastPublishedAtControl);
                    }
                })
            );
        }

        return control;
    }

    buildDefinitionHasRollingVersionControl(hasRollingVersion?: boolean) {
        const control = this.formBuilder.control(hasRollingVersion, ValidatorFunctions.required('Has rolling version is required'));
        return control;
    }

    buildFieldControl(field: FormEditorField, parent?: FormEditorField): UfControlGroup {

        // Here meta and field.id and field.type are guaranteed to be updated value thanks to:
        // field.type not changing
        // fieldControl rebuilt when form saved or special cases of field moved => meta and field.id up to date
        const meta = FormEditorFunctions.fieldMetadata(field.uuid, field.scopeUuid, field.type, this.context, parent?.type);

        this.fieldsSubscriptions.set(field.uuid, []);

        const repeatSortablePropertiesCtrl = this.formBuilder.control(FormEditorFunctions.calculateRepeatFieldSortableDescriptors(field));

        const fieldControl = this.formBuilder.group({
            [FieldControlKeys.Uuid]: field.uuid,
            [FieldControlKeys.ScopeUuid]: field.scopeUuid,
            [FieldControlKeys.RepeatSortableProperties]: repeatSortablePropertiesCtrl,
            [FieldControlKeys.Id]: field.id,
            [FieldControlKeys.Type]: [field.type, ValidatorFunctions.required('Type is required')],
            [FieldControlKeys.Fields]: meta.isContainer ? this.buildFieldsControl(field.fields, field) : null,
            [FieldControlKeys.Identifier]: this.buildFieldIdentifierControl(meta, field.id, field.identifier),
            [FieldControlKeys.Label]: this.buildFieldLabelControl(meta, field.id, field.label),
            [FieldControlKeys.ShortLabel]: this.buildFieldShortLabelControl(meta, field.id, field.shortLabel),
            [FieldControlKeys.Description]: field.description,
            [FieldControlKeys.Help]: meta.help ? field.help : null,
            [FieldControlKeys.Currency]: this.buildCurrencyControl(meta, field.currency),
            [FieldControlKeys.Placeholder]: meta.placeholder ? field.placeholder : null,
            [FieldControlKeys.Step]: this.buildStepControl(meta, field.step),
            [FieldControlKeys.Format]: meta.format ? field.format : null,
            [FieldControlKeys.HierarchyConfig]: meta.hierarchy ? this.buildHierarchyConfigControl(field.hierarchyConfig) : null,
            [FieldControlKeys.Sort]: meta.sort ? this.buildSortControl(meta, repeatSortablePropertiesCtrl, field.sort) : null,
            [FieldControlKeys.Tags]: this.buildTagsControl(meta.uuid, field.tags, meta),
            [FieldControlKeys.DataSourceConfig]: this.buildDatasourceConfigControl(meta, AttributeParentType.FieldOptionType, field.dataSourceConfig),
            [FieldControlKeys.AvoidDuplicates]: meta.avoidDuplicates ? field.avoidDuplicates : null,
            [FieldControlKeys.Autofill]: this.buildAutofillControl(meta, field.autofill),
            [FieldControlKeys.IsReadOnly]: meta.isReadOnly ? field.isReadOnly : null,
            [FieldControlKeys.IsRequired]: meta.isRequired ? field.isRequired : null,
            [FieldControlKeys.IsSearchable]: meta.isSearchable ? field.isSearchable : null,
            [FieldControlKeys.IsReportable]: meta.isReportable ? field.isReportable : null,
            [FieldControlKeys.IsTranslatable]: meta.isTranslatable ? field.isTranslatable : null,
            [FieldControlKeys.DataCaptures]: meta.dataCaptures ? { value: field.dataCaptures, disabled: this.isDataCaptureDisabled(meta, field.dataSourceConfig) } : [[]],
            [FieldControlKeys.AutoDetect]: meta.autoDetect ? field.autoDetect : null,
            [FieldControlKeys.MaxLength]: meta.maxLength ? field.maxLength : null,
            [FieldControlKeys.Precision]: meta.precision ? field.precision : null,
            [FieldControlKeys.ItemLabel]: meta.itemLabel ? field.itemLabel : null,
            [FieldControlKeys.AddButtonLabel]: meta.addButtonLabel ? field.addButtonLabel : null,
            [FieldControlKeys.Width]: this.buildFieldWidthControl(meta, field.width),
            [FieldControlKeys.BreakAfter]: meta.breakAfter ? field.breakAfter : null,
            [FieldControlKeys.BindTo]: this.buildBindToControl(meta, field.bindTo),
            [FieldControlKeys.Template]: this.buildFieldTemplateControl(meta, field.template),
            [FieldControlKeys.ColumnCount]: meta.columnCount ? field.columnCount : null,
            [FieldControlKeys.ActiveBackgroundTinted]: meta.activeBackgroundTinted ? { value: field.template ? field.activeBackgroundTinted : false, disabled: this.isActiveBackgroundTintedDisabled(field.template) } : null,
            [FieldControlKeys.AlwaysExpanded]: this.buildFieldAlwaysExpandedControl(meta, field.alwaysExpanded),
            [FieldControlKeys.CollapseWhenInactive]: this.buildFieldCollapseWhenInactiveControl(meta, field.collapseWhenInactive, field.alwaysExpanded),
            [FieldControlKeys.HideWhenInactive]: this.buildFieldHideWhenInactiveControl(meta, field.hideWhenInactive),
            [FieldControlKeys.AllowedTypes]: meta.allowedTypes ? this.buildFieldAllowedTypesControl(field.allowedTypes) : null,
            [FieldControlKeys.LayoutDirection]: meta.layoutDirection ? field.layoutDirection : null,
            [FieldControlKeys.ColumnVisibility]: meta.columnVisibility ? field.columnVisibility : null,
            [FieldControlKeys.Roles]: this.buildFieldRolesControl(meta.role, field.roles),
            [FieldControlKeys.VisibleTo]: this.buildFieldRolesControl(meta.visibleTo, field.visibleTo),
            [FieldControlKeys.ShowIf]: this.buildShowIfControl(meta, field.showIf),
            [FieldControlKeys.ShowOn]: this.buildShowOnControl(meta, field.showOn),
            [FieldControlKeys.AddressAutocomplete]: meta.addressAutocomplete ? field.addressAutocomplete : null,
            [FieldControlKeys.AddressNested]: this.builAddressNestedControl(meta, field.addressNested),
            [FieldControlKeys.GeoLocationNested]: this.builGeoLocationNestedControl(meta, field.geoLocationNested),
            [FieldControlKeys.Variations]: meta.variations ? this.buildVariationsControl(meta, field.variations) : null,
            [FieldControlKeys.Options]: meta.options ? this.buildOptionsControl(meta, AttributeParentType.FieldOptionType, field.options) : null,
            [FieldControlKeys.Transitions]: meta.transitions ? this.buildFiledTransitions(meta, field.transitions) : null,
            [VariationControlKeys.Validators]: meta.validators ? this.buildValidatorsControl(meta, field.validators) : null
        });

        fieldControl.addValidators(this.fieldPositionValidator);

        this.fieldsScopeManager.onAddedField(fieldControl, true);
        // fieldControl.updateDependencies();

        if (this.setSubmitted) {
            fieldControl.setSubmitted(true);
        }

        return fieldControl;
    }

    buildVariationControl(meta: FormFieldMetadata, variation: FormEditorVariation): UfControlGroup {

        const conditionControl = this.formBuilder.control(
            variation.condition,
            ValidatorFunctions.compose([
                ValidatorFunctions.required('Condition is required'),
                ValidatorFunctions.isValidExpression(this.expressionParser, 'Invalid expression'),
                c => this.expressionIdentifiersValidator(c, meta)
            ]), undefined, { deps: [this.status.fieldsIdentifier] }
        );

        const control = this.formBuilder.group({
            [VariationControlKeys.Name]: [variation.name, ValidatorFunctions.required('Name is required')],
            [VariationControlKeys.Condition]: conditionControl,
            [VariationControlKeys.Label]: variation.label,
            [VariationControlKeys.Placeholder]: variation.placeholder,
            [VariationControlKeys.Help]: variation.help,
            [VariationControlKeys.Options]: this.buildOptionsControl(meta, AttributeParentType.VariationOptionType, variation.options),
            [FieldControlKeys.DataSourceConfig]: this.buildDatasourceConfigControl(meta, AttributeParentType.VariationOptionType, variation.dataSourceConfig),
            [VariationControlKeys.Validators]: this.buildValidatorsControl(meta, variation.validators),
        });

        if (this.setSubmitted) {
            control.setSubmitted(true);
        }

        return control;
    }

    buildOptionControl(meta: FormFieldMetadata, optionType: AttributeParentType, option: FormEditorOption): UfControlGroup {

        const fieldControlPath = optionType === AttributeParentType.FieldOptionType ? `../../../` : `../../../../../`; // from option.*
        const optionsPath = `../../[*]`; // from option.*
        const optionsIdentifiersPath = `${optionsPath}.${OptionControlKeys.Identifier}`; // from option.*

        // Identifier control
        const identifierControlAccessor = new ControlAccessor();
        const identifierControl = this.formBuilder.control(option.identifier, ValidatorFunctions.compose([
            ValidatorFunctions.required('Identifier is required'),
            ValidatorFunctions.custom((v: string) => {

                if (!v) {
                    return true;
                }

                if (!identifierControlAccessor.control) {
                    return true;
                }

                const identifiers = identifierControlAccessor.get(optionsIdentifiersPath)
                    .filter(c => c !== identifierControlAccessor.control)
                    .map(c => c.value as string)
                    .filter(identifier => identifier != null);
                return !identifiers.find(i => i.toLowerCase() === v.toLowerCase());

            }, 'Identifier needs to be unique'),
            ValidatorFunctions.pattern(FORM_EDITOR_CONSTANTS.FIELD_OPTION_IDENTIFIER_REGEX, 'Identifier contains invalid characters'),
            ValidatorFunctions.noWhiteSpaces(`Can't contains white spaces`),
            ValidatorFunctions.maxLength(this.status.identifiersMaxLength.option, `Identifier can't be longer than ${this.status.identifiersMaxLength.option} characters`)
        ]), undefined, { deps: [optionsIdentifiersPath] });

        identifierControlAccessor.control = identifierControl;

        this.fieldsSubscriptions.get(meta.uuid)?.push(identifierControl.valueChanges.subscribe(_ => {
            if (!identifierControl.pristine && this.status.hasBeenPublished && option.id) {
                this.toast.warning('Editing the identifier after the option is published may cause errors with your Form');
            }
        }));

        // Name control
        const optionIdControlPath = `../${OptionControlKeys.Id}`; // from option.*
        const nameControl = this.formBuilder.control(option.name, ValidatorFunctions.required('Name is required'));
        const nameControlAccessor = new ControlAccessor(nameControl);

        if (meta.type !== FieldType.Bool) {
            this.fieldsSubscriptions.get(meta.uuid)?.push(nameControl.valueChanges.subscribe(v => {

                const id = nameControlAccessor.get(optionIdControlPath)[0]?.value as string | undefined;

                if (id == null) {
                    const otherOptionsIndentifiers = nameControlAccessor.get(optionsPath)
                        .filter(c => c.get(OptionControlKeys.Uuid)?.value !== option.uuid)
                        .map(c => c.get(OptionControlKeys.Identifier)?.value as string);
                    const generatedIdentifier = FormEditorFunctions.generateSafeIdentifier(IdentifierFunctions.pascalize(v), otherOptionsIndentifiers);
                    identifierControl.setValue(generatedIdentifier, { onlySelf: true, emitEvent: false });
                }
            }));
        }

        // Content control
        const fieldTemplateControlPath = `${fieldControlPath}${FieldControlKeys.Template}`; // from option.*
        const contentControlAccessor = new ControlAccessor();
        const contentControl = this.formBuilder.control(option.content, ValidatorFunctions.custom(v => {

            if (!contentControlAccessor.control) {
                return true;
            }

            const templateValue = contentControlAccessor.get(fieldTemplateControlPath)[0]?.value as FieldTemplate;

            return ![FieldType.MultiChoice, FieldType.Choice].includes(meta.type) ||
                (!templateValue || ![FieldTemplate.OptionWithContent, FieldTemplate.CheckboxWithContent, FieldTemplate.RadioWithContent].includes(templateValue)) ||
                ValidatorFunctions.isEmpty(v) === false;
        }, 'Content is required'), undefined, { deps: [fieldTemplateControlPath] });

        contentControlAccessor.control = contentControl;

        // Group control
        const control = this.formBuilder.group({
            [OptionControlKeys.Uuid]: option.uuid,
            [OptionControlKeys.Id]: option.id,
            [OptionControlKeys.Identifier]: identifierControl,
            [OptionControlKeys.Name]: nameControl,
            [OptionControlKeys.Content]: contentControl
        });

        // control.updateDependencies();

        if (this.setSubmitted) {
            control.setSubmitted(true);
        }

        return control;
    }

    buildValidatorControl(validator: FieldValidator): UfControlGroup {

        const valueTypes = [ValidatorType.Expression,
        ValidatorType.ItemExpression,
        ValidatorType.Pattern,
        ValidatorType.Min,
        ValidatorType.Max,
        ValidatorType.MinLength
        ];
        const expressionTypes = [ValidatorType.Expression, ValidatorType.ItemExpression];

        const control = this.formBuilder.group({
            [ValidatorControlKeys.Type]: [validator.type, ValidatorFunctions.required('Type is required')],
            [ValidatorControlKeys.Message]: [validator.message, ValidatorFunctions.required('Message is required')],
            [ValidatorControlKeys.Value]: [{ value: validator.value, disabled: !valueTypes.includes(validator.type) }, ValidatorFunctions.compose([
                ValidatorFunctions.custom(v => !valueTypes.includes(validator.type) || !ValidatorFunctions.isEmpty(v), 'A value is required'),
                ValidatorFunctions.custom(v => !v || !expressionTypes.includes(validator.type) || this.expressionParser.validate(v), 'Invalid expression')
            ])]
        });

        if (this.setSubmitted) {
            control.setSubmitted(true);
        }

        return control;
    }

    buildTransitionControl(meta: FormFieldMetadata, transition: FormEditorTransition, skipSubmitted = false): UfControlGroup {

        const actionControl = this.formBuilder.control(transition.action, ValidatorFunctions.compose([
            ValidatorFunctions.required('Action is required'),
            ValidatorFunctions.pattern(FORM_EDITOR_CONSTANTS.FIELD_TRANSITION_STATUS_REGEX, 'Action contains invalid characters')
        ]));

        const actionLabelControl = this.formBuilder.control(transition.actionLabel, ValidatorFunctions.required('Label is required'));
        this.fieldsSubscriptions.get(meta.uuid)?.push(actionLabelControl.valueChanges.subscribe(v => {
            actionControl.setValue(IdentifierFunctions.pascalize(v), { emitEvent: false });
        }));

        const sectionsRolesControlPath = `../../../${FieldControlKeys.Roles}`; // from transition.*
        const rolesAccessor = new ControlAccessor();
        const rolesControl = this.formBuilder.control(
            transition.roles,
            ValidatorFunctions.custom((roles: string[]) => {
                if (!rolesAccessor.control) {
                    return true;
                }
                const sectionRoles = rolesAccessor.get(sectionsRolesControlPath)[0]?.value as string[] | undefined;
                if (roles == null || !sectionRoles?.length) {
                    return true;
                }

                return roles.filter(r => sectionRoles.includes(r) === false).length === 0;
            }, 'Role(s) must be used in this section'),
            this.asyncRolesValidator,
            { deps: [sectionsRolesControlPath] }
        );

        rolesAccessor.control = rolesControl;

        const showIfControl = this.formBuilder.control(transition.showIf, ValidatorFunctions.compose([
            ValidatorFunctions.isValidExpression(this.expressionParser, 'Invalid expression'),
            c => this.expressionIdentifiersValidator(c, meta)
        ]), undefined, { deps: [this.status.fieldsIdentifier] });

        const transitionControl = this.formBuilder.group({
            [TransitionControlKeys.Source]: [transition.source, ValidatorFunctions.compose([
                ValidatorFunctions.required('Start status is required'),
                ValidatorFunctions.pattern(FORM_EDITOR_CONSTANTS.FIELD_TRANSITION_STATUS_REGEX, 'Start status contains invalid characters'),
            ])],
            [TransitionControlKeys.Target]: [transition.target, ValidatorFunctions.compose([
                ValidatorFunctions.required('Target status is required'),
                ValidatorFunctions.pattern(FORM_EDITOR_CONSTANTS.FIELD_TRANSITION_STATUS_REGEX, 'Target contains invalid characters'),
            ])],
            [TransitionControlKeys.Action]: actionControl,
            [TransitionControlKeys.ActionLabel]: actionLabelControl,
            [TransitionControlKeys.Result]: [transition.result, ValidatorFunctions.pattern(FORM_EDITOR_CONSTANTS.FIELD_TRANSITION_STATUS_REGEX, 'Result contains invalid characters')],
            [TransitionControlKeys.Notify]: transition.notify,
            [TransitionControlKeys.Validate]: transition.validate,
            [TransitionControlKeys.Roles]: rolesControl,
            [TransitionControlKeys.ShowIf]: showIfControl,
            [TransitionControlKeys.Triggers]: this.formBuilder.array(transition.triggers?.map(t => this.buildTransitionTargetControl(t, meta, skipSubmitted)) ?? []),
            [TransitionControlKeys.Spawns]: this.formBuilder.array(transition.spawns?.map(s => this.buildTransitionTargetControl(s, meta, skipSubmitted)) ?? []),
            [TransitionControlKeys.Tags]: this.buildTagsControl(meta.uuid, transition.tags)
        }, {
            options: {
                deps: [
                    `$.${FieldControlKeys.Fields}[*].${FieldControlKeys.Transitions}[*].${TransitionControlKeys.Action}`,
                    `$.${FieldControlKeys.Fields}[*].${FieldControlKeys.Transitions}[*].${TransitionControlKeys.Source}`,
                    `$.${FieldControlKeys.Fields}[*].${FieldControlKeys.Transitions}[*].${TransitionControlKeys.Target}`
                ]
            }
        });

        const transitionsAccessor = new ControlAccessor(transitionControl);
        const transitionsPath = `$.${FieldControlKeys.Fields}[*].${FieldControlKeys.Transitions}[*]`;

        // Transitions are "similar" when they have same Source and Action but different Target, this is not allowed
        transitionControl.addValidators([ValidatorFunctions.custom(v => {

            if (!transitionsAccessor.control) {
                return true;
            }

            const myAction = transitionControl.get(TransitionControlKeys.Action)?.value;
            const mySource = transitionControl.get(TransitionControlKeys.Source)?.value;
            const myTarget = transitionControl.get(TransitionControlKeys.Target)?.value;

            if (!myAction || !mySource || !myTarget) {
                return true;
            }

            const otherTransitionsControl = transitionsAccessor.get(transitionsPath) as UfControlGroup[];
            // console.log('transitionControls', otherTransitionsControl);

            const match = otherTransitionsControl.find(tc => {
                const action = tc.get(TransitionControlKeys.Action)?.value;
                const source = tc.get(TransitionControlKeys.Source)?.value;
                const target = tc.get(TransitionControlKeys.Target)?.value;

                const isSimilar = source === mySource && action === myAction && target !== myTarget;
                // console.log(`sources ${source}|${mySource} actions ${action}|${myAction} targets ${target}|${myTarget} => isSimilar ${isSimilar}`);
                return isSimilar;
            });

            return match == null;
        }, 'A transition with same source and action but different target already exists')]);

        if (this.setSubmitted && !skipSubmitted) {
            transitionControl.setSubmitted(true);
        }

        return transitionControl;
    }

    buildTransitionTargetControl(target: FormEditorTransitionTarget, meta: FormFieldMetadata, skipSubmitted = false): UfControlGroup {

        const formControl = this.formBuilder.control(
            target.form,
            ValidatorFunctions.required('Target form is required'),
            this.asyncTransitionTargetFormValidator,
            { deps: [] }
        );

        const transitionControl = this.formBuilder.control(
            target.transition,
            ValidatorFunctions.required('Target transition is required'),
            this.asyncTransitionTargetTransitionValidator,
            { deps: [formControl] }
        );

        const transitionCondition = this.formBuilder.control(
            target.condition,
            ValidatorFunctions.compose([
                ValidatorFunctions.isValidExpression(this.expressionParser, 'Invalid expression'),
                c => this.expressionIdentifiersValidator(c, meta)
            ]), undefined, { deps: [this.status.fieldsIdentifier] }
        );

        const control = this.formBuilder.group({
            [TransitionTargetControlKeys.Label]: [target.label, ValidatorFunctions.required('Label is required')],
            [TransitionTargetControlKeys.Form]: formControl,
            [TransitionTargetControlKeys.Transition]: transitionControl,
            [TransitionTargetControlKeys.Condition]: transitionCondition,
            [TransitionTargetControlKeys.FieldMappings]: this.formBuilder.array(target.fieldMappings?.map(fm => this.buildFieldMapping(fm)) ?? [])
        });

        if (this.setSubmitted && !skipSubmitted) {
            control.setSubmitted(true);
        }

        return control;
    }

    buildFieldMapping(fieldMapping: FormEditorFieldMapping): UfControlGroup {

        const parentFieldControl = this.formBuilder.control(
            fieldMapping.parentField,
            ValidatorFunctions.required('A field is required'),
            this.asyncTransitionParentFieldMappingValidator,
            { deps: [`$.${DefinitionControlKeys.Bucket}`] }
        );

        const childFieldControl = this.formBuilder.control(
            fieldMapping.childField,
            ValidatorFunctions.compose([
                ValidatorFunctions.required('A field is required'),
                ValidatorFunctions.custom(v => {
                    const source = parentFieldControl.value as SchemaField | null;
                    const target = v as SchemaField | null;

                    if (!source || !target) {
                        return true;
                    }

                    return FormEditorFunctions.areTransitionMappingFieldsCompatible(source, target);
                }, 'Field types must match')
            ]),
            this.asyncTransitionChildFieldMappingValidator,
            { deps: [`../../../${TransitionTargetControlKeys.Form}`] }
        );

        const control = this.formBuilder.group({
            [FieldMappingControlKeys.ParentField]: parentFieldControl,
            [FieldMappingControlKeys.ChildField]: childFieldControl
        });

        if (this.setSubmitted) {
            control.setSubmitted(true);
        }

        return control;
    }

    private asyncTransitionParentFieldMappingValidator = async (control: AbstractControl): Promise<ValidationErrors | null> => {
        const field: SchemaField | null = control.value;
        if (field == null || this.status.root == null) {
            return null;
        }

        const isMetafield = this.dataPropertyInfoService.formDefinitionReferences.find(f => f.identifier === field.identifier);
        if (isMetafield) {
            return null;
        }

        const schema = await this.cache.getSchema(this.status.definition.bucket);
        const matchingField = schema?.fields.find(f => f.identifier === field.identifier);
        if (matchingField) {
            return null;
        }

        return { message: 'Field not found' };
    };

    private asyncTransitionChildFieldMappingValidator = async (control: AbstractControl): Promise<ValidationErrors | null> => {
        const field: SchemaField | null = control.value;
        if (field == null) {
            return null;
        }

        const formControl = control.parent?.parent?.parent?.get(TransitionTargetControlKeys.Form);
        if (!formControl || formControl.invalid || formControl.value == null || formControl.value.bucket == null) {
            return null;
        }

        const schema = await this.cache.getSchema(formControl.value.bucket);
        const matchingField = schema?.fields.find(f => f.identifier === field.identifier);

        if (matchingField) {
            return null;
        }

        return { message: 'Field not found' };
    };

    private asyncTransitionTargetFormValidator = async (control: AbstractControl): Promise<ValidationErrors | null> => {
        const formInfo: DefinitionInfo | null = control.value;
        if (formInfo == null) {
            return null;
        }

        const form = (await this.cache.getFormsInfo()).find(fi => fi.identifier === formInfo.identifier);
        if (form) {
            return null;
        }

        return { message: 'Form not found' };
    };

    private asyncTransitionTargetTransitionValidator = async (control: AbstractControl): Promise<ValidationErrors | null> => {
        const transition: SchemaTransition | null = control.value;
        if (transition == null) {
            return null;
        }

        const formControl = control.parent?.get(TransitionTargetControlKeys.Form);

        if (!formControl || formControl.invalid || formControl.value == null) {
            return null;
        }

        const formInfo: DefinitionInfo = formControl.value;

        const matchTransition = (await this.cache.getSchema(formInfo.bucket))?.transitions?.find(t => t.source === transition.source && t.action === transition.action);

        if (matchTransition) {
            return null;
        }

        return { message: 'Transition not found' };
    };

    private buildHierarchyConfigControl(hierarchyConfig?: FormEditorHierarchyConfiguration): UfControlGroup | null {

        const control = this.formBuilder.group({
            [HierarchyConfigControlKeys.Ceiling]: hierarchyConfig?.ceiling,
            [HierarchyConfigControlKeys.SelectionMode]: [hierarchyConfig?.selectionMode, ValidatorFunctions.required('A value is required')]
        });

        if (this.setSubmitted) {
            control.setSubmitted(true);
        }

        return control;
    }

    private buildSortControl(meta: FormFieldMetadata, repeatSortablePropertiesCtrl: UfControl, sort?: string): UfControl | null {
        const control = this.formBuilder.control(
            sort,
            ValidatorFunctions.custom(v => {
                if (ValidatorFunctions.isEmpty(v)) {
                    return true;
                }

                const sortName = SortStatus.fromString(v)?.name;

                if (!sortName) {
                    return false;
                }

                const properties = repeatSortablePropertiesCtrl.getRawValue() as DataPropertyDescriptor[];

                return properties.find(dpd => dpd.identifier === sortName) != null;
            }, 'Sort field not found'),
            undefined,
            { deps: [repeatSortablePropertiesCtrl] }
        );

        this.fieldsSubscriptions.get(meta.uuid)?.push(
            repeatSortablePropertiesCtrl.valueChanges.subscribe((properties: DataPropertyDescriptor[]) => {
                const sortValue = SortStatus.fromString(control.value)?.name;
                if (!sortValue) {
                    return;
                }
                if (!properties.find(p => sortValue === p.identifier)) {
                    control.setValue(null);
                }
            }));

        if (this.setSubmitted) {
            control.setSubmitted(true);
        }

        return control;
    }

    private buildOptionsControl(meta: FormFieldMetadata, optionType: AttributeParentType, options?: FormEditorOption[]): UfControlArray {

        if (!meta.options) {
            return this.formBuilder.array([]);
        }

        const dataSourcePath = `../${FieldControlKeys.DataSourceConfig}`;
        const variationsPath = `../${FieldControlKeys.Variations}`;

        const controlDependencies = [dataSourcePath];
        if (optionType === AttributeParentType.FieldOptionType) {
            controlDependencies.push(variationsPath);
        }

        const optionsControl = this.formBuilder.array(
            (options ?? []).map(o => this.buildOptionControl(meta, optionType, o)),
            undefined, undefined, { deps: controlDependencies }
        );

        const controlAccessor = new ControlAccessor(optionsControl);

        const hasVariations = (): boolean => {
            const variationsControl = controlAccessor.get(variationsPath)[0] as UfControlArray | undefined;
            if (variationsControl && variationsControl.length > 0) {
                return true;
            }
            return false;
        };

        if (meta.type === FieldType.Bool) {
            optionsControl.addValidators(ValidatorFunctions.compose([
                ValidatorFunctions.custom(v => hasVariations() || v.length === 2, 'Must have two options'),
                ValidatorFunctions.custom((v: FormEditorOption[]) =>
                    hasVariations() || v.length !== 2 ||
                    (v.filter(o => o.identifier === 'true').length === 1 && v.filter(o => o.identifier === 'false').length === 1),
                    `Must have a 'true' option and a 'false' option`
                )
            ]) as ValidatorFn);
        }

        if ([FieldType.MultiChoice, FieldType.Survey].includes(meta.type)) {
            optionsControl.addValidators(ValidatorFunctions.custom(v => hasVariations() || v.length !== 0, 'Must have at least one option'));
        }

        if (meta.type === FieldType.Choice) {
            optionsControl.addValidators(ValidatorFunctions.custom(v => {

                if (hasVariations()) {
                    return true;
                }

                const dataSourceCtrl = controlAccessor.get(dataSourcePath)[0] as UfFormControl | undefined;
                if (!dataSourceCtrl) {
                    return true;
                }

                if (v.length === 0 && dataSourceCtrl.value == null) {
                    return false;
                }

                return true;
            }, 'Options or Data Source are required'));
        }

        if (this.setSubmitted) {
            optionsControl.setSubmitted(true);
        }

        return optionsControl;
    }

    private buildVariationsControl(meta: FormFieldMetadata, variations?: FormEditorVariation[]): UfControlArray {

        if (!meta.variations || variations == null) {
            return this.formBuilder.array([]);
        }

        const control = this.formBuilder.array(variations.map(v => this.buildVariationControl(meta, v)));

        if (this.setSubmitted) {
            control.setSubmitted(true);
        }

        return control;
    }

    private buildValidatorsControl(meta: FormFieldMetadata, validators?: FieldValidator[]): UfControlArray {

        if (!meta.validators || validators == null) {
            return this.formBuilder.array([]);
        }

        const control = this.formBuilder.array(validators.map(v => this.buildValidatorControl(v)));

        if (this.setSubmitted) {
            control.setSubmitted(true);
        }

        return control;
    }

    private buildFieldsControl(fields?: FormEditorField[], parent?: FormEditorField): UfControlArray {

        const controlArray = this.formBuilder.array(fields?.map(f => this.buildFieldControl(f, parent)) ?? []);

        // Root's fields
        if (!parent) {
            if (this.limitService.sectionsLimit != null) {
                controlArray.addValidators(ValidatorFunctions.maxLength(this.limitService.sectionsLimit, 'Sections limit exceeded'));
            }

            controlArray.addValidators(ValidatorFunctions.compose([
                ValidatorFunctions.custom(v => {
                    const sections = ((v ?? []) as FormEditorField[]).filter(field => field.type === FieldType.Section);
                    return sections.length > 0;
                }, 'At least one section is required'),
                ValidatorFunctions.custom(v => {

                    const sections = ((v ?? []) as FormEditorField[]).filter(field => field.type === FieldType.Section);
                    if (sections.length === 0) {
                        return true; // Covered by required at least one section
                    }

                    for (const section of sections) {
                        const match = section.transitions?.find(transition => transition.source === FORM_EDITOR_CONSTANTS.WORKFLOW_INITIAL_STATE);
                        if (match) {
                            return true;
                        }
                    }

                    return false;
                }, `A workflow transition with Start Status "${FORM_EDITOR_CONSTANTS.WORKFLOW_INITIAL_STATE}" is required`)
            ]) as ValidatorFn);
        }

        if (parent?.type === FieldType.Survey) {
            controlArray.addValidators(ValidatorFunctions.compose([
                ValidatorFunctions.minLength(1, 'At least one question is required'),
                ValidatorFunctions.custom((v: FormEditorField[]) => {
                    const count = v.filter(field => field.type === FieldType.Choice).length;
                    return count === 0 || count === v.length;
                }, 'A mix of Choice and Multi Choice fields is not allowed'),
                ValidatorFunctions.custom((v: FormEditorField[]) =>
                    v.filter(field => [FieldType.Choice, FieldType.MultiChoice].includes(field.type)).length === v.length
                    , 'Only Choice and Multi Choice fields are allowed here')
            ]) as ValidatorFn);

            controlArray.markAsTouched();
        }

        if (this.setSubmitted) {
            controlArray.setSubmitted(true);
        }

        return controlArray;
    }

    private buildFiledTransitions(meta: FormFieldMetadata, transitions?: FormEditorTransition[]): UfControlArray {

        if (!meta.transitions) {
            return this.formBuilder.array([]);
        }

        const controlArray = this.formBuilder.array(transitions?.map(t => this.buildTransitionControl(meta, t)) ?? []);
        controlArray.addValidators(ValidatorFunctions.minLength(1, 'At least one workflow is required'));
        controlArray.markAsTouched();

        if (this.setSubmitted) {
            controlArray.setSubmitted(true);
        }

        return controlArray;
    }

    private buildCurrencyControl(meta: FormFieldMetadata, currency?: string): UfControl {

        if (!meta.currency) {
            return this.formBuilder.control(null);
        }

        const control = this.formBuilder.control(currency, ValidatorFunctions.custom(v => !ValidatorFunctions.isEmpty(v), 'Currency is required.'));

        if (this.setSubmitted) {
            control.setSubmitted(true);
        }

        return control;
    }

    private buildStepControl(meta: FormFieldMetadata, step?: string): UfControl {

        let control;
        if (!meta.step) {
            control = this.formBuilder.control(null);
        } else {
            control = this.formBuilder.control(step, ValidatorFunctions.custom((v: string) =>
                !v || FORM_EDITOR_CONSTANTS.TIME_STEP_VALUES.findIndex(o => o.identifier === v) >= 0, 'Interval needs to be a valid step.')
            );
        }

        if (this.setSubmitted) {
            control.setSubmitted(true);
        }

        return control;
    }

    private buildDatasourceConfigControl(meta: FormFieldMetadata, parentType: AttributeParentType, dataSourceConfig?: DataSource): UfControl {

        let control: UfControl;
        const controlAccessor = new ControlAccessor();
        const dataCapturesPath = parentType === AttributeParentType.FieldOptionType ? `../${FieldControlKeys.DataCaptures}` : `../../../${FieldControlKeys.DataCaptures}`;

        if (!meta.dataSourceConfig) {
            control = this.formBuilder.control(null);
        } else {

            const validators = [ValidatorFunctions.custom(v => meta.type !== FieldType.Lookup || !ValidatorFunctions.isEmpty(v), 'Data Source is required.')];

            if (parentType === AttributeParentType.FieldOptionType) {
                validators.push(
                    ValidatorFunctions.custom(v => {
                        if (!controlAccessor.control) {
                            return true;
                        }
                        const dataCapturesControl = controlAccessor.get(dataCapturesPath)[0];
                        // if data capture, but no target identifier
                        return !((dataCapturesControl && dataCapturesControl.getRawValue().length && !v?.findBy));
                    }, 'FindBy required for Data Capture'),
                    ValidatorFunctions.custom(v => {
                        if (!controlAccessor.control) {
                            return true;
                        }
                        const dataCapturesControl = controlAccessor.get(dataCapturesPath)[0];
                        // if no data capture, but target identifier
                        return !((dataCapturesControl && !dataCapturesControl.getRawValue().length && v?.findBy));
                    }, 'FindBy requires Data Capture'),
                );
            }

            control = this.formBuilder.control(
                dataSourceConfig,
                ValidatorFunctions.compose(validators),
                this.asyncDataSourceValidator,
                { deps: parentType === AttributeParentType.FieldOptionType ? [dataCapturesPath] : undefined }
            );

            if (meta.type === FieldType.Repeat) {
                // A change to the Repeat DS can lead to duplicated identifier against it's scoped Fields vs the DS mappings
                this.fieldsSubscriptions.get(meta.uuid)?.push(control.valueChanges.subscribe(() => {
                    this.fieldsScopeManager.notifyScopeIdentifiersStatusChange(meta.uuid);
                }));
            }

            controlAccessor.control = control;
        }

        if (this.setSubmitted) {
            control.setSubmitted(true);
        }

        this.fieldsSubscriptions.get(meta.uuid)?.push(control.valueChanges.subscribe(v => {
            // Set avoidDuplicates to false when DataSource is deleted
            if (v == null) {
                const avoidDuplicatesCtrl = controlAccessor.get(`../${FieldControlKeys.AvoidDuplicates}`)[0];
                // Exists only for Repeat field type
                if (avoidDuplicatesCtrl) {
                    avoidDuplicatesCtrl.setValue(null);
                }
            }

            const dataCapturesControl = controlAccessor.get(`../${FieldControlKeys.DataCaptures}`)[0];

            if (dataCapturesControl) {
                const toDisable = this.isDataCaptureDisabled(meta, v);
                if (toDisable && !dataCapturesControl.disabled) {
                    dataCapturesControl.disable();
                    dataCapturesControl.reset([]);
                }
                if (!toDisable && dataCapturesControl.disabled) {
                    dataCapturesControl.enable();
                }
            }

            // Find this DS scopeUuid associated uuid control (a Repeat) to update its RepeatSortableProperties control value
            this.fieldsScopeManager.updateRepeatSortableProperties(meta.scopeUuid);
        }));

        return control;
    }

    private isDataCaptureDisabled(meta: FormFieldMetadata, dataSourceConfig: DataSource | undefined): boolean {

        if (!meta.dataSourceConfig) {
            return false;
        }

        if (!dataSourceConfig) {
            return true;
        }

        return ![DataSourceType.Bucket, DataSourceType.Collection, DataSourceType.Users].includes(dataSourceConfig.type);
    }

    private isActiveBackgroundTintedDisabled(template?: FieldTemplate): boolean {
        return !template;
    }

    private isCollapseWhenInactiveDisabled(alwaysExpanded?: boolean): boolean {
        return !!alwaysExpanded;
    }

    private asyncRolesValidator = async (control: AbstractControl): Promise<ValidationErrors | null> => {

        const message = await FormEditorFunctions.missingRoleError(this.cache, control.value);
        if (message) {
            return { message };
        }

        return null;
    };

    private asyncDataSourceValidator = async (control: AbstractControl): Promise<ValidationErrors | null> => {
        const dataSource: DataSource | null = control.value;
        if (dataSource == null) {
            return null;
        }

        const validator = new DataSourceValidator(this.cache, this.dataPropertyInfoService);
        const message = await validator.validate(dataSource);
        if (message != null) {
            return { message };
        }
        return null;
    };

    private buildFieldIdentifierControl(meta: FormFieldMetadata, id?: number, identifier?: string): UfControl {

        let control;
        if (!meta.identifier) {
            control = this.formBuilder.control(null);
        } else {
            // Build control
            const controlAccessor = new ControlAccessor();
            control = this.formBuilder.control(identifier, ValidatorFunctions.compose([
                ValidatorFunctions.custom(v => !meta.identifier || !ValidatorFunctions.isEmpty(v), 'Identifier is required'),
                ValidatorFunctions.pattern(FORM_EDITOR_CONSTANTS.FIELD_IDENTIFIER_REGEX, 'Your identifier contains an invalid character'),
                ValidatorFunctions.noWhiteSpaces(`Can't contains white spaces`),
                ValidatorFunctions.maxLength(this.status.identifiersMaxLength.field, `Identifier can't be longer than ${this.status.identifiersMaxLength.field} characters`),
                ValidatorFunctions.custom(v => !/^toDate$|^add$|^toTime$|^id$/.test(v), `This is a reserved term in the system`),
                ValidatorFunctions.custom((v: string) => {

                    if (!controlAccessor.control) {
                        return true;
                    }

                    const scopeUuidCtrl = controlAccessor.get(`../${FieldControlKeys.ScopeUuid}`)[0] as UfControl | undefined;
                    if (!scopeUuidCtrl) {
                        return true;
                    }

                    const scopeUuid = scopeUuidCtrl.value as string;
                    const scope = this.fieldsScopeManager.getScope(scopeUuid);

                    // Use scopeUuid to try to retrieve the Repeat container control's DataSource
                    const scopedDataSource = this.status.fieldByUuid.get(scopeUuid)?.get(FieldControlKeys.DataSourceConfig)?.value as DataSource | undefined;
                    // A match between one of the scoped DataSource 'to' identifiers with the field identifier would lead to an identifier duplication
                    if (scopedDataSource?.outputs && scopedDataSource.outputs[v]) {
                        return false;
                    }

                    return Array.from(scope.values()).find(i => {
                        // Different field
                        if (i.uuid === meta.uuid) {
                            return false; // same field
                        }
                        if (i.identifier == null || v == null) {
                            return false; // field identifier required validator will cover this case
                        }
                        return i.identifier.toLowerCase() === v.toLowerCase();
                    }) == null;
                }, 'Identifier needs to be unique')
            ]));

            if (this.status.hasBeenPublished && id) {
                this.fieldsSubscriptions.get(meta.uuid)?.push(control.valueChanges.subscribe(() => {
                    // Warning unsafe identifier changes
                    this.toast.warning('Editing your identifier after your field is published may cause errors with your Form');
                }));
            }

            controlAccessor.control = control;
        }

        if (this.setSubmitted) {
            control.setSubmitted(true);
        }

        return control;
    }

    private buildFieldShortLabelControl(meta: FormFieldMetadata, fieldId?: number, shortLabel?: string): UfControl {

        const control = this.formBuilder.control(shortLabel);
        const controlAccessor = new ControlAccessor(control);

        if (fieldId == null && meta.identifier) {
            this.fieldsSubscriptions.get(meta.uuid)?.push(control.valueChanges.pipe(debounceTime(FORM_EDITOR_CONSTANTS.INPUT_DEBOUCE_SHORT)).subscribe(v => {

                const identifierControl = controlAccessor.get(`../${FieldControlKeys.Identifier}`)[0] as UfControl | undefined;
                const scopeUuidControl = controlAccessor.get(`../${FieldControlKeys.ScopeUuid}`)[0];

                if (identifierControl != null && scopeUuidControl != null) {
                    const otherFieldsIdentifiers = Array
                        .from(this.fieldsScopeManager.getScope(scopeUuidControl.value as string).values())
                        .filter(i => i.uuid !== meta.uuid)
                        .map(i => i.identifier as string);
                    identifierControl.setValue(FormEditorFunctions.generateSafeIdentifier(IdentifierFunctions.camelize(v), otherFieldsIdentifiers), { emitEvent: true });
                    identifierControl.markAsTouched();
                }
            }));
        }


        if (this.setSubmitted) {
            control.setSubmitted(true);
        }

        return control;
    }

    private buildFieldLabelControl(meta: FormFieldMetadata, fieldId?: number, label?: string): UfControl {

        const control = this.formBuilder.control(label, ValidatorFunctions.compose([
            ValidatorFunctions.custom(v => !meta.label || !ValidatorFunctions.isEmpty(v), 'Label is required'),
            ValidatorFunctions.custom(v => ValidatorFunctions.isEmpty(v) || (v as string).split('\n').length < 2, 'New lines are invalid in labels')
        ]));

        const controlAccessor = new ControlAccessor(control);

        if (meta.identifier && fieldId == null) {
            this.fieldsSubscriptions.get(meta.uuid)?.push(control.valueChanges.pipe(debounceTime(FORM_EDITOR_CONSTANTS.INPUT_DEBOUCE_SHORT)).subscribe(v => {

                const shortLabelValue = (controlAccessor.get(`../${FieldControlKeys.ShortLabel}`)[0]?.value ?? '') as string;
                const identifierControl = controlAccessor.get(`../${FieldControlKeys.Identifier}`)[0];
                const scopeUuidControl = controlAccessor.get(`../${FieldControlKeys.ScopeUuid}`)[0];

                if (fieldId == null && shortLabelValue.trim().length === 0 && identifierControl != null && scopeUuidControl != null) {
                    const otherFieldsIdentifiers = Array
                        .from(this.fieldsScopeManager.getScope(scopeUuidControl.value as string).values())
                        .filter(i => i.uuid !== meta.uuid)
                        .map(i => i.identifier as string);
                    identifierControl.setValue(FormEditorFunctions.generateSafeIdentifier(IdentifierFunctions.camelize(v), otherFieldsIdentifiers), { emitEvent: true });
                    identifierControl.markAsTouched();
                }
            }));
        }

        if (this.setSubmitted) {
            control.setSubmitted(true);
        }

        return control;
    }


    private buildFieldAllowedTypesControl(allowedTypes?: LinkContentType[]): UfControl {

        const control = this.formBuilder.control(allowedTypes, ValidatorFunctions.custom(v => !ValidatorFunctions.isEmpty(v), `Allowed Types is required`));

        if (this.setSubmitted) {
            control.setSubmitted(true);
        }

        return control;
    }

    /** Strange inputs as it is used by Definition.tags, Field.tags, Transition.tags */
    private buildTagsControl(uuid: string, tags?: string[], meta?: FormFieldMetadata): UfControl {

        let control;

        if (meta?.tags === false) {
            return this.formBuilder.control(null);
        } else {

            control = this.formBuilder.control(tags, ValidatorFunctions.custom(v => !(v as string[] || []).find(tag => tag.includes(' ')), `Tags can't contain spaces`));

            this.fieldsSubscriptions.get(uuid)?.push(control.valueChanges.subscribe(v => {
                for (const tag of (v ?? [])) {
                    this.status.tags.add(tag);
                }
            }));
        }

        if (this.setSubmitted) {
            control.setSubmitted(true);
        }

        return control;
    }

    private buildFieldWidthControl(meta: FormFieldMetadata, width?: FieldWidth): UfControl {

        let control;

        if (!meta.width) {
            control = this.formBuilder.control(null);
        } else {

            control = this.formBuilder.control(width);
            const controlAccessor = new ControlAccessor(control);

            this.fieldsSubscriptions.get(meta.uuid)?.push(control.valueChanges.subscribe(v => {

                if (meta.breakAfter && v === FieldWidth.Quarter) {
                    this.toast.warning('All inputs require a minimum width and may not display as a full quarter');
                }

                const columnCountValue = controlAccessor.get(`../${FieldControlKeys.ColumnCount}`)[0]?.value as number ?? 0;

                if (v !== FieldWidth.Stretch && (columnCountValue > 1) && [FieldType.Choice, FieldType.MultiChoice].includes(meta.type)) {
                    this.toast.warning('Inputs with columns should be set to full width for better readability');
                }

                const breakAfterControl = controlAccessor.get(`../${FieldControlKeys.BreakAfter}`)[0] as UfControl | undefined;

                if (breakAfterControl != null && v === FieldWidth.Stretch) {
                    breakAfterControl.setValue(false, { emitEvent: false });
                }
            }));
        }

        if (this.setSubmitted) {
            control.setSubmitted(true);
        }

        return control;
    }

    private buildAutofillControl(meta: FormFieldMetadata, autofill?: string): UfControl | null {
        if (!meta.autofill) {
            return null;
        }

        const control = this.formBuilder.control(autofill, ValidatorFunctions.compose([
            ValidatorFunctions.isValidExpression(this.expressionParser, 'Invalid expression'),
            c => this.expressionIdentifiersValidator(c, meta)
        ]), undefined, { deps: [this.status.fieldsIdentifier] });

        if (this.setSubmitted) {
            control.setSubmitted(true);
        }

        return control;
    }

    private buildFieldTemplateControl(meta: FormFieldMetadata, template?: FieldTemplate): UfControl {

        let control;

        if (!meta.template) {
            control = this.formBuilder.control(null);
        } else {

            control = this.formBuilder.control(template);

            const controlAccessor = new ControlAccessor(control);

            this.fieldsSubscriptions.get(meta.uuid)?.push(control.valueChanges.subscribe(v => {

                if (meta.type === FieldType.Repeat && v === FieldTemplate.Table) {
                    this.toast.warning('This template will restrict how its fields are displayed');
                }

                const activeBackgroundTintedControl = controlAccessor.get(`../${FieldControlKeys.ActiveBackgroundTinted}`)[0];
                const collapseWhenInactiveControl = controlAccessor.get(`../${FieldControlKeys.CollapseWhenInactive}`)[0];
                const hideWhenInactiveControl = controlAccessor.get(`../${FieldControlKeys.HideWhenInactive}`)[0];

                if (activeBackgroundTintedControl) {
                    const toDisable = this.isActiveBackgroundTintedDisabled(v);
                    if (toDisable && activeBackgroundTintedControl.enabled) {
                        activeBackgroundTintedControl.disable();
                        activeBackgroundTintedControl.reset(undefined);
                    }
                    if (!toDisable && activeBackgroundTintedControl.disabled) {
                        activeBackgroundTintedControl.enable();
                    }

                    if (v && v !== FieldTemplate.Content) {
                        activeBackgroundTintedControl.reset(true);
                    }
                }

                if (v && collapseWhenInactiveControl && !collapseWhenInactiveControl.disabled && !hideWhenInactiveControl.value) {
                    collapseWhenInactiveControl.reset(true);
                }

            }));
        }

        if (this.setSubmitted) {
            control.setSubmitted(true);
        }

        return control;
    }

    private buildFieldAlwaysExpandedControl(meta: FormFieldMetadata, alwaysExpanded?: boolean): UfControl {

        let control;

        if (!meta.alwaysExpanded) {
            control = this.formBuilder.control(null);
        } else {

            control = this.formBuilder.control(alwaysExpanded);

            const controlAccessor = new ControlAccessor(control);

            this.fieldsSubscriptions.get(meta.uuid)?.push(control.valueChanges.subscribe(alwaysExpandedValue => {

                const collapseWhenInactiveControl = controlAccessor.get(`../${FieldControlKeys.CollapseWhenInactive}`)[0];

                if (collapseWhenInactiveControl) {
                    const toDisable = this.isCollapseWhenInactiveDisabled(!!alwaysExpandedValue);
                    if (toDisable && !collapseWhenInactiveControl.disabled) {
                        collapseWhenInactiveControl.disable();
                        collapseWhenInactiveControl.reset(undefined);
                    }
                    if (!toDisable && collapseWhenInactiveControl.disabled) {
                        collapseWhenInactiveControl.enable();
                    }
                }
            }));
        }

        return control;
    }

    private buildFieldCollapseWhenInactiveControl(meta: FormFieldMetadata, collapseWhenInactive?: boolean, alwaysExpanded?: boolean): UfControl {

        let control;

        if (!meta.collapseWhenInactive) {
            control = this.formBuilder.control(null);
        } else {

            control = this.formBuilder.control(collapseWhenInactive);

            const controlAccessor = new ControlAccessor(control);

            this.fieldsSubscriptions.get(meta.uuid)?.push(control.valueChanges.subscribe(collapseWhenInactiveValue => {

                if (!collapseWhenInactiveValue) {
                    return;
                }

                const hideWhenInactiveControl = controlAccessor.get(`../${FieldControlKeys.HideWhenInactive}`)[0];

                if (hideWhenInactiveControl) {
                    hideWhenInactiveControl.reset(undefined);
                }
            }));
        }

        if (this.isCollapseWhenInactiveDisabled(alwaysExpanded)) {
            control.disable();
        }

        return control;
    }

    private buildFieldHideWhenInactiveControl(meta: FormFieldMetadata, hideWhenInactive?: boolean): UfControl {

        let control;

        if (!meta.hideWhenInactive) {
            control = this.formBuilder.control(null);
        } else {

            control = this.formBuilder.control(hideWhenInactive);

            const controlAccessor = new ControlAccessor(control);

            this.fieldsSubscriptions.get(meta.uuid)?.push(control.valueChanges.subscribe(hideWhenInactiveValue => {

                if (!hideWhenInactiveValue) {
                    return;
                }

                const collapseWhenInactiveControl = controlAccessor.get(`../${FieldControlKeys.CollapseWhenInactive}`)[0];

                if (collapseWhenInactiveControl) {
                    collapseWhenInactiveControl.reset(undefined);
                }
            }));
        }
        return control;
    }

    private buildFieldRolesControl(show: boolean, roles: string[]): UfControl {

        let control;

        if (!show) {
            control = this.formBuilder.control(null);
        } else {
            control = this.formBuilder.control(
                roles,
                undefined,
                this.asyncRolesValidator
            );
        }

        if (this.setSubmitted) {
            control.setSubmitted(true);
        }

        return control;
    }

    private buildShowIfControl(meta: FormFieldMetadata, showIf?: string): UfControl {

        let control;

        if (!meta.showIf) {
            control = this.formBuilder.control(null);
        } else {
            control = this.formBuilder.control(showIf, ValidatorFunctions.compose([
                ValidatorFunctions.isValidExpression(this.expressionParser, 'Invalid expression'),
                c => this.expressionIdentifiersValidator(c, meta)
            ]), undefined, { deps: [this.status.fieldsIdentifier] });
        }

        if (this.setSubmitted) {
            control.setSubmitted(true);
        }

        return control;
    }

    private expressionIdentifiersValidator(control: AbstractControl, meta: FormFieldMetadata): ValidationErrors | null {

        return null;

        /*
        const expression = control.value as string | null;
        if (!expression) {
            return null;
        }

        const fieldControl = this.status.fieldByUuid.get(meta.uuid);
        if (!fieldControl) {
            return null;
        }

        const identifiersMap = FormEditorFunctions.getFieldIdentifiersScopes(this.fieldsScopeManager, fieldControl);
        const missings = FormEditorFunctions.getNonAvailableIdentifiers(expression, identifiersMap);
        return missings.length ? { message: `Undefined identifiers: ${missings.join(', ')}` } : null;
        */
    }

    private buildShowOnControl(meta: FormFieldMetadata, showOn?: string): UfControl {

        let control;

        if (!meta.showOn) {
            control = this.formBuilder.control(null);
        } else {

            const transitionsControlPath = `../../../${FieldControlKeys.Transitions}`;
            const controlAccessor = new ControlAccessor();

            control = this.formBuilder.control(showOn, ValidatorFunctions.compose([
                ValidatorFunctions.custom(v => !ValidatorFunctions.isEmpty(v), 'Show On is required'),
                ValidatorFunctions.noWhiteSpaces(`Can't contains white spaces`),
                ValidatorFunctions.custom(v => {

                    // console.log('ShowOn custom validator', v, controlAccessor.control);

                    if (!v || controlAccessor.control == null) {
                        return true;
                    }

                    const deps = controlAccessor.get(`${transitionsControlPath}[*].${TransitionControlKeys.Action}`);
                    const actions = deps.map(d => d.value);
                    const match = actions.includes(v);
                    // console.log('ShowOn.validate:', v, actions.join('|'), match);
                    return deps.map(c => c.value).includes(v);

                }, 'Show on must match a workflow action'),
            ]), undefined, { deps: [`${transitionsControlPath}`] }); /** [*] */

            controlAccessor.control = control;
        }

        if (this.setSubmitted) {
            control.setSubmitted(true);
        }

        return control;
    }

    private builAddressNestedControl(meta: FormFieldMetadata, addressNested?: FormAddressNestedFields): UfControlGroup {

        const control = this.formBuilder.group({});

        if (meta.addressNested && addressNested != null) {

            const nested: Dictionary<FormNestedField> = addressNested as any;

            for (const key of Object.keys(nested)) {
                control.addControl(key, this.buildNestedFieldControl(nested[key]));
            }
        }

        if (this.setSubmitted) {
            control.setSubmitted(true);
        }

        return control;
    }

    private builGeoLocationNestedControl(meta: FormFieldMetadata, geoLocationNested?: FormGeoLocationNestedFields): UfControlGroup {

        const control = this.formBuilder.group({});

        if (meta.geoLocationNested && geoLocationNested != null) {

            const nested: Dictionary<FormNestedField> = geoLocationNested as any;

            for (const key of Object.keys(nested)) {
                control.addControl(key, this.buildNestedFieldControl(nested[key]));
            }
        }

        if (this.setSubmitted) {
            control.setSubmitted(true);
        }

        return control;
    }

    private buildNestedFieldControl(nestedField: FormNestedField): UfControlGroup {

        const visibleControl = this.buildNestedVisibleFieldControl(nestedField.visible);
        const control = this.formBuilder.group({
            [NestedControlKey.Visible]: visibleControl,
            [NestedControlKey.Required]: [nestedField.required, ValidatorFunctions.custom(v =>
                !v || visibleControl.value === true, 'A required field must be visible too')],
            [NestedControlKey.ReadOnly]: nestedField.readOnly
        });

        // visibleControl.updateDependencies();

        if (this.setSubmitted) {
            control.setSubmitted(true);
        }

        return control;
    }

    private buildNestedVisibleFieldControl(visible: boolean): UfControl {

        const controlAccessor = new ControlAccessor();
        const requiredControlPath = `../${NestedControlKey.Required}`;

        const control = this.formBuilder.control(
            visible,
            ValidatorFunctions.custom(isVisible => {

                if (controlAccessor.control == null) {
                    return true;
                }

                const isRequired = controlAccessor.get(requiredControlPath)[0]?.value as boolean ?? false;

                if (!isVisible && isRequired) {
                    return false;
                }

                return true;
            }, 'A required field must be visible'),
            undefined,
            { deps: [requiredControlPath] });

        controlAccessor.control = control;

        if (this.setSubmitted) {
            control.setSubmitted(true);
        }

        return control;
    }

    private buildBindToControl(meta: FormFieldMetadata, bindTo?: string): UfControl {

        let control;

        if (!meta.bindTo) {
            control = this.formBuilder.control(null);
        } else {

            const isReadOnlyControlPath = `../${FieldControlKeys.IsReadOnly}`;
            const controlAccessor = new ControlAccessor();

            control = this.formBuilder.control(
                bindTo,
                ValidatorFunctions.compose([
                    ValidatorFunctions.isValidExpression(this.expressionParser, 'Invalid expression'),
                    ValidatorFunctions.custom(v => {
                        if (!controlAccessor.control) {
                            return true;
                        }
                        const isReadOnlyValue = controlAccessor.get(isReadOnlyControlPath)[0]?.value as boolean ?? false;
                        return ValidatorFunctions.isEmpty(v) || isReadOnlyValue === true;
                    }, 'bindTo only supported for isReadOnly field')
                ]),
                undefined,
                { deps: [isReadOnlyControlPath] }
            );

            controlAccessor.control = control;
        }

        control.addDependencies([this.status.fieldsIdentifier]);

        if (this.setSubmitted) {
            control.setSubmitted(true);
        }

        return control;
    }

    private fieldPositionValidator(control: AbstractControl): ValidationErrors {

        if (!(control instanceof UfControlGroup)) {
            return {};
        }
        if (!control.root.value.bucket) {
            return {};
        }
        if (!control.parent) {
            return {};
        }

        const fieldType = control.get(FieldControlKeys.Type)?.value as FieldType | null;
        if (!fieldType) {
            return {};
        }

        // console.log('fieldPositionValidator', control.value.type, control.root.value.bucket, control.parent);
        const innerDepth = FormEditorFunctions.getFieldControlInnerDept(control);
        const parentControl = control.parent?.parent as UfControlGroup | null;
        const parentDepth = parentControl ? FormEditorFunctions.getFieldControlDepth(parentControl) : undefined;
        const error = FormEditorFunctions.getFieldPoistionError(control, innerDepth, parentControl ?? undefined, parentDepth);
        // console.log(fieldType, innerDepth, parentType, parentDepth, error);

        return error ? { message: error } : {};
    }

    private checkIdentifierEditWarning(lastPublishedAtControl: UfControl) {
        if (lastPublishedAtControl.value) {
            this.toast.warning('Editing the identifier after publishing may cause problems.');
        }
    }

    private checkBucketEditWarning(lastPublishedAtControl: UfControl) {
        if (lastPublishedAtControl.value) {
            this.toast.warning('Editing the form data repository after publishing may cause problems.');
        }
    }




}