import { Location } from '@angular/common';
import { Component, HostBinding } from '@angular/core';


@Component({
    templateUrl: './unauthorized.html',
    styleUrls: ['./unauthorized.less']
})
export class UnauthorizedComponent {

    @HostBinding('class.stretch-component') class = true;

    constructor(private location: Location) { }

    back() {
        this.location.back();
    }

}
