import { Component, Inject } from '@angular/core';
import { Modal, ModalData, ModalRuntime } from '@unifii/library/common';
import { PrintConfig } from '@unifii/library/smart-forms/input';


/**
 * Displays form in a full screen wrapper
 */
@Component({
    templateUrl: './print-form-wrapper.html',
    styleUrls: ['./print-form-wrapper.less']
})
export class PrintFormWrapperComponent implements Modal<PrintConfig, void> {

    /** TODO: Should this be moved to the library? discussion */
    constructor(
        public runtime: ModalRuntime<PrintConfig, void>,
        @Inject(ModalData) public data: PrintConfig
    ) { }

    close() {
        setTimeout(() => {
            /**
             * todo: gettting application tick recursively
             * move to library debug this there!!!!!
             */
            this.runtime.close();
        }, 0);
    }

}
