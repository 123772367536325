<div class="header-pane uf-app-bar transparent">
    <uf-breadcrumbs [breadcrumbs]="breadcrumbs" class="grow large">
        <span *ngIf="edited">*</span>
    </uf-breadcrumbs>
    <div class="row space-children right">
        <button *ngIf="form && !typeControl.disabled" type="button" class="uf-action" (click)="paste()" title="Paste">
            <uf-icon name="paste"></uf-icon>
        </button>
        <a class="uf-button tertiary" routerLink="..">Cancel</a>
        <button class="uf-button primary" type="button" (click)="save()">Save</button>
    </div>
</div>
<uf-panel class="content-pane container">
    <div *ngIf="form" class="uf-container-lg">
        <div class="uf-grid row-gap-lg pad-top">
            <uf-message *ngIf="error" icon="errorSolid" [content]="error.message" class="col-12 error">
            </uf-message>
            <ng-container *ngIf="form" [formGroup]="form">
                <div class="col-12 uf-box">
                    <div class="uf-app-bar accent">
                        <div class="title">Details</div>
                    </div>
                    <div class="pad uf-grid">
                        <ng-container *ngIf="!typeControl.disabled; else descriptionList">
                            <uf-select class="col-12" [formControlName]="claimControlKeys.ValueType" label="Type"
                                [options]="valueTypeOptions" valueProperty="identifier"
                                (valueChange)="valueTypeChange($event)">
                            </uf-select>
                            <uf-text class="col-12" [formControlName]="claimControlKeys.Label" label="Label"
                                (valueChange)="changeIdentifier($event)"></uf-text>
                            <uf-text class="col-12" [formControlName]="claimControlKeys.Type" label="Identifier">
                            </uf-text>
                        </ng-container>
                        <ng-template #descriptionList>
                            <uf-dl class="col-12">
                                <dt>Type:</dt>
                                <dd>{{ valueTypeControl.value | fieldDisplay: fieldType.Choice:valueTypeOptions }}
                                </dd>
                                <uf-text class="col-12" [formControlName]="claimControlKeys.Label" label="Label"
                                    (valueChange)="changeIdentifier($event)"></uf-text>
                                <dt>Identifier:</dt>
                                <dd>{{ typeControl.value }}</dd>
                            </uf-dl>
                        </ng-template>
                        <uf-checkbox class="col-12" [formControlName]="claimControlKeys.IsRequired" label="Required">
                        </uf-checkbox>
                        <uf-checkbox *ngIf="!searchableControl.disabled" class="col-12"
                            [formControlName]="claimControlKeys.Searchable" label="Searchable">
                        </uf-checkbox>
                    </div>
                </div>
                <div class="col-12 uf-box gap-top"
                    *ngIf="claim.valueType === fieldType.Choice || claim.valueType === fieldType.MultiChoice">
                    <div class="uf-app-bar accent">
                        <div class="title">Options</div>
                    </div>
                    <uc-drag-list *ngIf="optionControls.length" [items]="optionControls" canDrop="false"
                        canReorder="true" class="pad">
                        <ng-container *ngFor="let option of optionControls; let i = index">
                            <div dragItem class="uf-box uf-list-fieldset flat draggable">
                                <div class="uf-grid pad" [formGroup]="option">
                                    <uf-text class="col-6" [formControlName]="claimOptionControlKeys.Display"
                                        label="Label" (valueChange)="optionLabelChange($event, option)">
                                    </uf-text>
                                    <uf-text class="col-6" [formControlName]="claimOptionControlKeys.Id"
                                        label="Identifier">
                                    </uf-text>
                                </div>
                                <div class="uf-app-bar flat transparent">
                                    <button class="uf-action" type="button" title="Delete"
                                        (click)="removeOption(i, option)">
                                        <uf-icon name="delete"></uf-icon>
                                    </button>
                                </div>
                            </div>
                        </ng-container>
                    </uc-drag-list>
                    <div class="row pad">
                        <button class="uf-button secondary right" type="button" (click)="addOption()">Add
                            Option</button>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</uf-panel>