<uc-user-access-box [tenantSettings]="settings">
    <ng-container content>

        <uf-message *ngIf="settings == null && error != null; else main" icon="errorSolid" class="error"
            [content]="error">
        </uf-message>

        <ng-template #main>

            <ng-container *ngIf="changePasswordControl.disabled; else isChangePassword">

                <form class="uf-grid" (ngSubmit)="login()">

                    <div class="col-12">
                        <h4>{{ settings?.name }}</h4>
                    </div>

                    <uf-text class="col-12" name="username" label="Username" [(value)]="username"
                        autocomplete="username" validators>
                    </uf-text>

                    <uf-password class="col-12" name="password" label="Password" [(value)]="password"
                        autocomplete="password" [showReveal]="true" validators>
                    </uf-password>

                    <div class="col-12 col center-all gap-sm-top">
                        <button type="submit" [disabled]="inProgress" class="uf-button primary">
                            Sign In
                        </button>
                    </div>

                    <ng-container *ngIf="authProviders?.length">
                        <div class="col-12 col center-all">
                            <span>or</span>
                        </div>
                        <div class="col-12 auth-providers col space-children">
                            <ng-container *ngFor="let provider of authProviders">
                                <div class="col">
                                    <label *ngIf="provider.providerLoginLabel"
                                        class="small">{{provider.providerLoginLabel}}</label>
                                    <button type="button" [disabled]="inProgress" class="authentication-provider grow"
                                        (click)="providerSignIn(provider)">
                                        <img [src]="provider.loginIcon" />
                                        <p class="small">{{provider.loginLabel}}</p>
                                    </button>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>

                    <uf-message *ngIf="error" class="error col-12" icon="errorSolid" [content]="error">
                    </uf-message>

                </form>

            </ng-container>

            <ng-template #isChangePassword>

                <form class="grid" (ngSubmit)="changePassword()">

                    <div class="col-1of1"></div>

                    <uf-create-password class="col-1of1" [control]="changePasswordControl"
                        [config]="changePasswordConfig">
                    </uf-create-password>

                    <div class="col-1of1 col center-all">
                        <button type="submit" [disabled]="inProgress" class="uf-button primary">
                            Submit
                        </button>
                        <a class="uf-button tertiary gap-top" [class.disabled]="inProgress"
                            (click)="changePasswordControl.disable()">
                            Back to login
                        </a>
                    </div>

                    <uf-message *ngIf="error" class="error col-1of1" icon="errorSolid" [content]="error">
                    </uf-message>

                </form>

            </ng-template>

        </ng-template>

    </ng-container>
</uc-user-access-box>