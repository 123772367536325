<div class="header">
    <button type="button" class="uf-action tertiary" (click)="close()" title="Close">
        <uf-icon name="close"></uf-icon>
    </button>
    <h3>{{data.title}}</h3>
</div>

<uf-panel class="container">
    <div [formGroup]="root" class="grid">
        <uf-autocomplete class="col-1of1" [label]="data.searchLabel" [placeholder]="data.searchPlaceHolder"
            (searchChange)="search($event)" [options]="contentsResult" (valueChange)="selected($event)"
            [nameProperty]="mapDescription" [formControlName]="searchControlKey" [autofocus]="true">
        </uf-autocomplete>

        <uf-autocomplete *ngIf="ucCollection != null" class="col-1of1" [label]="data.searchSecondLabel"
            [placeholder]="data.searchSecondPlaceHolder" (searchChange)="search($event)"
            [options]="contentsSecondResult" (valueChange)="selectedSecond($event)"
            [nameProperty]="data.searchSecondNameProperty" [formControlName]="searchSecondControlKey"
            [autofocus]="true">
        </uf-autocomplete>
    </div>
</uf-panel>

<div class="row space-children pad">
    <button type="button" class="uf-button tertiary right" (click)="close()">Cancel</button>
    <button type="button" class="uf-button primary" (click)="confirm()">Confirm</button>
</div>