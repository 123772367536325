import { lastValueFrom, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Client } from '@unifii/sdk';

import { UcProject } from 'client';


@Component({
    templateUrl: './import.html',
    styleUrls: ['./import.less']
})
export class ImportComponent implements OnInit, OnDestroy {

    @HostBinding('class.stretch-component') class = true;

    busy: boolean;
    messages: string[] = [];
    iframeUrl: SafeResourceUrl | null;

    private subscriptions = new Subscription();

    constructor(private ufProject: UcProject, private client: Client, private sanitizer: DomSanitizer) { }

    async ngOnInit() {

        this.iframeUrl = await this.getImportToolUrl();

        if (this.iframeUrl) {
            return;
        }

        // attempt to connecto to an ongoing stream
        try {
            const result = await lastValueFrom(this.ufProject.getImportProgress());

            this.busy = true;
            if (!this.messages.length) {
                this.messages.push('Import running ...');
            }

            this.messages.push(result);

        } catch (err) {
            console.log('err', err);
            // don't care about errors
        } finally {
            this.busy = false;
        }

    }

    async ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    async import() {
        this.messages = [];
        this.busy = true;
        try {
            await this.ufProject.import();
            this.messages.push('Import starting...');
            this.subscriptions.add(
                this.ufProject.getImportProgress().pipe(finalize(() => this.busy = false)).subscribe(s => this.messages.push(s)));
        } catch (e) {
            console.error(e);
        } finally {
            this.busy = false;
        }
    }

    private async getImportToolUrl(): Promise<SafeResourceUrl | null> {

        const projectInfo = await this.ufProject.get();

        if (!projectInfo.importToolUrl || !this.client.token) {
            return null;
        }

        const importToolUrl = new URL(projectInfo.importToolUrl);

        importToolUrl.searchParams.append('accessToken', this.client.token);

        return this.sanitizer.bypassSecurityTrustResourceUrl(importToolUrl.toString());
    }
}
