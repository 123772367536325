import { Context, DataSourceLoader, Scope, SourceConfig } from '@unifii/library/common';
import { DataSeed } from '@unifii/sdk';

import { SchemaInfo, UcProject } from 'client';


export class BucketLoader implements DataSourceLoader {

    sourceConfig: SourceConfig;

    constructor(private ucProject: UcProject) { }

    async search(q?: string): Promise<DataSeed[]> {
        return (await this.ucProject.getFormBuckets({ params: { q } })).map(this.mapToSeed);
    }

    async findAllBy(match: string): Promise<DataSeed[]> {
        console.warn('datasource does not support findByAll, falling back on search');
        return this.search(match);
    }

    getOptions(_context?: Context | undefined, _scope?: Scope | undefined): Promise<any> {
        throw new Error('method not implemented');
    }

    async get(id: string): Promise<DataSeed> {
        const schema = await this.ucProject.getBucket(id);
        return { _id: schema.bucket, _display: schema.bucket };
    }

    mapToSeed = (info: SchemaInfo): DataSeed => ({ _id: info.id, _display: info.id });

}
