<div class="uf-grid condensed">
    <uf-blockquote class="col-12 info gap-bottom" icon="infoSolid"
        content="You can add and reorder multiple email templates on top of the default template. The email will use the the top matching template based on order and conditions, or fall back to default.">
    </uf-blockquote>
    <uc-drag-list *ngIf="templatesControl.controls.length" [items]="templatesControl.controls" canDrop="false"
        canReorder="true" class="col-12">
        <ng-container *ngFor="let control of (templatesControl.controls | asUfControlsGroups); let i = index">
            <div dragItem>
                <ng-container [ngTemplateOutlet]="template" [ngTemplateOutletContext]="{i, control}">
                </ng-container>
            </div>
        </ng-container>
    </uc-drag-list>
    <div class="col-12">
        <ng-container [ngTemplateOutlet]="template"
            [ngTemplateOutletContext]="{control: defaultTemplateControl, isDefault: true}">
        </ng-container>
    </div>
    <div class="col-12 row gap-top">
        <button type="button" class="uf-button secondary right" (click)="addTemplate()" title="Add Template">
            Add Template
        </button>
    </div>
    <uf-error *ngIf="templatesControl.invalid" class="col-12 gap-top" [control]="templatesControl | asUfControlArray">
    </uf-error>
</div>


<ng-template #template let-i="i" let-control="control" let-isDefault="isDefault">
    <uf-expander class="uf-box flat gap-sm-top" [isExpanded]="isDefault ? false : expanderStates[i]">
        <div class="uf-app-bar pad-sm-left" expanderHeader dragHandle>
            <div class="grow title primary">
                {{control.get(templateControlKey.Title)?.value}}
            </div>
            <uf-icon name="error" class="error" *ngIf="control.invalid && control.submitted"></uf-icon>
            <button type="button" class="uf-action tertiary" (click)="preview(i, isDefault)" title="Preview">
                <uf-icon name="view"></uf-icon>
            </button>
            <button *ngIf="!isDefault" class="uf-action tertiary" (click)="removeTemplate(i)" title="Delete Template">
                <uf-icon name="delete"></uf-icon>
            </button>
        </div>
        <ng-container expanderBody>
            <div class="uf-grid pad" [formGroup]="control">
                <uf-text class="col-12" [formControlName]="templateControlKey.Title" label="Title">
                </uf-text>
                <uf-text class="col-12" [formControlName]="templateControlKey.Subject" label="Email Subject">
                </uf-text>
                <uf-markdown-editor class="col-12" [formControlName]="templateControlKey.IntroMessage"
                    label="Intro Message">
                </uf-markdown-editor>
                <uf-text class="col-12" [formControlName]="templateControlKey.ButtonLabel" label="Button Label">
                </uf-text>
                <uf-markdown-editor class="col-12" [formControlName]="templateControlKey.BodyMessage"
                    label="Body Message">
                </uf-markdown-editor>
                <uf-text class="col-12" [formControlName]="templateControlKey.BaseUrl" label="Base URL">
                    <uf-help content="Base URL from General Settings will be used if left blank."></uf-help>
                </uf-text>
                <uf-text class="col-12" label="Reply to" [formControlName]="templateControlKey.ReplyTo">
                    <uf-help content="Comma separate multiple email addresses."></uf-help>
                </uf-text>
                <ul class="uf-list uf-box col-12">
                    <ng-container
                        *ngIf="!isDefault && control.get(templateControlKey.Conditions); let conditionControls">
                        <li class="uf-list-fieldset"
                            *ngFor="let conditionControl of (conditionControls | asUfControlArray).controls | asUfControlsGroups; let j = index">
                            <div [formGroup]="conditionControl" class="uf-grid grow pad gaps">
                                <uf-select label="Condition Type" [formControlName]="conditionControlKey.ConditionType"
                                    class="col-12" (valueChange)="conditionTypeChanged($event, i, j)"
                                    [options]="conditionOptions" valueProperty="identifier" nameProperty="name">
                                </uf-select>
                                <div class="col-12 uf-grid" [formGroupName]="conditionControlKey.Claim"
                                    *ngIf="conditionControl.get(conditionControlKey.Claim)?.enabled">
                                    <uf-autocomplete label="Claim Type" class="col-6"
                                        (searchChange)="searchClaims($event)" [options]="claimOptions"
                                        [formControlName]="conditionClaimControlKey.Type" nameProperty="type">
                                    </uf-autocomplete>
                                    <uf-claim-field [formControlName]="conditionClaimControlKey.Value"
                                        [claimConfig]="conditionControl.get(conditionControlKey.Claim)?.get(conditionClaimControlKey.Type)?.value"
                                        class="col-6">
                                    </uf-claim-field>
                                </div>
                                <uf-autocomplete label="Role" class="col-12" (searchChange)="searchRoles($event)"
                                    *ngIf="conditionControl.get(conditionControlKey.Role)?.enabled"
                                    [options]="roleOptions" [formControlName]="conditionControlKey.Role">
                                </uf-autocomplete>
                            </div>
                            <div class="pad-sm">
                                <button type="button" *ngIf="!isDefault" class="uf-action tertiary"
                                    title="Delete Condition" (click)="removeCondition(i, j)">
                                    <uf-icon name="delete"></uf-icon>
                                </button>
                            </div>
                        </li>
                    </ng-container>
                </ul>
                <div class="col-12 row" *ngIf="!isDefault">
                    <button type="button" class="uf-button secondary right" (click)="addCondition(i)"
                        title="Add Condition">
                        Add Condition
                    </button>
                </div>
                <uf-error [control]="control.get(templateControlKey.Conditions)"></uf-error>
            </div>
        </ng-container>
    </uf-expander>
</ng-template>

<ng-template #header>

</ng-template>