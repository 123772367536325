import { Component, HostBinding, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CreatePasswordConfig, UfControl, UfFormBuilder, WindowWrapper } from '@unifii/library/common';
import {
    AppAuthProviderConfiguration, AuthProvider, AuthProviderConfiguration, Client, decrypt, Dictionary, PasswordDetails, TenantClient, TenantSettings
} from '@unifii/sdk';

import { Config } from 'app-config';
import { UcClient } from 'client';

import { mapProviderLoginLabel } from 'pages/users/provider-utils';

import { ContextService } from 'services/context.service';
import { Auth0DirectoryURL, AzureDirectoryURL, SSOService } from 'services/sso.service';


@Component({
    templateUrl: './login.html',
    styleUrls: ['./login.less', '../../styles/external-branding.less'],
})
export class LoginComponent implements OnInit {

    @HostBinding('class.stretch-component') class = true;

    readonly changePasswordConfig: CreatePasswordConfig = {
        showStrengthIndicator: true,
        canGenerate: true,
        labels: {
            message: 'You are required to update your password'
        }
    };

    inProgress = true;
    error: string | null;

    settings: TenantSettings | undefined;
    authProviders: AppAuthProviderConfiguration[];

    username: string;
    password: string;
    changePasswordControl: UfControl;

    constructor(
        @Inject(WindowWrapper) private window: Window,
        @Inject(Config) private config: Config,
        private router: Router,
        private route: ActivatedRoute,
        private client: UcClient,
        private sdkClient: Client,
        private tenantClient: TenantClient,
        private ssoService: SSOService,
        private context: ContextService,
        private ufb: UfFormBuilder
    ) {
        this.init(this.route.snapshot.queryParams);
    }

    ngOnInit() {
        this.changePasswordControl = this.ufb.control({ value: {}, disabled: true });
    }

    async login() {

        this.error = null;
        this.inProgress = true;

        try {
            await this.client.authenticate(this.username, this.password);
            const account = await this.client.getMyAccount();

            if (!account.changePasswordOnNextLogin) {
                this.redirect();
                return;
            }

            this.changePasswordControl.enable();

        } catch (error) {

            if (!error.message && error.data != null && error.data.error_description) {
                this.error = error.data.error_description;
            } else {
                this.error = error.message || 'Authentication failed';
            }
        } finally {
            this.inProgress = false;
        }
    }

    async changePassword() {

        if (this.changePasswordControl.invalid) {
            this.changePasswordControl.setSubmitted();
            return;
        }

        this.inProgress = true;
        this.error = null;

        const passwordDetails: PasswordDetails = {
            oldPassword: this.password,
            password: this.changePasswordControl.value?.password,
        };

        try {
            await this.client.updateMyPassword(passwordDetails);
            this.redirect();
        } catch (error) {

            if (error != null && error.message) {
                this.error = error.message;
            } else {
                this.error = 'Update password failed';
            }
        } finally {
            this.inProgress = false;
        }
    }

    protected async providerSignIn(provider: AuthProviderConfiguration) {

        this.inProgress = true;
        this.error = null;

        try {
            const redirectUrl = await this.ssoService.getProviderUrl(provider, this.responseURL);

            if (!redirectUrl) {
                throw new Error();
            }
            this.window.location.href = redirectUrl;

        } catch (e) {
            this.inProgress = false;
            this.error = `${provider.type} authentication failed`;
        }
    }

    private async init({ reason, code, state, provider_id }: Dictionary<string>) {
        try {
            this.settings = await this.tenantClient.getSettings();
            this.ssoService.authProviders = this.settings.authProviders || [];
            this.authProviders = this.ssoService.providerList.map((provider, _, providers) => mapProviderLoginLabel(provider, providers));

            // SSO sign on pass errors via the reason url param
            if (reason) {
                throw new Error(reason);
            }

            if (code) {
                await this.authorizeWithCode(code, provider_id, state);
            }

        } catch (error) {
            this.error = error.message || 'Tenant configuration not available';
        } finally {
            this.inProgress = false;
        }
    }

    private async authorizeWithCode(code: string, providerId?: string, state?: string): Promise<void> {

        try {
            let redirectUri;

            if (state) {
                const decoded = await this.decodeState(state);
                providerId = decoded.providerId;
                redirectUri = decoded.redirectUri;
            } else if (providerId) {
                redirectUri = this.getRedirectUri(providerId);
            }

            await this.sdkClient.authenticateWithCode(code, redirectUri, providerId);
            const account = await this.client.getMyAccount();

            if (!account.roles.length) {
                throw new Error('At least one role is required');
            }

            this.context.account = account;
            this.router.navigate(['']);

        } catch (error) {

            const provider = this.authProviders.find(p => `${p.id}` === providerId);
            const providerType = provider?.type || 'Extenal provider';
            const message = error.message || `${providerType} authentication failed`;
            throw new Error(message);
        }
    }

    private async decodeState(state: string): Promise<{ providerId: string; redirectUri: string }> {

        const decrypted = await decrypt({ byteString: decodeURIComponent(state), key: this.config.appId });
        const searchParams = new URLSearchParams(decodeURIComponent(decrypted));
        const providerId = searchParams.get('providerId') as string;
        const redirectUri = searchParams.get('redirectUri') as string;

        return {
            providerId, redirectUri
        };
    }

    private getRedirectUri(providerId: string): string {

        const provider = this.authProviders.find(p => '' + p.id === providerId);

        if (provider?.useDirectory === false) {
            return this.responseURL;
        }

        switch (provider?.type) {
            case AuthProvider.Azure: return AzureDirectoryURL;
            case AuthProvider.Auth0: return Auth0DirectoryURL;
            default: throw new Error('Invalid URL, could not match provider id');
        }
    }

    private get responseURL(): string {
        return `${this.window.location.origin}/sso`;
    }

    private redirect() {
        this.router.navigateByUrl(this.route.snapshot.params.next || '/');
    }

}
