import { amendOptionsParams, ClientDeleteOptions, ClientGetOptions, mergeParams } from '@unifii/sdk';

import { Permission, PermissionPrincipalType } from './models';
import { UcClient } from './uc-client';


export class UcPermissions {

    constructor(
        private client: UcClient,
        public entityType: PermissionPrincipalType,
        public entityId: string,
        public inherited?: boolean
    ) { }

    get(options?: ClientGetOptions): Promise<Permission[]> {
        const params = mergeParams(options?.params, { source: this.inherited ? 'inherited' : 'user' });
        return this.client.get(this.url(), amendOptionsParams(params, options));
    }

    getDetails(id: string, options?: ClientGetOptions): Promise<Permission> {
        return this.client.get(this.url(id), options);
    }

    save(permission: Permission): Promise<Permission> {
        return permission.id ?
            this.client.put(this.url(permission.id), permission) :
            this.client.post(this.url(), { body: permission });
    }

    delete(id: string, options?: ClientDeleteOptions): Promise<void> {
        return this.client.delete(this.url(id), options);
    }

    private url(...extra: string[]): string {

        let type: string | null = null;

        switch (this.entityType) {
            case PermissionPrincipalType.Role:
                type = 'roles';
                break;
            case PermissionPrincipalType.User:
                type = 'users';
                break;
            case PermissionPrincipalType.ApiKey:
                type = 'api-keys';
                break;
        }

        return this.client.buildUrl([type, this.entityId, 'permissions', ...extra]);
    }
}
