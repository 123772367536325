import { Component, HostBinding } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager } from '@unifii/components';

import { AppDevicesTableManager } from 'pages/apps/app-devices-table-manager';


@Component({
    template: `
        <uf-table-container ufMasterDetail (addItem)="addItem()" class="accent default pad-none list-md"></uf-table-container>
        <router-outlet></router-outlet>
    `,
    providers: [
        { provide: TableContainerManager, useClass: AppDevicesTableManager }
    ]
})
export class AppDevicesComponent {

    constructor(
        private route: ActivatedRoute,
        private router: Router
    ) { }

    addItem() {
        this.router.navigate(['./new'], { relativeTo: this.route });
    }
}
