import { Component, HostBinding, Inject } from '@angular/core';
import { Modal, ModalData, ModalRuntime, UfControl, UfControlGroup, ValidatorFunctions } from '@unifii/library/common';
import { Error, Option } from '@unifii/sdk';

import { Permission, PermissionPrincipalType, UcAPIKeys, UcClient, UcPermissions, UcRoles, UcUsers } from 'client';

import { PermissionChangeAction, PermissionsManagerService } from './permissions-manager.service';


@Component({
    templateUrl: './permissions-cloner.html',
    styleUrls: ['./permissions-cloner.less'],
})
export class PermissionsClonerComponent implements Modal<Permission[], void> {

    @HostBinding('class.uc-form-card') class = true;

    form: UfControlGroup;
    types: PermissionPrincipalType[] = [PermissionPrincipalType.User, PermissionPrincipalType.Role, PermissionPrincipalType.ApiKey];
    targets: Option[] = [];

    type: PermissionPrincipalType;
    target: Option | null;
    items: { permission: Permission; pathLabel: string; cloned?: boolean; error?: Error }[] = [];
    busy: boolean;
    cloned: boolean;

    private ucRoles: UcRoles;
    private ucPermissions: UcPermissions;

    constructor(
        public runtime: ModalRuntime<Permission[], void>,
        @Inject(ModalData) data: Permission[],
        private ucClient: UcClient,
        private ucUsers: UcUsers,
        private ucAPIKeys: UcAPIKeys,
        private permissionsManager: PermissionsManagerService
    ) {
        this.ucRoles = new UcRoles(this.ucClient);

        this.items = data.map(permission => ({ permission, pathLabel: (permission.path as string[]).join('/') }));

        this.buildControls();
    }

    changeType() {
        this.target = null;
    }

    filterTarget(query: string) {

        switch (this.type) {

            case PermissionPrincipalType.User:
                // Load users
                this.ucUsers.get(query, 'username').then(users =>
                    this.targets = users.map(user => ({ identifier: user.id as string, name: user.username }))
                );
                break;

            case PermissionPrincipalType.Role:
                // Load roles
                this.ucRoles.get(query, 'name').then(roles =>
                    this.targets = roles.map(role => ({ identifier: role.id as string, name: role.name }))
                );
                break;

            case PermissionPrincipalType.ApiKey:
                // Load api keys
                this.ucAPIKeys.get().then(apiKeys =>
                    this.targets = apiKeys.map(apiKey => ({ identifier: apiKey.key as string, name: apiKey.name }))
                );
                break;
        }
    }

    reset() {
        this.cloned = false;
        for (const item of this.items) {
            item.error = undefined;
            item.cloned = false;
        }
    }

    async clone() {
        this.form.setSubmitted();
        if (this.form.valid) {

            this.busy = true;

            this.items.forEach(i => {
                delete i.cloned;
                delete i.error;
            });

            this.ucPermissions = new UcPermissions(this.ucClient, this.type, (this.target as Option).identifier);

            let clonedCount = 0;

            for (const item of this.items) {
                const permission = Object.assign({}, item.permission);
                delete permission.id;
                try {
                    await this.ucPermissions.save(permission);
                    item.cloned = true;
                    clonedCount++;
                } catch (e) {
                    console.error(e);
                    item.error = e;
                }
            }

            if (clonedCount > 0) {
                this.permissionsManager.notify.next({
                    action: PermissionChangeAction.Added,
                    principalType: this.type,
                    principalId: (this.target as Option).identifier
                });
            }

            this.cloned = true;
            this.busy = false;
        }
    }

    close() {
        this.runtime.close();
    }

    private buildControls() {
        this.form = new UfControlGroup({
            type: new UfControl(ValidatorFunctions.custom(v => !ValidatorFunctions.isEmpty(v), 'A type is required')),
            target: new UfControl(ValidatorFunctions.custom(v => !ValidatorFunctions.isEmpty(v), 'A target is required'))
        });
    }

}
