<uf-panel *ngIf="!iframeUrl" class="container">
    <div class="header-pane uf-app-bar transparent">
        <uf-breadcrumbs [breadcrumbs]="[{ name: 'Import'}]" class="large"></uf-breadcrumbs>
    </div>

    <div class=" content-pane">

        <form>
            <uf-spinner *ngIf="busy"></uf-spinner>
            <ul class="uc-list col-1of1">
                <li class="uc-list-item" *ngFor="let msg of messages">
                    <pre>{{msg}}</pre>
                </li>
            </ul>
            <div class="row space-children col-1of1">
                <button type="button" class="uf-button primary right" (click)="import()">Start</button>
            </div>
        </form>

    </div>
</uf-panel>

<iframe *ngIf="iframeUrl" [src]="iframeUrl" frameBorder="0"></iframe>