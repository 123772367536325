<uf-expander class="uf-box flat gap-sm-bottom">
    <div expanderHeader class="uf-app-bar flat">
        <div class="title primary">Tables</div>
        <uf-icon *ngIf="control.invalid" name="error" class="error"></uf-icon>
        <uc-expander-controls *ngIf="control.length" [container]="tablesWrap">
        </uc-expander-controls>
    </div>

    <div expanderBody #tablesWrap class="pad small">
        <uf-spinner *ngIf="!ready"></uf-spinner>
        <ng-template [ngIf]="ready">
            <span *ngIf="!allTables.length" class="not-published">
                Please define Tables before adding them to a Dashboard
            </span>

            <ng-template [ngIf]="allTables.length">

                <uf-search-result *ngIf="!control.disabled" label="Tables" (valueChange)="add($event)"
                    (searchChange)="search($event)" [options]="searchResults" [nameProperty]="mapDescription"
                    placeholder="Search Tables" class="col-12 pad-bottom">
                </uf-search-result>

                <uc-drag-list *ngIf="control.length" [items]="control.controls" canDrop="false" canReorder="true">

                    <ng-template ngFor [ngForOf]="control.controls | asUfControlsGroups" let-table let-i="index">
                        <uf-expander dragItem [dragDisabled]="table.disabled"
                            class="uf-box flat draggable gap-sm-bottom">
                            <div expanderHeader dragHandle class="uf-app-bar flat">
                                <div class="title primary">
                                    {{getLabel(table)}}
                                </div>
                                <uf-icon *ngIf="table.invalid" name="error" class="error">
                                </uf-icon>
                                <a *ngIf="getTableLink(table)" [routerLink]="getTableLink(table)" target="_blank"
                                    class="uf-action tertiary" title="Open">
                                    <uf-icon name="open"></uf-icon>
                                </a>
                                <button *ngIf="!table.disabled" (click)="remove(i)" title="Delete"
                                    class="uf-action tertiary">
                                    <uf-icon name="delete"></uf-icon>
                                </button>
                            </div>
                            <div expanderBody [formGroup]="table" class="uf-grid pad">
                                <ng-template [ngIf]="findTableDefinition(table)" [ngIfElse]="tableNotFound">
                                    <uf-description-list [items]="getTableDescription(table)" class="col-12">
                                    </uf-description-list>
                                    <uf-number [formControlName]="formKeys.PageSize" label="Rows" precision="0"
                                        class="col-12">
                                    </uf-number>
                                </ng-template>
                                <ng-template #tableNotFound>
                                    <uf-message [content]="'Table \''+getTableIdentifier(table) + '\' not found'"
                                        class="error col-12">
                                    </uf-message>
                                </ng-template>

                            </div>
                        </uf-expander>
                    </ng-template>

                </uc-drag-list>

            </ng-template>
        </ng-template>
    </div>
</uf-expander>