<div class="uf-box col-12">

    <div class="uf-app-bar flat accent">
        <div class="title">
            Columns
        </div>
        <uc-expander-controls [container]="expandAllContainer" class="primary">
        </uc-expander-controls>
    </div>

    <div #expandAllContainer class="uc-group-content pad-sides pad-bottom pad-sm-top">
        <uc-drag-list *ngIf="cols.length" [items]="cols" (moved)="emit()" canDrop="false" canReorder="true">
            <ng-template ngFor let-colInfo [ngForOf]="cols" let-i="index">
                <uf-expander class="uf-box draggable flat gap-sm-top" [isExpanded]="false" dragItem>
                    <div class="uf-app-bar" expanderHeader dragHandle>
                        <uf-icon [name]="colInfo.icon"></uf-icon>
                        <div class="grow title primary">{{colInfo.label}}</div>
                        <span *ngIf="!colInfo.exists" class="uf-lozenge error">Missing</span>
                        <uf-icon *ngIf="hasVariations(colInfo)" name="variation"
                            title="This node has variations"></uf-icon>
                        <button class="uf-action tertiary" (click)="removeColumn(i)" title="Delete">
                            <uf-icon name="delete"></uf-icon>
                        </button>
                    </div>
                    <ng-container expanderBody>
                        <uc-column-descriptor-editor *ngIf="colInfo.exists" [(column)]="colInfo.descriptor"
                            [descriptor]="colInfo.dataPropertyDescriptor" [parentControl]="parentControl"
                            (columnChange)="emit()">
                        </uc-column-descriptor-editor>
                    </ng-container>
                </uf-expander>
            </ng-template>
        </uc-drag-list>
        <uf-search-result (valueChange)="addColumn({identifier: $event.identifier})"
            (searchChange)="filterColumns($event)" [options]="columnResults" placeholder="Search column"
            nameProperty="display" class="gap-top">
        </uf-search-result>
        <div class="row gap-top">
            <button class="uf-button right" type="button" (click)="addCustom()">Add Custom</button>
        </div>
    </div>

</div>