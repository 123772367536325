import { Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';

import { Component, HostBinding, OnDestroy } from '@angular/core';

import { UcClient } from 'client';

import { DragItemDirective } from 'components/dragdrop/drag-item.directive';


@Component({
    selector: 'uc-app',
    templateUrl: './app.html',
    styleUrls: ['./app.less'],
    providers: [DragItemDirective]
})
export class AppComponent implements OnDestroy {

    @HostBinding('class.stretch-component') class = true;
    @HostBinding('class.small') small = true;

    progressCount = 0;

    private subscriptions = new Subscription();

    constructor(
        private client: UcClient,
    ) {
        this.subscriptions.add(this.client.inProgress.pipe(delay(0)).subscribe(inProgress => {
            if (inProgress) {
                this.progressCount++;
            } else if (this.progressCount > 0) {
                this.progressCount--;
            }
        }));
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

}
