import { Inject, Injectable, InjectionToken } from '@angular/core';
import { HierarchyUnitProvider } from '@unifii/library/common';
import { HierarchyUnitExtended, Role, Schema } from '@unifii/sdk';

import {
    ConsoleDataSource, DefinitionInfo, Integration, UcClaimConfig, UcCompanyClaims, UcDataSources, UcDefinition, UcIntegrations, UcProject, UcRoles,
    UcUserClaims
} from 'client';

import { DataSourceEditorCache } from 'components/field-builder/data-source-editor/data-source-model';


export interface FormEditorCache {
    getSchema(bucket?: string | null): Promise<Schema | null>;
    getCollectionDefinition(identifier: string): Promise<UcDefinition | null>;
    getExternalDataSource(identifier: string): Promise<ConsoleDataSource | null>;
    getHierarchyUnit(id: string): Promise<HierarchyUnitExtended | undefined>;
    listCompanyClaimConfig(): Promise<UcClaimConfig[]>;
    listUserClaimConfig(): Promise<UcClaimConfig[]>;
    getRoles(): Promise<Role[]>;
    getFormsInfo(): Promise<DefinitionInfo[]>;
    reset(): void;
}
export const FormEditorCache = new InjectionToken<FormEditorCache>('FormEditorCache');

@Injectable()
export class FormEditorCacheService implements FormEditorCache, DataSourceEditorCache {

    private schemas: Map<string, Promise<Schema | null>> = new Map();
    private collectionDefinitions: Map<string, Promise<UcDefinition | null>> = new Map();
    private externalDataSources: Map<string, Promise<ConsoleDataSource | null>> = new Map();
    private integrations: Map<string, Promise<Integration | null>> = new Map();
    private hierarchyUnits: Map<string, Promise<HierarchyUnitExtended | undefined>> = new Map();
    private formsInfo?: Promise<DefinitionInfo[]>;
    private companyClaimConfig?: Promise<UcClaimConfig[]>;
    private userClaimConfig?: Promise<UcClaimConfig[]>;
    private roles?: Promise<Role[]>;


    constructor(
        private ucProject: UcProject,
        private ucRoles: UcRoles,
        private ucDataSources: UcDataSources,
        private companyClaimsClient: UcCompanyClaims,
        private userClaimsClient: UcUserClaims,
        private ucIntegrations: UcIntegrations,
        @Inject(HierarchyUnitProvider) private hierarchyUnitProvider: HierarchyUnitProvider,
    ) { }

    reset() {
        this.schemas = new Map();
        this.collectionDefinitions = new Map();
        this.externalDataSources = new Map();
        this.integrations = new Map();
        this.hierarchyUnits = new Map();
        delete this.formsInfo;
        delete this.companyClaimConfig;
        delete this.userClaimConfig;
        delete this.roles;
    }

    async getSchema(bucket?: string | null): Promise<Schema | null> {

        return new Promise(resolve => {

            if (!bucket) {
                resolve(null);
                return;
            }

            const stored = this.schemas.get(bucket);

            if (stored !== undefined) {
                resolve(stored);
                return;
            }

            try {
                const loaded = this.ucProject.getBucket(bucket).catch(() => null);
                this.schemas.set(bucket, loaded);
            } catch (e) {
                console.warn('FormEditorCache schema not found for bucket', bucket, e);
                this.schemas.set(bucket, Promise.resolve(null));
            } finally {
                resolve(this.schemas.get(bucket) ?? null);
            }

            resolve(null);
        });
    }

    async getCollectionDefinition(identifier: string): Promise<UcDefinition | null> {

        return new Promise(resolve => {

            if (!identifier) {
                resolve(null);
                return;
            }

            const stored = this.collectionDefinitions.get(identifier);

            if (stored !== undefined) {
                resolve(stored);
                return;
            }

            try {
                const loaded = this.ucProject.collection(identifier).getDefinition().catch(() => null);
                this.collectionDefinitions.set(identifier, loaded);
            } catch (e) {
                console.warn('FormEditorCache collection definition not found for', identifier, e);
                this.collectionDefinitions.set(identifier, Promise.resolve(null));
            } finally {
                resolve(this.collectionDefinitions.get(identifier) ?? null);
            }

            resolve(null);
        });
    }

    async getExternalDataSource(identifier: string): Promise<ConsoleDataSource | null> {

        return new Promise(resolve => {

            if (!identifier) {
                resolve(null);
                return;
            }

            const stored = this.externalDataSources.get(identifier);

            if (stored !== undefined) {
                resolve(stored);
                return;
            }

            try {
                const loaded = this.ucDataSources.get(identifier).catch(() => null);
                this.externalDataSources.set(identifier, loaded);
            } catch (e) {
                console.warn('FormEditorCache dataSource not found for', identifier, e);
                this.externalDataSources.set(identifier, Promise.resolve(null));
            } finally {
                resolve(this.externalDataSources.get(identifier) ?? null);
            }

            resolve(null);
        });
    }

    async getIntegration(id: string): Promise<Integration | null> {

        return new Promise(resolve => {

            if (!id) {
                resolve(null);
                return;
            }

            const stored = this.integrations.get(id);

            if (stored !== undefined) {
                resolve(stored);
                return;
            }

            try {
                const loaded = this.ucIntegrations.get(id).catch(() => null);
                this.integrations.set(id, loaded);
            } catch (e) {
                console.warn('FormEditorCache integration not found for', id, e);
                this.integrations.set(id, Promise.resolve(null));
            } finally {
                resolve(this.integrations.get(id) ?? null);
            }

            resolve(null);
        });
    }

    async getHierarchyUnit(id: string): Promise<HierarchyUnitExtended | undefined> {

        return new Promise(resolve => {

            if (!id) {
                resolve(undefined);
                return;
            }

            const stored = this.hierarchyUnits.get(id);

            if (stored !== undefined) {
                resolve(stored);
                return;
            }

            try {
                const loaded = this.hierarchyUnitProvider.getUnit(id).catch(() => undefined);
                this.hierarchyUnits.set(id, loaded);
            } catch (e) {
                console.warn('FormEditorCache hierarchy unit not found for', id, e);
                this.hierarchyUnits.set(id, Promise.resolve(undefined));
            } finally {
                resolve(this.hierarchyUnits.get(id) ?? undefined);
            }

            resolve(undefined);
        });
    }

    async listCompanyClaimConfig(): Promise<UcClaimConfig[]> {

        if (this.companyClaimConfig == null) {
            this.companyClaimConfig = this.companyClaimsClient.list({ params: { limit: 1000 } });
        }

        return this.companyClaimConfig;
    }

    async listUserClaimConfig(): Promise<UcClaimConfig[]> {

        if (this.userClaimConfig == null) {
            this.userClaimConfig = this.userClaimsClient.list({ params: { limit: 1000 } });
        }

        return this.userClaimConfig;
    }

    async getRoles(): Promise<Role[]> {

        if (!this.roles) {
            this.roles = this.ucRoles.get(undefined, undefined, { params: { limit: 1000 } });
        }

        return this.roles;
    }

    async getFormsInfo(): Promise<DefinitionInfo[]> {

        if (this.formsInfo) {
            return this.formsInfo;
        }

        this.formsInfo = this.ucProject.getForms({ params: { limit: 1000 } });
        return this.formsInfo;
    }

    async getCompanyClaimConfig(id: string): Promise<UcClaimConfig | null> {
        const claims = await this.listCompanyClaimConfig();
        return claims.find(c => c.id === id) ?? null;
    }

    async getUserClaimConfig(id: string): Promise<UcClaimConfig | null> {
        const claims = await this.listUserClaimConfig();
        return claims.find(c => c.id === id) ?? null;
    }
}