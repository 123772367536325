import { Subject } from 'rxjs';

import { inject, Injectable } from '@angular/core';
import { TableContainerManager, TableInputManager, TableInputs } from '@unifii/components';
import { FilterEntry, FilterValue, HierarchyUnitProvider, TableConfig } from '@unifii/library/common';

import { IntegrationInfo, UcIntegrations } from 'client';

import { SystemIntegrationsDataSource } from './system-integrations-datasource';


@Injectable()
export class SystemIntegrationsTableManager implements TableContainerManager<IntegrationInfo, FilterValue, FilterEntry> {

    tableConfig: TableConfig<IntegrationInfo>;
    showSearch = true;
    addActionConfig = true; // TODO, handle using AddActionConfig
    reload = new Subject<void>();
    inputManager: TableInputManager<FilterValue, FilterEntry> = new TableInputManager([], inject(HierarchyUnitProvider));

    constructor(
        private ucIntegrations: UcIntegrations
    ) {
        this.tableConfig = {
            columns: [{
                name: 'providerName',
                label: 'Provider',
                value: item => item.provider.name
            }],
            row: {
                image: item => item.provider?.logo,
                label: item => item.name,
                link: item => item.id as string
            }
        };
    }

    createDataSource({ q }: TableInputs<any> = {}) {
        return new SystemIntegrationsDataSource(this.ucIntegrations, q);
    }


}