<ng-template [ngIf]="ready">

    <div class="header">
        <button type="button" class="uf-action tertiary" (click)="close()" title="Close">
            <uf-icon name="close"></uf-icon>
        </button>
        <h3>{{label}}</h3>
    </div>

    <uf-panel class="container">

        <div [formGroup]="control" class="uf-grid pad-sides pad-bottom">


            <uf-autocomplete label="Start Status" placeholder="Start" [formControlName]="transitionKeys.Source"
                (searchChange)="findStartStatuses($event)" [options]="startStatusesResult" [allowCustom]="true"
                class="col-6">
            </uf-autocomplete>

            <uf-autocomplete label="Target Status" [formControlName]="transitionKeys.Target"
                (searchChange)="findEndStatuses($event)" [options]="endStatusesResult" [allowCustom]="true"
                class="col-6">
            </uf-autocomplete>

            <uf-text label="Button Label" placeholder="Request" [formControlName]="transitionKeys.ActionLabel"
                class="col-6">
            </uf-text>

            <uf-text label="Action" placeholder="Request" [formControlName]="transitionKeys.Action" class="col-6">
            </uf-text>

            <uf-chips label="Roles" [formControlName]="transitionKeys.Roles" (searchChange)="findRoles($event)"
                [options]="rolesResult" class="col-6">
            </uf-chips>

            <uf-text label="Result" [formControlName]="transitionKeys.Result" class="col-6">
            </uf-text>

            <uf-checkbox label="Validate Required Fields" [formControlName]="transitionKeys.Validate" class="col-12">
            </uf-checkbox>

            <uf-chips label="Tags" [formControlName]="transitionKeys.Tags" [options]="tagsResult"
                (searchChange)="findTags($event)" [allowCustom]="true" class="col-12">
            </uf-chips>

            <uf-textarea label="Show if" [formControlName]="transitionKeys.ShowIf" class="col-12">
            </uf-textarea>

        </div>

        <div class="uf-grid pad-sides">

            <div class="col-12 uf-box flat" *ngFor="let spawn of spawns.controls | asUfControlsGroups; index as i">
                <uc-form-field-transition-target [control]="spawn" [forms]="formDefinitionInfos" [parentSchema]="schema"
                    [showFieldMappings]="true" (remove)="removeSpawn(i)">
                </uc-form-field-transition-target>
            </div>
            <div class="col-12 row">
                <button type="button" class="uf-button right" (click)="addSpawn()">
                    Add Trigger with Data Mapping
                </button>
            </div>

            <div class="col-12 uf-box flat" *ngFor="let trigger of triggers.controls | asUfControlsGroups; index as i">
                <uc-form-field-transition-target [control]="trigger" [forms]="formDefinitionInfos"
                    [parentSchema]="schema" [showCondition]="true" [startTransitionsOnly]="true"
                    (remove)="removeTrigger(i)">
                </uc-form-field-transition-target>
            </div>

        </div>

    </uf-panel>

    <uf-error [control]="control" class="col-12 center-all gap-left"></uf-error>

    <div class="row space-children pad">
        <button type="button" class="uf-button tertiary right" (click)="close()">Cancel</button>
        <button type="button" class="uf-button primary" (click)="save()">Save</button>
    </div>

</ng-template>