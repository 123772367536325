<uf-expander *ngIf="configuredField" [class.uc-group--draggable--no-shrink]="canDragField && !isSelected"
    [class.uc-group--draggable--no-shrink--accent]="canDragField && isSelected" class="uc-group">
    <div dragHandle expanderHeader (click)="selectField($event)" [class.accent]="isSelected" class="uf-app-bar flat">
        <uf-icon name="collectionLink" />
        <div class="title primary">
            Collection | {{collectionLabel}}
        </div>
        <uf-icon *ngIf="!isValid" name="warning" class="icon-warning" />
        <uf-help *ngIf="configuredField.help" [content]="configuredField.help" class="uf-action tertiary" />
        <a *ngIf="canRemoveField" (click)="removeField($event)" title="Delete" class="uf-action tertiary">
            <uf-icon name="delete" />
        </a>
    </div>
    <div expanderBody class="uc-group-content">
        <!-- to do replace with list from library -->
        <uc-drag-list *ngIf="content" canDrop="false" [canReorder]="canEditField" [items]="content" [parent]="content"
            (moved)="moved()">
            <ul class="uc-list--gap">
                <li [class.uc-list-item--small--no-shrink--bordered]="disabled"
                    [class.uc-list-item--draggable--small--no-shrink]="!disabled"
                    *ngFor="let link of content, let i = index" dragItem [dragDisabled]="disabled"
                    class="row center-all">
                    <div class="list-content">
                        <div class="list-context">
                            <a [routerLink]="getLink(link)" target="_blank" title="Open" class="uf-action tertiary">
                                <uf-icon name="open" />
                            </a>
                            <button *ngIf="canEditField" (click)="delete(i)" tabindex="-1" title="Delete"
                                class="uf-action tertiary">
                                <uf-icon name="delete" />
                            </button>
                        </div>
                        <div>{{link.recordName}}</div>
                    </div>
                </li>
            </ul>
        </uc-drag-list>
        <div *ngIf="searchConfig" class="row padded">
            <button *ngIf="canEditField" (click)="add()" class="uf-button x-small right">{{searchConfig.title}}</button>
        </div>
    </div>
</uf-expander>