import { Component, Input, Optional } from '@angular/core';
import { ExpandersService } from '@unifii/library/common';


@Component({
    selector: 'uc-expander-controls',
    templateUrl: './expander-controls.html',
    styleUrls: ['./expander-controls.less']
})
export class ExpanderControlsComponent {

    @Input() container: HTMLElement;
    @Input() className: string | string[];

    constructor(@Optional() private service?: ExpandersService) {
    }

    expand() {
        this.service?.expandAll(this.container);
    }

    collapse() {
        this.service?.collapseAll(this.container);
    }

}
