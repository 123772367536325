import { Inject, Injectable } from '@angular/core';
import { DataDescriptor, DataDescriptorClaimsType, DataDescriptorService, DataPropertyDescriptor, getEntriesMap } from '@unifii/library/common';
import { DataSourceType, FieldType, Option } from '@unifii/sdk';

import { Config } from 'app-config';
import { ConsoleDataSource, TenantSettings, UcCompanyClaims, UcDataSources, UcProject, UcUserClaims } from 'client';

import { DataSourceTypeLabelPipe } from 'pipes/data-source-type-label.pipe';

import { ContextService } from 'services/context.service';

import { DataSourceDisplayLabel, DataSourceIdLabel, isValidTypeAndIdConfiguration } from './data-source-editor-functions';
import { DataSourceEditorStatus } from './data-source-editor-status';
import { DataSourceEditorCache, ExternalInfo } from './data-source-model';


@Injectable()
export class DataSourceEditorService {

    constructor(
        @Inject(Config) private config: Config,
        private status: DataSourceEditorStatus,
        @Inject(DataSourceEditorCache) private cache: DataSourceEditorCache,
        private project: UcProject,
        private companyClaimsClient: UcCompanyClaims,
        private userClaimsClient: UcUserClaims,
        private ucDataSources: UcDataSources,
        private dataDescriptorService: DataDescriptorService,
        private context: ContextService,
        private dataSourceTypeLabel: DataSourceTypeLabelPipe
    ) { }

    async searchResources(type: DataSourceType, q?: string): Promise<Option[]> {

        const query = q ? q.trim().toLowerCase() : undefined;

        switch (type) {

            case DataSourceType.Collection:
                if (!this.status.collections) {
                    this.status.collections = this.project.getCollections();
                }

                return (await this.status.collections)
                    .filter(i => !query || i.name.toLowerCase().indexOf(query) >= 0)
                    .map(i => ({ identifier: i.identifier, name: i.name }));

            case DataSourceType.Bucket:
                return (await this.project.getFormBuckets({ params: { q } }))
                    .map(i => ({ identifier: i.id, name: i.id }));

            case DataSourceType.CompanyClaims:
                if (!this.status.companyClaims) {
                    this.status.companyClaims = this.companyClaimsClient.list();
                }

                return (await this.status.companyClaims)
                    .filter(i => [FieldType.Choice, FieldType.MultiChoice].includes(i.valueType) && (!query || i.label.toLowerCase().indexOf(query) >= 0))
                    .map(i => ({ identifier: i.id as string, name: i.label }));

            case DataSourceType.UserClaims:
                if (!this.status.userClaims) {
                    this.status.userClaims = this.userClaimsClient.list();
                }

                return (await this.status.userClaims)
                    .filter(i => [FieldType.Choice, FieldType.MultiChoice].includes(i.valueType) && (!query || i.label.toLowerCase().indexOf(query) >= 0))
                    .map(i => ({ identifier: i.id as string, name: i.label }));

            case DataSourceType.External:
                return (await this.ucDataSources.list(q))
                    .map(i => ({ identifier: i.id, name: i.name }));

            default:
                return [];
        }
    }

    async loadResource(type?: DataSourceType, id?: string): Promise<Option | null> {

        if (!type || !id) {
            return null;
        }

        switch (type) {
            case DataSourceType.Bucket:
                const schema = await this.cache.getSchema(id);
                return schema ? { identifier: schema.bucket, name: schema.bucket } : null;

            case DataSourceType.Collection:
                const def = await this.cache.getCollectionDefinition(id);
                return def ? { identifier: def.identifier, name: def.label } : null;

            case DataSourceType.External:
                const ds = await this.cache.getExternalDataSource(id);
                return ds ? { identifier: ds.id as string, name: ds.name } : null;

            case DataSourceType.CompanyClaims:
                const cc = await this.cache.getCompanyClaimConfig(id);
                return cc ? { identifier: cc.id, name: cc.label } : null;

            case DataSourceType.UserClaims:
                const uc = await this.cache.getUserClaimConfig(id);
                return uc ? { identifier: uc.id as string, name: uc.label } : null;
        }

        return null;
    }

    async loadDescriptor(type?: DataSourceType | null, id?: string): Promise<DataDescriptor | undefined> {

        if (!isValidTypeAndIdConfiguration(type, id)) {
            return;
        }

        switch (type) {
            case DataSourceType.Bucket:
                return this.dataDescriptorService.getBucketDataDescriptor(id as string) ?? null;

            case DataSourceType.Collection:
                return this.dataDescriptorService.getCollectionDataDescriptor(id as string) ?? null;

            case DataSourceType.Company:
                return this.dataDescriptorService.getCompanyDataDescriptor();

            case DataSourceType.Users:
                return this.dataDescriptorService.getUserDataDescriptor();

            case DataSourceType.UserClaims:
            case DataSourceType.CompanyClaims:
                const entries: DataPropertyDescriptor[] = [{
                    type: FieldType.Text, identifier: 'id', label: DataSourceIdLabel, display: `${DataSourceIdLabel} (id)`,
                    asDisplay: true, asSearch: false, asSort: false, asInputFilter: false, asStaticFilter: false
                },
                {
                    type: FieldType.Text, identifier: 'display', label: DataSourceDisplayLabel, display: `${DataSourceDisplayLabel} (display)`,
                    asDisplay: true, asSearch: false, asSort: false, asInputFilter: false, asStaticFilter: false
                }];
                return {
                    type: DataDescriptorClaimsType,
                    propertyDescriptors: entries,
                    propertyDescriptorsMap: getEntriesMap(entries)
                };

            default:
                return;
        }
    }

    async loadExternalInfo(type?: DataSourceType | null, id?: string): Promise<ExternalInfo | undefined> {

        if (type !== DataSourceType.External || !id) {
            return;
        }

        const dataSource = await this.cache.getExternalDataSource(id as string);
        if (!dataSource) {
            return;
        }

        const integration = await this.cache.getIntegration(dataSource.integrationId);
        if (!integration) {
            return;
        }

        const feature = integration.provider.features.find(f => f.id === (dataSource as ConsoleDataSource).featureId);
        if (!feature) {
            return;
        }

        return { dataSource, feature };
    }

    async getAllowedTypesOption(): Promise<Option[]> {

        const aclDisabled = !(this.context.tenantSettings as TenantSettings).isAccessControlEnabled && (this.config.env === 'prod' || this.config.env === 'uat');

        const types = Object.keys(DataSourceType).filter((type: DataSourceType) => {

            // Drop support for Company and CompanyClaims DataSource types
            if ([DataSourceType.Company, DataSourceType.CompanyClaims].includes(type)) {
                return false;
            }

            // Exclude some dataSource types if ACL's are not enabled
            if (aclDisabled && ![DataSourceType.Collection, DataSourceType.Named, DataSourceType.External].includes(type)) {
                return false;
            }

            // Otherwise allowed
            return true;
        });

        return types.map(type => ({
            identifier: type,
            name: this.dataSourceTypeLabel.transform(type as DataSourceType)
        }));
    }
}