import { Component } from '@angular/core';

import { TabsPage } from 'pages/tabs-page';

import { BreadcrumbService } from 'services/breadcrumb.service';


@Component({
    templateUrl: './settings.html'
})
export class SettingsComponent extends TabsPage {
    breadcrumbs = this.breadcrumbService.getBreadcrumbs(this.route);

    constructor(
        private breadcrumbService: BreadcrumbService
    ) {
        super();
    }
}
