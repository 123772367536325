import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Inject, Injectable, OnDestroy } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { ModalService, Repository, WindowWrapper } from '@unifii/library/common';

import { Config } from 'app-config';

import { TenantSettingsService } from 'services/tenant-settings.service';

import { RequiredUpdateModalComponent } from './required-update-modal.component';


const LastUpdateRequestKey = 'UfLastUpdateRequest';
const ForcedUpdateRequestKey = 'UfForcedUpdateRequest';

@Injectable()
export class AppUpdateService implements OnDestroy {

    private subscriptions = new Subscription();

    constructor(
        @Inject(Config) private config: Config,
        @Inject(WindowWrapper) private window: Window,
        private repo: Repository,
        private modalService: ModalService,
        private router: Router,
        private tenantSettings: TenantSettingsService
    ) { }

    init() {

        this.checkAppInfo().catch(() => { });

        this.subscriptions.add(this.router.events
            .pipe(filter(event => event instanceof NavigationStart))
            .subscribe(() => {
                this.checkAppInfo().catch(() => { });
            }));
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    private async checkAppInfo() {

        const appInfo = (await this.tenantSettings.sync()).appInfo;

        // guard no appInfo
        if (!appInfo) {
            return;
        }

        // current version below minVersion
        if (!this.compareVersion(this.config.version, appInfo.minVersion)) {

            const previousForcedUpdateVersion = this.repo.loadString(ForcedUpdateRequestKey);

            // try reload page once
            if (!previousForcedUpdateVersion || !this.compareVersion(ForcedUpdateRequestKey, appInfo.minVersion)) {
                this.repo.storeString(ForcedUpdateRequestKey, appInfo.minVersion);
                this.window.location.reload();
                return;
            }

            // after reload, block with modal
            this.modalService.openFullScreen(
                RequiredUpdateModalComponent, appInfo, { guard: true }
            );

            return;

        }

        // current version below latestVersion
        if (!this.compareVersion(this.config.version, appInfo.latestVersion)) {

            // if previous version is up to date with latest version dont update
            const previousPopupVersion = this.repo.loadString(LastUpdateRequestKey);

            if (previousPopupVersion && this.compareVersion(previousPopupVersion, appInfo.latestVersion)) {
                return;
            }

            // try reload page once
            this.repo.storeString(LastUpdateRequestKey, appInfo.latestVersion);
            this.window.location.reload();
            return;

        }
    }

    // returns true is current version is same or greater than minimum, or if not able to compare.
    private compareVersion(currentVersion: string, minimumVersion: string): boolean {
        const current = this.splitVersion(currentVersion);
        const minimum = this.splitVersion(minimumVersion);

        // no version
        if (!current || !minimum) {
            return true;
        }

        if (current.major > minimum.major) {
            return true;
        }

        if (current.major < minimum.major) {
            return false;
        }

        if (current.minor > minimum.minor) {
            return true;
        }

        if (current.minor < minimum.minor) {
            return false;
        }

        if (current.build > minimum.build) {
            return true;
        }

        if (current.build < minimum.build) {
            return false;
        }

        if (!isNaN(current.revision) && !isNaN(minimum.revision)) {

            if (current.revision > minimum.revision) {
                return true;
            }

            if (current.revision < minimum.revision) {
                return false;
            }
        }

        // same number
        return true;
    }

    private splitVersion(version: string): { major: number; minor: number; build: number; revision: number } | null {

        const array = version.split('.');

        const major = parseInt(array[0]);
        const minor = parseInt(array[1]);
        const build = parseInt(array[2]);
        const revision = parseInt(array[3]);

        if (isNaN(major) || isNaN(minor) || isNaN(build)) {
            return null;
        }

        return { major, minor, build, revision };


    }

}