<div class="header-pane uf-app-bar transparent">
    <uf-breadcrumbs [breadcrumbs]="breadcrumbs" class="large">
        <span *ngIf="edited">*</span>
    </uf-breadcrumbs>
    <button type="button" class="uf-button right" (click)="test()">Test
        Connection</button>
    <a class="uf-button tertiary" [routerLink]="['../']">Cancel</a>

    <button type="button" class="uf-button primary" [disabled]="!integration || loading"
        (click)="submit()">Save</button>
</div>

<uf-panel class="content-pane container">
    <div class="uf-container-lg">
        <div class="uf-grid row-gap-lg pad-top">

            <ng-container *ngIf="integration">

                <div class="uf-box col-12">
                    <div class="uf-app-bar flat accent">
                        <div class="title">Provider Details</div>
                    </div>
                    <div class="content uf-grid pad">
                        <uf-description-list class="col-10 row-gap-sm">
                            <dt>Provider Name</dt>
                            <dd>{{ $any(integration.provider).name}}</dd>
                            <dt>Provider Description</dt>
                            <dd>{{$any(integration.provider).description}}</dd>
                        </uf-description-list>

                        <div class="col-2 row">
                            <img *ngIf="$any(integration.provider)?.logo" class="intergration-logo right"
                                [src]="$any(integration.provider).logo | bypassSanitizeUrl">
                            <img *ngIf="!$any(integration.provider)?.logo" class="intergration-logo right"
                                src="/assets/svg/icon-integrations.svg">
                        </div>
                    </div>

                    <ng-container *ngIf="lookupFeatures?.length">
                        <div class="uf-app-bar transparent">
                            <div class="title">Incoming Data Features</div>
                        </div>
                        <div class="content uf-grid pad">
                            <ul class="uf-list uf-box flat col-12">
                                <li *ngFor="let feature of lookupFeatures" class="uf-list-item">
                                    <uf-icon *ngIf="!feature.configurable" name="download"></uf-icon>
                                    <uf-checkbox *ngIf="feature.configurable"
                                        [value]="!$any(integration.featureConfig)[feature.id].disabled"
                                        (valueChange)="$any(integration.featureConfig)[feature.id].disabled = !$event">
                                    </uf-checkbox>
                                    <div class="multi-text">{{feature.name}}</div>
                                </li>
                            </ul>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="sinkFeatures?.length">
                        <div class="uf-app-bar transparent">
                            <div class="title">Outgoing Data Features</div>
                        </div>
                        <div class="content uf-grid pad">
                            <ul class="uf-list uf-box flat col-12">
                                <li *ngFor="let feature of sinkFeatures" class="uf-list-item">
                                    <uf-icon *ngIf="!feature.configurable" name="upload"></uf-icon>
                                    <uf-checkbox *ngIf="feature.configurable"
                                        [value]="!$any(integration.featureConfig)[feature.id].disabled"
                                        (valueChange)="$any(integration.featureConfig)[feature.id].disabled = !$event">
                                    </uf-checkbox>
                                    <div class="multi-text">{{feature.name}}</div>
                                </li>
                            </ul>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="ticketProviderFeatures?.length">
                        <div class="uf-app-bar transparent">
                            <div class="title">Ticket Providers</div>
                        </div>
                        <div class="content uf-grid pad">
                            <ul class="uf-list uf-box flat col-12">
                                <ng-container *ngFor="let feature of ticketProviderFeatures">
                                    <li class="uf-list-item">
                                        {{feature.name}}
                                    </li>
                                </ng-container>
                            </ul>
                        </div>
                    </ng-container>

                    <!-- <ng-container *ngIf="validatorFeatures?.length">
                            <div class="uf-app-bar transparent">
                                <div class="title">Validators Features</div>
                            </div>
                            <div class="content uf-grid">

                                <ul class="uf-list col-12">
                                    <li *ngFor="let feature of validatorFeatures" class="uf-list-item">
                                        <uf-icon *ngIf="!feature.configurable" name="upload"></uf-icon>
                                        <uf-checkbox *ngIf="feature.configurable"
                                            [value]="!$any(integration.featureConfig)[feature.id].disabled"
                                            (valueChange)="$any(integration.featureConfig)[feature.id].disabled = !$event">
                                        </uf-checkbox>
                                        <div class="multi-text">{{feature.name}}</div>
                                    </li>
                                </ul>
                            </div>
                        </ng-container> -->
                </div>

                <div class="uf-box col-12">
                    <div class="uf-app-bar flat accent">
                        <div class="title">
                            Integration Configuration
                        </div>
                    </div>
                    <div class="content uf-grid pad-sides pad-top condensed">
                        <uf-text class="col-12" label="Integration Name" [control]="control"
                            [(value)]="integration.name">
                        </uf-text>
                        <div class="col-12">
                            <uf-form *ngIf="definition" [config]="formConfig" [definition]="definition"
                                (formDataChange)="onFormDataChange()" [(formData)]="integration.config">
                            </uf-form>
                        </div>
                    </div>
                </div>
            </ng-container>

            <div class="col-12" *ngIf="error">
                <uf-blockquote class="error-text" icon="errorSolid" [content]="error.message"></uf-blockquote>
            </div>

        </div>
    </div>
</uf-panel>