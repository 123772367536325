<uf-panel class="container">
    <div class="header-pane uf-app-bar transparent">
        <uf-breadcrumbs [breadcrumbs]="breadcrumbs" class="large">
        </uf-breadcrumbs>
    </div>
    <div class="content-pane">
        <div class="uf-container-lg">
            <div class="uf-grid gaps">

                <uf-message *ngIf="status && statusError" class="error col-12" icon="errorSolid"
                    [content]="statusError.message">
                </uf-message>

                <ng-container *ngIf="status && !statusError">

                    <form class="uf-form-card col-12 col-lg-6" (submit)="publish({ isPreview: true })">
                        <div class="pad-sides grow">
                            <h3>Preview</h3>
                            <uf-description-list
                                *ngIf="status.preview && !(status.pending != null && status.pending?.preview != null)">
                                <dt>Current Preview:</dt>
                                <dd>
                                    {{status.preview.version}}-preview.{{status.preview.preview}}
                                </dd>
                                <dt>Published on:</dt>
                                <dd>
                                    {{status.preview.publishedAt | momentDate:'eee, MMM d, yyyy h:mm a' }}
                                </dd>
                            </uf-description-list>
                            <uf-message *ngIf="previewPublishError" class="error pad-top" icon="errorSolid"
                                [content]="previewPublishError.message">
                            </uf-message>
                            <br>
                            <uf-spinner *ngIf="inProgress && status.pending?.preview != null"></uf-spinner>
                        </div>
                        <div class="row space-children pad">
                            <button type="submit" class="uf-button primary right" restrict="Publisher"
                                [disabled]="inProgress">
                                Publish Preview
                            </button>
                        </div>
                    </form>

                    <form class="uf-form-card col-12 col-lg-6" (submit)="publish()">
                        <div class="pad-sides grow">
                            <h3>Stable</h3>
                            <uf-description-list
                                *ngIf="status.stable && !(status.pending != null && status.pending?.preview == null) && !downloadInProgress">
                                <dt>Current Stable:</dt>
                                <dd>{{ status.stable.version}}</dd>
                                <dt>Published on:</dt>
                                <dd>
                                    {{status.stable.publishedAt | momentDate:'eee, MMM d, yyyy h:mm a' }}
                                </dd>
                            </uf-description-list>
                            <uf-message *ngIf="stablePublishError || downloadError" class="error pad-top"
                                icon="errorSolid" [content]="stablePublishError?.message || downloadError?.message">
                            </uf-message>
                            <uf-spinner *ngIf="inProgress && status.pending?.preview == null"></uf-spinner>
                            <br>
                        </div>
                        <div class="row space-children pad">
                            <button type="button" class="uf-button right" [disabled]="inProgress"
                                *ngIf="status.stable && artifact" (click)="getAppPackage(status.stable.version)">
                                Get AppPackage
                            </button>
                            <button type="submit" class="uf-button primary"
                                [ngClass]="{right: !status.stable || !artifact}" restrict="Publisher"
                                [disabled]="inProgress">
                                Publish Stable
                            </button>
                        </div>
                    </form>

                </ng-container>
            </div>
        </div>
    </div>
</uf-panel>