import { Component, HostBinding, Inject } from '@angular/core';
import { TableContainerManager } from '@unifii/components';
import { ModalService, ToastService } from '@unifii/library/common';
import { CompoundType, Definition, FieldType, FormData, ValidatorType } from '@unifii/sdk';

import { UcRoles } from 'client';

import { ModalFormComponent, ModalFormData } from 'components/content/modals/modal-form.component';

import { RolesTableManager } from './roles-table-manager';


@Component({
    template: `
        <div ufMasterDetail>
            <uf-table-container ufSyncRoute (addItem)="addRole()" class="accent list-md pad-none"></uf-table-container>
        </div>
        <router-outlet></router-outlet>
    `,
    providers: [
        { provide: TableContainerManager, useClass: RolesTableManager }
    ]
})
export class RolesComponent {

    @HostBinding('class.stretch-component') class = true;

    constructor(
        private ucRoles: UcRoles,
        private modalService: ModalService,
        private toastService: ToastService,
        @Inject(TableContainerManager) private tableManager: RolesTableManager
    ) { }

    async addRole() {
        const data: ModalFormData = {
            title: 'New Role',
            definition: this.roleFormDefinition
        };

        const formData = await this.modalService.openMedium<ModalFormData, FormData>(ModalFormComponent, data);
        if (!formData) {
            return;
        }

        try {
            await this.ucRoles.save({ name: formData.name, description: formData.description });
            this.toastService.success('Role added');
            this.tableManager.reload.next();
        } catch (error) {
            this.toastService.error(error.message);
        }
    }

    private get roleFormDefinition(): Definition {
        return {
            identifier: null as any as string,
            label: null as any as string,
            compoundType: CompoundType.Form,
            fields: [{
                identifier: 'name',
                label: 'Name',
                type: FieldType.Text,
                isRequired: true,
                validators: [{ type: ValidatorType.Pattern, value: `^[A-Za-z][A-Za-z0-9_]*$`, message: `Name contains an invalid character` }]
            }, {
                identifier: 'description',
                label: 'Description',
                type: FieldType.MultiText
            }]
        };
    }

}
