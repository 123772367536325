import { UfControlGroup } from '@unifii/library/common';
import { sortRoles } from '@unifii/library/smart-forms';




export const sortValue = (group: UfControlGroup, key: string) => {
    const control = group.get(key);
    if (control && Array.isArray(control.value)) {
        control.setValue(control.value.sort(sortRoles), { emitEvent: false });
    }
};