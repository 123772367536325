import { Injectable } from '@angular/core';
import { appendParamsToUrl, ClientDeleteOptions, ClientGetOptions, Device, mergeParams, UserAuthProvider, UserInfo } from '@unifii/sdk';

import { DefaultPaginationParams } from 'constant';

import { PermissionPrincipalType } from './models';
import { UcPermissions } from './permissions';
import { UcClient } from './uc-client';


export interface UserQueryParams {
    isExternal?: boolean;
    isActive?: boolean;
    hasPassword?: boolean;
    roles?: string | string[];
    systemRoles?: string | string[];
}

export interface UcUserAuthProvider extends UserAuthProvider {
    id: string;
    lockedSystemRoles: string[];
}

@Injectable()
export class UcUsers {

    constructor(private ucClient: UcClient) { }

    get(q?: string, sort?: string, params?: UserQueryParams, options?: ClientGetOptions): Promise<UserInfo[]> {

        const mergedParams = mergeParams(
            DefaultPaginationParams,
            options?.params,
            params as Record<string, unknown>,
            { q, sort }
        );

        return this.ucClient.get(this.url(), { ...options, params: mergedParams });
    }

    getDetails(id: string, options?: ClientGetOptions): Promise<UserInfo> {
        return this.ucClient.get(this.url(id), options);
    }

    getDevices(id: string, options?: ClientGetOptions): Promise<Device[]> {
        const params = mergeParams(DefaultPaginationParams, options?.params);
        return this.ucClient.get(this.url(id, 'devices'), { ...options, params });
    }

    getAuthProviders(id: string, options?: ClientGetOptions): Promise<UcUserAuthProvider[]> {
        return this.ucClient.get(this.url(id, 'auth-providers'), options);
    }

    save(user: UserInfo): Promise<UserInfo> {
        if (user.id) {
            return this.ucClient.put(this.url(user.id), user);
        }
        return this.ucClient.post(this.url(), { body: user });
    }

    delete(id: string, options?: ClientDeleteOptions): Promise<void> {
        return this.ucClient.delete(this.url(id), options);
    }

    permissions(id: string): UcPermissions {
        return new UcPermissions(this.ucClient, PermissionPrincipalType.User, id);
    }

    getDownloadUrl(q?: string, sort?: string, params?: UserQueryParams, offset = 0, limit = 10000) {

        params = { ...params };
        params.roles = this.toString(params.roles);
        params.systemRoles = this.toString(params.systemRoles);

        const mergedParams = mergeParams(params as Record<string, unknown>, { q, sort, _format: 'csv', offset, limit });

        return appendParamsToUrl(this.url(), mergedParams);
    }

    private url(...extra: string[]): string {
        const urlParts = ['users', ...extra];
        return this.ucClient.buildUrl(urlParts);
    }

    private toString(param?: string | string[]): string | undefined {
        if (Array.isArray(param)) {
            return param.join(', ');
        }
        return param;
    }
}
