import { Inject, Injectable } from '@angular/core';
import { FeatureFlagProvider } from '@unifii/library/common';
import { Dictionary } from '@unifii/sdk';

import { Config, ConsoleOptions } from 'app-config';


@Injectable()
export class UcFeatureFlagProvider implements FeatureFlagProvider {

    constructor(@Inject(Config) private config: ConsoleOptions) { }

    async get(): Promise<Dictionary<boolean>> {
        return this.config.flags;
    }
}