<div class="header-pane uf-app-bar transparent">
    <uf-breadcrumbs [breadcrumbs]="breadcrumbs" class="large">
        <span class="gap-sm-left">{{ edited ? '*' : ''}}</span>
    </uf-breadcrumbs>
    <a class="uf-button tertiary right" routerLink="..">
        Cancel
    </a>
    <button type="button" class="uf-button primary" (click)="save()">
        Save
    </button>
</div>
<uf-panel *ngIf="form" class="content-pane container">
    <div [formGroup]="form" class="uf-container-lg">
        <div class="uf-grid row-gap-lg pad-top">
            <div class="uf-box col-12">
                <div class="uf-app-bar flat accent">
                    <div class="title">
                        Details
                    </div>
                    <!-- [label]="commonTK.UsernameLabel | translate" -->
                </div>
                <div class="uf-grid pad">
                    <uf-text class="col-12" label="Title" [formControlName]="controlKeys.Label">
                        <span class="suffix">*</span>
                    </uf-text>
                    <uf-autocomplete class="col-12" label="Form Data Repository"
                        placeholder="Search form data repository" [allowCustom]="false"
                        (searchChange)="searchBuckets($event)" nameProperty="id" [options]="bucketsResult"
                        [formControlName]="controlKeys.Bucket">
                    </uf-autocomplete>
                </div>
            </div>
            <!-- Only show when you select a bucket  -->
            <div class="uf-box col-12">
                <div class="uf-app-bar flat accent">
                    <div class="title">
                        Timer
                    </div>
                    <!-- [label]="commonTK.UsernameLabel | translate" -->
                </div>
                <div class="uf-grid pad">
                    <uf-textarea class="col-12" label="Run At (Expression)" [formControlName]="controlKeys.Expression">
                        <span class="suffix">*</span>
                    </uf-textarea>
                </div>
            </div>
            <uf-message class="error col-12" *ngIf="error" [content]="error" icon="error">
            </uf-message>
        </div>
    </div>
</uf-panel>