import { Router } from '@angular/router';


/** Force reload of the current route */
export const reloadCurrentRoute = (router: Router) => {

    const shouldReuseRoute = router.routeReuseStrategy.shouldReuseRoute;
    const onSameUrlNavigation = router.onSameUrlNavigation;
    router.routeReuseStrategy.shouldReuseRoute = () => false;
    router.onSameUrlNavigation = 'reload';
    const currentUrl = router.url;

    const url = router.url.substring(0, router.url.lastIndexOf('/'));

    router.navigateByUrl(url ?? '/', { skipLocationChange: true }).then(() => router.navigateByUrl(currentUrl)).then(() => {
        router.routeReuseStrategy.shouldReuseRoute = shouldReuseRoute;
        router.onSameUrlNavigation = onSameUrlNavigation;
    });
};