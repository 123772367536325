<ng-template [ngIf]="isInput">
    <uf-radio [value]="value ?? entry?.emptyOption?.identifier" valueProperty="identifier" [options]="options"
        columns="1" (valueChange)="valueChanged($event)">
    </uf-radio>
</ng-template>

<div *ngIf="isDisplay && !isEmpty" class="uf-chip x-small">
    {{label}}:{{ displayValue }}
    <button (click)="remove()" type="button" class="uf-action">
        <uf-icon name="delete"></uf-icon>
    </button>
</div>