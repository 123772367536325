<ng-template ngFor [ngForOf]="groups" let-group>
    <uf-expander *ngIf="group.title && group.items.length " class="gap-bottom-sm" class="uf-box flat">
        <div expanderHeader class="uf-app-bar flat">
            <span class="title primary">{{ group.title }}</span>
        </div>
        <div expanderBody class="pad-none gap-none">
            <ng-template ngFor let-item [ngForOf]="group.items">
                <div class="uf-box draggable uf-app-bar flat gap-sm" [dragItem]="item">
                    <uf-icon [name]="item.icon"></uf-icon>
                    <div class="title primary">{{ item.label }}</div>
                    <a *ngIf="item.deletable" class="uf-action tertiary" (click)="onDelete(item)" title="Delete">
                        <uf-icon name="delete"></uf-icon>
                    </a>
                </div>
            </ng-template>
        </div>
    </uf-expander>
    <ng-template [ngIf]="group.title == null">
        <ng-template ngFor let-item [ngForOf]="group.items">
            <div class="uf-box draggable uf-app-bar flat gap-sm" [dragItem]="item">
                <uf-icon [name]="item.icon"></uf-icon>
                <div class="title primary">{{ item.label }}</div>
                <a *ngIf="item.deletable" class="uf-action tertiary" (click)="onDelete(item)" title="Delete">
                    <uf-icon name="delete"></uf-icon>
                </a>
            </div>
        </ng-template>
    </ng-template>
</ng-template>