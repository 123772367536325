import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Directive, HostBinding, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';


@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[ufMasterDetail]'
})
export class MasterDetailDirective implements OnDestroy {

    subscriptions: Subscription[] = [];
    isDetails: boolean;

    @HostBinding('style')
    get hideMaster()/*: SafeStyle CLI warning not exported*/ {

        const style = this.isDetails ? `
        height: 0;
        width: 0;
        overflow: hidden;
        visibility: hidden;` : ``;

        return this.sanitizer.bypassSecurityTrustStyle(style);
    }

    constructor(
        private sanitizer: DomSanitizer,
        private router: Router,
        private route: ActivatedRoute
    ) {

        this.subscriptions.push(
            this.router.events.pipe(
                filter(e => e instanceof NavigationEnd))
                .subscribe(() => this.isDetails = !!this.route.children.length)
        );

        this.isDetails = !!this.route.children.length;
    }

    ngOnDestroy() {
        for (const subscription of this.subscriptions) {
            subscription.unsubscribe();
        }
    }
}
