<div class="header-pane uf-app-bar transparent">
    <a class="uf-button tertiary right" [routerLink]="['/']">Cancel</a>
    <button type="button" *ngIf="canDelete" class="uf-button secondary right" (click)="delete()">Delete</button>
    <button type="button" class="uf-button secondary" (click)="sendTest()">Test</button>
    <button type="button" class="uf-button primary" (click)="save()">Save</button>
</div>

<uf-panel class="content-pane content">
    <div *ngIf="!loading" class="uf-container-lg">
        <div class="uf-grid row-gap-lg pad-top">

            <uf-blockquote *ngIf="error && error.message" class="col-12 error" icon="errorSolid"
                [content]="error.message">
            </uf-blockquote>

            <div class="uf-box col-12">
                <div class="uf-app-bar flat accent">
                    <div class="title">
                        SMTP Set Up
                    </div>
                </div>
                <div class="content uf-grid pad">
                    <uf-text class="col-9" label="Host" [control]="form.controls.smtpHost | asUfControl">
                    </uf-text>
                    <uf-number class="col-3" label="Port" [control]="form.controls.smtpPort | asUfControl">
                    </uf-number>
                    <uf-text class="col-6" label="Username" [control]="form.controls.smtpUsername | asUfControl">
                    </uf-text>
                    <uc-secret-input class="col-6" label="Password" [control]="form.controls.smtpPassword | asUfControl"
                        [value]="form.controls.smtpPassword.value">
                    </uc-secret-input>
                    <uf-text class="col-12" label="System Name" [control]="form.controls.smtpSystemName | asUfControl">
                    </uf-text>
                    <uf-email class="col-12" label="System Email"
                        [control]="form.controls.smtpSystemEmail | asUfControl">
                    </uf-email>
                </div>
            </div>
        </div>
    </div>
</uf-panel>