import { Component, Input } from '@angular/core';
import { ClipboardService, DescriptionListItem, UfControlGroup } from '@unifii/library/common';
import { StructureNode, StructureNodeType } from '@unifii/sdk';

import { StructureNodeControlKeys } from '../structure-control-keys';
import { StructureEditorService } from '../structure-editor.service';
import { StructureFunctions } from '../structure-functions';


@Component({
    selector: 'uc-node-settings',
    templateUrl: './node-settings.html',
})
export class NodeSettingsComponent {

    readonly formKeys = StructureNodeControlKeys;

    ready: boolean;

    // Computed info
    appUrl: DescriptionListItem | null;
    canCopy = false;
    showTags: boolean;
    showDefinitionIdentifier: boolean;
    showURL: boolean;

    tagOptions: string[] = [];

    private _control: UfControlGroup;

    constructor(
        private service: StructureEditorService,
        private clipboard: ClipboardService
    ) { }

    @Input()
    set control(v: UfControlGroup) {

        this._control = v;

        // Reset
        this.ready = false;

        this.canCopy = this.service.canCopy(this.node, v) && [StructureNodeType.IFrame].includes(this.node.type);
        this.showTags = [StructureNodeType.View, StructureNodeType.Empty, StructureNodeType.Custom].includes(this.node.type);
        this.showDefinitionIdentifier = this.node.type === StructureNodeType.Custom;
        this.showURL = [StructureNodeType.IFrame, StructureNodeType.Link].includes(this.node.type);

        const appUrl = this.getNodeUrlParts(this.node)?.join('/');
        if (appUrl) {
            this.appUrl = { term: 'App URL', description: appUrl };
        }

        this.ready = true;
    }

    get control() {
        return this._control;
    }

    get node(): StructureNode {
        return this.control.getRawValue() as StructureNode;
    }

    get isInvalid(): boolean {
        const invalidName = this.control.get(StructureNodeControlKeys.Name)?.invalid ?? false;
        const invalidUrl = this.showURL && (this.control.get(StructureNodeControlKeys.Url)?.invalid ?? false);
        const invalidIdentifier = this.showDefinitionIdentifier && (this.control.get(StructureNodeControlKeys.DefinitionIdentifier)?.invalid ?? false);

        return invalidName || invalidUrl || invalidIdentifier;
    }

    copy() {
        this.service.copyAndInsert(this.node, this.control);
    }

    async copyAppURL() {
        if (this.appUrl?.description) {
            this.clipboard.setText(`${this.appUrl.description}`);
        }
    }

    searchTags() {
        this.tagOptions = [];
    }

    private getNodeUrlParts(node: StructureNode): string[] | undefined {

        if (node == null || StructureFunctions.isNodeRoot(node) || [StructureNodeType.Empty, StructureNodeType.Link].indexOf(node.type) > -1) {
            return;
        }

        const prefix = ['/n', node.nodeId as string];

        switch (node.type) {
            case StructureNodeType.Page:
            case StructureNodeType.View:
            case StructureNodeType.Collection:
            case StructureNodeType.Form:
            case StructureNodeType.FormBucket:
                return [...prefix, node.definitionIdentifier as string];
            case StructureNodeType.CollectionItem:
                return [...prefix, node.definitionIdentifier as string, (node.id as number).toString()];
            case StructureNodeType.Dashboard:
                return [...prefix, 'dashboard'];
            case StructureNodeType.Custom:
                return [...prefix, 'custom', node.definitionIdentifier as string];
            case StructureNodeType.IFrame:
                return [...prefix, 'iframe'];
            case StructureNodeType.PdfViewer:
                return [...prefix, 'pdf-viewer', node.id?.toString() as string];
            default:
                return [...prefix];
        }
    }
}
