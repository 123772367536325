import { Subject } from 'rxjs';

import { Inject, inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DownloadConfig, TableContainerManager, TableInputManager, TableInputs, TableManagerFunctions } from '@unifii/components';
import {
    CellDisplayDescriptor, FieldDisplayPipe, FilterEntries, FilterEntry, FilterValue, FromNowPipe, HierarchyFunctions, HierarchyUnitProvider,
    TableConfig, TableConfigColumn, TableRowContext, ToastService
} from '@unifii/library/common';
import { sortRoles } from '@unifii/library/smart-forms';
import { CellTemplateType, ClaimConfig, Client, Dictionary, getUserStatus, MessageColour, UserInfo, UserStatus } from '@unifii/sdk';

import { UcUserInfo, UcUsers } from 'client';
import { TABLE_SEARCH_MIN_LENGTH } from 'constant';

import { UserDataSource } from 'pages/users/users-table-data-source';

import { DialogsService } from 'services/dialogs.service';


@Injectable()
export class UsersTableManager implements TableContainerManager<UcUserInfo, FilterValue, FilterEntry> {

    tableConfig: TableConfig<UcUserInfo>;
    defaultSort = 'username';
    showSearch = true;
    searchMinLength = TABLE_SEARCH_MIN_LENGTH;
    addActionConfig = true;
    downloadConfig: DownloadConfig;

    customColumns: CellDisplayDescriptor[] = [{
        name: 'status',
        variations: [{
            condition: `isActive == true`,
            template: {
                type: CellTemplateType.Lozenge,
                colour: MessageColour.Success
            }
        }, {
            condition: `hasPassword == false && isActive == false && isExternal == false`,
            template: {
                type: CellTemplateType.Lozenge,
                colour: MessageColour.Warning
            }
        }, {
            condition: `isActive == false`,
            template: {
                type: CellTemplateType.Lozenge
            }
        }]
    }];

    tableChange = new Subject<TableInputs<FilterValue>>();
    reload = new Subject<void>();
    update = new Subject<TableInputs<FilterValue>>();
    updateItem = new Subject<UcUserInfo>();
    inputManager: TableInputManager<FilterValue, FilterEntry>;

    private dataSource: UserDataSource;

    constructor(
        route: ActivatedRoute,
        private client: Client,
        private ucUsers: UcUsers,
        private fromNowPipe: FromNowPipe,
        @Inject(FilterEntries) entries: FilterEntry[],
        private displayPipe: FieldDisplayPipe,
        private dialogs: DialogsService,
        private toastService: ToastService
    ) {
        const { claimConfig, companiesEnabled } = route.snapshot.data.tableData;
        const columns = this.getColumns(claimConfig, companiesEnabled);
        const tableConfig = TableManagerFunctions.createTableConfig(columns, 'users-table');
        tableConfig.row = { link: element => '' + element.id };
        tableConfig.actions = [{
            label: 'Delete',
            predicate: row => getUserStatus(row.$implicit) === UserStatus.Pending,
            action: rows => this.delete((rows as TableRowContext<UcUserInfo>[]).map(row => row.$implicit))
        }];
        tableConfig.selectable = true;

        this.tableConfig = tableConfig;

        this.downloadConfig = {
            name: 'users.csv',
            getUrl: this.getDownloadUrl.bind(this)
        };

        this.inputManager = new TableInputManager(entries, inject(HierarchyUnitProvider));

    }

    createDataSource(inputs: TableInputs<FilterValue> = {}) {
        const { status, ...params } = this.inputManager.serializeInputs(inputs);
        let extras: Dictionary<boolean> = {};
        if (status != null) {
            extras = this.getStatus(status as UserStatus);
        }

        this.dataSource = new UserDataSource(this.ucUsers, { ...params, ...extras });
        return this.dataSource;
    }

    async getDownloadUrl(): Promise<string | null> {
        const url = this.dataSource.getDownloadUrl();
        if (url) {
            const downloadToken: { token: string } = await this.client.getDownloadToken(url);
            return url + '&_dlt=' + downloadToken.token;
        }
        return null;
    }

    private getColumns(claimConfig: ClaimConfig[], companiesEnabled: boolean): TableConfigColumn<UcUserInfo>[] {
        const columns = [{
            name: 'username',
            label: 'Username',
            sortable: true
        }, {
            name: 'firstName',
            label: 'First Name',
            sortable: true
        }, {
            name: 'lastName',
            label: 'Last Name',
            sortable: true
        }, {
            name: 'email',
            label: 'Email',
            hidden: true,
            sortable: true
        }, {
            name: 'manager',
            label: 'Manager',
            hidden: true,
            value: (user: UcUserInfo) => `${user?.manager?.firstName ?? ''} ${user?.manager?.lastName ?? ''}`
        }, {
            name: 'roles',
            label: 'Roles',
            hidden: true,
            value: (user: UcUserInfo) => user.roles?.sort(sortRoles).join(', ')
        }, {
            name: 'systemRoles',
            label: 'System Roles',
            hidden: true,
            value: (user: UcUserInfo) => user.systemRoles?.sort(sortRoles).join(', ')
        }, {
            name: 'lastModified',
            label: 'Last Modified',
            hidden: true,
            value: (user: UcUserInfo) => this.fromNowPipe.transform(user.lastModifiedAt)
        }, {
            name: 'status',
            label: 'Status',
            value: getUserStatus
        }, {
            name: 'logins',
            label: 'Identity Provider',
            value: (user: UcUserInfo) => (user.logins || []).map(login => login.authProvider).filter(login => !!login).join(', ')
        }, {
            name: 'isExternal',
            label: 'Authentication',
            value: (user: UcUserInfo) => user.isExternal ? 'External' : 'Internal'
        }, {
            name: 'unitPaths',
            label: 'Hierarchies',
            value: (user: UcUserInfo) => (user.unitPaths || []).map((path: any) => HierarchyFunctions.pathToDisplay(path)).join(', ')
        },
        ...claimConfig.map(c => this.claimConfigToColumn(c))
        ];

        if (companiesEnabled) {
            columns.push({
                name: 'company',
                label: 'Company',
                value: (user: UcUserInfo) => user.company?.name
            });
        }
        return columns;
    }

    private claimConfigToColumn(config: ClaimConfig): TableConfigColumn<UserInfo> {
        return {
            name: config.type,
            label: config.label || config.type,
            hidden: true,
            value: (user: UcUserInfo) => {
                const values = (user.claims || []).filter(claim => claim.type === config.type).map(value => value.value);
                return this.displayPipe.transform(values.length === 1 ? values[0] : values, config.valueType);
            }
        };
    }

    private async delete(users: UcUserInfo[]): Promise<void> {
        if (!await this.dialogs.confirmDelete()) {
            return;
        }

        const errors: string[] = [];

        for (const { id, username } of users) {
            try {
                await this.ucUsers.delete(id as string);
            } catch (e) {
                errors.push(username);
            }
        }

        if (errors.length) {
            this.toastService.error(`Error: could not delete users: ${errors.join(', ')}`);
        } else {
            this.toastService.success('User/s successfully deleted');
        }
    }

    private getStatus(status: UserStatus): { hasPassword?: boolean; isExternal?: boolean; isActive?: boolean } {
        switch (status) {
            case UserStatus.Pending: return {
                hasPassword: false,
                isActive: false,
                isExternal: false,
            };
            case UserStatus.Active: return {
                isActive: true
            };
            case UserStatus.Inactive: return {
                hasPassword: true,
                isExternal: false,
                isActive: false
            };
        }
    }

}
