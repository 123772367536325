<uf-panel *ngIf="user && !isNew && !isMyAccount" class="container locked">

    <div class="header-pane uf-app-bar transparent">
        <uf-breadcrumbs [breadcrumbs]="breadcrumbs" class="large">
            {{edited ? ' *' : ''}}
        </uf-breadcrumbs>
    </div>

    <div class="content-pane">
        <uf-tabs>
            <uf-tab label="Details" path=""></uf-tab>
            <uf-tab label="Devices" path="devices"></uf-tab>
            <uf-tab label="Permissions" path="permissions" restrict="SuperUser"></uf-tab>
            <uf-tab label="Inherited Permissions" path="inherited-permissions" restrict="SuperUser"></uf-tab>
        </uf-tabs>
    </div>

</uf-panel>

<router-outlet *ngIf="isNew || isMyAccount"></router-outlet>