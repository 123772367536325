import { Subscription } from 'rxjs';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService, UfControl, UfControlGroup, ValidatorFunctions } from '@unifii/library/common';
import { Dictionary, FormStyle, Option, Theme } from '@unifii/sdk';

import { UcProject, UcProjectInfo } from 'client';

import { EditData } from 'components/common/edit-data';

import { ContextService } from 'services/context.service';

import { SettingsComponent } from './settings.component';


const PRESET_THEMES: Dictionary<Theme> = {
    green: {
        brand: '#262626',
        brandAccent: '#177E89',
        group: '#336d79',
        groupChild: '#ABBFC3',
        groupSecondLevel: '#3b7e8c',
        groupThirdLevel: '#71a0aa',
        groupFourthLevel: '#dbe7ea',
        primaryAction: '#177E89',
        primaryActionDisabled: '#8BBEC4',
        primaryActionHover: '#39919A',
        primaryActionPressed: '#115E66',
        secondaryAction: '#FFFFFF',
        secondaryActionDisabled: '#FFFFFF',
        secondaryActionHover: '#EEF6F6',
        secondaryActionPressed: '#C4D8DC',
        info: '#325786',
        success: '#017E3C',
        warning: '#FBA600',
        error: '#AF1211',
        borderRadius: '4px',
        borderRadiusButton: '4px',
        inputLabel: '#177E89',
    },
    blue: {
        brand: '#262626',
        brandAccent: '#2957A3',
        group: '#2957A3',
        groupChild: '#EAEEF6',
        groupSecondLevel: '#5479B5',
        groupThirdLevel: '#7F9AC8',
        groupFourthLevel: '#BFCDE3',
        primaryAction: '#2957A3',
        primaryActionDisabled: '#EAEEF6',
        primaryActionHover: '#234A8A',
        primaryActionPressed: '#17315B',
        secondaryAction: '#FFFFFF',
        secondaryActionDisabled: '#FFFFFF',
        secondaryActionHover: '#EAEEf7',
        secondaryActionPressed: '#BFCDE3',
        info: '#3257C7',
        success: '#017E3C',
        warning: '#FBA600',
        error: '#AF1211',
        borderRadius: '4px',
        borderRadiusButton: '4px',
        inputLabel: '#2957A3'
    }
};

const DefaultInputStyle = {
    inputBackground: 'transparent',
    inputBorderRadius: '0px',
    inputPaddingRight: '0',
    inputPaddingLeft: '0',
    inputBorderTop: 'none',
    inputBorderRight: 'none',
    inputBorderLeft: 'none',
    inputIconMarginRight: '0',
    labelPaddingBottom: '0',
    inputIconColour: '#737373'
};

const OutlinedInputStyle = {
    inputBackground: 'white',
    inputBorderRadius: '4px',
    inputPaddingLeft: '8px',
    inputBorderTop: '1px solid #cccccc',
    inputBorderRight: '1px solid #cccccc',
    inputBorderLeft: '1px solid #cccccc',
    inputIconMarginRight: '8px',
    labelPaddingBottom: '6px',
    inputIconColour: '#888888'
};

const INPUT_STYLES: Option[] = [
    { name: 'Default', identifier: 'default' },
    { name: 'Outlined', identifier: 'outlined' }
];

const FORM_STYLES: Option[] = [
    { name: 'Summary', identifier: FormStyle.Summary },
    { name: 'Full Form', identifier: FormStyle.FullForm }
];


@Component({
    templateUrl: './settings-theming.html',
    styleUrls: ['./settings-theming.less']
})
export class SettingsThemingComponent implements OnInit, OnDestroy, EditData {

    readonly inputStyleOptions: Option[] = INPUT_STYLES;
    readonly formStyleOptions = FORM_STYLES;

    project: UcProjectInfo;

    form: UfControlGroup;
    inputStyle: string;

    private subscriptions = new Subscription();

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private toastService: ToastService,
        private context: ContextService,
        private ucProject: UcProject,
        private parent: SettingsComponent
    ) { }

    set edited(v: boolean) {
        this.parent.edited = v;
    }

    get edited() {
        return this.parent.edited;
    }

    ngOnInit() {

        const required = ValidatorFunctions.required('A value is required');
        const formStyle = new UfControl();
        formStyle.setValue(FormStyle.FullForm);

        this.form = new UfControlGroup({
            // App Theme
            brand: new UfControl(required),
            brandAccent: new UfControl(required),
            primaryAction: new UfControl(required),
            primaryActionDisabled: new UfControl(required),
            primaryActionHover: new UfControl(required),
            primaryActionPressed: new UfControl(required),
            secondaryAction: new UfControl(required),
            secondaryActionDisabled: new UfControl(required),
            secondaryActionHover: new UfControl(required),
            secondaryActionPressed: new UfControl(required),
            success: new UfControl(required),
            warning: new UfControl(required),
            error: new UfControl(required),
            info: new UfControl(required),
            borderRadius: new UfControl(required),
            // Form Theme
            group: new UfControl(required),
            groupSecondLevel: new UfControl(required),
            groupThirdLevel: new UfControl(required),
            groupFourthLevel: new UfControl(required),
            groupChild: new UfControl(required),
            inputLabel: new UfControl(required),
            formStyle
        });

        this.inputStyle = Object.assign({}, this.inputStyleOptions[0].identifier);
        this.load();

        this.subscriptions.add(this.form.statusChanges.subscribe(() => this.edited = true));
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
        this.parent.edited = false;
    }

    applyTheme(identifier: string) {
        this.setTheme(Object.assign({}, PRESET_THEMES[identifier]));
    }

    setTheme(theme: Theme) {

        for (const key of Object.keys(this.form.controls)) {
            const value = theme[key as keyof Theme];

            if (value) {
                this.form.controls[key].setValue(value);
            }
        }
        // Update form theme
        this.inputStyle = theme.inputBackground === 'white' ? 'outlined' : 'default';
    }

    save() {

        this.form.setSubmitted(true);

        if (this.form.invalid) {
            return;
        }

        const inputStyles = this.inputStyle === 'outlined' ? OutlinedInputStyle : DefaultInputStyle;
        this.project.theme = Object.assign(inputStyles, this.form.value);

        this.ucProject.save(this.project).then(p => {
            this.context.project = p;
            this.project = JSON.parse(JSON.stringify(p));
            this.toastService.success('Changes saved!');
            this.edited = false;
        });

    }

    cancel() {
        this.router.navigate(['../general'], { relativeTo: this.route });
    }

    private load() {

        this.project = JSON.parse(JSON.stringify(this.context.project));
        const theme = Object.assign({}, PRESET_THEMES.green, this.normalizeTheme((this.context.project as UcProjectInfo).theme));
        this.setTheme(theme);
        this.edited = false;
    }

    private normalizeTheme(theme?: Theme): Theme {

        theme = theme || {};
        /**
         * todo: remove!!! once all themes have been resaved (date:16/4/2019)
         * new colour input label has been added to suit specific projects if it doesn't exist
         * use primary action so styles will still look correct
         */
        if (theme.primaryAction != null && theme.inputLabel == null) {
            theme.inputLabel = theme.primaryAction;
        }

        return theme;
    }

}
