import { Component, Input } from '@angular/core';
import { ModalService, UfControlArray, UfControlGroup } from '@unifii/library/common';
import { ClaimConfig, FieldType, Option } from '@unifii/sdk';

import { EmailTemplate, UcRoles, UcUserClaims } from 'client';

import { DialogsService } from 'services/dialogs.service';

import {
    ConditionClaimControlKey, ConditionControlKey, ConditionTypes, EmailTemplateControlKey, SystemEmailSettingsController
} from './system-email-settings-controller';
import { SystemEmailPreviewModalComponent } from './system-email-template-preview-modal.component';


@Component({
    selector: 'uc-email-templates',
    templateUrl: './system-email-templates.html'
})
export class SystemEmailTemplatesComponent {

    @Input() emailColour: string;
    @Input() default?: EmailTemplate;
    @Input() defaultTemplateControl: UfControlGroup;

    @Input() set templatesControl(v: UfControlArray) {
        this._templatesControl = v;
        this.expanderStates = Array(v.controls.length);
    };
    get templatesControl(): UfControlArray {
        return this._templatesControl;
    }

    readonly templateControlKey = EmailTemplateControlKey;
    readonly conditionControlKey = ConditionControlKey;
    readonly conditionType = ConditionTypes;
    readonly conditionClaimControlKey = ConditionClaimControlKey;

    readonly conditionOptions: Option[] = [
        { identifier: ConditionTypes.Claim, name: 'Claim' },
        { identifier: ConditionTypes.Role, name: 'Role' },
        { identifier: ConditionTypes.Combination, name: 'Combination' }
    ];

    claimOptions: ClaimConfig[] = [];
    roleOptions: string[] = [];
    expanderStates: boolean[] = [];

    private _templatesControl: UfControlArray;

    constructor(
        private formController: SystemEmailSettingsController,
        private ucUserClaims: UcUserClaims,
        private ucRoles: UcRoles,
        private modalService: ModalService,
        private dialogs: DialogsService
    ) { }

    async addTemplate() {
        this.templatesControl?.push(this.formController.buildTemplateControl(await this.formController.toTemplateFormModel(this.default)));
        this.expanderStates.push(true);
    }

    async removeTemplate(index: number) {
        // if template has values, ask the user if they're sure about deleting
        const templateData = this.templatesControl.at(index)?.getRawValue();
        if (Object.keys(templateData).filter(key => templateData[key] != null && templateData[key].length !== 0).length) {
            if (!await this.dialogs.confirmDelete()) {
                return;
            }
        }

        this.templatesControl?.removeAt(index);
        this.expanderStates.splice(index, 1);
    }

    addCondition(index: number) {
        const control = this.templatesControl?.at(index).get(EmailTemplateControlKey.Conditions) as UfControlArray;
        control?.push(this.formController.buildConditionControl());
    }

    async removeCondition(templateIndex: number, conditionIndex: number) {
        if (!await this.dialogs.confirmDelete()) {
            return;
        }
        const control = this.templatesControl?.at(templateIndex).get(EmailTemplateControlKey.Conditions) as UfControlArray;
        control?.removeAt(conditionIndex);
    }

    conditionTypeChanged(value: ConditionTypes, templateIndex: number, conditionIndex: number) {
        const control = (this.templatesControl?.at(templateIndex).get(EmailTemplateControlKey.Conditions) as UfControlArray | undefined)?.at(conditionIndex);
        switch (value) {
            case ConditionTypes.Claim:
                control?.get(ConditionControlKey.Claim)?.enable();
                control?.get(ConditionControlKey.Role)?.disable();
                break;

            case ConditionTypes.Role:
                control?.get(ConditionControlKey.Claim)?.disable();
                control?.get(ConditionControlKey.Role)?.enable();
                break;
            case ConditionTypes.Combination:
                control?.get(ConditionControlKey.Claim)?.enable();
                control?.get(ConditionControlKey.Role)?.enable();
                break;

        }
    }

    async searchClaims(q?: string) {
        // TODO add support for MultiChoice and TextArray claims
        this.claimOptions = (await this.ucUserClaims.list({ params: { q } })).filter(c => ![FieldType.MultiChoice, FieldType.TextArray].includes(c.valueType));
    }

    async searchRoles(query?: string) {
        this.roleOptions = (await this.ucRoles.get(query)).map(role => role.name);
    }

    preview(i: number, defaultTemplate: boolean) {
        const email = defaultTemplate ? this.defaultTemplateControl?.getRawValue() : this.templatesControl.at(i)?.getRawValue();
        this.modalService.openMedium(SystemEmailPreviewModalComponent, Object.assign(email, { emailColour: this.emailColour }));
    }
}