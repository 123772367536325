<uf-panel *ngIf="revisionList" class="container gray-background">
    <div class="header-pane uf-app-bar uf-box flat">
        <uf-breadcrumbs [breadcrumbs]="breadcrumbs"></uf-breadcrumbs>

        <div *ngIf=" revisionList.length> 1" class="fieldset-item--small--stacked pad-sides right">
            <span>Revision</span>
            <uf-select [options]="revOptions" [(value)]="selected" valueProperty="position">
            </uf-select>
        </div>

        <ng-container *ngIf="revisionList[selected]?.formData as data">
            <ng-template [ngIf]="data && revisionList.length === 1">
                <div class="fieldset-item--small--stacked right">
                    <span>Status:</span>
                    <span>{{ data._state }}</span>
                </div>
                <div *ngIf="data._result" class="fieldset-item--small--stacked">
                    <span>Result:</span>
                    <span>{{ data._result }}</span>
                </div>
            </ng-template>
        </ng-container>

        <ng-template [ngIf]="selected >= 0">
            <button (click)="showMetadata()" title="Metadata" type="button" class="uf-action">
                <uf-icon name="info"></uf-icon>
            </button>
            <ng-template ufFeatureFlag="pdfRendering">
                <a class="uf-action" title="Pdf" (click)="openPDF()">
                    <uf-icon name="pdf"></uf-icon>
                </a>
            </ng-template>
        </ng-template>

        <!-- <a class="uf-button tertiary" [routerLink]="['../']">Cancel</a> -->
    </div>

    <div class="content-pane">
        <uf-panel class="container">
            <div class="uf-container-lg">
                <div class="uf-box flat">
                    <div class="uf-grid">
                        <ng-container *ngFor="let rev of revisionList; let i = index;">
                            <uf-form *ngIf="i === selected" [definition]="rev.definition" [formData]="rev.formData"
                                [disabled]="true" class="col-12">
                            </uf-form>
                        </ng-container>
                    </div>
                </div>
            </div>
        </uf-panel>
    </div>
</uf-panel>