<div class="header">
    <button type="button" class="uf-action tertiary" (click)="close()" title="Close">
        <uf-icon name="close"></uf-icon>
    </button>
    <h3>{{data.title}}</h3>
</div>

<uf-panel class="container">

    <div class="pad-sides">
        <ul class="uf-list">
            <li class="uf-list-button" *ngFor="let option of data.types" (click)="close(option)">
                <div *ngIf="option.logo" class="uc-image gap-right">
                    <img [src]="option.logo | bypassSanitizeUrl">
                </div>
                <uf-icon *ngIf="option.icon" [name]="option.icon">
                </uf-icon>
                <div>{{ option.label }}</div>
            </li>
        </ul>
    </div>

</uf-panel>