<div class="header-pane uf-app-bar transparent">
    <button type="button" class="uf-button tertiary right" (click)="cancel()">Cancel</button>
    <button type="button" class="uf-button primary" (click)="save()">Save</button>
</div>

<uf-panel class="content-pane container">
    <div *ngIf="project" class="uf-container-lg">
        <div class="uf-grid row-gap-lg pad-top">

            <div class="uf-box col-12">
                <div class="uf-app-bar flat accent">
                    <div class="title">
                        App Theme
                    </div>
                    <button type="button" class="uf-button x-small primary" (click)="applyTheme('green')">
                        Apply Green
                        Theme</button>
                    <button type="button" class="uf-button x-small primary" (click)="applyTheme('blue')">Apply Blue
                        Theme</button>
                </div>
                <uf-expander>
                    <div class="uf-app-bar flat" expanderHeader>
                        <div class="title">
                            Brand Colours
                        </div>
                    </div>
                    <div class="grid" expanderBody>
                        <uc-color label="Brand" class="col-1of2" [control]="form.controls.brand | asUfControl">
                        </uc-color>
                        <uc-color label="Brand Accent" class="col-1of2"
                            [control]="form.controls.brandAccent | asUfControl">
                        </uc-color>
                    </div>
                </uf-expander>
                <uf-expander>
                    <div class="uf-app-bar flat" expanderHeader>
                        <div class="title">
                            Button/Action Colours
                        </div>
                    </div>
                    <div class="grid" expanderBody>
                        <uc-color class="col-1of2" label="Primary Action"
                            [control]="form.controls.primaryAction | asUfControl">
                        </uc-color>
                        <uc-color class="col-1of2" label="Primary Action Disabled"
                            [control]="form.controls.primaryActionDisabled | asUfControl">
                        </uc-color>
                        <uc-color class="col-1of2" label="Primary Action Hover"
                            [control]="form.controls.primaryActionHover | asUfControl">
                        </uc-color>
                        <uc-color class="col-1of2" label="Primary Action Active"
                            [control]="form.controls.primaryActionPressed | asUfControl">
                        </uc-color>
                        <uc-color class="col-1of2" label="Secondary Action"
                            [control]="form.controls.secondaryAction | asUfControl">
                        </uc-color>
                        <uc-color class="col-1of2" label="Secondary Action Disabled"
                            [control]="form.controls.secondaryActionDisabled | asUfControl">
                        </uc-color>
                        <uc-color class="col-1of2" label="Secondary Action Hover"
                            [control]="form.controls.secondaryActionHover | asUfControl">
                        </uc-color>
                        <uc-color class="col-1of2" label="Secondary Action Active"
                            [control]="form.controls.secondaryActionPressed | asUfControl">
                        </uc-color>
                    </div>
                </uf-expander>
                <uf-expander>
                    <div class="uf-app-bar flat" expanderHeader>
                        <div class="title">
                            Form
                        </div>
                    </div>
                    <div class="grid" expanderBody>
                        <uf-select class="col-1of1" label="Form Style" nameProperty="name" valueProperty="identifier"
                            [control]="form.controls.formStyle | asUfControl" [options]="formStyleOptions">
                        </uf-select>
                        <div class="col-1of1">
                            <h3>Colours</h3>
                        </div>
                        <uc-color class="col-1of2" label="Group first level colour"
                            [control]="form.controls.group | asUfControl">
                        </uc-color>
                        <uc-color class="col-1of2" label="Group second level colour"
                            [control]="form.controls.groupSecondLevel | asUfControl">
                        </uc-color>
                        <uc-color class="col-1of2" label="Group third level colour"
                            [control]="form.controls.groupThirdLevel | asUfControl">
                        </uc-color>
                        <uc-color class="col-1of2" label="Group fourth level colour"
                            [control]="form.controls.groupFourthLevel | asUfControl">
                        </uc-color>
                        <uc-color class="col-1of2" label="Group children"
                            [control]="form.controls.groupChild | asUfControl">
                        </uc-color>
                        <uc-color class="col-1of2" label="Input label colour"
                            [control]="form.controls.inputLabel | asUfControl">
                        </uc-color>
                    </div>
                </uf-expander>
                <uf-expander>
                    <div class="uf-app-bar flat" expanderHeader>
                        <div class="title">
                            Messaging Colours
                        </div>
                    </div>
                    <div class="grid" expanderBody>
                        <uc-color class="col-1of2" label="Info" [control]="form.controls.info | asUfControl">
                        </uc-color>
                        <uc-color class="col-1of2" label="Success" [control]="form.controls.success | asUfControl">
                        </uc-color>
                        <uc-color class="col-1of2" label="Warning" [control]="form.controls.warning | asUfControl">
                        </uc-color>
                        <uc-color class="col-1of2" label="Error" [control]="form.controls.error | asUfControl">
                        </uc-color>
                    </div>
                </uf-expander>
                <uf-expander>
                    <div class="uf-app-bar flat" expanderHeader>
                        <div class="title">
                            Other
                        </div>
                    </div>
                    <div class="grid" expanderBody>
                        <uf-select class="col-1of2" label="Input style" [(value)]="inputStyle"
                            [options]="inputStyleOptions" nameProperty="name" valueProperty="identifier">
                        </uf-select>
                        <uf-text class="col-1of2" label="Border Radius"
                            [control]="form.controls.borderRadius | asUfControl">
                        </uf-text>
                    </div>
                </uf-expander>
            </div>
        </div>
    </div>
</uf-panel>