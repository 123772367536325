import { Directive, HostListener } from '@angular/core';

import { DragItemDirective } from './drag-item.directive';


// This directive registers itself with DragItem as a valid target
// DragItem on dragstart checks for valid targets if any
@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[dragHandle]'
})
export class DragHandleDirective {

    constructor(private dragItem: DragItemDirective) {
        dragItem.hasHandle = true;
    }

    @HostListener('mousedown', ['$event'])
    mouseDown(event: MouseEvent) {
        this.dragItem.handleUsed = true;
    }
}
