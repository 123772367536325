import { ActivityType, WorkflowEventType, WorkflowState } from 'client';


export const WorkflowEventLabel = {
    [WorkflowEventType.FormSubmitted]: 'Workflow Transition',
    [WorkflowEventType.Timer]: 'Timer',
    [WorkflowEventType.ApiEvent]: 'API Event',
    [WorkflowEventType.RoleAdded]: 'Role Added',
};

export const WorkflowActivityLabel = {
    [ActivityType.DataForwarder]: 'Data Forwarder',
    [ActivityType.Notification]: 'Notification',
    [ActivityType.Timer]: 'Timer',
};

export const WorkflowStateLabel = {
    [WorkflowState.DryRun]: 'Dry Run',
    [WorkflowState.Active]: WorkflowState.Active,
    [WorkflowState.Inactive]: WorkflowState.Inactive,
    [WorkflowState.Preview]: WorkflowState.Preview,
};