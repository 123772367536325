import { EmptyComponent } from './empty.component';
import { ErrorHandlingComponent } from './error-handling.component';
import { IconsConsoleComponent } from './icons-console';
import { IconsLibraryComponent } from './icons-library.component';
import { IconsComponent } from './icons.component';
import { LabDragListComponent } from './lab-drag-list.component';
import { LabComponent } from './lab.component';
import { ListsComponent } from './lists.component';
import { ReactiveFormsComponent } from './reactive-forms.component';
import { StyleGuideComponent } from './style-guide.component';
import { TemplateFormComponent } from './template-form.component';
import { TemplateTableComponent } from './template-table.component';
import { TemplateTabsComponent } from './template-tabs.component';


const LAB_COMPONENTS = [
    EmptyComponent,
    ErrorHandlingComponent,
    IconsConsoleComponent,
    IconsLibraryComponent,
    IconsComponent,
    LabDragListComponent,
    LabComponent,
    ListsComponent,
    ReactiveFormsComponent,
    StyleGuideComponent,
    TemplateFormComponent,
    TemplateTableComponent,
    TemplateTabsComponent
];

export {
    LAB_COMPONENTS,
    EmptyComponent,
    ErrorHandlingComponent,
    IconsConsoleComponent,
    IconsLibraryComponent,
    IconsComponent,
    LabDragListComponent,
    LabComponent,
    ListsComponent,
    ReactiveFormsComponent,
    StyleGuideComponent,
    TemplateFormComponent,
    TemplateTableComponent,
    TemplateTabsComponent
};
