<div class="grid">
    <uf-text class="col-1of1" label="Label" [(value)]="definition.label"
        [control]="control.controls.label | asUfControl">
    </uf-text>
    <uf-text class="col-1of1" label="Identifier" [(value)]="definition.identifier"
        [control]="control.controls.identifier | asUfControl" [maxLength]="maxLength">
    </uf-text>
    <div class="row space-children col-1of1" restrict="FormDesigner">
        <button type="button" class="uf-button right" (click)="copyFields()">
            Copy Fields
        </button>
        <button type="button" class="uf-button" (click)="pasteFields()">
            Paste Fields
        </button>
    </div>
</div>