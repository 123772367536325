<ul class="uf-list uf-box">
    <a *ngFor="let config of data.contextMenuConfig" [routerLink]="config.link" routerLinkActive="router-link-active"
        [restrict]="config.restrict" (click)="config.action ? config.action() : undefined">
        <!-- keep li wrapper instead of adding class to anchor, to make sure active link style works -->
        <li class="uf-list-button">
            <uf-icon *ngIf="config.icon" [name]="config.icon">
            </uf-icon>
            <span>{{ config.label }}</span>
        </li>
    </a>
</ul>