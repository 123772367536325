import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { DataDescriptor, DataDescriptorService } from '@unifii/library/common';
import { Schema } from '@unifii/sdk';

import { UcProject } from 'client';


export const bucketConfigResolver: ResolveFn<{ schema?: Schema; dataDescriptor?: DataDescriptor | undefined }> = async (route: ActivatedRouteSnapshot) => {

    const ucProject = inject(UcProject);
    const dataDescriptorService = inject(DataDescriptorService);
    const bucketId = route.params.id;

    try {
        return {
            schema: await ucProject.getBucket(bucketId),
            dataDescriptor: await dataDescriptorService.getBucketDataDescriptor(bucketId)
        };
    } catch (e) {
        console.error(e);
        return {};
    }
}
