import { Subject } from 'rxjs';

import { Inject, Injectable } from '@angular/core';
import { TableContainerManager, TableInputManager, TableInputs } from '@unifii/components';
import {
    CellDisplayDescriptor, FileSizePipe, FilterEntries, FilterEntry, FilterValue, HierarchyUnitProvider, MomentDatePipe, TableConfig,
    TableConfigColumn
} from '@unifii/library/common';
import { CellTemplateType } from '@unifii/sdk';

import { Media, MediaType, SystemRole, UcMedia } from 'client';
import { TABLE_SEARCH_MIN_LENGTH } from 'constant';

import { MediaHelper } from 'helpers/media-helper';

import { FileTypePipe } from 'pipes/file-type.pipe';

import { ContextService } from 'services/context.service';

import { MediaDataSource } from './media-datasource';


export enum MediaMetadataIdentifiers {
    Url = 'url',
    Title = 'title',
    Filename = 'filename',
    UploadedAt = 'uploadedAt',
    Size = 'size',
    FileSize = 'fileSize',
    FileType = 'fileType'
}

@Injectable()
export class MediaTableManager implements TableContainerManager<Media, FilterValue, FilterEntry> {

    tableConfig: TableConfig<Media>;
    showSearch = true;
    searchMinLength = TABLE_SEARCH_MIN_LENGTH;
    addActionConfig = this.context.checkRoles(SystemRole.AssetManager);
    customColumns: CellDisplayDescriptor[] = [{
        name: MediaMetadataIdentifiers.Url,
        // TODO debug default template should be enough for this to work
        variations: [{
            condition: 'true',
            template: {
                type: CellTemplateType.Image
            }
        }]
    }];
    defaultSort = 'title';

    reload = new Subject<void>();
    update = new Subject<TableInputs<FilterValue>>();
    updateItem = new Subject<Media>();
    inputManager: TableInputManager<FilterValue, FilterEntry>;

    constructor(
        @Inject(FilterEntries) filterEntries: FilterEntry[],
        @Inject(HierarchyUnitProvider) hierarchyUnitProvider: HierarchyUnitProvider,
        private datePipe: MomentDatePipe,
        private fileSizePipe: FileSizePipe,
        private ucMedia: UcMedia,
        private fileTypePipe: FileTypePipe,
        private context: ContextService
    ) {
        this.inputManager = new TableInputManager(filterEntries, hierarchyUnitProvider);

        this.tableConfig = {
            id: 'media',
            columns: this.columns,
            pageSize: 50,
            columnToggles: true,
            row: {
                link: item => `${item.id}`,
                image: item => this.getUrl(item, 214, 120),
                label: item => item.title ?? item.filename
            }
        };
    }

    createDataSource(inputs?: TableInputs<FilterValue> | undefined) {
        const params = this.inputManager.serializeInputs(inputs ?? {});
        if (params.sortBy) {
            params.sort = params.sortBy;
            delete params.sortBy;
        }

        return new MediaDataSource(this.ucMedia, params);
    }

    private get columns(): TableConfigColumn<Media>[] {
        return [{
            name: MediaMetadataIdentifiers.Url,
            label: 'Thumbnail',
            value: item => this.getUrl(item, 32),
            hidden: true
        }, {
            name: MediaMetadataIdentifiers.Title,
            label: 'Title',
            hidden: true
        }, {
            name: MediaMetadataIdentifiers.Filename,
            label: 'File Name',
            hidden: true
        }, {
            name: MediaMetadataIdentifiers.UploadedAt,
            label: 'Uploaded',
            value: item => this.datePipe.transform(new Date(item.uploadedAt), 'dd MMM yyyy')
        }, {
            name: MediaMetadataIdentifiers.Size,
            label: 'Size',
            value: item => this.getSize(item),
            hidden: true
        }, {
            name: MediaMetadataIdentifiers.FileSize,
            label: 'File Size',
            hidden: true,
            value: item => this.fileSizePipe.transform(item.filesize)
        }, {
            name: MediaMetadataIdentifiers.FileType,
            label: 'File Type',
            value: item => this.fileTypePipe.transform(item.filename)
        }];
    }

    private getSize(media: Media) {
        switch (media.type) {
            case MediaType.Image:
                return media.width && media.height ? media.width + 'px x ' + media.height + 'px' : '';
            case MediaType.Audio:
            case MediaType.Video:
            case MediaType.File:
                return media.length ? media.length : '';
            default:
                return null;
        }
    }

    private getUrl(media: Media, width: number, height?: number): string {
        const assetBaseUrl = '/assets/svg';

        switch (media.type) {
            case MediaType.Image: return MediaHelper.getImageUrl(media, width, height) ?? '';
            case MediaType.Audio: return assetBaseUrl + '/icon-audio.svg';
            case MediaType.Video: return assetBaseUrl + '/icon-video.svg';
            default: return assetBaseUrl + '/icon-file.svg';
        }
    }

}
