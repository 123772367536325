import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';

import { ContextService } from 'services/context.service';


/** Hides host element if context.account does not have the required roles */
@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[restrict]',
})
export class RestrictDirective implements OnChanges {

    @Input() restrict: string | string[] | null | undefined;

    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
        private context: ContextService
    ) { }

    ngOnChanges(changes: SimpleChanges) {

        if (changes.restrict) {
            const next: string | string[] | null | undefined = changes.restrict.currentValue;

            if (!next) {
                return;
            }

            let list: string[];
            if (!Array.isArray(next)) {
                list = next.split(',').map(r => r.trim());
            } else {
                list = next;
            }

            const hasAccess = this.context.checkRoles(...list);

            // todo: come up with a way to ensure [hidden] bindings do not mess with this
            if (!hasAccess) {
                this.renderer.setProperty(this.el.nativeElement, 'hidden', true);
            }
        }
    }
}
