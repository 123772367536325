import { Component, HostBinding, Inject } from '@angular/core';
import { TableContainerManager } from '@unifii/components';
import { FilterComponentRegistry, FilterEntries } from '@unifii/library/common';

import { UcProject } from 'client';

import { InfoFilterFactory } from 'services/table/info-filter-factory';
import { InfoFilterRegistry } from 'services/table/info-filter-registry';
import { ViewInfoLoader } from 'services/table/info-loaders';
import { InfoTableManager } from 'services/table/info-table-manager';
import { InfoLoader, InfoType } from 'services/table/models';


const createLoader = (ucProject: UcProject) => new ViewInfoLoader(ucProject);

const createFilters = (filterFactory: InfoFilterFactory) => filterFactory.create(InfoType.View);

@Component({
    providers: [
        { provide: FilterComponentRegistry, useClass: InfoFilterRegistry },
        { provide: FilterEntries, useFactory: createFilters, deps: [InfoFilterFactory] },
        { provide: InfoLoader, useFactory: createLoader, deps: [UcProject] },
        { provide: TableContainerManager, useClass: InfoTableManager }
    ],
    template: `
        <div ufMasterDetail>
            <uf-table-container ufSyncRoute (addItem)="addItem()"  class="accent list-md pad-none">
            </uf-table-container>
        </div>
        <router-outlet></router-outlet>
    `
})
export class ViewsComponent {

    @HostBinding('class.stretch-component') class = true;

    constructor(
        @Inject(TableContainerManager) private manager: InfoTableManager
    ) {
    }

    addItem() {
        this.manager.addActionCallback();
    }

}
