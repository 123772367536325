import { Subject } from 'rxjs';

import { EventEmitter, Injectable } from '@angular/core';

import { UcCompound, UcDefinition, UcPage, UcStructure, UcTable, UcView } from 'client';

import { SaveOption } from 'components/common/save-options/save-options.component';

import { BuilderNotificationInfo } from '../builder.service';

import { HeaderConfig } from './builder-header.component';


export type HeaderTypes = (UcTable | UcCompound | UcView | UcDefinition | UcPage | UcStructure) & HeaderConfig;

@Injectable()
export class BuilderHeaderService {

    saveClicked = new EventEmitter<SaveOption | undefined>();

    notify: Subject<BuilderNotificationInfo> = new Subject<BuilderNotificationInfo>();

    config: HeaderConfig = {};

    init() {
        this.config = {};
    }

    buildConfig(config: HeaderTypes): void {
        this.config = {
            lastModified: {
                at: config.lastModifiedAt,
                by: config.lastModifiedBy
            },
            lastPublished: {
                at: config.lastPublishedAt,
                by: config.lastPublishedBy
            },
            ...config
        };
    }

    updateConfig(config: HeaderTypes) {
        this.config = Object.assign({}, this.config, config);
    }
}