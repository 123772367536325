import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import { ExpandersService, ModalService, RuntimeDefinition, RuntimeDefinitionAdapter, ToastService, UfControl } from '@unifii/library/common';
import { ComponentRegistry } from '@unifii/library/smart-forms';
import { CompoundType, Definition } from '@unifii/sdk';

import { BuilderField, CompoundInfo, UcCompound } from 'client';

import { ContentSettings, SaveAndClose, SaveAndNext, SaveOption } from 'components';
import { BuilderBasic } from 'components/compound-builder/builder-basic';
import { BuilderHeaderService } from 'components/compound-builder/builder-header/builder-header.service';
import { BuilderCompoundSubjects } from 'components/compound-builder/builder-models';
import { BuilderEventInfo, BuilderService } from 'components/compound-builder/builder.service';
import { ContentEditorRegistry } from 'components/content-editor-registry';

import { LanguageCollectionComponent } from 'pages/translations/language-collection.component';

import { BreadcrumbService } from 'services/breadcrumb.service';
import { ContextService } from 'services/context.service';

import { LanguageCollectionStore } from './language-collection-store';
import { LanguageCollectionTableManager } from './language-collection-table-manager';


@Component({
    templateUrl: './language-compound.html',
    styleUrls: ['./../../styles/pages/builder.less'],
    providers: [
        BuilderService,
        ExpandersService,
        { provide: ComponentRegistry, useClass: ContentEditorRegistry },
        { provide: ContentSettings, useValue: { mode: 'DataEntry' } }
    ]
})
export class LanguageCompoundComponent extends BuilderBasic implements OnInit, OnDestroy {

    type = CompoundType.Collection;
    subject = BuilderCompoundSubjects.TRANSLATION;
    ready: boolean;
    definition: RuntimeDefinition;
    compound: UcCompound;
    translation: UcCompound;
    error: any;

    // Check if this is working correctly
    protected lastEditedField: BuilderField;

    constructor(
        builderService: BuilderService,
        modalService: ModalService,
        private store: LanguageCollectionStore,
        protected parent: LanguageCollectionComponent,
        protected router: Router,
        protected route: ActivatedRoute,
        @Inject(TableContainerManager) public tableManager: LanguageCollectionTableManager,
        private toastService: ToastService,
        private context: ContextService,
        private breadcrumbService: BreadcrumbService,
        private builderHeaderService: BuilderHeaderService,
        private runtimeDefinitionAdapter: RuntimeDefinitionAdapter
    ) {
        super(builderService, modalService, route);
    }

    get id(): number {
        return +this.route.snapshot.params.id;
    }

    async ngOnInit() {
        // Fix save options
        this.saveOptions = [SaveAndClose, SaveAndNext];
        const definition = this.store.definition as Definition;

        try {
            this.compound = await this.store.ucCollection.getItem(this.id);
            try {
                const translationRaw = await this.store.ucCollectionTranslation.getItem(this.id);
                this.translation = this.builderService.filterDefinedFields(definition.fields, translationRaw);
            } catch (e) {
                this.translation = {};
            }

            // Register event handlers
            this.addSubscribers();

            // Init builder service
            this.builderService.init(this, definition, this.compound);

            this.builderHeaderService.init();
            this.subscriptions.add(this.builderHeaderService.saveClicked.subscribe(saveOption => this.save(saveOption)));
            this.buildHeaderConfig(this.compound);

            this.definition = await this.runtimeDefinitionAdapter.transform(definition);
        } catch (e) {
            this.error = e;
        }
    }

    addSubscribers() {
        this.subscriptions.add(this.builderService.ready.subscribe(() => {
            this.saveStatus();
            this.builderService.memento.edited = false;
            this.ready = true;
        }));

        this.subscriptions.add(this.builderService.fieldEdit.subscribe(i => {
            this.saveStatus(i);
            this.builderService.fieldEdited.next(i);
        }));
    }

    contentChanged(data: any, field?: any, control?: UfControl) {

        const errors: string[] = [];
        const messages: { message: string }[] = [];
        const changed = field !== this.lastEditedField;
        this.lastEditedField = field;

        if (changed) {
            this.builderService.memento.setLastAtomic();
        }

        if (control) {

            if (control.errors && control.errors.message) {
                const { message } = control.errors;
                errors.push(message);
                messages.push({ message });
            }

            this.builderService.setErrors(this.builderService.compound, errors, 'all');
        }
        this.builderService.fieldEdit.next({ subject: field, errors: messages, atomic: false });
    }

    async save(saveOption?: SaveOption) {
        try {
            this.builderService.busy.next(true);
            this.compound = await this.store.ucCollectionTranslation.saveItem(this.id, this.translation);
            this.toastService.success('Translation saved');
            /**
             * The deal with the missaligned interfaces between the table and language component
             * (CompoundInfo and UcCompound) the table item has to be updated with
             * Find the souce item from the table manager and merge with updated compound,
             */
            const item = this.tableManager.items.find(i => +i.id === this.id) as CompoundInfo;
            const updated = Object.assign({}, item, this.compound);
            this.saved(updated, saveOption);
            this.builderService.init(this, this.definition, this.compound);
            this.buildHeaderConfig(updated);
        } finally {
            this.builderService.busy.next(false);
        }
    }

    restore(step: number) {
        const value = step < 0 ? this.builderService.memento.undo() : this.builderService.memento.redo();
        this.translation = value;
        this.builderService.fieldSelect.next(null);
    }

    private saveStatus(i: BuilderEventInfo = { subject: null, atomic: true }) {
        this.builderService.memento.save(this.translation, i.atomic);
        this.builderService.memento.edited = true;

        if (this.ready) {
            this.builderHeaderService.config.edited = true;
        }
    }

    private buildHeaderConfig(translation: UcCompound) {
        const currentTranslationName = [this.context.project?.name, this.context.language?.name].filter(s => s != null).join(' ');

        this.builderHeaderService.buildConfig({
            ...translation,
            title: translation._title,
            publishState: this.builderService.compound?.publishState,
            saveOptions: this.saveOptions,
            cancelRoute: ['../'],
            restrictSave: 'Translator',
            breadcrumbs: this.breadcrumbService.getBreadcrumbs(this.route, [currentTranslationName, translation._title])
        });
    }
}
