import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { Repository } from '@unifii/library/common';

import { UcClient, UcProjectInfo } from 'client';
import { LAST_USED_PROJECT_ID_KEY } from 'constant';

import { ContextService } from 'services/context.service';
import { ProjectService } from 'services/project.service';


export const defaultProjectResolver: ResolveFn<UcProjectInfo | undefined> = async () => {

    const context = inject(ContextService);
    const projectService = inject(ProjectService);
    const repository = inject(Repository);
    const ucClient = inject(UcClient);

    // if project is already set or user doesn't have project roles, return
    if (context.project != null || !projectService.checkProjectRoles()) {
        return;
    }

    let projectId;

    const lastUsedProjectId = repository.load(LAST_USED_PROJECT_ID_KEY) as string | undefined;
    if (lastUsedProjectId) { // check if user previously used a project
        projectId = lastUsedProjectId;
    } else { // if no project was used previously check if tenant only has one project and set that as default
        const projects = await ucClient.getProjects();
        projectId = projects.length === 1 ? projects[0].id : undefined;
    }

    if (!projectId) {
        return;
    }

    return projectService.setProject(projectId);
}
