import { Observable } from 'rxjs';

import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Params, ResolveFn, Router, RouterStateSnapshot } from '@angular/router';
import {
    ClipboardService, DataDescriptor, FormDefinitionMetadataIdentifiers, ModalService, ToastService, UserInfoIdentifiers
} from '@unifii/library/common';
import { FieldType, TableDetail, TableSourceType } from '@unifii/sdk';

import { SchemaInfo, UcDataSources, UcProject, UcTable } from 'client';

import { EditMode, getEditMode } from 'components/common/edit-data';
import { TypeSelectComponent, TypeSelectConfig, TypeSelectOption } from 'components/common/type-select.component';
import {
    ContentSelectComponent, ContentSelectConfig, ContentSelectType, ContentType, getContentSelectConfig
} from 'components/content/modals/content-select.component';

import { TableSourceTypeLabelPipe } from 'pipes/table-source-type-label.pipe';

import { TableEditorService } from './table-editor.service';


export interface TableInfo {
    table: UcTable;
    dataDescriptor: DataDescriptor;
    duplicateTableDetails?: TableDetail;
}

export const tableResolver: ResolveFn<TableInfo> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => new TableResolver(
    inject(ModalService),
    inject(TableSourceTypeLabelPipe),
    inject(ToastService),
    inject(ClipboardService),
    inject(TableEditorService),
    inject(UcProject),
    inject(Router)
).resolve(route, state);

class TableResolver {

    ucDataSources: UcDataSources;

    constructor(
        private modalService: ModalService,
        private tableSourceTypeLabelPipe: TableSourceTypeLabelPipe,
        private toast: ToastService,
        private clipboard: ClipboardService,
        private tableEditorService: TableEditorService,
        private ucProject: UcProject,
        private router: Router
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<TableInfo> {
        const prevUrl = state.url.replace('/new', '');
        return this.resolvePromise(this.getTableInfo(route.params), prevUrl);
    }

    private async getTableInfo(params: Params): Promise<TableInfo | null> {

        const editMode = getEditMode(params);
        let table: UcTable | undefined;
        let tableDetail: TableDetail | undefined;

        switch (editMode) {
            case EditMode.New:
                table = await this.getNewTable();
                break;
            case EditMode.Existing:
                table = await this.ucProject.getTable(params.id);
                break;
            case EditMode.Duplicate:
                table = await this.ucProject.getTable(params.id);
                try {
                    tableDetail = await this.ucProject.getTableDetail(params.id);
                } catch (e) { }

                table.title = undefined as any;
                table.identifier = undefined as any;
                delete table.id;
                delete table.lastModifiedAt;
                delete table.lastPublishedAt;
                delete table.publishState;
                break;
        }

        if (!table) {
            return null;
        }

        let tableInfo: TableInfo | null = null;
        switch (table.sourceType) {
            case TableSourceType.Bucket:
                tableInfo = await this.loadBucketInfo(table);
                break;
            case TableSourceType.Users:
                tableInfo = await this.loadUsersInfo(table);
                break;
            case TableSourceType.Company:
                tableInfo = await this.loadCompanyInfo(table);
                break;
        }

        if (tableInfo != null) {
            tableInfo.duplicateTableDetails = tableDetail;
        }
        return tableInfo;
    }

    private async getNewTable(): Promise<UcTable | undefined> {

        const copiedTable = await this.getCopiedTable();

        if (copiedTable) {
            const pasteChoice = await this.askPaste();

            if (pasteChoice == null) {
                // Execution stopped
                return;
            }

            if (pasteChoice) {
                return copiedTable;
            }
        }

        const sourceType = await this.askSourceType();
        if (sourceType == null) {
            // Execution stopped
            return;
        }

        const table: UcTable = { identifier: null as any, sourceType, title: null as any, hideExport: true };

        switch (sourceType) {

            case TableSourceType.Users:
                table.columns = [
                    { identifier: UserInfoIdentifiers.Username },
                    { identifier: UserInfoIdentifiers.FirstName },
                    { identifier: UserInfoIdentifiers.LastName },
                    { identifier: UserInfoIdentifiers.Email },
                    { identifier: UserInfoIdentifiers.Phone },
                    { identifier: UserInfoIdentifiers.Roles }
                ];
                table.defaultSort = UserInfoIdentifiers.Username;
                return table;

            case TableSourceType.Company:
                return;

            case TableSourceType.Bucket:
                const bucket: SchemaInfo = await this.modalService.openMedium<ContentSelectConfig, ContentType>(
                    ContentSelectComponent, getContentSelectConfig(ContentSelectType.Schema, this.ucProject)) as SchemaInfo;
                if (!bucket) {
                    // Execution stopped
                    return;
                }
                table.source = bucket.id;
                table.columns = [
                    { identifier: FormDefinitionMetadataIdentifiers.SeqId },
                    { identifier: FormDefinitionMetadataIdentifiers.CreatedBy },
                    { identifier: FormDefinitionMetadataIdentifiers.CreatedAt },
                    { identifier: FormDefinitionMetadataIdentifiers.LastModifiedBy },
                    { identifier: FormDefinitionMetadataIdentifiers.LastModifiedAt },
                    { identifier: FormDefinitionMetadataIdentifiers.State }
                ];
                return table;
        }
    }

    private async getCopiedTable(): Promise<UcTable | undefined> {
        try {
            const text = await this.clipboard.getText();
            const copiedTable = text ? JSON.parse(text) as UcTable : undefined;
            if (!copiedTable?.sourceType) {
                // Wrong copied text/format
                throw new Error('Pasted Table.sourceType undefined');
            }

            copiedTable.identifier = undefined as any;
            copiedTable.title = undefined as any;
            delete copiedTable.id;
            delete copiedTable.lastModifiedAt;
            delete copiedTable.lastModifiedBy;
            delete copiedTable.lastPublishedAt;
            delete copiedTable.lastPublishedBy;
            delete copiedTable.publishState;

            return copiedTable;

        } catch (e) {
            return;
        }
    }

    private async askPaste(): Promise<boolean | undefined> {
        const choice = await this.modalService.openMedium<TypeSelectConfig, TypeSelectOption>(
            TypeSelectComponent, {
            title: 'Create table',
            types: [{
                type: 'paste',
                label: 'Paste copied table',
                icon: 'paste'
            }, {
                type: 'new',
                label: 'Define new table',
                icon: 'add'
            }]
        });

        if (!choice) {
            return;
        }

        return choice.type === 'paste';
    }

    private async askSourceType(): Promise<TableSourceType | undefined> {
        // Table sourceType
        const options = [
            {
                type: TableSourceType.Bucket,
                label: this.tableSourceTypeLabelPipe.transform(TableSourceType.Bucket),
            }, {
                type: TableSourceType.Users,
                label: this.tableSourceTypeLabelPipe.transform(TableSourceType.Users),
            }
        ];

        const sourceTypeChoice = await this.modalService.openMedium<TypeSelectConfig, TypeSelectOption>(
            TypeSelectComponent, {
            title: 'Select a source type',
            types: options
        });

        if (sourceTypeChoice == null) {
            return;
        }

        return sourceTypeChoice.type as TableSourceType;
    }

    private async loadBucketInfo(table: UcTable): Promise<TableInfo | null> {

        const dataDescriptor = await this.tableEditorService.getBucketDataDescriptor(table.source as string);

        if (!dataDescriptor) {
            return null;
        }

        return { table, dataDescriptor };
    }

    private async loadCompanyInfo(table: UcTable): Promise<TableInfo> {
        const dataDescriptor = await this.tableEditorService.getCompanyDataDescriptor();

        if (!dataDescriptor) {
            throw new Error('Company data descriptor undefined');
        }

        return { table, dataDescriptor };
    }

    private async loadUsersInfo(table: UcTable): Promise<TableInfo> {
        const dataDescriptor = await this.tableEditorService.getUserDataDescriptor();

        if (!dataDescriptor) {
            throw new Error('User data descriptor undefined');
        }

        // TODO - Should this be done in DDE ?
        const units = dataDescriptor.propertyDescriptors.find(dpd => dpd.identifier === UserInfoIdentifiers.Units);
        if (units) {
            units.type = FieldType.Hierarchy;
            units.icon = 'hierarchy';
        }

        return { table, dataDescriptor };
    }

    private resolvePromise(promise: Promise<TableInfo | null>, prevUrl: string): Observable<TableInfo> {
        // Wraps promises with an obserable that completes which will cancel navigation
        return new Observable(subscriber => {
            promise.then(d => {
                if (d) {
                    subscriber.next(d);
                } else {
                    this.router.navigateByUrl(prevUrl);
                }
                subscriber.complete();
            }).catch(err => this.toast.error(err.message || 'An error occurred while loading your table'));
        });
    }
}
