<div #wrapper class="column grow container">
    <div *ngIf="!errorMessage" class="row wrapper">
        <uc-hierarchy-finder-column *ngFor="let column of columns; let i = index;" [label]="column.label"
            [units]="column.units" [selected]="column.selected" [parentId]="column.parentId"
            (selectUnit)="select($event, i)" (updateGroupLabel)="editColumnLabel(i)" [hideEditButton]="i === 0"
            (finderUnitChange)="finderUnitChange($event, i)" (add)="add(i)">
        </uc-hierarchy-finder-column>
    </div>
    <uf-message *ngIf="errorMessage" [showExpander]="false" class="error small" icon="alert" [content]="errorMessage">
        <button class="uf-button x-small right" type="button" (click)="retry()">
            Retry
        </button>
    </uf-message>
</div>