import { Component, HostBinding, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import { ModalService, ToastService } from '@unifii/library/common';
import { CompoundType, FieldType, FormData } from '@unifii/sdk';

import { UcClient } from 'client';

import { ModalFormComponent, ModalFormData } from 'components/content/modals/modal-form.component';
import { BreadcrumbService } from 'services/breadcrumb.service';
import { ContextService } from 'services/context.service';

import { LimitService } from 'services/limit.service';

import { ProjectTableManager } from './project-table-manager';


@Component({
    template: `
        <uf-table-container ufSyncRoute (addItem)="addProject()" class="cards">
            <uf-breadcrumbs title [breadcrumbs]="breadcrumbs" class="large"></uf-breadcrumbs>
        </uf-table-container>
    `,
    providers: [
        { provide: TableContainerManager, useClass: ProjectTableManager }
    ]
})
export class ProjectsComponent {

    @HostBinding('class.stretch-component') class = true;

    breadcrumbs = this.breadcrumbService.getBreadcrumbs(this.route);

    constructor(
        private modalService: ModalService,
        private toastService: ToastService,
        private ucClient: UcClient,
        private limitService: LimitService,
        private context: ContextService,
        private breadcrumbService: BreadcrumbService,
        private route: ActivatedRoute,
        @Inject(TableContainerManager) private tableManager: ProjectTableManager
    ) {
        this.context.project = null;
    }

    async addProject() {
        try {
            const projects = await this.tableManager.getProjects();
            if (!this.limitService.canAddProject(projects)) {
                this.toastService.warning('Project limit reached');
                return;
            }

            const formData = await this.modalService.openMedium<ModalFormData, FormData>(ModalFormComponent, this.formDefinition);
            if (formData == null) {
                return;
            }
            await this.ucClient.addProject(formData.name);
            this.toastService.success('Project added');
            this.tableManager.refeshList();
        } catch (error) {
            this.toastService.error(error.message);
        }
    }

    private get formDefinition(): ModalFormData {
        return {
            title: 'New Project',
            definition: {
                identifier: null as any,
                label: null as any,
                compoundType: CompoundType.Form,
                fields: [{
                    identifier: 'name',
                    label: 'Name',
                    type: FieldType.Text,
                    isRequired: true
                }]
            }
        };
    }


}
