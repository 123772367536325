<div class="grid" *ngIf="fm.variations && field.variations?.length">
    <uf-select class="col-1of1" label="Variation" nameProperty="name" [options]="field.variations" [value]="variation"
        (valueChange)="variationChange($event)" placeholder="Default">
    </uf-select>
</div>

<ng-container *ngIf="variation || field">

    <uf-expander class="uf-box flat gap--bottom-small" [isExpanded]="expanded">
        <div expanderHeader class="uf-app-bar flat">
            <span class="title primary">Settings</span>
            <uf-icon *ngIf="invalids.settings" name="warning" [class.icon-warning-submitted]="field.isSubmitted"
                class="icon-warning"></uf-icon>
        </div>
        <div expanderBody class="uc-group-content">
            <uc-field-settings [field]="variation || field" (refresh)="refresh($event)" (edited)="edited($event)">
            </uc-field-settings>
        </div>
    </uf-expander>

    <uf-expander *ngIf="visibleGroups.display" class="uf-box flat gap--bottom-small">
        <div expanderHeader class="uf-app-bar flat">
            <span class="title primary">Display</span>
            <uf-icon *ngIf="invalids.display" name="warning" [class.icon-warning-submitted]="field.isSubmitted"
                class="icon-warning"></uf-icon>
        </div>
        <div expanderBody class="uc-group-content">
            <uc-field-display [field]="variation || field" (refresh)="refresh($event)" (edited)="edited($event)">
            </uc-field-display>
        </div>
    </uf-expander>

    <uf-expander *ngIf="visibleGroups.visibility" class="uf-box flat gap--bottom-small">
        <div expanderHeader class="uf-app-bar flat">
            <span class="title primary">Visibility</span>
            <uf-icon *ngIf="invalids.visibility" name="warning" [class.icon-warning-submitted]="field.isSubmitted"
                class="icon-warning"></uf-icon>
        </div>
        <div expanderBody class="uc-group-content">
            <uc-field-visibility [field]="variation || field" (refresh)="refresh($event)" (edited)="edited($event)">
            </uc-field-visibility>
        </div>
    </uf-expander>

    <uf-expander *ngIf="visibleGroups.nested" class="uf-box flat gap--bottom-small">
        <div expanderHeader class="uf-app-bar flat">
            <span class="title primary">Visible Fields</span>
            <uf-icon *ngIf="invalids.nested" name="warning" [class.icon-warning-submitted]="field.isSubmitted"
                class="icon-warning "></uf-icon>
        </div>
        <div expanderBody class="uc-group-content">
            <uc-field-nested [field]="variation || field" (refresh)="refresh($event)" (edited)="edited($event)">
            </uc-field-nested>
        </div>
    </uf-expander>

    <uf-expander *ngIf="visibleGroups.options" class="uf-box flat gap-sm-bottom small">
        <div expanderHeader class="uf-app-bar flat">
            <span class="title primary">Options</span>
            <uf-icon *ngIf="invalids.options" name="warning" [class.icon-warning-submitted]="field.isSubmitted"
                class="icon-warning"></uf-icon>
            <uc-expander-controls *ngIf="(variation || field).options?.length" [container]="optionsWrap">
            </uc-expander-controls>
        </div>
        <div expanderBody class="col pad-sm-top" #optionsWrap>
            <uc-field-options [field]="variation || field" (refresh)="refresh($event)" (edited)="edited($event)">
            </uc-field-options>

            <div class="row pad-sm" *ngIf="field?.type !== fieldType.Bool">
                <button class="uf-button x-small right" (click)="addOption()">
                    Add Option
                </button>
            </div>
        </div>
    </uf-expander>

    <uf-expander *ngIf="visibleGroups.validators" class="uf-box flat gap--bottom-small">
        <div expanderHeader class="uf-app-bar flat">
            <span class="title primary">Validators</span>
            <uf-icon *ngIf="invalids.validators" name="warning" [class.icon-warning-submitted]="field.isSubmitted"
                class="icon-warning"></uf-icon>
            <uc-expander-controls *ngIf="field?.validators?.length" [container]="validatorsWrap"></uc-expander-controls>
        </div>
        <div expanderBody class="uc-group-content grid" #validatorsWrap>
            <uc-field-validators class="col-1of1" [field]="variation || field" (refresh)="refresh($event)"
                (edited)="edited($event)">
            </uc-field-validators>
            <div class="col-1of1 row">
                <button class="uf-button x-small right" (click)="addValidator()">
                    Add Validator
                </button>
            </div>
        </div>
    </uf-expander>

    <div *ngIf="!field.variations?.length" class="row space-children col-1of1">
        <button class="uf-button x-small right" *ngIf="customFields" (click)="saveAsCustomField()">
            Save as Custom
        </button>
    </div>

</ng-container>