<form *ngIf="ready && notifyEnabled" class="grid">

    <!-- Details  -->
    <uf-text class="col-1of1" label="Label" *ngIf="config.label.show" [(value)]="field.label"
        [control]="form.controls.label | asUfControl">
    </uf-text>
    <uf-text class="col-1of1" label="Short label" *ngIf="config.shortLabel.show" [(value)]="field.shortLabel"
        [control]="form.controls.shortLabel | asUfControl">
    </uf-text>
    <uf-text class="col-1of1" label="Identifier" *ngIf="config.identifier.show" [(value)]="field.identifier"
        [control]="form.controls.identifier | asUfControl" [maxLength]="identifierMaxLength">
    </uf-text>

    <uf-message class="col-1of1 x-small warning" icon="warningSolid" *ngIf="showWarningIdentifier">
        <p>Identifier is too long</p>
    </uf-message>

    <!-- Link list for collections only -->
    <uf-select class="col-1of1" label="Collection" *ngIf="config.collection.show" [(value)]="field.definitionIdentifier"
        [control]="form.controls.collection | asUfControl" nameProperty="name" valueProperty="identifier"
        [options]="builderService.collections" disabled="true"></uf-select>
    <uf-multi-choice class="col-1of1" label="Types" *ngIf="config.types.show" [(value)]="field.types"
        [control]="form.controls.types | asUfControl" nameProperty="name" valueProperty="identifier"
        [options]="builderService.collections" disabled="false">
    </uf-multi-choice>

    <uc-markdown *ngIf="config.helpText.show && field.type !== fieldType.Content" class="col-1of1" label="Help text"
        [control]="form.controls.helpText | asUfControl" [(value)]="field.help" [showAlignmentOptions]="false">
    </uc-markdown>

    <uc-markdown *ngIf="config.helpText.show && field.type === fieldType.Content" class="col-1of1" label="Content"
        [control]="form.controls.helpText | asUfControl" [(value)]="field.help"
        [showAlignmentOptions]="showAlignmentOptions">
    </uc-markdown>

    <!-- currency -->
    <uf-select class="col-1of1" label="Currency" [(value)]="field.currency" *ngIf="config.currency.show"
        [control]="form.controls.currency | asUfControl" valueProperty="value" nameProperty="name"
        [options]="currencies">
    </uf-select>
    <uf-text class="col-1of1" label="Placeholder" *ngIf="config.placeholder.show" [(value)]="field.placeholder"
        [control]="form.controls.placeholder | asUfControl"></uf-text>
    <uc-step class="col-1of1" *ngIf="config.step.show" [(value)]="field.step"
        [control]="form.controls.step | asUfControl">
    </uc-step>
    <uf-text class="col-1of1" label="Format" *ngIf="config.format.show" [(value)]="field.format"
        [control]="form.controls.format | asUfControl">
    </uf-text>
    <uf-text class="col-1of1" label="Autofill" *ngIf="config.autofill.show" [(value)]="field.autofill"
        [control]="form.controls.autofill | asUfControl"></uf-text>
    <uf-text class="col-1of1" label="Bind to" *ngIf="config.bindTo.show" [(value)]="field.bindTo"
        [control]="form.controls.bindTo | asUfControl"></uf-text>
    <uf-chips class="col-1of1" label="Tags" *ngIf="config.tags.show" [(value)]="field.tags"
        [control]="form.controls.tags | asUfControl" [allowCustom]="true" (searchChange)="filterTags($event)"
        [options]="filteredTags" (valueChange)="builderService.refreshTags()"></uf-chips>

    <!-- Attributes -->
    <uf-checkbox class="col-1of1" label="Read only" *ngIf="config.isReadOnly.show" [(value)]="field.isReadOnly"
        [control]="form.controls.isReadOnly | asUfControl"></uf-checkbox>
    <uf-checkbox class="col-1of1" label="Required" *ngIf="config.isRequired.show" [(value)]="field.isRequired"
        [control]="form.controls.isRequired | asUfControl"></uf-checkbox>
    <uf-checkbox class="col-1of1" label="One to many" *ngIf="config.isOneToMany.show" [(value)]="field.isOneToMany"
        [control]="form.controls.isOneToMany | asUfControl"></uf-checkbox>
    <uf-checkbox class="col-1of1" label="AutoDetect" *ngIf="config.autoDetect.show" [(value)]="field.autoDetect"
        [control]="form.controls.autoDetect | asUfControl"></uf-checkbox>
    <uf-number class="col-1of1" label="Maximum length" *ngIf="config.maxLength.show" [(value)]="field.maxLength"
        [control]="form.controls.maxLengt | asUfControl"></uf-number>
    <uf-number class="col-1of1" label="Precision" *ngIf="config.precision.show" [(value)]="field.precision"
        [control]="form.controls.precision | asUfControl"></uf-number>

    <!-- Searchable -->
    <uf-checkbox class="col-1of1" label="Searchable" *ngIf="config.searchable.show" [(value)]="field.isSearchable"
        [control]="form.controls.searchable | asUfControl"></uf-checkbox>

    <!-- Reportable -->
    <uf-checkbox class="col-1of1" label="Reportable" *ngIf="config.reportable.show" [(value)]="field.isReportable"
        [control]="form.controls.reportable | asUfControl"></uf-checkbox>

    <uf-checkbox class="col-1of1" label="Translatable" *ngIf="config.translatable.show" [(value)]="field.isTranslatable"
        [control]="form.controls.translatable | asUfControl"></uf-checkbox>

    <uf-hierarchy-unit class="col-1of1" label="Starting Unit" *ngIf="config.ceiling.show" [(value)]="hierarchyCeiling"
        (valueChange)="updateFieldCeiling($event)" [control]="form.controls.ceiling | asUfControl"
        [selectLeafsOnly]="false" [isRequired]="false" placeholder="Root">
    </uf-hierarchy-unit>

    <uf-select class="col-1of1" label="Limit selection to"
        *ngIf="config.selectionMode.show && field.hierarchyConfig != null"
        [(value)]="$any(field.hierarchyConfig).selectionMode" [options]="selectionModeOptions"
        valueProperty="identifier" nameProperty="name">
    </uf-select>

</form>