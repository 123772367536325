import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import { FilterEntries } from '@unifii/library/common';

import { UcProject } from 'client';

import { WorkflowRulesTableManager } from './workflow-rules-table-manager';
import { workflowRuleFilterFactory } from './workflows-filters';


@Component({
    providers: [
        { provide: FilterEntries, useFactory: workflowRuleFilterFactory, deps: [UcProject] },
        { provide: TableContainerManager, useClass: WorkflowRulesTableManager }
    ],
    template: `
        <div ufMasterDetail>
            <uf-table-container ufSyncRoute (addItem)="add()" class="accent pad-none list-md"></uf-table-container>
        </div>
        <router-outlet></router-outlet>
    `
})
export class WorkflowRulesComponent {

    constructor(
        private router: Router,
        private route: ActivatedRoute
    ) { }

    add() {
        this.router.navigate(['new'], { relativeTo: this.route });
    }

}
