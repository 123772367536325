<div class="stretch-component col">
    <div class="tenant-nav row space-children center-all pad-sm-sides">
        <a [routerLink]="['/']" class="logo">
            <img src="/assets/svg/menu-logo.svg" alt="Unifii" class="unifii-logo" />
        </a>

        <div class="row center-all"
            restrict="ProjectManager,ContentEditor,FormDesigner,Publisher,Importer,FormDataViewer,WorkflowDesigner">
            <div>
                <a [routerLink]="['/projects' ]" class="uf-button tertiary"
                    restrict="ProjectManager,ContentEditor,FormDesigner,Publisher,Importer,FormDataViewer,WorkflowDesigner">Projects</a>
            </div>
            <a *ngIf="(projectMenuConfig.contextMenuConfig?.length || 0) > 1" label="Select Project"
                (click)="openContextMenu($event, projectMenuConfig)" class="uf-action tertiary">
                <uf-icon name="arrowDownSolid"></uf-icon>
            </a>
        </div>

        <div class="grow"></div>

        <div class="row">
            <uc-menu-item *ngFor="let config of menuConfigs" [restrict]="config.restrict" [label]="config.label"
                [icon]="config.icon" (click)="openContextMenu($event, config)">
            </uc-menu-item>
        </div>
    </div>
    <div class="row grow">
        <uc-project-nav></uc-project-nav>
        <div class="grow console-content">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>