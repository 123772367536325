import { Component, HostBinding } from '@angular/core';


@Component({
    templateUrl: './lab.html'
})
export class LabComponent {

    @HostBinding('class.stretch-component') class = true;
}
