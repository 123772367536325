import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { Language } from '@unifii/sdk';

import { UcCollection, UcCollectionTranslation, UcDefinition, UcProject } from 'client';

import { ContextService } from 'services/context.service';


export interface LanguageCollectionData {
    definition: UcDefinition;
    ucCollection: UcCollection;
    ucCollectionTranslation: UcCollectionTranslation;
}

export const languageCollectionResolver: ResolveFn<LanguageCollectionData> = async (route: ActivatedRouteSnapshot) => {

    const ucProject = inject(UcProject);
    const context = inject(ContextService);
    const collectionId = route.params.collectionId;
    const ucCollection = ucProject.collection(collectionId);
    const ucCollectionTranslation = ucCollection.translation((context.language as Language).code);

    try {
        const definition = await ucCollection.getDefinition();
        return { definition, ucCollection, ucCollectionTranslation };
    } catch (e) {
        throw new Error(e);
    }
}