import { Subscription } from 'rxjs';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DataPropertyDescriptor, UfControl, UfControlGroup } from '@unifii/library/common';
import { NodeType, OperatorComparison, Option } from '@unifii/sdk';

import { FilterEditorNode, FilterNodeControlKeys } from './filter-editor-form-ctrl';


@Component({
    selector: 'uc-filter-entry',
    templateUrl: './filter-entry.html'
})
export class FilterEntryComponent implements OnInit, OnDestroy {

    @Input() control: UfControlGroup;
    @Input() dataProperties: DataPropertyDescriptor[];
    @Input() cssClass: string | string[] = 'small';

    readonly controlKeys = FilterNodeControlKeys;
    readonly nodeTypes = NodeType;

    // options
    fieldOptions: DataPropertyDescriptor[] = [];
    operatorOptions: Option[] = [];
    valueTypeOptions: Option[] = [
        { identifier: NodeType.Expression as any as string, name: 'Expression' },
        { identifier: NodeType.Value as any as string, name: 'Value' }
    ];

    get filterEntry(): FilterEditorNode {
        return this.control.getRawValue();
    }

    get fieldControl() {
        return this.control.get(FilterNodeControlKeys.Field) as UfControl;
    }

    get operatorControl() {
        return this.control.get(FilterNodeControlKeys.Operator) as UfControl;
    }

    get valueTypeControl() {
        return this.control.get(FilterNodeControlKeys.ValueType) as UfControl;
    }

    get valueControl() {
        return this.control.get(FilterNodeControlKeys.Value) as UfControl;
    }

    private subs: Subscription[] = [];

    ngOnInit() {

        this.operatorOptions = (this.filterEntry.field?.operators ?? []).map(o => this.getOperatorOption(o));

        // Left change
        this.subs.push(this.fieldControl.valueChanges.subscribe(v => {
            const property = v as DataPropertyDescriptor | null;
            this.operatorOptions = [...(property?.operators ?? [])].map(o => this.getOperatorOption(o));
            const nextOperator = this.operatorOptions.length === 1 ? this.operatorOptions[0].identifier : null;
            this.operatorControl.setValue(nextOperator);
        }));

        // Operator change
        this.subs.push(this.operatorControl.valueChanges.subscribe(() => {
            this.valueTypeControl.setValue(null);
        }));

        // ValueType change
        this.subs.push(this.valueTypeControl.valueChanges.subscribe(() => {
            this.valueControl.setValue(null);
        }));
    }

    ngOnDestroy() {
        for (const sub of this.subs) {
            sub.unsubscribe();
        }
    }

    async search(q?: string) {

        if (!q || !q.trim().length) {
            this.fieldOptions = [...this.dataProperties];
            return;
        }

        const lowerCaseQ = q.toLowerCase().trim();

        this.fieldOptions = this.dataProperties.filter(entry => {
            const label = entry.display ?? entry.label ?? entry.identifier;
            return label.toLowerCase().indexOf(lowerCaseQ) >= 0;
        });
    }

    private getOperatorOption(operator: OperatorComparison): Option {

        let name;
        switch (operator) {
            case 'eq': name = 'Equal'; break;
            case 'ne': name = 'Not equal'; break;
            case 'lt': name = 'Lower than'; break;
            case 'le': name = 'Lower equal'; break;
            case 'gt': name = 'Greater than'; break;
            case 'ge': name = 'Greater equal'; break;
            case 'in': name = 'In'; break;
            case 'contains': name = 'Contains'; break;
            case 'descs': name = 'Descendants'; break;
            default: name = ''; break;
        }

        return { identifier: operator, name };
    }
}
