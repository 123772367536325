import { Component, HostBinding, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import { Breadcrumb, FilterComponentRegistry, FilterEntries } from '@unifii/library/common';

import { UcProject } from 'client';

import { CollectionService, collectionServiceFactory } from 'pages/content/collections/collection-service';

import { BreadcrumbService } from 'services/breadcrumb.service';
import { InfoFilterFactory } from 'services/table/info-filter-factory';
import { InfoFilterRegistry } from 'services/table/info-filter-registry';
import { CollectionCompoundLoader } from 'services/table/info-loaders';
import { InfoTableManager } from 'services/table/info-table-manager';
import { InfoLoader, InfoType } from 'services/table/models';


const createLoader = (service: CollectionService) => new CollectionCompoundLoader(service.ucCollection);

const createFilters = (filterFactory: InfoFilterFactory) => filterFactory.create(InfoType.CollectionData);

@Component({
    providers: [
        { provide: CollectionService, useFactory: collectionServiceFactory, deps: [ActivatedRoute, UcProject] },
        { provide: FilterComponentRegistry, useClass: InfoFilterRegistry },
        { provide: FilterEntries, useFactory: createFilters, deps: [InfoFilterFactory] },
        { provide: InfoLoader, useFactory: createLoader, deps: [CollectionService] },
        { provide: TableContainerManager, useClass: InfoTableManager },
    ],
    template: `
        <div ufMasterDetail>
            <uf-table-container ufSyncRoute (addItem)="addItem()" class="accent list-md pad-none">
                <uf-breadcrumbs title [breadcrumbs]="breadcrumbs" class="large"></uf-breadcrumbs>
            </uf-table-container>
        </div>
        <router-outlet></router-outlet>
    `
})
export class CollectionDataComponent implements OnInit {

    @HostBinding('class.stretch-component') class = true;

    breadcrumbs: Breadcrumb[];

    constructor(
        private service: CollectionService,
        private route: ActivatedRoute,
        private breadcrumbService: BreadcrumbService,
        @Inject(TableContainerManager) private manager: InfoTableManager
    ) { }

    async ngOnInit() {
        await this.service.definitionLoadPromise;
        this.breadcrumbs = this.breadcrumbService.getBreadcrumbs(this.route, [this.service.definition?.label]);
    }

    addItem() {
        this.manager.addActionCallback();
    }

}
