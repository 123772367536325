<div class="uf-box flat row">
    <div class="uf-grid grow pad-top pad-left pad-bottom">
        <uf-text class="col-6" label="Label" [control]="control.controls.label | asUfControl"></uf-text>
        <uf-autocomplete class="col-6" label="Form" [control]="control.controls.form | asUfControl" [options]="forms"
            (searchChange)="filterForms($event)" nameProperty="_display" (valueChange)="formChange($event)">
        </uf-autocomplete>
        <uf-select class="col-12" label="Transition" placeholder="Select transition"
            [control]="control.controls.transition | asUfControl" [options]="transitions"
            [nameProperty]="transitionNamePipe">
        </uf-select>
        <div class="col-12" *ngIf="$any(control.controls.fieldMappings).controls.length">
            <h4>Data Mapping</h4>
            <table class="uf-table inputs accent">
                <thead>
                    <tr>
                        <th>Field in Primary Form</th>
                        <th>Field in Triggered Form</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let c of $any(control.controls.fieldMappings).controls; index as i">
                        <td class="input-cell">
                            <uf-autocomplete class="table-cell" [options]="parentFieldOptions"
                                [control]="c.controls.parentField" nameProperty="optionLabel"
                                (searchChange)="filterParentFormFields($event, c.controls.childField.value)">
                            </uf-autocomplete>
                        </td>
                        <td class="input-cell">
                            <uf-autocomplete class="table-cell" [options]="childFieldOptions"
                                [control]="c.controls.childField" nameProperty="optionLabel"
                                (searchChange)="filterChildFormFields($event, c.controls.parentField.value)">
                            </uf-autocomplete>
                        </td>
                        <td class="input-cell">
                            <button class="uf-action tertiary right gap-sm-right"
                                (click)="$any(control.controls.fieldMappings).removeAt(i)">
                                <uf-icon name="delete"></uf-icon>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <uf-message class="col-12 warning" *ngIf="dataMappingDisabledWarning" icon="warning" icon="warning"
            [content]="dataMappingDisabledWarning"></uf-message>
        <div class="col-12">
            <button class="uf-button right" (click)="addMappingsControl()">
                Add Data
            </button>
        </div>
    </div>
    <a class="uf-action tertiary gap-sm-top gap-sm-right" (click)="delete()">
        <uf-icon name="delete"></uf-icon>
    </a>
</div>