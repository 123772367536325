<uf-panel class="container">
    <div class="header-pane uf-app-bar transparent">
        <uf-breadcrumbs [breadcrumbs]="breadcrumbs" class="large">
        </uf-breadcrumbs>
    </div>
    <div class="content-pane">
        <uf-tabs>
            <uf-tab label="Live Form Data" path="live"></uf-tab>
            <uf-tab label="Preview Form Data" path="preview"></uf-tab>
        </uf-tabs>
    </div>
</uf-panel>