import { Inject } from '@angular/core';
import { DataPropertyInfoService, DataSourceMappingDisplayAllowedDataTypes } from '@unifii/library/common';
import { AstNode, DataSource, DataSourceOutputMap, DataSourceType, Dictionary, FieldType, NodeType, OutputField, SchemaField } from '@unifii/sdk';

import { FormEditorCache } from './form-editor-cache';
import { FormEditorFunctions } from './form-editor-functions';


interface DataSourceErrors {
    notFound: string[];
    incompatible: string[];
}

interface DataSourceMappingInfo {
    target: string;
    source: string;
    valueType: FieldType | null;
}


export class DataSourceValidator {

    constructor(
        @Inject(FormEditorCache) private cache: FormEditorCache,
        private dataPropertyInfoService: DataPropertyInfoService
    ) { }

    async validate(dataSource: DataSource): Promise<string | null> {

        if (DataSourceType.Named === dataSource.type) {
            return null;
        }

        if (DataSourceType.External === dataSource.type) {
            return await this.validateExternal(dataSource.id as string);
        }

        // Other DS types shared the same structure
        const { id, outputs, type, outputFields, filter } = dataSource;
        if (outputs == null || !Object.keys(outputFields ?? {}).length) {
            return 'Data source missconfigured';
        }

        switch (type) {
            case DataSourceType.Collection: return await this.validateCollection(id as string, outputs, outputFields);
            case DataSourceType.Bucket: return await this.validateBucket(id as string, outputs, outputFields);
            case DataSourceType.Users: return await this.validateUser(outputs, outputFields, filter);
            case DataSourceType.Company: return await this.validateCompany(outputs, outputFields);
        }
        return null;
    }

    private async validateExternal(id: string): Promise<string | null> {
        const externalDataSource = await this.cache.getExternalDataSource(id);
        return externalDataSource ? null : 'External data source not found';
    }

    private async validateCompany(outputs: DataSourceOutputMap, outputFields?: Dictionary<OutputField>): Promise<string | null> {
        const mappingInfo = this.getMappingInfo(outputs, outputFields)
            .filter(o => /^claims\..*/.test(o.source))
            .map(info => {
                info.source = info.source.replace('claims.', '');
                return info;
            });

        if (!mappingInfo.length) {
            return null;
        }

        try {
            const claimConfig = await this.cache.listCompanyClaimConfig() ?? [];
            const schemaFields = claimConfig.map(c => ({ identifier: c.type, type: c.valueType, label: '' }));

            const { notFound, incompatible } = this.errorReducer(mappingInfo, schemaFields);
            return this.createError(notFound, incompatible);
        } catch (e) {
            return 'Failed to load claim configuration';
        }
    }

    private async validateUser(outputs: DataSourceOutputMap, outputFields?: Dictionary<OutputField>, filter?: AstNode): Promise<string | null> {

        if (filter && filter.args && filter.args.length !== 0) {
            const parentNode = filter.args.find(arg => arg.args?.find(arg2 => arg2.type === NodeType.Identifier && arg2.value === 'roles'));
            const userNode = parentNode?.args?.find(arg => arg.type === NodeType.Value);
            if (userNode) {

                const errorMessage = await FormEditorFunctions.missingRoleError(this.cache, userNode.value);
                // console.log(userNode, errorMessage);
                if (errorMessage) {
                    return errorMessage;
                }
            }
        }

        const mappingInfo = this.getMappingInfo(outputs, outputFields)
            .filter(o => /^claims\..*/.test(o.source))
            .map(info => {
                info.source = info.source.replace('claims.', '');
                return info;
            });

        if (!mappingInfo.length) {
            return null;
        }

        try {
            const claimConfig = await this.cache.listUserClaimConfig() ?? [];
            const schemaFields = claimConfig.map(c => ({ identifier: c.type, type: c.valueType, label: '' }));

            const { notFound, incompatible } = this.errorReducer(mappingInfo, schemaFields);
            return this.createError(notFound, incompatible);
        } catch (e) {
            return 'Failed to load claim configuration';
        }
    }

    private async validateBucket(id: string, outputs: DataSourceOutputMap, outputFields?: Dictionary<OutputField>): Promise<string | null> {
        const schema = await this.cache.getSchema(id);
        if (schema == null) {
            return 'Form Data Repository not found';
        }

        const mappingInfo = this.getMappingInfo(outputs, outputFields, this.dataPropertyInfoService.formDefinitionReferences.map(info => info.identifier));

        const { notFound, incompatible } = this.errorReducer(mappingInfo, schema.fields);
        return this.createError(notFound, incompatible);
    }

    private async validateCollection(id: string, outputs: DataSourceOutputMap, outputFields?: Dictionary<OutputField>): Promise<string | null> {
        const definition = await this.cache.getCollectionDefinition(id);
        if (definition == null) {
            return 'Collection not found';
        }

        const schemaField = (definition.fields?.filter(f => f.identifier != null) ?? []) as SchemaField[];
        const mappingInfo = this.getMappingInfo(outputs, outputFields, this.dataPropertyInfoService.collectionItemReferences.map(info => info.identifier));

        const { notFound, incompatible } = this.errorReducer(mappingInfo, schemaField);
        return this.createError(notFound, incompatible);
    }

    private errorReducer(mappingInfo: DataSourceMappingInfo[], fields: SchemaField[]): DataSourceErrors {

        return mappingInfo.reduce((errors, { target, source, valueType }) => {
            const isExpression = /{{.*?}}/g.test(source);
            if (isExpression) {
                return errors;
            }

            const found = this.matchSource(source, fields, valueType);
            if (found == null) {
                const valueTypeError = valueType != null ? ` (${valueType})` : '';
                errors.notFound.push(`${source}${valueTypeError}`);
            }
            else if (valueType != null && found?.type !== valueType) {
                errors.incompatible.push(`${source} (${valueType}, ${found?.type})`);
            }
            else if (target === '_display' && found?.type && !DataSourceMappingDisplayAllowedDataTypes.includes(found?.type)) {
                errors.incompatible.push(`${target} data type ${found?.type} not allowed`);
            }
            return errors;
        }, { notFound: [], incompatible: [] } as DataSourceErrors);
    }

    private createError(notFound: string[], incompatible: string[]): string | null {
        if (!notFound.length && !incompatible.length) {
            return null;
        }

        let message = '';
        if (notFound.length) {
            message += 'Data source missing mapped fields: ' + notFound.join(', ') + '. ';
        }
        if (incompatible.length) {
            message += 'Data source mapped fields have incompatible data types: ' + incompatible.join(', ') + '. ';
        }
        return message;
    }

    private getMappingInfo(outputs: Dictionary<string>, outputFields?: Dictionary<OutputField>, exclude: string[] = []): DataSourceMappingInfo[] {
        return Array.from(this.outputIterator(outputs, outputFields, exclude));
    }

    private *outputIterator(outputs: Dictionary<string>, outputFields?: Dictionary<OutputField>, exclude: string[] = []): Iterable<DataSourceMappingInfo> {
        for (const target of Object.keys(outputs)) {
            const source = outputs[target];
            let valueType: FieldType | null = null;
            if (outputFields != null) {
                valueType = outputFields[target]?.type ?? null;
            }

            if (!exclude.includes(source)) {
                yield { target, source, valueType };
            }
        }
    }

    private matchSource(source: string, fields: SchemaField[], sourceValueType: FieldType | null): { type: FieldType } | null {
        for (const { identifier, type, dataSourceConfig } of fields) {
            if (source === identifier) {
                return { type };
            }

            if (!source.includes('.')) {
                continue;
            }

            const [parent, child] = source.split('.');
            if (parent !== identifier) {
                continue;
            }
            // Trust that children of Hierarchy fields are
            if (type === FieldType.Hierarchy) {
                return { type: sourceValueType ?? FieldType.Text };
            }

            if (dataSourceConfig != null) {
                const { outputFields } = dataSourceConfig;
                if (outputFields == null) {
                    return null;
                }
                return outputFields[child] as any;
            }

        }
        return null;
    }


}
