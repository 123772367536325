import { isAfter, parseISO } from 'date-fns';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Component, OnDestroy } from '@angular/core';
import { ToastService, UfControl, UfControlArray, UfControlGroup, ValidatorFunctions } from '@unifii/library/common';

import { App, UcApps } from 'client';

import { EditData } from 'components/common/edit-data';

import { DialogsService } from 'services/dialogs.service';

import { AppDetailsComponent } from './app-details.component';


@Component({
    selector: 'uc-app-settings',
    templateUrl: 'app-settings.html'
})
export class AppSettingsComponent implements EditData, OnDestroy {

    app: App;
    editingGoogleKey: boolean;
    editSecret: boolean;
    newSecret: string | null;
    form = new UfControlGroup({
        id: new UfControl(ValidatorFunctions.required('Id is required')),
        name: new UfControl(ValidatorFunctions.required('Name is required')),
        appSecretControl: new UfControl(ValidatorFunctions.minLength(12, 'Secret must be at least 12 characters.')),
        corsOrigins: new UfControlArray([])
    });

    private formChangesSubscription: Subscription | null;

    constructor(
        private ucApps: UcApps,
        private parent: AppDetailsComponent,
        private toastService: ToastService,
        private dialogs: DialogsService
    ) {
        this.load();
    }

    set edited(v: boolean) {
        this.parent.edited = v;
    }

    get edited() {
        return this.parent.edited;
    }

    get isNew() {
        return this.id === 'new';
    }

    get id() {
        return this.parent.id;
    }

    get isAppleCertExpired(): boolean {
        return this.app.appleCertNotAfter != null && isAfter(new Date(), parseISO(this.app.appleCertNotAfter));
    }

    get showAppleCertDetails(): boolean {
        return this.app.appleCertName != null && !this.uploadedAppleCert;
    }

    get showAppleCertPreview(): boolean {
        return !this.app.appleCertName && this.uploadedAppleCert;
    }

    get uploadedAppleCert(): boolean {
        return this.app.appleCert != null && !this.app.appleCertName;
    }

    ngOnDestroy() {
        this.parent.edited = false;
    }

    updateAppleCertificate(files: File[]) {

        if (!files.length) {
            return;
        }

        const reader = new FileReader();

        reader.addEventListener('load', () => {

            this.app.appleCert = (reader.result as string).split(',')[1];

            delete this.app.appleCertName;
            delete this.app.appleCertNotAfter;
            delete this.app.appleIsSandbox;

            this.form.markAsDirty();
            this.edited = false;

        }, false);

        reader.readAsDataURL(files[0]);
    }

    downloadAppleCertificate() {
        // TODO > find out why data url's wont work in IE
        const link = document.createElement('a');
        link.setAttribute('href', 'data:text/csv;charset=utf-8;base64,' + this.app.appleCert);
        link.setAttribute('download', this.app.id + '.p12');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    addCorsOrigin() {
        (this.form.controls?.corsOrigins as UfControlArray).push(this.getCorsControl());
    }

    async deleteCorsOriging(i: number) {
        if (!await this.dialogs.confirmDelete()) {
            return;
        }
        this.app.corsOrigins?.splice(i, 1);
        (this.form.controls?.corsOrigins as UfControlArray).removeAt(i);
    }

    save() {

        this.form.setSubmitted();

        if (this.form.invalid) {
            return;
        }

        if (!this.editSecret && !this.app.secret) {
            delete this.app.secret;
        }

        if (this.app.corsOrigins) {
            this.app.corsOrigins = this.app.corsOrigins.map(this.clearOriginUrl);
        }

        this.ucApps.save(this.app).then(result => {

            this.edited = false;
            this.editSecret = false;
            this.form.markAsPristine();

            if (this.isNew) {
                this.ucApps.appAdded.next(this.app);
            } else {
                this.ucApps.appModified.next(this.app);
            }

            this.toastService.success('App saved');
            this.app = result;
            this.init();
        }, err => {
            this.toastService.error(err.message || 'Save failed');
        });

    }

    changeSecret(secret: string) {
        this.app.secret = secret;
        this.editSecret = true;
    }

    private async load() {

        if (this.isNew) {
            this.app = {
                id: null as any,
                name: null as any,
                corsOrigins: []
            };
            this.editingGoogleKey = true;
        } else {
            this.app = await this.ucApps.getDetail(this.id);
            this.ucApps.appModified.next(this.app);
        }

        (this.app.corsOrigins || []).forEach(() => (this.form.controls.corsOrigins as UfControlArray).push(this.getCorsControl()));

        this.init();
    }

    private async init() {

        if (this.formChangesSubscription) {
            this.formChangesSubscription.unsubscribe();
            this.formChangesSubscription = null;
        }

        this.editSecret = !this.app.secret;

        this.parent.edited = false;

        this.formChangesSubscription = this.form.statusChanges.pipe(filter(() => !this.form.pristine)).subscribe(() =>
            this.edited = true
        );
    }

    private getCorsControl(): UfControl {
        return new UfControl(ValidatorFunctions.required('Origin is required'));
    }

    private clearOriginUrl(url: string): string {
        return url.trim().replace(/\/$/, '');
    }

}
