import { Component, HostBinding, Inject, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import { Breadcrumb, ClipboardService, ModalService, ToastService } from '@unifii/library/common';

import { ApiKey, UcAPIKeys, UcPermissions } from 'client';

import { BreadcrumbService } from 'services/breadcrumb.service';

import { APIKeysTableManager } from './api-keys-table-manager';
import { APIKeysComponent } from './api-keys.component';


@Component({
    templateUrl: './api-key-details.html',
    styleUrls: ['./api-key-details.less'],
    providers: [{
        provide: UcPermissions,
        useFactory: (service: UcAPIKeys, route: ActivatedRoute) => service.permissions(route.snapshot.params.id),
        deps: [UcAPIKeys, ActivatedRoute]
    }]
})
export class APIKeyDetailsComponent implements OnDestroy {

    @HostBinding('class.stretch-component') class = true;

    item: ApiKey;
    breadcrumbs: Breadcrumb[] = [];

    constructor(
        private ucAPIKeys: UcAPIKeys,
        private toast: ToastService,
        private router: Router,
        private route: ActivatedRoute,
        private parent: APIKeysComponent,
        private modalService: ModalService,
        protected clipboard: ClipboardService,
        private breadcrumbService: BreadcrumbService,
        @Inject(TableContainerManager) private tableManager: APIKeysTableManager
    ) {
        this.init();
    }

    async init() {
        if (this.parent.newApiKey != null) {
            this.item = this.parent.newApiKey;
        } else {
            this.item = await this.ucAPIKeys.getDetails(this.route.snapshot.params.id);
            delete this.item.secret; // TODO Remove once API is fixed
        }

        this.breadcrumbs = this.breadcrumbService.getBreadcrumbs(this.route, [this.item.name]);
    }

    ngOnDestroy() {
        this.parent.newApiKey = null;
    }

    async revoke() {
        if (!await this.modalService.openConfirm({
            title: `Confirm Revoke`,
            message: `Revoke ${this.item.name}?`
        })) {
            return;
        }

        await this.ucAPIKeys.delete(this.item.key as string);
        this.toast.success(`API Key ${this.item.name} revoked`);
        this.tableManager.reload.next();
        this.router.navigate(['../'], { relativeTo: this.route });
    }
}
