<div class="header-pane uf-app-bar transparent">
    <uf-breadcrumbs [breadcrumbs]="breadcrumbs" class="large">
        <span class="gap-sm-left">{{ edited ? '*' : ''}}</span>
    </uf-breadcrumbs>
    <a type="button" class="uf-button tertiary right" [routerLink]="['../']">
        Cancel
    </a>
    <button type="button" class="uf-button primary" (click)="save()">
        Save
    </button>
</div>
<uf-panel class="content-pane container">
    <div class="uf-container-lg">
        <div class="uf-grid row-gap-lg pad-top">
            <uf-message *ngIf="!!error" [content]="error" icon="error" class="error col-12"></uf-message>
            <ng-container *ngIf="form" [formGroup]="form">
                <div class="uf-box col-12">
                    <div class="uf-app-bar flat accent">
                        <div class="title">Details</div>
                    </div>
                    <div class="uf-grid pad">
                        <uf-text [formControlName]="controlKeys.Label" class="col-12" label="Title">
                            <span class="suffix">*</span>
                        </uf-text>
                        <uf-autocomplete [formControlName]="controlKeys.Bucket" class="col-12"
                            label="Form Data Repository" placeholder="Search form data repository" [allowCustom]="false"
                            (searchChange)="searchBuckets($event)" nameProperty="name" [options]="buckets"
                            (valueChange)="bucketChange($event)">
                        </uf-autocomplete>
                        <uf-autocomplete placeholder="Select an integration" nameProperty="name"
                            [options]="integrations" (searchChange)="searchIntegration($event)"
                            [formControlName]="controlKeys.Integration" (valueChange)="integrationChange($event)"
                            class="col-12" label="Integration">
                        </uf-autocomplete>
                        <uf-autocomplete placeholder="Select a feature" nameProperty="name" [options]="filteredFeatures"
                            [formControlName]="controlKeys.Feature" (searchChange)="searchFeature($event)"
                            (valueChange)="featureChange($event)" class="col-12" label="Feature">
                        </uf-autocomplete>
                    </div>
                </div>

                <div *ngIf="!!inputMapControl?.value.length" class="col-12">
                    <h4>Data Mapping</h4>
                    <table class="uf-table inputs accent clickable">
                        <thead>
                            <tr>
                                <th>Integration Parameter</th>
                                <th>Form Data</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let ic of (inputMapControl| asUfControlArray).controls">
                                <ng-container *ngIf="(ic | asUfControl) as inputControl">
                                    <td class="row" (click)="inputArgClick(inputControl)">
                                        <div *ngIf="inputControl?.value.isRequired" class="suffix gap-right">
                                            *
                                        </div>
                                        {{inputControl.value?.identifier}}
                                    </td>
                                    <td (click)="inputArgClick(inputControl)">
                                        <span *ngIf="inputControl.value?.field">
                                            {{inputControl.value?.field?.display }}
                                        </span>
                                        <span
                                            *ngIf="(inputControl.submitted || inputControl.touched) && inputControl.invalid"
                                            class="uf-lozenge error">Required</span>
                                    </td>
                                </ng-container>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </ng-container>
        </div>
    </div>
</uf-panel>