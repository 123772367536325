import { filter, Subscription } from 'rxjs';

import { Directive, HostBinding, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';


@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class TabsPage implements OnInit, OnDestroy {
    @HostBinding('class.stretch-component') stretch = true;
    @HostBinding('class.hide-tabs') hideTabs = true;

    route: ActivatedRoute;
    router: Router;
    edited = false;

    protected subscriptions = new Subscription();

    constructor() {

        this.route = inject(ActivatedRoute);
        this.router = inject(Router);

        this.updateTabVisibility();

        // hide first level tabs when we're at the user level
        this.subscriptions.add(this.router.events.pipe(
            filter(e => e instanceof NavigationEnd))
            .subscribe(() => {
                this.updateTabVisibility();
            }));
    }

    ngOnInit(): void {
        this.updateTabVisibility();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private updateTabVisibility() {
        this.hideTabs = !!(this.route.children.length && this.route.children[0].children.length);
    }

}
