<ng-template [ngIf]="ready">

    <div class="header">
        <button type="button" class="uf-action tertiary" (click)="close()" title="Close">
            <uf-icon name="close"></uf-icon>
        </button>
        <h3>{{title}}</h3>
    </div>

    <uf-panel class="container">

        <div *ngIf="message" class="padded--big">{{ message }}</div>

        <uf-form #pageForm [definition]="definition" [(formData)]="formData" [config]="config"></uf-form>

    </uf-panel>

    <div *ngIf="pageForm" class="row space-children pad">
        <button type="button" class="uf-button tertiary right" (click)="close()">{{ cancelText }}</button>
        <button type="button" class="uf-button primary" (click)="save()">{{ actionText }}</button>
    </div>

</ng-template>