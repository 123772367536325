import { Component, HostBinding, Inject } from '@angular/core';
import { Modal, ModalData, ModalRuntime, UfControlGroup } from '@unifii/library/common';
import { SchemaField } from '@unifii/sdk';

import { FormEditorFunctions } from '../form-editor-functions';


export interface SchemaFieldMappingData {
    sourceFields: SchemaField[];
    targetFields: SchemaField[];
    mappingGroup: { control: UfControlGroup; sourceKey: string; targetKey: string };
    sourceFieldLabel: string;
    targetFieldLabel: string;
}

/**
 * @description
 * SchemaFieldMapperComponent is a generic modal class that is responsible
 * for mapping a source schema field to a target schema field
 */
@Component({
    templateUrl: './schema-field-mapper.html'
})
export class SchemaFieldMapperComponent implements Modal<SchemaFieldMappingData, UfControlGroup> {

    @HostBinding('class.uf-form-card') cardClass = true;

    formGroup: UfControlGroup;
    sourceFields: SchemaField[] = [];
    targetFields: SchemaField[] = [];
    sourceKey = 'source';
    targetKey = 'target';

    sourceFieldLabel: string;
    targetFieldLabel: string;

    private _sourceFields: SchemaField[] = [];
    private _targetFields: SchemaField[] = [];

    constructor(
        public runtime: ModalRuntime<SchemaFieldMappingData, UfControlGroup>,
        @Inject(ModalData) public data: SchemaFieldMappingData
    ) {
        const { sourceFields, targetFields, mappingGroup, sourceFieldLabel, targetFieldLabel } = data;
        this._sourceFields = sourceFields;
        this._targetFields = targetFields;

        this.formGroup = mappingGroup.control;
        this.sourceKey = mappingGroup.sourceKey;
        this.targetKey = mappingGroup.targetKey;

        this.formGroup.setSubmitted(true);
        this.sourceFieldLabel = sourceFieldLabel;
        this.targetFieldLabel = targetFieldLabel;
    }

    close() {
        this.runtime.close();
    }

    submit() {
        this.formGroup.setSubmitted();
        if (this.formGroup.invalid) {
            return;
        }

        this.runtime.close(this.formGroup);
    }

    sourceSearch(q?: string) {
        const target = this.formGroup.get(this.targetKey)?.value;

        this.sourceFields = this._sourceFields
            .filter(o => this.queryFilter(o, q) && (target == null || o.type === target.type));
    }

    targetSearch(q?: string) {
        const source = this.formGroup.get(this.sourceKey)?.value as SchemaField | null;

        if (!source) {
            this.targetFields = [];
            return;
        }

        this.targetFields = this._targetFields
            .filter(targetField => this.queryFilter(targetField, q) && FormEditorFunctions.areTransitionMappingFieldsCompatible(source, targetField));
    }

    namePropertyFunc = (field: SchemaField) => `${field.shortLabel ?? field.label} (${field.identifier})`;

    private queryFilter(field: SchemaField, q = ''): boolean {
        let { identifier, label } = field;
        identifier = identifier.toLowerCase();
        label = label.toLowerCase();

        q = q.trim().toLowerCase();
        return identifier.includes(q) || label.includes(q);
    }
}