<div class="bg-img"></div>

<uf-panel class="container">

  <uf-progress [inProgress]="inProgress" class="large"></uf-progress>

  <div class="box">

    <div *ngIf="tenantSettings" class="contact-info">
      <button class="uf-action" title="Contact Info" (click)="showContactInfo()">
        <uf-icon name="query"></uf-icon>
      </button>
    </div>

    <div class="logo-wrapper">
      <ng-template [ngIf]="tenantSettings?.logo?.url" [ngIfElse]="defaultLogo">
        <img class="logo" [src]="tenantSettings?.logo?.url+'?h=100'" alt="Unifii" />
      </ng-template>
      <ng-template #defaultLogo>
        <img class="logo" src="assets/svg/product-logo.svg" alt="Unifii" />
      </ng-template>
    </div>

    <div class="content">
      <ng-content select="[content]"></ng-content>
    </div>

    <div class="footer body-copy">
      <div class="row">
        <div class="grow">
          <p class="small">
            <a href="https://www.unifii.com.au/privacy-policy" target="_blank">
              Privacy Policy
            </a>
          </p>
          <uf-description-list [items]="{version: config.version }"></uf-description-list>
        </div>
        <div class="right row">
          <a class="col" href="https://www.unifii.com.au" target="_blank">
            <img class="supplier-logo" src="assets/svg/product-logo-secondary.svg" alt="Powered by Unifii" />
          </a>
        </div>
      </div>
    </div>
  </div>

</uf-panel>