import { Component, Input } from '@angular/core';
import { DescriptionListItem, MomentDatePipe, UfControlGroup } from '@unifii/library/common';
import { CompoundType, StructureNode, StructureNodeType } from '@unifii/sdk';

import { UcCompound, UcDefinition, UcMedia, UcPage, UcProject, UcTable, UcView } from 'client';

import { FieldReferenceHelper } from 'helpers/field-reference-helper';

import { TableSourceTypeLabelPipe } from 'pipes/table-source-type-label.pipe';

import { StructureEditorService } from '../structure-editor.service';
import { StructureFunctions } from '../structure-functions';


@Component({
    selector: 'uc-node-content-info',
    templateUrl: './node-content-info.html',
})
export class NodeContentInfoComponent {

    readonly displayDateTimeFormat = 'eee, MMM d, yyyy h:mm a';

    ready: boolean;
    contentInfo: DescriptionListItem[] | null;
    contentLink: any[] | null;
    contentTypeLabel: string;
    unpublishedWarningMessage: string | null;
    canCopy = false;

    private _control: UfControlGroup;

    @Input()
    set control(v: UfControlGroup) {
        this._control = v;
        this.canCopy = this.service.canCopy(this.node, v);
        // Reset
        this.loadContentInfo();
    }

    get control() {
        return this._control;
    }

    get node(): StructureNode {
        return this.control.getRawValue() as StructureNode;
    }

    constructor(
        private project: UcProject,
        private media: UcMedia,
        private tableSourceTypeLabel: TableSourceTypeLabelPipe,
        private momentDatePipe: MomentDatePipe,
        private service: StructureEditorService
    ) { }

    copy() {
        this.service.copyAndInsert(this.node, this.control);
    }

    private async loadContentInfo() {

        this.ready = false;

        this.contentTypeLabel = FieldReferenceHelper.getFieldReference(this.node, CompoundType.Structure).label;
        this.contentLink = null;
        this.unpublishedWarningMessage = null;

        let content: UcPage | UcView | UcDefinition | UcCompound | UcTable | null = null;

        switch (this.node.type) {

            case StructureNodeType.Page:
                content = await this.project.getPage(this.node.id as number);
                break;

            case StructureNodeType.View:
                content = await this.project.getView(this.node.id as number);
                break;

            case StructureNodeType.Form:
                content = await this.project.getForm(this.node.definitionIdentifier as string);
                break;

            case StructureNodeType.Collection:
                content = await this.project.collection(this.node.definitionIdentifier as string).getDefinition();
                break;

            case StructureNodeType.CollectionItem:
                content = await this.project.collection(this.node.definitionIdentifier as string).getItem(this.node.id as number);
                break;

            case StructureNodeType.FormBucket:
                if (this.node.id) {
                    content = await this.project.getTable(this.node.id as any as string);
                }
                break;
        }

        this.contentInfo = [];

        // PDF Viewer
        if (this.node.type === StructureNodeType.PdfViewer) {
            const media = await this.media.getDetail(this.node.id as number);
            this.contentInfo.push({ term: 'Title', description: media.title });
            this.contentInfo.push({ term: 'Uploaded At', description: this.momentDatePipe.transform(media.uploadedAt, this.displayDateTimeFormat) ?? '' });
        }

        if (content) {
            // Collection
            if (this.node.type === StructureNodeType.Collection) {
                this.contentInfo.push({ term: 'Collection', description: (content as UcDefinition).label });
            }
            // CollectionItem
            if (this.node.type === StructureNodeType.CollectionItem) {
                this.contentInfo.push({ term: 'Collection', description: this.node.definitionLabel });
                this.contentInfo.push({ term: 'Title', description: (content as UcCompound).recordName });
            }
            // Page
            if (this.node.type === StructureNodeType.Page) {
                this.contentInfo.push({ term: 'Title', description: (content as UcPage).recordName });
                this.contentInfo.push({ term: 'Identifier', description: this.node.definitionIdentifier });
            }
            // View
            if (this.node.type === StructureNodeType.View) {
                this.contentInfo.push({ term: 'Title', description: (content as UcView).recordName });
                this.contentInfo.push({ term: 'Identifier', description: this.node.definitionIdentifier });
            }
            // Form
            if (this.node.type === StructureNodeType.Form) {
                this.contentInfo.push({ term: 'Title', description: (content as UcDefinition).label });
                this.contentInfo.push({ term: 'Identifier', description: this.node.definitionIdentifier });
                this.contentInfo.push({ term: 'Form Data Repository', description: (content as UcDefinition).bucket });
            }
            // Table
            if (this.node.type === StructureNodeType.FormBucket) {
                const tableContent = content as UcTable;
                this.contentInfo.push({ term: 'Title', description: this.node.definitionLabel });
                this.contentInfo.push({ term: 'Identifier', description: tableContent.identifier });

                if (tableContent.sourceType) {
                    this.contentInfo.push({ term: 'Source Type', description: this.tableSourceTypeLabel.transform(tableContent.sourceType) });
                }

                if (tableContent.source) {
                    this.contentInfo.push({ term: 'Source', description: tableContent.source });
                }
            }

            // common
            if (StructureFunctions.isNodeContentPublished(this.node)) {
                this.unpublishedWarningMessage = null;
            } else {
                if (content.lastPublishedAt == null) {
                    this.unpublishedWarningMessage = `This content is unpublished`;
                } else {
                    this.unpublishedWarningMessage = `The current version of this content is unpublished`;
                }
            }
            if (content.publishState) {
                this.contentInfo.push({ term: 'State', description: content.publishState });
            }
            if (content.lastModifiedAt) {
                this.contentInfo.push({ term: 'Last Modified', description: this.momentDatePipe.transform(content.lastModifiedAt, this.displayDateTimeFormat) ?? '' });
            }
            if (content.lastPublishedAt) {
                this.contentInfo.push({ term: 'Last Published', description: this.momentDatePipe.transform(content.lastPublishedAt, this.displayDateTimeFormat) ?? '' });
            }
        }

        this.contentLink = this.getContentLink();
        this.ready = true;
    }

    private getContentLink(): any[] | null {
        switch (this.node.type) {
            case StructureNodeType.Collection: return ['..', 'content', 'collections', this.node.definitionIdentifier];
            case StructureNodeType.CollectionItem: return ['..', 'content', 'collections', this.node.definitionIdentifier, this.node.id];
            case StructureNodeType.View: return ['..', 'content', 'views', this.node.id];
            case StructureNodeType.Page: return ['..', 'content', 'pages', this.node.id];
            case StructureNodeType.Form: return ['..', 'forms', this.node.id];
            case StructureNodeType.FormBucket: return ['..', 'tables', this.node.id];
            case StructureNodeType.PdfViewer: return ['../../../', 'assets', 'media', this.node.id];
        }

        return null;
    }
}
