import { Subject, Subscription } from 'rxjs';

import { inject, Injectable, OnDestroy } from '@angular/core';
import { TableContainerManager, TableInputManager, TableInputs } from '@unifii/components';
import {
    CellDisplayDescriptor, FilterEntry, FilterValue, HierarchyUnitProvider, TableAction, TableConfig, TableConfigColumn, TableRowContext, ToastService
} from '@unifii/library/common';
import { CellTemplateType, MessageColour } from '@unifii/sdk';

import { PermissionPrincipalType, Role, UcRoles } from 'client';

import { PermissionChangeAction, PermissionsManagerService } from 'components/permissions/permissions-manager.service';

import { RolesDataSource } from './roles-datasource';


@Injectable()
export class RolesTableManager implements TableContainerManager<Role, FilterValue, FilterEntry>, OnDestroy {

    tableConfig: TableConfig<Role>;
    defaultSort = 'name';
    showSearch = true;
    addActionConfig = true;

    customColumns: CellDisplayDescriptor[] = [{
        name: 'permissionsCount',
        variations: [{
            condition: 'permissionsCount > 0',
            value: 'radioTick',
            template: {
                type: CellTemplateType.Icon,
                colour: MessageColour.Success
            }
        }],
        defaultTemplate: {
            type: CellTemplateType.Icon
        }
    }];

    reload = new Subject<void>();
    update = new Subject<TableInputs<FilterValue>>();
    updateItem = new Subject<Role>();
    inputManager: TableInputManager<FilterValue, FilterEntry>;

    private permissionSubscription: Subscription;

    constructor(
        private ucRoles: UcRoles,
        private toastService: ToastService,
        permissionsManager: PermissionsManagerService
    ) {
        this.inputManager = new TableInputManager([], inject(HierarchyUnitProvider));

        this.permissionSubscription = permissionsManager.notify.subscribe(async info => {
            if (info.principalType !== PermissionPrincipalType.Role || info.action !== PermissionChangeAction.Edited) {
                return;
            }
            this.reload.next();
        });

        this.tableConfig = {
            id: 'roles',
            columns: this.columns,
            actions: this.actions,
            pageSize: 50,
            selectable: true,
            columnToggles: true,
            rowLink: role => role.id as string
        };
    }

    ngOnDestroy(): void {
        this.permissionSubscription.unsubscribe();
    }

    createDataSource(inputs?: TableInputs<FilterValue> | undefined) {
        return new RolesDataSource(this.ucRoles, inputs?.q, inputs?.sort);
    }

    private get columns(): TableConfigColumn<Role>[] {
        return [{
            name: 'name',
            label: 'Name',
            sortable: true
        }, {
            name: 'description',
            label: 'Description'
        }, {
            name: 'permissionsCount',
            label: 'Permissions'
        }];
    };

    private get actions(): TableAction<Role>[] {
        return [{
            label: 'Delete',
            icon: 'delete',
            action: (rows: TableRowContext<Role>[]) => this.delete(rows.map(r => r.$implicit))
        }];
    }

    private async delete(roles: Role[]) {
        // Create new reference so array can be modified for printing a meaningful error message
        roles = [...roles];

        try {
            for (let i = (roles.length - 1); i >= 0; i--) {
                const role = roles[i];
                await this.ucRoles.delete(role.id as string);
                roles.splice(i, 1);
            }
            this.toastService.success(`Role/s deleted`);
        } catch (e) {
            this.toastService.error(`Failed to delete roles: ${roles.map(r => r.name).join(', ')}`);
        } finally {
            this.reload.next();
        }
    }


}

