import { WorkflowNotificationConditionType, WorkflowNotificationRecipientClaimMatchType, WorkflowNotificationRecipientFormDataType, WorkflowNotificationRecipientType } from 'client';

export const WorkflowNotificationRecipientOptions = [
    { name: 'CreatedBy', identifier: WorkflowNotificationRecipientType.CreatedBy },
    { name: 'LastModifiedBy', identifier: WorkflowNotificationRecipientType.LastModifiedBy },
    { name: `CreatedBy's Manager`, identifier: WorkflowNotificationRecipientType.CreatedByManager },
    { name: `LastModifiedBy's Manager `, identifier: WorkflowNotificationRecipientType.LastModifiedByManager },
    { name: 'User', identifier: WorkflowNotificationRecipientType.User },
    { name: 'Role', identifier: WorkflowNotificationRecipientType.Role },
    { name: 'Claim', identifier: WorkflowNotificationRecipientType.Claim },
    { name: 'Email', identifier: WorkflowNotificationRecipientType.Email },
    { name: 'User Data Source', identifier: WorkflowNotificationRecipientType.UserDatasource },
    { name: `User Data Source's Manager`, identifier: WorkflowNotificationRecipientType.UserDatasourceManager },
    { name: 'Email Field', identifier: WorkflowNotificationRecipientType.EmailField },
    { name: 'Combination', identifier: WorkflowNotificationRecipientType.Combo },
];

export const WorkflowNotificationUserManagementRecipientOptions = [
    { name: 'User', identifier: WorkflowNotificationRecipientType.User },
    { name: 'User Modified', identifier: WorkflowNotificationRecipientType.UserModified },
    { name: `User Modified's Manager`, identifier: WorkflowNotificationRecipientType.UserModifiedManager },
    { name: 'Role', identifier: WorkflowNotificationRecipientType.Role },
    { name: 'Claim', identifier: WorkflowNotificationRecipientType.Claim },
    { name: 'Email', identifier: WorkflowNotificationRecipientType.Email },
    { name: 'Combination', identifier: WorkflowNotificationRecipientType.Combo },
];

export const WorkflowNotificationFormDataOptions = [
    { name: 'User Data Source', identifier: WorkflowNotificationRecipientFormDataType.User },
    { name: `User Data Source's Manager`, identifier: WorkflowNotificationRecipientFormDataType.UserManager },
    { name: 'Email Field', identifier: WorkflowNotificationRecipientFormDataType.Email },
];

export const WorkflowNotificationUserManagentConditions = [
    WorkflowNotificationConditionType.Role,
    WorkflowNotificationConditionType.Claim,
    // WorkflowNotificationConditionType.Hierarchy,
];

export const WorkflowNotificationConditions = [
    WorkflowNotificationConditionType.Role,
    WorkflowNotificationConditionType.Claim,
    WorkflowNotificationConditionType.Hierarchy,
    WorkflowNotificationConditionType.Company,
];

export const WorkflowNotificationClaimMatchTypes = [
    { name: 'Value', identifier: WorkflowNotificationRecipientClaimMatchType.Value },
    { name: 'User (CreatedBy)', identifier: WorkflowNotificationRecipientClaimMatchType.CreatedBy },
    { name: 'User (LastModifiedBy)', identifier: WorkflowNotificationRecipientClaimMatchType.LastModifiedBy },
    { name: 'Form Data', identifier: WorkflowNotificationRecipientClaimMatchType.FormData },
];

export const WorkflowNotificationUserManagementClaimMatchTypes = [
    { name: 'Value', identifier: WorkflowNotificationRecipientClaimMatchType.Value },
    { name: 'User Modified', identifier: WorkflowNotificationRecipientClaimMatchType.UserModified },
];