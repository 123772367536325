<uf-panel class="container">
    <div class="header-pane uf-app-bar transparent">
        <uf-breadcrumbs [breadcrumbs]="breadcrumbs" class="large"></uf-breadcrumbs>
    </div>
    <div class="content-pane">
        <uf-tabs>
            <uf-tab label="Workflow Rules" path="rules"></uf-tab>
            <uf-tab label="Notifications" path="notifications"></uf-tab>
            <uf-tab label="Timers" path="timers"></uf-tab>
            <!-- <uf-tab label="Form Data Creation" path="form-data"></uf-tab> -->
            <uf-tab label="Data Forwarders" path="data-forwarders"></uf-tab>
        </uf-tabs>
    </div>
</uf-panel>