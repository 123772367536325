import { Subscription } from 'rxjs';

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UfControlGroup } from '@unifii/library/common';

import { FormEditorField, FormEditorTransition } from './form-editor-model';


@Component({
    selector: 'uc-form-field-icons',
    templateUrl: './form-field-icons.html'
})
export class FormFieldIconsComponent implements OnInit, OnDestroy {

    @Input() control: UfControlGroup;
    @Input() selected: boolean;

    protected hasVariations: boolean;
    protected hasTags: boolean;
    protected hasShowIf: boolean;
    protected hasAutofill: boolean;
    protected hasBindTo: boolean;
    protected isRequired: boolean;
    protected isReportable: boolean;
    protected isSearchable: boolean;
    protected hasDescription: boolean;
    protected hasSpawnOrTriggers: boolean;

    private subscriptions = new Subscription();

    ngOnInit(): void {
        this.subscriptions.add(this.control.valueChanges.subscribe(v => this.setup(v)));
        this.setup(this.control.getRawValue());
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private setup(field: FormEditorField) {
        this.hasVariations = (field.variations ?? []).length > 0;
        this.hasTags = (field.tags ?? []).length > 0;
        this.hasShowIf = !!field.showIf;
        this.hasAutofill = !!field.autofill;
        this.hasBindTo = !!field.bindTo;
        this.isRequired = !!field.isRequired;
        this.isReportable = !!field.isReportable;
        this.isSearchable = !!field.isSearchable;
        this.hasDescription = !!field.description;
        this.hasSpawnOrTriggers = this.checkIfHasSpawnOrTriggers(field.transitions);
    }

    private checkIfHasSpawnOrTriggers(transitions?: FormEditorTransition[]) {
        if (!transitions || transitions.length === 0) {
            return false;
        }

        return transitions.some(transition => (transition.triggers ?? []).length > 0 || (transition.spawns ?? []).length > 0);
    }

}