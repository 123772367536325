<div class="row">
    <uf-password *ngIf="!edit" class="grow" [disabled]="true" [showReveal]="edit" [control]="control" [label]="label"
        [value]="value">
        <uf-help *ngIf="help" [content]="help"></uf-help>
    </uf-password>
    <uf-password *ngIf="edit" class="grow" [showReveal]="true" [control]="control" [label]="label" [(value)]="value">
        <uf-help *ngIf="help" [content]="help"></uf-help>
    </uf-password>
    <div class="edit" *ngIf="initialValue">
        <button type="button" class="uf-button" (click)="toggleEdit()">
            <ng-container *ngIf="!edit">Edit</ng-container>
            <ng-container *ngIf="edit">Cancel Edit</ng-container>
        </button>
    </div>
</div>