import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { RuntimeField, UfControl, ValidatorFunctions } from '@unifii/library/common';
import { FieldType } from '@unifii/sdk';

import { SystemRole, UcCompound } from 'client';

import { ContextService } from 'services/context.service';

import { BuilderService } from '../../components/compound-builder/builder.service';


@Component({
    selector: 'uc-language-field',
    templateUrl: './language-field.html',
    styleUrls: ['./language-field.less']
})
export class LanguageFieldComponent implements OnInit, OnChanges {

    @Input() field: RuntimeField;
    @Input() compound: UcCompound;
    @Input() translation: UcCompound;

    @Output() valueChange: EventEmitter<UcCompound> = new EventEmitter<UcCompound>();

    label?: string;
    enabled: boolean;
    disabledControl: UfControl;

    constructor(
        private builderService: BuilderService,
        private context: ContextService
    ) {
        this.disabledControl = new UfControl(ValidatorFunctions.required('A value is needed.'));
        this.disabledControl.disable();
    }

    get translatable() {
        return this.field.isTranslatable && this.context.checkRoles(SystemRole.Translator);
    }

    get showAsText() {
        return [FieldType.Text, FieldType.MultiText, FieldType.Website, FieldType.Email, FieldType.Phone, FieldType.Number].indexOf(this.field.type) >= 0;
    }

    ngOnChanges(changes: SimpleChanges) {

        if (changes.field) {
            this.label = this.field.label;
            delete this.field.label;
        }
    }

    ngOnInit() {

        if (!this.translatable) {
            delete this.translation[this.field.identifier as string];
            return;
        }

        this.enabled = this.translation[this.field.identifier as string] !== undefined;
    }

    toggle() {
        if (this.enabled) {
            delete this.translation[this.field.identifier as string];
            this.enabled = false;
        } else {
            this.translation[this.field.identifier as string] = null;
            this.enabled = true;
        }

        this.emitChange(true);
    }

    copy() {
        this.translation[this.field.identifier as string] =
            Array.isArray(this.compound[this.field.identifier as string]) ?
                [...this.compound[this.field.identifier as string] as Array<any>] :
                this.compound[this.field.identifier as string];

        this.enabled = true;

        this.emitChange(true);
    }

    clear() {
        this.translation[this.field.identifier as string] = null;
        this.emitChange(true);
    }

    changedTranslation() {
        this.emitChange();
    }

    private emitChange(atomic?: boolean) {
        // console.log('translation changes');
        this.valueChange.emit(this.translation);
        this.builderService.fieldEdit.next({ subject: this.field, atomic });
    }

}
