import { SortStatus, TableDataSource, TableDataSourceQueryOptions } from '@unifii/library/common';
import { RequestAnalyticsOrigin } from '@unifii/sdk';

import { CompoundInfo } from 'client/content-models';
import { UcLanguage } from 'client/language';


export class LanguageViewsDataSource extends TableDataSource<CompoundInfo> {

    constructor(
        private ucLanguage: UcLanguage,
        private search?: string,
        private sort?: SortStatus,
        private status?: string
    ) {
        super();
    }

    get filtered(): boolean {
        return (this.search && this.search.trim().length > 0) || this.status != null;
    }

    get sorted(): boolean {
        return this.sort != null;
    }

    load(options?: TableDataSourceQueryOptions) {
        this.ucLanguage
            .getViews({
                params: {
                    q: this.search,
                    sort: this.sort?.toString(),
                    status: this.status,
                    offset: options?.offset,
                    limit: options?.limit
                }, analytics: { origin: RequestAnalyticsOrigin.Table }
            })
            .then(views => {
                this.stream.next({ data: views });
            }, error => {
                this.stream.next({ error });
            });
    }
}
