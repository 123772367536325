import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService } from '@unifii/library/common';
import { ErrorType, Interceptor } from '@unifii/sdk';


@Injectable()
export class SdkInterceptor implements Interceptor {

    private _disabled = false;

    constructor(
        private router: Router,
        private modalService: ModalService,
        private location: Location
    ) { }

    async intercept(source: Promise<any>): Promise<any> {
        try {
            return await source;
        } catch (error) {
            if (this.requiresRedirect(error.type)) {
                // Redirect to the most appropriate route
                await this.redirectUser(error.type);
                // The error may have happened in a modal dialog, close all of them here
                this.modalService.closeAll();
            }
            throw error;
        }
    }

    set disabled(v: boolean) {
        this._disabled = v;
    }

    get disabled(): boolean {
        return this._disabled;
    }

    private requiresRedirect(errorType: ErrorType): boolean {
        if (this.disabled) {
            return false;
        }
        return errorType === ErrorType.Unauthorized || errorType === ErrorType.Forbidden;
    }

    private async redirectUser(errorType: ErrorType): Promise<boolean> {
        const path = errorType === ErrorType.Forbidden ? '/unauthorized' : '/login';
        const currentUrl = this.location.path();
        // Already redirected
        if (currentUrl.startsWith(path)) {
            return true;
        }
        return await this.router.navigate([`/${path}`, { return: currentUrl }]);
    }

}