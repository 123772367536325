<uf-expander *ngIf="control" class="uf-box flat gap-sm-bottom">
    <div expanderHeader class="uf-app-bar flat">
        <div class="title primary">
            Parameters
        </div>
        <uf-icon *ngIf="control.invalid" name="error" class="error"></uf-icon>
        <uc-expander-controls *ngIf="control.length" [container]="argsWrap"></uc-expander-controls>
    </div>
    <div expanderBody #argsWrap class="uc-group-content grid">
        <ul *ngIf="control.length" class="uc-list--joined col-1of1">
            <ng-template ngFor let-arg [ngForOf]="control.controls | asUfControlsGroups" let-i="index">
                <uf-expander class="uc-group margin--vertical" [formGroup]="arg">
                    <div expanderHeader class="uf-app-bar flat">
                        <div class="title primary">
                            {{ arg.get(formKeys.Key)?.value }}
                        </div>
                        <uf-icon *ngIf="arg.invalid" name="error" class="error">
                        </uf-icon>
                        <button *ngIf="!arg.disabled" class="uf-action tertiary" (click)="removeArg(i)" title="Delete">
                            <uf-icon name="delete"></uf-icon>
                        </button>
                    </div>
                    <div expanderBody class="uc-group-content grid">
                        <uf-text [formControlName]="formKeys.Key" label="Key" class="col-1of1"></uf-text>
                        <uf-expression [formControlName]="formKeys.Value" label="Value" class="col-1of1">
                        </uf-expression>
                        <!-- <div class="col-1of1">{{arg.value | json}}</div> -->
                    </div>
                </uf-expander>
            </ng-template>
        </ul>
        <div class="col-1of1">
            <button *ngIf="!control.disabled" class="uf-button x-small right" (click)="addArg()">
                Add Parameter
            </button>
        </div>
    </div>
</uf-expander>