<uf-autocomplete [(value)]="value" [options]="options" [control]="control" (searchChange)="filter($event)"
    (valueChange)="valueChange.emit($event)" nameProperty="title" [autofocus]="autofocus">
    <ng-template #listBox let-options="options" let-select="select" let-active="active">
        <ul class="uf-list">
            <li class="uf-list-button" *ngFor="let option of options; let i = index" (click)="select(option)"
                [class.active]="i === active">
                <div class="image"
                    *ngIf="option.url && option.type !== 'File' && option.type !== 'Audio' && option.type !== 'Video'">
                    <img [src]="option?.url + '?w=48&h=48&mode=crop'" alt="option.title">
                </div>
                <span class="content">
                    <h4>{{option.title}}</h4>
                    <p>{{option.description}}</p>
                </span>
            </li>
        </ul>
    </ng-template>
</uf-autocomplete>