import { fromEvent, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { animate, style, transition, trigger } from '@angular/animations';
import {
    AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, InjectionToken, OnDestroy, RendererFactory2, ViewChild
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
    ContextProvider, GoogleLocationProvider, GoogleWindow, LocationProvider, ModalService, RuntimeDefinition, RuntimeDefinitionAdapter,
    StorageWrapper, ThemeProvider, ThemeService, ToastService, UfLocationProvider, WindowWrapper
} from '@unifii/library/common';
import { DebugValidation, FormConfiguration, FormDebugger, FormSettings } from '@unifii/library/smart-forms';
import { InputFormSettings, SubmitArgs, UfFormComponent } from '@unifii/library/smart-forms/input';
import {
    Client, Definition, ExternalDataSourcesClient, FormData, Interceptor, Option, ProjectContentOptions, ProjectContentOptionsInterace, ProjectInfo,
    PublishedContent, TokenStorage, TokenStorageInterface
} from '@unifii/sdk';

import { Config } from 'app-config';
import { UcClient, UcProject } from 'client';
import { SdkInterceptor } from 'client/sdk-interceptor';
import { PREVIEW_FORM_STORAGE_KEY, PROJECT_STORAGE_KEY } from 'constant';

import { PreviewContentService } from 'components/common/preview-content.service';

import { ContextService } from 'services/context.service';

import { FormFakeUploader } from './form-fake-uploader.service';


export const projectOptionsFactory = (projectInfo: ProjectInfo): ProjectContentOptionsInterace => ({
    projectId: projectInfo.id,
    preview: false
});

const createMapProvider = (
    contextService: ContextService,
    window: GoogleWindow,
    translateService: TranslateService,
    contextProvider: ContextProvider
): LocationProvider => {
    if (contextService.tenantSettings && contextService.tenantSettings.googleMapsApiKey) {
        return new GoogleLocationProvider(translateService, window, contextService.tenantSettings.googleMapsApiKey, contextProvider);
    }

    return new UfLocationProvider(translateService, window);
};

const ProjectProvider = new InjectionToken<ProjectInfo>('ProjectProvider');

const createProjectProvider = (storage: StorageWrapper): ProjectInfo => JSON.parse(storage.getItem(PROJECT_STORAGE_KEY));

const createThemeProvider = (projectInfo: ProjectInfo) => ({
    theme: {
        formStyle: projectInfo?.theme?.formStyle
    }
});

export const createProject = (client: UcClient, projectInfo: ProjectInfo, tokenStorage: TokenStorageInterface) =>
    new UcProject(client, { projectId: projectInfo.id, preview: false }, tokenStorage);

export const createExternalDataSource = (client: Client, projectInfo: ProjectInfo) =>
    new ExternalDataSourcesClient(client, { projectId: projectInfo.id });

export const fadeInAnimation = trigger('fadeIn', [
    transition(':enter', [
        style({ opacity: 0, transition: 'ease' }),
        animate('200ms', style({ opacity: 1 }))
    ]),
    transition(':leave', [
        style({ opacity: 1, transition: 'ease' }),
        animate('200ms', style({ opacity: 0 }))
    ])
]);

@Component({
    templateUrl: './form-preview.html',
    providers: [
        // deps for PreviewContentService
        { provide: ProjectProvider, useFactory: createProjectProvider, deps: [StorageWrapper] },
        { provide: ProjectContentOptions, useFactory: projectOptionsFactory, deps: [ProjectProvider] },
        { provide: UcProject, useFactory: createProject, deps: [UcClient, ProjectProvider, TokenStorage] },
        { provide: PublishedContent, useClass: PreviewContentService },
        { provide: ExternalDataSourcesClient, useFactory: createExternalDataSource, deps: [Client, ProjectProvider] },
        { provide: FormSettings, useClass: InputFormSettings },
        FormDebugger,
        { provide: ContextProvider, useExisting: FormDebugger },
        {
            provide: LocationProvider, useFactory: createMapProvider,
            deps: [ContextService, WindowWrapper, TranslateService, ContextProvider]
        },
        { provide: ThemeProvider, useFactory: createThemeProvider, deps: [ProjectProvider] }
    ],
    styleUrls: ['./form-preview.less'],
    animations: [fadeInAnimation]
})
export class FormPreviewComponent implements AfterViewInit, OnDestroy {

    @ViewChild(UfFormComponent, { static: false }) form: UfFormComponent;
    @ViewChild(UfFormComponent, { read: ElementRef }) set formComponent(element: ElementRef) {
        if (element == null) {
            return;
        }

        try {
            const themeService = new ThemeService(element.nativeElement, this.renderFactory);
            const projectTheme = this.projectInfo.theme;
            if (projectTheme && themeService) {
                themeService.theme = projectTheme;
            }
        } catch (error: any) {
            console.error(error);
        }
    }

    readonly validationOptions = [
        { label: 'On', value: DebugValidation.On },
        { label: 'Off', value: DebugValidation.Off },
        { label: 'Exclude required', value: DebugValidation.ExcludeRequired }
    ];

    formLoadError: boolean;
    hideData = true;
    formRoles: Option[] = [];
    selectedRoles: string[];
    states: Option[] = [];
    formConfig: FormConfiguration = { optionalCancelButtonLabel: 'Cancel', optionalSubmitButtonLabel: 'Emulate Submit' };
    definition: RuntimeDefinition | undefined;
    formData: FormData;
    settingsVisible = true;
    enableTriggers: boolean;
    edited: boolean;

    private storageSubscription: Subscription;
    private changesSub: Subscription;

    constructor(
        public formDebugger: FormDebugger,
        private modalService: ModalService,
        private toastService: ToastService,
        private ref: ChangeDetectorRef,
        private renderFactory: RendererFactory2,
        @Inject(WindowWrapper) window: Window,
        @Inject(FormSettings) settings: FormSettings,
        @Inject(StorageWrapper) private storage: StorageWrapper,
        @Inject(Config) public config: Config,
        @Inject(Interceptor) private interceptor: SdkInterceptor,
        @Inject(ProjectProvider) private projectInfo: ProjectInfo,
        private runtimeDefinitionAdapter: RuntimeDefinitionAdapter
    ) {
        settings.uploader = new FormFakeUploader();

        this.interceptor.disabled = true;
        this.formDebugger.validation = DebugValidation.ExcludeRequired;

        /** Small screen required for testing mobile size forms */
        const bodyElement = window.document.getElementsByTagName('body')[0];
        bodyElement.className = 'enable-mobile-screen';

    }

    ngAfterViewInit() {

        if (!this.form) {
            this.formLoadError = true;
            return;
        }

        this.storageSubscription = fromEvent<StorageEvent>(window, 'storage').pipe(
            filter(e => [PROJECT_STORAGE_KEY, PREVIEW_FORM_STORAGE_KEY].indexOf(e.key as string) >= 0))
            .subscribe(() => this.load());

        this.load();
    }

    ngOnDestroy() {
        this.storageSubscription.unsubscribe();
        this.changesSub.unsubscribe();
    }


    async setDefinition(v: Definition | null) {

        if (!this.form || !v) {
            return;
        }

        if (v.identifier === this.definition?.identifier) {
            return;
        }

        this.definition = await this.runtimeDefinitionAdapter.transform(v);

        setTimeout(() => {
            this.formRoles = this.formDebugger.formRoles.map(role => ({ identifier: role, name: role }));
            this.states = this.formDebugger.formStates.map(state => ({ identifier: state, name: state }));
            this.formDebugger.roles = [...this.formDebugger.formRoles];

            if (this.changesSub) {
                this.changesSub.unsubscribe();
            }

            this.edited = false;

            this.changesSub = this.form.rootControl.valueChanges.subscribe(() => {
                // console.log('valueChanges dirty', this.form.rootControl.dirty);
                if (this.form.rootControl.dirty) {
                    this.edited = true;
                }
            });
        }, 0);
    }

    async load() {

        try {
            this.formLoadError = false;
            await this.setDefinition(JSON.parse(this.storage.getItem(PREVIEW_FORM_STORAGE_KEY) || ''));
            this.edited = false;
            this.ref.detectChanges();
        } catch (e) {
            this.formLoadError = true;
            console.log('Error', this.formLoadError);
            this.ref.detectChanges();
        }
    }

    submit(args: SubmitArgs) {

        args.done(args.data);

        this.edited = false;

        this.toastService.success(`Transition to '${args.data._state} done'`);

        // List triggers
        if (this.enableTriggers && args.triggers) {
            for (const tr of args.triggers) {
                this.toastService.info(`Will trigger ${tr.label}`);
            }
        }
    }

    async close() {
        const close = await this.modalService.openConfirm({
            title: 'Leave',
            message: 'You are leaving the Form Preview page.',
            confirmLabel: 'Leave',
            cancelLabel: `Don't Leave`
        });

        if (close) {
            window.close();
        }
    }

    toggleWorkflow() {
        // console.log(this.formDebugger.enableWorkflow);
        if (!this.formDebugger.enableWorkflow) {
            // Disable workflow
            this.selectedRoles = [...this.formDebugger.roles];
            this.formDebugger.roles = [...this.formDebugger.formRoles];
        } else {
            // Re-enable workflow
            this.formDebugger.roles = [...this.selectedRoles];
        }
    }

}
