import { Subscription } from 'rxjs';

import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { UfControl, UfControlArray, UfControlGroup } from '@unifii/library/common';
import { FieldType, generateUUID } from '@unifii/sdk';

import { FieldControlKeys } from './form-editor-control-keys';
import { FormEditorFunctions } from './form-editor-functions';
import { FormEditorField, FormFieldScopedInfo } from './form-editor-model';
import { FormEditorStatus } from './form-editor-status';


@Injectable()
export class FormEditorFieldScopeManager implements OnDestroy {

    /** When a scope identifiers status change it emits the scopeUuid */
    scopeIdentifiersChange = new EventEmitter<string>();

    /** First Map is by field scopeUuid Second Map is by field uuid */
    private scopes: Map<string, Map<string, FormFieldScopedInfo>> = new Map();
    private identifiersChangeSubs: Map<string, Subscription> = new Map();
    private subscriptions = new Subscription();

    constructor(private status: FormEditorStatus) {
        this.subscriptions.add(this.scopeIdentifiersChange.subscribe(scopeUuid => {
            this.validateScopeFields(scopeUuid);
            this.updateRepeatSortableProperties(scopeUuid);
        }));
    }

    onAddedField(control?: UfControlGroup, skip = true) {

        if (!control) {
            return;
        }

        const scopeUuid = control.get(FieldControlKeys.ScopeUuid)?.value as string;
        const uuid = control.get(FieldControlKeys.Uuid)?.value as string;
        const scope = this.getScope(scopeUuid);
        const identifierControl = control.get(FieldControlKeys.Identifier) as UfControl | undefined;

        this.status.fieldByUuid.set(uuid, control);

        if (identifierControl) {
            this.identifiersChangeSubs.set(uuid, identifierControl.valueChanges.subscribe(() => {
                this.status.fieldsIdentifier.setValue(generateUUID());
            }));
        }

        if (!identifierControl) {
            console.warn('FormEditorFieldScopeManager.addField - missing Identifier control');
            return;
        }

        const fieldInfo: FormFieldScopedInfo = {
            control,
            uuid,
            identifier: identifierControl.value
        };

        scope.set(uuid, fieldInfo);

        if (!skip) {
            this.notifyScopeIdentifiersStatusChange(control.get(FieldControlKeys.ScopeUuid)?.value as string);
        }

        fieldInfo.identifierValueChangesSub = identifierControl.valueChanges.subscribe(v => {
            fieldInfo.identifier = v;
            this.notifyScopeIdentifiersStatusChange(control.get(FieldControlKeys.ScopeUuid)?.value as string);
        });
    }

    onRemovedField(control?: UfControlGroup) {

        if (!control) {
            return;
        }

        const scopeUuid = control.get(FieldControlKeys.ScopeUuid)?.value as string;
        const uuid = control.get(FieldControlKeys.Uuid)?.value as string;
        const type = control.get(FieldControlKeys.Type)?.value as FieldType;

        this.status.fieldByUuid.delete(uuid);
        this.identifiersChangeSubs.get(uuid)?.unsubscribe();
        this.identifiersChangeSubs.delete(uuid);

        const fieldInfo = this.getFieldInfo(control);
        fieldInfo.identifierValueChangesSub?.unsubscribe();
        this.getScope(scopeUuid).delete(uuid);
        this.notifyScopeIdentifiersStatusChange(scopeUuid);

        if (type === FieldType.Repeat) {
            this.scopes.delete(uuid);
        }

        const fields = (control.get(FieldControlKeys.Fields) as UfControlArray)?.controls as UfControlGroup[] | undefined;
        if (fields) {
            for (const field of fields) {
                this.onRemovedField(field);
            }
        }
    }

    onMovedField(nextScopeUuid: string, control?: UfControlGroup) {

        if (!control) {
            return;
        }

        const scopeUuid = control.get(FieldControlKeys.ScopeUuid)?.value as string;
        const uuid = control.get(FieldControlKeys.Uuid)?.value as string;
        const oldScope = this.getScope(scopeUuid);
        const nextScope = this.getScope(nextScopeUuid);
        const info = this.getFieldInfo(control);

        // Remove from oldScope
        oldScope.delete(uuid);
        this.notifyScopeIdentifiersStatusChange(scopeUuid);

        // Add to nextScope
        (control.get(FieldControlKeys.ScopeUuid) as UfControl).setValue(nextScopeUuid);
        nextScope.set(uuid, info);
        this.notifyScopeIdentifiersStatusChange(nextScopeUuid);
    }

    getScope(scopeUuid: string): Map<string, FormFieldScopedInfo> {
        let scope = this.scopes.get(scopeUuid);
        if (scope == null) {
            scope = new Map<string, FormFieldScopedInfo>();
            this.scopes.set(scopeUuid, scope);
        }
        return scope;
    }

    reset() {
        this.scopes = new Map();
    }

    notifyScopeIdentifiersStatusChange(scopeUuid: string) {
        this.scopeIdentifiersChange.emit(scopeUuid);
    }

    /** Update the RepeatSortableProperties control value for the field associated to the input uuid
     * Operation executed only if the field is found and it is a Repeat
     */
    updateRepeatSortableProperties(uuid: string) {
        const control = this.status.fieldByUuid.get(uuid);

        if (control?.get(FieldControlKeys.Type)?.value !== FieldType.Repeat) {
            return;
        }

        const reportableProperties = FormEditorFunctions.calculateRepeatFieldSortableDescriptors(control.getRawValue() as FormEditorField);
        control.get(FieldControlKeys.RepeatSortableProperties)?.setValue(reportableProperties);
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();

        for (const [key, scope] of this.scopes) {
            for (const [fieldKey, fieldInfo] of scope) {
                fieldInfo.identifierValueChangesSub?.unsubscribe();
            }
        }
        for (const [key, sub] of this.identifiersChangeSubs) {
            sub.unsubscribe();
        }
    }

    /**
     * Validate every Field identifier control within the scope
     *
     * @param scopeUuid of the Fields to validate
     */
    private validateScopeFields(scopeUuid: string) {
        const scope = this.getScope(scopeUuid);
        const others = Array.from(scope.values());
        for (const info of others) {
            // set onlySelf: false to notify parent of change
            info.control.get(FieldControlKeys.Identifier)?.updateValueAndValidity({ onlySelf: false, emitEvent: false });
        }
    }

    private getFieldInfo(control: UfControlGroup): FormFieldScopedInfo {
        const scopeUuid = control.get(FieldControlKeys.ScopeUuid)?.value as string;
        const fieldUuid = control.get(FieldControlKeys.Uuid)?.value as string;
        const scope = this.getScope(scopeUuid);
        let fieldInfo = scope.get(fieldUuid);
        if (fieldInfo == null) {
            fieldInfo = { control, uuid: fieldUuid };
            scope.set(fieldUuid, fieldInfo);
        }
        return fieldInfo;
    }
}
