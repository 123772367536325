import { Inject, Injectable } from '@angular/core';
import { ProjectContentOptions, ProjectContentOptionsInterace } from '@unifii/sdk';

import { StructureFunctions } from 'pages/structure';

import { UcStructure } from './content-models';
import { UcClient } from './uc-client';


@Injectable()
export class StructureService {

    constructor(
        private client: UcClient,
        @Inject(ProjectContentOptions) private projectOptions: ProjectContentOptionsInterace
    ) { }

    async get(): Promise<UcStructure> {
        const structure = await this.client.get(this.url());
        StructureFunctions.cleanUp(structure);
        return structure;
    }

    save(data: UcStructure, approve?: boolean): Promise<UcStructure> {
        StructureFunctions.cleanUp(data);
        const params = approve ? { params: { approve: true } } : undefined;
        return this.client.put(this.url(), data, params);
    }

    approve(): Promise<void> {
        return this.client.post(this.url('approved'), {});
    }

    unapprove(): Promise<void> {
        return this.client.post(this.url('unapproved'), {});
    }

    private url(...extra: string[]): string {
        if (!this.projectOptions.projectId) {
            throw new Error('Project id set');
        }
        const urlParts = ['projects', this.projectOptions.projectId, 'structure'].concat(extra);
        return this.client.buildUrl(urlParts);
    }
}
