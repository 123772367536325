<div class="header-pane uf-app-bar transparent">
    <a [routerLink]="['/']" class="uf-button tertiary right">Cancel</a>
    <button (click)="save()" type="button" class="uf-button primary">Save</button>
</div>
<uf-panel class="content-pane container">
    <div *ngIf="settings" class="uf-container-lg">
        <div class="uf-grid row-gap-lg pad-top">

            <div class="uf-box col-12">
                <div class="uf-app-bar flat accent">
                    <div class="title">
                        Details
                    </div>
                </div>
                <div class="uf-grid pad">
                    <uf-text class="col-12" label="Name" [(value)]="settings.name"
                        [control]="form.controls.name | asUfControl">
                    </uf-text>

                    <uf-url class="col-12" label="Base URL" [(value)]="settings.baseUrl"
                        [control]="form.controls.baseUrl | asUfControl">
                    </uf-url>

                    <uf-url class="col-12" label="Privacy Policy URL" [(value)]="settings.privacyPolicyUrl"
                        [control]="form.controls.privacyPolicyUrl | asUfControl">
                    </uf-url>
                </div>
            </div>

            <div class="uf-box col-12">
                <div class="uf-app-bar flat accent">
                    <div class="title">
                        Contact Details
                    </div>
                </div>
                <div class="grid">
                    <uf-text class="col-1of1" label="Contact Name" [(value)]="settings.contactName"
                        [control]="form.controls.contactName | asUfControl"></uf-text>
                    <uf-text class="col-1of1" label="Contact Email" [(value)]="settings.contactEmail"
                        [control]="form.controls.contactEmail | asUfControl"></uf-text>
                    <uf-text class="col-1of1" label="Contact Phone" [(value)]="settings.contactPhone"
                        [control]="form.controls.contactPhone | asUfControl"></uf-text>
                </div>
            </div>

            <div class="uf-box col-12">
                <div class="uf-app-bar flat accent">
                    <div class="title">
                        Logo
                    </div>

                    <button type="button" class="uf-action tertiary" *ngIf="settings.logo" (click)="deleteLogo()"
                        title="Remove">
                        <uf-icon name="backspace"></uf-icon>
                    </button>
                    <button type="button" class="uf-action tertiary" *ngIf="!settings.logo" (click)="findLogo()"
                        title="Edit">
                        <uf-icon name="edit"></uf-icon>
                    </button>
                </div>
                <div class="uf-grid condensed">
                    <uf-message *ngIf="!settings.logo" icon="infoSolid" content="No logo added" class="info col-12 gap">
                    </uf-message>
                    <div class="col-12 checkboard-bg" *ngIf="settings.logo">
                        <a [routerLink]="['/media', settings.logo.id]" target="_blank">
                            <img class="thumb" [src]="settings.logo.url + '?h=120'" alt="item.title"
                                style="max-height: 120px;" />
                        </a>
                    </div>
                </div>
            </div>

            <div class="uf-box col-12">
                <div class="uf-app-bar flat accent">
                    <div class="title">
                        User Configuration
                    </div>
                </div>
                <div class="grid">
                    <uf-blockquote class="info col-1of1" icon="infoSolid"
                        content="While selected, any user created or updated will require an email address">
                    </uf-blockquote>
                    <uf-checkbox class="col-1of1" label="User Email Required" [(value)]="settings.isUserEmailRequired">
                    </uf-checkbox>
                </div>
            </div>

            <div class="uf-box col-12">
                <div class="uf-app-bar flat accent">
                    <div class="title">
                        Language
                    </div>
                </div>
                <div class="grid">
                    <uf-autocomplete class="col-1of1" placeholder="Select a language..."
                        [control]="form.controls.language | asUfControl" [(value)]="settings.language"
                        (searchChange)="searchLang($event)" [options]="langResults" nameProperty="name">
                    </uf-autocomplete>
                </div>
            </div>

            <div class="uf-box col-12">
                <div class="uf-app-bar flat accent">
                    <div class="title">
                        Session Expiry
                    </div>
                </div>
                <div class="uf-app-bar flat">
                    <div class="title">
                        Inactive Session Expiry
                    </div>
                </div>
                <div class="uf-grid pad">
                    <div class="col-12 uf-grid condensed column-gap-lg">
                        <uf-number class="col-md-3 col-sm-12" (valueChange)="tokenLengthChange($event, 'days')"
                            [value]="tokenDuration.days" label="Days">
                        </uf-number>
                        <uf-number class="col-md-3 col-sm-12" (valueChange)="tokenLengthChange($event, 'hours')"
                            [value]="tokenDuration.hours" label="Hours">
                        </uf-number>
                        <uf-number class="col-md-3 col-sm-12" (valueChange)="tokenLengthChange($event, 'minutes')"
                            [value]="tokenDuration.minutes" label="Minutes">
                        </uf-number>
                        <uf-number class="col-md-3 col-sm-12" (valueChange)="tokenLengthChange($event, 'seconds')"
                            [value]="tokenDuration.seconds" label="Seconds">
                        </uf-number>
                        <uf-error class="col-12" [control]="form.controls.refreshTokenExpiry | asUfControl">
                        </uf-error>
                    </div>
                </div>
                <div class="uf-app-bar flat">
                    <div class="title">
                        Total Session Timeout
                    </div>
                </div>
                <div class="uf-grid pad">
                    <div class="col-12 uf-grid condensed column-gap-lg" *ngIf="form.controls.maxSessionLength">
                        <uf-number class="col-md-3 col-sm-12" (valueChange)="sessionLengthChange($event, 'days')"
                            [value]="sessionDuration.days" label="Days">
                        </uf-number>
                        <uf-number class="col-md-3 col-sm-12" (valueChange)="sessionLengthChange($event, 'hours')"
                            [value]="sessionDuration.hours" label="Hours">
                        </uf-number>
                        <uf-number class="col-md-3 col-sm-12" (valueChange)="sessionLengthChange($event, 'minutes')"
                            [value]="sessionDuration.minutes" label="Minutes">
                        </uf-number>
                        <uf-number class="col-md-3 col-sm-12" (valueChange)="sessionLengthChange($event, 'seconds')"
                            [value]="sessionDuration.seconds" label="Seconds">
                        </uf-number>
                        <uf-error class="col-12" [control]="form.controls.maxSessionLength | asUfControl">
                        </uf-error>
                    </div>
                    <uf-checkbox class="col-12" label="Total Session Timeout Disabled"
                        [(value)]="disabledSessionTimeout">
                    </uf-checkbox>
                </div>
            </div>

            <div class="uf-box col-12">
                <div class="uf-app-bar flat accent">
                    <div class="title">
                        Allowed File Types
                    </div>
                </div>
                <div class="uf-app-bar flat">
                    <div class="title">
                        Media Asset File Types
                    </div>
                </div>
                <div class="uf-grid pad">
                    <uf-chips class="col-12" *ngIf="!bypassAssetFileTypesWhitelist" placeholder="Add file types"
                        (searchChange)="searchAssetFileTypes($event)" [options]="fileTypeOptions" nameProperty="id"
                        [(value)]="settings.allowedAssetFileTypes" [allowDuplicates]="false">
                    </uf-chips>
                    <uf-checkbox class="col-12" label="Allow any asset file types"
                        [(value)]="bypassAssetFileTypesWhitelist">
                    </uf-checkbox>
                </div>
                <div class="uf-app-bar flat">
                    <div class="title">
                        Form Attachment File Types
                    </div>
                </div>
                <div class="uf-grid pad">
                    <uf-chips class="col-12" *ngIf="!bypassAttachmentFileTypesWhitelist" placeholder="Add file types"
                        (searchChange)="searchAttachmentFileTypes($event)" [options]="fileTypeOptions" nameProperty="id"
                        [(value)]="settings.allowedAttachmentFileTypes" [allowCustom]="false" [allowDuplicates]="false">
                    </uf-chips>
                    <uf-checkbox class="col-12" label="Allow any attachment file types"
                        [(value)]="bypassAttachmentFileTypesWhitelist">
                    </uf-checkbox>
                </div>
            </div>

            <div class="uf-box col-12">
                <div class="uf-app-bar flat accent">
                    <div class="title">Google Maps Configuration</div>
                </div>
                <div class="uf-grid pad">
                    <uc-secret-input class="col-12" label="API Key" [(value)]="settings.googleMapsApiKey">
                    </uc-secret-input>
                </div>
            </div>

            <div class="uf-box col-12">
                <div class="uf-app-bar flat accent">
                    <div class="title">User Login</div>
                </div>

                <div class="uf-grid pad-sides pad-bottom pad-top">
                    <uf-blockquote class="info col-12" icon="infoSolid"
                        content="When enabled, users can opt in to be remembered in the App">
                    </uf-blockquote>
                    <uf-checkbox class="col-12" label="Remember Users" [(value)]="settings.rememberMe">
                    </uf-checkbox>
                    <div class="col-12 uf-grid condensed column-gap-lg" *ngIf="settings.rememberMe">
                        <uf-number class="col-12" [(value)]="settings.rememberMeExpiryDays"
                            [control]="form.controls.rememberMeExpiryDays | asUfControl"
                            label="Forget users after (days)">
                        </uf-number>
                        <uf-error class="col-12" [control]="form.controls.rememberMeExpiryDays | asUfControl">
                        </uf-error>
                    </div>
                    <uf-text class="col-12" label="Username Login Label" [(value)]="settings.usernameLoginLabel">
                    </uf-text>
                </div>
            </div>

            <uf-blockquote *ngIf="error" class="error" icon="errorSolid" [content]="error.message">
            </uf-blockquote>

        </div>
    </div>
</uf-panel>