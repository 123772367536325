<uf-panel class="container">
    <div class="header-pane uf-app-bar transparent">
        <uf-breadcrumbs [breadcrumbs]="[{ name: 'Template Table' }]" class="large">
        </uf-breadcrumbs>
        <uf-search class="right" [search]="query"></uf-search>
        <uf-select [value]="status" [options]="publishStates" valueProperty="value" placeholder="Active"></uf-select>
        <uf-table-sort [table]="pageTable"></uf-table-sort>
        <uf-table-column-toggles [table]="pageTable"></uf-table-column-toggles>
        <button class="uf-action primary" title="Add">
            <uf-icon name="add"></uf-icon>
        </button>
    </div>
    <uf-panel class="content-pane">
        <uf-table #pageTable [config]="config" [datasource]="datasource" class="gap-lg-bottom accent">
        </uf-table>
    </uf-panel>
</uf-panel>