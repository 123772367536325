<div *ngIf="control" [formGroup]="control" class="uf-box flat pad-sm-sides uf-grid row-gap-sm pad-sm-top pad-sm-bottom">

    <ng-template [ngIf]="fieldsControl.showError && fieldsControl.errors?.message">
        <uf-message class="error col-12" [content]="fieldsControl.errors?.message" icon="error">
        </uf-message>
    </ng-template>

    <uf-expander class="col-12 uf-box flat">
        <div expanderHeader class="uf-app-bar flat">
            <div class="title primary">Form</div>
            <ng-template [ngIf]="isFormInvalid" [ngIfElse]="formValid">
                <uf-icon name="error" class="error"></uf-icon>
            </ng-template>
            <ng-template #formValid>
                <a *ngIf="fieldsControl.length" (click)="copyFields()" title="Copy fields" class="uf-action tertiary">
                    <uf-icon name="copy"></uf-icon>
                </a>
                <a (click)="pasteFields()" title="Paste fields" class="uf-action tertiary">
                    <uf-icon name="paste"></uf-icon>
                </a>
            </ng-template>
        </div>
        <div expanderBody class="uf-grid pad-sides pad-sm-top pad-bottom">
            <uf-text [formControlName]="definitionKeys.Label" label="Label" class="col-12"></uf-text>
            <uf-text [formControlName]="definitionKeys.Identifier" label="Identifier" class="col-12"
                [maxLength]="identifierMaxLength">
            </uf-text>
            <uf-message *ngIf="showIdentifierWarning" class="x-small warning col-12" icon="warningSolid">
                <p>Identifier is too long</p>
            </uf-message>
            <uf-text [formControlName]="definitionKeys.SequenceNumberFormat" label="Form Number Format"
                placeholder="ABC-######" class="col-12">
            </uf-text>
            <ng-container [formGroupName]="definitionKeys.Settings">
                <uf-text [formControlName]="settingsKeys.RequiredSuffix" label="Mark required fields with:"
                    placeholder="*" class="col-12">
                </uf-text>
                <uf-text [formControlName]="settingsKeys.OptionalSuffix" label="Mark optional fields with:"
                    placeholder="(Optional)" class="col-12">
                </uf-text>
            </ng-container>
            <uf-chips [formControlName]="definitionKeys.Tags" label="Tags" [allowCustom]="true"
                (searchChange)="searchTags($event)" [options]="tagsResults" class="col-12">
            </uf-chips>
            <uf-chips [formControlName]="definitionKeys.ReportableMetaFields"
                (searchChange)="searchReportableMetaFields($event)" [options]="reportableMetaFieldsResults"
                [allowDuplicates]="false" label="Reportable Metadata" class="col-12">
            </uf-chips>
        </div>
    </uf-expander>

    <uf-expander class="col-12 uf-box flat">
        <div expanderHeader class="uf-app-bar flat">
            <div class="title primary">Form Data Repository</div>
            <uf-icon *ngIf="isFormDataRepositoryInvalid" name="error" class="error"></uf-icon>
            <a *ngIf="control.get(definitionKeys.Bucket)?.enabled" (click)="editFormDataRepository()" title="Edit"
                class="uf-action tertiary">
                <uf-icon name="edit"></uf-icon>
            </a>
        </div>
        <div expanderBody class="uf-grid pad-sides pad-sm-top pad-bottom">
            <uf-description-list [items]="formDataRepositoryInfo" class="small-label col-12"></uf-description-list>
            <uf-message *ngIf="showBucketWarning" class="x-small warning col-12" icon="warningSolid">
                <p>Form Data Repository is too long</p>
            </uf-message>
        </div>
    </uf-expander>

</div>