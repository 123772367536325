<div class="header">
    <button type="button" class="uf-action tertiary" (click)="close()" title="Close">
        <uf-icon name="close"></uf-icon>
    </button>
    <h3>Clone</h3>
</div>

<uf-panel class="container">
    <div class="grid">
        <div class="col-1of1">
            <h4>Target</h4>
        </div>
        <uf-select class="col-1of2" [(value)]="type" (valueChange)="changeType(); reset()" [options]="types"
            placeholder="Select a type" [control]="form.controls.type | asUfControl">
        </uf-select>
        <uf-autocomplete *ngIf="type" class="col-1of2" [(value)]="target" (valueChange)="reset()"
            (searchChange)="filterTarget($event)" [options]="targets" nameProperty="name"
            [placeholder]="'Select a ' + type" [control]="form.controls.target | asUfControl">
        </uf-autocomplete>
        <div class="col-1of1">
            <h4>Permissions</h4>
            <ul class="permissions">
                <li class="row" *ngFor="let item of items">
                    <div class="status">
                        <uf-icon *ngIf="item.cloned" name="tick"></uf-icon>
                        <uf-icon *ngIf="item.error" name="warning"></uf-icon>
                        <uf-icon *ngIf="!item.error && !item.cloned"></uf-icon>
                    </div>
                    <div class="item">
                        <uf-text [value]="item.pathLabel" disabled="true" class="grow"></uf-text>
                        <span *ngIf="item.error" class="error-text">
                            {{item.error.message}}
                        </span>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</uf-panel>

<div class="row space-children pad">
    <button type="button" class="uf-button tertiary right" [disabled]="busy" (click)="close()">Cancel</button>
    <button type="button" class="uf-button primary" [disabled]="busy" (click)="clone()" *ngIf="!cloned">Clone</button>
    <button type="button" class="uf-button primary" [disabled]="busy" (click)="close()" *ngIf="cloned">Close</button>
</div>