import { Config, ConsoleOptions } from 'app-config';
import add from 'date-fns/add';
import format from 'date-fns/format';

import { Inject, Injectable } from '@angular/core';
import { FnsDatetime } from '@unifii/library/common';

import { TenantSettings, UcClient } from 'client';

import { ContextService } from './context.service';


/**
 * Tenant settings gets reused at multiple times during user access, it is important that
 * tenant settings are refreshed on app start and only after cache has expired
 */
@Injectable()
export class TenantSettingsService {

    private cacheExpiry: string; // set on successfully update
    private cacheLifetime: { minutes: number } = { minutes: 10 };  // Amount of minutes before settings should be updated

    constructor(
        private client: UcClient,
        @Inject(Config) private config: ConsoleOptions,
        private context: ContextService,
    ) {
        if (this.config.env === 'dev' || 'localhost') {
            this.cacheLifetime = { minutes: 2 };
        }
    }

    async sync(): Promise<TenantSettings> {
        if (!this.requiresSync) {
            return this.context.tenantSettings as TenantSettings;
        }

        this.context.tenantSettings = await this.client.getSettings();
        this.cacheExpiry = this.getDatetime(this.cacheLifetime);
        // Update config from environment with feature flags loaded from the backend.
        Object.assign(this.config.flags, this.context.tenantSettings.features);

        return this.context.tenantSettings;
    }

    private get requiresSync(): boolean {
        if (this.context.tenantSettings == null || this.cacheExpiry == null) {
            return true;
        }
        return this.cacheExpiry < this.getDatetime();
    }

    private getDatetime(offset?: { minutes: number }): string {

        if (offset != null) {
            return format(add(new Date(), offset), FnsDatetime);
        }

        return format(new Date(), FnsDatetime);
    }

}
