import { Subscription } from 'rxjs';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastService, UfControl, UfControlArray, UfControlGroup } from '@unifii/library/common';
import { Dictionary } from '@unifii/sdk';

import { EmailTemplate } from 'client';
import { UcSmtp } from 'client/smtp';

import { EditData } from 'components/common/edit-data';

import { TenantSettingsComponent } from './settings.component';
import { EmailSettingsControlKey, EmailTemplateControlKey, SystemEmailSettingsController } from './system-email-settings-controller';


@Component({
    templateUrl: './system-email-settings.html'
})
export class SystemEmailSettingsComponent implements OnInit, OnDestroy, EditData {
    readonly controlKey = EmailSettingsControlKey;
    readonly templateControlKey = EmailTemplateControlKey;

    readonly defaultTemplates: Dictionary<EmailTemplate> = {
        invite: {
            title: '',
            subject: 'Invitation to join {{tenantName}}',
            introMessage: '### You have been invited to join {{tenantName}}',
            bodyMessage: 'If you are having difficulties please contact your administrator:\n  \n**Name:** {{contactName}}  \n**Email:** {{contactEmail}}  \n**Phone:** {{contactPhone}}  \n\nBy completing this registration you will be agreeing to our [privacy policy]({{privacyPolicy}})',
            buttonLabel: 'Complete registration online',
            baseUrl: null,
            conditions: []
        },
        resetPassword: {
            title: '',
            subject: 'Password reset for {{tenantName}}',
            introMessage: '### You have requested to reset your password for {{tenantName}}',
            bodyMessage: 'If you are having difficulties please contact your administrator:\n  \n**Name:** {{contactName}}  \n**Email:** {{contactEmail}}  \n**Phone:** {{contactPhone}}  \n\nBy completing this registration you will be agreeing to our [privacy policy]({{privacyPolicy}})',
            buttonLabel: 'Reset your password',
            baseUrl: null,
            conditions: []
        }
    };

    error: any;
    form: UfControlGroup;
    emailTokenDuration: Duration;
    valueChangesSubscription: Subscription;

    constructor(
        private ucSmtp: UcSmtp,
        private toast: ToastService,
        private formController: SystemEmailSettingsController,
        private parent: TenantSettingsComponent
    ) { }

    set edited(v: boolean) {
        this.parent.edited = v;
    }

    get edited() {
        return this.parent.edited;
    }

    get replyToControl(): UfControl {
        return this.form.get(EmailSettingsControlKey.ReplyTo) as UfControl;
    }

    get inviteEmailExpiryControl(): UfControl {
        return this.form.get(EmailSettingsControlKey.InviteEmailExpiry) as UfControl;
    }

    get inviteEmailDefaultTemplateControl(): UfControlGroup {
        return this.form.get(EmailSettingsControlKey.InviteEmailDefaultTemplate) as UfControlGroup;
    }

    get resetPasswordEmailDefaultTemplateControl(): UfControlGroup {
        return this.form.get(EmailSettingsControlKey.ResetPasswordEmailDefaultTemplate) as UfControlGroup;
    }

    get inviteEmailTemplatesControl(): UfControlArray {
        return this.form.get(EmailSettingsControlKey.InviteEmailTemplates) as UfControlArray;
    }

    get resetPasswordEmailTemplatesControl(): UfControlArray {
        return this.form.get(EmailSettingsControlKey.ResetPasswordEmailTemplates) as UfControlArray;
    }

    get emailColourControl(): UfControl {
        return this.form.get(EmailSettingsControlKey.EmailColour) as UfControl;
    }

    async ngOnInit() {
        this.error = null;
        this.edited = false;

        try {
            const data = await this.ucSmtp.getEmail();
            this.form = await this.formController.buildRoot(data);

            this.emailTokenDuration = this.secondsToDuration(data.inviteEmailExpiry);

        } catch (e) {
            this.error = e;
            this.toast.error('Loading error');
            console.error(e);
        }

        this.valueChangesSubscription = this.form.valueChanges.subscribe(() => this.edited = true);
    }

    ngOnDestroy(): void {
        this.valueChangesSubscription.unsubscribe();
        this.parent.edited = false;
    }

    async save() {
        this.error = null;
        this.form.setSubmitted();

        if (!this.form.valid) {
            this.toast.error('There are errors on this page');
            return;
        }

        const data = this.formController.toDataModel(this.form.getRawValue());

        try {
            await this.ucSmtp.saveEmail(data);
            this.toast.success('Saved successfully');
            this.edited = false;
        } catch (e) {
            this.error = e;
            this.toast.error('Error saving');
            console.error(e);
        }
    }

    inviteEmailExpirationChange(value: number, field: keyof Duration) {
        this.emailTokenDuration[field] = value;
        this.form.controls.inviteEmailExpiry.setValue(this.durationToSeconds(this.emailTokenDuration));
        this.form.setSubmitted();
    }

    private durationToSeconds(duration: Duration): number {
        return (duration.days as number * 86400) + (duration.hours as number * 3600) + (duration.minutes as number * 60) + (duration.seconds as number);
    }

    private secondsToDuration(seconds: number): Duration {
        const duration: Duration = {};
        duration.days = Math.floor(seconds / 86400);

        const daysRemainder = seconds % 86400;
        duration.hours = Math.floor(daysRemainder / 3600);

        const hoursRemainder = daysRemainder % 3600;

        duration.minutes = Math.floor(hoursRemainder / 60);
        duration.seconds = hoursRemainder % 60;

        return duration;
    }
}