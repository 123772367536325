<div class="grid">
    <uf-text class="col-1of1" label="Title" [(value)]="page._title" [control]="control.controls.title | asUfControl">
    </uf-text>
    <uf-text class="col-1of1" label="Record Name" [(value)]="page.recordName"
        [control]="control.controls.recordName | asUfControl">
    </uf-text>
    <uf-text class="col-1of1" label="Identifier" [(value)]="page.identifier"
        [control]="control.controls.identifier | asUfControl" [maxLength]="maxLength">
    </uf-text>
    <uf-chips class="col-1of1" label="Tags" [(value)]="page.tags"
        [control]="control.controls.tags | asUfControl" [allowCustom]="true" (searchChange)="filterTags($event)"
        [options]="filteredTags" (valueChange)="builderService.refreshTags()"></uf-chips>
</div>