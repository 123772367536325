import { ContentSelectComponent } from './content-select.component';
import { ImageCropComponent } from './image-crop.component';
import { ImagePreviewComponent } from './image-preview.component';
import { LinkSearchComponent } from './link-search.component';
import { MediaSearchComponent } from './media-search.component';
import { ModalFormComponent } from './modal-form.component';


export const COMPONENTS_CONTENT_MODALS = [
    ContentSelectComponent,
    ImageCropComponent,
    ImagePreviewComponent,
    LinkSearchComponent,
    MediaSearchComponent,
    ModalFormComponent,
];

export * from './content-select.component';
export * from './image-crop.component';
export * from './image-preview.component';
export * from './link-search.component';
export * from './media-search.component';
export * from './modal-form.component';
export * from './modal-search';
