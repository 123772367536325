import { Component, HostBinding } from '@angular/core';
import { ActivatedRoute } from '@angular/router';


@Component({
    templateUrl: './page-not-found.html',
    styleUrls: ['./page-not-found.less']
})
export class PageNotFoundComponent {

    @HostBinding('class.stretch-component') class = true;

    title: string;
    message: string;

    constructor(route: ActivatedRoute) {

        switch (route.snapshot.params.type) {
            case 'offline':
                this.title = '';
                this.message = 'You are not connected to the internet.';
                break;
            case 'unauthorized':
                this.title = '403';
                this.message = 'You are not authorized to make this request';
                break;
            case 'server':
                this.title = '500';
                this.message = 'Our server was unable to process your request';
                break;
            default:
                this.title = '404';
                this.message = 'The page you requested could not be found';
        }
    }

}
