import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalService, ToastService } from '@unifii/library/common';
import { HierarchyUnit, HierarchyUnitExtended, HierarchyUnitInfo, HierarchyUnitState, HierarchyUnitWithChildCount } from '@unifii/sdk';

import { UcHierarchy } from 'client';

import { DialogsService } from 'services/dialogs.service';

import { HierarchyFinderColumnInputComponent } from './hierarchy-finder-column-input.component';
import { FinderContextMenu, FinderUnitChangeData } from './hierarchy-finder-column.component';
import { HierarchyFinderUnitInputComponent } from './hierarchy-finder-unit-input.component';


interface FinderColumn {
    units: HierarchyUnitWithChildCount[];
    label?: string;
    selected?: HierarchyUnitWithChildCount;
    parentId?: string;
}

interface ErrorDisplay {
    e: Error;
    message: string;
}

@Component({
    selector: 'uc-hierarchy-finder',
    templateUrl: './hierarchy-finder.html',
    styleUrls: ['./hierarchy-finder.less']
})
export class HierarchyFinderComponent implements OnInit {

    @ViewChild('wrapper') wrapper: ElementRef;

    @Input() disabled?: boolean;

    @Output() itemChange = new EventEmitter<HierarchyUnit>();

    errorMessage: string;
    columns: FinderColumn[];

    private root: HierarchyUnitExtended | undefined;
    private readonly rootId = 'root';
    private _listWidth = 0;

    constructor(
        private modal: ModalService,
        private toast: ToastService,
        private dialogService: DialogsService,
        private ucHierarchy: UcHierarchy,
    ) { }

    async ngOnInit() {
        this.root = await this.ucHierarchy.getUnit(this.rootId);

        if (!this.root) {
            this.errorMessage = 'Error loading root hierarchy';
            return;
        }

        this.updateColumns(this.root);
    }

    get listWidth() {
        if (this._listWidth === 0) {
            const list = this.wrapper.nativeElement.querySelector('.uf-list-button') as HTMLElement;
            if (list && list?.clientWidth) {
                this._listWidth = list.clientWidth;
            }
        }
        return this._listWidth;
    }

    showActivateContextMenu(unit: HierarchyUnitWithChildCount): boolean {
        if (unit.state !== HierarchyUnitState.Inactive || !unit.parentId) {
            return false;
        }

        for (const column of this.columns) {
            if (column.selected?.parentId === unit.id) {
                return true;
            } else if (column.selected?.state === HierarchyUnitState.Inactive && column?.selected?.id !== unit.id) {
                return false;
            }
        }

        return true;
    }

    showDeleteContextMenu(unit: HierarchyUnitWithChildCount): boolean {
        return !unit.childCount;
    }

    protected retry() {
        this.errorMessage = '';
        if (this.root) {
            this.updateColumns(this.root);
        }
    }

    protected async select(unit: HierarchyUnitWithChildCount, level: number) {
        const columns = [...this.columns];
        if (++level < columns.length) {
            while (level < columns.length) {
                columns.pop();
            }
        }

        const lastColumn = columns[columns.length - 1];
        const column = await this.getColumn(unit);

        lastColumn.selected = unit;

        columns.push({
            units: column.units,
            label: column.label,
            parentId: unit.id,
        });

        this.columns = columns;

        this.scrollToElement(this.columns.length);
    }

    protected async editColumnLabel(level: number) {
        try {
            const column = this.columns[level];

            if (!column || !column.parentId) {
                return;
            }

            const unitData = await this.modal.openFit(HierarchyFinderColumnInputComponent, { label: column.label });
            if (!unitData) {
                return;
            }

            const unit = await this.ucHierarchy.getUnit(column.parentId);
            if (!unit) {
                return;
            }

            unit.childrenLabel = unitData;
            column.label = unit.childrenLabel;

            await this.ucHierarchy.update(unit);

            const parentColumn = this.columns[--level];
            if (parentColumn) {
                const unitInParent = parentColumn.units.find(pc => pc.id === unit.id);
                if (unitInParent) {
                    unitInParent.childrenLabel = unitData;
                }
            }
            this.toast.success('Label Saved');
        } catch (e) {
            this.displayError({ e, message: 'Error changing column label' });
        }

    }

    protected finderUnitChange({ action, unit }: FinderUnitChangeData, level: number) {
        switch (action) {
            case FinderContextMenu.Delete:
                this.delete(unit.id, level);
                break;
            case FinderContextMenu.Update:
                this.edit(unit);
                break;
            case FinderContextMenu.Activate:
                this.activate(unit);
                break;
            case FinderContextMenu.Deactivate:
                this.deactivate(unit);
        }
    }

    protected async add(level: number) {
        try {
            const column = this.columns[level];
            const unitData = await this.modal.openFit(HierarchyFinderUnitInputComponent, {} as HierarchyUnitInfo);

            if (!unitData) {
                return;
            }

            const { path, ...unit } = await this.ucHierarchy.add({
                label: unitData.label,
                parentId: column.parentId,
                childrenLabel: '',
                id: unitData.id,
                state: HierarchyUnitState.Active
            });

            this.updateColumns(unit);
            this.toast.success('Unit Saved');
        } catch (e) {
            this.displayError({ e, message: 'Error adding unit' });
        }

    }

    private async activate(unit: HierarchyUnit) {
        try {

            const { path, ...hierarchyUnit } = await this.ucHierarchy.update({
                ...unit,
                state: HierarchyUnitState.Active
            });

            this.updateColumns(hierarchyUnit);
            this.toast.success('Unit Activated');
        } catch (e) {
            this.displayError({ e, message: 'Error Activating Unit' });
        }
    }

    private async deactivate(unit: HierarchyUnitWithChildCount) {
        try {
            const result = await this.modal.openConfirm({
                title: 'Deactivate Unit',
                message: `${unit.label} and any Units below it will be deactivated`,
                cancelLabel: `Don't Deactivate`,
                confirmLabel: 'Deactivate'
            });

            if (!result) {
                return;
            }

            const hierarchyUnit = await this.ucHierarchy.update({
                ...unit,
                state: HierarchyUnitState.Inactive
            }) as HierarchyUnitExtended; // TODO - remove cast once provider interface fixed

            this.updateColumns(hierarchyUnit);
            this.toast.success('Unit(s) Deactivated');
        } catch (e) {
            this.displayError({ e, message: 'Error Deactivating Unit(s)' });
        }
    }

    private async edit(unit: HierarchyUnitWithChildCount) {
        try {
            const data = await this.modal.openFit(HierarchyFinderUnitInputComponent, unit);
            if (!data) {
                return;
            }

            const { childCount, ...updated } = { ...unit, ...data };
            const hierarchyUnit = await this.ucHierarchy.update(updated);
            unit.label = hierarchyUnit.label;

            this.toast.success('Unit Saved');
        } catch (e) {
            this.displayError({ e, message: 'Error trying to save' });
        }
    }

    private async delete(id: string, level: number) {
        try {
            const result = await this.dialogService.confirmDelete();
            if (!result) {
                return;
            }

            await this.ucHierarchy.remove(id);

            this.removeFromFinder(id, level);
            this.toast.success('Unit Deleted');
        } catch (e) {
            this.displayError({ e, message: `Error deleting unit: ${id}` });
        }
    }

    private removeFromFinder(id: string, level: number) {
        const column = this.columns[level];

        if (level > 0) {
            this.columns = this.columns.slice(0, level);
        } else {
            column.units = column.units.filter(u => u.id !== id);
        }

        if (column.selected?.id === id) {
            column.selected = undefined;
        }

        const lastValue = [...this.columns].reverse().find(u => u.selected != null)?.selected;
        if (lastValue) {
            this.updateColumns(lastValue);
        }
    }

    private async updateColumns(unit: HierarchyUnitWithChildCount) {
        try {
            this.columns = (await this.buildColumns(unit, [])).reverse();
            this.scrollToElement(this.columns.length);
        } catch (e) {
            this.displayError({ e, message: `Error loading unit: ${unit}` });
        }
    }

    private async buildColumns(unit: HierarchyUnitWithChildCount | string | null, columns: FinderColumn[], selected?: HierarchyUnitWithChildCount): Promise<FinderColumn[]> {
        if (unit == null) {
            return columns;
        }

        if (typeof unit === 'string') {
            const extendedUnit = await this.ucHierarchy.getUnit(unit);
            if (extendedUnit == null) {
                return columns;
            }

            const { path, ...unitProperties } = extendedUnit;
            unit = unitProperties;
        }

        const column = await this.getColumn(unit, selected);
        columns.push(column);

        return this.buildColumns(unit.parentId ?? null, columns, unit);
    }

    private async getColumn(unit: HierarchyUnitWithChildCount, selected?: HierarchyUnitWithChildCount): Promise<FinderColumn> {
        const unitData = await this.ucHierarchy.getUnit(unit.id) ?? unit;
        const units = (await this.ucHierarchy.getChildren(unit.id as string) ?? []).sort(this.sortUnitsByLabel);

        return { label: unitData.childrenLabel, units, parentId: unit.id, selected };
    }

    private sortUnitsByLabel(unit1: HierarchyUnitWithChildCount, unit2: HierarchyUnitWithChildCount) {
        return unit1.label > unit2.label ? 1 : -1;
    }

    private displayError({ e, message }: ErrorDisplay) {
        this.toast.error(e.message ?? message);
        console.error(e);
    }

    private scrollToElement(level: number) {
        if (this.wrapper?.nativeElement.scrollWidth >= this.wrapper?.nativeElement.clientWidth) {
            window.setTimeout(() => {
                this.wrapper.nativeElement.scrollLeft = this.listWidth * ++level;
            }, 0);
        }
    }

}