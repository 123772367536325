<uf-expander class="uf-box flat">
    <div expanderHeader class="uf-app-bar flat">
        <div class="title primary">
            Restrict to Roles
        </div>
    </div>
    <div expanderBody [formGroup]="control" class="uc-group-content grid">
        <uf-chips [formControlName]="formKeys.Roles" class="col-1of1" placeholder="Search Roles"
            (searchChange)="findRoles($event)" [options]="rolesResult">
        </uf-chips>
    </div>
</uf-expander>