import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { RuntimeField, UfControl } from '@unifii/library/common';
import { FormField } from '@unifii/library/smart-forms';


@Component({
    selector: 'uc-secret-input-field',
    templateUrl: './secret-input-field.html',
    providers: [{
        provide: NG_VALUE_ACCESSOR, useExisting: SecretFieldComponent, multi: true
    }]
})
export class SecretFieldComponent implements FormField {
    @Input() content: string | null | undefined;
    @Output() contentChange: EventEmitter<any> = new EventEmitter();

    control: UfControl;
    field: RuntimeField;

}