<div *ngIf="ready" class="pad uf-grid">

    <uf-text *ngIf="control.get(labelControlKey)" label="Label"
        [control]="control.controls[labelControlKey] | asUfControl" placeholder="Override default label" class="col-12">
    </uf-text>

    <uf-select *ngIf="control.get(formatControlKey)" label="Format"
        [control]="control.controls[formatControlKey] | asUfControl" placeholder="Override default format"
        [options]="formatOptions" valueProperty="_id" nameProperty="_display" class="col-12">
    </uf-select>

    <uf-select *ngIf="control.get(inputTypeControlKey)" label="Input type"
        [control]="control.controls[inputTypeControlKey] | asUfControl" [placeholder]="dateTimePlaceholder"
        [options]="dataTimeOptions" valueProperty="_id" nameProperty="_display" class="col-12">
    </uf-select>

    <uf-chips *ngIf="control.get(rolesControlKey)" label="Restrict to"
        [control]="control.controls[rolesControlKey] | asUfControl" (searchChange)="findRoles($event)"
        [options]="roleResults" placeholder="Search roles" class="col-12">
    </uf-chips>
</div>