import { Subject } from 'rxjs';

import { Inject, inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager, TableInputManager, TableInputs, TableManagerFunctions } from '@unifii/components';
import {
    ActionMultiplicity, CellDisplayDescriptor, FieldDisplayPipe, FilterEntries, FilterEntry, FilterValue, HierarchyUnitProvider, TableConfig,
    TableConfigColumn, TableRowContext, ToastService
} from '@unifii/library/common';
import { CellTemplateType, Dictionary, FieldType, MessageColour } from '@unifii/sdk';

import { FormSubmittedCondition, UcWorkflow, WorkflowRule, WorkflowState } from 'client';
import { TABLE_SEARCH_MIN_LENGTH } from 'constant';

import { DialogsService } from 'services/dialogs.service';

import { WorkflowActivityLabel, WorkflowEventLabel, WorkflowStateLabel } from './constants';
import { WorkflowRulesTableDataSource } from './workflow-rules-table-data-source';


@Injectable()
export class WorkflowRulesTableManager implements TableContainerManager<WorkflowRule, FilterValue, FilterEntry> {

    tableConfig: TableConfig<WorkflowRule>;
    showSearch = true;
    searchMinLength = TABLE_SEARCH_MIN_LENGTH;
    addActionConfig = true;
    customColumns: CellDisplayDescriptor[] = [{
        name: 'state',
        variations: [{
            condition: `$self === '${WorkflowState.Active}'`,
            template: {
                type: CellTemplateType.Lozenge,
                colour: MessageColour.Success
            }
        }, {
            condition: `$self === '${WorkflowState.DryRun}'`,
            template: {
                type: CellTemplateType.Lozenge,
                colour: MessageColour.Info
            }
        }],
        defaultTemplate: {
            type: CellTemplateType.Lozenge,
        }
    }];
    defaultSort = 'label';

    reload = new Subject<void>();
    update = new Subject<TableInputs<FilterValue>>();
    updateItem = new Subject<WorkflowRule>();

    inputManager: TableInputManager<FilterValue, FilterEntry>;

    constructor(
        private ucWorkflow: UcWorkflow,
        private router: Router,
        private route: ActivatedRoute,
        private displayPipe: FieldDisplayPipe,
        private toastService: ToastService,
        private dialogs: DialogsService,
        @Inject(FilterEntries) entries: FilterEntry[]
    ) {
        this.inputManager = new TableInputManager(entries, inject(HierarchyUnitProvider));

        const id = `table_workflow_rule`;
        const columns: TableConfigColumn<WorkflowRule>[] = [{
            name: 'label',
            label: 'Title',
            sortable: true
        }, {
            name: 'event',
            label: 'Event',
            value: (item) => WorkflowEventLabel[item.event.type]
        }, {
            name: 'activites',
            label: 'Activities',
            value: (item) => [...(new Set((item.activities ?? []).map(a => WorkflowActivityLabel[a.type])))].join(',')
        }, {
            name: 'bucket',
            label: 'Form Data Repository',
            value: (item) => (item?.condition as FormSubmittedCondition)?.bucket ?? ''
        }, {
            name: 'lastModifiedAt',
            label: 'Last Modified At',
            sortable: true,
            value: (item) => this.displayPipe.transform(item.lastModifiedAt, FieldType.DateTime)
        }, {
            name: 'lastModifiedBy',
            label: 'Last Modified by',
            value: (item) => item.lastModifiedBy.username
        }, {
            name: 'state',
            label: 'State',
            value: ({ state }) => WorkflowStateLabel[state],
            sortable: true
        }];

        const tableConfig = TableManagerFunctions.createTableConfig(columns, id);
        tableConfig.selectable = 100;
        tableConfig.rowLink = (rule: WorkflowRule) => [rule.id];
        tableConfig.actions = [{
            label: 'Activate',
            predicate: row => row.$implicit.state !== WorkflowState.Active,
            action: rows => {
                const workflowRules = (rows as TableRowContext<WorkflowRule>[]).map(r => ({ ...r.$implicit }));
                this.updateWorkflowRules(workflowRules, 'state', WorkflowState.Active);
            }
        }, {
            label: 'Deactivate',
            predicate: row => row.$implicit.state !== WorkflowState.Inactive,
            action: rows => {
                const workflowRules = (rows as TableRowContext<WorkflowRule>[]).map(r => ({ ...r.$implicit }));
                this.updateWorkflowRules(workflowRules, 'state', WorkflowState.Inactive);
            }
        }, {
            label: 'Dry Run Mode',
            predicate: row => row.$implicit.state !== WorkflowState.DryRun,
            action: rows => {
                const workflowRules = (rows as TableRowContext<WorkflowRule>[]).map(r => ({ ...r.$implicit }));
                this.updateWorkflowRules(workflowRules, 'state', WorkflowState.DryRun);
            }
        }, {
            label: 'Duplicate',
            multiplicity: ActionMultiplicity.Single,
            action: (row: TableRowContext<WorkflowRule>) => this.duplicate(row.$implicit?.id as string)
        }, {
            label: 'Delete',
            multiplicity: ActionMultiplicity.Single,
            action: (row: TableRowContext<WorkflowRule>) => this.delete(row.$implicit as WorkflowRule)
        }];

        this.tableConfig = tableConfig;
    }

    createDataSource(inputs?: TableInputs<FilterValue>) {
        let params: Dictionary<any> | undefined;
        if (inputs != null) {
            params = this.inputManager.serializeInputs(inputs);
        }
        return new WorkflowRulesTableDataSource(this.ucWorkflow, params);
    }
    // todo: remove if not used
    async addItem() {
        this.router.navigate(['new'], { relativeTo: this.route });
    }

    private duplicate(id: string) {
        this.router.navigate([id, { duplicate: 'true' }], { relativeTo: this.route });
    }

    private async delete(rule: WorkflowRule) {
        try {

            if (!await this.dialogs.confirmDelete()) {
                return;
            }

            await this.ucWorkflow.deleteRule(rule.id);
            this.reload.next();
            this.toastService.success('Workflow rule deleted');
        } catch (e) {
            this.toastService.error(e?.message ?? 'Could not delete workflow rule');
        }
    }

    private async updateWorkflowRules(workflowRules: WorkflowRule[], key: keyof WorkflowRule, value: any): Promise<void> {
        try {
            for (const rule of workflowRules) {
                rule[key] = value;
                const updatedRule = await this.ucWorkflow.updateRule(rule);
                this.updateItem.next(updatedRule as WorkflowRule);
            }

            this.toastService.success('Workflow rule/s updated');
        } catch (e) {
            this.toastService.error('Failed to update workflow rule/s');
        }
    }

}
