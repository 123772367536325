import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';

import { AppDetailsComponent } from 'pages/apps/app-details.component';
import { CollectionBuilderComponent } from 'pages/content/collections/collection-builder.component';
import { CollectionItemBuilderComponent } from 'pages/content/collections/collection-item-builder.component';
import { PageBuilderComponent } from 'pages/content/pages/page-builder.component';
import { ViewBuilderComponent } from 'pages/content/views/view-builder.component';
import { BucketComponent } from 'pages/form-data/bucket.component';
import { FormEditorComponent } from 'pages/form-editor';
import { AuthProviderComponent } from 'pages/system-settings/identity';
import { LanguageCollectionComponent } from 'pages/translations/language-collection.component';
import { LanguageCompoundComponent } from 'pages/translations/language-compound.component';
import { LanguageViewComponent } from 'pages/translations/language-view.component';
import { UserDetailsComponent } from 'pages/users/user-details.component';


@Injectable()
export class ConsoleRouteReuseStrategy extends RouteReuseStrategy {

    shouldDetach(_: ActivatedRouteSnapshot): boolean {
        return false;
    }

    store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    }

    shouldAttach(_: ActivatedRouteSnapshot): boolean {
        return false;
    }

    retrieve(_: ActivatedRouteSnapshot): DetachedRouteHandle | null {
        return null;
    }

    shouldReuseRoute(active: ActivatedRouteSnapshot, next: ActivatedRouteSnapshot): boolean {

        switch (next.component) {
            case BucketComponent:
            case AppDetailsComponent:
            case PageBuilderComponent:
            case CollectionBuilderComponent:
            case ViewBuilderComponent:
            case FormEditorComponent:
            case LanguageCompoundComponent:
            case LanguageViewComponent:
            case CollectionItemBuilderComponent:
                return next.params.id === active.params.id;
            case LanguageCollectionComponent:
                return next.params.collectionId === active.params.collectionId;
            case AuthProviderComponent:
                return false;
            case UserDetailsComponent:
            default: return active.routeConfig === next.routeConfig;
        }
    }

}
