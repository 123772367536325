import { Component, Input } from '@angular/core';


@Component({
    selector: 'uc-not-found',
    templateUrl: './not-found.html',
    styleUrls: ['./not-found.less']
})
export class NotFoundComponent {
    @Input() title: string;
    @Input() message: string;
    @Input() imgUrl: string;
    @Input() icon: string;
}
