<div *ngIf="role" class="col grow">
    <div class="uf-app-bar transparent pad-sides">
        <uf-breadcrumbs [breadcrumbs]="breadcrumbs" class="large">
            <span *ngIf="edited">*</span>
        </uf-breadcrumbs>

        <a [routerLink]="['../']" class="uf-button tertiary right">Cancel</a>
        <button type="button" class="uf-button primary" (click)="save()">Save</button>
    </div>
    <div class="content-pane">
        <div class="uf-container-lg">
            <div class="uf-grid row-gap-lg pad-top">
                <div class="col-12 uf-box">
                    <div class="uf-app-bar accent">
                        <div class="title">Details</div>
                    </div>
                    <div class="uf-grid pad">
                        <uf-textarea label="Description" [control]="control" [(value)]="role.description"
                            class="col-12"></uf-textarea>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <uf-panel class="grow pad-sides">
        <uc-permissions></uc-permissions>
    </uf-panel>
</div>