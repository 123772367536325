<div class="header-pane uf-app-bar transparent">
    <uf-breadcrumbs [breadcrumbs]="breadcrumbs" class="large">
        <span *ngIf="edited">*</span>
    </uf-breadcrumbs>

    <a class="uf-button tertiary right" [routerLink]="['../']">Cancel</a>
    <button type="button" class="uf-button primary" [class.busy]="busy" (click)="save()">Save</button>
</div>

<uf-panel class="content-pane container" *ngIf="dataSource">
    <div class="uf-container-lg">
        <div class="uf-grid row-gap-lg pad-top">

            <ng-container *ngIf="dataSource">

                <div class="uf-box col-12">
                    <div class="uf-app-bar flat accent">
                        <div class="title">
                            Details
                        </div>
                    </div>
                    <div class="pad uf-grid">
                        <uf-select class="col-12" label="Integration" (valueChange)="integrationChanged($event)"
                            [disabled]="!isNew" [(value)]="integration"
                            [control]="form.controls.integration | asUfControl" [options]="integrations">
                        </uf-select>

                        <ng-container *ngIf="features?.length">
                            <div class="col-2" *ngIf="$any(integration.provider).logo">
                                <div class="uc-image--fixed-ratio">
                                    <img [src]="$any(integration.provider).logo | bypassSanitizeUrl">
                                </div>
                            </div>
                            <uf-select class="col-12" label="Feature" (valueChange)="featureChanged($event)"
                                [disabled]="!isNew" [control]="form.controls.feature | asUfControl" [options]="features"
                                [(value)]="feature">
                            </uf-select>
                        </ng-container>
                    </div>
                </div>

                <ng-container *ngIf="feature">
                    <div class="uf-box col-12">
                        <div class="uf-app-bar flat accent">
                            <div class="title">
                                Feature Configuration
                            </div>
                        </div>
                        <div class="uf-grid pad">

                            <uf-text class="col-12" label="Data Source Name"
                                [control]="form.controls.name | asUfControl" [(value)]="dataSource.name">
                                <div class="suffix">*</div>
                            </uf-text>

                            <uf-textarea class="col-12" label="Description"
                                [control]="form.controls.description | asUfControl" [(value)]="dataSource.description">
                            </uf-textarea>

                            <uf-blockquote class="info col-12" icon="infoSolid"
                                content="Default values have been configured for you.">
                            </uf-blockquote>
                        </div>

                        <div class="uf-app-bar flat">
                            <div class="title">
                                Parameters Available for the Data Source
                            </div>
                        </div>

                        <div class="uf-grid pad">

                            <uf-blockquote class="info col-12" icon="infoSolid"
                                content="These values must be set before the Data Source will function. They can be “Constant” ie. configured in Project Settings and set automatically for each individual use, “Default” ie. configured in Project Settings but can be manually changed when used, “Form” ie. always changed manually for each use, or “Unset” ie. when a value will never be set.">
                            </uf-blockquote>

                            <div class="col-12" *ngIf="inputArgs?.length">

                                <table class="uf-table inputs accent">
                                    <thead>
                                        <tr>
                                            <th>Integration Parameter</th>
                                            <th>Value Source</th>
                                            <th>Value</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let inputArg of inputArgs; let i = index">
                                            <td class="input-cell">
                                                <div class="row center-all">
                                                    <div *ngIf="inputArg.isRequired" class="suffix pad-left">*</div>
                                                    <uf-text class="table-cell grow" disabled="true"
                                                        value="{{inputArg.key}}">
                                                    </uf-text>
                                                </div>
                                            </td>
                                            <td class="input-cell">
                                                <uf-select *ngIf="!inputArg.isRequired" class="table-cell"
                                                    [(value)]="inputArg.source" [options]="sourceTypes"
                                                    [control]="$any(form.controls.inputArgs).at(i).controls.source">
                                                </uf-select>
                                                <uf-select *ngIf="inputArg.isRequired" class="table-cell"
                                                    [(value)]="inputArg.source" [options]="filteredSourceTypes"
                                                    [control]="$any(form.controls.inputArgs).at(i).controls.source">
                                                </uf-select>
                                            </td>
                                            <td class="input-cell">
                                                <uf-text class="table-cell grow"
                                                    *ngIf="inputArg.source === 'Form' || inputArg.source === 'Unset'"
                                                    value="{{sourceTypesInfo[inputArg.source]}}" disabled="true">
                                                </uf-text>

                                                <ng-container
                                                    *ngIf="inputArg.source === 'Constant' || inputArg.source === 'Default'">
                                                    <uf-text *ngIf="inputArg.type === 'Text'" class="table-cell grow"
                                                        [(value)]="inputArg.value"
                                                        [control]="$any(form.controls.inputArgs).at(i).controls.value">
                                                    </uf-text>
                                                    <uf-number *ngIf="inputArg.type === 'Number'"
                                                        class="table-cell grow" [(value)]="inputArg.value"
                                                        [control]="$any(form.controls.inputArgs).at(i).controls.value">
                                                    </uf-number>
                                                </ng-container>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="uf-app-bar flat">
                            <div class="title">
                                Data Source Display
                            </div>
                        </div>

                        <div class="uf-grid pad">
                            <uf-blockquote class="col-12 info" icon="infoSolid"
                                content="This determines how your Data Source options will display in the form. This data will be saved in the form for the selected Data Source option.">
                            </uf-blockquote>

                            <div class="col-12" *ngIf="dataSeedOutputArgs?.length">

                                <table class="uf-table inputs accent">
                                    <thead>
                                        <tr>
                                            <th>Parameter</th>
                                            <th>Value</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let outputArg of dataSeedOutputArgs; let i = index">
                                            <td class="input-cell">
                                                <div class="row center-all">
                                                    <div class=" suffix pad-left">*</div>
                                                    <uf-text class="table-cell grow" [(value)]="outputArg.key"
                                                        [control]="$any(form.controls.dataSeedOutputArgs).at(i).controls.key"
                                                        disabled="true">
                                                    </uf-text>
                                                </div>
                                            </td>
                                            <td class="input-cell">
                                                <uf-select class="table-cell" [options]="feature.outputArgs"
                                                    [control]="$any(form.controls.dataSeedOutputArgs).at(i).controls.value"
                                                    [(value)]="outputArg.value" valueProperty="identifier"
                                                    nameProperty="identifier">
                                                </uf-select>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <ng-container *ngIf="dataSeedOutputArgs?.length || outputArgs?.length">
                            <div class="uf-app-bar flat">
                                <div class="title">
                                    Data mapping
                                </div>
                            </div>
                            <div class="uf-grid pad">
                                <uf-blockquote class="info col-12" icon="infoSolid"
                                    content="This determines what additional data will be saved in the form for the selected Data Source option.">
                                </uf-blockquote>

                                <div class="col-12">
                                    <table class="uf-table inputs accent">
                                        <thead>
                                            <tr>
                                                <th>Parameter</th>
                                                <th>Value</th>
                                                <th class="detele-cell"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <ng-container *ngIf="outputArgs?.length">
                                                <tr *ngFor="let outputArg of outputArgs; let i = index">
                                                    <td class="input-cell">
                                                        <div class="row">
                                                            <uf-text class="table-cell grow" [(value)]="outputArg.key"
                                                                [control]="$any(form.controls.outputArgs).at(i).controls.key">
                                                            </uf-text>
                                                        </div>
                                                    </td>
                                                    <td class="input-cell">
                                                        <uf-select class="table-cell" [options]="feature.outputArgs"
                                                            [control]="$any(form.controls.outputArgs).at(i).controls.value"
                                                            [(value)]="outputArg.value" valueProperty="identifier"
                                                            nameProperty="identifier">
                                                        </uf-select>
                                                    </td>
                                                    <td class="input-cell">
                                                        <div class="row">
                                                            <button type="button" class="uf-action tertiary right"
                                                                (click)="deleteOutput(i)" title="Delete">
                                                                <uf-icon name="delete"></uf-icon>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </ng-container>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="col-12">
                                    <button type="button" class="uf-button right" (click)="add()">
                                        Add Parameter
                                    </button>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>

            </ng-container>

            <div class="col-12" *ngIf="error">
                <uf-blockquote class="error-text" icon="errorSolid" [content]="error.message"></uf-blockquote>
            </div>
            <div class="col-12" *ngIf="features && !features?.length">
                <uf-blockquote class="warning" icon="warningSolid" content="No features available"></uf-blockquote>
            </div>

            <div class="col-12" *ngIf="!dataSource">
                <br>
                <uf-spinner type="dots"></uf-spinner>
                <br>
            </div>
        </div>
    </div>
</uf-panel>