import { Subject, Subscription } from 'rxjs';

import { Inject, inject, Injectable, OnDestroy } from '@angular/core';
import { TableInputManager, TableInputs } from '@unifii/components';
import { CellDisplayDescriptor, FilterEntries, FilterEntry, FilterValue, HierarchyUnitProvider, TableConfig } from '@unifii/library/common';
import { CompoundType } from '@unifii/sdk';

import { CompoundInfo } from 'client';
import { TABLE_SEARCH_MIN_LENGTH } from 'constant';

import { UcTableManager } from 'services/table/models';

import { LanguageCollectionCompoundsDataSource } from './language-collection-compounds-datasource';
import { LanguageCollectionStore } from './language-collection-store';
import { LanguageColumnFactory } from './language-column-factory';


@Injectable()
export class LanguageCollectionTableManager implements UcTableManager<CompoundInfo>, OnDestroy {

    tableConfig: TableConfig<CompoundInfo>;
    showSearch = true;
    searchMinLength = TABLE_SEARCH_MIN_LENGTH;
    customColumns: CellDisplayDescriptor[];

    reload = new Subject<void>();
    update = new Subject<TableInputs<FilterValue>>();
    updateItem = new Subject<CompoundInfo>();
    inputManager: TableInputManager<FilterValue, FilterEntry>;
    items: CompoundInfo[] = [];

    private connection: Subscription;

    constructor(
        private store: LanguageCollectionStore,
        private languageColumnFactory: LanguageColumnFactory,
        @Inject(FilterEntries) entries: FilterEntry[]
    ) {
        this.inputManager = new TableInputManager(entries, inject(HierarchyUnitProvider));
        this.customColumns = this.languageColumnFactory.createCustom();

        this.tableConfig = {
            id: 'translations-collections',
            columns: this.languageColumnFactory.create(),
            actions: this.languageColumnFactory.createActions(CompoundType.Collection, this.reload),
            pageSize: 50,
            columnToggles: true,
            selectable: true,
            rowLink: element => element.id
        };
    }

    getNextItem(id?: string | number | undefined): CompoundInfo | undefined {
        const itemIndex = this.items.findIndex(item => item.id === id);
        if (itemIndex < 0) {
            return;
        }
        return this.items[itemIndex + 1];
    }

    createDataSource(inputs?: TableInputs<FilterValue> | undefined) {
        const q = inputs?.q;
        const sort = inputs?.sort;
        const status = inputs?.filters?.status as string | undefined;

        const dataSource = new LanguageCollectionCompoundsDataSource(this.store.ucCollectionTranslation, q, sort, status);

        if (this.connection) {
            this.connection.unsubscribe();
        }
        this.items = [];
        this.connection = dataSource.connect().subscribe(items => this.items.push(...(items?.data ?? [])));
        return dataSource;
    }

    ngOnDestroy(): void {
        this.connection.unsubscribe();
    }

}

