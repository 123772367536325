

import { Component, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import { ClipboardService, ModalService, ToastService } from '@unifii/library/common';

import { Permission, Resource, UcPermissions } from 'client';

import { ContextService } from 'services/context.service';

import { PermissionEditorComponent, PermissionEditorData } from './permission-editor.component';
import { PermissionChangeAction, PermissionsManagerService } from './permissions-manager.service';
import { PermissionsTableManager } from './permissions-table-manager';


@Component({
    selector: 'uc-permissions',
    template: `
        <uf-table-container *ngIf="!warningMessage && !errorMessage" [class.pad-none]="stretchedContainer" (addItem)="addPermission()" class="accent list-md">
            <ng-container *ngIf="!readonly" actions>
                <button (click)="paste()" title="Paste" type="button" class="uf-action tertiary">
                    <uf-icon name="paste"></uf-icon>
                </button>
            </ng-container>
        </uf-table-container>
        <uf-message *ngIf="warningMessage" icon="warningSolid"
            [content]="warningMessage" class="warning gap-top">
        </uf-message>
        <uf-message *ngIf="errorMessage" icon="errorSolid"
            [content]="errorMessage" class="error gap-top">
        </uf-message>
    `,
    providers: [
        { provide: TableContainerManager, useClass: PermissionsTableManager }
    ]
})
export class PermissionsComponent {

    readonly: boolean;
    stretchedContainer = false;
    warningMessage?: string;
    errorMessage?: string;

    private resource: Resource;

    constructor(
        route: ActivatedRoute,
        private ucPermissions: UcPermissions,
        private permissionsManager: PermissionsManagerService,
        private toastService: ToastService,
        private context: ContextService,
        private modalService: ModalService,
        private clipboard: ClipboardService,
        @Inject(TableContainerManager) private tableManager: PermissionsTableManager
    ) {
        this.readonly = this.tableManager.readonly;

        if (route.snapshot.data.resourceData == null) {
            this.errorMessage = 'Failed to load permissions';
            return;
        }
        // Access Control Disabled
        if (this.context.tenantSettings && !this.context.tenantSettings.isAccessControlEnabled) {
            this.warningMessage = 'Functionality is disabled for this tenant';
        }

        this.stretchedContainer = route.snapshot.data.stretchedContainer === true;
        this.resource = route.snapshot.data.resourceData.resource;
    }

    async paste() {
        try {
            const text = await this.clipboard.getText();
            const permissions = text ? JSON.parse(text) as Permission[] : [];

            let copied = 0;

            for (const permission of permissions) {
                permission.principalType = this.ucPermissions.entityType;
                permission.principalId = this.ucPermissions.entityId;
                try {
                    await this.ucPermissions.save(permission);
                    copied++;
                } catch (e) {
                    console.error(e);
                }
            }

            this.toastService.success(`Copied ${copied} out of ${permissions.length} permissions`);
            this.tableManager.reload.next();
        } catch (err) {
            console.error('Failed to read clipboard contents: ', err);
            this.toastService.error('Failed to paste');
        }
    }

    async addPermission() {
        const newPermission: Permission = {
            principalType: this.ucPermissions.entityType,
            principalId: this.ucPermissions.entityId
        };

        const permission = await this.modalService.openLarge<PermissionEditorData, Permission>(
            PermissionEditorComponent, { permission: newPermission, resources: this.resource }
        );
        if (!permission) {
            return;
        }

        try {
            await this.ucPermissions.save(permission);
            this.tableManager.reload.next();
            this.permissionsManager.notify.next({
                action: PermissionChangeAction.Added,
                principalType: permission.principalType,
                principalId: permission.principalId
            });
            this.toastService.success('Permission added');
        } catch (error) {
            console.error(error);
            this.toastService.error('Permission create failed');
        }
    }

}
