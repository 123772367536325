import { Directive, inject, InjectionToken, Type } from '@angular/core';
import { ModalService } from '@unifii/library/common';

import { Content } from 'components/content/content';
import { LinkSearchConfig } from 'components/content/modals/link-search.component';

import { ModalSearch, ModalSearchData } from './modals/modal-search';


export const ModalSearchType = new InjectionToken<Type<ModalSearch>>('ModalSearchType');

@Directive()
export abstract class ContentList extends Content {

    content: any | any[] | undefined;

    protected modalService = inject(ModalService);
    private modalSearchType = inject(ModalSearchType);

    /** An item within the list has been reordered */
    protected moved() {
        if (this.editorField) {
            this.builderService.fieldEdit.next({ subject: this.editorField, atomic: true });
        } else {
            this.control.markAsDirty();
            this.control.setValue(this.content);
            this.control.setValue(this.content);
        }
    }

    /** Append an item to the list */
    protected async add() {

        const searchConfig = this.getSearchConfig();

        const entries = await this.modalService.openMedium<ModalSearchData, any[]>(
            this.modalSearchType, searchConfig
        );

        if (!entries?.length) {
            return;
        }

        if (searchConfig.multiSelect) {

            if (!this.content) {
                this.content = [];
            }

            for (const entry of entries) {
                this.content.push(
                    this.convertResultToContent ?
                        this.convertResultToContent(entry) :
                        entry
                );
            }
        } else {
            this.content = this.convertResultToContent ?
                this.convertResultToContent(entries[0]) :
                entries[0];
        }

        if (this.scope) {
            this.scope[this.configuredField.identifier as string] = this.content;
        }

        this.control.markAsDirty();
        this.control.setValue(this.content);
        this.contentChange.emit(this.content);

        if (this.editorField) {
            this.builderService.fieldEdit.next({ subject: this.editorField, atomic: true });
        }
    }

    /** Remove an item from the list */
    protected delete(i: number) {

        if (Array.isArray(this.content)) {
            this.content.splice(i, 1);
            if (this.scope) {
                this.scope[this.configuredField.identifier as string] = this.content;
            }
        } else {
            this.content = undefined;
            if (this.builderService.compound) {
                delete this.builderService.compound[this.configuredField.identifier as string];
            }
        }

        this.control.markAsTouched();
        this.control.setValue(this.content);

        if (this.editorField) {
            this.builderService.fieldEdit.next({ subject: this.editorField, atomic: true });
        }
    }

    protected abstract getSearchConfig(): LinkSearchConfig;
    protected convertResultToContent?(item: any): any;
}
