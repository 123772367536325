<label *ngIf="label" class="grow">
    {{ label }}
    <uf-help *ngIf="help" [content]="help"></uf-help>
</label>
<uf-markdown-editor [value]="value" [control]="control" [disabled]="disabled">
    <button compact type="button" listBox [options]="defaultOptions" [template]="mediaOptions" class="uf-action"
        title="Media">
        <uf-icon name="media"></uf-icon>
        <ng-template #mediaOptions>
            <ul class="uf-list uf-box">
                <li class="uf-list-button body-copy compact" (click)="addImage()" title="Insert image">
                    <uf-icon name="imageList"></uf-icon>
                    <span class="gap-sm-left">Insert image</span>
                </li>
                <li class="uf-list-button body-copy compact" (click)="addVideo()" title="Insert video">
                    <uf-icon name="video"></uf-icon>
                    <span class="gap-sm-left">Insert video</span>
                </li>
            </ul>
        </ng-template>
    </button>
    <button normal class="uf-action tertiary" title="Insert image" (click)="addImage()">
        <uf-icon name="imageList"></uf-icon>
    </button>
    <button normal class="uf-action tertiary" title="Insert video" (click)="addVideo()">
        <uf-icon name="video"></uf-icon>
    </button>
</uf-markdown-editor>