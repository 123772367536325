import { AfterViewInit, Component } from '@angular/core';

import { ContentList, ModalSearchType } from 'components/content/content-list';
import { MediaSearchComponent } from 'components/content/modals/media-search.component';


@Component({
    templateUrl: './file-list.html',
    styleUrls: ['./group-input.less'],
    providers: [{ provide: ModalSearchType, useValue: MediaSearchComponent }]
})
export class FileListComponent extends ContentList implements AfterViewInit {

    protected getSearchConfig(): any {
        return {
            type: 'File',
            title: 'Add File(s)',
            multiSelect: true
        };
    }
}
