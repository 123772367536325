import { Injectable } from '@angular/core';
import { BucketDataDescriptorAdapterLoader } from '@unifii/library/common';
import { ClaimConfig, Schema } from '@unifii/sdk';

import { UcCompanyClaims, UcDefinition, UcProject, UcUserClaims } from 'client';


@Injectable()
export class UcBucketDataDescriptorAdapterLoader implements BucketDataDescriptorAdapterLoader {

    constructor(
        private ucProject: UcProject,
        private ucUserClaims: UcUserClaims,
        private ucCompanyClaims: UcCompanyClaims
    ) { }

    loadCollectionDefinition(identifier: string): Promise<UcDefinition> {
        return this.ucProject.collection(identifier).getDefinition();
    }

    loadSchema(identifier: string): Promise<Schema> {
        return this.ucProject.getBucket(identifier);
    }

    async loadForms(): Promise<{ identifier: string; label: string; bucket?: string | undefined }[]> {

        const results: { identifier: string; label: string; bucket?: string | undefined }[] = [];
        const pageSize = 50;

        let batch: { identifier: string; label: string; bucket?: string | undefined }[];
        let page = 0;

        do {
            batch = (await this.ucProject.getForms({ params: { offset: page * pageSize, limit: pageSize } })).map(definitionInfo => ({
                identifier: definitionInfo.identifier,
                label: definitionInfo.name,
                bucket: definitionInfo.bucket
            }));
            if (batch.length > 0) {
                results.push(...batch);
            }
            page = page + 1;
        } while (batch.length === pageSize);

        return results;
    }

    loadUserClaims(): Promise<ClaimConfig[]> {
        return this.ucUserClaims.list({ params: { limit: 1000 } });
    }

    loadCompanyClaims(): Promise<ClaimConfig[]> {
        return this.ucCompanyClaims.list({ params: { limit: 1000 } });
    }
}