import { amendOptionsParams, ClientGetOptions, ClientPostOptions, ClientPutOptions } from '@unifii/sdk';

import { DefaultPaginationParams } from 'constant';

import { CompoundInfo, UcCompound } from './content-models';
import { UcClient } from './uc-client';


export class UcCollectionTranslation {

    constructor(
        private client: UcClient,
        private projectId: number,
        private collectionIdentifier: string,
        private lang: string
    ) { }

    /** q, sort, status, offset = 0, limit = 50, analytics?: RequestAnalytics */
    get(options?: ClientGetOptions): Promise<CompoundInfo[]> {
        return this.client.get(this.url(), amendOptionsParams(DefaultPaginationParams, options));
    }

    getItem(id: number, options?: ClientGetOptions): Promise<UcCompound> {
        return this.client.get(this.url([id]), options);
    }

    saveItem(id: number, compound: UcCompound, options?: ClientPutOptions): Promise<UcCompound> {
        return this.client.put(this.url([id]), compound, options);
    }

    approve(id: number, options?: ClientPostOptions): Promise<CompoundInfo> {
        return this.client.post(this.url([], ['approved']), amendOptionsParams({ id }, options));
    }

    unapprove(id: number, options?: ClientPostOptions): Promise<CompoundInfo> {
        return this.client.post(this.url(), amendOptionsParams({ id }, options));
    }

    private url(beforeLanguage: (string | number)[] = [], afterLanguage: string[] = []): string {
        const urlParts = ['projects', this.projectId, 'collections', this.collectionIdentifier].concat(...beforeLanguage, 'translations', this.lang, ...afterLanguage);
        return this.client.buildUrl(urlParts);
    }
}
