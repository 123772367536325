<div class="header">
    <button type="button" class="uf-action tertiary" (click)="close()" title="Close">
        <uf-icon name="close"></uf-icon>
    </button>
    <h3>{{data.title}}</h3>
</div>

<div class="content">
    <uf-search [search]="query" (searchChange)="filter($event)" [autofocus]="true">
    </uf-search>
</div>

<uf-panel (scrollBottom)="onScrolledToEnd()" [bottomThreshold]="20">
    <ul class="media-select">
        <li *ngIf="!busy && query?.length && !items.length">
            Sorry, there was no result for your search.
        </li>
        <li *ngFor="let item of items, let i = index">
            <a (click)="select(i)" [ngClass]="{active: item.selected}">
                <div class="uc-image--no-padding"
                    *ngIf="item.url && item.type !== 'File' && item.type !== 'Audio' && item.type !== 'Video'">
                    <img [src]="item?.url + '?w=48&h=48&mode=crop'" alt="item.title">
                </div>
                <span class="padded--horizontal">
                    <h4>{{item.title}}</h4>
                    <p>{{item.description}}</p>
                </span>
            </a>
        </li>
    </ul>
</uf-panel>

<div class="row space-children pad">
    <button type="button" class="uf-button tertiary right" (click)="close()">Close</button>
    <button type="button" class="uf-button primary" (click)="save()">Add</button>
</div>