import { FormFieldAdvancedComponent } from './field-configuration/form-field-advanced.component';
import { FormFieldConfigurationComponent } from './field-configuration/form-field-configuration.component';
import { FormFieldDetailsComponent } from './field-configuration/form-field-details.component';
import { FormFieldDisplayComponent } from './field-configuration/form-field-display.component';
import { FormFieldNestedFieldsComponent } from './field-configuration/form-field-nested-fields.component';
import { FormFieldOptionsComponent } from './field-configuration/form-field-options.component';
import { FormFieldSettingsComponent } from './field-configuration/form-field-settings.component';
import { FormFieldTransitionEditorComponent } from './field-configuration/form-field-transition-editor.component';
import { FormFieldTransitionTargetComponent } from './field-configuration/form-field-transition-target.component';
import { FormFieldTransitionsComponent } from './field-configuration/form-field-transitions.component';
import { FormFieldValidatorsComponent } from './field-configuration/form-field-validators.component';
import { FormFieldVariationComponent } from './field-configuration/form-field-variation.component';
import { FormFieldVariationsComponent } from './field-configuration/form-field-variations.component';
import { FormFieldMetadata } from './form-editor-model';
import { FormEditorComponent } from './form-editor.component';
import { FormFieldIconsComponent } from './form-field-icons.component';
import { FormFieldComponent } from './form-field.component';
import { FormMetadataModalComponent } from './form-metadata-modal.component';
import { FormMetadataComponent } from './form-metadata.component';


const FORM_EDITOR_COMPONENTS = [
    FormEditorComponent,
    FormFieldComponent,
    FormFieldConfigurationComponent,
    FormFieldDetailsComponent,
    FormMetadataComponent,
    FormFieldSettingsComponent,
    FormFieldDisplayComponent,
    FormFieldNestedFieldsComponent,
    FormFieldVariationsComponent,
    FormFieldOptionsComponent,
    FormFieldTransitionsComponent,
    FormFieldTransitionEditorComponent,
    FormFieldTransitionTargetComponent,
    FormFieldValidatorsComponent,
    FormFieldVariationComponent,
    FormFieldAdvancedComponent,
    FormMetadataModalComponent,
    FormFieldIconsComponent
];

export {
    FORM_EDITOR_COMPONENTS,
    FormEditorComponent,
    FormFieldComponent,
    FormFieldConfigurationComponent,
    FormFieldDetailsComponent,
    FormMetadataComponent,
    FormFieldMetadata,
    FormFieldSettingsComponent,
    FormFieldDisplayComponent,
    FormFieldNestedFieldsComponent,
    FormFieldVariationsComponent,
    FormFieldOptionsComponent,
    FormFieldTransitionsComponent,
    FormFieldTransitionEditorComponent,
    FormFieldTransitionTargetComponent,
    FormFieldValidatorsComponent,
    FormFieldVariationComponent,
    FormFieldAdvancedComponent,
    FormMetadataModalComponent,
    FormFieldIconsComponent
};