import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';

import { Resource, ResourceElement, UcResources } from 'client';


export interface ResourceData {
    resource: Resource;
    projectResources: ResourceElement[];
    userClaimResources: ResourceElement[];
    companyClaimResources: ResourceElement[];
}

export const resourceDataResolver: ResolveFn<ResourceData | undefined> = async () => {

    const ucResources = inject(UcResources);

    try {
        const resource = await ucResources.get();
        const projectResources = await ucResources.getProjects();
        const userClaimResources = await ucResources.getUserClaims();
        const companyClaimResources = await ucResources.getCompanyClaims();

        return { resource, projectResources, userClaimResources, companyClaimResources };
    } catch (e) {
        return;
    }
}
