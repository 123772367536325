import { format, parseISO } from 'date-fns';

import { Component, ElementRef, HostBinding, Inject, Injectable, OnInit, RendererFactory2, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
    AppContext, Breadcrumb, ContextProvider, DateAndTimeFormat, mapUserToUserContext, ModalService, RuntimeDefinition, RuntimeDefinitionAdapter,
    ThemeProvider, ThemeService, WindowWrapper
} from '@unifii/library/common';
import { FormRevisionStorage, FormSettings } from '@unifii/library/smart-forms';
import { EmpFormUploader, InputFormSettings, UfFormComponent } from '@unifii/library/smart-forms/input';
import { Client, DataSeed, Definition, FormData } from '@unifii/sdk';

import { MyAccount, Role, UcClient, UcProject, UcRoles } from 'client';

import { FormBucketService } from 'pages/form-data/bucket-service';

import { BreadcrumbService } from 'services/breadcrumb.service';
import { ContextService } from 'services/context.service';

import { MetadataModalComponent } from './metadata-modal.component';


const createThemeProvider = (context: ContextService) => ({
    theme: {
        formStyle: context.project?.theme?.formStyle
    }
});

const revisionStorageFactory = (ucProject: UcProject, client: Client, route: ActivatedRoute) => {
    return new FormRevisionStorage(client, { projectId: '' + ucProject.options.projectId, preview: route.snapshot.data.preview });
};

@Injectable()
export class FormDataContextProvider implements ContextProvider {

    private context: AppContext | undefined;

    constructor(private contextService: ContextService) { }

    get() {

        if (this.context) {
            return this.context;
        }

        if (this.contextService.account == null) {
            return {
                user: {
                    username: 'user',
                    roles: [],
                    claims: {}
                }
            };
        }

        const myAccount = JSON.parse(JSON.stringify(this.contextService.account)) as MyAccount;

        this.context = {
            user: mapUserToUserContext(Object.assign({}, myAccount, {
                hasPassword: false,
                isExternal: false,
                isActive: false,
            }))
        };

        return this.context;
    }
}


@Component({
    templateUrl: './form-data.html',
    providers: [
        { provide: FormSettings, useClass: InputFormSettings },
        { provide: ThemeProvider, useFactory: createThemeProvider, deps: [ContextService] },
        { provide: FormRevisionStorage, useFactory: revisionStorageFactory, deps: [UcProject, Client, ActivatedRoute] },
        { provide: ContextProvider, useClass: FormDataContextProvider }
    ]
})
export class FormDataComponent implements OnInit {

    @HostBinding('class.stretch-component') class = true;

    @ViewChild(UfFormComponent, { read: ElementRef }) set formComponent(e: ElementRef) {
        if (e == null) {
            return;
        }

        const themeService = new ThemeService(e.nativeElement, this.renderFactory);
        const projectTheme = this.context.project?.theme;
        if (projectTheme) {
            themeService.theme = projectTheme;
        }
    }

    revOptions: DataSeed[] = [];
    revisions: FormData[];
    selected: number;
    revisionList: { formData: FormData; definition: RuntimeDefinition }[] = [];
    info: { formData: FormData; definition: Definition }[] = [];
    breadcrumbs: Breadcrumb[] = [];

    private id: string;

    constructor(
        private route: ActivatedRoute,
        private context: ContextService,
        private bucketService: FormBucketService,
        @Inject(FormSettings) private formSettings: FormSettings,
        private modal: ModalService,
        private client: UcClient,
        private renderFactory: RendererFactory2,
        @Inject(WindowWrapper) private window: Window,
        private revisionStorage: FormRevisionStorage,
        private breadcrumbService: BreadcrumbService,
        private ucRoles: UcRoles,
        private contextProvider: ContextProvider,
        private runtimeDefinitionAdapter: RuntimeDefinitionAdapter
    ) {
        this.id = this.route.snapshot.params.id;
        /**
         * //TODO the any on the bucket input parameter was causing issues's
         * have marked as any for now, can't
         * Argument of type 'Bucket' is not assignable to parameter of type 'Bucket'.
         * Types have separate declarations of a private property 'client'
         */
        this.formSettings.uploader = new EmpFormUploader(this.bucketService.formDataClient);
        this.breadcrumbs = this.breadcrumbService.getBreadcrumbs(this.route, [bucketService.schema.bucket, undefined, this.id]);
    }

    async ngOnInit() {
        if (!this.id) {
            return;
        }

        try {

            const rolesName = await this.ucRoles
                .get(undefined, undefined, { params: { limit: 1000 } })
                .then(roles => this.mapRolesDescription(roles));

            if (this.contextProvider.get().user != null && this.contextProvider.get().user?.roles != null) {
                this.contextProvider.get().user?.roles?.push(...rolesName);
            }

            const sourceFormData = await this.bucketService.formDataClient.get(this.id);

            await this.revisionStorage.loadRevisionsByFormData(sourceFormData);
            const revisions = this.revisionStorage.revisions ?? [];

            let definition: RuntimeDefinition | undefined;
            let index = 0;

            for (const formData of revisions) {
                if (formData._definitionVersion !== definition?.version) {
                    definition = await this.runtimeDefinitionAdapter.transform(await this.bucketService.content.getForm(formData._definitionIdentifier as string, formData._definitionVersion));
                }
                if (definition) {
                    this.revisionList.push({ formData, definition });

                    this.revOptions.push({
                        _display: this.getRevisionLabel(formData, index + 1),
                        _id: '' + index,
                        position: index
                    });

                    index++;
                }
            }

            this.selected = this.revOptions.length - 1;

            // clone object so openAt doesn't change
            this.info = this.revisionList.map(revision => JSON.parse(JSON.stringify(revision)));
        } catch (e) {
            console.error('Error: Cannot load form data for id ', this.id);
        }
    }

    showMetadata() {
        this.modal.openFit(MetadataModalComponent, this.info[this.selected].formData);
    }

    async openPDF() {
        const { formData, definition } = this.revisionList[this.selected];
        const url = this.getPdfUrl(this.context.project?.id, definition.bucket, formData.id, this.route.snapshot.data.preview, formData._rev);
        const headers = new Headers({ Accept: 'application/pdf' });
        try {
            const data = await this.client.get(url, { headers });
            const file = new Blob([data], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            this.window.open(fileURL);
        } catch (e) {
            this.modal.openAlert({ title: 'Error loading Pdf', message: e.message });
        }

    }

    private getRevisionLabel(data: FormData, index: number): string {
        const initialState = data?._history?.length ? data._history[0].state : '';
        const currentState = data?._state;
        const action = data?._action;
        const lastModifiedAt = data?._lastModifiedAt ? format(parseISO(data._lastModifiedAt), DateAndTimeFormat) : null;

        return `${index} - ${initialState} -> ${currentState} (${action})${lastModifiedAt ? ` - ${lastModifiedAt}` : ''}`;
    }

    private getPdfUrl(projectId?: string, bucket?: string, id?: string, preview?: boolean, revision?: string): string {

        const url = ['v0', 'projects', projectId];

        if (preview) {
            url.push('preview');
        }

        url.push('form-data', bucket, id);

        if (revision) {
            url.push('revisions', revision);
        }

        return this.client.buildUrl(url, false);
    }

    private mapRolesDescription(roles: Role[]) {
        return roles.map(r => r.name);
    }


}
