<a [routerLink]="[media.id]" class="uc-card {{displayType}} {{fileType}}">

	<div class="uc-image--fixed-ratio--3by2 checkboard-bg">
		<img *ngIf="displayType === imageDisplay" [src]="url" />
		<uf-icon *ngIf="displayType === infoDisplay" name="{{icon}}"></uf-icon>
	</div>

	<div class="grid">
		<div class="col-1of1 compact">
			<p class="small">{{media.title}}</p>
			<div class="fieldset-item--x-small" *ngIf="displayType === imageDisplay">
				<div class="fieldset-label">Uploaded</div>
				<div class="fieldset-value">{{ media.uploadedAt | date : "dd/MM/y"}}</div>
			</div>
			<div *ngIf="media.length && displayType === infoDisplay" class="fieldset-item--x-small">
				<div class="fieldset-label">Length</div>
				<div class="fieldset-value">{{media.length | fileLength }}</div>
			</div>
			<div class="fieldset-item--x-small">
				<div class="fieldset-label">File Type</div>
				<div class="fieldset-value">{{media.filename | fileType }}</div>
			</div>
		</div>
	</div>
</a>