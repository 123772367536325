<div class="header">
  <button type="button" class="uf-action tertiary" (click)="close()" title="Close">
    <uf-icon name="close"></uf-icon>
  </button>
  <h3>Field Order</h3>
</div>

<uf-panel class="container">
  <uc-drag-list [items]="items" canDrop="false" canReorder="true" class="col-1of1">
    <ul class="uc-list">
      <li class="uc-list-item--draggable--small row center-all" *ngFor="let item of items" dragItem>
        <div class="list-content">
          <div class="grow">{{item.name}}</div>
        </div>
      </li>
    </ul>
  </uc-drag-list>
</uf-panel>

<div class="row space-children pad">
  <button type="button" class="uf-button tertiary right" (click)="close()">Cancel</button>
  <button type="button" class="uf-button primary" (click)="save()">Save</button>
</div>