import { Subscription } from 'rxjs';

import { Component, Inject, Input, OnDestroy } from '@angular/core';
import { ModalService } from '@unifii/library/common';
import { TenantSettings } from '@unifii/sdk';

import { Config } from 'app-config';
import { UcClient } from 'client/uc-client';

import { ContactInfoModalComponent } from './contact-info-modal.component';


@Component({
    selector: 'uc-user-access-box',
    templateUrl: './user-access-box.html',
    styleUrls: ['./user-access-box.less'],
})
export class UserAccessBoxComponent implements OnDestroy {

    @Input() tenantSettings: TenantSettings | undefined;

    authenticated: boolean;
    inProgress: boolean;

    private subscriptions = new Subscription();

    constructor(
        @Inject(Config) public config: Config,
        private modalService: ModalService,
        private client: UcClient
    ) {
        this.subscriptions.add(this.client.inProgress.subscribe(inProgress => this.inProgress = inProgress));
    }

    async showContactInfo() {
        if (!this.tenantSettings) {
            return;
        }

        this.modalService.openFit<TenantSettings, void>(ContactInfoModalComponent, this.tenantSettings);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
