import { Component, EventEmitter, Input, Output } from '@angular/core';


export enum SaveOptionType {
    Next = 'next',
    Close = 'close',
    New = 'new',
    Approved = 'approve',
    Unapproved = 'unapprove'
}

export interface SaveOption {
    label: string;
    id: SaveOptionType;
}

export const SaveAndClose: SaveOption = { label: 'Save & Close', id: SaveOptionType.Close };
export const SaveAndNext: SaveOption = { label: 'Save & Next', id: SaveOptionType.Next };
export const SaveAndNew: SaveOption = { label: 'Save & Add New', id: SaveOptionType.New };
export const Approve: SaveOption = { label: 'Save & Approve', id: SaveOptionType.Approved };
export const Unapprove: SaveOption = { label: 'Save & Unapprove', id: SaveOptionType.Unapproved };

@Component({
    selector: 'uc-save-options',
    templateUrl: './save-options.html',
    styleUrls: ['./save-options.less']
})
export class SaveOptionsComponent {

    @Input() options: SaveOption[];
    @Input() disabled: boolean | string;
    @Output() onSave: EventEmitter<SaveOption> = new EventEmitter();
}
