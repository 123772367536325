import { Component, HostBinding, Inject } from '@angular/core';
import { Modal, ModalData, ModalRuntime } from '@unifii/library/common';


export interface GlossaryEntry {
    key: string;
    value: string;
}

export interface GlossaryConfig {
    title: string;
    glossary: GlossaryEntry[];
}

@Component({
    templateUrl: 'glossary.html',
    styleUrls: ['./glossary.less']
})
export class GlossaryComponent implements Modal<GlossaryConfig, GlossaryEntry> {

    @HostBinding('class.uc-form-card') classes = true;

    constructor(
        @Inject(ModalData) public data: GlossaryConfig,
        public runtime: ModalRuntime<GlossaryConfig, GlossaryEntry>
    ) { }

    close(entry?: GlossaryEntry) {
        this.runtime.close(entry);
    }

    // Block this component to get focus
    onMouseDown(_event: MouseEvent) {
        return false;
    }
}
