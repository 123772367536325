<uf-expander *ngIf="ready" class="uf-box flat">

    <div expanderHeader class="uf-app-bar flat">
        <div class="title primary">Options</div>
        <uf-icon *ngIf="isInvalid" name="error" class="error"></uf-icon>
        <uc-expander-controls *ngIf="options.length" [container]="optionsWrap"></uc-expander-controls>
    </div>

    <div expanderBody #optionsWrap class="pad-sm">

        <uf-message *ngIf="options.length > 0 && dataSource" class="x-small warning" icon="warningSolid">
            <p>Unsupported configuration. Please configure either a Data Source or Options.</p>
        </uf-message>

        <uf-message *ngIf="options.length === 0 && dataSource == null && options.valid" class="x-small warning"
            icon="warningSolid">
            <p>Default has no options - please ensure variations cover all scenarios.</p>
        </uf-message>


        <!-- Options -->
        <uc-drag-list *ngIf="meta.options" [items]="options.controls" canDrop="false" canReorder="true">
            <ng-template ngFor [ngForOf]="options.controls | asUfControlsGroups" let-option let-i="index">

                <uf-expander dragItem [dragDisabled]="options.disabled" [isExpanded]="expandeds[i]"
                    class="uf-box flat draggable gap-sm-bottom">
                    <div expanderHeader dragHandle class="uf-app-bar flat">
                        <div class="title primary">
                            {{ getOptionLabel(option) }}
                        </div>
                        <uf-icon *ngIf="option.invalid" name="error" class="error">
                        </uf-icon>
                        <button *ngIf="!option.disabled && type !== fieldTypes.Bool" (click)="removeOption(i)"
                            title="Delete" class="uf-action">
                            <uf-icon name="delete"></uf-icon>
                        </button>
                    </div>
                    <div expanderBody [formGroup]="option" class="uf-grid pad">
                        <uf-text label="Name" placeholder="Option name" [formControlName]="optionKeys.Name"
                            class="col-12">
                        </uf-text>
                        <uf-text *ngIf="type !== fieldTypes.Bool" label="Identifier" placeholder="Option identifier"
                            [formControlName]="optionKeys.Identifier" [maxLength]="identifierMaxLength" class="col-12">
                        </uf-text>
                        <uf-message *ngIf="showIdentifierWarningLength(option)" class="x-small warning col-12"
                            icon="warningSolid">
                            <p>Identifier is too long</p>
                        </uf-message>
                        <uc-markdown *ngIf="showContent" label="Content" [formControlName]="optionKeys.Content"
                            [showAlignmentOptions]="showAlignmentOptions" class="col-12">
                        </uc-markdown>
                    </div>
                </uf-expander>

            </ng-template>

        </uc-drag-list>

        <!-- Options validators error sit on the ArrayControl -->
        <uf-error *ngIf="meta.options" [control]="options"></uf-error>

        <!-- DataSourceConfig -->
        <div *ngIf="dataSourceConfig && meta.dataSourceConfig">
            <div *ngIf="dataSource" class="uf-box flat">
                <div class="uf-app-bar flat">
                    <div class="title">
                        Data Source
                    </div>
                    <button *ngIf="canEditDataSource" title="Edit" (click)="editDataSource()" type="button"
                        class="uf-action">
                        <uf-icon name="edit"></uf-icon>
                    </button>
                    <button type="button" title="Delete" (click)="deleteDataSource()" class="uf-action">
                        <uf-icon name="delete"></uf-icon>
                    </button>
                </div>
                <uf-checkbox *ngIf="meta.avoidDuplicates" [control]="avoidDuplicates" label="Avoid duplicates"
                    class="gap-sides">
                </uf-checkbox>
                <uf-dl class="small-label pad">
                    <dt>Type:</dt>
                    <dd>{{ dataSource.type | dataSourceTypeLabel }}</dd>
                    <ng-container *ngIf="dataSourceName">
                        <dt>Name:</dt>
                        <dd>{{dataSourceName}}</dd>
                    </ng-container>
                </uf-dl>
                <uf-message *ngIf="dataSourceConfig?.showError" [content]="dataSourceConfig.errors?.message"
                    [showExpander]="false" icon="error" class="error gap-sides gap-bottom"></uf-message>
                <uf-message *ngIf="showReportableWarning" [showExpander]="false" icon="warning"
                    content="At least one mapped field should be reportable" class="warning gap"></uf-message>
            </div>
        </div>

        <div class="row x-small gap-sm-top">
            <div class="right"></div>
            <button
                *ngIf="meta.dataSourceConfig && !dataSource && !dataSourceConfig.disabled &&  (options.value == null || options.length === 0)"
                class="uf-button gap-sm-right" (click)="editDataSource()">
                Add Data Source
            </button>
            <button *ngIf="!options.disabled && meta.options && type !== fieldTypes.Bool && !dataSource"
                class="uf-button" (click)="addOption()">
                Add Option
            </button>
        </div>

    </div>
</uf-expander>