import { fromEvent, Subscription } from 'rxjs';

import { Directive, EventEmitter, Input, OnDestroy, Output } from '@angular/core';

import { AbstractControl } from '@angular/forms';

import { ArrayHelper } from 'helpers/helpers';
import { flattenControls } from 'pages/structure/structure-form-ctrl';
import { UfControlGroup } from '@unifii/library/common';


@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[keySelect]'
})
export class KeySelectDirective implements OnDestroy {

    @Input() entries: any[];
    @Input() current: any;
    @Input() filter: ((c: UfControlGroup) => boolean) | null | undefined;
    @Output() next = new EventEmitter<any>();

    /** Prevent bubbling from the following focused elements */
    readonly tagsToBlock = ['input', 'select', 'option', 'textarea', 'label', 'button'];

    private keyDownSubscriber: Subscription;

    constructor() {

        this.keyDownSubscriber = fromEvent(document, 'keydown').subscribe((e: KeyboardEvent) => {

            // Guard up and dowm arrow
            if (!['ArrowDown', 'ArrowUp'].includes(e.key)) {
                return;
            }

            // Guard non unfocusable elements
            if (document.activeElement && this.tagsToBlock.indexOf(document.activeElement.tagName.toLowerCase()) >= 0) {
                return;
            }

            // Try to select next one
            this.updateView(e.key === 'ArrowDown');
        });
    }

    updateView(next: boolean) {

        // Controls
        if (this.current instanceof AbstractControl) {

            let controls = flattenControls(this.current.root)
                .map(c => c.control)
                .filter(c => c instanceof UfControlGroup) as UfControlGroup[];

            if (this.filter) {
                controls = controls.filter(c => (this.filter as (c: UfControlGroup) => boolean)(c));
            }

            const currentIndex = controls.findIndex(c => c === this.current);

            if (currentIndex < 0) {
                return;
            }

            const selectedIndex = currentIndex + (next ? 1 : -1);

            if (selectedIndex < 0 || selectedIndex >= controls.length) {
                return;
            }

            const selectedControl = controls[selectedIndex];

            if (!selectedControl) {
                return;
            }

            this.next.emit(selectedControl);
            return;
        }

        // Array
        if (this.entries) {
            const found = ArrayHelper.findNext(next, this.current, this.entries, null) || this.current;
            this.next.emit(found);
        }

    }

    ngOnDestroy() {
        this.keyDownSubscriber.unsubscribe();
    }

}
