import { Config } from 'app-config';

import { Injectable } from '@angular/core';

import { ContextService } from './context.service';


@Injectable()
export class ConfigService {

    constructor(
        private config: Config,
        private context: ContextService
    ) { }


    get tenant(): string | null {
        const url = this.config.baseUrl !== '' ? this.config.baseUrl : window.location.href;
        const domain = url.split('://')[1];

        if (domain) {
            const tenant = domain.split('.')[0];
            return tenant;
        }

        return null;
    }

    /**
     * Check if Console need to run in Crown back-compatibility mode
     * TODO: Remove when no deployment need old crown data back-compatibility
     */
    isCrownCompatible(): boolean {
        const projectId = this.context.project ? this.context.project.id as any as number : null;

        // Match for deploy on Crown server https://emp.tilt.crownresorts.com.au/ using environment.crown.ts
        return this.config.compatibility === 'crown' ||
            // Match https://crown.unifii.net (change this to Tenant = crown and prj 52)
            (this.tenant === 'crown' && projectId === 52) ||
            // Match https://dupont.unifii.net (change this to Tenant = dupont and prj 66)
            (this.tenant === 'dupont' && projectId === 66);
    }
}
