<uf-panel *ngIf="ready" class="container">

    <div class="header-pane uf-app-bar transparent">
        <uc-builder-header>
        </uc-builder-header>
    </div>

    <uf-panel class="content-pane">
        <div class="grid--fixed">
            <uf-checkbox label="Show untranslated only" [(value)]="showUntranslated" class="col-1of1"></uf-checkbox>
        </div>
        <div *ngIf="definition" class="grid--fixed">
            <ng-template ngFor [ngForOf]="definition.fields" let-field let-i="index">
                <uc-language-field
                    *ngIf="(!showUntranslated || (field.identifier && !translation[field.identifier])) && field.isTranslatable"
                    [field]="field" [compound]="compound" [translation]="translation" class="col-1of1">
                </uc-language-field>
            </ng-template>
        </div>
    </uf-panel>

</uf-panel>