<div class="row grow center-all justify-center">
    <div class="uc-form-card">
        <div class="header">
            <h3>Unauthorized</h3>
        </div>
        <div class="grid">
            <div class="col-1of1">
                <p>
                    Sorry, you aren't authorized to view this page
                </p>
            </div>
        </div>
        <div class="row space-children pad">
            <a class="uf-button primary right" [routerLink]="['/login']">Go to login</a>
        </div>
    </div>
</div>