import { Subject } from 'rxjs';


export interface Dimension {
    width: number;
    height: number;
}

export interface ImageCrop extends Dimension {
    x: number;
    y: number;
}

export interface CropMeasures {
    cWidth: number;
    cHeight: number;
    iWidth: number;
    iHeight: number;
    factor: number;
}

interface CropperAction {
    type: null | 'move' | 'resize'; // move or resize
    active: boolean;
    originalX: number;
    originalY: number;
    originalOverlayX: number;
    originalOverlayY: number;
    originalOverlayWidth: number;
    originalOverlayHeight: number;
}

export class ImageCropper {

    cropStream: Subject<ImageCrop>;

    minWidth: number;
    minHeight: number;
    overlay: ImageCrop;
    ratio: number;
    measures: CropMeasures;

    private cropInitialValue?: ImageCrop;
    private size: Dimension;
    private context: CanvasRenderingContext2D;
    private action: CropperAction;

    constructor(
        private canvas: HTMLCanvasElement,
        private image: HTMLImageElement,
        private enabled: boolean,
        minWidth: number,
        minHeight: number,
        cropInitialValue?: ImageCrop,
        size?: Dimension
    ) {
        this.minWidth = Math.round(minWidth);
        this.minHeight = Math.round(minHeight);
        this.ratio = this.minHeight / this.minWidth;
        this.cropInitialValue = this.roundImageCrop(cropInitialValue);
        this.context = this.canvas.getContext('2d') as CanvasRenderingContext2D;
        this.setSize(size, true);

        if (this.enabled) {
            this.cropStream = new Subject<ImageCrop>();
            this.registerEventsHandler();
            this.setCrop(this.roundImageCrop(this.cropInitialValue), false);
        } else {
            this.render();
        }
    }

    setCrop(value?: ImageCrop, notify = true) {

        const previous = Object.assign({}, this.overlay);
        const next = this.checkCrop(value);

        if (this.enabled && !this.isSameCrop(previous, next as ImageCrop)) {
            this.overlay = next as ImageCrop;
            this.render();
            if (notify) {
                this.notifyCrop(previous, next as ImageCrop);
            }
            return this.overlay;
        }

        this.render();
        return null;
    }

    updateMeasures() {
        // choose the dimension to scale to, depending on which is "more too big"
        const canvasBox = this.canvas.getBoundingClientRect();
        const info: CropMeasures = {
            cWidth: canvasBox.width,
            cHeight: canvasBox.height,
            iWidth: this.image.width,
            iHeight: this.image.height,
            factor: 1
        };

        if ((info.cWidth - info.iWidth) < (info.cHeight - info.iHeight)) {
            // scale to width
            info.factor = info.cWidth / info.iWidth;
        } else {
            // scale to height
            info.factor = info.cHeight / info.iHeight;
        }

        this.measures = info;
    }

    registerEventsHandler() {
        this.action = {
            type: null, // move or resize
            active: false,
            originalX: 0,
            originalY: 0,
            originalOverlayX: 0,
            originalOverlayY: 0,
            originalOverlayWidth: 0,
            originalOverlayHeight: 0
        };

        this.canvas.onmousedown = (event) => {
            this.clickOnCanvas(event);
        };
        this.canvas.onmousemove = (event) => {
            this.moveOverCanvas(event);
        };
        this.canvas.onmouseup = () => {
            this.releaseOnCanvas();
        };
        this.canvas.onmouseout = () => {
            this.releaseOnCanvas();
        };
    }

    // Events Handlers
    clickOnCanvas(event: MouseEvent) {
        const pos = this.getCanvasMouseCoords(event);

        if (!this.overlay) {
            // Init cropping
            this.setCrop(this.roundImageCrop({
                x: Math.max((pos.x / this.measures.factor - this.minWidth / 2), 0),
                y: Math.max((pos.y / this.measures.factor - this.minHeight / 2), 0),
                width: this.minWidth,
                height: this.minHeight
            }));
        } else {
            // Register dragging start
            if (this.isInOverlay(pos.x, pos.y)) {
                // As move action
                this.action.type = 'move';
                this.action.active = true;
                this.action.originalOverlayX = pos.x / this.measures.factor - this.overlay.x;
                this.action.originalOverlayY = pos.y / this.measures.factor - this.overlay.y;
            }
            if (this.isInHandle(pos.x, pos.y)) {
                // As resize action
                this.action.type = 'resize';
                this.action.active = true;
                this.action.originalX = pos.x / this.measures.factor;
                this.action.originalY = pos.y / this.measures.factor;
                this.action.originalOverlayWidth = this.overlay.width;
                this.action.originalOverlayHeight = this.overlay.height;
            }
        }
    }

    moveOverCanvas(event: MouseEvent) {
        const pos = this.getCanvasMouseCoords(event);

        this.updateCursorStyle(pos);
        const x = pos.x / this.measures.factor;
        const y = pos.y / this.measures.factor;

        // give up if there is no this.action in progress
        if (this.action.active) {

            const previousCrop = Object.assign({}, this.overlay);
            // check what type of this.action to do
            if (this.action.type === 'move') {
                this.overlay.x = x - this.action.originalOverlayX;
                this.overlay.y = y - this.action.originalOverlayY;

                // Limit to size of the image.
                const xMax = this.measures.iWidth - this.overlay.width;
                const yMax = this.measures.iHeight - this.overlay.height;

                if (this.overlay.x < 0) {
                    this.overlay.x = 0;
                } else if (this.overlay.x > xMax) {
                    this.overlay.x = xMax;
                }

                if (this.overlay.y < 0) {
                    this.overlay.y = 0;
                } else if (this.overlay.y > yMax) {
                    this.overlay.y = yMax;
                }

                this.render();
            } else if (this.action.type === 'resize') {
                // Refresh overlay width (crop resize is based on width)
                this.overlay.width = this.action.originalOverlayWidth + (x - this.action.originalX);
                // Don't allow the overlay to get too small
                if (this.overlay.width < this.minWidth) {
                    this.overlay.width = this.minWidth;
                }
                // Compute crop resize height based on the width and ratio
                this.overlay.height = this.overlay.width * this.ratio;
                // Compute right (width) boundary and check if overflowed
                const right = Math.round(this.overlay.x + this.overlay.width);
                const rightCap = right >= this.measures.iWidth;
                // Compute bottom (height) boundary and check if overflowed
                const bottom = Math.round(this.overlay.y + this.overlay.height);
                const bottomCap = bottom >= this.measures.iHeight;

                // Fix resize in case of overflow
                if (rightCap) {
                    // force limit width and resize height accordingly
                    this.overlay.width = (this.measures.iWidth - this.overlay.x);
                    this.overlay.height = this.overlay.width * this.ratio;
                } else if (bottomCap) {
                    // force limit height and resize width accordingly
                    this.overlay.height = (this.measures.iHeight - this.overlay.y);
                    this.overlay.width = this.overlay.height / this.ratio;
                }
                // Refresh the overlay render on canvas
                this.render();
            }
            // Notify new crop size upon move or resize action
            this.notifyCrop(previousCrop, this.roundImageCrop(this.overlay) as ImageCrop);
        }// active
    }

    releaseOnCanvas() {
        this.action.active = false;
    }

    updateCursorStyle(pos: { x: number; y: number }) {
        // Set current cursor as appropriate
        if (this.overlay) {
            if (this.isInHandle(pos.x, pos.y) || (this.action.active && this.action.type === 'resize')) {
                this.canvas.style.cursor = 'nwse-resize';
            } else if (this.isInOverlay(pos.x, pos.y)) {
                this.canvas.style.cursor = 'move';
            } else {
                this.canvas.style.cursor = 'auto';
            }
        }
    }

    checkCrop(value?: ImageCrop) {

        if (!value) {
            return;
        }

        const checked = Object.assign({}, value);

        if (checked != null && checked.x + checked.width <= this.measures.iWidth && checked.y + checked.height <= this.measures.iHeight) {
            checked.x = Math.round(checked.x);
            checked.y = Math.round(checked.y);
            checked.width = Math.round(checked.width);
            checked.height = Math.round(checked.height);
            return checked;
        }

        return null;
    }

    isInOverlay(x: number, y: number) {
        const cOverlay = this.getCanvasOverlay();
        return x > cOverlay.x &&
            x < (cOverlay.x + cOverlay.width) &&
            y > cOverlay.y &&
            y < (cOverlay.y + cOverlay.height);
    }

    isInHandle(x: number, y: number) {
        const cOverlay = this.getCanvasOverlay();
        return x > (cOverlay.x + cOverlay.width - 10) &&
            x < (cOverlay.x + cOverlay.width + 10) &&
            y > (cOverlay.y + cOverlay.height - 10) &&
            y < (cOverlay.y + cOverlay.height + 10);
    }

    render() {
        this.context.save();
        // clear the canvas
        this.context.clearRect(0, 0, this.measures.cWidth, this.measures.cHeight);
        // draw the image
        this.context.drawImage(this.image, 0, 0, this.measures.cWidth, this.measures.cHeight);

        this.context.restore();

        // optionally draw the cropping area and resize icon
        this.renderOverlay();
    }

    renderOverlay() {
        if (this.overlay) {
            const cOverlay = this.getCanvasOverlay();
            // draw the overlay using a path made of 4 trapeziums
            this.context.save();
            this.context.fillStyle = 'rgba(0, 0, 0, 0.7)';
            this.context.beginPath();

            this.context.moveTo(0, 0);
            this.context.lineTo(cOverlay.x, cOverlay.y);
            this.context.lineTo(cOverlay.x + cOverlay.width, cOverlay.y);
            this.context.lineTo(this.canvas.width, 0);

            this.context.moveTo(this.canvas.width, 0);
            this.context.lineTo(cOverlay.x + cOverlay.width, cOverlay.y);
            this.context.lineTo(cOverlay.x + cOverlay.width, cOverlay.y + cOverlay.height);
            this.context.lineTo(this.canvas.width, this.canvas.height);

            this.context.moveTo(this.canvas.width, this.canvas.height);
            this.context.lineTo(cOverlay.x + cOverlay.width, cOverlay.y + cOverlay.height);
            this.context.lineTo(cOverlay.x, cOverlay.y + cOverlay.height);
            this.context.lineTo(0, this.canvas.height);

            this.context.moveTo(0, this.canvas.height);
            this.context.lineTo(cOverlay.x, cOverlay.y + cOverlay.height);
            this.context.lineTo(cOverlay.x, cOverlay.y);
            this.context.lineTo(0, 0);

            this.context.fill();
            this.context.restore();

            // draw the resize corner
            const x = cOverlay.x + cOverlay.width - 5;
            const y = cOverlay.y + cOverlay.height - 5;
            const w = 10;
            const h = 10;

            this.context.save();
            this.context.fillStyle = '#000000';
            this.context.strokeStyle = '#ffffff';
            this.context.fillRect(x, y, w, h);
            this.context.strokeRect(x, y, w, h);
            this.context.restore();
        }
    }

    getCanvasMouseCoords(event: MouseEvent) {
        const rect = this.canvas.getBoundingClientRect();
        return { x: event.clientX - rect.left, y: event.clientY - rect.top };
    }

    isSameCrop(a?: ImageCrop, b?: ImageCrop) {
        return (a == null && b == null) ||
            (a != null && b != null && a.x === b.x && a.y === b.y && a.width === b.width && a.height === b.height);
    }

    setSize(size?: Dimension, skipRender?: boolean) {
        this.size = (size && size.width > 0 && size.height > 0) ? size : {
            width: this.image.width,
            height: this.image.height
        };
        this.canvas.width = this.size.width;
        this.canvas.height = this.size.height;
        this.updateMeasures();
        if (!skipRender) {
            this.render();
        }
    }

    roundImageCrop(imageCrop?: ImageCrop) {
        if (imageCrop) {
            imageCrop.x = Math.round(imageCrop.x);
            imageCrop.y = Math.round(imageCrop.y);
            imageCrop.width = Math.round(imageCrop.width);
            imageCrop.height = Math.round(imageCrop.height);
            if (this.measures) {
                imageCrop.width = Math.min(imageCrop.width, this.measures.iWidth - imageCrop.x);
                imageCrop.height = Math.min(imageCrop.height, this.measures.iHeight - imageCrop.y);
            }
        }
        return imageCrop;
    }

    private notifyCrop(previous: ImageCrop, next: ImageCrop): void {
        if (!this.isSameCrop(previous, next)) {
            this.cropStream.next(next);
        }
    }

    private getCanvasOverlay() {
        return {
            x: Math.round(this.overlay.x * this.measures.factor),
            y: Math.round(this.overlay.y * this.measures.factor),
            width: Math.round(this.overlay.width * this.measures.factor),
            height: Math.round(this.overlay.height * this.measures.factor),
        };
    }
}
