import { Component, Input } from '@angular/core';
import { ModalService, UfControlArray, UfControlGroup } from '@unifii/library/common';

import { DataSourceExternalInputEditorComponent } from './data-source-external-input-editor.component';
import { DataSourceFormCtrl } from './data-source-form-ctrl';
import { DataSourceExternalInfo, DataSourceExternalInput, DataSourceExternalInputControlKey } from './data-source-model';


@Component({
    selector: 'uc-data-source-external-inputs',
    templateUrl: './data-source-external-inputs.html'
})
export class DataSourceExternalInputsComponent {

    @Input() externalInputs: UfControlArray;

    readonly externalInputsKeys = DataSourceExternalInputControlKey;

    constructor(private formCtrl: DataSourceFormCtrl, private modalService: ModalService) { }

    getInfo(index: number): DataSourceExternalInfo {
        return this.externalInputs.at(index).get(DataSourceExternalInputControlKey.Info)?.getRawValue() as DataSourceExternalInfo;
    }

    async edit(index: number) {
        const originalControl = this.externalInputs.at(index) as UfControlGroup;
        const editControl = this.formCtrl.buildExternalInputControl(originalControl.getRawValue() as DataSourceExternalInput);
        this.externalInputs.setControl(index, editControl);
        this.externalInputs.updateDependencies();
        this.externalInputs.updateValueAndValidity();

        const result = await this.modalService.openLarge<UfControlGroup, UfControlGroup>(
            DataSourceExternalInputEditorComponent,
            editControl,
            { guard: true }
        );

        if (!result) {
            this.externalInputs.setControl(index, originalControl);
            this.externalInputs.updateDependencies();
            this.externalInputs.updateValueAndValidity();
        }
    }

}