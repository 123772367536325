import { Component, EventEmitter, Input, Output } from '@angular/core';
import { HierarchyUnit, HierarchyUnitState, HierarchyUnitWithChildCount } from '@unifii/sdk';

import { ToolTipPosition } from 'directives/tooltip.directive';

import { HierarchyFinderComponent } from './hierarchy-finder.component';


export interface FinderUnitChangeData {
    unit: HierarchyUnitWithChildCount;
    action: FinderContextMenu;
}

export enum FinderContextMenu {
    Update,
    Delete,
    Activate,
    Deactivate
}

interface ContextOption {
    label: string;
    action: FinderContextMenu;
    predicate?: (unit: HierarchyUnit) => boolean;
}

@Component({
    selector: 'uc-hierarchy-finder-column',
    templateUrl: './hierarchy-finder-column.html',
    styleUrls: ['./hierarchy-finder-column.less']
})
export class HierarchyFinderColumnComponent {

    @Input() disabled: boolean;
    @Input() label?: string;
    @Input() parentId?: string;
    @Input() selected?: HierarchyUnitWithChildCount;
    @Input() hideEditButton: boolean;
    @Input() units: HierarchyUnitWithChildCount[];

    @Output() selectUnit = new EventEmitter<HierarchyUnitWithChildCount>();
    @Output() updateGroupLabel = new EventEmitter<void>();
    @Output() finderUnitChange = new EventEmitter<FinderUnitChangeData>();
    @Output() add = new EventEmitter<void>();

    protected readonly contextOptions: ContextOption[] = [
        { label: 'Edit', action: FinderContextMenu.Update },
        { label: 'Delete', action: FinderContextMenu.Delete, predicate: (unit: HierarchyUnitWithChildCount) => this.hierarchyFinder.showDeleteContextMenu(unit) },
        { label: 'Activate', action: FinderContextMenu.Activate, predicate: (unit: HierarchyUnitWithChildCount) => this.hierarchyFinder.showActivateContextMenu(unit) },
        { label: 'Deactivate', action: FinderContextMenu.Deactivate, predicate: (unit: HierarchyUnitWithChildCount) => unit.state !== HierarchyUnitState.Inactive },
    ];

    protected readonly tooltipPosition = ToolTipPosition.Bottom;

    constructor(
        private hierarchyFinder: HierarchyFinderComponent
    ) { }

    protected get showEditColumnButton() {
        return !this.disabled && !this.hideEditButton;
    }

    protected isInactive(unit: HierarchyUnitWithChildCount): boolean {
        return unit.state === HierarchyUnitState.Inactive;
    }

    protected mapLabel(unit: HierarchyUnitWithChildCount) {
        return !!unit.id ? `${unit.label} (${unit.id})` : unit.label;
    }

    protected executeContextOption(unit: HierarchyUnitWithChildCount, contextOption?: ContextOption) {
        if (!contextOption) {
            return;
        }
        const { action } = contextOption;

        this.finderUnitChange.emit({
            action,
            unit
        });
    }

}