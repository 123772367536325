import { Component, HostBinding } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Breadcrumb } from '@unifii/library/common';

import { UcTable } from 'client';

import { EditData } from 'components/common/edit-data';
import { Approve, SaveAndClose } from 'components/common/save-options/save-options.component';
import { BuilderHeaderService } from 'components/compound-builder/builder-header/builder-header.service';

import { PUBLISH_STATE_CLASS_DICTIONARY } from 'helpers/css-class-helper';

import { BreadcrumbService } from 'services/breadcrumb.service';

import { TableInfo } from './table-resolver';


@Component({
    templateUrl: './table.html'
})
export class TableComponent implements EditData {

    @HostBinding('class.stretch-component') class = true;

    readonly publishStateClassDictionary = PUBLISH_STATE_CLASS_DICTIONARY;

    info: TableInfo;

    private breadcrumbs: Breadcrumb[];

    constructor(
        private route: ActivatedRoute,
        private breadcrumbService: BreadcrumbService,
        private builderHeaderService: BuilderHeaderService
    ) {
        this.info = this.route.snapshot.data.info;
        this.builderHeaderService.init();
        this.buildHeaderConfiguration(this.info.table);
    }

    get edited() {
        return !!this.builderHeaderService.config.edited;
    }

    set edited(v: boolean) {
        this.builderHeaderService.config.edited = v;
    }

    updateTable(table: UcTable) {
        this.info.table = table;

        this.buildHeaderConfiguration(table);
    }

    private buildHeaderConfiguration(table: UcTable) {
        this.breadcrumbs = this.breadcrumbService.getBreadcrumbs(this.route, [table.title || 'New']);
        this.builderHeaderService.buildConfig({
            ...table,
            edited: this.edited,
            breadcrumbs: this.breadcrumbs,
            saveOptions: [SaveAndClose, Approve],
            cancelRoute: ['../']
        });
    }

}