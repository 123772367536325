<uf-expander *ngIf="ready" class="uf-box flat">
    <div expanderHeader class="uf-app-bar flat">
        <div class="title primary">Details</div>
        <a *ngIf="!description" (click)="editDescription()" title="Add description" class="uf-action tertiary">
            <uf-icon name="notes"></uf-icon>
        </a>
        <ng-template [ngIf]="isInvalid" [ngIfElse]="isValid">
            <uf-icon name="error" class="error"></uf-icon>
        </ng-template>
        <ng-template #isValid>
            <a *ngIf="meta.customFields" (click)="save()" title="Save as Custom Field" class="uf-action tertiary">
                <uf-icon name="save"></uf-icon>
            </a>
        </ng-template>
        <ng-template [ngIf]="control.valid">
            <a (click)="copy()" title="Copy field" class="uf-action tertiary">
                <uf-icon name="copy"></uf-icon>
            </a>
            <a *ngIf="meta.isContainer" (click)="paste()" title="Paste fields" class="uf-action tertiary">
                <uf-icon name="paste"></uf-icon>
            </a>
        </ng-template>

    </div>
    <div expanderBody [formGroup]="control" class="uc-group-content grid">

        <uf-message *ngIf="description" icon="infoSolid" [content]="description" [actions]="descriptionActions"
            [showExpander]="false" class="col-1of1 info">
        </uf-message>

        <uf-textarea *ngIf="meta.label" [formControlName]="fieldKeys.Label" label="Label" class="col-1of1">
        </uf-textarea>

        <uf-text *ngIf="meta.shortLabel" [formControlName]="fieldKeys.ShortLabel" label="Short label" class="col-1of1">
        </uf-text>

        <uf-text *ngIf="meta.identifier" [formControlName]="fieldKeys.Identifier" label="Identifier" class="col-1of1"
            [maxLength]="identifierMaxLength">
        </uf-text>
        <uf-message *ngIf="showIdentifierWarningLength" class="col-1of1 x-small warning" icon="warningSolid">
            <p>Identifier is too long</p>
        </uf-message>

        <uc-markdown *ngIf="showContent" label="Content" [formControlName]="fieldKeys.Help"
            [showAlignmentOptions]="true" class="col-1of1">
        </uc-markdown>

        <uf-select *ngIf="meta.template" [formControlName]="fieldKeys.Template" valueProperty="identifier"
            nameProperty="name" [options]="templateOptions" label="Template" class="col-1of1">
        </uf-select>

        <div class="col-1of1 col">
            <uf-checkbox *ngIf="meta.alwaysExpanded" [formControlName]="fieldKeys.AlwaysExpanded"
                label="Always Expanded"></uf-checkbox>

            <uf-checkbox *ngIf="meta.collapseWhenInactive" [formControlName]="fieldKeys.CollapseWhenInactive"
                label="Collapse in Inactive Sections"></uf-checkbox>

            <uf-checkbox *ngIf="meta.hideWhenInactive" [formControlName]="fieldKeys.HideWhenInactive"
                label="Hide in Inactive Sections"></uf-checkbox>

            <uf-checkbox *ngIf="meta.activeBackgroundTinted" [formControlName]="fieldKeys.ActiveBackgroundTinted"
                label="Background Tinted in Active Sections"></uf-checkbox>
        </div>

        <uf-chips *ngIf="meta.role" (searchChange)="findRoles($event)" [options]="rolesResult"
            [formControlName]="fieldKeys.Roles" label="Roles" class="col-1of1">
        </uf-chips>

    </div>
</uf-expander>