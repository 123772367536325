import { Component } from '@angular/core';
import { Breadcrumb } from '@unifii/library/common';

import { FormBucketService } from 'pages/form-data/bucket-service';
import { TabsPage } from 'pages/tabs-page';

import { BreadcrumbService } from 'services/breadcrumb.service';
import { ContextService } from 'services/context.service';


/** Route non-reusable component, see ConsoleRouteReuseStrategy */
@Component({
    templateUrl: 'bucket.html',
    providers: [FormBucketService]
})
export class BucketComponent extends TabsPage {

    breadcrumbs: Breadcrumb[];

    constructor(
        public context: ContextService,
        private bucketService: FormBucketService,
        private breadcrumbService: BreadcrumbService
    ) {
        super();
        this.init();
    }

    private async init() {
        const { schema, dataDescriptor } = this.route.snapshot.data.bucketConfig;
        this.bucketService.schema = schema;
        this.bucketService.dataDescriptor = dataDescriptor;
        this.breadcrumbs = this.breadcrumbService.getBreadcrumbs(this.route, [schema.id]);
    }
}
