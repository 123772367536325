<ng-container *ngIf="ready && field">
    <li (click)="select($event)" class="uc-list-item--draggable--no-shrink--small row center-all"
        [class.selected]="selected">
        <uf-icon [name]="iconName" />
        <div class="node-label gap-left grow">{{ label }}</div>
        <uf-icon *ngIf="!valid" name="warning" class="icon-warning" />
        <a (click)="remove($event)" title="Delete" class="uf-action tertiary">
            <uf-icon name="delete" />
        </a>
    </li>
</ng-container>