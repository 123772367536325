import { debounceTime } from 'rxjs/operators';

import { ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, Optional, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import {
    ExpandersService, fieldIterator, ModalService, RuntimeDefinition, RuntimeDefinitionAdapter, ToastService, UfControl, UfControlGroup,
    ValidatorFunctions
} from '@unifii/library/common';
import { FormConfiguration, FormSettings } from '@unifii/library/smart-forms';
import { DisplayService } from '@unifii/library/smart-forms/display';
import { FormComponentRegistry, InputFormSettings, UfFormComponent } from '@unifii/library/smart-forms/input';
import { CompoundType, Definition, FieldType } from '@unifii/sdk';

import { CompoundInfo, SystemRole, UcCompound, UcDefinition } from 'client';

import { ContentSettings, EditMode, SaveOption } from 'components';
import { BuilderBasic } from 'components/compound-builder/builder-basic';
import { BuilderHeaderService } from 'components/compound-builder/builder-header/builder-header.service';
import { BuilderCompoundSubjects } from 'components/compound-builder/builder-models';
import { BuilderEventInfo, BuilderService } from 'components/compound-builder/builder.service';
import { MarkdownEditorRegistry } from 'components/markdown-editor-registry';

import { CollectionService } from 'pages/content/collections/collection-service';

import { BreadcrumbService } from 'services/breadcrumb.service';
import { UcTableManager } from 'services/table/models';


@Component({
    templateUrl: './collection-item-builder.html',
    styleUrls: ['./collection-item-builder.less'],
    providers: [
        BuilderService,
        ExpandersService,
        { provide: ContentSettings, useValue: {} },
        { provide: FormSettings, useClass: InputFormSettings },
        { provide: FormComponentRegistry, useClass: MarkdownEditorRegistry },
    ]
})
export class CollectionItemBuilderComponent extends BuilderBasic implements OnInit, OnDestroy {

    @ViewChild('form', { static: true }) form: ElementRef;

    type = CompoundType.Collection;
    subject = BuilderCompoundSubjects.CONTENT;

    protected config: FormConfiguration = { hideLabel: true };
    protected ready: boolean;
    protected rootControl: UfControlGroup;
    protected definition: UcDefinition;
    protected displayDefinition: RuntimeDefinition;
    protected displayCompound?: UcCompound;
    protected compound: UcCompound;

    private recordNameCtrl = new UfControl(ValidatorFunctions.required('This field is mandatory'));
    private titleCtrl = new UfControl(ValidatorFunctions.required('This field is mandatory'));
    private title: string;

    constructor(
        builderService: BuilderService,
        modalService: ModalService,
        protected router: Router,
        protected route: ActivatedRoute,
        private service: CollectionService,
        private toastService: ToastService,
        private displayService: DisplayService,
        private cdr: ChangeDetectorRef,
        private breadcrumbService: BreadcrumbService,
        public expandersService: ExpandersService,
        private builderHeaderService: BuilderHeaderService,
        private runtimeDefinitionAdapter: RuntimeDefinitionAdapter,
        @Optional() @Inject(TableContainerManager) protected tableManager: UcTableManager<CompoundInfo>
    ) {
        super(builderService, modalService, route, tableManager);
    }

    async ngOnInit() {

        // wait for collection service to finish loading
        await this.service.definitionLoadPromise;

        this.definition = this.service.definition;
        this.definition.settings = Object.assign(this.definition.settings ? this.definition.settings : {}, { inputStyle: 'small' });
        this.displayDefinition = await this.runtimeDefinitionAdapter.transform(this.definition as Definition);

        // Load data
        this.compound = await this.load();
        this.updateTitle();

        // Register event listeners
        this.addSubscribers();

        // Init builder service
        this.builderService.init(this, this.definition, this.compound);

        this.updatePreview();

        // Automatically update recordName with title value if new entry
        if (!Object.keys(this.compound || {}).length) {
            this.subscriptions.add(this.titleCtrl.valueChanges
                .subscribe(v => {
                    if (!this.recordNameCtrl.touched) {
                        this.recordNameCtrl.setValue(v, { onlySelf: false, emitEvent: true });
                    }
                }));
        }

        this.builderHeaderService.init();
        this.subscriptions.add(this.builderHeaderService.saveClicked.subscribe(saveOption => this.save(saveOption)));
        this.buildHeaderConfig(this.compound);
    }

    @ViewChild(UfFormComponent, { static: false }) set ufForm(form: UfFormComponent) {

        /** Because of ready bool form will be undefined until ready changes */
        if (form == null || this.rootControl) {
            return;
        }

        form.rootControl.setControl('_recordName', this.recordNameCtrl);
        form.rootControl.setControl('_title', this.titleCtrl);
        this.rootControl = form.rootControl;

        this.subscriptions.add(this.rootControl.valueChanges.pipe(debounceTime(500)).subscribe(() => {
            if (form.rootControl.dirty) {
                this.saveStatus();
            }
            this.updatePreview();
        }));

        this.cdr.detectChanges();
    }

    addSubscribers() {
        this.subscriptions.add(this.builderService.ready.subscribe(() => {
            this.saveStatus();
            this.builderService.memento.edited = false;
            this.ready = true;
        }));

        this.subscriptions.add(this.builderService.fieldEdit.subscribe(i => {
            this.saveStatus(i);
            this.builderService.fieldEdited.next(i);
        }));
    }

    async save(saveOption?: SaveOption) {

        // Notify the other components of a builder save/submit action
        // this.builderService.submit.next(null); // TODO Why different from the other buidlers
        this.rootControl.setSubmitted();

        if (this.rootControl.invalid) {
            this.toastService.error('Unable to save. There are errors in your Collection.');
            return;
        }

        if (this.editMode === EditMode.New || this.editMode === EditMode.Duplicate) {
            /**
             * Form component creates id on init
             * this needs to be removed for new entries
             */
            delete this.compound.id;
        }

        this.rootControl.markAsPristine();

        // Trim Website fields value
        if (this.definition.fields) {
            for (const entry of fieldIterator(this.definition.fields)) {
                if (entry.field.type === FieldType.Website && entry.field.identifier && this.compound[entry.field.identifier]) {
                    this.compound[entry.field.identifier] = (this.compound[entry.field.identifier] as string).trim();
                }
            }
        }

        // Save the compound
        try {
            this.builderService.busy.next(true);
            this.compound = await this.service.ucCollection.saveItem(this.compound);

            // Notify user of the save success
            this.toastService.success('Collection saved');

            this.saved(this.compound, saveOption);
            this.updateTitle();
            this.builderService.init(this, this.definition, this.compound);
            this.buildHeaderConfig(this.compound);
        } catch (err) {
            const message = err.message || (err.data && err.data.message) ? err.data.message : 'Oops... something went wrong with saving your form';
            this.toastService.error(message);
        } finally {
            this.builderService.busy.next(false);
        }
    }

    private async updatePreview() {

        /** Definition without LinkList fields doesn't need DisplayService */
        if (this.displayDefinition.fields.find(field => field.type === FieldType.LinkList) == null) {
            this.displayCompound = this.compound;
            return;
        }

        /** LinkList fields need to be converted to Compounds before rendered */
        const displayContent = await this.displayService.renderCompound(this.displayDefinition, this.compound);
        if (!displayContent.definition || !displayContent.compound) {
            this.displayCompound = undefined;
            return;
        }

        this.displayDefinition = displayContent.definition;
        this.displayCompound = displayContent.compound;
    }

    private async load(): Promise<UcCompound> {

        if (this.editMode === EditMode.New) {
            return {} as UcCompound;
        }

        const compound = await this.service.ucCollection.getItem(+this.route.snapshot.params.id);

        if (this.editMode === EditMode.Existing) {
            return compound;
        }

        delete compound.id;
        compound.recordName += ' COPY';
        compound._title += ' COPY';
        return compound;
    }

    private saveStatus(i: BuilderEventInfo = { subject: null, atomic: true }) {
        this.builderService.memento.save(this.builderService.compound, i.atomic);
        this.builderService.memento.edited = true;
        if (this.ready) {
            this.builderHeaderService.config.edited = true;
        }
    }

    private buildHeaderConfig(definition: UcCompound) {
        this.builderHeaderService.buildConfig({
            ...definition,
            title: this.title,
            breadcrumbs: this.breadcrumbService.getBreadcrumbs(this.route, [this.builderService.definition?.label || '', this.title]),
            publishState: definition.publishState,
            saveOptions: this.saveOptions,
            restrictSave: SystemRole.ContentEditor,
            cancelRoute: ['../'],
        });
    }

    private updateTitle() {
        this.title = this.editMode === EditMode.Existing ?
            this.compound.recordName || '' :
            'New';
    }
}
