import { Subject } from 'rxjs';

import { Inject, Injectable } from '@angular/core';
import {
    Client, ClientDeleteOptions, ClientGetOptions, ClientPatchOptions, ClientPostOptions, ClientPutOptions, Company, FileType, Language, mergeParams,
    PasswordDetails, TokenStorage, UserInfo
} from '@unifii/sdk';

import { Config } from 'app-config';
import { IntegrationProvider, IntegrationProviderInfo, MyAccount, ProjectTranslations, TenantSettings, UcProjectInfo } from 'client';
import { DefaultPaginationParams } from 'constant';

import { TokenService } from './token.service';


@Injectable()
export class UcClient {

    inProgress = new Subject<boolean>();

    constructor(
        @Inject(Config) private config: Config,
        private client: Client,
        @Inject(TokenStorage) private tokenStorage: TokenService
    ) {
        this.client.start = this.start;
        this.client.end = this.end;
    }

    start = () => {
        this.inProgress.next(true);
    };

    end = () => {
        this.inProgress.next(false);
    };

    authenticate(username: string, password: string): Promise<any> {
        return this.client.authenticate(username, password);
    }

    deauthenticate() {
        this.tokenStorage.clear();
    }

    getMyAccount(options?: ClientGetOptions): Promise<MyAccount> {
        return this.get(this.buildUrl(['my-account']), options);
    }

    updateMyPassword(passwordDetails: PasswordDetails, options?: ClientPatchOptions): Promise<MyAccount> {
        return this.patch(this.buildUrl(['my-account']), passwordDetails, options);
    }

    getMe(options?: ClientGetOptions): Promise<UserInfo> {
        return this.get(this.buildUrl(['me']), options);
    }

    getSettings(options?: ClientGetOptions): Promise<TenantSettings> {
        return this.get(this.buildUrl(['settings']), { ...options, anonymous: true });
    }

    getFileTypes(options?: ClientGetOptions): Promise<FileType[]> {
        return this.get(this.buildUrl(['file-types']), options);
    }

    getProjects(options?: ClientGetOptions): Promise<UcProjectInfo[]> {
        return this.get(this.buildUrl(['projects']), options);
    }

    addProject(name: string, options?: ClientPostOptions): Promise<UcProjectInfo> {
        return this.post(this.buildUrl(['projects']), { ...options, body: { name } });
    }

    getLanguages(options?: ClientGetOptions): Promise<Language[]> {
        return this.get(this.buildUrl(['languages']), options);
    }

    getProjectsTranslations(options?: ClientGetOptions): Promise<ProjectTranslations[]> {
        return this.get(this.buildUrl(['projects/translations']), options);
    }

    updateSettings(settings: TenantSettings, options?: ClientPutOptions): Promise<TenantSettings> {
        return this.put(this.buildUrl(['settings']), settings, options);
    }

    getClaims(q?: string, options?: ClientGetOptions): Promise<string[]> {
        return this.get(this.buildUrl(['claims']), { ...options, params: mergeParams(options?.params, { q }) });
    }

    getAvailableIntegrations(q?: string, options?: ClientGetOptions): Promise<IntegrationProviderInfo[]> {
        const params = mergeParams(DefaultPaginationParams, options?.params, { q });
        return this.get(this.buildUrl(['available-integrations']), { ...options, params });
    }

    getAvailableIntegration(id: string, options?: ClientGetOptions): Promise<IntegrationProvider> {
        return this.get(this.buildUrl(['available-integrations', id]), options);
    }

    testAvailableIntegration(id: string, data: any, options?: ClientPostOptions): Promise<boolean> {
        return this.post(this.buildUrl(['available-integrations', id, 'connection-test']), { ...options, body: data });
    }

    getCompanies(q?: string, options?: ClientGetOptions): Promise<Company[]> {
        const params = mergeParams(DefaultPaginationParams, options?.params, { q });
        return this.get(this.buildUrl(['companies']), { ...options, params });
    }


    /**
     * SDK Extesions
     */

    get(url: string, options?: ClientGetOptions): Promise<any> {
        return this.client.get(url, options);
    }

    put(url: string, body: any, options?: ClientPutOptions): Promise<any> {
        return this.client.put(url, body, options);
    }

    post(url: string, options?: ClientPostOptions): Promise<any> {
        return this.client.post(url, options);
    }

    patch(url: string, body: any, options?: ClientPatchOptions): Promise<any> {
        return this.client.patch(url, body, options);
    }

    delete(url: string, options?: ClientDeleteOptions): Promise<any> {
        return this.client.delete(url, options);
    }

    buildUrl(parts: any[], addApi = true): string {

        parts = parts.map(p => encodeURIComponent(p));

        if (addApi) {
            parts.unshift('api');
        }
        parts.unshift(this.config.baseUrl);

        return parts.join('/');
    }
}
