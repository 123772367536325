import { Injectable } from '@angular/core';
import { FilterEntry, FilterType } from '@unifii/library/common';
import { Option, TableSourceType } from '@unifii/sdk';

import { UcProject } from 'client';
import { BOOL_OPTIONS } from 'constant';

import { CustomChoiceFilterEntry } from 'components';

import { PUBLISH_STATES } from 'helpers/publish-states-helper';

import { BucketLoader } from 'pages/workflows/bucket-loader';

import { TableSourceTypeLabelPipe } from 'pipes/table-source-type-label.pipe';

import { InfoType } from './models';


@Injectable({
    providedIn: 'root'
})
export class InfoFilterFactory {

    constructor(
        private tableSourceTypePipe: TableSourceTypeLabelPipe,
        private ucProject: UcProject
    ) { }

    create(type?: InfoType): FilterEntry[] {
        const filterEntries: (FilterEntry | CustomChoiceFilterEntry)[] = [
            {
                label: 'State',
                identifier: 'status',
                type: FilterType.Choice,
                emptyOption: { name: 'Active', identifier: 'all' },
                options: PUBLISH_STATES.map(ps => ({ name: ps.name, identifier: ps.value }))
            }
        ];

        if (type === InfoType.Table) {
            filterEntries.push({
                label: 'Type',
                identifier: 'type',
                type: FilterType.Choice,
                options: this.getTableSourceOptions()
            });

            filterEntries.push({
                identifier: 'source',
                label: 'Source',
                type: FilterType.DataSeed,
                loader: new BucketLoader(this.ucProject)
            });

            filterEntries.push({
                identifier: 'hasDetail',
                label: 'Detail Page',
                type: FilterType.Bool,
                options: BOOL_OPTIONS
            });
        }

        return filterEntries;
    }

    private getTableSourceOptions(): Option[] {
        return Object.keys(TableSourceType).map(v => ({
            name: this.tableSourceTypePipe.transform(v as TableSourceType),
            identifier: v
        }));
    }

}
