import { Component, HostBinding } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import { ModalService } from '@unifii/library/common';

import { UcClient } from 'client';

import { TypeSelectComponent, TypeSelectConfig, TypeSelectOption } from 'components/common/type-select.component';
import { SystemIntegrationsTableManager } from './system-integrations-table-manager';


@Component({
    template: `
        <uf-table-container ufSyncRoute ufMasterDetail (addItem)="addIntegration()" class="cards container"></uf-table-container>
        <router-outlet></router-outlet>
    `,
    providers: [
        { provide: TableContainerManager, useClass: SystemIntegrationsTableManager }
    ]
})
export class SystemIntegrationsComponent {

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private ucClient: UcClient,
        private modalService: ModalService
    ) { }

    async addIntegration() {

        const availableIntegrations = await this.ucClient.getAvailableIntegrations();
        const config: TypeSelectConfig = {
            title: 'Select Integration Provider',
            types: availableIntegrations.map(i => ({
                type: i.id,
                label: i.name,
                logo: i.logo || '/assets/svg/icon-integrations.svg'
            }))
        };

        const integration = await this.modalService.openMedium<TypeSelectConfig, TypeSelectOption>(TypeSelectComponent, config);

        if (integration) {
            this.router.navigate(['new'], { queryParams: { provider: integration.type }, relativeTo: this.route });
        }
    }

}
