import { Subscription } from 'rxjs';

import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ExpandersService, MessageLevel, StorageWrapper, UfControlArray, UfControlGroup, WindowWrapper } from '@unifii/library/common';
import { CompoundType, DefinitionPublishState, ErrorType, StructureNodeType } from '@unifii/sdk';

import { StructureService, SystemRole, UcStructure } from 'client';
import { STRUCTURE_STORAGE_KEY } from 'constant';

import { EditData } from 'components/common/edit-data';
import { Approve, SaveAndClose, SaveOption, SaveOptionType } from 'components/common/save-options/save-options.component';
import { BuilderHeaderService } from 'components/compound-builder/builder-header/builder-header.service';

import { PUBLISH_STATE_CLASS_DICTIONARY } from 'helpers/css-class-helper';

import { BreadcrumbService } from 'services/breadcrumb.service';
import { ContextService } from 'services/context.service';

import { STRUCTURE_CONSTANTS } from './structure-constants';
import { StructureNodeControlKeys } from './structure-control-keys';
import { StructureEditorCache } from './structure-editor-cache';
import { StructureEditorService } from './structure-editor.service';
import { flattenControls, StructureFormCtrl } from './structure-form-ctrl';
import { StructureFunctions } from './structure-functions';
import { ConsoleStructure } from './structure-model';
import { StructureStatus } from './structure-status';


@Component({
    selector: 'uc-structure',
    templateUrl: './structure.html',
    providers: [StructureStatus, StructureFormCtrl, StructureEditorService, ExpandersService, StructureEditorCache],
    styleUrls: ['./structure.less']
})
export class StructureComponent implements OnInit, OnDestroy, EditData {

    subscriptions: Subscription = new Subscription();
    saveOptions: SaveOption[] = [];

    breadcrumbs = this.breadcrumbService.getBreadcrumbs(this.route);

    readonly itemGroups = STRUCTURE_CONSTANTS.PICKER_GROUPS;
    readonly compoundTypes = CompoundType;
    readonly publishStateClassDictionary = PUBLISH_STATE_CLASS_DICTIONARY;

    private previewWindow?: Window | null;

    constructor(
        public status: StructureStatus,
        public service: StructureEditorService,
        private sfb: StructureFormCtrl,
        private ucStructure: StructureService,
        private context: ContextService,
        private router: Router,
        private route: ActivatedRoute,
        private cdr: ChangeDetectorRef,
        private breadcrumbService: BreadcrumbService,
        private builderHeaderService: BuilderHeaderService,
        @Inject(ExpandersService) private expanders: ExpandersService,
        @Inject(WindowWrapper) private window: Window,
        @Inject(StorageWrapper) private storage: StorageWrapper,
    ) { }

    async ngOnInit() {
        // Load data
        const structure = await this.loadStructure();
        this.setup(structure);
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    get form(): UfControlGroup {
        return this.status.root;
    }

    get disabled(): boolean {
        return this.status.root.disabled;
    }

    get children(): UfControlArray {
        return this.form.get(StructureNodeControlKeys.Children) as UfControlArray;
    }

    get structure(): ConsoleStructure {
        return this.form.value;
    }

    get edited(): boolean {
        return this.status.edited;
    }

    async onAction(saveOption?: SaveOption) {

        if (!this.isFormValid()) {
            return;
        }

        // Disable save button so it won't be pressed twice mid save
        this.builderHeaderService.config.disabledSave = true;

        try {
            this.cdr.detectChanges();
            let structure = this.getStructure();

            structure = await this.ucStructure.save(structure, saveOption?.id === SaveOptionType.Approved);

            this.status.edited = false;

            this.builderHeaderService.notify.next({
                message: 'Structure saved',
                level: MessageLevel.Success,
            });

            if (saveOption?.id === SaveOptionType.Close) {
                this.back();
            }

            this.updateStructureFormValue(structure);
            this.buildHeaderConfig(structure);
        } catch (e) {
            console.error(e);
            if (e.type === ErrorType.Server || e.type === ErrorType.Conflict) {
                this.builderHeaderService.notify.next({
                    message: 'Someone else modified the structure, please refresh the page to continue',
                    level: MessageLevel.Error,
                });
            } else {
                this.builderHeaderService.notify.next({
                    message: 'An unexpected error occured',
                    level: MessageLevel.Error,
                });
            }
        } finally {
            this.builderHeaderService.config.disabledSave = false;
            this.cdr.detectChanges();
        }
    }

    toggleExpanders(expand: boolean, list: HTMLElement) {
        if (expand) {
            this.expanders.expandAll(list);
            return;
        }
        this.expanders.collapseAll(list);
    }

    /**
     * todo: UNIFII-4805
     */
    openPreview() {
        const structure = this.getStructure();
        this.storage.setItem(STRUCTURE_STORAGE_KEY, structure);

        if (this.previewWindow?.closed) {
            this.previewWindow.focus();
            return;
        }
        /** todo: create component and route  */
        const url = `${this.window.location}/structure-preview`;
        const openFn = this.window.open;
        if (openFn != null) {
            this.previewWindow = openFn(url, 'StructurePreview');
        }
    }

    filterStructureNodeControls(control: UfControlGroup): boolean {
        return StructureFunctions.isANodeControl(control);
    }

    private async loadStructure(): Promise<UcStructure> {
        try {
            return await this.ucStructure.get();
        } catch (e) {
            return {
                rev: undefined as any,
                children: [],
                type: StructureNodeType.Empty,
                nodeId: '0',
                lastNodeId: '0',
                structurePublishState: DefinitionPublishState.Draft
            };
        }
    }

    private back() {
        if (this.router) {
            this.router.navigate(['../'], { relativeTo: this.route });
        }
    }

    private getFirstSelectableNode(): UfControlGroup | null {
        if (!this.form) {
            return null;
        }

        if (this.form.get(StructureNodeControlKeys.Type)?.value !== StructureNodeType.Empty) {
            return this.form;
        }

        if (this.children.length) {
            return this.children.controls[0] as UfControlGroup;
        }

        return null;
    }

    private updateSaveActions() {
        const canPublish = this.context.checkRoles(SystemRole.Publisher);

        this.saveOptions = [SaveAndClose];

        if (canPublish) {
            this.saveOptions.push(Approve);
        }
    }

    private getStructure(): UcStructure {
        const controlStructure: ConsoleStructure = this.form.getRawValue();
        const structure = this.sfb.mapControlValueToData(controlStructure);
        StructureFunctions.removeUnvalorizedAttributes(structure);
        return structure;
    }

    private setup(structure: UcStructure) {
        this.subscriptions.add(this.service.fieldDeselected.subscribe(() => {
            this.cdr.detectChanges();
        }));
        this.subscriptions.add(this.service.fieldSelected.subscribe(() => {
            this.cdr.detectChanges();
        }));

        // Build root control
        const rootControl = this.sfb.buildRoot(structure);

        if (!this.context.checkRoles(SystemRole.ProjectManager, SystemRole.ContentEditor)) {
            rootControl.disable();
        }

        this.status.root = rootControl;

        this.subscriptions.add(this.form.valueChanges.subscribe(v => {
            this.status.edited = true;
            this.builderHeaderService.config.edited = true;
        }));

        this.updateSaveActions();

        this.service.selectNode(this.getFirstSelectableNode() || undefined);
        this.buildHeaderConfig(structure);
        this.subscriptions.add(this.builderHeaderService.saveClicked.subscribe(saveOption => this.onAction(saveOption)));
    }

    private buildHeaderConfig(structure: UcStructure) {
        this.builderHeaderService.buildConfig({
            ...structure,
            title: 'Structure',
            cancelRoute: ['../'],
            saveOptions: this.saveOptions,
            publishState: structure.structurePublishState,
            breadcrumbs: this.breadcrumbs,
            restrictSave: `${SystemRole.ContentEditor},${SystemRole.Publisher}`
        });
    }

    private updateStructureFormValue(structure: UcStructure) {
        this.form.patchValue(this.sfb.mapDataToControlValue(structure), { emitEvent: false });
    }

    private isFormValid(): boolean {
        if (!this.form.valid) {
            const items = flattenControls(this.form);

            for (const entry of items) {
                entry.control.markAsTouched();

                if (entry.control.errors != null) {
                    console.log(entry.key, entry.control.errors);
                }
            }

            this.builderHeaderService.notify.next({
                message: 'Unable to save. There are errors in your Structure',
                level: MessageLevel.Error
            });

            return false;
        }

        return true;
    }

}