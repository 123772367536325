<uf-autocomplete *ngIf="!control.disabled" [label]="label" [(value)]="findByProperty" (valueChange)="encodeFindBy()"
    (searchChange)="search($event)" [options]="findByResults" nameProperty="display" placeholder="Search..."
    class="col-12 gap-top-sm">
</uf-autocomplete>
<uf-error [control]="control"></uf-error>

<uf-message *ngIf="control.disabled && findByProperty" icon="errorSolid" class="col-12 grow small error">
    <h3>Search Target Error</h3>
    <p>Search Target requires Data Capture</p>
    <button (click)="reset()" class="uf-button primary small">Clear</button>
</uf-message>