<uf-expander *ngIf="option && control" class="uf-box flat draggable" [isExpanded]="isExpanded">
    <div expanderHeader class="uf-app-bar flat" dragHandle>
        <div class="title primary">
            {{ labelPrefix }}{{ option.name }}
        </div>
        <uf-icon *ngIf="control.invalid" name="warning" class="icon-warning"></uf-icon>
        <button *ngIf="field.type !== fieldType.Bool" class="uf-action tertiary" (click)="removeOption($event)"
            title="Delete">
            <uf-icon name="delete"></uf-icon>
        </button>
    </div>
    <div expanderBody class="uf-grid pad">
        <uf-text class="col-12" label="Name" [(value)]="option.name" [control]="control.controls.name | asUfControl"
            (valueChange)="nameChange($event)" placeholder="Option name">
        </uf-text>
        <uf-text *ngIf="field.type !== fieldType.Bool" class="col-12" label="Identifier" [(value)]="option.identifier"
            [control]="control.controls.identifier | asUfControl" placeholder="Option identifier"
            [maxLength]="identifierMaxLength">
        </uf-text>

        <uc-markdown *ngIf="showContentField" class="col-12" label="Content"
            [control]="control.controls.content | asUfControl" [(value)]="option.content"
            [showAlignmentOptions]="showAlignmentOptions">
        </uc-markdown>

    </div>
</uf-expander>