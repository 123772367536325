import { Subject } from 'rxjs';

import { Injectable } from '@angular/core';
import { TableContainerManager } from '@unifii/components';
import { FilterEntry, FilterValue, FromNowPipe, TableConfig } from '@unifii/library/common';

import { DataSourceInfo, UcDataSources } from 'client';

import { DataSourcesDataSource } from 'pages/project-settings/data-sources/data-sources-datasource';


@Injectable()
export class DataSourcesTableManager implements TableContainerManager<DataSourceInfo, FilterValue, FilterEntry> {

    tableConfig: TableConfig<DataSourceInfo>;
    addActionConfig = true;
    reload = new Subject<void>();

    constructor(
        private ucDataSources: UcDataSources,
        private fromNowPipe: FromNowPipe
    ) {
        this.tableConfig = {
            id: 'data-sources',
            columnToggles: false,
            selectable: false,
            pageSize: 50,
            columns: [{
                name: 'name',
                label: 'Name'
            }, {
                name: 'integrationName',
                label: 'Integration'
            }, {
                name: 'integrationProviderName',
                label: 'Provider'
            }, {
                name: 'lastModifiedAt',
                label: 'Last Modified At',
                value: item => this.fromNowPipe.transform(item.lastModifiedAt)
            }],
            rowLink: item => '' + item.id
        };
    }

    createDataSource() {
        return new DataSourcesDataSource(this.ucDataSources);
    }

}