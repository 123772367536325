import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationStart } from '@angular/router';
import { Breadcrumb } from '@unifii/library/common';

import { MyAccount, UcPermissions, UcUserInfo, UcUsers } from 'client';

import { EditData } from 'components/common/edit-data';

import { TabsPage } from 'pages/tabs-page';

import { BreadcrumbService } from 'services/breadcrumb.service';
import { ContextService } from 'services/context.service';


@Component({
    templateUrl: './user.html',
    providers: [
        {
            provide: UcPermissions,
            useFactory: (users: UcUsers, route: ActivatedRoute) => users.permissions(route.snapshot.params.id),
            deps: [UcUsers, ActivatedRoute]
        }
    ]
})
export class UserComponent extends TabsPage implements OnDestroy, EditData {

    id: string;
    user: UcUserInfo;
    isNew: boolean;
    edited: boolean;
    sub: Subscription;
    breadcrumbs: Breadcrumb[];

    constructor(
        private context: ContextService,
        private ucUsers: UcUsers,
        private ucPermissions: UcPermissions,
        private breadcrumbService: BreadcrumbService
    ) {
        super();
        this.init();
    }

    get isMyAccount(): boolean {
        return this.route.snapshot.params.id == null;
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    async init() {

        this.ucPermissions.inherited = this.router.url.endsWith('inherited-permissions');
        this.sub = this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe((e: NavigationStart) => {
            this.ucPermissions.inherited = e.url.endsWith('inherited-permissions');
        });

        const params = this.route.snapshot.params;
        this.id = params.id || (this.context.account as MyAccount).id;
        this.isNew = this.id === 'new';
        this.user = await this.getUser();

        this.breadcrumbs = this.breadcrumbService.getBreadcrumbs(this.route, [this.userFullName]);
    }

    async reload() {
        this.user = await this.getUser();
    }

    async getUser(): Promise<UcUserInfo> {

        let user: UcUserInfo;

        if (this.isNew) {
            user = {
                username: null as any as string,
                hasPassword: null as any as boolean,
                isExternal: null as any as boolean,
                isActive: null as any as boolean,
            };
        } else {
            user = await this.ucUsers.getDetails(this.id);
        }
        return Object.assign({ roles: [], claims: [], systemRoles: [] }, user);
    }

    private get userFullName() {
        if (this.user == null) {
            return '';
        }

        if (this.user.firstName && this.user.lastName) {
            return this.user.firstName + ' ' + this.user.lastName;
        }

        return this.user.username;
    }
}
