import { Subscription } from 'rxjs';

import { AfterViewInit, Directive, EventEmitter, inject, OnDestroy, OnInit } from '@angular/core';
import { RuntimeField, Scope, UfControl } from '@unifii/library/common';
import { FormField, isGroup } from '@unifii/library/smart-forms';
import { Field } from '@unifii/sdk';

import { DialogsService } from 'services/dialogs.service';

import { BuilderEventInfo, BuilderService } from '../compound-builder/builder.service';

import { ContentSettings } from './content-model';


@Directive()
export abstract class Content implements FormField, OnInit, AfterViewInit, OnDestroy {

    contentChange: EventEmitter<any>;
    control: UfControl;
    content: any;
    scope: Scope;

    // EditorField or Field base if used from uf-field or uf-content-field
    protected configuredField: Field | RuntimeField;

    protected isSelected: boolean;
    protected isGroup: boolean;
    protected ready: boolean;
    protected subscriptions = new Subscription();
    protected builderService = inject(BuilderService);
    protected contentSettings = inject(ContentSettings);

    private dialogs: DialogsService = inject(DialogsService);
    private _field: RuntimeField;
    private _editorField: Field | undefined;

    ngOnInit() {
        if (this.editorField) {
            // React to builder ready status
            this.subscriptions.add(this.builderService.ready.subscribe(() => {
                this.ready = true;
            }));

            // React to field selection in builder
            this.subscriptions.add(this.builderService.fieldSelected.subscribe(i => {
                this.isSelected = !!this.canSelectField && this.isMe(i);
            }));
        }

        this.isGroup = isGroup(this.configuredField.type);
    }

    ngAfterViewInit() {
        // TODO setting control value in ngOnInit throws an error
        if (this.content && this.control) {
            this.control.setValue(this.content, { onlySelf: true, emitEvent: false });
        }

        if (this.editorField) {
            this.builderService.fieldReady.next({ subject: this.editorField, atomic: true });
        }
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    // Assigned only when content is created via ContentFieldComponent
    set editorField(v: Field | undefined) {
        this._editorField = v;
        this.configuredField = this._editorField ?? this.field;
    }

    get editorField() {
        return this._editorField;
    }

    set field(v: RuntimeField) {
        this._field = v;
        this.configuredField = this._editorField ?? this.field;
    }

    get field() {
        return this._field;
    }

    get isValid() {
        if (!this.editorField) {
            return true;
        }

        return this.builderService.isValid(this.editorField);
    }

    get disabled() {
        return this.control.disabled;
    }

    /** Enable action to remove the field from the editor */
    get canRemoveField() {
        return this.editorField && !this.disabled && this.contentSettings.canEditDefinition;
    }

    /** Enable action to select the field */
    get canSelectField() {
        return this.editorField && !this.disabled && this.contentSettings.canEditDefinition;
    }

    /** Enable action to move the field within the editor */
    get canDragField() {
        return this.editorField && !this.disabled && (this.contentSettings.canEditDefinition || this.contentSettings.canReorderDefinition);
    }

    /** Enable to edit the field value */
    get canEditField() {
        return !this.editorField || (!this.disabled && this.contentSettings.canEditContent);
    }

    protected async removeField(event: Event) {
        event.stopPropagation();

        if (!this.canRemoveField) {
            return;
        }

        if (!await this.dialogs.confirmDelete()) {
            return;
        }

        this.builderService.fieldRemove.next({ subject: this.editorField, atomic: true });
    }

    protected selectField(event: Event) {
        event.stopPropagation();

        if (!this.canSelectField) {
            return;
        }

        this.builderService.fieldSelect.next({ subject: this.editorField });
    }

    private isMe(i: BuilderEventInfo = { subject: null }): boolean {
        return !!this.editorField && i.subject === this.editorField;
    }
}
