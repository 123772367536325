<ng-template [ngIf]="ready">

    <!-- Form header -->
    <ng-template [ngIf]="isRoot" [ngIfElse]="formField">
        <div class="uf-app-bar flat gap-sm-bottom" [class.accent]="isSelected" (click)="select($event)">
            <div class="title primary">{{status.definition.label}}</div>
            <uf-icon *ngIf="status.root.invalid" name="error" [class.error]="!isSelected"
                title="Form configuration is invalid">
            </uf-icon>
            <button class="uf-action" [class.tertiary]="!isSelected" [class.primary]="isSelected"
                (click)="expand($event)" title="Expand All">
                <uf-icon name="arrowDown"></uf-icon>
            </button>
            <button class="uf-action" [class.tertiary]="!isSelected" [class.primary]="isSelected"
                (click)="collapse($event)" title="Collapse All">
                <uf-icon name="arrowUp"></uf-icon>
            </button>
        </div>
    </ng-template>

    <ng-template #formField>

        <!-- Leaf node -->
        <ng-template [ngIf]="!meta.isContainer" [ngIfElse]="parent">
            <div class="uf-app-bar flat draggable" [class.uf-box]="!isSelected" [class.accent]="isSelected"
                (click)="select($event)">
                <uf-icon class="gap-sm-left" [name]="icon"></uf-icon>
                <!-- Content field show help instead of label  -->
                <div class="title primary">{{label}}</div>
                <uc-form-field-icons [control]="control" [selected]="isSelected"></uc-form-field-icons>
                <a *ngIf="!control.disabled" class="uf-action" (click)="remove($event)" title="Delete">
                    <uf-icon name="delete"></uf-icon>
                </a>
            </div>
        </ng-template>

        <!-- Parent node -->
        <ng-template #parent>
            <uf-expander class="uf-box flat draggable gap-sm-bottom">
                <div expanderHeader class="uf-app-bar flat draggable" [class.accent]="isSelected"
                    (click)="select($event)">
                    <uf-icon [name]="icon"></uf-icon>
                    <div class="title primary">{{label}}</div>
                    <uc-form-field-icons [control]="control" [selected]="isSelected"></uc-form-field-icons>
                    <a *ngIf="!control.disabled" class="uf-action" (click)="remove($event)" tilte="Delete">
                        <uf-icon name="delete"></uf-icon>
                    </a>
                </div>
                <div expanderBody>
                    <uc-drag-list [parent]="fields" [items]="fields.controls" childrenProperty="fields"
                        [canDrop]="service.canDrop" [convertAdded]="service.addConverter"
                        (moved)="service.fieldMoved($event)" (insert)="service.fieldAdded($event)"
                        class="gap-left gap-sm-top">
                        <uc-form-field *ngFor="let field of fields.controls | asUfControlsGroups" dragItem
                            [dragDisabled]="field.disabled" [nestable]="status.containersByFieldType[field.value?.type]"
                            [control]="field" class="gap-sm-bottom">
                        </uc-form-field>
                        <div *ngIf="!fields.controls.length">
                            <p class="body-copy small">Drag your items here</p>
                        </div>
                        <uf-error [control]="fields"></uf-error>
                    </uc-drag-list>
                </div>
            </uf-expander>
        </ng-template>

    </ng-template>

</ng-template>