import { Component, HostBinding } from '@angular/core';


@Component({
    templateUrl: './error-handling.html'
})
export class ErrorHandlingComponent {

    @HostBinding('class.stretch-component') class = true;

    error() {
        const test: any = {};
        console.log(test.test.test);
    }

}

