import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { StorageWrapper, WindowWrapper } from '@unifii/library/common';


export enum BrowserErrorTypes {
    Unsupported
}

export class BrowserError implements Error {
    name: string;

    constructor(
        public type: BrowserErrorTypes,
        public message: string,
    ) { }
}

@Injectable()
export class BrowserGuard {

    readonly message = `You are attempting to view this website in an <strong>unsupported</strong> browser.<br>
    Please download <a href="https://www.google.com/chrome/">Google Chrome</a> to view this website.`;

    allowed: Promise<void> = new Promise((resolve, reject) => {

        if (this.storage.DevMode || this.supported()) {
            resolve();
        } else {
            this.appendMessage();
            reject(new BrowserError(BrowserErrorTypes.Unsupported, 'Unsupported browser'));
        }
    });

    constructor(
        @Inject(WindowWrapper) private window: Window,
        @Inject(DOCUMENT) private document: HTMLDocument,
        @Inject(StorageWrapper) private storage: StorageWrapper
    ) { }

    private appendMessage() {

        const parragraph = this.document.createElement('p');
        parragraph.setAttribute('style', 'position: absolute; margin: auto; left: 0; right: 0; top: 30px; text-align: center;padding: 16px;');
        parragraph.innerHTML = this.message;

        const body = this.document.getElementsByTagName('body')[0];
        body.appendChild(parragraph);
    }

    private supported() {
        return (/Chrome/.test(this.window.navigator.userAgent) && this.window.navigator.vendor && /Google Inc/.test(this.window.navigator.vendor)) || /Firefox/.test(this.window.navigator.userAgent);
    }

}
