import { Subscription } from 'rxjs';

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import { Breadcrumb, DescriptionListItem, ModalService, ToastService, UfControl, UfControlArray, UfControlGroup } from '@unifii/library/common';
import { amendOptionsParams, DataSeed } from '@unifii/sdk';

import {
    ActivityType, DataForwarder, FormSubmittedCondition, IntegrationInfo, IntegrationProvider, IntegrationProviderFeature, Timer, UcClient,
    UcIntegrations, UcProject, UcRoles, UcWorkflow, WorkflowActivityInfo, WorkflowEventType, WorkflowNotification,
    WorkflowNotificationRecipientFormData, WorkflowNotificationRecipientType, WorkflowRule
} from 'client';

import { EditData } from 'components/common/edit-data';

import { BreadcrumbService } from 'services/breadcrumb.service';
import { ContextService } from 'services/context.service';
import { DialogsService } from 'services/dialogs.service';

import { WorkflowActivityLabel, WorkflowEventLabel } from './constants';
import {
    WorkflowNotificationFormDataOptions, WorkflowNotificationRecipientOptions, WorkflowNotificationUserManagementRecipientOptions
} from './workflow-notification-constants';
import { ControlKeys, SchemaTransitionDescription, WorkflowRuleFormController, WorkflowRuleFormModel } from './workflow-rule-form.controller';
import { WorkflowRulesTableManager } from './workflow-rules-table-manager';


@Component({
    templateUrl: './workflow-rule-form.html'
})
export class WorkflowRuleFormComponent implements EditData, OnInit, OnDestroy {

    readonly controlKeys = ControlKeys;
    readonly activityType = ActivityType;
    readonly eventTypes = WorkflowEventType;
    readonly eventOptions: DataSeed[] = [
        { _id: WorkflowEventType.FormSubmitted, _display: WorkflowEventLabel[WorkflowEventType.FormSubmitted] },
        { _id: WorkflowEventType.Timer, _display: WorkflowEventLabel[WorkflowEventType.Timer] },
        { _id: WorkflowEventType.ApiEvent, _display: WorkflowEventLabel[WorkflowEventType.ApiEvent] },
        { _id: WorkflowEventType.RoleAdded, _display: WorkflowEventLabel[WorkflowEventType.RoleAdded] },
    ];
    readonly activityOptions: DataSeed[] = [
        { _id: ActivityType.Notification, _display: WorkflowActivityLabel[ActivityType.Notification] },
        { _id: ActivityType.Timer, _display: WorkflowActivityLabel[ActivityType.Timer] },
        { _id: ActivityType.DataForwarder, _display: WorkflowActivityLabel[ActivityType.DataForwarder] },
    ];

    edited = false;
    root: UfControlGroup;
    breadcrumbs: Breadcrumb[];
    error: Error | null = null;
    bucketError: Error | null = null;

    activityData: DescriptionListItem[][] = [];

    // Select inputs
    buckets: string[] = [];
    filteredFormOptions: DataSeed[] = [];
    formOptions: DataSeed[] = [];
    integrations: IntegrationInfo[] = [];


    filteredTransitions: SchemaTransitionDescription[] = [];
    filteredFeatures: IntegrationProviderFeature[] = [];
    filteredTimers: WorkflowActivityInfo[] = [];
    filteredDataForwarders: WorkflowActivityInfo[] = [];
    filteredNotifications: WorkflowActivityInfo[] = [];
    filteredRoles: string[];

    private features: IntegrationProviderFeature[] = [];
    private _integrations: IntegrationInfo[];
    private integrationCache = new Map<string, Promise<IntegrationProvider>>();
    private subscriptions: Subscription[] = [];
    private recipientTypes = [...WorkflowNotificationRecipientOptions, ...WorkflowNotificationUserManagementRecipientOptions];
    private formDataTypes = WorkflowNotificationFormDataOptions;
    private prevEventType: WorkflowEventType;
    private prevBucket: string | null;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private ucWorkflow: UcWorkflow,
        private ucIntegrations: UcIntegrations,
        private ucClient: UcClient,
        private ucRoles: UcRoles,
        private toastService: ToastService,
        private ucProject: UcProject,
        private formController: WorkflowRuleFormController,
        private modalService: ModalService,
        public context: ContextService,
        private dialogs: DialogsService,
        private breadcrumbService: BreadcrumbService,
        @Inject(TableContainerManager) private tableManager: WorkflowRulesTableManager
    ) { }

    async ngOnInit() {
        const { id, duplicate } = this.route.snapshot.params;
        let workflowRule: WorkflowRule | null = null;
        let formModel: WorkflowRuleFormModel | null = null;

        try {
            if (id !== 'new') {
                workflowRule = await this.ucWorkflow.getRule(id);
                formModel = await this.formController.toFormModel(workflowRule);

                if (duplicate === 'true') {
                    formModel[ControlKeys.Id] = null as any as string;
                    formModel[ControlKeys.Label] += ' (copy)';
                }
            }

            // Collect all require activites and set lists for inputs
            const eventActivityType = this.toActivityType(workflowRule?.event?.type);
            const activityTypes = (workflowRule?.activities || []).map(a => a.type);
            if (eventActivityType != null) {
                activityTypes.push(eventActivityType);
            }

            if (formModel != null && formModel.integration) {
                this.integrations = await this.getIntegrations();
                this.features = await this.getFeatures(formModel.integration.provider.id);
                this.filteredFeatures = this.features;
            }

            if (formModel?.condition && (formModel.condition as FormSubmittedCondition).bucket) {
                await this.searchForms(undefined, 1000, (formModel.condition as FormSubmittedCondition).bucket);
            }

            if (workflowRule?.event?.type === WorkflowEventType.FormSubmitted && workflowRule.condition != null) {
                const { bucket } = workflowRule.condition as FormSubmittedCondition;
                const definitionInfo = await this.ucProject.getForms({ params: { bucket, limit: 2 } });
                this.formOptions = definitionInfo.map(d => ({ _id: d.identifier, _display: d.name }));
            }

            this.root = this.formController.buildRoot(formModel);

            this.subscriptions.push(this.root.valueChanges.subscribe(_ => {
                this.edited = true;
            }));

            for (const activity of (formModel?.activities ?? [])) {
                const activityInfo = await this.ucWorkflow.getActivity(activity.id);
                this.activityData.push(await this.addActivityData(activityInfo));
            }

        } catch (e) {
            this.error = new Error(e.mesage ?? 'Failied to load workflow rule');
        }

        // Set breadcrumbs
        this.breadcrumbs = this.breadcrumbService.getBreadcrumbs(this.route, [formModel?.label ?? 'New']);
    }

    ngOnDestroy() {
        for (const subscription of this.subscriptions) {
            subscription.unsubscribe();
        }
    }

    get timer(): UfControl {
        return this.root.get(ControlKeys.Timer) as UfControl;
    }

    get integration(): UfControl {
        return this.root.get(ControlKeys.Integration) as UfControl;
    }

    get feature(): UfControl {
        return this.root.get(ControlKeys.Feature) as UfControl;
    }

    get condition(): UfControlGroup {
        return this.root.get(ControlKeys.Condition) as UfControlGroup;
    }

    get bucket(): UfControl {
        return this.condition.get(ControlKeys.Bucket) as UfControl;
    }

    get transitions(): UfControl {
        return this.condition.get(ControlKeys.Transitions) as UfControl;
    }

    get forms(): UfControl {
        return this.condition.get(ControlKeys.Forms) as UfControl;
    }

    get activities(): UfControlArray {
        return this.root.get(ControlKeys.Activities) as UfControlArray;
    }

    get eventType(): UfControl {
        return this.root.get(ControlKeys.EventType) as UfControl;
    }

    get transitionOptions(): UfControl {
        return this.root.get(ControlKeys.TransitionOptions) as UfControl;
    }

    async eventChange(type: WorkflowEventType) {
        if (type === this.prevEventType) {
            return;
        }

        if (this.prevEventType && this.activities.controls.length) {
            const confirm = await this.modalService.openConfirm({
                title: 'Change Event Type',
                message: 'Activities are going to be reset',
                confirmLabel: 'Change',
                cancelLabel: `Don't Change`
            });

            if (!confirm) {
                this.eventType.setValue(this.prevEventType);
                return;
            }
        }

        this.prevEventType = type;

        if (type === WorkflowEventType.ApiEvent) {
            this.integrations = await this.getIntegrations();
        }

        while (this.activities.controls.length) {
            this.activities.removeAt(0);
        }

        this.formController.resetEventForm({
            type,
            forms: this.forms,
            timer: this.timer,
            bucket: this.bucket,
            feature: this.feature,
            integration: this.integration,
            transitions: this.transitions,
            roles: this.condition.get(ControlKeys.Roles) as UfControl,
            includeNewUser: this.condition.get(ControlKeys.IncludeNewUser) as UfControl,
            includeExternal: this.condition.get(ControlKeys.IncludeExternal) as UfControl,
        });
    }

    async integrationChange(integration?: IntegrationInfo) {
        if (!integration) {
            return;
        }

        try {
            this.features = await this.loadFeatures(integration.provider.id);
        } catch (e) {
            this.error = new Error(e?.message ?? 'Failied to load integration');
        }
    }

    async searchBuckets(q?: string) {
        this.bucketError = null;

        try {
            const schemaInfo = await this.ucProject.getFormBuckets({ params: { q } });
            this.buckets = schemaInfo.map(i => (i.id));
        } catch (e) {
            this.bucketError = new Error(e?.message ?? 'Failed to load Form Data Repistories');
        }
    }

    async bucketChange(bucket: string | null) {

        if (bucket === this.prevBucket) {
            return;
        }

        this.bucketError = null;
        this.formOptions = [];

        if (this.prevBucket && this.activities.controls.length) {
            const confirm = await this.modalService.openConfirm({
                title: 'Change Form Data Repository',
                message: 'Notification Activities are going to be removed.',
                confirmLabel: 'Change',
                cancelLabel: `Don't Change`
            });

            if (!confirm) {
                this.bucket.setValue(this.prevBucket);
                return;
            }
        }

        this.prevBucket = bucket;
        this.transitions.reset({ value: [], disabled: false });
        this.removeNotificationActivities();

        if (bucket == null) {
            this.transitionOptions.reset([]);
            return;
        }

        try {
            const { transitions } = await this.ucProject.getBucket(bucket);
            this.transitionOptions.reset(transitions.map(transition => this.formController.mapSchemaTransitionDescription(transition)));
            const definitionInfo = await this.ucProject.getForms({ params: { bucket, limit: 2 } });
            this.formOptions = definitionInfo.map(d => ({ _id: d.identifier, _display: d.name }));
        } catch (e) {
            this.bucketError = new Error(e.mesage ?? 'Failed to load schema');
        }
    }

    async searchForms(q?: string, limit?: number, bucket?: string) {
        const definitionInfo = await this.ucProject.getForms({ params: { q, bucket: bucket ?? this.bucket.value, limit } });
        this.filteredFormOptions = definitionInfo.map(d => ({ _id: d.identifier, _display: d.name }));
    }

    openActivity(controlGroup: UfControlGroup) {
        const activity = controlGroup.getRawValue() as WorkflowActivityInfo;
        switch (activity.type) {
            case ActivityType.Timer:
                return `../../timers/${activity.id}`;
            case ActivityType.Notification:
                return `../../notifications/${activity.id}`;
            case ActivityType.DataForwarder:
                return `../../data-forwarders/${activity.id}`;
        }
    }

    async searchTimers(q?: string) {
        this.filteredTimers = await this.ucWorkflow.getActivities({ params: { type: ActivityType.Timer, q } });
    }

    async searchIntegrations(q?: string) {
        this.integrations = await this.ucIntegrations.list(amendOptionsParams({ q }));
    }

    async searchFeatures(query?: string) {
        this.filteredFeatures = this.features.filter(feature => !query || feature.name.toLowerCase().includes(query.toLowerCase()));
    }

    async searchRoles(query?: string) {
        this.filteredRoles = (await this.ucRoles.get(query)).map(r => r.name);
    }

    async searchNotifications(q?: string) {
        const model = this.root.getRawValue() as WorkflowRuleFormModel;
        const notifications = (await this.ucWorkflow.getActivities({ params: { type: ActivityType.Notification, q } }) ?? [])
            .filter((activity: WorkflowNotification) => {
                if (model.eventType === WorkflowEventType.FormSubmitted) {
                    return activity.bucket === this.bucket.value;
                } else if (model.eventType === WorkflowEventType.RoleAdded) {
                    return !activity.bucket;
                }
                return true;
            })
            .map((activity: WorkflowNotification) => ({
                ...activity,
                label: activity.bucket ? `${activity.label} (${activity.bucket})` : activity.label
            }));
        this.filteredNotifications = notifications.filter(field => !q || field.label.toLowerCase().includes(q.toLowerCase()));
    }

    async searchDataForwarders(q?: string) {
        this.filteredDataForwarders = await this.ucWorkflow.getActivities({ params: { type: ActivityType.DataForwarder, q } });
    }

    searchTransitions(q?: string) {
        const transitions = this.transitionOptions?.value;
        this.filteredTransitions = transitions.filter((t: SchemaTransitionDescription) => t.description.toLowerCase().includes((q ?? '').toLowerCase()));
    }

    addActivity({ _id: type }: { _id: ActivityType }) {
        this.activityData.push();
        this.activities.push(this.formController.buildActivityControl({ type } as any));
    }

    async activityChange(controlGroup: UfControlGroup, activity: WorkflowActivityInfo, index: number) {
        this.activityData[index] = await this.addActivityData(activity);
        controlGroup.get(ControlKeys.Id)?.setValue(activity?.id);
    }

    async deleteActivity(index: number) {
        if (!await this.dialogs.confirmDelete()) {
            return;
        }
        this.activityData.splice(index, 1);
        this.activities.removeAt(index);
    }

    async searchTimer(q: string) {
        this.filteredTimers = await this.ucWorkflow.getActivities({ params: { type: ActivityType.Timer, q } });
    }

    async save() {
        if (this.root.invalid) {
            this.root.setSubmitted();
            return;
        }

        let workflowRule = this.formController.toDataModel(this.root.getRawValue());
        if (workflowRule == null) {
            return;
        }

        this.error = null;
        try {
            if ((workflowRule as WorkflowRule)?.id != null) {
                workflowRule = await this.ucWorkflow.updateRule(workflowRule as WorkflowRule);
                this.tableManager.updateItem.next(workflowRule as WorkflowRule);
            }
            else {
                workflowRule = await this.ucWorkflow.addRule(workflowRule);
                this.tableManager.reload.next();
            }

            this.toastService.success('Workflow rule saved');
            this.edited = false;
            this.router.navigate(['..'], { relativeTo: this.route });

        } catch (e) {
            this.toastService.error(e.message ?? 'Failed to save workflow rule');
        }
    }

    private async loadFeatures(providerId: string) {
        const { features } = await this.ucClient.getAvailableIntegration(providerId);
        return features;
    }

    private async addActivityData(activity?: WorkflowActivityInfo) {
        switch (activity?.type) {
            case ActivityType.Timer:
                return [{
                    term: 'Run at expression',
                    description: (activity as Timer).expression
                },
                {
                    term: 'Form Data Repository',
                    description: activity.bucket
                }];
            case ActivityType.Notification:
                const recipients = (activity as WorkflowNotification).recipients
                    .map(recipient => {
                        if (recipient.type === WorkflowNotificationRecipientType.FormData) {
                            return this.formDataTypes.find(option => option.identifier === (recipient as WorkflowNotificationRecipientFormData).formData.type)?.name;
                        } else {
                            return this.recipientTypes.find(option => (option.identifier === recipient.type))?.name;
                        }
                    })
                    .filter(Boolean)
                    .join(', ');

                let deliveryMethod = '';

                if ((activity as WorkflowNotification).messages.email) {
                    deliveryMethod = 'Email';
                }

                if ((activity as WorkflowNotification).messages.push) {
                    deliveryMethod += (!!deliveryMethod ? ', ' : '') + 'Push';
                }

                return [{
                    term: 'Recipient Type',
                    description: recipients
                },
                {
                    term: 'Delivery Method',
                    description: deliveryMethod
                }];
            case ActivityType.DataForwarder:
                const details = [{
                    term: 'Form Data Repository',
                    description: activity.bucket
                }];
                if (!!(activity as DataForwarder).integrationId) {
                    const integration = await this.ucIntegrations.get((activity as DataForwarder).integrationId as string);
                    details.push({
                        term: 'Integration',
                        description: integration.name
                    });
                    const { features } = await this.ucClient.getAvailableIntegration(integration.provider.id);
                    const feature = features.find(f => f.id === (activity as DataForwarder).featureId);
                    if (feature) {
                        details.push({
                            term: 'Feature',
                            description: feature.name
                        });
                    }
                }
                return details;
            default:
                return [];
        }
    }

    private async getIntegrations() {
        if (this._integrations == null) {
            this._integrations = await this.ucIntegrations.list();
        }
        return this._integrations;
    }

    private async getFeatures(providerId: string): Promise<IntegrationProviderFeature[]> {
        const cachedPromise = this.integrationCache.get(providerId);
        if (cachedPromise != null) {
            return (await cachedPromise).features ?? [];
        }
        const promise = this.ucClient.getAvailableIntegration(providerId);

        try {
            const { features } = await promise;
            this.integrationCache.set(providerId, promise);
            return features;
        } catch (e) {
            throw e;
        }
    }

    private toActivityType(type?: WorkflowEventType): ActivityType | null {
        switch (type) {
            case WorkflowEventType.ApiEvent: return ActivityType.DataForwarder;
            case WorkflowEventType.Timer: return ActivityType.Timer;
            default: return null;
        }
    }

    private removeNotificationActivities() {
        if (!this.activities.controls.length) {
            return;
        }

        this.activities.controls = this.activities.controls.filter(activity => {
            const { value: { type } } = activity;
            return type !== ActivityType.Notification;
        });
    }

}