import { ReplaySubject } from 'rxjs';

import { Injectable } from '@angular/core';

import { PublishStatus } from 'client';
import { UcProject } from 'client/project';


const PublishStatusError = 'An error occurred retriving publish status';
const PreviewPublishError = 'An error occurred while publishing preview';
const StablePublishError = 'An error occurred while publishing stable';

export interface PublishArgs {
    isPreview?: boolean;
}

/**
 * Simplified VersionInfo required
 * as publishedAt won't exist on pending publishes
 */
export interface PendingVersion {
    version: number;
    preview?: number;
}

/**
 * Real time information on publish events
 */
export interface PublishInfo extends PublishStatus {
    pending?: PendingVersion;
    failure?: {
        version?: PendingVersion;
        error: Error;
    };
}


@Injectable()
export class ProjectPublisher {

    event = new ReplaySubject<PublishInfo>(1);

    private _status: PublishStatus = {};

    constructor(private project: UcProject) {
        this.update();
    }

    get status(): PublishStatus {
        return this._status;
    }

    async update() {
        /**
         * Merge latest published state with any errors or
         * pending publishes
         */
        try {
            const status = await this.project.getPublishStatus();
            Object.assign(this._status, status);
            this.event.next(this.status);
        } catch (error) {

            this._status = {};
            this.failure(new Error(this.getErrorMessage(error, PublishStatusError)));
        }

    }

    async publish({ isPreview }: PublishArgs = {}) {

        this.start({ isPreview });

        try {

            if (isPreview) {
                await this.project.publishPreview();
            } else {
                await this.project.publishStable();
            }
            this.complete();

        } catch (error) {
            const message = isPreview ? PreviewPublishError : StablePublishError;
            this.failure(new Error(this.getErrorMessage(error, message)));
            // We're re-throwing the error to POST it to Sentry
            throw error;
        }

    }

    getNextVersion({ isPreview }: PublishArgs = {}): PendingVersion {

        const versionInfo: PendingVersion = { version: 0 };

        if (!isPreview) {
            Object.assign(versionInfo, this.status.stable || {});
            return { version: versionInfo.version + 1 };
        }

        Object.assign(versionInfo, { preview: 0 }, this.status.preview || {});

        if ((versionInfo.preview as number) > 0) {
            return { version: versionInfo.version, preview: (versionInfo.preview as number) + 1 };
        }

        return { version: versionInfo.version + 1, preview: 1 };
    }

    /**
     * Publish event handlers
     */
    private async complete() {

        try {
            this._status = await this.project.getPublishStatus();
            this.event.next(this.status);
        } catch (error) {
            this._status = {};
            this.failure(new Error(this.getErrorMessage(error, PublishStatusError)));
        }

    }

    private failure(error: Error) {

        const status: PublishInfo = this.status;
        status.failure = { error };

        if (status.pending) {
            status.failure.version = status.pending;
        }

        delete status.pending;

        this.event.next(status);
    }

    private start({ isPreview }: PublishArgs = {}) {

        const status: PublishInfo = this.status;
        status.pending = this.getNextVersion({ isPreview });

        delete status.failure;

        this.event.next(status);
    }

    private getErrorMessage(error: any, fallback: string): string {

        if (error && error.message) {
            return error.message;
        }
        return fallback;
    }

}
