import { COMPONENTS_CHART } from './chart';
import { COMPONENTS_COMMON } from './common';
import { COMPONENTS_CONTENT } from './content';
import { FILTER_COMPONENTS } from './filters';
import { INPUT_FILTERS } from './input-filters';


export const COMPONENTS = [
    COMPONENTS_CHART,
    COMPONENTS_COMMON,
    FILTER_COMPONENTS,
    INPUT_FILTERS,
    COMPONENTS_CONTENT,
];

export * from './chart';
export * from './common';
export * from './content';
export * from './filters';
export * from './input-filters';
