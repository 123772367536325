<uf-panel class="container">
    <ng-template ngFor let-group [ngForOf]="groups">
        <ng-template [ngIf]="group.title">
            <uf-expander class="uc-group gap--bottom-small">
                <div expanderHeader class="uf-app-bar flat">
                    <span class="title primary">{{ group.title }}</span>
                </div>
                <div expanderBody class="uc-group-content padded--small">
                    <ul class="uc-list">
                        <!-- todo replace with library list -->
                        <ng-template ngFor let-field [ngForOf]="group.entries">
                            <li class="uc-list-item--draggable--small row center-all"
                                *ngIf="!field.disabled || config.debug" [dragItem]="field"
                                [dragDisabled]="field.disabled">
                                <uf-icon [name]="field.icon"></uf-icon>
                                <span class="grow gap-left">{{ field.label }}</span>
                                <a *ngIf="field.customId" class="uf-action tertiary" (click)="deleteCustomField(field)"
                                    title="Delete">
                                    <uf-icon name="delete"></uf-icon>
                                </a>
                            </li>
                        </ng-template>
                    </ul>
                </div>
            </uf-expander>
        </ng-template>
        <ng-template [ngIf]="!group.title">
            <div>
                <ul class="uc-list drag-blocks-no-heading">
                    <ng-template ngFor let-field [ngForOf]="group.entries">
                        <li class="uc-list-item--draggable--small row center-all"
                            *ngIf="!field.disabled || config.debug" [dragItem]="field" [dragDisabled]="field.disabled">
                            <uf-icon [name]="field.icon"></uf-icon>
                            <span class="grow gap-left">{{ field.label }}</span>
                            <a *ngIf="field.customId" class="uf-action tertiary" (click)="deleteCustomField(field)"
                                title="Delete">
                                <uf-icon name="delete"></uf-icon>
                            </a>
                        </li>
                    </ng-template>
                </ul>
            </div>
        </ng-template>
    </ng-template>
</uf-panel>