<div class="header">
    <button type="button" class="uf-action tertiary" (click)="close()" title="Close">
        <uf-icon name="close"></uf-icon>
    </button>
    <h3>Metadata - revision {{formData._rev}}</h3>
</div>

<div class="container pad">
    <uf-description-list class="columns" [items]="formData">
    </uf-description-list>
</div>

<div class="row space-children pad">
    <button type="button" class="uf-button tertiary right" (click)="close()">Close</button>
</div>