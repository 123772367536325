import { Subscription } from 'rxjs';

import { Component, HostBinding, Inject, OnDestroy, OnInit } from '@angular/core';
import { TableContainerManager } from '@unifii/components';

import { UcApps } from 'client';

import { AppsTableManager } from './apps-table-manager';


@Component({
    providers: [
        { provide: TableContainerManager, useClass: AppsTableManager }
    ],
    template: `
    <div ufMasterDetail>
        <uf-table-container ufSyncRoute (addItem)="addItem()" class="accent list-md pad-none">
        </uf-table-container>
    </div>
    <router-outlet></router-outlet>
`
})
export class AppsComponent implements OnInit, OnDestroy {

    @HostBinding('class.stretch-component') class = true;

    private subscriptions: Subscription = new Subscription();

    constructor(
        private ucApps: UcApps,
        @Inject(TableContainerManager) private manager: AppsTableManager
    ) { }

    ngOnInit() {
        this.subscriptions.add(this.ucApps.appDeleted.subscribe(() => this.manager.reload.next()));
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    addItem() {
        this.manager.addItem();
    }

}
