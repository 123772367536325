<div class="grid padded--vert" *ngIf="ready && notifyEnabled">
    <uf-checkbox *ngIf="field.type === fieldType.Address" class="col-1of1--no-margin" label="Enable search"
        [(value)]="autocompleteEnabled" (valueChange)="autocompleteEnabledChange($event)"></uf-checkbox>
    <div class="col-1of1--no-margin" *ngFor="let name of $any(fields)[field.type]">
        <ng-container *ngIf="name !== 'lat' && name !== 'lng'">

            <div class="fieldset-item--x-small">
                <span>{{ getLabel(name) | toDisplayName }}</span>
            </div>
            <div class="row space-children">
                <uf-checkbox label="Visible" [(value)]="values.visible[name]" (valueChange)="updateValues(name)">
                </uf-checkbox>
                <uf-checkbox label="Read Only" [(value)]="values.readOnly[name]" (valueChange)="updateValues(name)">
                </uf-checkbox>
                <uf-checkbox label="Required" [(value)]="values.required[name]" (valueChange)="updateValues(name)">
                </uf-checkbox>
            </div>
            <uf-error [control]="form.controls[name] | asUfControl"></uf-error>
        </ng-container>
    </div>
</div>