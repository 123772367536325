import { TableDataSource, TableDataSourceQueryOptions } from '@unifii/library/common';
import { mergeParams, RequestAnalyticsOrigin } from '@unifii/sdk';

import { Media, MediaQueryParams, UcMedia } from 'client';


export class MediaDataSource extends TableDataSource<Media> {

    constructor(
        private ucMedia: UcMedia,
        private params?: MediaQueryParams
    ) {
        super();
    }

    get filtered(): boolean {
        return this.params?.q != null && this.params.q.trim().length > 0;
    }

    get sorted(): boolean {
        return this.params?.sort != null;
    }

    load(options?: TableDataSourceQueryOptions) {
        const params = mergeParams(this.params as Record<string, unknown>, { offset: options?.offset, limit: options?.limit });
        this.ucMedia.get(params, { analytics: { origin: RequestAnalyticsOrigin.Table } })
            .then(media => {
                this.stream.next({ data: media });
            }, error => {
                this.stream.next({ error });
            });
    }
}
