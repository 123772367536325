import { Injectable } from '@angular/core';
import { Error, ErrorType, Interceptor } from '@unifii/sdk';


@Injectable()
export class ConsoleInterceptor implements Interceptor {

    intercept<T>(response: Promise<T>): Promise<T> {

        return response.catch((err: Error) => {

            console.warn('ConsoleInterceptor.catch', err);

            if (err.type === ErrorType.AbortError) {
                console.warn('ShowcaseInterceptor.catch - User aborted operation', err);
                return Promise.resolve(null as any);
            }

            throw err;
        });
    }
}
