import { Injectable } from '@angular/core';
import { DataDescriptor } from '@unifii/library/common';
import { FormDataClient, PublishedContent, Schema } from '@unifii/sdk';

import { UcDataForwarders, UcWorkflowNotifications } from 'client';


/**
 * Provided at BucketComponent, WorkflowComponent and TableComponent levels
 * Centralize and share the formBucket status across sub-routes components
 */
@Injectable()
export class FormBucketService {

    /** Current bucket Schema */
    schema: Schema;

    /** Configured WorkflowNotifications of current bucket */
    ucWorkflowNotifications: UcWorkflowNotifications;

    /** Configured DataForwardeds of current bucket */
    ucDataForwarders: UcDataForwarders;

    /**
     * Configured Bucket in Live or Preview
     * Use SDK v0 endpoint that is ACL exceptions mitigated for Console users
     */
    formDataClient: FormDataClient;

    /** Configured Content in Live vs Preview */
    content: PublishedContent;

    dataDescriptor?: DataDescriptor;

}
