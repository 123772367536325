import { Subscription } from 'rxjs';

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MessageAction, UfControl, UfControlGroup, UfFormControl } from '@unifii/library/common';
import { FieldType, Option } from '@unifii/sdk';

import { ContextService } from 'services/context.service';

import { FORM_EDITOR_CONSTANTS } from '../form-editor-constants';
import { FieldControlKeys } from '../form-editor-control-keys';
import { FormEditorFunctions } from '../form-editor-functions';
import { FormEditorField, FormFieldMetadata } from '../form-editor-model';
import { FormEditorService } from '../form-editor.service';

import { sortValue } from './field-utils';


@Component({
    selector: 'uc-form-field-details',
    templateUrl: './form-field-details.html'
})
export class FormFieldDetailsComponent implements OnInit, OnDestroy {

    @Input() control: UfControlGroup;

    readonly fieldKeys = FieldControlKeys;
    readonly identifierWarningLength = FORM_EDITOR_CONSTANTS.FIELD_IDENTIFIER_WARNING_LENGTH;
    readonly identifierMaxLength = FORM_EDITOR_CONSTANTS.FIELD_IDENTIFIER_MAX_LENGTH;

    ready: boolean;
    meta: FormFieldMetadata;
    controls: UfFormControl[];
    showContent: boolean;
    rolesResult: string[] = [];
    templateOptions: Option[];
    identifierSub: Subscription | undefined;
    showIdentifierWarningLength: boolean;
    descriptionActions: MessageAction[] = [{
        label: 'Edit',
        action: () => this.editDescription(),
        icon: 'edit',
        type: 'secondary'
    }, {
        label: 'Delete',
        action: () => this.descriptionControl?.setValue(null),
        icon: 'delete',
        type: 'primary'
    }];

    protected get isInvalid(): boolean {
        return this.controls.find(c => c.invalid) != null;
    }

    protected get description(): string | undefined {
        return this.descriptionControl?.value;
    }

    private get field(): FormEditorField {
        return this.control.getRawValue() as FormEditorField;
    }

    private get identifierControl(): UfControl {
        return this.control.get(FieldControlKeys.Identifier) as UfControl;
    }

    private get descriptionControl(): UfControl | undefined {
        return this.control.get(FieldControlKeys.Description) as UfControl | undefined;
    }

    constructor(
        private service: FormEditorService,
        private context: ContextService
    ) { }

    async ngOnInit() {

        this.meta = FormEditorFunctions.controlMetadata(this.control, this.context);
        this.controls = FORM_EDITOR_CONSTANTS.SECTION_DETAILS_FIELDS.map(k => this.control.get(k) as UfFormControl).filter(c => c != null);
        this.showContent = this.meta.help && this.field.type === FieldType.Content;
        this.templateOptions = FormEditorFunctions.fieldTemplateOptions(this.field);

        this.checkIdentifierMaxLength();
        this.identifierSub = this.identifierControl.valueChanges.subscribe(this.checkIdentifierMaxLength.bind(this));

        sortValue(this.control, FieldControlKeys.Roles);

        this.ready = true;
    }

    ngOnDestroy() {
        this.identifierSub?.unsubscribe();
    }

    protected async findRoles(query?: string) {
        this.rolesResult = await this.service.queryProjectRoles(query);
    }

    protected editDescription() {
        return this.service.editDescription(this.descriptionControl);
    }

    protected removeDescription() {
        if (this.descriptionControl) {
            this.descriptionControl.setValue(null);
        }
    }

    protected save() {
        this.service.saveTemplateField(this.field);
    }

    protected copy() {
        this.service.copyFields([this.field]);
    }

    protected paste() {
        if (this.meta.isContainer) {
            this.service.pasteFields(this.control);
        }
    }

    private checkIdentifierMaxLength() {
        this.showIdentifierWarningLength = (this.identifierControl.value ?? '').length > FORM_EDITOR_CONSTANTS.FIELD_IDENTIFIER_WARNING_LENGTH &&
            !this.identifierControl.showError;
    }
}