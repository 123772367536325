<div class="header">
    <button type="button" class="uf-action tertiary" (click)="close()" title="Close">
        <uf-icon name="close"></uf-icon>
    </button>
    <h3>{{data.fieldType}} Data Source ({{data.fieldIdentifier}})</h3>
</div>

<uf-panel *ngIf="form" class="container">
    <div [formGroup]="form" class="uf-grid pad">

        <ng-container class="col-12 uf-grid">
            <uf-blockquote *ngIf="data.fieldId" icon="warningSolid"
                content="Changing this datasource could cause a mismatch in any previously submitted forms."
                class="warning col-12">
            </uf-blockquote>

            <uf-select label="Type" placeholder="Select a type" [formControlName]="dataSourceControlKeys.Type"
                (valueChange)="typeChanged()" [options]="types" valueProperty="identifier" nameProperty="name"
                class="col-6">
            </uf-select>

            <div *ngIf="resourceLabel" class="col-6 row">
                <uf-autocomplete [label]="resourceLabel" placeholder="Search..."
                    [formControlName]="dataSourceControlKeys.Resource" (valueChange)="resourceChanged()"
                    (searchChange)="searchResource($event)" [options]="resourceOptions" nameProperty="name"
                    class="grow">
                </uf-autocomplete>

                <div class="gap-sm-top pad-md-top gap-left">
                    <a [routerLink]="resourceLink" title="Open"
                        [style.visibility]="resourceLink ? 'visible' : 'hidden' " target="_blank"
                        class="uf-action tertiary">
                        <uf-icon name="open"></uf-icon>
                    </a>
                </div>
            </div>
        </ng-container>

        <ng-template [ngIf]="isSourceSelected">

            <uc-data-source-mappings *ngIf="showMappings" [mappings]="mappingsControl"
                [properties]="status.mappableProperties" [fieldIdentifier]="data.fieldIdentifier" class="col-12">
            </uc-data-source-mappings>

            <uc-sort-property-input *ngIf="showSort" [properties]="status.sortableProperties" label="Sort"
                [formControlName]="dataSourceControlKeys.Sort" class="col-12 gap-top-sm">
            </uc-sort-property-input>

            <uc-find-by-property-input [properties]="status.findByProperties" label="Find By Field"
                [formControlName]="dataSourceControlKeys.FindBy" class="col-12 gap-top-sm">
            </uc-find-by-property-input>

            <ng-template [ngIf]="showFilter">

                <uc-visible-filters *ngIf="showVisibleFilters" [availableFilters]="status.inputFilterProperties"
                    [filters]="visibleFilters" [parentControl]="form" (filtersChange)="updateVisibleFilters($event)"
                    class="col-12">
                </uc-visible-filters>

                <div class="col-12 row">
                    <div class="left compact">
                        <h4 class="left">Hidden Filters</h4>
                    </div>
                    <a *ngIf="isAdvancedFilter" (click)="switchFilter()" title="Switch to standard"
                        class="uf-action tertiary right">
                        <uf-icon name="filter"></uf-icon>
                    </a>
                    <a *ngIf="!isAdvancedFilter" (click)="switchFilter()" title="Switch to advanced"
                        class="uf-action tertiary" right>
                        <uf-icon name="custom"></uf-icon>
                    </a>
                </div>

                <uc-filter-editor *ngIf="!isAdvancedFilter" [dataProperties]="status.staticFilterProperties"
                    [filter]="filter" [parentControl]="form" (filterChange)="updateFilter($event)" class="col-12">
                </uc-filter-editor>

                <uf-textarea *ngIf="isAdvancedFilter" [formControlName]="dataSourceControlKeys.AdvancedFilter"
                    class="col-12">
                </uf-textarea>
            </ng-template>

            <!-- External -->
            <ng-container *ngIf="status.externalInfo; let ext">
                <uf-message *ngIf="ext.dataSource.description" icon="infoSolid" [content]="ext.dataSource.description"
                    class="col-12 info">
                </uf-message>
                <uc-data-source-external-inputs [externalInputs]="externalInputsControl" class="col-12">
                </uc-data-source-external-inputs>
            </ng-container>

            <!-- Named (aka Custom) -->
            <ng-template [ngIf]="dataSourceType === dataSourceTypes.Named">
                <uf-text label="Name" [formControlName]="dataSourceControlKeys.NamedId" class="col-6"></uf-text>
            </ng-template>

        </ng-template>
    </div>
</uf-panel>

<div class="row space-children pad">
    <button type="button" class="uf-button tertiary right" (click)="close()">Cancel</button>
    <button type="button" class="uf-button primary" (click)="save()">Save</button>
</div>