import { Injectable, Type } from '@angular/core';
import { FilterEntry, IFilterComponent, UfFilterComponentRegistry } from '@unifii/library/common';

import { CustomChoiceFilterComponent } from 'components';


@Injectable()
export class LanguageFilterRegistry extends UfFilterComponentRegistry {

    get(entry: FilterEntry): Type<IFilterComponent> {
        if (entry.identifier === 'status') {
            return CustomChoiceFilterComponent;
        }
        return super.get(entry);
    }

}