import { Subscription } from 'rxjs';

import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { CompoundType } from '@unifii/sdk';

import { BuilderEventInfo, BuilderService } from './builder.service';


@Component({
    selector: 'uc-builder-fields-header',
    templateUrl: './builder-fields-header.html',
    styleUrls: ['./builder-fields-header.less']
})
export class BuilderFieldsHeaderComponent implements OnInit, OnDestroy {

    @HostBinding('class.builder-fields-header') class = true;
    ready = false;
    isSelected = true;
    isValid = true;

    private subscribers: Subscription[] = [];

    constructor(private builderService: BuilderService) { }

    ngOnInit() {

        this.subscribers.push(this.builderService.ready.subscribe(() =>
            this.ready = true
        ));

        this.subscribers.push(this.builderService.fieldSelected.subscribe((i: BuilderEventInfo = { subject: null }) => {

            this.isSelected = i.subject == null;
            // Validate if user selected a field without filling in all required metadata
            this.isValid = this.builderService.isValid(this.builderService.definition);
        }));

        this.subscribers.push(this.builderService.fieldEdited.subscribe(i =>
            this.refreshErrorsStatus(i)
        ));

        this.subscribers.push(this.builderService.fieldRefreshed.subscribe(i =>
            this.refreshErrorsStatus(i)
        ));
    }

    ngOnDestroy() {
        this.subscribers.forEach(s => s.unsubscribe());
    }

    get title() {
        if (this.builderService.definition) {
            switch (this.builderService.builder.type) {
                case CompoundType.Page: return this.builderService.definition.recordName || 'Untitled';
                case CompoundType.Form: return this.builderService.definition.label || 'Untitled';
                case CompoundType.Collection: return this.builderService.definition.label || 'Untitled';
                case CompoundType.View: return this.builderService.compound._title || 'Untitled';
                default: return this.builderService.definition.label || 'Untitled';
            }
        }
    }

    onSelect() {
        this.builderService.fieldSelect.next(null);
    }

    private isMe(builderEvent: BuilderEventInfo = { subject: null }): boolean {
        return builderEvent.subject != null && builderEvent.subject === this.builderService.definition;
    }

    private refreshErrorsStatus(builderEvent: BuilderEventInfo) {

        if (this.isMe(builderEvent)) {
            // console.log('BuilderEventInfo update errors status');
            this.isValid = this.builderService.isValid(this.builderService.definition);
        }
    }
}
