import { Subscription } from 'rxjs';

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import { Breadcrumb, ToastService, UfControlGroup } from '@unifii/library/common';

import { SchemaInfo, Timer, UcProject } from 'client';
import { UcWorkflow } from 'client/workflow';

import { EditData } from 'components/common/edit-data';

import { BreadcrumbService } from 'services/breadcrumb.service';

import { WorkflowActivityTableManager } from './workflow-activity-table-manager';
import { ControlKeys, WorkflowTimerFormController, WorkflowTimerModel } from './workflow-timers-form.controller';


@Component({
    templateUrl: 'workflow-timers-form.html'
})
export class WorkflowTimersFormComponent implements EditData, OnInit, OnDestroy {

    readonly controlKeys = ControlKeys;

    form: UfControlGroup;
    bucketsResult: SchemaInfo[];
    breadcrumbs: Breadcrumb[];
    edited = false;
    error?: string;

    private subscriptions = new Subscription();

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private ucProject: UcProject,
        private ucWorkflow: UcWorkflow,
        private toastService: ToastService,
        private formController: WorkflowTimerFormController,
        private breadcrumbService: BreadcrumbService,
        @Inject(TableContainerManager) private tableManager: WorkflowActivityTableManager
    ) { }

    async ngOnInit() {
        const { id, duplicate } = this.route.snapshot.params;
        let formModel: WorkflowTimerModel | undefined;

        try {
            const timer = await this.getTimer(id);
            if (timer != null) {
                formModel = this.formController.toModel(timer);

                if (!!duplicate) {
                    formModel.id = null as any as string;
                    formModel.label += ' (copy)';
                }
            }

            this.form = this.formController.buildRoot(formModel);

            this.subscriptions.add(this.form.valueChanges.subscribe(() => this.edited = true));

            this.breadcrumbs = this.breadcrumbService.getBreadcrumbs(this.route, [timer?.label ?? 'New']);
        } catch (e) {
            this.error = e.message;
            console.error(e);
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    async searchBuckets(q: string) {
        this.bucketsResult = await this.ucProject.getFormBuckets({ params: { q } });
    }

    async save() {
        this.error = undefined;
        this.form.setSubmitted();

        if (!this.form.valid) {
            return;
        }

        const wfTimerModel = this.form.getRawValue() as WorkflowTimerModel;

        try {
            let updatedTimer;
            if (!wfTimerModel.id) {
                updatedTimer = await this.ucWorkflow.addActivity<Timer>(this.formController.toData(wfTimerModel));
                this.toastService.success('Timer saved successfully');
                this.tableManager.reload.next();
            } else {
                updatedTimer = await this.ucWorkflow.updateActivity<Timer>(this.formController.toData(wfTimerModel));
                this.toastService.success('Timer updated successfully');
                this.tableManager.updateItem.next(updatedTimer);
            }

            this.edited = false;
            this.router.navigate(['../'], { relativeTo: this.route });
        } catch (e) {
            this.error = e.message;
        }
    }

    private async getTimer(id: string): Promise<Timer | null> {
        if (id === 'new') {
            return null;
        }
        return this.ucWorkflow.getActivity<Timer>(id);
    }

}