<div class="header">
    <button (click)="close()" type="button" class="uf-action tertiary" title="Close">
        <uf-icon name="close"></uf-icon>
    </button>
    <h3>Select Devices</h3>
</div>

<div class="content">
    <uf-search (searchChange)="filter($event)" [autofocus]="true">
    </uf-search>
</div>

<uf-panel class="container">
    <ul class="uc-list--joined list">
        <li *ngIf="!filteredDevices.length" class="uc-list-item--small padded--vertical">
            Sorry, there was no result for your search.
        </li>

        <li *ngFor="let device of filteredDevices" class="uc-list-item--bottom-border--small row center-all list-item"
            [class.selected]="selectedMap[device.id]" (click)="select(device.id)">
            <span class="grow">
                {{ device.name }} - {{ device.model }} - {{ device.id}}
            </span>
            <uf-icon name="tick" *ngIf="selectedMap[device.id]"></uf-icon>
        </li>
    </ul>
</uf-panel>

<div class="row space-children pad">
    <button type="button" class="uf-button tertiary right" (click)="close()">Close</button>
    <button type="button" class="uf-button primary" (click)="save()">Add</button>
</div>