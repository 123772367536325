import { Component, HostBinding } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TableContainerManager } from '@unifii/components';

import { BreadcrumbService } from 'services/breadcrumb.service';

import { BucketsTableManager } from './buckets-table-manager';


@Component({
    providers: [
        { provide: TableContainerManager, useClass: BucketsTableManager }
    ],
    template: `
    <div ufMasterDetail>
        <uf-table-container ufSyncRoute class="accent list-md">
            <uf-breadcrumbs title [breadcrumbs]="breadcrumbs" class="large"></uf-breadcrumbs>
        </uf-table-container>
    </div>
    <router-outlet></router-outlet>
`
})
export class BucketsComponent {

    @HostBinding('class.stretch-component') class = true;

    breadcrumbs = this.breadcrumbService.getBreadcrumbs(this.route);

    constructor(
        private breadcrumbService: BreadcrumbService,
        private route: ActivatedRoute
    ) { }

}
