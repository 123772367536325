<div class="header-pane uf-app-bar transparent">
    <uf-breadcrumbs [breadcrumbs]="breadcrumbs" class="large">
        <span class="gap-sm-left" *ngIf="edited">*</span>
    </uf-breadcrumbs>
    <a class="uf-button tertiary right" routerLink="..">
        Cancel
    </a>
    <button type="button" class="uf-button primary" (click)="save()">
        Save
    </button>
</div>
<uf-panel class="content-pane container">
    <div class="uf-container-lg">
        <div class="uf-grid col-12 pad-top">
            <uf-skeleton *ngIf="!error && !root" class="gap-none col-12"></uf-skeleton>

            <uf-message *ngIf="error" [content]="error.message" icon="error" class="error gap-bottom col-12">
            </uf-message>

            <ng-container *ngIf="root" [formGroup]="root">
                <div class="uf-box col-12">
                    <div class="uf-app-bar flat accent">
                        <div class="title">
                            Details
                        </div>
                    </div>
                    <div class="uf-grid pad">
                        <uf-text class="col-12" label="Title" [formControlName]="controlKeys.Label">
                            <span class="suffix">*</span>
                        </uf-text>
                        <uf-autocomplete *ngIf="root.get(controlKeys.Bucket)?.enabled" class="col-12"
                            label="Form Data Repository" (valueChange)="bucketChange($event)"
                            [formControlName]="controlKeys.Bucket" (searchChange)="searchBuckets($event)"
                            [options]="bucketsResult" placeholder="Search form data repository" nameProperty="name">
                        </uf-autocomplete>
                    </div>
                </div>

                <div class="uf-box col-12">
                    <div class="uf-app-bar flat accent">
                        <div class="title">
                            Recipients
                        </div>
                    </div>

                    <div *ngFor="let recipient of recipients.controls; let i = index" class="uf-box">
                        <uf-expander dragItem [dragDisabled]="true" [isExpanded]="true">
                            <div expanderHeader class="uf-app-bar flat">
                                <div class="title">
                                    {{recipientLabel(i)}}
                                </div>
                                <button (click)="removeRecipient(i)" title="Delete" class="uf-action">
                                    <uf-icon name="delete"></uf-icon>
                                </button>
                            </div>
                            <div expanderBody class="uf-grid pad">

                                <!-- Form Data -->
                                <ng-template
                                    [ngIf]="$any(recipient).get(controlKeys.Type)?.value === workflowNotificationRecipientType.FormData">

                                    <!-- Form Data User / User Manager - Datasources -->
                                    <uf-autocomplete class="col-12" *ngIf="isFormDataUserDatasource($any(recipient))"
                                        [options]="filteredUserDatasources" label="User DataSource"
                                        (searchChange)="searchUserDatasource($event)"
                                        [control]="$any(recipient).get(controlKeys.FormData).get(controlKeys.Value)"
                                        placeholder="Select a DataSource">
                                    </uf-autocomplete>

                                    <!-- Form Data Email Field -->
                                    <uf-autocomplete class="col-12"
                                        *ngIf="$any(recipient).get(controlKeys.FormData)?.get(controlKeys.Type)?.value === workflowNotificationRecipientFormDataType.Email"
                                        [options]="filteredEmailFields" (searchChange)="searchEmailFields($event)"
                                        [control]="$any(recipient).get(controlKeys.FormData).get(controlKeys.Value)"
                                        label="Email Fields" nameProperty="name" placeholder="Select a Field">
                                    </uf-autocomplete>

                                </ng-template>

                                <!-- Email -->
                                <uf-email class="col-12"
                                    *ngIf="$any(recipient).get(controlKeys.Type)?.value === workflowNotificationRecipientType.Email"
                                    [control]="$any(recipient).get(controlKeys.Email)" label="Email">
                                </uf-email>

                                <!-- Role -->
                                <uf-autocomplete class="col-12"
                                    *ngIf="$any(recipient).get(controlKeys.Type)?.value === workflowNotificationRecipientType.Role"
                                    [options]="filteredRoles" (searchChange)="searchRole($event)"
                                    [control]="$any(recipient).get(controlKeys.Role)" label="Role"
                                    placeholder="Select a role">
                                </uf-autocomplete>

                                <!-- User -->
                                <uf-autocomplete class="col-12"
                                    *ngIf="$any(recipient).get(controlKeys.Type)?.value === workflowNotificationRecipientType.User"
                                    label="User" (searchChange)="searchUsers($event)" [options]="users"
                                    [control]="$any(recipient).get(controlKeys.User)" nameProperty="username"
                                    valueProperty="username"></uf-autocomplete>

                                <!-- Claim -->
                                <ng-template
                                    [ngIf]="$any(recipient).get(controlKeys.Type)?.value === workflowNotificationRecipientType.Claim">
                                    <uf-autocomplete class="col-6" label="Claim" [options]="filteredUserClaims"
                                        (valueChange)="changeClaim($event, recipientClaimControl(i))"
                                        [control]="$any(recipient).get(controlKeys.Claim).get(controlKeys.Type)"
                                        placeholder="Search Claims" (searchChange)="searchUserClaims($event)"
                                        [allowCustom]="false">
                                    </uf-autocomplete>

                                    <uf-select class="col-6"
                                        *ngIf="!!$any(recipient).get(controlKeys.Claim).get(controlKeys.MatchType)"
                                        label="Match Type" [options]="claimMatchOptions" valueProperty="identifier"
                                        nameProperty="name"
                                        [control]="$any(recipient).get(controlKeys.Claim).get(controlKeys.MatchType)"
                                        placeholder="Select a match type">
                                    </uf-select>

                                    <!-- Match type = Value - Claim Editor -->
                                    <uf-claim-field
                                        *ngIf="!!$any(recipient).get(controlKeys.Claim).get(controlKeys.ClaimConfig)?.value && isValueClaim($any(recipient))"
                                        [control]="$any(recipient).get(controlKeys.Claim).get(controlKeys.Value)"
                                        [claimConfig]="$any(recipient).get(controlKeys.Claim).get(controlKeys.ClaimConfig)?.value"
                                        class="col-6">
                                    </uf-claim-field>

                                    <!-- Match type = User -->
                                    <uf-autocomplete class="col-6" *ngIf="isMatchAgainst($any(recipient))"
                                        label="User Claim" placeholder="Search Claims"
                                        (searchChange)="searchUserClaims($event)" [allowCustom]="true"
                                        [options]="filteredUserClaims"
                                        [control]="$any(recipient).get(controlKeys.Claim).get(controlKeys.MatchAgainst)">
                                    </uf-autocomplete>

                                    <!-- Match type = Form Data -->
                                    <uf-autocomplete class="col-6"
                                        *ngIf="$any(recipient).get(controlKeys.Claim)?.get(controlKeys.MatchType)?.value === workflowNotificationRecipientClaimMatchType.FormData"
                                        label="Claim DataSource" placeholder="Search Claims"
                                        (searchChange)="searchClaim($event)" [allowCustom]="false"
                                        [options]="filteredClaimDatasources"
                                        [control]="$any(recipient).get(controlKeys.Claim).get(controlKeys.Value)">
                                    </uf-autocomplete>

                                </ng-template>

                                <!-- Combo -->
                                <div *ngIf="$any(recipient).get(controlKeys.Type)?.value === workflowNotificationRecipientType.Combo"
                                    class="col-12 uf-grid">
                                    <div *ngFor="let conditionGroup of $any(recipient).get(controlKeys.Conditions).controls; let ci = index"
                                        class="uf-box flat col-12 pad uf-grid">

                                        <!-- <uf-select class="col-6" label="Types" [options]="conditionTypes"
                                            [control]="$any(conditionGroup).get(controlKeys.Type)"
                                            placeholder="Select a condition type">
                                        </uf-select> -->

                                        <!-- Condition Role -->
                                        <uf-autocomplete
                                            *ngIf="$any(conditionGroup).get(controlKeys.Type)?.value === workflowNotificationConditionType.Role"
                                            class="col-11" label="Role" [options]="filteredRoles"
                                            (searchChange)="searchRole($event)"
                                            [control]="$any(conditionGroup).get(controlKeys.Role)"
                                            placeholder="Select a role">
                                        </uf-autocomplete>

                                        <!-- Condition Company -->
                                        <uf-autocomplete
                                            *ngIf="$any(conditionGroup).get(controlKeys.Type)?.value === workflowNotificationConditionType.Company && !isUserManagementNotification"
                                            class="col-11" label="Company" [options]="filteredCompanyDatasources"
                                            (searchChange)="searchCompanyDatasource($event)"
                                            [control]="$any(conditionGroup).get(controlKeys.Company)"
                                            placeholder="Select a company">
                                        </uf-autocomplete>

                                        <!-- Condition Hierarchy -->
                                        <ng-template
                                            [ngIf]="$any(conditionGroup).get(controlKeys.Type)?.value === workflowNotificationConditionType.Hierarchy">
                                            <uf-autocomplete *ngIf="!isUserManagementNotification" class="col-12"
                                                [options]="filteredHierarchyFields"
                                                (searchChange)="searchHierarchyField($event)" valueProperty="identifier"
                                                nameProperty="name" label="Hierarchy Field"
                                                [control]="$any(conditionGroup).get(controlKeys.FormData)"
                                                placeholder="Select a Field">
                                            </uf-autocomplete>

                                            <uf-hierarchy-unit class="col-11" label="Hierarchy Unit"
                                                [control]="$any(conditionGroup).get(controlKeys.HierarchyUnit)"
                                                [selectLeafsOnly]="false" placeholder="Root">
                                            </uf-hierarchy-unit>
                                        </ng-template>

                                        <!-- Condition Claim -->
                                        <ng-template
                                            [ngIf]="$any(conditionGroup).get(controlKeys.Type)?.value === workflowNotificationConditionType.Claim">
                                            <div class="col-11 uf-grid">
                                                <uf-autocomplete class="col-5" label="Claim"
                                                    [control]="$any(conditionGroup).get(controlKeys.Claim).get(controlKeys.Type)"
                                                    placeholder="Search Claims"
                                                    (searchChange)="searchUserClaims($event)"
                                                    (valueChange)="changeClaim($event, $any(conditionGroup).get(controlKeys.Claim))"
                                                    [allowCustom]="true" [options]="filteredUserClaims">
                                                </uf-autocomplete>

                                                <uf-select [options]="claimMatchOptions"
                                                    *ngIf="!!$any(conditionGroup).get(controlKeys.Claim).get(controlKeys.MatchType)"
                                                    [control]="$any(conditionGroup).get(controlKeys.Claim).get(controlKeys.MatchType)"
                                                    valueProperty="identifier" nameProperty="name"
                                                    placeholder="Select a match type" class="col-6" label="Match Type">
                                                </uf-select>

                                                <!-- Match type = Form Data -->
                                                <uf-autocomplete class="col-6"
                                                    *ngIf="$any(conditionGroup).get(controlKeys.Claim)?.get(controlKeys.MatchType)?.value === workflowNotificationRecipientClaimMatchType.FormData"
                                                    label="Claim DataSource" placeholder="Search Claims"
                                                    (searchChange)="searchFormDataClaim($event)" [allowCustom]="false"
                                                    [options]="filteredFormDataClaimDatasources"
                                                    [control]="$any(conditionGroup).get(controlKeys.Claim).get(controlKeys.Value)">
                                                </uf-autocomplete>

                                                <!-- Match type = Value - Claim Editor -->
                                                <uf-claim-field *ngIf="isValueClaim($any(conditionGroup))"
                                                    [control]="$any(conditionGroup).get(controlKeys.Claim).get(controlKeys.Value)"
                                                    [claimConfig]="$any(conditionGroup).get(controlKeys.Claim).get(controlKeys.ClaimConfig).value"
                                                    class="col-5">
                                                </uf-claim-field>

                                                <!-- Match Type = CreatedBy/LastModifiedBy -->
                                                <uf-autocomplete class="col-5"
                                                    *ngIf="isMatchAgainst($any(conditionGroup))" label=" User Claim"
                                                    placeholder="Search Claims"
                                                    (searchChange)="searchUserClaims($event)" [allowCustom]="true"
                                                    [options]="filteredUserClaims"
                                                    [control]="$any(conditionGroup).get(controlKeys.Claim).get(controlKeys.MatchAgainst)">
                                                </uf-autocomplete>
                                            </div>

                                        </ng-template>

                                        <button title="Remove Condition"
                                            class="col-1 uf-action tertiary center grow right"
                                            (click)="recipientConditionsControls(i).removeAt(ci)">
                                            <uf-icon name="delete"></uf-icon>
                                        </button>
                                    </div>

                                    <div class="col-12 right row"
                                        *ngIf="recipientTypeControl(i).value === workflowNotificationRecipientType.Combo">
                                        <uf-error [control]="$any(recipient).get(controlKeys.TwoConditions)"
                                            class="gap-right">
                                        </uf-error>
                                        <button type="button" class="uf-button x-small right gap-sm-top" listBox
                                            [options]="conditionTypes" nameProperty="name"
                                            (optionSelected)="addCondition($event, $any(recipient).get(controlKeys.Conditions))">
                                            Add Condition
                                            <uf-icon name="more" class="gap-none"></uf-icon>
                                        </button>
                                    </div>
                                </div>

                                <!-- End of Combo -->

                                <uf-checkbox class="col-12" label="LiveOnly"
                                    [control]="$any(recipient).get(controlKeys.LiveOnly)">
                                </uf-checkbox>
                            </div>
                        </uf-expander>
                    </div>
                    <div class="pad border-top">
                        <button [options]="recipientTypes" (optionSelected)="addRecipient($event)"
                            [disabled]="!canAddRecipient" listBox type="button"
                            class="uf-button x-small right gap-sm-top" nameProperty="name">
                            Add Recipient
                            <uf-icon name="more" class="gap-none"></uf-icon>
                        </button>
                    </div>
                </div>

                <!-- Messages -->
                <div class="uf-box col-12">
                    <div class="uf-app-bar flat accent">

                        <div class="title">
                            Email Notification
                        </div>

                        <button *ngIf="hasEmail?.value" type="button" class="uf-action tertiary"
                            (click)="showGlossary(workflowNotificationDeliveryMethod.Email)" title="Glossary">
                            <uf-icon name="info"></uf-icon>
                        </button>
                        <button *ngIf="hasEmail?.value" type="button" class="uf-action tertiary"
                            (click)="previewMessage(workflowNotificationDeliveryMethod.Email)" title="Preview">
                            <uf-icon name="view"></uf-icon>
                        </button>
                        <button type="button" class="uf-action tertiary" (click)="hasEmail.setValue(!hasEmail.value);"
                            [title]="hasEmail.value ? 'Disable Email Notifications' : 'Enable Email Notifications'">
                            <uf-icon *ngIf="!hasEmail.value" name="checkbox"></uf-icon>
                            <uf-icon *ngIf="hasEmail.value" name="checkboxTick"></uf-icon>
                        </button>

                    </div>

                    <div *ngIf="hasEmail.value" class="content row notification-type pad">
                        <div class="grow">
                            <uf-text label="Title" [value]="email.title.value" [control]="email.title"
                                class="gap-bottom">
                            </uf-text>
                            <uf-markdown-editor #emailMarkdownEditor label="Message" [control]="email.body">
                            </uf-markdown-editor>
                            <ng-template *ngIf="!isUserManagementNotification" ufFeatureFlag="pdfRendering">
                                <uf-checkbox label="Attach form as PDF" [control]="email.attachFormAsPdf">
                                </uf-checkbox>
                            </ng-template>
                            <uf-text label="Reply to" [control]="email.replyTo">
                                <uf-help content="Comma separate multiple email addresses."></uf-help>
                            </uf-text>
                        </div>
                    </div>

                </div>

                <div class="uf-box col-12">
                    <div class="uf-app-bar flat accent">
                        <div class="title">
                            Push Notification
                        </div>

                        <button *ngIf="hasPushNotification?.value" type="button" class="uf-action tertiary"
                            (click)="showGlossary(workflowNotificationDeliveryMethod.Push)" title="Glossary">
                            <uf-icon name="info"></uf-icon>
                        </button>
                        <button *ngIf="hasPushNotification?.value" type="button" class="uf-action tertiary"
                            (click)="previewMessage(workflowNotificationDeliveryMethod.Push)" title="Preview">
                            <uf-icon name="view"></uf-icon>
                        </button>
                        <button type="button" class="uf-action tertiary"
                            (click)="hasPushNotification.setValue(!hasPushNotification.value);"
                            [title]="hasPushNotification.value ? 'Disable Push Notification Notifications' : 'Enable Push Notification Notifications'">
                            <uf-icon *ngIf="!hasPushNotification.value" name="checkbox"></uf-icon>
                            <uf-icon *ngIf="hasPushNotification.value" name="checkboxTick"></uf-icon>
                        </button>

                    </div>
                    <div *ngIf="hasPushNotification.value" class="content row notification-type pad">
                        <div class="grow">
                            <uf-text label="Title" [control]="pushNotification.title">
                            </uf-text>
                            <uf-textarea #pushTextArea label="Message" [control]="pushNotification.body">
                            </uf-textarea>
                        </div>
                    </div>
                </div>

                <uf-error [control]="$any(root.get(controlKeys.AtLeastOneNotification))" class="col-12"></uf-error>
            </ng-container>
        </div>
    </div>

</uf-panel>