import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { DataPropertyDescriptor, SortStatus, UfControlValueAccessor } from '@unifii/library/common';
import { SortDirections } from '@unifii/sdk';


@Component({
    selector: 'uc-sort-property-input',
    templateUrl: 'sort-property-input.html',
    providers: [{
        provide: NG_VALUE_ACCESSOR, useExisting: SortPropertyInputComponent, multi: true
    }]
})
export class SortPropertyInputComponent extends UfControlValueAccessor<string> implements OnInit {

    @Input() label: string | null | undefined;
    @Input() properties: DataPropertyDescriptor[];
    @Output() valueChange = new EventEmitter<string>();

    sortProperty: DataPropertyDescriptor | null;
    sortResults: DataPropertyDescriptor[];
    sortAscending: boolean;

    ngOnInit() {
        const sort = SortStatus.fromString(this.value);
        this.sortProperty = sort ? this.properties.find(c => c.identifier === sort.name) || null : null;
        this.sortAscending = sort ? sort.direction === SortDirections.Ascending : false;
    }

    encodeSort() {
        if (this.sortProperty == null) {
            this.value = undefined;
        } else {
            const sort = new SortStatus(
                this.sortProperty.identifier,
                this.sortAscending ? SortDirections.Ascending : SortDirections.Descending
            );
            this.value = sort.toString();
        }

        this.control.markAsTouched();
        this.valueChange.emit(this.value);
    }

    async search(q?: string) {
        this.sortResults = this.properties.filter(dp => {
            if (q && q.trim().length) {
                return (dp.display as string).toLowerCase().indexOf(q.toLowerCase()) > -1;
            }
            return true;
        });
    }
}