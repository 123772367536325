<div *ngIf="showAsCard; else showFlat" class="uf-box col-12">

    <div class="uf-app-bar flat accent">
        <div class="title">
            Visible Filters
        </div>
        <uc-expander-controls [container]="expandAllContainer" class="primary">
        </uc-expander-controls>
    </div>

    <div #expandAllContainer class="uc-group-content pad-sides pad-bottom pad-sm-top">
        <ng-container *ngTemplateOutlet="filters"></ng-container>
    </div>

</div>

<ng-template #showFlat>
    <h4>Visible Filters</h4>
    <ng-container *ngTemplateOutlet="filters"></ng-container>
</ng-template>

<ng-template #filters>
    <uc-drag-list *ngIf="items.length" [items]="items" (moved)="emit()" canDrop="false" canReorder="true">
        <ng-template ngFor let-item [ngForOf]="items" let-i="index">

            <uf-expander *ngIf="item.hasConfiguration" [isExpanded]="false" dragItem
                class="uf-box draggable flat gap-sm-top">
                <div class="uf-app-bar" expanderHeader dragHandle>
                    <uf-icon [name]="item.icon"></uf-icon>
                    <div class="grow title primary">{{item.label}}</div>
                    <span *ngIf="!item.dataPropertyDescriptor" class="uf-lozenge error">Missing</span>
                    <button class="uf-action tertiary" (click)="removeFilter(i)" title="Remove">
                        <uf-icon name="delete"></uf-icon>
                    </button>
                </div>
                <ng-container expanderBody>
                    <uc-visible-filter [parentControl]="parentControl" [filterInfo]="item" (filterChange)="emit()">
                    </uc-visible-filter>
                </ng-container>
            </uf-expander>

            <div *ngIf="!item.hasConfiguration" dragItem class="uf-box draggable flat gap-sm-top">
                <div class="uf-app-bar" dragHandle>
                    <uf-icon [name]="item.icon"></uf-icon>
                    <div class="grow title primary">{{item.label}}</div>
                    <span *ngIf="!item.dataPropertyDescriptor" class="uf-lozenge error">Missing</span>
                    <button class="uf-action tertiary" (click)="removeFilter(i)" title="Remove">
                        <uf-icon name="delete"></uf-icon>
                    </button>
                </div>
            </div>

        </ng-template>
    </uc-drag-list>
    <uf-search-result (valueChange)="addFilter({identifier: $event.identifier})" (searchChange)="filterFilters($event)"
        [options]="filterResults" placeholder="Search filter" nameProperty="display" class="gap-top">
    </uf-search-result>
</ng-template>