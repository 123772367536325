import { Injectable } from '@angular/core';
import { DataDescriptorService, UfControlArray, UfControlGroup, UfFormBuilder, ValidatorFunctions } from '@unifii/library/common';
import { SchemaField } from '@unifii/sdk';

import { ActivityType, DataForwarder, IntegrationInfo, IntegrationProviderFeature, UcClient, UcIntegrations } from 'client';


interface WorkflowInputMap {
    identifier: string;
    field?: SchemaField;
    isRequired: boolean;
}

interface InputDataModelData {
    feature: IntegrationProviderFeature;
    inputMap?: WorkflowInputMap[];
}

export interface WorkflowDataForwardersModel {
    id: string;
    label: string;
    bucket: { id: string };
    integration: IntegrationInfo;
    feature: IntegrationProviderFeature;
    inputMap: WorkflowInputMap[];
}

export enum ControlKeys {
    Id = 'id',
    Label = 'label',
    Bucket = 'bucket',
    Integration = 'integration',
    Feature = 'feature',
    InputMap = 'inputMap',
}

@Injectable({
    providedIn: 'root'
})
export class WorkflowDataForwardersFormController {

    private readonly requiredMessage = 'Field is Required';

    constructor(
        private ufb: UfFormBuilder,
        private ucIntegrations: UcIntegrations,
        private ucClient: UcClient,
        private dataDescriptorService: DataDescriptorService,
    ) { }

    buildRoot(wfDataForwardersModel?: WorkflowDataForwardersModel): UfControlGroup {
        const groupControl = this.ufb.group({
            [ControlKeys.Id]: [{ value: wfDataForwardersModel?.id, disabled: true }],
            [ControlKeys.Label]: [wfDataForwardersModel?.label, ValidatorFunctions.required(this.requiredMessage)],
            [ControlKeys.Bucket]: [wfDataForwardersModel?.bucket],
            [ControlKeys.Integration]: wfDataForwardersModel?.integration,
            [ControlKeys.Feature]: wfDataForwardersModel?.feature,

        });

        if (wfDataForwardersModel?.feature && wfDataForwardersModel?.bucket) {
            groupControl.addControl(ControlKeys.InputMap, this.buildInputMapControl({ feature: wfDataForwardersModel.feature, inputMap: wfDataForwardersModel.inputMap }));
        }

        return groupControl;
    }

    async toModel(data?: DataForwarder | null): Promise<WorkflowDataForwardersModel | undefined> {

        if (!data) {
            return;
        }

        const { id, bucket, featureId, inputMap, integrationId, label } = data;
        const fields = [];

        if (bucket) {
            const { propertyDescriptors } = await this.dataDescriptorService.getBucketDataDescriptor(bucket) ?? { propertyDescriptors: [] };
            fields.push(...propertyDescriptors);
        }

        let integration;
        if (integrationId) {
            integration = await this.ucIntegrations.get(integrationId);

        }

        let feature;
        if (featureId && integration) {
            feature = (await this.loadFeature(integration.provider.id)).find(f => f.id === featureId);
        }

        const inputMapInfo: WorkflowInputMap[] = [];
        if (inputMap && feature) {
            for (const inputArg of (feature.inputArgs ?? [])) {
                let field;
                if (inputMap) {
                    field = fields.find(f => f.identifier === inputMap[inputArg.identifier]);
                }
                inputMapInfo.push({
                    identifier: inputArg.identifier,
                    isRequired: !!inputArg.isRequired,
                    field
                });
            }
        }

        return { id, label, bucket: { id: bucket }, integration, feature, inputMap: inputMapInfo } as any as WorkflowDataForwardersModel;
    }

    toData(model: WorkflowDataForwardersModel): DataForwarder {
        const { id, label, integration, feature, inputMap: inputMapInfo } = model;

        let bucketId;
        if (model.bucket) {
            bucketId = model.bucket.id;
        }

        const inputMap = (inputMapInfo || []).reduce((acc, ip) => ({ ...acc, [ip.identifier]: ip.field?.identifier }), {});
        return {
            id,
            label,
            bucket: bucketId,
            integrationId: integration.id,
            featureId: feature.id,
            inputMap,
            type: ActivityType.DataForwarder
        } as any as DataForwarder;
    }

    buildInputMapControl({ feature, inputMap }: InputDataModelData): UfControlArray {
        const arrayControl = this.ufb.array([]);
        const customValidator = ValidatorFunctions.custom((value: WorkflowInputMap) => !!value?.field, this.requiredMessage);
        for (const inputArg of (feature.inputArgs ?? [])) {
            let field;

            if (inputMap) {
                field = inputMap.find(item => item.identifier === inputArg.identifier)?.field;
            }

            if (inputArg.isRequired) {
                arrayControl.push(this.ufb.control({ field, identifier: inputArg.identifier, isRequired: inputArg.isRequired }, customValidator));
            } else {
                arrayControl.push(this.ufb.control({ field, identifier: inputArg.identifier, isRequired: inputArg.isRequired }));
            }
        }
        return arrayControl;
    }

    private async loadFeature(providerId: string) {
        const provider = await this.ucClient.getAvailableIntegration(providerId);
        return (provider.features ?? []);
    }

}