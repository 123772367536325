import { UfControlGroup, ValidatorFunctions } from '@unifii/library/common';
import { DefinitionPublishState, StructureNode, StructureNodeType } from '@unifii/sdk';

import { UcStructure } from 'client';

import { ArrayHelper } from 'helpers/array-helper';

import { defaultBucketPageSize } from './structure-constants';
import { StructureNodeControlKeys } from './structure-control-keys';


/** Find all nodes of a specific type in the structure */
const getAllNodesOfType = (structure: UcStructure, type: StructureNodeType): StructureNode[] =>
    ArrayHelper.flatTree(structure).filter(n => n.type === type);

/** Find the max nodeId in the structure and return the next one free to be used */
const getNextAvailableNodeId = (structure: UcStructure): number => {

    let lastUsed: number;
    if (structure.lastNodeId) {
        lastUsed = parseInt(structure.lastNodeId);
    } else {
        let ids = ArrayHelper.flatTree(structure)
            .filter(n => n.nodeId)
            .map(n => parseInt(n.nodeId));

        if (ids.length === 0) {
            ids = [0];
        }
        lastUsed = Math.max.apply(null, ids);
    }

    return lastUsed + 1;
};

/** Fix inconsistencies and errors */
const cleanUp = (structure: UcStructure): void => {

    // All nodes
    const nodes = ArrayHelper.flatTree(structure);

    // Apply nodeIds where missing and update lastNodeId
    structure.nodeId = '0'; // Default
    let next = getNextAvailableNodeId(structure);
    nodes.forEach(node => {
        if (!node.nodeId) {
            node.nodeId = next.toString();
            next++;
        }
    });
    structure.lastNodeId = (next - 1).toString();

    // Fix node general attributes
    delete (structure as any).recordName;
    nodes.forEach(node => {
        node.roles = node.roles || [];
        delete (node as any).recordName;
    });

    // Remove fron Dashboard.buckets entries not defined in the structure tree
    nodes.forEach(node => {
        fixDashboardNode(structure, node);
        fixUserFilters(node);
    });
};

const isNodeRoot = (node: StructureNode): boolean =>
    node.nodeId == null || node.nodeId === '0';

const isNodeLinkedToContent = (node: StructureNode): boolean =>
    [
        StructureNodeType.Page,
        StructureNodeType.View,
        StructureNodeType.PdfViewer,
        StructureNodeType.Form,
        StructureNodeType.Collection,
        StructureNodeType.CollectionItem,
        StructureNodeType.FormBucket
    ].includes(node.type);


/** The node content is published or doesn't need to be published */
const isNodeContentPublished = (node: StructureNode): boolean =>
    !isNodeLinkedToContent(node) || node.publishState === DefinitionPublishState.Published || node.type === StructureNodeType.PdfViewer;

const hasVariations = (node: StructureNode): boolean =>
    isNodeRoot(node) && ((node as UcStructure).variations?.length ?? 0) > 0;

const isNodeAccessRestricted = (node: StructureNode): boolean =>
    (node.roles?.length ?? 0) > 0 || (node.claims?.length ?? 0) > 0;

const removeUnvalorizedAttributes = (structure: UcStructure) => {
    const nodes = ArrayHelper.flatTree(structure);

    for (const node of nodes) {
        Object.keys(node).forEach(k => {
            const value = node[k] as any;
            if (Array.isArray(value) && value.length === 0) {
                delete node[k];
                return;
            }
            if (ValidatorFunctions.isEmpty(value)) {
                delete node[k];
                return;
            }
        });
    }
};

const isANodeControl = (control: UfControlGroup): boolean =>
    control.controls &&
    control.controls[StructureNodeControlKeys.NodeId] != null &&
    control.controls[StructureNodeControlKeys.NodeId].value !== '0' &&
    control.controls[StructureNodeControlKeys.Type] != null;


export const StructureFunctions = {
    getAllNodesOfType,
    getNextAvailableNodeId,
    isNodeRoot,
    isNodeLinkedToContent,
    isNodeContentPublished,
    isNodeAccessRestricted,
    isANodeControl,
    hasVariations,
    removeUnvalorizedAttributes,
    cleanUp
};

// ---------------------------------------------- PRIVATE ----------------------------------------------
/** A Dashboard node can list Table from within the Structure or the Content directly */
const fixDashboardNode = (structure: UcStructure, node: StructureNode): void => {

    // Only Dashboards have buckets
    if (node.type !== StructureNodeType.Dashboard) {
        delete node.buckets;
        delete node.bucketOptions;
        return;
    }

    // Migrate legacy buckets to bucketOptions
    if (!node.bucketOptions) {
        node.bucketOptions = (node.buckets || []).map(nodeId => ({ nodeId, pageSize: defaultBucketPageSize }));
    }
    delete node.buckets;

    // Prune non existings buckets from the lists and normalize the others
    if (node.bucketOptions.length) {
        const existings = getAllNodesOfType(structure, StructureNodeType.FormBucket);

        node.bucketOptions = node.bucketOptions.filter(option => {
            // Table option always accepted
            if (option.identifier) {
                return true;
            }

            // Otherwise check that the option is linked to an existing FormBucket node
            const bucketNode = existings.find(e => e.nodeId === option.nodeId);

            if (!bucketNode) {
                return false;
            }

            // Normalize model (match Table model)
            option.identifier = bucketNode.definitionIdentifier;
            delete option.nodeId;
            return true;
        });
    }
};

/** Bucket and Users nodes sync userFilters and userFilterOptions */
const fixUserFilters = (node: StructureNode): void => {
    if (node.type === StructureNodeType.FormBucket && node.id != null) {
        // Table linked format do not use userFilters, columns and filter
        delete node.userFilters;
        delete node.userFilterOptions;
        delete node.columns;
        delete node.filter;
        return;
    }

    // Mirror userFilters and userFilterOptions
    if (!node.userFilterOptions && (node.userFilters?.length ?? 0) > 0) {
        node.userFilterOptions = (node.userFilters || []).map(fieldIdentifier => ({ identifier: fieldIdentifier }));
    }
    delete node.userFilters;
};