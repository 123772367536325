/* eslint-disable arrow-body-style */
import {
    CollectionItemMetadataIdentifiers, CompanyIdentifiers, DataDescriptorBucketType, DataDescriptorCollectionType, DataDescriptorCompanyType,
    DataDescriptorType, DataDescriptorUsersType, DataPropertyDescriptor, FormDefinitionMetadataIdentifiers, UserInfoIdentifiers
} from '@unifii/library/common';


const labelOverrideIdentifiers = new Map<DataDescriptorType, string[]>([
    [DataDescriptorBucketType, [
        FormDefinitionMetadataIdentifiers.CreatedAt,
        FormDefinitionMetadataIdentifiers.CreatedBy,
        FormDefinitionMetadataIdentifiers.DefinitionIdentifier,
        FormDefinitionMetadataIdentifiers.DefinitionVersion,
        FormDefinitionMetadataIdentifiers.LastModifiedAt,
        FormDefinitionMetadataIdentifiers.LastModifiedBy,
        FormDefinitionMetadataIdentifiers.ParentBucket,
        FormDefinitionMetadataIdentifiers.ParentDefinitionIdentifier,
        FormDefinitionMetadataIdentifiers.ParentId,
        FormDefinitionMetadataIdentifiers.ParentSeqId,
        FormDefinitionMetadataIdentifiers.ParentDefinitionVersion,
        FormDefinitionMetadataIdentifiers.Result,
        FormDefinitionMetadataIdentifiers.State,
        FormDefinitionMetadataIdentifiers.SeqId,
        FormDefinitionMetadataIdentifiers.Id
    ]],
    [DataDescriptorUsersType, [
        UserInfoIdentifiers.Id,
        UserInfoIdentifiers.Username,
        UserInfoIdentifiers.FirstName,
        UserInfoIdentifiers.LastName,
        UserInfoIdentifiers.Email,
        UserInfoIdentifiers.Phone,
        UserInfoIdentifiers.Company,
        UserInfoIdentifiers.CompanyId,
        UserInfoIdentifiers.CompanyName,
        UserInfoIdentifiers.Roles,
        UserInfoIdentifiers.CreatedAt,
        UserInfoIdentifiers.LastModifiedAt,
        UserInfoIdentifiers.IsExternal,
        UserInfoIdentifiers.Units,
        UserInfoIdentifiers.UnitPaths,
        CompanyIdentifiers.Status,
        'hasPassword',
        'isActive'
    ]],
    [DataDescriptorCompanyType, [
        CompanyIdentifiers.Id,
        CompanyIdentifiers.Name,
        CompanyIdentifiers.Status
    ]],
    [DataDescriptorCollectionType, [
        CollectionItemMetadataIdentifiers.Id,
        CollectionItemMetadataIdentifiers.LastModifiedAt,
        CollectionItemMetadataIdentifiers.LastPublishedAt,
        CollectionItemMetadataIdentifiers.DefinitionIdentifier,
        CollectionItemMetadataIdentifiers.PublishedVersion,
        CollectionItemMetadataIdentifiers.Rev,
        CollectionItemMetadataIdentifiers.State,
        CollectionItemMetadataIdentifiers.Title
    ]]
]);

export const allowsLabelOverride = (type?: DataDescriptorType, descriptor?: DataPropertyDescriptor): boolean =>
    type != null &&
    descriptor != null &&
    (labelOverrideIdentifiers.get(type)?.includes(descriptor.identifier) ?? false);