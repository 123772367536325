import { Injectable } from '@angular/core';
import { ClientDeleteOptions, ClientGetOptions, ClientPostOptions, ClientPutOptions } from '@unifii/sdk';

import { SmtpInfo } from 'client';

import { EmailInfo } from './models';
import { UcClient } from './uc-client';


@Injectable()
export class UcSmtp {

    private readonly url = this.client.buildUrl(['settings', 'smtp']);
    private readonly emailUrl = this.client.buildUrl(['settings', 'email']);

    constructor(private client: UcClient) { }

    get(options?: ClientGetOptions): Promise<SmtpInfo> {
        return this.client.get(this.url, options);
    }

    save(smtpInfo: SmtpInfo, options?: ClientPutOptions): Promise<SmtpInfo> {
        return this.client.put(this.url, smtpInfo, options);
    }

    delete(options?: ClientDeleteOptions) {
        return this.client.delete(this.url, options);
    }

    test(smtpInfo: SmtpInfo, email: string, options?: ClientPostOptions) {
        return this.client.post(this.client.buildUrl(['test-emails', email]), { ...options, body: { smtpInfo } });
    }

    getEmail(options?: ClientGetOptions) {
        return this.client.get(this.emailUrl, options);
    }

    saveEmail(email: EmailInfo, options?: ClientPutOptions) {
        return this.client.put(this.emailUrl, email, options);
    }

}
