<uf-panel class="container">

    <div class="header-pane uf-app-bar transparent">
        <uf-breadcrumbs [breadcrumbs]="breadcrumbs" class="large">
            <span *ngIf="edited">*</span>
        </uf-breadcrumbs>
    </div>

    <div class="content-pane">
        <uf-tabs>
            <uf-tab label="General" path="general"></uf-tab>
            <uf-tab label="Theming" path="theming"></uf-tab>
            <uf-tab label="Data Sources" path="data-sources"></uf-tab>
        </uf-tabs>
    </div>
</uf-panel>