<uf-expander *ngIf="control" class="uf-box flat">
    <div expanderHeader class="uf-app-bar flat">
        <div class="title primary">Variations</div>
        <uf-icon *ngIf="control.invalid" name="error" class="error"></uf-icon>
        <uc-expander-controls *ngIf="control.length" [container]="variationsWrap">
        </uc-expander-controls>
    </div>

    <div expanderBody #variationsWrap class="pad small">
        <uc-drag-list *ngIf="control.length" [parent]="control" [items]="control.controls" canDrop="false"
            canReorder="true">
            <ng-container *ngFor="let variation of control.controls | asUfControlsGroups; let i = index">

                <uf-expander dragItem class="uf-box flat draggable gap-sm-bottom">
                    <div expanderHeader dragHandle class="uf-app-bar flat">
                        <uf-icon [name]="getIcon(variation)"></uf-icon>
                        <div class="title primary">{{variation.get(variationControlKeys.Name)?.value}}</div>
                        <uf-icon *ngIf="!getIsPublished(variation)" name="unpublished"
                            title="This content is unpublished" class="icon-warning">
                        </uf-icon>
                        <uf-icon *ngIf="variation.invalid" name="error" class="error"></uf-icon>
                        <button *ngIf="!variation.disabled" (click)="remove(i)" title="Delete" class="uf-action">
                            <uf-icon name="delete"></uf-icon>
                        </button>
                    </div>
                    <div expanderBody class="uf-grid pad">
                        <uf-description-list *ngIf="getVariationRolesDescriptionItem(variation)"
                            [items]="getVariationRolesDescriptionItem(variation)" class="small-label col-12">
                        </uf-description-list>
                    </div>
                </uf-expander>
            </ng-container>
        </uc-drag-list>
        <div class="row">
            <button *ngIf="!control.disabled" class="uf-button x-small right" (click)="add()">
                Add Variation
            </button>
        </div>
    </div>
</uf-expander>