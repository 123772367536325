import { Config } from 'app-config';

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { ConsoleOptions } from './app/app-config';
import { AppModule } from './app/app.module';


(async () => {
    let config: Config;

    const configElement = window.document.getElementById('env') as HTMLElement;

    try {
        config = JSON.parse(configElement.innerHTML);
    } catch (e) {
        const response = await fetch('environment.json');
        config = await response.json();
        window.document.title = config.env;
    }

    // Retrieve it from origin if not specified in the config
    config.baseUrl = config.baseUrl ?? window.location.origin;

    // 8021 server connect to localhost unifii-api server
    if (config.env === 'localhost' && window.location.port === '8021') {
        config.baseUrl = 'http://localhost:5171';
    }

    if (config.env !== 'localhost' && config.env !== 'dev') {
        enableProdMode();
    }

    platformBrowserDynamic([{ provide: Config, useValue: new ConsoleOptions(config) }]).bootstrapModule(AppModule)
        .catch(err => console.error(err));
})();
