<uc-icons-library></uc-icons-library>
<uc-icons-console></uc-icons-console>

<uf-panel class="container">
    <div class="grid--fixed">

        <div class="col-1of1 body-copy--small">
            <h2>Icons</h2>
        </div>

        <uf-search class="col-1of1" label="Search" (searchChange)="search($event)"></uf-search>

        <uf-expander class="col-1of1 uc-group--shadow--no-border">
            <div expanderHeader class="uf-app-bar flat accent">
                <div class="title">Library</div>
            </div>
            <div expanderBody class="uc-group-content grid">
                <ng-template ngFor [ngForOf]="libraryIcons" let-item let-i="index">
                    <div #libs class="wrapper" (click)="selectIcon(item.name)"
                        [ngClass]="{'selected': item.name === selected, 'duplicated': item.duplicated, 'overridden': item.overridden}"
                        [title]="item.name">
                    </div>
                </ng-template>
            </div>
        </uf-expander>

        <uf-expander class="col-1of1 uc-group--shadow--no-border">
            <div expanderHeader class="uf-app-bar flat accent">
                <div class="title">Console</div>
            </div>
            <div expanderBody class="uc-group-content grid">
                <ng-template ngFor [ngForOf]="consoleIcons" let-item let-i="index">
                    <div #consoles class="wrapper" (click)="selectIcon(item.name)"
                        [ngClass]="{'selected': item.name === selected, 'duplicated': item.duplicated, 'overridden': item.overridden}"
                        [title]="item.name">
                    </div>
                </ng-template>
            </div>
        </uf-expander>

    </div>
</uf-panel>