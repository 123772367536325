<div class="col-1of1 compact">

    <div class="uc-card-actions">
        <button class="uf-action tertiary" (click)="close()">
            <uf-icon name="close"></uf-icon>
        </button>
    </div>

    <h3>Problem?</h3>
    <p>If you are having difficulties please contact your administrator.</p>

    <uf-description-list *ngIf="contactDetails.length" [items]="contactDetails"></uf-description-list>

    <br />


</div>