import { Component, HostBinding } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Breadcrumb } from '@unifii/library/common';

import { SystemRole } from 'client';

import { DirectoryItem } from 'pages/page-models';

import { BreadcrumbService } from 'services/breadcrumb.service';


@Component({
    templateUrl: './landing.html',
    styleUrls: ['./landing.less']
})
export class LandingComponent {
    @HostBinding('class.stretch-component') classes = true;

    breadcrumbs: Breadcrumb[] = this.breadcrumbService.getBreadcrumbs(this.route);
    items: DirectoryItem[];

    constructor(
        private route: ActivatedRoute,
        private breadcrumbService: BreadcrumbService
    ) {
        this.items = [{
            label: 'Projects',
            route: ['projects'],
            roles: [
                SystemRole.ProjectManager,
                SystemRole.ContentEditor,
                SystemRole.FormDesigner,
                SystemRole.Publisher,
                SystemRole.Importer,
                SystemRole.FormDataViewer,
                SystemRole.WorkflowDesigner
            ],
            image: '/assets/svg/new-icons/projects.svg'
        }, {
            label: 'Tenant Settings',
            route: ['/system-settings', 'general'],
            roles: [
                SystemRole.SystemManager
            ],
            image: '/assets/svg/new-icons/tenant-settings.svg'
        }, {
            label: 'Identity',
            route: ['/system-settings', 'sso'],
            roles: [
                SystemRole.SystemManager
            ],
            image: '/assets/svg/new-icons/identity.svg'
        }, {
            label: 'Users',
            route: ['user-management/users'],
            roles: [
                SystemRole.UserManager
            ],
            image: '/assets/svg/new-icons/user-management.svg'
        }, {
            label: 'Roles',
            route: ['user-management/roles'],
            roles: [
                SystemRole.UserManager
            ],
            image: '/assets/svg/new-icons/roles.svg'
        }, {
            label: 'User Claims',
            route: ['user-management/user-claims'],
            roles: [
                SystemRole.UserManager
            ],
            image: '/assets/svg/new-icons/user-claims.svg'
        }, {
            label: 'Hierarchy',
            route: ['user-management/hierarchy'],
            image: '/assets/svg/new-icons/hierarchy.svg',
            roles: [
                SystemRole.UserManager
            ]
        }, {
            label: 'API Keys',
            route: ['user-management/api-keys'],
            roles: [
                SystemRole.UserManager
            ],
            image: '/assets/svg/new-icons/api-keys.svg'
        }, {
            label: 'Integrations',
            route: ['/system-settings', 'integrations'],
            roles: [
                SystemRole.SystemManager
            ],
            image: '/assets/svg/new-icons/integrations.svg',
        }, {
            label: 'Email Settings',
            route: ['/system-settings', 'email'],
            roles: [
                SystemRole.SystemManager
            ],
            image: '/assets/svg/new-icons/email-settings.svg'
        }, {
            label: 'SMTP Settings',
            route: ['/system-settings', 'smtp'],
            roles: [
                SystemRole.SystemManager
            ],
            image: '/assets/svg/new-icons/smtp.svg'
        }, {
            label: 'Apps',
            roles: [
                SystemRole.SystemManager,
                SystemRole.SuperUser,
                SystemRole.AppManager
            ],
            route: ['/system-settings', 'apps'],
            image: '/assets/svg/new-icons/apps.svg'
        }, {
            label: 'Audit Log',
            route: ['user-management/audit-log'],
            roles: [
                SystemRole.UserManager,
                SystemRole.Auditor
            ],
            image: '/assets/svg/new-icons/audit-log.svg'
        }, {
            label: 'Assets',
            route: ['assets/media'],
            roles: [
                SystemRole.AssetManager,
                SystemRole.ContentEditor,
                SystemRole.Publisher,
                SystemRole.ProjectManager
            ],
            image: '/assets/svg/new-icons/media.svg'
        }, {
            label: 'Translations',
            route: ['assets/translations'],
            roles: [
                SystemRole.Translator
            ],
            image: '/assets/svg/new-icons/translations.svg'
        }];
    }
}
