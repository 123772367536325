<uf-expander *ngIf="control" class="uf-box flat">
    <div expanderHeader class="uf-app-bar flat">
        <div class="title primary">Settings</div>
        <uf-icon *ngIf="isInvalid" name="error" class="error"></uf-icon>
        <a *ngIf="canCopy" (click)="copy()" class="uf-action tertiary" title="Copy">
            <uf-icon name="copy"></uf-icon>
        </a>
    </div>
    <div expanderBody [formGroup]="control" class="uc-group-content grid">
        <uf-spinner *ngIf="!ready" class="col-1of1"></uf-spinner>
        <ng-template [ngIf]="ready">
            <uf-text [formControlName]="formKeys.Name" label="Menu Name" class="col-1of1"></uf-text>
            <uf-text *ngIf="showDefinitionIdentifier" [formControlName]="formKeys.DefinitionIdentifier"
                label="Identifier" class="col-1of1 small">
            </uf-text>
            <uf-text *ngIf="showURL" [formControlName]="formKeys.Url" label="URL" class="col-1of1"></uf-text>
            <uf-chips *ngIf="showTags" label="Tags" [formControlName]="formKeys.Tags" [allowCustom]="true"
                [options]="tagOptions" (searchChange)="searchTags()" class="col-1of1">
            </uf-chips>
            <div *ngIf="appUrl" class="grid center-all col-1of1">
                <uf-description-list [items]="[appUrl]" class="small-label"></uf-description-list>
                <a (click)="copyAppURL()" class="uf-action tertiary right" title="Copy App URL">
                    <uf-icon name="copy"></uf-icon>
                </a>
            </div>

        </ng-template>
    </div>
</uf-expander>