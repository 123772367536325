import { Component, HostBinding, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import { FilterComponentRegistry, FilterEntries } from '@unifii/library/common';

import { UcProject } from 'client';

import { BreadcrumbService } from 'services/breadcrumb.service';
import { InfoFilterFactory } from 'services/table/info-filter-factory';
import { InfoFilterRegistry } from 'services/table/info-filter-registry';
import { FormInfoLoader } from 'services/table/info-loaders';
import { InfoTableManager } from 'services/table/info-table-manager';
import { InfoLoader, InfoType } from 'services/table/models';


const createLoader = (ucProject: UcProject) => new FormInfoLoader(ucProject);

const createFilters = (filterFactory: InfoFilterFactory) => filterFactory.create(InfoType.Form);

@Component({
    selector: 'uc-forms',
    template: `
        <div ufMasterDetail>
            <uf-table-container ufSyncRoute (addItem)="addItem()" class="accent list-md">
                <uf-breadcrumbs title [breadcrumbs]="breadcrumbs" class="large"></uf-breadcrumbs>
            </uf-table-container>
        </div>
        <router-outlet></router-outlet>
    `,
    providers: [
        { provide: FilterComponentRegistry, useClass: InfoFilterRegistry },
        { provide: FilterEntries, useFactory: createFilters, deps: [InfoFilterFactory] },
        { provide: InfoLoader, useFactory: createLoader, deps: [UcProject] },
        { provide: TableContainerManager, useClass: InfoTableManager }
    ],
})
export class FormsComponent {

    @HostBinding('class.stretch-component') class = true;

    breadcrumbs = this.breadcrumbService.getBreadcrumbs(this.route);

    constructor(
        @Inject(TableContainerManager) private manager: InfoTableManager,
        private breadcrumbService: BreadcrumbService,
        private route: ActivatedRoute
    ) { }

    addItem() {
        this.manager.addActionCallback();
    }

}
