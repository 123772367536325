import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UfControlArray, UfControlGroup, UfFormBuilder } from '@unifii/library/common';
import { CompoundType, FieldType, Option } from '@unifii/sdk';

import { FieldReferenceHelper } from 'helpers/field-reference-helper';

import { ContextService } from 'services/context.service';
import { DialogsService } from 'services/dialogs.service';

import { FORM_EDITOR_CONSTANTS } from './form-editor-constants';
import { FieldControlKeys } from './form-editor-control-keys';
import { FormEditorFunctions } from './form-editor-functions';
import { FormEditorField, FormFieldMetadata } from './form-editor-model';
import { FormEditorStatus } from './form-editor-status';
import { FormEditorService } from './form-editor.service';


@Component({
    selector: 'uc-form-field',
    templateUrl: './form-field.html',
    styles: [`:host { display: block }`]
})
export class FormFieldComponent implements OnInit {

    @Input() control: UfControlGroup;
    @Output() expandAll: EventEmitter<boolean> = new EventEmitter();

    meta: FormFieldMetadata;
    ready: boolean;
    templateOptions: Option[];

    constructor(
        public status: FormEditorStatus,
        public service: FormEditorService,
        private ufFormBuilder: UfFormBuilder,
        private context: ContextService,
        private dialogs: DialogsService
    ) { }

    get field(): FormEditorField {
        return this.control.value as FormEditorField;
    }

    get fields(): UfControlArray {
        return this.control.get(FieldControlKeys.Fields) as UfControlArray;
    }

    get isRoot(): boolean {
        return this.control == null;
    }

    get isSelected(): boolean {

        // check selected root
        if (this.isRoot) {
            return this.status.selected == null;
        }

        return this.control === this.status.selected;
    }

    get icon(): string {
        return FieldReferenceHelper.getFieldReference(this.field, CompoundType.Form).icon;
    }

    get hasVariations(): boolean {
        return (this.control.get(FieldControlKeys.Variations) as UfControlArray).length > 0;
    }

    get label() {

        let label: string;

        switch (this.field.type) {
            case FieldType.Content:
                label = this.field.help ?? this.field.type;
                break;
            case FieldType.Separator:
                label = `Separator (${this.templateOptions.find(o => o.identifier === this.field.template)?.name})`;
                break;
            default:
                label = this.field.label ?? '';
                break;
        }

        return `${label.substring(0, FORM_EDITOR_CONSTANTS.FIELD_RENDERED_LABEL_MAX_LENGTH)}${(label.length > FORM_EDITOR_CONSTANTS.FIELD_RENDERED_LABEL_MAX_LENGTH ? '...' : '')}`;
    }

    ngOnInit() {
        if (this.control) {
            this.meta = FormEditorFunctions.controlMetadata(this.control, this.context);
            this.templateOptions = FormEditorFunctions.fieldTemplateOptions(this.field);
        }

        this.ready = true;
    }

    select(event: Event) {
        event.stopPropagation();
        this.status.selected = this.control;
    }

    async remove(event: Event) {
        event.stopPropagation();

        if (!await this.dialogs.confirmDelete()) {
            return;
        }

        this.ufFormBuilder.detach(this.control);
        this.service.fieldRemoved(this.control);
    }

    expand(event: Event) {
        event.stopPropagation();
        this.expandAll.next(true);
    }

    collapse(event: Event) {
        event.stopPropagation();
        this.expandAll.next(false);
    }

}