<div class="header-pane uf-app-bar transparent">
    <uf-breadcrumbs [breadcrumbs]="breadcrumbs" class="large">
        <span *ngIf="edited">*</span>
    </uf-breadcrumbs>
    <a class="uf-button tertiary right" routerLink="..">
        Cancel
    </a>
    <button type="button" class="uf-button primary" (click)="save()">
        Save
    </button>
</div>
<uf-panel class="content-pane container">
    <div class="uf-container-lg">
        <div class="pad">

            <uf-skeleton *ngIf="!error && !root" class="gap-none"></uf-skeleton>

            <uf-message *ngIf="error" [content]="error.message" icon="error" class="error gap-bottom"></uf-message>
            <div *ngIf="root" [formGroup]="root" class="uf-grid">
                <div class="uf-box col-12">
                    <div class="uf-app-bar flat accent">
                        <div class="title">
                            Details
                        </div>
                    </div>
                    <div class="uf-grid pad">
                        <uf-text class="col-12" label="Title" [formControlName]="controlKeys.Label">
                            <span class="suffix">*</span>
                        </uf-text>
                    </div>
                </div>

                <div class="uf-box col-12">
                    <div class="uf-app-bar flat accent">
                        <div class="title">
                            Event
                        </div>
                    </div>
                    <div class="uf-grid pad">
                        <uf-select label="Event" [formControlName]="controlKeys.EventType" [options]="eventOptions"
                            valueProperty="_id" nameProperty="_display" (valueChange)="eventChange($event)"
                            class="col-12">
                            <span class="suffix">*</span>
                        </uf-select>
                        <ng-container [formGroup]="condition">
                            <uf-autocomplete *ngIf="eventType?.value === eventTypes.FormSubmitted"
                                label="Form Data Repository" [formControlName]="controlKeys.Bucket"
                                (searchChange)="searchBuckets($event)" [options]="buckets"
                                (valueChange)="bucketChange($event)" class="col-12">
                                <span class="suffix">*</span>
                            </uf-autocomplete>
                            <uf-message *ngIf="bucketError" [content]="bucketError.message" icon="error"
                                class="error col-12"></uf-message>
                            <uf-chips *ngIf="transitions?.enabled" label="Workflow Transition"
                                [formControlName]="controlKeys.Transitions" [options]="filteredTransitions"
                                (searchChange)="searchTransitions($event)" nameProperty="description" class="col-12">
                                <span class="suffix">*</span>
                            </uf-chips>
                        </ng-container>
                        <uf-autocomplete *ngIf="timer?.enabled" label="Timer" [formControlName]="controlKeys.Timer"
                            [options]="filteredTimers" (searchChange)="searchTimer($event)" nameProperty="label"
                            class="col-12">
                            <span class="suffix">*</span>
                        </uf-autocomplete>
                        <uf-autocomplete *ngIf="integration?.enabled" label="Integration"
                            (searchChange)="searchIntegrations($event)" [formControlName]="controlKeys.Integration"
                            [options]="integrations" nameProperty="name" (valueChange)="integrationChange($event)"
                            class="col-12">
                            <span class="suffix">*</span>
                        </uf-autocomplete>
                        <uf-autocomplete *ngIf="feature?.enabled" label="Feature"
                            (searchChange)="searchFeatures($event)" [formControlName]="controlKeys.Feature"
                            [options]="filteredFeatures" nameProperty="name" class="col-12">
                            <span class="suffix">*</span>
                        </uf-autocomplete>
                        <ng-container *ngIf="condition?.get(controlKeys.Roles)?.enabled" [formGroup]="condition">
                            <uf-chips [options]="filteredRoles" (valueChange)="searchRoles('')"
                                (searchChange)="searchRoles($event)" [formControlName]="controlKeys.Roles"
                                class="col-12" label="Roles">
                            </uf-chips>
                        </ng-container>
                    </div>
                </div>

                <div class="uf-box col-12">
                    <div class="uf-app-bar flat accent">
                        <div class="title">
                            Condition
                        </div>
                    </div>
                    <div [formGroup]="condition" class="uf-grid pad">
                        <uf-textarea [formControlName]="controlKeys.Expression" label="Expression" class="col-12">
                        </uf-textarea>
                        <uf-chips *ngIf="forms.enabled && formOptions.length > 1" [formControlName]="controlKeys.Forms"
                            [options]="filteredFormOptions" nameProperty="_display" valueProperty="_id" label="Forms"
                            (searchChange)="searchForms($event)" class="col-12">
                        </uf-chips>
                        <uf-checkbox *ngIf="condition?.get(controlKeys.Roles)?.enabled" label="Include External Users"
                            [formControlName]="controlKeys.IncludeExternal" class="col-12">
                        </uf-checkbox>
                        <uf-checkbox *ngIf="condition?.get(controlKeys.Roles)?.enabled" label="Include New Users"
                            [formControlName]="controlKeys.IncludeNewUser" class="col-12">
                        </uf-checkbox>
                    </div>
                </div>

                <div class="uf-box col-12">
                    <div class="uf-app-bar flat accent">
                        <div class="title">
                            Activities
                        </div>
                    </div>

                    <div *ngFor="let activity of activities.controls; let i = index" class="uf-box">
                        <uf-expander dragItem [dragDisabled]="true" [isExpanded]="true">
                            <div expanderHeader class="uf-app-bar flat">
                                <div class="title">
                                    {{activity.get(controlKeys.Type)?.value}}
                                </div>
                                <button (click)="deleteActivity(i)" title="Delete" class="uf-action">
                                    <uf-icon name="delete"></uf-icon>
                                </button>
                            </div>
                            <div expanderBody class="uf-grid pad">
                                <ng-container [ngSwitch]="activity.get(controlKeys.Type)?.value">
                                    <uf-autocomplete *ngSwitchCase="activityType.Timer" label="Timer"
                                        [control]="$any(activity).get(controlKeys.Activity) | asUfControl"
                                        [options]="filteredTimers" (searchChange)="searchTimers($event)"
                                        (valueChange)="activityChange($any(activity), $event, i)" nameProperty="label"
                                        valueProperty="label" placeholder="Select a Timer" class="col-11">
                                        <span class="suffix">*</span>
                                    </uf-autocomplete>
                                    <uf-autocomplete *ngSwitchCase="activityType.Notification" label="Notification"
                                        [options]="filteredNotifications" (searchChange)="searchNotifications($event)"
                                        (valueChange)="activityChange($any(activity), $event, i)"
                                        [control]="$any(activity).get(controlKeys.Activity) | asUfControl"
                                        nameProperty="label" valueProperty="label" placeholder="Select a Notification"
                                        class="col-11">
                                        <span class="suffix">*</span>
                                    </uf-autocomplete>
                                    <uf-autocomplete *ngSwitchCase="activityType.DataForwarder" label="Data Forwarder"
                                        [control]="$any(activity).get(controlKeys.Activity) | asUfControl"
                                        (searchChange)="searchDataForwarders($event)"
                                        (valueChange)="activityChange($any(activity), $event, i)"
                                        [options]="filteredDataForwarders" nameProperty="label" valueProperty="label"
                                        placeholder="Select a Data Forwarder" class="col-11">
                                        <span class="suffix">*</span>
                                    </uf-autocomplete>
                                    <a *ngIf="$any(activity).get(controlKeys.Id)?.value"
                                        class="uf-action tertiary col-1 center"
                                        [routerLink]="[openActivity($any(activity))]" target="_blank">
                                        <uf-icon name="open"></uf-icon>
                                    </a>
                                    <uf-description-list *ngIf="activityData[i]" class="col-12 columns"
                                        [items]="activityData[i]">
                                    </uf-description-list>
                                </ng-container>
                            </div>
                        </uf-expander>
                    </div>
                    <div class="pad border-top">
                        <uf-error [control]="activities"></uf-error>
                        <button type="button" class="uf-button right" listBox [options]="activityOptions"
                            nameProperty="name" (optionSelected)="addActivity($event);">
                            Add Activity
                            <uf-icon name="more" class="gap-none"></uf-icon>
                        </button>
                    </div>

                </div>
            </div>
        </div>
    </div>

</uf-panel>