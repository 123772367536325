import { Injectable } from '@angular/core';
import { amendOptionsParams, ClientDeleteOptions, ClientGetOptions, ClientPatchOptions, mergeParams } from '@unifii/sdk';

import { DefaultPaginationParams } from 'constant';

import { Media } from './models';
import { UcClient } from './uc-client';


export interface MediaQueryParams {
    q?: string;
    sort?: string;
    types?: string | string[];
    tags?: string | string[];
    pending?: boolean;
    offset?: number;
    limit?: number;
}


@Injectable()
export class UcMedia {

    constructor(private client: UcClient) { }

    get(params?: MediaQueryParams, options?: ClientGetOptions): Promise<Media[]> {

        let url = this.url();
        if (params?.pending) {
            url = this.url('pending');
            delete params.pending;
        }

        const defaultedParams = mergeParams(DefaultPaginationParams, params as Record<string, unknown>);
        return this.client.get(url, amendOptionsParams(defaultedParams, options));
    }

    getDetail(id: number, options?: ClientGetOptions): Promise<Media> {
        return this.client.get(this.url(id + ''), options);
    }

    save(media: Media, options?: ClientPatchOptions): Promise<Media> {
        return this.client.patch(this.url(media.id + ''), media, options);
    }

    delete(id: number, options?: ClientDeleteOptions): Promise<void> {
        return this.client.delete(this.url(id + ''), options);
    }

    private url(...extra: string[]): string {
        const urlParts = ['assets'].concat(extra);
        return this.client.buildUrl(urlParts);
    }
}
