import { Injectable } from '@angular/core';
import { amendOptionsParams, ClientGetOptions, mergeParams } from '@unifii/sdk';

import { DefaultPaginationParams } from 'constant';

import { Resource, ResourceElement } from './models';
import { UcClient } from './uc-client';


@Injectable()
export class UcResources {

    constructor(private client: UcClient) { }

    get(options?: ClientGetOptions): Promise<Resource> {
        return this.client.get(this.url(), options);
    }

    getUsers(q?: string, options?: ClientGetOptions): Promise<ResourceElement[]> {
        const defaultedParams = mergeParams(DefaultPaginationParams, options?.params, { q });
        return this.client.get(this.url('users'), amendOptionsParams(defaultedParams, options));
    }

    getUser(id: string, options?: ClientGetOptions): Promise<ResourceElement> {
        return this.client.get(this.url('users', id), options);
    }

    getProjects(q?: string, options?: ClientGetOptions): Promise<ResourceElement[]> {
        const defaultedParams = mergeParams(DefaultPaginationParams, options?.params, { q });
        return this.client.get(this.url('projects'), amendOptionsParams(defaultedParams, options));
    }

    getProject(projectId: number): Promise<ResourceElement> {
        return this.client.get(this.url('projects', projectId));
    }

    getCollections(projectId: number, q?: string, options?: ClientGetOptions): Promise<ResourceElement[]> {
        const defaultedParams = mergeParams(DefaultPaginationParams, options?.params, { q });
        return this.client.get(this.url('projects', projectId, 'collections'), amendOptionsParams(defaultedParams, options));
    }

    getCollection(projectId: number, identifier: string, options?: ClientGetOptions): Promise<ResourceElement> {
        return this.client.get(this.url('projects', projectId, 'collections', identifier), options);
    }

    getCollectionItems(projectId: number, collectionIdentifier: string, q?: string, options?: ClientGetOptions): Promise<ResourceElement[]> {
        const defaultedParams = mergeParams(DefaultPaginationParams, options?.params, { q });
        return this.client.get(this.url('projects', projectId, 'collections', collectionIdentifier, 'items'), amendOptionsParams(defaultedParams, options));
    }

    getCollectionItem(projectId: number, collectionIdentifier: string, identifier: string, options?: ClientGetOptions): Promise<ResourceElement> {
        return this.client.get(this.url('projects', projectId, 'collections', collectionIdentifier, 'items', identifier), options);
    }

    getViews(projectId: number, q?: string, options?: ClientGetOptions): Promise<ResourceElement[]> {
        const defaultedParams = mergeParams(DefaultPaginationParams, options?.params, { q });
        return this.client.get(this.url('projects', projectId, 'views'), amendOptionsParams(defaultedParams, options));
    }

    getView(projectId: number, id: string, options?: ClientGetOptions): Promise<ResourceElement> {
        return this.client.get(this.url('projects', projectId, 'views', id), options);
    }

    getPages(projectId: number, q?: string, options?: ClientGetOptions): Promise<ResourceElement[]> {
        const defaultedParams = mergeParams(DefaultPaginationParams, options?.params, { q });
        return this.client.get(this.url('projects', projectId, 'pages'), amendOptionsParams(defaultedParams, options));
    }

    getPage(projectId: number, id: string, options?: ClientGetOptions): Promise<ResourceElement> {
        return this.client.get(this.url('projects', projectId, 'pages', id), options);
    }

    getForms(projectId: number, q?: string, options?: ClientGetOptions): Promise<ResourceElement[]> {
        const defaultedParams = mergeParams(DefaultPaginationParams, options?.params, { q });
        return this.client.get(this.url('projects', projectId, 'forms'), amendOptionsParams(defaultedParams, options));
    }

    getForm(projectId: number, id: string, options?: ClientGetOptions): Promise<ResourceElement> {
        return this.client.get(this.url('projects', projectId, 'forms', id), options);
    }

    getBuckets(projectId: number, q?: string, options?: ClientGetOptions): Promise<ResourceElement[]> {
        const defaultedParams = mergeParams(DefaultPaginationParams, options?.params, { q });
        return this.client.get(this.url('projects', projectId, 'buckets'), amendOptionsParams(defaultedParams, options));
    }

    getBucket(projectId: number, id: string, options?: ClientGetOptions): Promise<ResourceElement> {
        return this.client.get(this.url('projects', projectId, 'buckets', id), options);
    }

    getTables(projectId: number, q?: string, options?: ClientGetOptions): Promise<ResourceElement[]> {
        const defaultedParams = mergeParams(DefaultPaginationParams, options?.params, { q });
        return this.client.get(this.url('projects', projectId, 'tables'), amendOptionsParams(defaultedParams, options));
    }

    getTable(projectId: number, id: string, options?: ClientGetOptions): Promise<ResourceElement> {
        return this.client.get(this.url('projects', projectId, 'tables', id), options);
    }

    getExternalDataSources(projectId: number, q?: string, options?: ClientGetOptions): Promise<ResourceElement[]> {
        const defaultedParams = mergeParams(DefaultPaginationParams, options?.params, { q });
        return this.client.get(this.url('projects', projectId, 'external-data-sources'), amendOptionsParams(defaultedParams, options));
    }

    getExternalDataSource(projectId: number, id: string, options?: ClientGetOptions): Promise<ResourceElement> {
        return this.client.get(this.url('projects', projectId, 'external-data-sources', id), options);
    }

    getCompanies(q?: string, options?: ClientGetOptions): Promise<ResourceElement[]> {
        const defaultedParams = mergeParams(DefaultPaginationParams, options?.params, { q });
        return this.client.get(this.url('companies'), amendOptionsParams(defaultedParams, options));
    }

    getCompany(id: string, options?: ClientGetOptions): Promise<ResourceElement> {
        return this.client.get(this.url('companies', id), options);
    }

    getCompanyClaims(q?: string, options?: ClientGetOptions): Promise<ResourceElement[]> {
        const defaultedParams = mergeParams(DefaultPaginationParams, options?.params, { q });
        return this.client.get(this.url('company-claims'), amendOptionsParams(defaultedParams, options));
    }

    getCompanyClaim(id: string, options?: ClientGetOptions): Promise<ResourceElement> {
        return this.client.get(this.url('company-claims', id), options);
    }

    getUserClaims(q?: string, options?: ClientGetOptions): Promise<ResourceElement[]> {
        const defaultedParams = mergeParams(DefaultPaginationParams, options?.params, { q });
        return this.client.get(this.url('user-claims'), amendOptionsParams(defaultedParams, options));
    }

    getUserClaim(id: string, options?: ClientGetOptions): Promise<ResourceElement> {
        return this.client.get(this.url('user-claims', id), options);
    }

    getMeFields(options?: ClientGetOptions): Promise<string[]> {
        return this.client.get(this.url('me', 'fields'), options);
    }

    getUsersFields(options?: ClientGetOptions): Promise<string[]> {
        return this.client.get(this.url('users', 'fields'), options);
    }

    getCompaniesFields(options?: ClientGetOptions): Promise<string[]> {
        return this.client.get(this.url('companies', 'fields'), options);
    }

    private url(...extra: (string | number)[]): string {
        const urlParts = ['resources', ...extra];
        return this.client.buildUrl(urlParts);
    }
}
