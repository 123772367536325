<uf-expander class="uf-box flat">

    <div expanderHeader class="uf-app-bar flat">
        <div class="title primary">Validators</div>
        <uf-icon *ngIf="validators.invalid" name="error" class="error"></uf-icon>
        <uc-expander-controls *ngIf="validators.length" [container]="validatorsWrap"></uc-expander-controls>
    </div>

    <div expanderBody #validatorsWrap class="pad-sm">

        <uc-drag-list [items]="validators.controls" canDrop="false" canReorder="true">

            <ng-template ngFor [ngForOf]="validators.controls | asUfControlsGroups" let-validator let-i="index">
                <uf-expander dragItem [dragDisabled]="validators.disabled" [isExpanded]="expandeds[i]"
                    class="uf-box flat draggable gap-sm-bottom">
                    <div expanderHeader dragHandle class="uf-app-bar flat">
                        <div class="title primary">{{getValidatorLiteral(validator).typeLabel}}</div>
                        <uf-icon *ngIf="validator.invalid" name="error" class="error">
                        </uf-icon>
                        <button *ngIf="!validator.disabled" (click)="removeValidator(i)" title="Delete"
                            class="uf-action">
                            <uf-icon name="delete"></uf-icon>
                        </button>
                    </div>
                    <div expanderBody [formGroup]="validator" class="uf-grid pad">
                        <uf-text *ngIf="!validator.get(validatorKeys.Value)?.disabled"
                            [label]="getValidatorLiteral(validator).valueLabel"
                            [placeholder]="getValidatorLiteral(validator).valuePlaceholder"
                            [formControlName]="validatorKeys.Value" class="col-12">
                        </uf-text>
                        <uf-text label="Message" [placeholder]="getValidatorLiteral(validator).messagePlaceholder"
                            [formControlName]="validatorKeys.Message" class="col-12">
                        </uf-text>
                    </div>
                </uf-expander>
            </ng-template>

        </uc-drag-list>

        <button type="button" class="uf-button x-small right gap-sm-top" listBox [options]="validatorTypes"
            nameProperty="name" (optionSelected)="addValidator($event)">
            Add Validator
            <uf-icon name="more" class="gap-none"></uf-icon>
        </button>

    </div>
</uf-expander>