import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { UcCollection, UcCollectionTranslation, UcDefinition } from 'client';

import { LanguageCollectionData } from './language-collection-resolver';


@Injectable()
export class LanguageCollectionStore {

    definition: UcDefinition;
    ucCollection: UcCollection;
    ucCollectionTranslation: UcCollectionTranslation;

    constructor(
        route: ActivatedRoute
    ) {
        const { definition, ucCollection, ucCollectionTranslation } = route.snapshot.data.collectionData as LanguageCollectionData;

        this.definition = definition;
        this.ucCollection = ucCollection;
        this.ucCollectionTranslation = ucCollectionTranslation;
    }


}