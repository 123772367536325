import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { Config } from 'app-config';

import { ContextService } from './context.service';


//TODO is this class needed??
@Injectable()
export class TitleService implements OnDestroy {

    private subscriptions = new Subscription();

    constructor(
        private context: ContextService,
        private router: Router,
        private route: ActivatedRoute,
        private title: Title,
        @Inject(Config) private config: Config
    ) {

    }

    public init() {
        this.subscriptions.add(this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe(() => this.updateName()));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private updateName() {

        let result = this.context.tenantSettings?.name || this.config.title;

        let title = '';
        let child = this.route.firstChild;
        while (child) {
            // Store title
            if (child.snapshot.data && child.snapshot.data.title) {
                title = child.snapshot.data.title;
            }
            // Look for deeper route title (more specific)
            if (child.firstChild) {
                child = child.firstChild;
            } else {
                child = null;
            }
        }

        if (title) {
            result += ' | ' + title;
        }

        this.title.setTitle(result);
    }
}
