import { Component, HostBinding, Inject } from '@angular/core';
import { ModalService } from '@unifii/library/common';
import { TokenStorage, TokenStorageInterface } from '@unifii/sdk';

import { UcClient, UcProject } from 'client';

import {
    ContentSelectComponent, ContentSelectConfig, ContentSelectType, ContentType, getContentSelectConfig
} from 'components/content/modals/content-select.component';


@Component({
    templateUrl: './empty.html'
})
export class EmptyComponent {

    @HostBinding('class.stretch-component') class = true;

    readonly contentTypes = Object.keys(ContentSelectType);

    contentType: ContentSelectType | null;
    content: ContentType | null;

    private ucProject: UcProject;

    constructor(
        private ucClient: UcClient,
        @Inject(TokenStorage) private tokenStorage: TokenStorageInterface,
        private modalService: ModalService
    ) {
        this.ucProject = new UcProject(this.ucClient, { projectId: '53', preview: false }, this.tokenStorage);
    }

    async show(type: ContentSelectType) {
        this.content = null;
        this.content = await this.modalService.openMedium<ContentSelectConfig, ContentType>(
            ContentSelectComponent, getContentSelectConfig(type, this.ucProject)
        );


    }
}
