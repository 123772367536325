import { Component } from '@angular/core';
import { Breadcrumb } from '@unifii/library/common';

import { App, UcApps } from 'client';

import { TabsPage } from 'pages/tabs-page';

import { BreadcrumbService } from 'services/breadcrumb.service';


@Component({
    selector: 'uc-app-details',
    templateUrl: './app-details.html'
})
export class AppDetailsComponent extends TabsPage {

    name: string;
    breadcrumbs: Breadcrumb[];
    private _id: string;

    constructor(
        private ucApps: UcApps,
        private breadcrumbService: BreadcrumbService
    ) {
        super();
        this.subscriptions.add(this.route.params.subscribe(params => this.id = params.id));
        this.subscriptions.add(this.ucApps.appModified.subscribe(app => this.updateName(app)));
        this.subscriptions.add(this.ucApps.appAdded.subscribe(app => this.reload(app.id)));
    }

    get isNew() {
        return this.id === 'new';
    }

    set id(v: string) {

        if (v === this._id) {
            return;
        }

        this._id = (null as any as string);
        this._id = v;

        if (this.isNew) {
            this.name = 'New';
            return;
        }

        this.updateName();
    }

    get id(): string {
        return this._id;
    }

    private async updateName(app?: App) {
        if (app) {
            this.name = app.name;
        }

        this.breadcrumbs = this.breadcrumbService.getBreadcrumbs(this.route, [this.name]);
    }

    private reload(id: string) {
        this.router.navigate(['../', id], { relativeTo: this.route });
    }
}
