<uf-expander class="uf-box flat">
    <div expanderHeader class="uf-app-bar flat">
        <div class="title primary">Workflow</div>
        <uf-icon *ngIf="transitions.invalid" name="error" class="error"></uf-icon>
        <a *ngIf="transitions.valid" (click)="pasteTransition()" title="Paste transition" class="uf-action tertiary">
            <uf-icon name="paste"></uf-icon>
        </a>
    </div>

    <div expanderBody class="pad-sm">

        <uf-blockquote *ngIf="!hasBucket" class="warning col-1of1" icon="warningSolid"
            content="The Form Data Repository is neccessary in order to configure the workflow">
        </uf-blockquote>

        <uc-drag-list *ngIf="transitions.length" [parent]="transitions" [items]="transitions.controls" canDrop="false"
            canReorder="true">
            <ng-template ngFor [ngForOf]="transitions.controls | asUfControlsGroups" let-transition let-i="index">

                <div dragItem [dragDisabled]="transition.disabled"
                    class="uf-app-bar uf-box flat draggable gap-sm-bottom">
                    <div class="row grow center-all">
                        <div class="col grow">
                            <div class="grow pad-sm-left">
                                <div>{{getTransitionLabel(transition)}}</div>
                                <!-- TODO Wait for 2.1 release to actually show the deep error -->
                                <!-- <uf-error-deep [control]="transition"></uf-error-deep> -->
                            </div>
                            <div class="grow pad-sm-left">
                                {{getResultAndRolesLabel(transition)}}
                            </div>
                        </div>
                        <uf-icon *ngIf="transition.invalid" name="error" class="error"></uf-icon>
                        <uf-icon *ngIf="hasShowIf(transition)" name="showIf" title="show if"
                            class="gap-sm-sides primary"></uf-icon>
                        <uf-icon *ngIf="hasTags(transition)" name="tagged" title="tags"
                            class="gap-sm-sides primary"></uf-icon>
                        <uf-icon *ngIf="hasTriggersOrSpawns(transition)" name="trigger" title="triggers"
                            class="gap-sm-sides primary"></uf-icon>
                        <button *ngIf="!transition.invalid" (click)="copyTransition(i)" title="Copy"
                            [disabled]="!hasBucket" class="uf-action">
                            <uf-icon name="copy"></uf-icon>
                        </button>
                        <button *ngIf="!transition.disabled" (click)="editTransition(i)" title="Edit"
                            [disabled]="!hasBucket" class="uf-action">
                            <uf-icon name="edit"></uf-icon>
                        </button>
                        <button *ngIf="!transition.disabled" (click)="removeTransition(i)" title="Delete"
                            class="uf-action">
                            <uf-icon name="delete"></uf-icon>
                        </button>
                    </div>

                </div>
            </ng-template>
        </uc-drag-list>

        <uf-error [control]="transitions" class="wrap-text"></uf-error>

        <div class="row">
            <button *ngIf="!transitions.disabled" [disabled]="!hasBucket" (click)="addTransition()"
                class="uf-button x-small right">
                Add Transition
            </button>
        </div>

    </div>
</uf-expander>