import { amendOptionsParams, ClientDeleteOptions, ClientGetOptions } from '@unifii/sdk';

import { DefaultPaginationParams } from 'constant';

import { ClaimSource, UcClaimConfig } from './models';
import { UcClient } from './uc-client';


export class ClaimsClient {

    private baseUrlSegment: string;

    constructor(
        private client: UcClient,
        private claimSource: ClaimSource
    ) {
        this.baseUrlSegment = this.claimSource === ClaimSource.Company ? 'company-claims' : 'user-claims';
    }

    /** q, sort, offset = 0, limit = 100, analytics?: RequestAnalytics */
    list(options?: ClientGetOptions): Promise<UcClaimConfig[]> {
        return this.client.get(this.url(), amendOptionsParams(DefaultPaginationParams, options));
    }

    get(id: string, options?: ClientGetOptions): Promise<UcClaimConfig> {
        return this.client.get(this.url(id), options);
    }

    add(claim: UcClaimConfig): Promise<UcClaimConfig> {
        return this.client.post(this.url(), { body: claim });
    }

    update(id: string, claim: UcClaimConfig): Promise<UcClaimConfig> {
        return this.client.put(this.url(id), claim);
    }

    delete(id: string, options?: ClientDeleteOptions): Promise<void> {
        return this.client.delete(this.url(id), options);
    }

    private url(...extra: string[]): string {
        const urlParts = [this.baseUrlSegment, ...extra];
        return this.client.buildUrl(urlParts);
    }
}