<div class="uf-app-bar ">
    <button type="button" class="uf-action tertiary" (click)="close()" title="Close">
        <uf-icon name="close"></uf-icon>
    </button>
    <h3>External Input</h3>
</div>
<div class="uf-grid pad gaps" [formGroup]="control">
    <uf-description-list [items]="infoDescriptors" class="col-12"></uf-description-list>
    <uf-text [formControlName]="externalInputKeys.Value" [label]="label" [placeholder]="info.placeholder"
        class="col-12">
    </uf-text>
</div>

<div class="row space-children pad">
    <button (click)="close()" class="uf-button tertiary right" type="button">Cancel</button>
    <button (click)="submit()" class="uf-button primary" type="button">Save</button>
</div>