<uf-panel *ngIf="ready" class="content-pane container">
    <div class="uf-container-lg">
        <div class="uf-grid row-gap-lg gap-top">
            <div class="uf-box col-12">
                <div class="uf-app-bar flat accent">
                    <div class="title">
                        Details
                    </div>
                </div>
                <div class="uc-group-content uf-grid pad">
                    <uf-description-list class="small-label col-12" [items]="detailsKeyValue">
                    </uf-description-list>

                    <uf-text class="col-12" label="Title" [(value)]="table.title" [control]="cTitle">
                    </uf-text>

                    <uf-text class="col-12" label="Identifier" [(value)]="table.identifier" [control]="cIdentifier"
                        [maxLength]="identifierMaxLength">
                    </uf-text>

                    <uf-textarea class="col-12" label="Description" [(value)]="table.description"
                        [control]="cDescription">
                        <uf-help
                            content="Description is displayed in the Console only. Add notes to remember table set up and purpose."></uf-help>
                    </uf-textarea>

                    <uf-markdown-editor class="col-12" [(value)]="table.help" [control]="cHelpText" label="Help Text">
                        <uf-help content="Help Text is displayed to end users."></uf-help>
                    </uf-markdown-editor>

                    <uf-checkbox *ngIf="showRecordCount" [control]="cShowCount" label="Show Count" class="col-12">
                    </uf-checkbox>

                    <uf-checkbox *ngIf="table.sourceType !== sourceTypes.Company" [(value)]="export" [control]="cExport"
                        label="Export" class="col-12">
                    </uf-checkbox>

                    <uf-chips class="col-12" label="Export roles"
                        *ngIf="export && table.sourceType !== sourceTypes.Company" [control]="cExportRoles"
                        [options]="filteredRoles" (searchChange)="filterRoles($event)">
                    </uf-chips>
                </div>
            </div>

            <div class="uf-box col-12">
                <div class="uf-app-bar flat accent">
                    <div class="title">
                        Default Sort
                    </div>
                </div>
                <div class="uc-group-content uf-grid gap">
                    <uc-sort-property-input class="col-12" [properties]="sortableColumns" [(value)]="table.defaultSort"
                        (valueChange)="edited = true">
                    </uc-sort-property-input>
                </div>
            </div>

            <uc-table-columns [availableColumns]="availableColumns" [(columns)]="table.columns" [parentControl]="cRoot"
                (columnsChange)="edited = true" class="col-12">
            </uc-table-columns>

            <uc-visible-filters [availableFilters]="availableVisibleFilters" [(filters)]="table.visibleFilters"
                [parentControl]="cRoot" (filtersChange)="edited = true" class="col-12">
            </uc-visible-filters>

            <div class="uf-box col-12 gap-top-sm">
                <div class="uf-app-bar flat accent">
                    <ng-template [ngIf]="isAdvancedFilter" [ngIfElse]="basic">
                        <div class="title">
                            Advanced Hidden Filters
                        </div>
                        <a (click)="switchFilter()" title="Switch to standard" class="uf-action tertiary">
                            <uf-icon name="filter"></uf-icon>
                        </a>
                    </ng-template>
                    <ng-template #basic>
                        <div class="title">
                            Hidden Filters
                        </div>
                        <a (click)="switchFilter()" title="Switch to advanced" class="uf-action tertiary">
                            <uf-icon name="custom"></uf-icon>
                        </a>
                    </ng-template>
                </div>

                <div class="uc-group-content grid">
                    <uc-filter-editor *ngIf="!isAdvancedFilter" [dataProperties]="availableHiddenFilters"
                        [(filter)]="table.filter" [parentControl]="cFilter" class="col-1of1">
                    </uc-filter-editor>
                    <uf-textarea *ngIf="isAdvancedFilter" [(value)]="table.advancedFilter" [control]="cAdvancedFilter"
                        class="col-1of1">
                    </uf-textarea>
                </div>
            </div>
        </div>
    </div>
</uf-panel>