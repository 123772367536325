import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import { FilterEntries } from '@unifii/library/common';

import { ActivityType, UcIntegrations, UcProject } from 'client';

import { ActivityTypeToken, WorkflowActivityTableManager } from './workflow-activity-table-manager';
import { dataForwarderFilterFactory } from './workflows-filters';


@Component({
    selector: 'uc-data-forwarders',
    template: `
        <div ufMasterDetail>
            <uf-table-container ufSyncRoute (addItem)="add()" class="accent pad-none list-md"></uf-table-container>
        </div>
        <router-outlet></router-outlet>
    `,
    providers: [
        { provide: FilterEntries, useFactory: dataForwarderFilterFactory, deps: [UcProject, UcIntegrations] },
        { provide: ActivityTypeToken, useValue: ActivityType.DataForwarder },
        { provide: TableContainerManager, useClass: WorkflowActivityTableManager }
    ],
})
export class DataForwardersComponent {

    constructor(
        private router: Router,
        private route: ActivatedRoute
    ) {
    }

    add() {
        this.router.navigate(['new'], { relativeTo: this.route });
    }

}
