import { Subscription } from 'rxjs';

import { Component, Inject, OnDestroy, Optional } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import {
    DataPropertyDescriptor, ExpandersService, FilterEntry, FilterValue, ModalService, ToastService, UfControl, UfControlArray, UfControlGroup,
    ValidatorFunctions
} from '@unifii/library/common';
import { Dictionary, TableDetail, TableDetailModule, TableDetailModuleType, TableFieldDescriptor, TableSourceType } from '@unifii/sdk';

import { TableInfo, UcProject, UcTable } from 'client';

import { EditData } from 'components/common/edit-data';
import { SaveOption, SaveOptionType } from 'components/common/save-options/save-options.component';
import { TypeSelectComponent, TypeSelectConfig, TypeSelectOption } from 'components/common/type-select.component';
import { BuilderHeaderService } from 'components/compound-builder/builder-header/builder-header.service';
import {
    ContentSelectComponent, ContentSelectConfig, ContentSelectType, ContentType, getContentSelectConfig
} from 'components/content/modals/content-select.component';

import { allowsLabelOverride } from 'helpers/data-descriptor-helper';

import { DialogsService } from 'services/dialogs.service';

import { TableComponent } from '../table.component';


interface FieldInfo {
    descriptor: TableFieldDescriptor;
    dataProperty?: DataPropertyDescriptor;
    _label?: string;
    _icon?: string;
    _exists?: boolean;
    _overrideLabel?: boolean;
}

@Component({
    templateUrl: 'table-detail.html',
    providers: [ExpandersService]
})
export class TableDetailComponent implements OnDestroy, EditData {

    readonly sourceTypes = TableSourceType;
    readonly moduleTypes = TableDetailModuleType;

    ready: boolean;

    tableDetailType: 'basic' | 'details';
    tableDetailTypeOptions: Dictionary<string>[];
    availableVisibleFields: DataPropertyDescriptor[];
    availableHiddenFilters: DataPropertyDescriptor[];
    visibleFields: DataPropertyDescriptor[];
    fields: FieldInfo[];

    // Controls
    cRoot: UfControlGroup;
    cModules: UfControlArray;

    table: UcTable;
    tableDetail: TableDetail | undefined;

    private _edited: boolean;
    private subscriptions = new Subscription();

    constructor(
        @Optional() private tableComponent: TableComponent,
        private modalService: ModalService,
        private ucProject: UcProject,
        private toastService: ToastService,
        private dialogs: DialogsService,
        private builderHeaderService: BuilderHeaderService,
        @Optional() @Inject(TableContainerManager) private tableManager: TableContainerManager<TableInfo, FilterValue, FilterEntry>,
        private router: Router,
        private route: ActivatedRoute
    ) {
        this.setup();
    }

    get edited(): boolean {
        return this._edited;
    }

    set edited(v: boolean) {
        this._edited = v;
        this.tableComponent.edited = v;
    }

    ngOnDestroy() {
        this.tableComponent.edited = false;
        this.subscriptions.unsubscribe();
    }

    protected toggleTableDetailType() {
        if (this.tableDetailType === 'basic') {
            this.tableDetail = undefined;
        } else {
            this.tableDetail = { title: '', fields: [], modules: [] };
            this.fields = [];
        }

        this.edited = true;
        this.updateControls();
    }

    protected filterFields(q: string) {
        this.visibleFields = this.availableVisibleFields.filter(item => {
            let accepted = true;
            // Apply search
            if (q && q.trim().length) {
                accepted =
                    item.identifier.toLowerCase().indexOf(q.toLowerCase()) > -1 ||
                    item.label.toLowerCase().indexOf(q.toLowerCase()) > -1;
            }
            // Apply excluded
            if (accepted) {
                accepted = !this.tableDetail?.fields.find(field => field.type === 'Field' && field.identifier === item.identifier);
            }
            return accepted;
        });
    }

    protected addField(identifier?: string) {
        const fieldDescriptor: TableFieldDescriptor = identifier ?
            { type: 'Field', identifier } :
            { type: 'Heading', value: '' };
        this.tableDetail?.fields.push(fieldDescriptor);
        this.fields.push(this.getFieldInfo(fieldDescriptor));
        this.edited = true;
    }

    protected removeField(index: number) {
        this.tableDetail?.fields.splice(index, 1);
        this.fields.splice(index, 1);
        this.edited = true;
    }

    protected async addModule() {

        const config: TypeSelectConfig = {
            title: 'Select module type',
            types: [{
                type: TableDetailModuleType.Table,
                label: TableDetailModuleType.Table,
                icon: 'table'
            }]
        };

        const choice = await this.modalService.openMedium<TypeSelectConfig, TypeSelectOption>(TypeSelectComponent, config);

        if (!choice) {
            return;
        }

        let module: TableDetailModule | null = null;

        switch (choice?.type) {
            case TableDetailModuleType.Table:

                const table = await this.modalService.openMedium<ContentSelectConfig, ContentType>(
                    ContentSelectComponent,
                    getContentSelectConfig(ContentSelectType.Table, this.ucProject)
                ) as TableInfo;

                if (!table) {
                    return;
                }

                module = { type: TableDetailModuleType.Table, identifier: table.identifier };
                break;
        }

        if (module) {
            this.tableDetail?.modules.push(module);
            this.cModules.push(new UfControlGroup({}));
            this.edited = true;
        }
    }

    protected moveModule(event: any) {
        this.edited = true;
        const moduleControl = this.cModules.at(event.from);
        this.cModules.removeAt(event.from);
        this.cModules.insert(event.to, moduleControl);
    }

    protected async removeModule(index: number) {
        if (!await this.dialogs.confirmDelete()) {
            return;
        }
        this.tableDetail?.modules.splice(index, 1);
        this.cModules.removeAt(index);
        this.edited = true;
    }

    protected syncFieldsLabel() {
        for (const field of this.fields) {
            field._label = this.getDisplayLabelValue(field.descriptor, field.dataProperty);
        }
    }

    protected onFieldChange() {
        this.syncFieldsLabel();
        this.edited = true;
    }

    private async setup() {

        this.table = this.tableComponent.info.table;
        this.availableVisibleFields = this.tableComponent.info.dataDescriptor.propertyDescriptors.filter(pd => pd.asDisplay);
        this.availableHiddenFilters = this.tableComponent.info.dataDescriptor.propertyDescriptors.filter(pd => pd.asStaticFilter);

        this.tableDetailTypeOptions = [
            { value: 'basic', name: this.table.sourceType === TableSourceType.Bucket ? 'Form' : this.table.sourceType === TableSourceType.Company ? 'Company' : 'User' },
            { value: 'details', name: 'Details Page' }
        ];

        try {
            this.tableDetail = await this.ucProject.getTableDetail(this.table.id as string);
        } catch (e) {
            // 404 undefined tableDetail
        } finally {
            this.fields = this.tableDetail?.fields.map(field => this.getFieldInfo(field)) ?? [];
            this.tableDetailType = this.tableDetail == null ? 'basic' : 'details';
        }

        this.cRoot = new UfControlGroup({ type: new UfControl() });

        this.subscriptions.add(this.cRoot.valueChanges.subscribe(() => {
            if (!this.cRoot.pristine) {
                this.edited = true;
            }
        }));

        this.subscriptions.add(this.builderHeaderService.saveClicked.subscribe(saveOption => this.save(saveOption)));

        this.updateControls();

        this.ready = true;
    }

    private updateControls() {

        if (this.tableDetail) {
            this.cRoot.setControl('label', new UfControl(ValidatorFunctions.required('A label is required')));
            this.cModules = new UfControlArray(this.tableDetail.modules.map(m => new UfControlGroup({})));
            this.cRoot.setControl('modules', this.cModules);
        } else {
            this.cRoot.removeControl('label');
            this.cRoot.removeControl('modules');
        }
    }

    private getFieldInfo(field: TableFieldDescriptor): FieldInfo {

        if (field.type === 'Heading') {
            return {
                descriptor: field,
                _icon: 'content',
                _label: this.getDisplayLabelValue(field),
                _exists: true
            };
        }

        const dataProperty = this.availableVisibleFields.find(item => item.identifier === field.identifier);

        return {
            descriptor: field,
            dataProperty,
            _exists: dataProperty != null,
            _icon: dataProperty?.icon ?? 'query',
            _label: this.getDisplayLabelValue(field, dataProperty),
            _overrideLabel: allowsLabelOverride(this.tableComponent?.info.dataDescriptor.type, dataProperty)
        };
    }

    private getDisplayLabelValue(descriptor: TableFieldDescriptor, propertyDescriptor?: DataPropertyDescriptor): string {
        if (descriptor.type === 'Heading') {
            return `Heading ${descriptor.value ? `(${descriptor.value})` : ''}`;
        }

        if (descriptor.label) {
            return `${descriptor.label} (${descriptor.identifier})`;
        }

        return propertyDescriptor?.display ?? descriptor.identifier;
    }

    private async save(saveOption?: SaveOption) {

        try {

            this.cRoot.updateValueAndValidity();
            this.cRoot.setSubmitted(true);

            if (this.cRoot.invalid) {
                return;
            }

            // Save
            if (this.tableDetail != null) {
                this.tableDetail.fields = this.fields.map(f => f.descriptor);
                await this.ucProject.saveTableDetail(this.table.id as string, this.tableDetail);
            } else {
                await this.ucProject.deleteTableDetail(this.table.id as string);
            }

            if (saveOption?.id === SaveOptionType.Approved && this.table.id) {
                this.table = await this.ucProject.approveTable(this.table.id);
            }

            const updatedTable = await this.ucProject.getTable(this.table.id as string);

            this.tableManager?.updateItem?.next(updatedTable as TableInfo);
            this.builderHeaderService.updateConfig(updatedTable);

            this.edited = false;
            this.toastService.success('Detail saved!');

            if (saveOption && this.builderHeaderService.config.cancelRoute) {
                this.router.navigate(this.builderHeaderService.config.cancelRoute, { relativeTo: this.route });
            }

        } catch (e) {
            this.toastService.error(`Error save: ${e.message}`);
        }
    }
}