<uf-expander *ngIf="ready" class="uf-box flat">
    <div expanderHeader class="uf-app-bar flat">
        <div class="title primary">Advanced</div>
        <uf-icon *ngIf="isInvalid" name="error" class="error"></uf-icon>
    </div>
    <div expanderBody [formGroup]="control" class="uf-grid pad-sides pad-sm-top pad-bottom">

        <uf-multi-choice label="Data Capture"
            *ngIf="meta.dataCaptures && !control.get(fieldKeys.DataCaptures)?.disabled"
            [formControlName]="fieldKeys.DataCaptures" [options]="dataCaptureTypes" valueProperty="identifier">
        </uf-multi-choice>

        <uf-multi-choice label="Allowed Types" *ngIf="meta.allowedTypes" [formControlName]="fieldKeys.AllowedTypes"
            [options]="linkAllowedTypes" valueProperty="identifier">
        </uf-multi-choice>

        <uf-checkbox label="Reportable" *ngIf="meta.isReportable" [formControlName]="fieldKeys.IsReportable"
            class="col-12">
        </uf-checkbox>

        <uf-checkbox label="Searchable" *ngIf="meta.isSearchable" [formControlName]="fieldKeys.IsSearchable"
            class="col-12">
        </uf-checkbox>

        <uf-checkbox label="Translatable" *ngIf="meta.isTranslatable" [formControlName]="fieldKeys.IsTranslatable"
            class="col-12">
        </uf-checkbox>

        <uf-chips label="Tags" *ngIf="meta.tags" [formControlName]="fieldKeys.Tags" [allowCustom]="true"
            (searchChange)="searchTags($event)" [options]="tagsResult" class="col-12">
        </uf-chips>

    </div>
</uf-expander>