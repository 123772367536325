<div *ngIf="item" class="col grow">
    <div class="uf-app-bar transparent">
        <uf-breadcrumbs [breadcrumbs]="breadcrumbs" class="large">
        </uf-breadcrumbs>
        <button (click)="revoke()" type="button" class="uf-button secondary right">Revoke</button>
    </div>
    <div class="content-pane">
        <div class="uf-container-lg">
            <div class="uf-grid row-gap-lg pad-top">
                <div class="col-12 uf-box">
                    <div class="uf-app-bar accent">
                        <div class="title">Details</div>
                    </div>
                    <div class="uf-grid pad">
                        <div class="col-12 row align-flex-end space-children">
                            <uf-description-list
                                [items]="[{term: 'Name', description: item.name}, {term: 'Key', description: item.key}]"
                                class="small-label"></uf-description-list>
                            <button type="button" class="uf-action tertiary" (click)="clipboard.setText(item.key)"
                                title="Copy">
                                <uf-icon name="copy"></uf-icon>
                            </button>
                        </div>
                        <div *ngIf="item.enableOAuth" class="col-12">
                            <p class="small">
                                OAuth 2 client credentials flow enabled
                            </p>
                        </div>
                        <ng-template [ngIf]="item.secret">
                            <div class="col-12 row align-flex-end space-children">
                                <uf-description-list [items]="[{term: 'Secret', description: item.secret}]"
                                    class="small-label">
                                </uf-description-list>
                                <button (click)="clipboard.setText(item.secret)" type="button"
                                    class="uf-action tertiary" title="Copy">
                                    <uf-icon name="copy"></uf-icon>
                                </button>
                            </div>
                            <uf-blockquote class="col-12 warning" icon="warningSolid"
                                heading="Save your key and secret."
                                content="The secret won't be visible after leaving this page.">
                            </uf-blockquote>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <uf-panel class="grow pad-sides">
        <uc-permissions></uc-permissions>
    </uf-panel>
</div>