<div [formGroup]="parentControl" class="uf-grid">
    <ng-template [ngIf]="ready">
        <uf-description-list class="col-6">
            <dt>Table:</dt>
            <dd>{{table.title}}</dd>
        </uf-description-list>
        <a *ngIf="tableLink" [routerLink]="tableLink" target="_blank" class="uf-action tertiary col-6 right"
            title="Open">
            <uf-icon name="open"></uf-icon>
        </a>
        <uf-text [formControlName]="controlKeys.Title" label="Title" [placeholder]="table.title" class="col-12">
            <uf-help content="Leave blank to use the Table's title."></uf-help>
        </uf-text>
        <uf-number [formControlName]="controlKeys.Limit" label="Limit" class="col-12"></uf-number>
        <uf-chips [formControlName]="controlKeys.Roles" label="Restrict to Roles" placeholder="Search Roles"
            (searchChange)="findRoles($event)" [options]="rolesResult" class="col-12">
        </uf-chips>
        <uf-checkbox *ngIf="showCanAdd" [formControlName]="controlKeys.CanAdd" label="Enable Add Button" class="col-12">
        </uf-checkbox>
        <uc-filter-editor *ngIf="staticFilters.length" heading="Filter" [dataProperties]="staticFilters"
            [(filter)]="module.filter" (filterChange)="onFilterChange()" [parentControl]="parentControl" class="col-12">
        </uc-filter-editor>
    </ng-template>
    <uf-message *ngIf="error" content="Table not found, please remove this module" class="error col-12">
    </uf-message>
</div>