<div class="uf-app-bar ">
    <button type="button" class="uf-action tertiary" (click)="close()" title="Close">
        <uf-icon name="close"></uf-icon>
    </button>
    <h3>Data Mapping</h3>
</div>
<div class="uf-grid pad gaps" [formGroup]="formGroup">
    <uf-autocomplete [formControlName]="sourceKey" [options]="sourceFields" [label]="sourceFieldLabel"
        [nameProperty]="namePropertyFunc" (searchChange)="sourceSearch($event)" class="col-12">
    </uf-autocomplete>
    <uf-autocomplete [formControlName]="targetKey" [options]="targetFields" [label]="targetFieldLabel"
        [nameProperty]="namePropertyFunc" (searchChange)="targetSearch($event)" class="col-12">
    </uf-autocomplete>
    <!-- <span>Target valid: {{formGroup.get('target')?.valid}}</span> -->
</div>
<div class="row space-children pad">
    <button (click)="close()" class="uf-button tertiary right" type="button">Cancel</button>
    <button (click)="submit()" class="uf-button primary" type="button">Save</button>
</div>